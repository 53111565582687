import * as React from 'react';
import { toastError } from '@ez/components';
import { fromEdges, NodeType, useMutationJobTodo } from '@poolware/api';
import * as _ from 'lodash';
import { JobTodoForm } from './JobTodoForm';
import { useJobTodoMutators } from '../../../queries/mutators-job-todos';

export interface JobTodoTemplateNewFormProps {
    onDone: () => any;
    onCancel?: () => any;
    jobTodoMutators: ReturnType<typeof useJobTodoMutators>;
    workOrder: NodeType.WorkOrder;
}
export const JobTodoNewForm: React.FC<JobTodoTemplateNewFormProps> = ({ jobTodoMutators, workOrder, onDone }) => {
    const initialValues = {
        title: '',
        description: '',
        timeEstimate: '',
        price: '',
        items: [],
        'items-item-pending': { title: '', description: '', orderIndex: 1000 },
    };

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            const toCreate = values.items.map((item, index) => ({
                title: item.title?.trim() || '',
                description: item.description?.trim() || '',
                orderIndex: index,
            }));
            if (values['items-item-pending']?.title) {
                toCreate.push({
                    title: values['items-item-pending'].title,
                    description: '',
                    orderIndex: toCreate.length,
                });
            }

            try {
                await jobTodoMutators.create({
                    workOrder: workOrder.id,
                    description: values.description?.trim(),
                    title: values.title?.trim(),
                    timeEstimate: _.toNumber(values.timeEstimate),
                    price: _.toNumber(values.price),
                    items: toCreate,
                    index: fromEdges(workOrder.jobs).length,
                });
                onDone();
            } catch (e) {
                toastError(e);
            }
        } catch (e) {
            toastError(e);
        }
    };

    return <JobTodoForm debug={false} initialValues={initialValues} onCancel={onDone} onSubmit={handleSubmit} />;
};
