import * as React from 'react';
import { useState } from 'react';
import { Field, getIn } from 'formik';
import { FormikFormFieldLabel, Modal } from '@ez/components';
import { ProductPreview } from './components';
import { ProductPicker } from '@poolware/app-catalog';
import { Button } from '@ez/components';

export const ProductPickerField: React.FC<{ label; name; required; onNewProductCreateRequest }> = ({
    label,
    name,
    required,
    onNewProductCreateRequest,
}) => {
    const [showPicker, setShowPicker] = useState(false);
    const closePicker = () => {
        setShowPicker(false);
    };
    const openPicker = () => {
        setShowPicker(true);
    };

    const handleOnNewProductCreate = (suggestedValues) => {
        setShowPicker(false);
        onNewProductCreateRequest(suggestedValues);
    };

    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleOnNewProductSelect = (product) => {
                    form.setFieldValue(field.name, product);
                    closePicker();
                };

                const handleRemove = () => {
                    form.setFieldValue(field.name, null);
                };

                const productName = field.value?.['name'] || '';
                const touched = getIn(form.touched, name);
                const error = getIn(form.errors, name);

                let hasError = Boolean(touched && !!error);
                const labelComp = <FormikFormFieldLabel htmlFor={name} label={label} name={name} required={required} />;

                return (
                    <>
                        {labelComp}
                        <div className={'flex flex-row gap-2'}>
                            <div
                                className={
                                    'flex-grow flex h-10 pl-4 rounded bg-gray-100 border-input border items-center'
                                }
                            >
                                <div className={'flex-grow'}>{productName}</div>
                                {field.value && (
                                    <Button
                                        variant={'tertiary'}
                                        onClick={handleRemove}
                                        type={'button'}
                                        basic
                                        icon={'cancel'}
                                    />
                                )}
                            </div>

                            <Button size={'md'} icon={'search'} content={'Find in Catalog'} onClick={openPicker} />
                        </div>

                        {field.value && (
                            <div className={'my-4'}>
                                <ProductPreview product={field.value} />
                            </div>
                        )}

                        <Modal open={showPicker} centered={false} onClose={closePicker} closeOnEscape={false}>
                            <Modal.Content>
                                <ProductPicker
                                    onNewProductCreate={handleOnNewProductCreate}
                                    onCancel={closePicker}
                                    onSelect={handleOnNewProductSelect}
                                />
                            </Modal.Content>
                        </Modal>
                    </>
                );
            }}
        </Field>
    );
};
