import gql from 'graphql-tag';
import { NodeType } from '@poolware/api';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { OperationVariables } from '@apollo/react-common';

const QL = gql`
    query ($id: ID!) {
        viewer {
            resolveEZCDeviceState(device: $id) {
                state
                definitionId
                device {
                    id
                    deviceId
                    ezcId
                    definition {
                        id
                        definitionId
                    }
                }
            }
        }
    }
`;

export function useQueryEzcDeviceLink<TVariables = OperationVariables>(
    id: NodeType.ID,
    options?: QueryHookOptions<any, TVariables>
) {
    const variables = {
        id: id,
    };

    const { data, ...rest } = useQuery<NodeType.Query>(QL, {
        variables: variables,
        ...options,
    });

    const deviceState: NodeType.EZCDeviceState = data?.viewer?.resolveEZCDeviceState;
    return {
        deviceState,
        ...rest,
    };
}
