import * as React from 'react';
import { NodeType } from '@poolware/api';
import {
    Button,
    Segment,
    Form,
    FormikFormFieldLabel,
    Modal,
    stringFormatters,
    useModalCtrl,
    InputWithAction,
    FormField,
} from '@ez/components';
import { useField } from 'formik';
import { ModuleIconNames } from '../../../constants';
import { AppointmentsSplitter, AppointmentsSplitterMode } from './AppointmentsSplitterTable';

export const FormikRecurAppointmentSplitter: React.FC<{
    label: string;
    name: string;
    patternBlockId?: string;
    serviceJob: NodeType.ServiceJob;
    headerLeft: string;
    headerRight: string;
    mode: AppointmentsSplitterMode;
}> = ({ name, serviceJob, headerLeft, headerRight, mode, label, patternBlockId }) => {
    const modalWOPicker = useModalCtrl();

    const [field, meta, helpers] = useField<NodeType.AppointmentItem>(name);

    const onSelectStartAppointment = (appointmentItem: NodeType.AppointmentItem) => {
        helpers.setValue(appointmentItem);
        modalWOPicker.onClose();
    };

    const selectedAppt = field.value;
    let hasError = Boolean(meta.touched && meta.error);
    const onRemove = () => {
        helpers.setValue(null);
    };
    const value = selectedAppt ? stringFormatters.formatDate(selectedAppt?.startDate) : '';

    return (
        <>
            <FormField>
                <FormikFormFieldLabel label={label} name={name} required={true} />
                <InputWithAction
                    actionIcon={ModuleIconNames.Appointment}
                    actionLabel={'Select'}
                    value={value || ''}
                    hasError={hasError}
                    isClearable={!!value}
                    onClear={onRemove}
                    onClick={modalWOPicker.onOpen}
                />
            </FormField>
            <Modal {...modalWOPicker}>
                {modalWOPicker.open && (
                    <Segment>
                        <div className={'text-blue-800'}>
                            Please select appointment day from which the new service template should start.
                        </div>
                        <AppointmentsSplitter
                            patternBlockId={patternBlockId}
                            serviceJob={serviceJob}
                            mode={mode}
                            defaultSelection={selectedAppt}
                            onCancel={modalWOPicker.onClose}
                            onSelect={onSelectStartAppointment}
                            headerLeft={`FROM: ${headerLeft || serviceJob?.title}`}
                            headerRight={`TO: ${headerRight || ''}`}
                        />
                    </Segment>
                )}
            </Modal>
        </>
    );
};
