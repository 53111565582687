import * as React from 'react';
import { useState } from 'react';
import {
    ConfirmButton,
    ConnectionErrorMessage,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    PageSkeletonLoader,
    Panel,
    SectionHeader,
    toastError,
} from '@ez/components';
import { fromEdges, NodeType, useMutationWorkOrderTemplate } from '@poolware/api';
import { useQueryWorkOrderTemplate } from '../../../../WorkOrderTemplate/use-query-work-order-template';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import { ModuleIconNames } from '../../../../../constants';
import { JobTodoTemplateAssocsList } from './JobTodoTemplateAssocsList';
import * as _ from 'lodash';
import { JobTodoRowContainer } from './components';
import { Button } from '@ez/components';

export interface WorkOrderTemplateProps {
    workOrderTemplateId: NodeType.ID;
    onRemove: (workOrderTemplate: NodeType.WorkOrderTemplate) => any;
}

const WorkOrderDeleteConfirmButton: React.FC<{ onClick }> = ({ onClick }) => {
    return (
        <ConfirmButton
            basic
            size={'mini'}
            icon={'trash'}
            content={'Delete'}
            onClick={onClick}
            popup={{ content: 'Delete work order template from this service job template' }}
            confirmMessage={{
                content: <div>There is NO UNDO! This work order template will be deleted.</div>,
            }}
            cancelButton={{
                content: 'Keep',
            }}
            showLoaderOnConfirm={true}
            confirmButton={{
                content: 'Remove',
                icon: 'trash',
                negative: true,
            }}
        />
    );
};

const WorkOrderRemoveConfirmButton: React.FC<{ onClick }> = ({ onClick }) => {
    return (
        <ConfirmButton
            basic
            size={'mini'}
            icon={'unlink'}
            content={'Remove'}
            onClick={onClick}
            popup={{ content: 'Remove work order template from this service job template' }}
            confirmMessage={{
                content: (
                    <div>
                        <p>This work order template will not be removed from this service job template.</p>
                        <p>
                            It will not be permanetly deleted. It will still be available in the list of global
                            templates.
                        </p>
                    </div>
                ),
            }}
            cancelButton={{
                content: 'Keep',
            }}
            showLoaderOnConfirm={true}
            confirmButton={{
                content: 'Remove',
                icon: 'trash',
                negative: true,
            }}
        />
    );
};

export const WorkOrderTemplateDetails: React.FC<{
    templateMutation: ReturnType<typeof useMutationWorkOrderTemplate>;
    workOrderTemplate: NodeType.WorkOrderTemplate;
    onRemove;
}> = ({ workOrderTemplate, templateMutation }) => {
    const [edit, setEdit] = useState(false);

    const onSubmit = async (values: typeof workOrderTemplate) => {
        try {
            const conf = {
                id: workOrderTemplate.id,
                templateTitle: _.trim(values.templateTitle),
                providedTitle: _.trim(values.templateTitle),
                templateDescription: _.trim(values.templateDescription),
                providedDescription: _.trim(values.templateDescription),
            };
            await templateMutation.update(conf);
            setEdit(false);
            return conf;
        } catch (e) {
            toastError(e.message);
        }
    };

    if (edit) {
        return (
            <FormikDefaultForm
                // header={'Work Order'}
                onCancel={() => setEdit(false)}
                onSubmit={onSubmit}
                initialValues={workOrderTemplate}
            >
                <FormikInputField label={'Title'} name={'templateTitle'} />
                <FormikTextareaField label="Description" name={'templateDescription'} />
            </FormikDefaultForm>
        );
    }

    return (
        <>
            <JobTodoRowContainer>
                <div className={'row-content'}>
                    <div className={'row-content-main'}>
                        <div className={'row-content-details'} style={{ paddingLeft: '0.5rem' }}>
                            <Panel.Item labelWidth={'100%'} label={'Title'}>
                                <h2 style={{ fontSize: '1.8em', marginBottom: '0.5em' }}>
                                    {workOrderTemplate.templateTitle}
                                </h2>
                            </Panel.Item>
                            <Panel.ItemText
                                labelWidth={'100%'}
                                label={'Description'}
                                content={workOrderTemplate.templateDescription}
                            />
                        </div>
                    </div>
                    <div className={'row-content-actions'}>
                        <Button size={'sm'} icon={'edit'} onClick={() => setEdit(true)} />
                    </div>
                </div>
            </JobTodoRowContainer>
        </>
    );
};

export const WorkOrderTemplate: React.FC<WorkOrderTemplateProps> = ({ workOrderTemplateId, onRemove }) => {
    const { loading, error, node: workOrderTemplate, refetchQuery } = useQueryWorkOrderTemplate(workOrderTemplateId);

    const { modulesAccess } = useViewer();

    const templateMutation = useMutationWorkOrderTemplate({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !workOrderTemplate) {
        return <NotFoundPage />;
    }

    const handleOnTemplatePickerSubmit = async (jobTodoTemplates: NodeType.JobTodoTemplate[]) => {
        try {
            const assocsListLength = fromEdges(workOrderTemplate.jobTodoTemplateAssocs).length;
            const newAssocs = jobTodoTemplates.map<NodeType.UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateInput>(
                // @ts-ignore
                (jobTemplate, index) => {
                    return {
                        isSuggestion: false,
                        priority: assocsListLength + index,
                        jobTodoTemplate: { id: jobTemplate.id },
                    };
                }
            );
            await templateMutation.update({
                id: workOrderTemplate.id,
                jobTodoTemplateAssocs: {
                    create: newAssocs,
                },
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const isGlobal = !Boolean(workOrderTemplate.ownedByServiceJobTemplate?.id);

    const button = isGlobal ? (
        <WorkOrderRemoveConfirmButton onClick={() => onRemove(workOrderTemplate)} />
    ) : (
        <WorkOrderDeleteConfirmButton onClick={() => onRemove(workOrderTemplate)} />
    );

    return (
        <div className={'grid gap-2'}>
            <SectionHeader icon={ModuleIconNames.WorkOrder} button={button}>
                Work Order Template {isGlobal ? ' - TEMPLATE' : ''}
            </SectionHeader>

            <WorkOrderTemplateDetails
                onRemove={onRemove}
                templateMutation={templateMutation}
                workOrderTemplate={workOrderTemplate}
            />

            <JobTodoTemplateAssocsList
                jobTaskTemplatesEnabled={modulesAccess.FieldServices?.serviceJobTaskTemplates}
                templateMutation={templateMutation}
                workOrderTemplate={workOrderTemplate}
                refetchQuery={refetchQuery}
                handleOnTemplatePickerSubmit={handleOnTemplatePickerSubmit}
            />
        </div>
    );
};
