import * as React from 'react';
import { useState } from 'react';
import { NodeType, QueryFranchiseConnectionExternalProps, useQueryFranchiseConnection } from '@poolware/api';
import { FormikSuggestInputProps, SuggestInput, withFormikSuggestField } from '@ez/components';

interface External {
    search?: QueryFranchiseConnectionExternalProps;
}

export const SuggestInputFranchise: React.FC<FormikSuggestInputProps<NodeType.Franchise> & External> = ({
    value,
    onChange,
    label,
    isClearable = true,
    search,
}) => {
    const [acSearchQuery, setAcSearchQuery] = useState<string>('');
    const { connectionData, connectionState } = useQueryFranchiseConnection({ ...search, searchName: acSearchQuery });

    const handleOnSearchChange = (value: string) => {
        setAcSearchQuery(value);
    };

    const onItemSelect = (item: NodeType.Franchise) => {
        onChange(item);
    };

    const itemRenderer = (item: NodeType.Franchise) => {
        return item ? `${item?.name || '--'}` : '';
    };

    const formatOptionLabel = (o: { item: NodeType.Franchise }, ctx: any) => {
        if (!o.item) return '--';
        return (
            <span>
                <b>{o.item.organisationType?.name}</b> - {o.item?.name || '--'}
            </span>
        );
    };

    return (
        <SuggestInput
            isClearable={isClearable}
            value={value}
            items={connectionData}
            loading={connectionState.loading}
            onItemSelect={onItemSelect}
            onQueryChange={handleOnSearchChange}
            formatOptionLabel={formatOptionLabel}
            itemTextLabel={itemRenderer as any}
            label={label}
        />
    );
};

export const FormikSuggestInputFranchise = withFormikSuggestField<External>(SuggestInputFranchise);
