import * as React from 'react';
import { useState } from 'react';
import { Panel, useModalCtrl } from '@ez/components';
import { CustomerDetails } from './CustomerDetails';
import NewCustomer from './NewCustomer';
import { NodeType } from '@poolware/api';
import { CustomerPicker } from '@poolware/app-shell';
import { Button, Modal } from '@ez/components';

const FindCustomerButton: React.FC<{ onCustomerSelect: (customerId: string) => any }> = ({ onCustomerSelect }) => {
    const modalCtrlSearch = useModalCtrl(false);

    const handleSubmit = ({ customer }) => {
        onCustomerSelect?.(customer.id);
        modalCtrlSearch.onClose();
    };

    return (
        <>
            <Button
                size={'md'}
                className={'w-full'}
                variant={'secondary'}
                icon={'address book outline'}
                content={'Find Existing Customer'}
                onClick={modalCtrlSearch.onOpen}
            />
            <Modal closeOnDimmerClick={true} size={'small'} {...modalCtrlSearch}>
                <CustomerPicker onCancel={modalCtrlSearch.onClose} onSubmit={handleSubmit} />
            </Modal>
        </>
    );
};

interface NewCustomerButtonProps {
    site: NodeType.Site;
    onCustomerSelect: (customerId: NodeType.ID) => any;
}

const NewCustomerButton: React.FC<NewCustomerButtonProps> = ({ site, onCustomerSelect }) => {
    const [showFrom, setShowForm] = useState(false);

    const handleOnClick = () => {
        setShowForm(true);
    };

    const handleOnSubmit = (customerId: NodeType.ID) => {
        onCustomerSelect(customerId);
        setShowForm(false);
    };

    return (
        <>
            <Button
                size={'md'}
                className={'w-full'}
                icon={'plus'}
                content={'Register New Customer'}
                onClick={handleOnClick}
            />
            <Modal open={showFrom} closeOnEscape={false} centered={false}>
                <NewCustomer
                    defaultAddress={site.address}
                    onCancel={() => setShowForm(false)}
                    onSubmit={handleOnSubmit}
                />
            </Modal>
        </>
    );
};

interface NewCustomerSelectPanelProps {
    site: NodeType.Site;
    onCustomerSelect: (customerId: NodeType.ID) => any;
}

export const NewCustomerSelectPanel: React.FC<NewCustomerSelectPanelProps> = ({ site, onCustomerSelect }) => {
    const [newOwnerId, setNewOwnerId] = useState(null);

    const handleOnCustomerSelect = (customerId: NodeType.ID) => {
        //console.log(customer);
        setNewOwnerId(customerId);
        onCustomerSelect(customerId);
    };

    const onCustomerRemove = () => {
        setNewOwnerId(null);
        onCustomerSelect(null);
    };

    const buttons = [];
    if (newOwnerId) {
        buttons.push({
            content: 'Remove',
            icon: 'delete',
            onClick: onCustomerRemove,
        });
    }

    return (
        <Panel>
            <Panel.Header icon="user" button={buttons}>
                TO: New Owner
            </Panel.Header>
            <Panel.Body>
                {!newOwnerId && (
                    <>
                        <FindCustomerButton onCustomerSelect={handleOnCustomerSelect} />
                        <Panel.Divider />
                        <NewCustomerButton site={site} onCustomerSelect={handleOnCustomerSelect} />
                    </>
                )}

                {newOwnerId && (
                    <>
                        <CustomerDetails customerId={newOwnerId} />
                    </>
                )}
            </Panel.Body>
        </Panel>
    );
};
