export type LifeGuardModeType = 'SCHEDULE';

export enum LifeguardTimerDays {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export interface LifeGuardTimerType {
    pumpSpeed: number;
    days: LifeguardTimerDays[];
    from: { hour: number; minute: number };
    to: { hour: number; minute: number };
}

export type LifeGuardShadowType = {
    phSensorValue?: number | null;
    phSetpoint?: number | null;
    phSensorConnected?: boolean | null;
    temperatureSensorValue?: number | null;
    temperatureSensorConnected?: boolean | null;
    temperatureSetpoint?: number | null;
    orpSensorValue?: number | null;
    orpSensorConnected?: boolean | null;
    orpSetpoint?: number | null;
    cellOutput?: number | null;
    firmwareVersion?: string | null;
    poolSize?: number | null;
    boostDuration?: number | null;
    backwashDuration?: number | null;
    activeAlarmCount?: number | null;
    modes?: LifeGuardModeType[];

    timer0?: LifeGuardTimerType;
    timer1?: LifeGuardTimerType;
};

export type DeviceStateType<ShadowType = any> = {
    actionQueue: any[];
    desired: Partial<ShadowType>;
    reported: ShadowType;
    pending: Partial<ShadowType>;
    isAlive: boolean;
    heartbeatTimestamp: number;
};
