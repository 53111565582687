import * as React from 'react';
import { useMemo } from 'react';
import { useCalendarPageContext } from '../utils/CalendarPageContext';
import { WorkOrdersTable } from './WorkOrdersTable';
import { styled } from 'twin.macro';
import { useWorkOrderFeedActions } from '../../redux';
import { SortDirection } from '@ez/api-core';
import { useQueryWorkOrdersConnection } from '../../queries/use-query-work-orders-connection';
import { NodeType } from '@poolware/api';
import { StageFilterButtons } from '../../components/ActionBarFilters/ActionBarFilterByServiceJobStageType';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import {
    PanelNavigationStack,
    PanelNavigationStackProvider,
    usePanelNavigationStack,
} from '../Components/PanelNavigationStack';
import { AppErrorBoundary } from '@poolware/app-shell';
import { Icon, MenuBar } from '@ez/components';
import { SectionHeader } from '@ez/components';

const ContainerDiv = styled.div`
    .draggable {
        opacity: 50%;
        background-color: rgba(255, 225, 242, 0.5);
    }
`;

const WorkOrderPreview = () => {
    const panelStack = usePanelNavigationStack();

    const onBack = () => {
        panelStack.popStackPanel(true);
    };

    return (
        <div>
            <MenuBar.Root
            // size={'tiny'}
            >
                <MenuBar.Section>
                    <MenuBar.Item onClick={onBack} icon={'chevron left'} />
                    <MenuBar.HeaderItem>
                        <Icon color={ModuleColorNames.WorkOrder} name={ModuleIconNames.WorkOrder} />
                        Work Order Details
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
            </MenuBar.Root>
            <div>Todo</div>
        </div>
    );
};

const WorkOrdersContent = () => {
    // const panelStack = usePanelNavigationStack();

    const { State, Action } = useWorkOrderFeedActions();
    const { connectionData, connectionState } = useQueryWorkOrdersConnection({
        hasAppointment: false,
        sortKey: 'updatedAt',
        defaultSortDirection: SortDirection.DES,
        stageType: State.filters.serviceStageType,
    });
    const { setDragFromOutsideItem } = useCalendarPageContext();
    const handleDragStart = (item: NodeType.WorkOrder, event: React.DragEvent<HTMLDivElement>) => {
        // @ts-ignore
        event.target?.classList?.add('draggable');
        setDragFromOutsideItem(item);
    };

    const handleDragEnd = (event) => {
        event.target?.classList?.remove('draggable');
        setDragFromOutsideItem(null);
    };

    const onDetails = (item?: NodeType.WorkOrder) => {
        // panelStack.pushStackPanel({
        //     component: WorkOrderPreview,
        //     name: AppointmentEditMode.Single,
        //     props: { item: NodeType.WorkOrder },
        // });
    };

    return (
        <ContainerDiv className={'text-sm'}>
            <div>
                <SectionHeader>Unscheduled Work Orders</SectionHeader>
                <StageFilterButtons
                    color={ModuleColorNames.WorkOrder}
                    setServiceJobStageType={Action.setServiceStageType}
                    setServiceJobStageStatus={Action.setServiceStageStatus}
                    valueStageTypes={State.filters.serviceStageType}
                    valueStageStatus={State.filters.serviceStageStatus}
                />
            </div>
            <div className={'text-gray-500 text-sm px-1 py-2'}>Drag and drop work orders to calendar</div>
            <div className={'overflow-y-auto overflow-x-auto text-sm'}>
                <WorkOrdersTable
                    onView={onDetails}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    connectionData={connectionData}
                    connectionState={connectionState}
                />
            </div>
        </ContainerDiv>
    );
};

export const WorkOrders = WorkOrdersContent;

// TODO: unfinished feature.
export const WorkOrdersStack: React.FC = () => {
    const root = useMemo(() => {
        return {
            component: WorkOrdersContent,
            name: 'work-orders',
            props: {},
        };
    }, []);

    return (
        <AppErrorBoundary>
            <PanelNavigationStackProvider>
                <PanelNavigationStack root={root} stackId={'1'} />
            </PanelNavigationStackProvider>
        </AppErrorBoundary>
    );
};
