import * as React from 'react';
import { FormField, InputWithAction } from '@ez/components';
import { Field, useFormikContext } from 'formik';

import { FormikFormFieldLabel, Modal, stringFormatters, useModalCtrl } from '@ez/components';
import { NodeType } from '@poolware/api';
import CustomerPicker from './Pickers/CustomerPicker';

interface CustomerFieldProps {
    name: string;
    label?: string;
    // onPoolSelect?: (pool: NodeType.Pool) => any;
    required?: boolean;
    onRemove?: () => any;
    readOnly?: boolean;
}

export const FormikCustomerLookupField: React.FC<CustomerFieldProps> = ({
    name,
    label,
    required,
    onRemove,
    readOnly,
}) => {
    const { setFieldValue } = useFormikContext();

    const modalCtrlSearch = useModalCtrl(false);
    const modalCtrlNew = useModalCtrl();

    const onNewCustomer = () => {
        modalCtrlSearch.onClose();
        modalCtrlNew.onOpen();
    };

    const onPoolSelect = (pool: NodeType.Pool) => {
        setFieldValue('pool', pool);
        if (pool && pool.address) {
            setFieldValue('address', pool.address);
        } else {
            setFieldValue('address', null);
        }
    };

    return (
        <>
            <Field name={name}>
                {({ field, form }) => {
                    const hasError = form.errors[field.name] && form.touched[field.name];

                    const handleRemove = () => {
                        form.setFieldValue(field.name, null);
                        onRemove?.();
                    };

                    const handlePickerSubmit = ({ customer, pool }) => {
                        modalCtrlSearch.onClose();

                        // remove previous customer first.
                        handleRemove();
                        // set new customer;
                        form.setFieldValue(field.name, customer);
                        // if Pool is provided, set pool as well
                        onPoolSelect(pool);
                    };

                    const openPicker = modalCtrlSearch.onOpen;

                    return (
                        <FormField>
                            <FormikFormFieldLabel
                                readOnly={readOnly}
                                label={label}
                                name={name}
                                required={required}
                                htmlFor={'customer-id'}
                            />
                            <InputWithAction
                                id={'fieldId'}
                                actionIcon={'address book outline'}
                                actionLabel={'Find'}
                                value={stringFormatters.formatEntityName(field.value, '--')}
                                hasError={hasError}
                                isClearable={!!field.value}
                                onClear={handleRemove}
                                onClick={openPicker}
                                readOnly={readOnly}
                            />
                            <Modal closeOnDimmerClick={true} size={'small'} {...modalCtrlSearch}>
                                <CustomerPicker
                                    onCancel={modalCtrlSearch.onClose}
                                    onSubmit={handlePickerSubmit}
                                    // onNewCustomer={onNewCustomer}
                                />
                            </Modal>
                            <Modal closeOnDimmerClick={false} size={'small'} {...modalCtrlNew}>
                                <CustomerPicker onCancel={modalCtrlNew.onClose} onSubmit={handlePickerSubmit} />
                            </Modal>
                        </FormField>
                    );
                }}
            </Field>
        </>
    );
};
