import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Button, MenuBar, PageLayout, StickyMenuBar, toastError } from '@ez/components';
import {
    BottomButtonContainer,
    Frame,
    PhotoAttachmentGroup,
    PhotoAttachmentType,
    photoGroupTypeToLabel,
    SectionHeader,
} from '../components';
import { useMobileAppointmentCtx } from '../Root';
import { fromEdges, NodeType } from '@poolware/api';
import 'yet-another-react-lightbox/styles.css';
import { confirmModalImperative } from '@ez/components';

export const PhotoAttachmentsGrid = () => {
    const { workOrder, workOrderMutator } = useMobileAppointmentCtx();

    const onFileDidUpload = async (fileId: NodeType.ID, type: PhotoAttachmentType) => {
        try {
            await workOrderMutator.addFiles(workOrder, [fileId]);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to upload file', description: e.message });
        }
    };

    const onDeleteFiles = async (filesToDelete: NodeType.FileUpload[]) => {
        try {
            const res = await confirmModalImperative({
                confirmMessage: {
                    header: 'Delete Selected Files',
                    content: 'Are you sure you want to delete the selected files?',
                },
                negative: true,
                confirmButton: {
                    content: 'Delete Files',
                    negative: true,
                    icon: 'trash',
                },
            });
            if (!res) {
                return;
            }
            await workOrderMutator.removeFile(workOrder, filesToDelete);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete files', description: e.message });
        }
    };

    const files = fromEdges(workOrder?.files);

    return (
        <Frame>
            <SectionHeader>Attachments</SectionHeader>
            <PhotoAttachmentGroup
                files={files}
                label={photoGroupTypeToLabel[PhotoAttachmentType.BeforeService]}
                onFileDidUpload={(fileId) => onFileDidUpload(fileId, PhotoAttachmentType.BeforeService)}
                onDeleteFiles={onDeleteFiles}
            />
            <PhotoAttachmentGroup
                label={photoGroupTypeToLabel[PhotoAttachmentType.AfterService]}
                onFileDidUpload={(fileId) => onFileDidUpload(fileId, PhotoAttachmentType.AfterService)}
                onDeleteFiles={onDeleteFiles}
            />
            <PhotoAttachmentGroup
                label={photoGroupTypeToLabel[PhotoAttachmentType.Other]}
                onFileDidUpload={(fileId) => onFileDidUpload(fileId, PhotoAttachmentType.Other)}
                onDeleteFiles={onDeleteFiles}
            />
        </Frame>
    );
};

export interface PhotoPickerProps {}

export const PhotoPicker: React.FC<PhotoPickerProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const onGoBack = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    return (
        <PageLayout width={'screen-sm'} className={'text-[16px]'}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item icon={'chevron left'} onClick={onGoBack} />
                    <MenuBar.HeaderItem content={'Photos'} />
                </MenuBar.Section>
                <MenuBar.Section position={'right'}></MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.BodySection>
                <PhotoAttachmentsGrid />
            </PageLayout.BodySection>
            <BottomButtonContainer>
                <Button
                    size={'lg'}
                    content={'Back'}
                    className={'w-full h-[48px] border-2 rounded-none'}
                    onClick={onGoBack}
                />
            </BottomButtonContainer>
        </PageLayout>
    );
};
