import styled from 'styled-components';
import * as React from 'react';
import { Icon, SemanticCOLORS, SemanticICONS } from '@ez/components';
import { useDndRow } from './table-dnd-helpers';

const debugBorder = `
    border-color: 'red';
    border-width: 0.5px;
    border-style: solid;
`;

const segment = (props: { basic?: boolean }) => {
    if (props?.basic) {
        return null;
    }

    return `
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
`;
};

export const TabContainer = styled.div`
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 250px auto;

    flex-direction: row;
    align-items: flex-start;

    @media screen and (max-width: 720px) {
        grid-template-columns: auto;
        flex-direction: column;
        align-items: stretch;
    }
`;

export const TabPaneContainer = styled.div`
    display: block;
    flex: 3;
    padding: 8px;

    border-radius: 4px;
    background-color: #ffffff;

    ${segment}
`;

export const TabMenuContainer = styled.div<{ basic?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8px;
    min-width: 200px;

    border-radius: 4px;
    background-color: #ffffff;

    ${segment}
`;

export const TabMenuItemSeparator = styled.div`
    display: flex;
    border-bottom: #eeeeee 1px solid;
    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    &:last-child,
    &:first-child {
        display: none;
    }
`;

export const TabMenuItemContainer = styled.a`
    display: flex;
    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    justify-content: stretch;
    align-items: center;

    border-radius: 4px;
    overflow-clip: true;

    font-size: 0.95rem;
    padding: 0.5rem;

    &.active {
        background-color: #dedede;
        border-right: 5px solid #444444;
    }

    &:hover {
        //background-color: #eaeaea;
    }

    & > .menu-item-drag {
        cursor: grab;
        flex-grow: 0;
        flex-basis: 1rem;
    }
`;

const TabMenuItemLabel = styled.div`
    flex-grow: 2;
    &:hover {
        text-decoration: underline;
    }
`;

const TabMenuItemIcon = styled.div`
    width: 20px;
`;

interface TabMenuItemDraggableProps {
    onClick?: any;
    iconName: SemanticICONS;
    iconColor: SemanticCOLORS;
    moveRow: (dragIndex: number, hoverIndex: number) => any;
    dropRow: (item) => any;
    label: any;
    active: boolean;
    index;
}

export const TabMenuItemDraggable: React.FC<TabMenuItemDraggableProps> = (props) => {
    const { active, iconColor, iconName, label, index, moveRow, dropRow } = props;

    const { isDragging, dragRef, previewRef } = useDndRow({ index, moveRow, dropRow, type: 'TabMenuItem' });

    const handleOnClick = (e) => {
        e.preventDefault();
        props.onClick?.();
    };

    const className = active ? 'active' : undefined;

    return (
        <TabMenuItemContainer
            href={'#'}
            ref={previewRef}
            onClick={handleOnClick}
            className={className}
            style={{
                opacity: isDragging ? 0.1 : 1,
            }}
        >
            <div ref={dragRef} className={'menu-item-drag'}>
                <Icon name={'ellipsis vertical'} color={'grey'} />
            </div>
            <TabMenuItemIcon>
                <Icon name={iconName} color={iconColor} />
            </TabMenuItemIcon>
            <TabMenuItemLabel>{label}</TabMenuItemLabel>
        </TabMenuItemContainer>
    );
};

export const TabMenuNewItemContainer = styled.a`
    font-size: 0.85em;
    color: #444444;
    display: flex;
    justify-content: stretch;
    align-items: center;
    border-radius: 4px;
    overflow-clip: true;
    font-weight: 100;
    padding: 0.5rem;
`;

export const TabMenuItemAddNew: React.FC<{
    onClick?: any;
    label: any;
}> = ({ onClick, label }) => {
    const handleOnClick = (e) => {
        e.preventDefault();
        onClick?.();
    };

    return (
        <TabMenuNewItemContainer href={'#'} onClick={handleOnClick}>
            <TabMenuItemIcon>
                <Icon name={'plus'} />
            </TabMenuItemIcon>
            <TabMenuItemLabel>{label}</TabMenuItemLabel>
        </TabMenuNewItemContainer>
    );
};
