import * as React from 'react';
import { MenuBarItem, MenuBarItemWithConfirmProps } from './MenuBarItem';
import {
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarDropdownItemWithConfirmProps,
    MenuBarDropdownProps,
} from './MenuBarDropdown';
import { useAppBreakpoints } from '../use-app-breakpoints';

export interface MenuBarGroupItemProps extends MenuBarItemWithConfirmProps {
    render?: React.ReactNode;
    responsive?: boolean;
}

export enum MenuBarGroupMODE {
    DROPDOWN,
    DROPDOWN_ON_MOBILE,
    DROPDOWN_ON_TABLET,
    EXPANDED,
}

export interface MenuBarGroupConfType extends MenuBarDropdownProps {
    menuMode?: MenuBarGroupMODE;
}

export type MenuBarGroupItemConfType = MenuBarGroupItemProps | undefined | false;

export interface MenuBarGroupProps {
    menu: MenuBarGroupConfType;
    items: MenuBarGroupItemConfType[];
}

export const MenuBarGroup: React.FC<MenuBarGroupProps> = ({ menu, items }) => {
    const bp = useAppBreakpoints();

    if (!menu) {
        return <>No menu property</>;
    }
    if (!items) {
        return <>No items property</>;
    }

    // @ts-ignore
    const validItems: MenuBarGroupConfType[] = items.filter((item) => {
        return !(!item || Object.keys(item).length === 0);
    });

    if (validItems.length === 0) {
        return null;
    }

    if (menu.menuMode === MenuBarGroupMODE.EXPANDED) {
        return <RenderFlat menu={menu} items={validItems} />;
    } else if (
        menu.menuMode === MenuBarGroupMODE.DROPDOWN ||
        (menu.menuMode === MenuBarGroupMODE.DROPDOWN_ON_MOBILE && bp.isMobile) ||
        (menu.menuMode === MenuBarGroupMODE.DROPDOWN_ON_TABLET && !bp.gteDesktopSM)
    ) {
        return <RenderDropdown menu={menu} items={validItems} />;
    } else {
        return <RenderFlat menu={menu} items={validItems} />;
    }
};

interface RenderProps {
    menu: MenuBarGroupConfType;
    items: MenuBarGroupItemProps[];
}

const RenderDropdown: React.FC<RenderProps> = ({ menu, items }) => {
    return (
        <MenuBarDropdown responsive={false} {...menu}>
            {items.map((item, index) => {
                if (item.render) {
                    return <React.Fragment key={index}>{item.render}</React.Fragment>;
                }
                // TODO: FIX typescript typings
                const typedItem = item as unknown as MenuBarDropdownItemWithConfirmProps;
                return <MenuBarDropdownItemWithConfirm key={index} responsive={false} {...typedItem} />;
            })}
        </MenuBarDropdown>
    );
};

const RenderFlat: React.FC<RenderProps> = ({ menu, items }) => {
    return (
        <>
            {items.map((item, index) => {
                if (item.render) {
                    return (
                        <div className={'flex flex-row'} key={index}>
                            {item.render}
                        </div>
                    );
                }
                return <MenuBarItem responsive={false} key={index} {...item} />;
            })}
        </>
    );
};
