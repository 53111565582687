import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Button, PageLayout, SectionHeader, SlideInDock, useModalCtrl, VStack } from '@ez/components';
import moment from 'moment';
import { NodeType } from '@poolware/api';
import { TableAppointments } from './TableAppointments';
import { useQueryAppointmentItemsForServiceJob } from './use-query-appointment-items-connection';
import * as URLBuilder from '../../../Scheduler/url-builder';
import { useCalendarActions } from '../../../redux';
import { AppointmentViewDock, useAppointmentViewCtx } from '../../../Scheduler';

export const AppointmentsListLoadable = ({ onView, serviceJob, forward }) => {
    const startDate = forward ? moment().startOf('day').toDate() : moment().subtract(1, 'day').endOf('day').toDate();

    const { connectionData, connectionState, loadMore } = useQueryAppointmentItemsForServiceJob({
        serviceJobId: serviceJob.id,
        startDate: startDate,
        pageSize: 30,
        reverse: !forward,
    });

    const hasNext = connectionState?.pageInfo?.hasNextPage;

    if (connectionData.length === 0) {
        return (
            <div>
                <small>
                    <i>No appointments</i>
                </small>
            </div>
        );
    }

    return (
        <>
            <TableAppointments appointmentItems={connectionData} onView={onView} />

            {hasNext && (
                <Button
                    size={'small'}
                    className={'w-full'}
                    loading={connectionState.isFetchingMore}
                    content={'Load more...'}
                    onClick={() => loadMore({ forward: forward })}
                />
            )}
        </>
    );
};

export const PanelAppointments: React.FC<{ serviceJob: NodeType.ServiceJob }> = ({ serviceJob }) => {
    const { AppNavigator } = useAppNavigator();
    const { CalendarAction } = useCalendarActions();
    const modalCtrl = useModalCtrl();
    const previewItemCtx = useAppointmentViewCtx();
    const onPreview = (appointment: NodeType.AppointmentItem) => {
        previewItemCtx.setAppointmentItemId(appointment?.id);
        modalCtrl.onOpen();
    };
    const onClose = () => {
        previewItemCtx.setAppointmentItemId(null);
        modalCtrl.onClose();
    };

    const onEdit = (appointment: NodeType.AppointmentItem) => {
        onClose();
        AppNavigator.navigate(URLBuilder.Scheduler.edit(appointment.id), {
            setOrigin: true,
            modal: true,
            state: {
                appointmentItem: appointment,
            },
        });
    };

    const onShowInCalendar = (item: NodeType.AppointmentItem) => {
        CalendarAction.showAppointmentInCalendar(item);
    };

    return (
        <VStack>
            <PageLayout.TwoColumns mobileColReverse={true}>
                <div>
                    <SectionHeader>Past Appointments</SectionHeader>
                    <AppointmentsListLoadable forward={false} onView={onPreview} serviceJob={serviceJob} />
                </div>
                <div>
                    <SectionHeader>Future Appointments</SectionHeader>
                    <AppointmentsListLoadable forward={true} onView={onPreview} serviceJob={serviceJob} />
                </div>
            </PageLayout.TwoColumns>
            {modalCtrl.open && (
                <SlideInDock onClose={onClose}>
                    <AppointmentViewDock
                        onClose={onClose}
                        appointmentItemId={previewItemCtx.appointmentItemId}
                        // onDidDelete={onDidDelete}
                    />
                </SlideInDock>
            )}
        </VStack>
    );
};
