import * as React from 'react';
import { useState } from 'react';
import { Button, CheckboxRadix, MenuBar, PageLayout, StickyMenuBar, Textarea } from '@ez/components';
import { BottomButtonContainer, Frame, SectionHeader } from '../components/components';
import { useAppNavigator } from '@poolware/react-app-navigator';

export const PageNewActionable: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const [isUrgent, setIsUrgent] = useState(false);
    const onGoBack = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    return (
        <PageLayout width={'screen-sm'} className={'text-[16px] mb-48'}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item icon={'chevron left'} onClick={onGoBack} />
                    <MenuBar.HeaderItem content={'Pool Cleaning'} />
                </MenuBar.Section>
                <MenuBar.Section position={'right'}></MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.BodySection className={'px-4'} vStack={true}>
                <Frame bordered={true}>
                    <div className={'flex flex-row justify-between items-center'}>
                        <div className={'rounded-full bg-accent px-2 text-accent-foreground'}>Maintenance</div>
                        <div className={'text-strong'}>Pool Cleaning</div>
                        <div className={'text-sm uppercase'}>Additional Works</div>
                    </div>
                </Frame>

                <SectionHeader>Additional Work Request</SectionHeader>
                <Frame>
                    <Frame bordered={true} tertiary={true}>
                        <div className={'text-strong'}>Description</div>
                        <div>
                            <Textarea placeholder={'Add description'} rows={6} />
                        </div>
                    </Frame>
                    <Frame bordered={true} tertiary={true}>
                        <div>
                            <strong>Add attachments</strong>
                        </div>
                        <Button icon={'plus'} size={'lg'}>
                            Add Attachments
                        </Button>
                    </Frame>
                    <Frame bordered={true} tertiary={true}>
                        <div>
                            <strong>
                                Please select urgency of the additional works request and document rationale.
                            </strong>
                        </div>
                        <div className={'w-full flex flex-row items-start gap-2'}>
                            <CheckboxRadix
                                id={'urgency'}
                                className={'size-[1.5rem] mt-0.5'}
                                checked={isUrgent}
                                onCheckedChange={(checked) => setIsUrgent(Boolean(checked))}
                            />
                            <label htmlFor={'urgency'}>Tag Works As Critical</label>
                        </div>
                        {isUrgent && (
                            <div>
                                <Textarea
                                    placeholder={
                                        'Describe urgency and potential impact of additional works if left unaddressed'
                                    }
                                    rows={6}
                                />
                            </div>
                        )}
                    </Frame>
                </Frame>
            </PageLayout.BodySection>

            <BottomButtonContainer>
                <Button
                    size={'lg'}
                    content={'Back'}
                    segmentPosition={'first'}
                    className={'w-full h-[48px] border-2 rounded-none'}
                    onClick={onGoBack}
                />
                <Button
                    size={'lg'}
                    variant={'primary'}
                    segmentPosition={'last'}
                    content={'Submit Request'}
                    className={'w-full h-[48px] border-2 rounded-none'}
                    onClick={onGoBack}
                />
            </BottomButtonContainer>
        </PageLayout>
    );
};
