import gql from 'graphql-tag';
import { QueryConnectionExternalProps, useDefaultConnectionState, useQueryConnection } from '@ez/api-core';
import { NodeType } from '@poolware/api';

export const fragmentEZCDeviceNode = gql`
    fragment EZCDeviceConexpFragment on EZCDevice {
        createdAt
        definition {
            definitionId
            definitionVersion
            familyId
            familyVariation
            id
            name
            openAPIURL
            presentationId
            setupStrategyType
        }
        link {
            id
            label
            owner {
                id
                crn
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
        deviceId
        id
        title
        version
    }
`;

export const createGqlQueryEzcDevicesConnection = (_nodeFragment = fragmentEZCDeviceNode) => gql`
    query QueryEzcDevicesConnection(
        $after: String
        $before: String
        $first: Int
        $franchise: ID
        $last: Int
        $page: Int
    ) {
        viewer {
            ezcDevices(after: $after, before: $before, first: $first, franchise: $franchise, last: $last, page: $page) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }

                edges {
                    node {
                        ...EZCDeviceConexpFragment
                    }
                }
            }
        }
    }
    ${_nodeFragment}
`;

const QueryEzcDevicesConnectionGql = createGqlQueryEzcDevicesConnection(fragmentEZCDeviceNode);

export interface QueryEzcDeviceConnectionProps extends QueryConnectionExternalProps<NodeType.EZCDevice> {}

export function useQueryEzcDeviceConnection(props?: QueryEzcDeviceConnectionProps) {
    const connectionPath = 'viewer.ezcDevices';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 20,
        // search: mapPropsToSearchQuery(props),
        ...props,
    });

    return useQueryConnection({
        query: QueryEzcDevicesConnectionGql,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
