import * as React from 'react';
import { Button } from '@ez/components';
import { useRecurServiceJobListActions } from '../../redux/reducer-recur-service-job-list';

export const ClearAllFilters = () => {
    const { Actions } = useRecurServiceJobListActions();

    const hasActiveFilters = false;
    return (
        <div className={'p-1'}>
            <Button
                className={'w-full'}
                size={'tiny'}
                icon={'close'}
                color={hasActiveFilters ? 'purple' : 'grey'}
                onClick={() => {
                    Actions.clearAllFilters();
                }}
                content={'Clear all filters'}
            />
        </div>
    );
};
