import * as React from 'react';
import { useMemo } from 'react';
import {
    ConnectionErrorMessage,
    DebugJsonButton,
    FormikDefaultFormBase,
    FormikFormButtons,
    FormikFormDebug,
    MenuBar,
    PageLayout,
    PageSkeletonLoader,
    StickyMenuBar,
    toastError,
    VStack,
} from '@ez/components';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationAbstractDocument } from '@poolware/api';
import { useQueryInvoiceDoc } from '../queries/use-query-abstract-doc';
import { NotFoundPage } from '@poolware/app-shell';
import * as _ from 'lodash';
import { FormEditInvoiceDoc } from './FormEditInvoiceDoc';
import styled from 'styled-components';
import { InvoiceFormValueType } from './types';
import { InvoiceLinkEntitiesPanel } from '../components/InvoiceLinkEntitiesPanel';

const ButtonsContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    padding: 0.5rem;
    margin-left: var(--sideBar, 180px);
`;

export const InvoicePageEdit: React.FC = () => {
    const { AppNavigator, params } = useAppNavigator();
    const { loading, error, node: doc, refetchQuery } = useQueryInvoiceDoc(params.id);
    const mutations = useMutationAbstractDocument({ refetchQueries: [refetchQuery] });

    const initialValues = useMemo<InvoiceFormValueType>(() => {
        if (loading) return undefined;
        if (!doc) return undefined;
        const { jsonBody, ...docMeta } = doc;
        return {
            ...jsonBody,
            docMeta,
        };
    }, [loading, doc]);

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !doc) {
        return <NotFoundPage />;
    }

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const { docMeta, ...jsonBody } = values;
            const invoiceNumber = jsonBody?.invoiceNumber;
            await mutations.update({
                id: doc.id,
                jsonBody: jsonBody,
                refNumber: invoiceNumber,
                issuedAt: jsonBody.issueDate,
            });
            AppNavigator.replaceToOrigin(`/${doc.id}`, { relativeToModule: true });
        } catch (e) {
            toastError(e);
        }
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin(`/${doc.id}`, { relativeToModule: true });
    };

    const { workOrder, serviceJob } = doc;
    const woTitle = _.truncate(workOrder?.title, { length: 40 });

    return (
        <FormikDefaultFormBase initialValues={initialValues} onSubmit={onSubmit}>
            <PageLayout
            // className={'relative'}
            >
                <StickyMenuBar>
                    <MenuBar.Section>
                        <MenuBar.Item
                            icon={'chevron left'}
                            title={'Cancel'}
                            onClick={() => AppNavigator.navigateToOrigin('/', { relativeToModule: true })}
                        />
                        <MenuBar.HeaderItem color={ModuleColorNames.Invoice} icon={ModuleIconNames.Invoice}>
                            Edit Invoice - {doc.refNumber}
                        </MenuBar.HeaderItem>
                    </MenuBar.Section>
                    <MenuBar.Section position={'right'}>
                        <DebugJsonButton data={doc} />
                    </MenuBar.Section>
                </StickyMenuBar>

                <PageLayout.ThreeColumnManaged
                    main={
                        <PageLayout.BodySection width={'screen-lg'} className={'mb-32'}>
                            <FormEditInvoiceDoc />
                        </PageLayout.BodySection>
                    }
                    layoutColumns={3}
                    showLeft={false}
                    showRight={true}
                    rightSidebar={
                        <VStack>
                            <InvoiceLinkEntitiesPanel doc={doc} />
                            <FormikFormDebug hidden={true} />
                        </VStack>
                    }
                />
            </PageLayout>
            <ButtonsContainer>
                <div className={'shadow-xl border border-solid rounded-lg border-gray-400 bg-yellow-50 px-6 py-4'}>
                    <FormikFormButtons
                        activateOnDirty={true}
                        submitButton={{ content: 'Save', icon: 'save' }}
                        handleCancel={onCancel}
                    />
                </div>
            </ButtonsContainer>
        </FormikDefaultFormBase>
    );
};
