import {
    FormikCheckboxField,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    Panel,
    toastError,
    Modal,
} from '@ez/components';
import * as React from 'react';
import { useState } from 'react';
import { NodeType, useMutationWorkOrderTemplate } from '@poolware/api';

export const WorkOrderDetailsForm: React.FC<any> = ({ onCancel, onSubmit, refetchQuery, workOrderTemplate }) => {
    const { update } = useMutationWorkOrderTemplate({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    const initialValues = {
        ...workOrderTemplate,
    };

    const onUpdate = async (values: typeof initialValues) => {
        try {
            await update({
                id: workOrderTemplate.id,
                templateTitle: values.templateTitle?.trim() || '',
                templateDescription: values.templateDescription?.trim() || '',
                requiresAddress: Boolean(values.requiresAddress),
                requiresContact: Boolean(values.requiresContact),
                requiresCustomer: Boolean(values.requiresCustomer),
                requiresPool: Boolean(values.requiresPool),
                requiresStaff: Boolean(values.requiresStaff),
            });
            await onSubmit?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            header={'Service Template'}
            initialValues={initialValues}
            onSubmit={onUpdate}
            onCancel={onCancel}
        >
            <FormikInputField label={'Template Title'} name={'templateTitle'} />
            <FormikTextareaField label={'Template Description'} name={'templateDescription'} />

            {/*<Divider />*/}
            <h4>Required Fields</h4>
            <p>
                <i>
                    When new service case is being created from this template, the following data will be required to be
                    filled
                </i>
            </p>
            <FormikCheckboxField label={'Address is required'} name={'requiresAddress'} />
            <FormikCheckboxField label={'Pool is required'} name={'requiresPool'} />
            <FormikCheckboxField label={'Customer is required'} name={'requiresCustomer'} />
            <FormikCheckboxField label={'Contact is required'} name={'requiresContact'} />
        </FormikDefaultForm>
    );
};

export interface WorkOrderDetailsPanelProps {
    refetchQuery;
    workOrderTemplate: NodeType.WorkOrderTemplate;
}

export const WorkOrderDetailsPanel: React.FC<WorkOrderDetailsPanelProps> = ({ refetchQuery, workOrderTemplate }) => {
    const [edit, setEdit] = useState(false);

    return (
        <>
            <Panel>
                <Panel.Header
                    content={'Work Order Template'}
                    button={{ content: 'Edit', icon: 'edit', onClick: () => setEdit(true) }}
                />
                <Panel.Body>
                    <Panel.Item label={'Template Title'} content={workOrderTemplate.templateTitle} />
                    <Panel.ItemText label={'Template Description'} content={workOrderTemplate.templateDescription} />
                </Panel.Body>
            </Panel>
            <Modal open={edit} centered={false}>
                <WorkOrderDetailsForm
                    onCancel={() => setEdit(false)}
                    onSubmit={() => setEdit(false)}
                    refetchQuery={refetchQuery}
                    template={workOrderTemplate}
                />
            </Modal>
        </>
    );
};
