import * as React from 'react';
import { fromEdges, NodeType, TraitValueType } from '@poolware/api';
import { Table } from '@ez/components';
import * as _ from 'lodash';
import {
    flattenFromTree,
    flattenTraitValues,
    ProductTraitFlattened,
    unboxTreeNode,
    unflattenToTree,
} from '../../../../utils';
import { mapPrimitiveTypeToComponent } from '../../ProductTrait/ProductTraitInputFields';
import { FormBuilder, FormBuilderConfig } from '../../../../components/FormBuilder';
import * as Yup from 'yup';
import { IProductCatalogMutators } from '../../../../queries';

interface FlattenedTraitRows {
    name: string;
    flattenedTrait: ProductTraitFlattened;
    editMode: boolean;
}

const TraitValueRows: React.FC<FlattenedTraitRows> = ({ editMode, flattenedTrait, name }) => {
    const { trait, values, traitLevel } = flattenedTrait;

    const TraitCell = ({ rowSpan }) => (
        <Table.Cell rowSpan={rowSpan} verticalAlign={'top'}>
            <b>
                {'-'.repeat(traitLevel)} {_.get(trait, 'declaration.name')}
            </b>
        </Table.Cell>
    );

    if (values.length == 0) {
        return (
            <Table.Row key={trait.id}>
                <TraitCell rowSpan={1} />
                <Table.Cell disabled>-</Table.Cell>
                <Table.Cell disabled>-</Table.Cell>
                <Table.Cell disabled>-</Table.Cell>
                <Table.Cell disabled>-</Table.Cell>
            </Table.Row>
        );
    }

    return (
        <>
            {values.map((ff, i) => {
                const InputComp = mapPrimitiveTypeToComponent(ff.type);
                const extraProps: any = {};
                if (ff.type === TraitValueType.Flag) {
                    extraProps.label = 'enabled';
                    extraProps.checked = ff.formValue?.value;
                }
                if (ff.type === TraitValueType.Selection) {
                    const traitField = ff.traitField;
                    const opts = fromEdges(traitField.options).map((o: any) => ({
                        key: o.id,
                        value: o.id,
                        text: o.name,
                    }));
                    extraProps.options = opts;
                    extraProps.clearable = true;
                }

                return (
                    <Table.Row key={i}>
                        {i === 0 && <TraitCell rowSpan={values.length} />}
                        <Table.Cell>{ff.type}</Table.Cell>
                        <Table.Cell>{ff.name}</Table.Cell>
                        <Table.Cell>
                            {editMode ? (
                                <InputComp name={`${name}.values[${i}].formValue`} {...extraProps} />
                            ) : (
                                ff.printValue
                            )}
                        </Table.Cell>
                        {/*<Table.Cell>{ff.printValue !== undefined ? ff.printValue : '-'}</Table.Cell>*/}
                        <Table.Cell>{ff.traitField.unit}</Table.Cell>
                    </Table.Row>
                );
            })}
        </>
    );
};

const formConfig: FormBuilderConfig = {
    fields: [],
    validationSchema: Yup.object().shape({}),
};

export interface ProductTraitsFormProps extends IProductCatalogMutators {
    traits: NodeType.ProductTrait[];
    editMode: boolean;
    onDone: () => any;
}

export const ProductTraitsForm: React.FC<ProductTraitsFormProps> = ({
    traits,
    editMode,
    onDone,
    ProductCatalogMutator,
}) => {
    const declarationTree = traits.map((t) => ({
        id: t?.declaration?.id,
        name: t?.declaration?.name,
        parent: t?.declaration?.parent,
        flattenedTrait: flattenTraitValues(t),
    }));

    const tree = unflattenToTree(declarationTree);
    const sortedTraits = flattenFromTree(tree)
        .map(unboxTreeNode)
        .map((i) => i.flattenedTrait);

    const onCancel = () => {
        onDone && onDone();
    };

    const createSingleMutationConfig = (flattenedTrait: ProductTraitFlattened) => {
        const allValues = flattenedTrait.values.map((f) => {
            let value;
            switch (f.type) {
                case TraitValueType.Int:
                case TraitValueType.Float:
                    if (f.formValue === undefined) {
                        value = undefined;
                    } else if (f.formValue === '') {
                        value = null;
                    } else {
                        value = Number(f.formValue);
                    }
                    break;
                case TraitValueType.String:
                    const trimmed = _.trim(f.formValue);
                    value = trimmed ? trimmed : null;
                    break;
                case TraitValueType.Selection:
                    value = f.formValue ? f.formValue : null;
                    break;
                case TraitValueType.Flag:
                    value = Boolean(f.formValue);
                    break;
            }

            return {
                type: f.type,
                fieldId: f.traitField.id,
                valueId: f.traitValue ? f.traitValue.id : undefined,
                value: value,
            };
        });

        const toCreate = allValues.filter((f) => f.valueId === undefined && f.value);
        const toUpdate = allValues.filter((f) => f.valueId !== undefined && f.value !== null);
        const toDelete = allValues.filter((f) => f.valueId !== undefined && f.value === null);
        // console.log('toCreate', toCreate);
        // console.log('toUpdate', toUpdate);
        // console.log('toDelete', toDelete);

        return {
            productTrait: flattenedTrait.trait.id,
            createValues: toCreate,
            updateValues: toUpdate,
            deleteValues: toDelete,
        };
    };

    const onSubmit = async (values, actions) => {
        try {
            const { traits } = values;
            const configs = (traits as ProductTraitFlattened[]).map(createSingleMutationConfig);
            await ProductCatalogMutator.updateProductTraits(configs);

            onDone();
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e.message });
        }
    };

    return (
        <>
            <FormBuilder
                debug={false}
                hideButtons={!editMode}
                config={formConfig}
                onSubmit={onSubmit}
                onCancel={onCancel}
                initialValues={{
                    traits: sortedTraits,
                }}
            >
                {() => {
                    return (
                        <Table size={'small'} compact={'very'} celled structured unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign={'center'} rowSpan={2} width={4}>
                                        Trait
                                    </Table.HeaderCell>
                                    <Table.HeaderCell rowSpan={1} colSpan={4} textAlign={'center'}>
                                        Trait Fields
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell textAlign={'center'} width={2}>
                                        Type
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Trait Name</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'} width={4}>
                                        Value
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Misc.</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {sortedTraits.map((ft, index) => {
                                    return (
                                        <TraitValueRows
                                            key={index}
                                            name={`traits[${index}]`}
                                            flattenedTrait={ft}
                                            editMode={editMode}
                                        />
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    );
                }}
            </FormBuilder>
        </>
    );
};
