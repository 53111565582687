import * as React from 'react';
import { useRef } from 'react';
import { FieldArray, getIn } from 'formik';
import { fromEdges } from '@poolware/api';
import { Table } from '@ez/components';
import { TableRowViewOrEdit } from './TableRowViewOrEdit';
import { TableRowAddNew } from './TableRowAddNew';
import * as Yup from 'yup';

export interface FormikJobTodoItemsFieldsProps {
    name: string;
}

export const validationSchemaWorkOrderItems = Yup.array()
    .of(
        Yup.object({
            title: Yup.string().max(250).required('Required'),
        })
    )
    // .required('Required')
    // .min(3, 'Minimum 3 items')
    .max(100, 'Maximum 100 items');

export const FormikInputJobTodoItems: React.FC<FormikJobTodoItemsFieldsProps> = ({ name }) => {
    const inputRef = useRef<any>();

    const formatName = (fieldName: string) => {
        return `${name}${fieldName}`;
    };

    const onEditRowEnter = () => {
        inputRef.current.focus();
    };

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;

                const items = fromEdges<any>(getIn(form.values, name));

                const moveRow = (dragIndex, hoverIndex) => {
                    arrayHelpers.move(hoverIndex, dragIndex);
                };

                const appendItem = ({ title }) => {
                    arrayHelpers.push({
                        title,
                        id: Math.floor(Math.random() * 100000), // temporary ID
                        isNew: true,
                    });
                    form.validateField(name);

                    // IMPORTANT:
                    // Construct the full path to the `title` field, otherwise it will crash `onBlur` event.
                    // `Touching` the files triggers form validation.
                    form.setFieldTouched(formatName(`[${items.length}].title`));
                };

                return (
                    <Table unstackable={true} compact={true}>
                        <Table.Body>
                            {items.length === 0 && (
                                <Table.Row>
                                    <Table.Cell colSpan={3}>
                                        <i>No todo items</i>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {items.map((item, i) => {
                                return (
                                    <TableRowViewOrEdit
                                        fieldName={`${name}[${i}]`}
                                        key={item.id || i}
                                        index={i}
                                        onDelete={() => arrayHelpers.remove(i)}
                                        moveRow={moveRow}
                                        onEnter={onEditRowEnter}
                                        // dropRow={dropRow}
                                    />
                                );
                            })}
                        </Table.Body>
                        <Table.Footer>
                            <TableRowAddNew
                                ref={inputRef}
                                colSpan={3}
                                onAppendItem={appendItem}
                                name={formatName('-item-pending.title')}
                            />
                        </Table.Footer>
                    </Table>
                );
            }}
        />
    );
};
