import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionTableDef, IconWithPopup } from '@ez/components';
import { CostEstimationModel, MonthlyHeatGasProfile } from '../common/types';
import { GasHeaterModel } from '../data';
import { MonthlyDataTableBase } from '../common/MonthlyDataTableBase';

const IconCapacityWarning: React.FC = () => {
    return (
        <IconWithPopup
            popup={{
                header: 'Not enough capacity',
                content: 'Select heater with higher capacity, or increase heat up time',
            }}
            name={'warning sign'}
        />
    );
};

interface DataTableControlProp {
    selectedMonths: string[];
    setSelectedItems?: (newItems: any[]) => any;
    monthlyHeatProfiles: MonthlyHeatGasProfile[];
    costEstimation: CostEstimationModel<GasHeaterModel>;
    recommendedModel?: GasHeaterModel;
}

export const MonthlyDataTable: React.FC<DataTableControlProp> = ({
    selectedMonths,
    setSelectedItems,
    monthlyHeatProfiles,
    costEstimation,
}) => {
    const tableData = monthlyHeatProfiles.map((mp, index) => {
        const cost = costEstimation?.costByMonth[index];
        return {
            ...mp,
            cost: cost,
            id: mp.monthProfile.id,
            monthName: mp.monthProfile.name,
            days: mp.monthProfile.days,
            temp: mp.monthProfile.temp,
            model: costEstimation?.recommendedModel,
        };
    });

    const tableDef = useMemo(() => {
        const tableDef: ConnectionTableDef<(typeof tableData)[0]> = [
            {
                header: 'Month',
                cell: (pd) => pd.monthName,
            },
            // { header: 'Days', cell: (pd) => pd.days },
            { header: 'Ambient Temp', cell: (pd) => pd.temp },
            { header: 'Heat Up, MJ', cell: (pd) => pd.heatUpMJ?.toFixed(0) + ' MJ' },
            // { header: 'minimumOutputMJ, MJ', cell: (pd) => pd.minimumOutputMJ?.toFixed(0) },
            { header: 'Heat Running, MJ/Day', cell: (pd) => pd.heatRunningMJ?.toFixed(0) + ' MJ' },
            {
                header: 'Model Capacity',
                cell: (pd, i) => {
                    if (!pd.isSelected) return '--';
                    const m = pd.model;
                    if (!m) {
                        return '--';
                    }
                    const warn = m.capacity < pd.minimumOutputMJ;
                    return (
                        <span>
                            {m.capacity}
                            {warn && <IconCapacityWarning />}
                        </span>
                    );
                },
            },
            { header: 'Cost, $/Month', cell: (pd) => '$' + pd.cost?.costRunningMonthly?.toFixed?.(2) },
            { header: 'Cost, $/Day', cell: (pd) => '$' + pd.cost?.costRunningDaily?.toFixed?.(2) },
            // { header: 'Heat Up, $', cell: (pd) => '$' + pd.costToHeatUp?.toFixed(2) },
        ];
        return tableDef;
    }, [selectedMonths]);

    return (
        <MonthlyDataTableBase
            tableData={tableData}
            tableDefPartial={tableDef}
            selectedItems={selectedMonths}
            setSelectedItems={setSelectedItems}
        />
    );
};
