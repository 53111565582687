import * as React from 'react';
import { ConnectionTableDef, DefaultConnectionTable, Display, Icon, TruncatedDiv } from '@ez/components';
import { CustomerLink, PoolLink, StaffLink, WaterTestReportLink } from '../../../routes/Links';
import { useQueryWaterTestReportConnection } from '../../Queries/QueryWaterTestReportConnection';
import { AuthConnectProps, connectWithAuth, NodeType, SortDirection } from '@poolware/api';
import { endOfDay } from 'date-fns';

const tableDef: ConnectionTableDef<NodeType.TestReport> = [
    {
        header: 'Date',
        cellProps: {
            width: '2',
        },
        cell: (h) => <Display.Date value={h.createdAt} format={'llll'} />,
    },
    {
        header: 'Water Test',
        cell: (report) => {
            const pool = report?.pool;
            const customer = report?.reportTo?.user?.customer;
            if (!pool || !customer) {
                return '--';
            }

            return (
                <WaterTestReportLink reportId={report.id} customerId={customer.id} poolId={pool.id}>
                    Water Test Report
                </WaterTestReportLink>
            );
        },
    },
    {
        header: 'PDF Report',

        cell: (report) => {
            let pdfUrl = report?.pdfUrl;
            if (!pdfUrl) return null;

            return (
                <a target={'_blank'} href={pdfUrl} rel="noopener noreferrer">
                    Download <Icon name={'file pdf'} color={'red'} />
                </a>
            );
        },
    },
    {
        header: 'Pool',
        cellProps: {
            width: 2,
        },
        cell: (report) => {
            const pool = report?.pool;
            const customer = report?.reportTo?.user?.customer;
            if (!pool || !customer) {
                return '--';
            }

            return (
                <TruncatedDiv>
                    <PoolLink poolId={pool.id} customerId={customer.id}>
                        {pool.bottleNumber}
                    </PoolLink>
                    <> - {pool.name}</>
                </TruncatedDiv>
            );
        },
    },
    {
        header: 'Customer',
        cell: (report) => {
            const pool = report?.pool;
            const customer = report?.reportTo?.user?.customer;
            if (!pool || !customer) {
                return '--';
            }

            return (
                <TruncatedDiv>
                    <CustomerLink customerId={customer.id}>{customer.crn}</CustomerLink>
                    <span>
                        {' '}
                        - <Display.Entity value={customer} />
                    </span>
                </TruncatedDiv>
            );
        },
    },
    {
        header: 'Submitted By',
        cell: (r) => {
            const staff = r?.reportBy;
            if (!staff) {
                return '--';
            }

            return (
                <StaffLink staffId={staff.id}>
                    <Display.Entity value={staff} />
                </StaffLink>
            );
        },
    },
];

const ed = endOfDay(new Date());
const Page: React.FC<AuthConnectProps> = (props) => {
    // Add date range filter
    // Some legacy reports has timestamps that are in the future.
    // If sort by createAt, those reports will appear and pollute the result.
    // TODO: to fix timestamps in the database
    const { connectionState, connectionData } = useQueryWaterTestReportConnection({
        excludePageMeta: true,
        defaultSortKey: 'createdAt',
        defaultSortDirection: SortDirection.DES,
        filter: {
            createdBefore: ed,
        },
    });

    return (
        <DefaultConnectionTable
            tableProps={{ color: 'grey' }}
            connectionData={connectionData}
            connectionState={connectionState}
            tableDef={tableDef}
        />
    );
};

export default connectWithAuth()(Page);
