import { ConnectionTableDef } from '@ez/components';
import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Icon } from '@ez/components';
import { ModuleIconNames } from '../../../../constants';
import { TablePickerPanel } from '../../../../components/TablePickerPanel';
import { useQueryWorkOrderTemplateConnection } from '../../../WorkOrderTemplate/use-query-work-order-template-connection';

export interface WorkOrderTemplatePickerProps {
    onSubmit: (selectedItems: NodeType.WorkOrderTemplate[]) => any;
    onCancel?: () => any;
    selectedItems?: NodeType.WorkOrderTemplate[];
}

export const WorkOrderTemplatePicker: React.FC<WorkOrderTemplatePickerProps> = ({
    onSubmit,
    onCancel,
    selectedItems = [],
}) => {
    const { connectionState, connectionData } = useQueryWorkOrderTemplateConnection();
    const tableDef: ConnectionTableDef<NodeType.WorkOrderTemplate> = [
        {
            header: 'Template Name',
            cell: (item) => (
                <span>
                    <Icon name={ModuleIconNames.WorkOrderItem} />
                    {item.templateTitle}
                </span>
            ),
        },
        {
            header: 'Owner',
            width: 3,
            cell: (item) =>
                item.franchise ? (
                    item.franchise.name
                ) : (
                    <>
                        System <Icon name={'globe'} />
                    </>
                ),
        },
    ];

    return (
        <TablePickerPanel
            panelHeader={{ content: 'Work Order Template' }}
            onCancel={onCancel}
            onSubmit={onSubmit}
            hideItems={selectedItems}
            connectionState={connectionState}
            connectionData={connectionData}
            tableDef={tableDef}
        />
    );
};
