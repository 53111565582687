import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Portal } from '../semantic-ui';

const FullScreenContainer = styled.div<{ hideOnPrint?: boolean }>`
    --topBar: 0;
    --sideBar: 0;
    z-index: 900;
    position: fixed;
    overflow-x: hidden;
    overflow-y: hidden;
    overscroll-behavior: contain;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding-bottom: 10px;
    height: calc(100% - 10px);

    & .embedded-content {
        height: calc(100% - 10px);
    }

    @media print {
        display: ${({ hideOnPrint = false }) => (hideOnPrint ? 'none' : undefined)};
    }
`;

const BodyGlobalStyle = createGlobalStyle`
    &&& {
        body {
            overflow: hidden !important;
            user-select: none;
        }
    }
`;

const EmbeddedPageMenu: React.FC = ({ children }) => {
    return <div className={'sticky top-0 z-10 bg-tertiary p-2 rounded-t shadow'}>{children}</div>;
};

const EmbeddedPageBody: React.FC = ({ children }) => {
    return (
        <div className={'lg:mx-auto lg:px-8 w-full max-w-screen-xl p-4'}>
            <div className={'bg-panel rounded border border-gray-300 border-solid p-4'}>{children}</div>
        </div>
    );
};

export interface EmbeddedPageProps {
    fullScreen?: boolean;
    onClose?: () => any;
}

export class EmbeddedPage extends React.Component<EmbeddedPageProps> {
    public static Menu = EmbeddedPageMenu;
    public static Body = EmbeddedPageBody;

    render() {
        const { fullScreen, onClose, ...props } = this.props;
        if (!fullScreen) {
            return (
                <div data-testId={'embedded-page'} className={'bg-tertiary shadow-inner min-h-24 rounded'}>
                    <div className={'my-4'} {...props} />
                </div>
            );
        } else {
            return (
                <Portal open={true} closeOnEscape={true} onClose={this.props.onClose} closeOnDocumentClick={true}>
                    <FullScreenContainer
                        onClick={(e) => {
                            if (e.target == e.currentTarget) {
                                e.stopPropagation();
                                e.preventDefault();
                                this.props.onClose();
                            }
                        }}
                    >
                        <BodyGlobalStyle />
                        <div
                            className={
                                'embedded-content relative bg-gray-400 inset-4 overflow-y-auto flex flex-col max-w-screen-xl mx-auto rounded'
                            }
                        >
                            {props.children}
                        </div>
                    </FullScreenContainer>
                </Portal>
            );
        }
    }
}

//
// export interface EmbeddedPage extends React.Component<React.HTMLAttributes<HTMLDivElement>> {
//     Menu: typeof EmbeddedPageMenu;
//     Body: typeof EmbeddedPageBody;
// }
