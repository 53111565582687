import * as React from 'react';
import * as _ from 'lodash';
import { Field } from 'formik';
import { Button, ColorPillWithColorPicker, FormField, FormikFormFieldLabel } from '@ez/components';
import { IconButton } from '../../Components/IconButton';
import { ColorPalette } from '@poolware/api';

interface ColorFieldProps {
    name: string;
    label: string;
    required?: boolean;
    palette?: ColorPalette;
}

const ColorField: React.FC<ColorFieldProps> = ({ label, name, palette, required }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (newColor: string) => form.setFieldValue(field.name, newColor);
                const value = _.get(field, 'value', undefined);
                return (
                    <FormField>
                        <FormikFormFieldLabel label={label} name={name} required={required} />
                        <div className={'flex flex-row gap-1 flex-nowrap'}>
                            <ColorPillWithColorPicker value={value} palette={palette} onChange={handleChange} />
                            {value && (
                                <IconButton
                                    className={'p-2 h-input-base w-10'}
                                    data-testid={'ColorField-clear-button'}
                                    icon={'close'}
                                    onClick={() => handleChange(null)}
                                />
                            )}
                        </div>
                    </FormField>
                );
            }}
        </Field>
    );
};

export default ColorField;
