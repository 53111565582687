import * as React from 'react';
import {
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    Panel,
    StickyMenuBar,
    SuspenseSkeletonLoader,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryEmailMessage } from './use-query-email-message';
import { TemplateRendererFrame } from '../components/TemplateRendererFrame';
import { NodeType } from '@poolware/api';

export interface PageProps {
    emailId?: NodeType.ID;
}

export const EmailMessagePageView: React.FC<PageProps> = ({ emailId }) => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const id = emailId || params.id;
    const { node: email, error, loading } = useQueryEmailMessage(id, { skip: !id });

    const goBack = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    return (
        <SuspenseSkeletonLoader error={error} loading={loading}>
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back to list'} />
                        <MenuBarHeaderItem icon={'mail'}>Email Message</MenuBarHeaderItem>
                    </MenuBarSection>
                </StickyMenuBar>

                <Panel>
                    <Panel.Header content={'Email'} />
                    <Panel.Body>
                        <Panel.Item label={'Subject'} content={email?.subject} />
                        <Panel.Item label={'Type'} content={email?.type?.name} />
                        <Panel.ItemDate label={'Created'} content={email?.createdAt} />
                        <Panel.Item label={'To'} content={email?.recipientEmail} />
                        <Panel.ItemEntity label={'Send By'} content={email?.submittedBy} />

                        <Panel.SectionHeader>Message</Panel.SectionHeader>

                        <div style={{ backgroundColor: '#fefefe', padding: '1rem' }}>
                            <TemplateRendererFrame style={{ minHeight: '600px' }} content={email?.messageBody} />
                        </div>
                    </Panel.Body>
                </Panel>
            </VStack>
        </SuspenseSkeletonLoader>
    );
};
