import * as React from 'react';
import { clsxm } from '../utils';

export const FormGroup: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...rest }) => {
    return (
        <div
            className={clsxm('w-full flex flex-row flex-wrap gap-x-2 mb-2 justify-between *:flex-1', className)}
            {...rest}
        >
            {children}
        </div>
    );
};
