import { Optionable } from '@ez/components';
import { Icon, IconProps } from '@ez/components';
import * as React from 'react';

export enum InvoiceStatus {
    Draft = 'draft',
    PendingSend = 'pending-send',
    PendingPayment = 'pending-payment',
    Paid = 'paid',
    PaymentFailed = 'payment-failed',
    Cancelled = 'cancelled',
}

export const invoiceStatusToLabel = (status: InvoiceStatus | string) => {
    switch (status) {
        case InvoiceStatus.Draft:
            return 'Draft';
        case InvoiceStatus.PendingSend:
            return 'Pending Send';
        case InvoiceStatus.PendingPayment:
            return 'Pending Payment';
        case InvoiceStatus.Paid:
            return 'Paid';
        case InvoiceStatus.PaymentFailed:
            return 'Payment Failed';
        case InvoiceStatus.Cancelled:
            return 'Cancelled';
        default:
            return status;
    }
};

export const invoiceStatusToIcon = (status: InvoiceStatus | string): IconProps => {
    switch (status) {
        case InvoiceStatus.Draft:
            return { name: 'edit', color: 'grey' };
        case InvoiceStatus.PendingSend:
            return { name: 'send', color: 'grey' };
        case InvoiceStatus.PendingPayment:
            return { name: 'dot circle outline', color: 'orange' };
        case InvoiceStatus.Paid:
            return { name: 'check circle outline', color: 'green' };
        case InvoiceStatus.PaymentFailed:
            return { name: 'cancel', color: 'orange' };
        case InvoiceStatus.Cancelled:
            return { name: 'ban', color: 'grey' };
        default:
            return { name: 'square', color: 'green' };
    }
};

export const IconInvoiceStatus: React.FC<{ status: InvoiceStatus | string }> = ({ status }) => {
    return <Icon {...invoiceStatusToIcon(status)} />;
};

export const InvoiceStatusLabel: React.FC<{ status: InvoiceStatus | string }> = ({ status }) => {
    return (
        <span>
            <IconInvoiceStatus status={status} /> {invoiceStatusToLabel(status)}
        </span>
    );
};

const invoiceStatusToOptionable = (status: InvoiceStatus): Optionable<InvoiceStatus> => ({
    value: status,
    text: invoiceStatusToLabel(status),
});

export const invoiceStatusOptions: Optionable<InvoiceStatus>[] = [
    invoiceStatusToOptionable(InvoiceStatus.Draft),
    invoiceStatusToOptionable(InvoiceStatus.PendingSend),
    invoiceStatusToOptionable(InvoiceStatus.PendingPayment),
    invoiceStatusToOptionable(InvoiceStatus.Paid),
    invoiceStatusToOptionable(InvoiceStatus.PaymentFailed),
    invoiceStatusToOptionable(InvoiceStatus.Cancelled),
];

export namespace InvoiceDoc {
    export type ItemsType = {
        description: string;
        hours: number;
        rate: number;
        tax?: number;
        subTotal?: number;
        total?: number;
    };

    export type DocContent = {
        invoiceNumber: string;
        issueDate: string;
        sender?: {
            company?: {
                name?: string;
                abn?: string;
                address?: string;
                phone?: string;
                email?: string;
            };
            person?: {
                name: string;
                phone?: string;
                email?: string;
            };
        };
        receiver?: {
            company?: {
                name: string;
                abn?: string;
                address?: string;
                phone?: string;
                email?: string;
            };
            person?: {
                name: string;
                phone?: string;
                email?: string;
            };
        };
        paymentOption?: {
            bank?: {
                bankName: string;
                bankBSB: string;
                accountNumber: string;
                accountName: string;
            };
        };
        items: ItemsType[];
        totalAmountPayable: number;
        description?: string;
    };
}
