import * as React from 'react';
import { VStack } from '@ez/components';
import { FilterByStaff } from './FilterByStaff';
import { ClearAllFilters } from './ClearAllFilters';
import { FilterByWorkOrderNumber } from './FilterByWorkOrderNumber';
import { FilterByBookingStatus } from './FilterByBookingStatus';
import { FilterByRecurStatus } from './FilterByRecur';

export interface SearchSideBarProps {}

export const FilterBar: React.FC<SearchSideBarProps> = ({}) => {
    return (
        <VStack>
            <FilterByWorkOrderNumber label={'Work Order ID'} />
            <FilterByStaff />
            <FilterByBookingStatus />
            <FilterByRecurStatus />
            <ClearAllFilters />
        </VStack>
    );
};
