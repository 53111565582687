import * as React from 'react';
import { useWorkOrderListActions, WorkOrderBookingStatus } from '../../redux';
import { ActionBar, Optionable } from '@ez/components';

export const FilterByBookingStatus: React.FC = () => {
    const { WorkOrderListAction, WorkOrderListState } = useWorkOrderListActions();
    const { bookingStatus } = WorkOrderListState.filters;

    const statuses: Optionable<any>[] = [
        {
            text: 'Scheduled',
            icon: 'calendar check outline',
            value: WorkOrderBookingStatus.BOOKED,
        },
        {
            text: 'Unscheduled',
            icon: 'calendar times outline',
            value: WorkOrderBookingStatus.UNBOOKED,
        },
    ];

    // const onShowAll = (checked: boolean) => {
    //     if (checked) {
    //         WorkOrderListAction.setBookingFilter(WorkOrderBookingStatus.ANY);
    //     }
    // };

    const onFilterChange = (status, checked: boolean) => {
        if (checked) {
            WorkOrderListAction.setBookingFilter(status.value);
        } else {
            // WorkOrderListAction.setBookingFilter(WorkOrderBookingStatus.ANY);
        }
    };

    return (
        <ActionBar>
            <ActionBar.Header content={'Booking Status'} icon={'filter'} />
            {/*<ActionBar.ItemCheckbox*/}
            {/*    dividing={true}*/}
            {/*    checked={bookingStatus === WorkOrderBookingStatus.ANY}*/}
            {/*    onChange={(_, { checked }) => onShowAll(checked)}*/}
            {/*    content={'All'}*/}
            {/*/>*/}
            {statuses.map((status) => {
                const isChecked = status.value === bookingStatus;
                return (
                    <ActionBar.ItemCheckbox
                        radio={true}
                        key={status.value}
                        checked={isChecked}
                        onChange={(checked) => onFilterChange(status, checked)}
                        icon={status.icon}
                        content={status.text}
                    />
                );
            })}
        </ActionBar>
    );
};
