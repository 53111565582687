import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { NodeType } from '@poolware/api';
import { ConnectionErrorMessage, PageSkeletonLoader } from '@ez/components';
import { useAppointmentViewCtx } from '../../Scheduler';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import { useQueryServiceJob } from '../../queries/use-query-service-job';
import { QLServiceJobRecur } from './use-query-service-job';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { isServiceActive } from './utils';

export type RecurCaseContextType = {
    serviceJob: NodeType.ServiceJob;
    isStopped: boolean;
    isArchived: boolean;
    canEdit: boolean;
    isActive: boolean;
    refetchQueries: any[];
    serviceJobMutators: ReturnType<typeof useServiceJobMutators>;
};

const RecurCaseContext = React.createContext<RecurCaseContextType>({
    canEdit: false,
    isActive: false,
    isArchived: false,
    isStopped: false,
    refetchQueries: [],
    serviceJob: undefined,
    serviceJobMutators: undefined,
});

export const useRecurCaseCtx = () => {
    return useContext(RecurCaseContext);
};

export interface RecurCaseProviderProps {}

export const RecurCasePageProvider: React.FC<RecurCaseProviderProps> = ({ children }) => {
    const previewItemCtx = useAppointmentViewCtx();
    const { params } = useAppNavigator();
    const { modulesAccess } = useViewer();
    const sjId = params.sjId;
    if (!sjId) {
        throw 'Expected jsId param';
    }

    useEffect(() => {
        previewItemCtx.addRefetchQueries(refetchQueries);
        return () => {
            previewItemCtx.removeRefetchQueries(refetchQueries);
        };
    }, [sjId]);

    const {
        node: serviceJob,
        loading,
        refetchQuery,
        error,
    } = useQueryServiceJob(sjId, {
        query: QLServiceJobRecur,
    });

    const refetchQueries = [refetchQuery, 'AppointmentItemsConnectionForServiceCase'];

    const isStopped = !!serviceJob?.stopsAt;
    const isArchived = !!serviceJob?.archivedAt;
    const canEdit = !isArchived && !modulesAccess.FieldServices?.calendarSingleStaffMode;
    const isActive = useMemo(() => isServiceActive(serviceJob), [serviceJob]);
    const serviceJobMutators = useServiceJobMutators({
        refetchQueries: refetchQueries,
        awaitRefetchQueries: true,
    });

    const ctx: RecurCaseContextType = {
        serviceJob: serviceJob,
        isStopped: isStopped,
        isArchived: isArchived,
        canEdit: canEdit,
        isActive: isActive,
        refetchQueries: refetchQueries,
        serviceJobMutators,
    };

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !serviceJob) {
        return <NotFoundPage />;
    }

    return <RecurCaseContext.Provider value={ctx}>{children}</RecurCaseContext.Provider>;
};
