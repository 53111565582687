import * as React from 'react';
import {
    ConnectionErrorMessage,
    Form,
    FormikInputField,
    FormikPanelForm,
    FormSectionBody,
    FormSectionHeader,
    PageSkeletonLoader,
    toastError,
} from '@ez/components';
import {
    useMutateInvoiceDefaultPaymentSet,
    useQueryInvoiceDefaultPayment,
} from '../queries/use-query-invoice-default-settings';
import { InvoiceDoc } from '../constants';

export const InvoiceSettingsDefaultPayment: React.FC = ({}) => {
    const { data, loading, error, refetchQuery } = useQueryInvoiceDefaultPayment();
    const mutatePayment = useMutateInvoiceDefaultPaymentSet({ refetchQueries: [refetchQuery] });
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    }

    let initialValues: InvoiceDoc.DocContent['paymentOption'] = {
        bank: {
            bankBSB: '',
            bankName: '',
            accountName: '',
            accountNumber: '',
        },
        ...data,
    };

    const onUpdate = async (values: typeof initialValues) => {
        try {
            await mutatePayment(values);
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <>
            <FormikPanelForm
                debug={true}
                header={'Default Payment Options'}
                onSubmit={onUpdate}
                initialValues={initialValues}
            >
                <FormSectionHeader>Bank</FormSectionHeader>
                <FormSectionBody>
                    <Form.Group widths={'equal'}>
                        <FormikInputField label={'Bank'} name={'bank.bankName'} />
                        <FormikInputField label={'BSB'} name={'bank.bankBSB'} />
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <FormikInputField label={'Account No'} name={'bank.accountNumber'} />
                        <FormikInputField label={'Account Name'} name={'bank.accountName'} />
                    </Form.Group>
                </FormSectionBody>
            </FormikPanelForm>
        </>
    );
};
