import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { customerFragment, fragmentPoolOwnershipHistory, fromEdges, NodeType } from '@poolware/api';
import { Display, SectionHeader, Table, VStack, withApolloLoading } from '@ez/components';
import { CustomerLink, PoolLink } from '../../../../../routes/Links';

interface PoolOwnershipHistoryPanelProps extends PageControlProps {
    customerId: string;
}

const PoolOwnershipHistoryPanel: React.FC<PoolOwnershipHistoryPanelProps> = (props) => {
    const { previouslyOwnedPools, transferGainedPools } = props;
    const poolHistory = previouslyOwnedPools.concat(transferGainedPools).sort((l, r) => {
        return new Date(l.createdAt) < new Date(r.createdAt) ? 1 : -1;
    });

    return (
        <VStack>
            <SectionHeader content={'Ownership History'} />

            {poolHistory.length === 0 ? (
                <div className={'text-tertiary text-sm my-6'}>No events</div>
            ) : (
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Pool Transferred</Table.HeaderCell>
                            <Table.HeaderCell>From Customer</Table.HeaderCell>
                            <Table.HeaderCell>To Customer</Table.HeaderCell>
                            <Table.HeaderCell>By</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {poolHistory.map((h) => {
                            const customerId = h?.pool?.entity?.user?.customer?.id;
                            const poolId = h?.pool?.id;
                            const transferredFromCustomerId = h?.transferredFrom?.user?.customer?.id;
                            const transferredToCustomerId = h?.transferredTo?.user?.customer?.id;

                            return (
                                <Table.Row key={h.id}>
                                    <Table.Cell>
                                        <Display.Date value={h.createdAt} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <PoolLink customerId={customerId} poolId={poolId}>
                                            {h.pool.bottleNumber} - {h.pool.name}
                                        </PoolLink>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <CustomerLink customerId={transferredFromCustomerId}>
                                            <Display.Entity value={h.transferredFrom} />
                                        </CustomerLink>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <CustomerLink customerId={transferredToCustomerId}>
                                            <Display.Entity value={h.transferredTo} />
                                        </CustomerLink>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Display.Entity value={h.transferredBy} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </VStack>
    );
};

const QL = gql`
    query CustomerHistoryQuery($customerId: ID!) {
        customer: node(id: $customerId) {
            id
            ... on Customer {
                id
                ...CustomerFragment
                root {
                    id
                    previouslyOwnedPools {
                        edges {
                            cursor
                            node {
                                id
                                ...PoolOwnershipHistoryFragment
                            }
                        }
                    }
                    transferGainedPools {
                        edges {
                            cursor
                            node {
                                id
                                ...PoolOwnershipHistoryFragment
                            }
                        }
                    }
                }
            }
        }
    }
    ${fragmentPoolOwnershipHistory}
    ${customerFragment}
`;

export default compose(
    withAppNavigator(),
    graphql(QL, {
        options: (props: any) => ({
            variables: { customerId: props.customerId },
        }),
    }),
    withApolloLoading({ loaderPaths: 'data.customer' }),
    mapProps((props) => {
        let customer = props?.data?.customer as NodeType.Customer;
        const previouslyOwnedPools = fromEdges(customer?.root?.previouslyOwnedPools);
        const transferGainedPools = fromEdges(customer?.root.transferGainedPools);
        return {
            ...props,
            customer,
            previouslyOwnedPools,
            transferGainedPools,
        };
    })
)(PoolOwnershipHistoryPanel);

interface PageControlProps extends IAppNavigatorProps {
    customer: NodeType.Customer;
    previouslyOwnedPools: NodeType.PoolOwnershipHistory[];
    transferGainedPools: NodeType.PoolOwnershipHistory[];
}
