import invariant from 'invariant';
import _get from 'lodash/get';

// NOTE: unit-tests the demonstrate behaviour of this function

export interface ConvertToConfigValueInputProps {
    values: { [key: string]: any };
    key: string;
    isSourceNode?: boolean;
    isSwitch?: boolean;
}

export const convertToConfigValue = (input: ConvertToConfigValueInputProps) => {
    const { values = {}, key, isSourceNode = false, isSwitch = false } = input;

    invariant(key, 'Expected Key');
    if (!key) {
        return undefined;
    }

    const value = _get(values, key);
    if (value === null) {
        return isSwitch ? { id: null } : null;
    } else if (value !== undefined) {
        if (isSourceNode) {
            invariant(value.id, `Invalid object for key ${key}. Expected \'id\'.`);
            return isSwitch ? { id: value.id } : value.id;
        }

        invariant(
            typeof value === 'string' || typeof value === 'number' || value instanceof Date,
            'Expected a string or number type value for key = ' + key
        );

        if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
            return value;
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
};
