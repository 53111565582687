import * as React from 'react';
import { useMemo } from 'react';
import { EZStorage } from '@ez/tools';
import { useViewerContext } from '../Viewer';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useAppConfig } from '../app-config';
import { DebugPanel, DebugPanelTabProps } from '@ez/components';
import { ModuleAccessViewer } from './ModuleAccessViewer';
import { useTheme } from '../app-theme';
import { AppThemeSwitcher } from './AppThemeSwitcher';
import { JSONView, Panel } from '@ez/components';
import { useAppUpdater } from '../AppUpdater';

const isDevelopment = process.env.NODE_ENV === 'development';

const getPanelAvailabilityStatus = () => {
    //
    // NOTE:
    // use `localStorage['debugPanel.forceEnable']=true` command in
    // the browser console to force enable the panel
    //
    return useMemo(() => {
        // check only once on mount.
        return isDevelopment || EZStorage.getItem('debugPanel.forceEnable') === 'true';
    }, []);
};

const UpdaterState: React.FC = () => {
    const appStateContext = useAppUpdater();
    return (
        <Panel>
            <Panel.Body>
                <Panel.Item
                    labelWidth={200}
                    label={'Pending Reload'}
                    content={appStateContext.pendingReload ? 'YES' : 'NO'}
                />
                <Panel.Item
                    labelWidth={200}
                    label={'Reload on Nav'}
                    content={appStateContext.reloadOnNavigation ? 'YES' : 'NO'}
                />
                <Panel.Item labelWidth={200} label={'Initial Version'} content={appStateContext.initialVersion} />
                <Panel.Item labelWidth={200} label={'Server Ver'} content={appStateContext.serverVersion} />
                <Panel.Item labelWidth={200} label={'Last Refresh Ver'} content={appStateContext.lastRefreshVersion} />
                <Panel.Item
                    labelWidth={200}
                    label={'Prompt Timeout Passed'}
                    content={appStateContext.promptTimeoutPassed ? 'YES' : 'NO'}
                />
            </Panel.Body>
        </Panel>
    );
};

export const DebugPanelCtrl = () => {
    const viewerContext = useViewerContext();
    const appNavContext = useAppNavigator();
    const appConfig = useAppConfig();
    const { ThemeState } = useTheme();

    const enablePanel = getPanelAvailabilityStatus();

    const tabs: DebugPanelTabProps[] = useMemo(() => {
        if (!enablePanel) return [];

        const debugValue = { viewerContext, appNavContext, theme: ThemeState.theme, appConfig };
        return [
            {
                title: 'Viewer CTX',
                jsonData: {
                    data: debugValue,
                    shouldExpandNode: (key, data, level) => {
                        return level == 0 || (key.includes('viewerContext') && level == 1);
                    },
                },
            },
            {
                title: 'App Navigator',
                render: () => (
                    <div>
                        <JSONView data={appNavContext} />
                    </div>
                ),
            },
            {
                title: 'Module Access',
                render: () => (
                    <ModuleAccessViewer modulesAccess={viewerContext.modulesAccess} viewer={viewerContext.viewer} />
                ),
            },
            {
                title: 'Theme',
                render: () => {
                    return <AppThemeSwitcher />;
                },
            },
            {
                title: 'Updater',
                render: () => {
                    return <UpdaterState />;
                },
            },
        ];
    }, [viewerContext, appNavContext, appConfig, ThemeState]);

    if (!enablePanel) {
        return null;
    }

    return <DebugPanel showDimmer={false} tabs={tabs} />;
};
