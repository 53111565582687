import gql from 'graphql-tag';

export const mutateloginStaffQuery = gql`
    mutation loginStaff($input: LoginStaffInput!) {
        loginStaff(input: $input) {
            viewer {
                me {
                    staff {
                        id
                    }
                }
            }
        }
    }
`;

export const mutatepretendQuery = gql`
    mutation pretend($input: PretendInput!) {
        pretend(input: $input) {
            viewer {
                me {
                    staff {
                        id
                    }
                }
            }
        }
    }
`;

export const mutateunpretendQuery = gql`
    mutation unpretend($input: RemovePretendInput!) {
        unpretend(input: $input) {
            viewer {
                me {
                    staff {
                        id
                    }
                }
            }
        }
    }
`;
