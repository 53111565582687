//
// export enum AppointmentStatus_Legacy {
//     // V1 statuses
//     NOT_STARTED = 'notstarted',
//     COMPLETED = 'completed',
//     NO_SHOW = 'noshow',
//
//     // V2 statuses
//     PENDING = 'NotStarted',
//     STARTED = 'InProgress',
//     FINISHED = 'Finished',
//     FAILED = 'Cancelled',
//     ActionRequired = 'ActionRequired',
//     CUSTOM = 'custom',
// }
//
// /*
//        Service Job stage               <=>         Appointment status
//     [ServiceJobStageType.Opened]:             AppointmentStatus.NotStarted,
//     [ServiceJobStageType.InProgress]:         AppointmentStatus.InProgress,
//     [ServiceJobStageType.ActionRequired]:     AppointmentStatus.ActionRequired,
//     [ServiceJobStageType.Finished]:           AppointmentStatus.Finished,
//     [ServiceJobStageType.Canceled]:           AppointmentStatus.Cancelled,
//     [ServiceJobStageType.Suspended]:          AppointmentStatus.Cancelled,
//
//         Apt.status string convert to enum
//     'notstarted'    => AppointmentStatus.NotStarted
//     'pending'       => AppointmentStatus.NotStarted
//     'started'       => AppointmentStatus.InProgress
//     'completed'     => AppointmentStatus.Finished
//     'finished'      => AppointmentStatus.Finished
//     'custom'        => AppointmentStatus.Finished
//     'noshow'        => AppointmentStatus.Cancelled
//     'failed'        => AppointmentStatus.Cancelled
//     "any"           => AppointmentStatus.Finished
//
//  * */
//
// export const defaultAppointmentItemStatusMap_v1 = {
//     // V1
//     notstarted: {
//         title: 'Not Started',
//         isDefault: true,
//         isVisible: true,
//     },
//     completed: {
//         title: 'Completed',
//         isDefault: true,
//         isVisible: true,
//     },
//     noshow: {
//         title: 'No Show',
//         isDefault: true,
//         isVisible: true,
//     },
// };
//
// export const defaultAppointmentItemStatusMap_v2 = {
//     // V2
//     pending: {
//         title: 'Pending',
//         isDefault: true,
//         isVisible: true,
//     },
//     started: {
//         title: 'Started',
//         isDefault: true,
//         isVisible: false,
//     },
//     finished: {
//         title: 'Finished',
//         isDefault: true,
//         isVisible: true,
//     },
//     failed: {
//         title: 'Failed',
//         isDefault: true,
//         isVisible: true,
//     },
//     custom: {
//         title: 'Custom',
//         isDefault: true,
//         isVisible: false,
//     },
// };

export enum TraitValueType {
    Int = 'Int',
    Float = 'Float',
    String = 'String',
    Selection = 'Selection',
    Flag = 'Flag',
}
