import * as React from 'react';
import FilterByAppointmentGroup from './FilterByAppointmentGroup';
import StaffFilter from './FilterByStaff';
import ClearAllSection from './ClearAllSection';
import FilterByFranchise from './FilterByFranchise';
import { AdminOnly } from '@poolware/app-shell';
import AutoHideResourcesSection from './AutoHideResourcesSection';
import { MenuBar, ScrollableLayout } from '@ez/components';
import { FilterByServiceJobGroup } from './FilterByServiceJobGroup';
import { FilterByRecurState } from './FilterByRecurState';
import { useCalendarLayoutState } from '../../utils/use-calendar-layout-state';
import { FilterByAppointmentStatus } from './FilterByAppointmentStatus';

export const SidebarLeft: React.FC = () => {
    const CalLayoutState = useCalendarLayoutState();

    return (
        <ScrollableLayout style={{ borderWidth: 0 }}>
            <ScrollableLayout.MenuBar size={CalLayoutState.layoutColumns > 2 ? 'tiny' : undefined}>
                <MenuBar.Section position={'left'}>
                    <MenuBar.HeaderItem color={'grey'} icon={'filter'}>
                        Filters
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item
                        onClick={() => CalLayoutState.setFilterBarState(false)}
                        icon={'cancel'}
                        color={'grey'}
                    />
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>
            <ScrollableLayout.BodyScroll>
                <div className={'px-2 py-4 mb-4 space-y-4 text-base'}>
                    <AdminOnly>
                        <FilterByFranchise />
                    </AdminOnly>
                    <FilterByAppointmentGroup />
                    <FilterByAppointmentStatus />
                    <StaffFilter />
                    <FilterByServiceJobGroup />
                    <FilterByRecurState />
                    <AutoHideResourcesSection />
                    <ClearAllSection />
                    {/*<DebugSection />*/}
                </div>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};
