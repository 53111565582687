import * as React from 'react';
import {
    Button,
    DebugJSON,
    MenuBarHeaderItem,
    MenuBarSection,
    Modal,
    Panel,
    Segment,
    SemanticToastType,
    StickyMenuBar,
    toast,
} from '@ez/components';
import { detectBrowser, isMobileDevice } from '@ez/tools';
import { PageLayout, SectionHeader } from '@ez/components';
import { FileAttachmentUploader } from '@poolware/app-shell';

const browser = detectBrowser();
console.log(browser);

class Page extends React.Component<any, any> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            throwTest: false,
            showModal: false,
        };
    }

    showToast = (toastType: SemanticToastType) => () => {
        toast({
            type: toastType,
            title: 'Alert ' + toastType,
            description: 'In Icon (created by Message), SemanticToastContainer.',
        });
    };

    renderToastButtons = () => {
        return (
            <div>
                <Button onClick={this.showToast('error')} content={'Error'} />
                <Button onClick={this.showToast('info')} content={'Info'} />
                <Button onClick={this.showToast('success')} content={'Success'} />
                <Button onClick={this.showToast('warning')} content={'Warning'} />
            </div>
        );
    };

    render() {
        return (
            <PageLayout>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarHeaderItem content={'UI'} />
                    </MenuBarSection>
                </StickyMenuBar>
                <PageLayout.TwoColumns>
                    <Segment>
                        <SectionHeader content={'Toaster'} />
                        {this.renderToastButtons()}
                        <Modal
                            trigger={
                                <Button className={'mt-2'} size={'sm'} color={'tertiary'} content={'Test in Modal'} />
                            }
                            closeIcon
                        >
                            <SectionHeader content="Toasters" />
                            <Modal.Content>{this.renderToastButtons()}</Modal.Content>
                        </Modal>
                    </Segment>

                    <Segment>
                        <SectionHeader content={'Crash Logger Tests'} />
                        {this.state.throwTest && this.state.throwTestError()}
                        <p>Tests crash loggers</p>
                        <Button onClick={this.crash} content={'Crash'} color={'red'} basic icon={'warning sign'} />
                    </Segment>
                </PageLayout.TwoColumns>

                <PageLayout.TwoColumns>
                    <div className={'text-xl p-2 rounded bg-white'}>
                        <Panel>
                            <Panel.Header content={'Text-xl base'} button={{ content: 'Edit', icon: 'edit' }} />
                            <Panel.Body>
                                <Panel.Item label={'Customer'} content="Test" />
                            </Panel.Body>
                            <Panel.Footer>Footer</Panel.Footer>
                        </Panel>
                        <p className={'text-lg'}>text-lg</p>
                        <p className={'text-base'}>text-base</p>
                        <p className={'text-sm'}>text-sm</p>
                    </div>
                    <div className={'p-2 text-xs rounded bg-white'}>
                        <Panel>
                            <Panel.Header content={'Text-xs base'} button={{ content: 'Edit', icon: 'edit' }} />
                            <Panel.Body>
                                <Panel.Item label={'Customer'} content="Test" />
                            </Panel.Body>
                            <Panel.Footer>Footer</Panel.Footer>
                        </Panel>
                        <p className={'text-sm'}>text-sm</p>
                        <p className={'text-base'}>text-base</p>
                        <p className={'text-lg'}>text-lg</p>
                    </div>
                </PageLayout.TwoColumns>

                <PageLayout.BodySection vStack={true}>
                    <Segment>
                        <DebugJSON data={{ browser, isMobile: isMobileDevice() }} />
                    </Segment>

                    <Segment>
                        <FileAttachmentUploader
                            onDone={() => {}}
                            onFileUploadComplete={(fileId) => {}}
                            dzOptions={{ accept: 'image/*' }}
                        />
                    </Segment>
                </PageLayout.BodySection>

                <PageLayout.BodySection vStack={true}>
                    <Panel>
                        <Panel.Header content={'header'} />
                        <Panel.Body>
                            <Panel.Item label={'Customer'} content="Test" />
                        </Panel.Body>
                        <Panel.Footer>Footer</Panel.Footer>
                    </Panel>

                    <Panel>
                        <Panel.Header content={'header'} />
                        <Panel.Body>Body</Panel.Body>
                        <Panel.Footer>Footer</Panel.Footer>
                    </Panel>
                    <Panel>
                        <Panel.Header content={'header'} />
                        <Panel.Body>
                            <Panel.Item label={'Customer'} content="Test" />
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Body>
                            <Panel.Item label={'Customer'} content="Test" />
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Header content={'Text-xs base'} button={{ content: 'Edit', icon: 'edit' }} />
                        <Panel.Footer>Footer</Panel.Footer>
                    </Panel>
                </PageLayout.BodySection>
            </PageLayout>
        );
    }

    private crash = () => {
        this.setState({ throwTest: true });
    };
}

export default Page;
