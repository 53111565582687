import * as React from 'react';
import { useCalendarActions } from '../../../redux';
import { ActionBarSingleSelect, OptionableL2 } from '@ez/components';
import { RECUR_FILTER } from '../../../redux/helpers';

const _options: OptionableL2<RECUR_FILTER>[] = [
    {
        text: 'Recurring',
        icon: 'refresh',
        value: RECUR_FILTER.RECURRING,
    },
    {
        text: 'One-off',
        icon: 'calendar outline',
        value: RECUR_FILTER.ONEOFF,
    },
];

const _optionAll = {
    text: 'All',
    value: RECUR_FILTER.ALL,
};

export const FilterByRecurState: React.FC = () => {
    const { CalendarState, CalendarAction } = useCalendarActions();

    const handleOnChange = (option: OptionableL2<RECUR_FILTER>) => {
        CalendarAction.setRecurFilter(option.value);
    };

    return (
        <ActionBarSingleSelect //
            headerTitle={'Type'}
            value={CalendarState.filters?.isRecurring}
            onChange={handleOnChange}
            options={_options}
            optionAll={_optionAll}
            persistStorageKey={'sj.cal.filter.recur.toggle'}
        />
    );
};
