import PropTypes, { InferProps } from 'prop-types';
import clsx from 'clsx';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import React from 'react';

import * as dates from './utils/dates';
import Header from './Header';
import ResourceHeader from './ResourceHeader';
import { notify } from './utils/helpers';
import { RBCContext } from './CalendarContext';

const propTypes = {
    range: PropTypes.array.isRequired,
    events: PropTypes.array.isRequired,
    resources: PropTypes.any,
    getNow: PropTypes.func.isRequired,
    isOverflowing: PropTypes.bool,
    width: PropTypes.number,
    selected: PropTypes.object,
    selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
    onSelectSlot: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onDoubleClickEvent: PropTypes.func,
    onDrillDown: PropTypes.func,
    getDrilldownView: PropTypes.func.isRequired,
    scrollRef: PropTypes.any,
};

interface TimeGridHeaderProps extends InferProps<typeof propTypes> {
    style?: any;
    className?: string;
}

class TimeGridHeader extends React.Component<TimeGridHeaderProps> {
    public static propTypes = propTypes;
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    handleHeaderClick = (date, view, e) => {
        e.preventDefault();
        notify(this.props.onDrillDown, [date, view]);
    };

    renderHeaderCells(range) {
        let { getDrilldownView, getNow } = this.props;
        const {
            localizer,
            components: { header: HeaderComponent = Header },
            getters: { dayProp },
        } = this.context;

        const today = getNow();

        return range.map((date, i) => {
            let drilldownView = getDrilldownView(date);
            let label = localizer.format(date, 'dayFormat');

            const { className, style } = dayProp(date);

            let header = <HeaderComponent date={date} label={label} localizer={localizer} />;

            return (
                <div
                    key={i}
                    style={style}
                    className={clsx('rbc-header', className, dates.eq(date, today, 'day') && 'rbc-today')}
                >
                    {drilldownView ? (
                        <a href="#" onClick={(e) => this.handleHeaderClick(date, drilldownView, e)}>
                            {header}
                        </a>
                    ) : (
                        <span>{header}</span>
                    )}
                </div>
            );
        });
    }

    render() {
        let { width, resources, range, events, getNow, selectable, scrollRef, isOverflowing = true } = this.props;
        const {
            accessors,
            rtl,
            components: {
                timeGutterHeader: TimeGutterHeader,
                resourceHeader: ResourceHeaderComponent = ResourceHeader,
            },
        } = this.context;

        let style = {};
        if (isOverflowing) {
            style[rtl ? 'marginLeft' : 'marginRight'] = `${scrollbarSize()}px`;
        }

        let { getDrilldownView } = this.props;
        const {
            localizer,
            components: { header: HeaderComponent = Header },
            getters: { dayProp },
        } = this.context;

        const today = getNow();

        return (
            <div style={style} ref={scrollRef} className={clsx('rbc-time-header', isOverflowing && 'rbc-overflowing')}>
                <div className="rbc-time-header-gutter" style={{ width, minWidth: width, maxWidth: width }}>
                    {TimeGutterHeader && <TimeGutterHeader />}
                </div>

                {range.map((date, idx) => {
                    let drilldownView = getDrilldownView(date);
                    let label = localizer.format(date, 'dayFormat');

                    // const { className, style } = dayProp(date);
                    const isToday = dates.eq(date, today, 'day');

                    const shouldShowHeaderCell = resources?.length > 0;

                    return (
                        <div className="rbc-time-header-content" key={idx}>
                            <div className="rbc-time-header-date-row" key={`resource_${idx}`}>
                                <HeaderComponent
                                    date={date}
                                    label={label}
                                    localizer={localizer}
                                    isToday={isToday}
                                    onClick={
                                        drilldownView
                                            ? (e) => this.handleHeaderClick(date, drilldownView, e)
                                            : undefined
                                    }
                                />
                            </div>
                            {shouldShowHeaderCell && (
                                <div className={'rbc-time-header-resource-row'}>
                                    {resources.map(([id, resource], idx) => {
                                        return (
                                            <div className="rbc-time-header-resource-cell" key={idx}>
                                                <ResourceHeaderComponent
                                                    index={idx}
                                                    label={accessors.resourceTitle(resource)}
                                                    resource={resource}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default TimeGridHeader;
