import * as React from 'react';
import { ButtonRadix, ButtonWithPopupProps, clsxm, Icon } from '@ez/components';

export const QuickActionButton: React.FC<{
    icon?: string;
    label: string;
    onClick?: () => any;
    disabled?: boolean;
}> = ({ icon, label, onClick, disabled }) => {
    const [loading, setLoading] = React.useState(false);
    const handleClick = async () => {
        setLoading(true);
        await onClick?.();
        setLoading(false);
    };
    return (
        <ButtonRadix
            onClick={handleClick}
            disabled={disabled}
            // variant={'secondary'}
            className={
                '!w-[76px] !h-[81px] flex flex-col gap-2 p-2 pt-4 items-center text-primary border-primary border rounded justify-start hover:bg-accent'
            }
        >
            <div className={'size-[32px]'}>
                {!loading && <Icon name={icon} size={'large'} />}
                {loading && <Icon name={'spinner'} size={'large'} loading={true} />}
            </div>
            <div className={'text-sm whitespace-normal leading-none'}>{label}</div>
        </ButtonRadix>
    );
};

export const Frame = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement> & { tertiary?: boolean; bordered?: boolean }
>(({ bordered, className, tertiary, ...props }, ref) => {
    return (
        <div
            ref={ref}
            className={clsxm(
                // 'border border-red',
                'flex flex-col gap-4',
                tertiary && 'bg-tertiary',
                bordered && 'border p-2 rounded-sm',
                className
            )}
            {...props}
        />
    );
});

export const SectionHeader: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => {
    return <div className={clsxm('h-[54px] flex items-center font-bold text-strong text-xl', className)} {...props} />;
};

export const SectionButton: React.FC<ButtonWithPopupProps & { expanded?: boolean }> = ({
    content,
    expanded,
    children,
    className,
    icon,
    ...props
}) => {
    return (
        <button
            // variant={'tertiary'}
            // size={'lg'}
            className={clsxm(
                'ez-btn-base w-full h-[56px] px-2 border-none rounded-none text-strong font-bold flex flex-row items-center justify-between',
                className
            )}
            {...props}
        >
            {icon}
            <div className={'flex-grow text-left'}>
                {content}
                {children}
            </div>
            <Icon size={'big'} name={expanded ? 'caret up' : 'caret down'} />
        </button>
    );
};

export const BottomButtonContainer: React.FC = (props) => {
    return (
        <div
            className={
                'md:hidden fixed bottom-0 left-0 right-0 bg-panel shadow-[0_0_16px_0_rgba(0,0,0,0.3)] flex flex-row'
            }
            {...props}
        />
    );
};
