import Page from './Page';
import { compose, mapProps } from '@ez/tools';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { withApolloLoading } from '@ez/components';
import { NodeType, poolFragment, PoolMutatorProps, withPoolMutators } from '@poolware/api';
import { IWithPageProps, withPageProps } from '../../../../components/withPageProps';

const QL = gql`
    query EditPoolQuery($poolId: ID!) {
        pool: node(id: $poolId) {
            id
            ...PoolFragment
            ... on Pool {
                id
                site {
                    id
                    name
                }
                entity {
                    id
                    franchise {
                        id
                        organisationType {
                            id
                        }
                    }
                }
            }
        }
    }
    ${poolFragment}
`;

export default compose(
    withPageProps(),
    graphql(QL, {
        options: (props: any) => ({
            variables: {
                poolId: props?.params?.poolId,
            },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.pool.id' }),
    mapProps((props) => {
        let pool = props?.data?.pool;
        let customerId = props?.params?.customerId;
        return {
            ...props,
            pool,
            customerId,
        };
    }),
    withPoolMutators(['PoolDetailsQuery', 'CustomerQuery', 'CustomerSitesList', 'SiteQuery'])
)(Page);

export interface PageControlProps extends IWithPageProps, PoolMutatorProps {
    pool: NodeType.Pool;
    customerId: string;
}
