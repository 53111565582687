import * as React from 'react';
import { ComponentType, useMemo } from 'react';
import { RouteComponentProps, useHistory, useLocation, useRouteMatch, withRouter } from 'react-router';
import { useAppNavContext, useModuleContext, withAppNavContext, withModuleRouteContext } from './module-route';
import { createNavigator } from './create-app-navigator';
import invariant from 'invariant';

export interface ComponentEnhancer<TInner, TOuter> {
    (component: ComponentType<TInner>): ComponentType<TOuter>;
}

// Note: this is a hacky workaround to "fix" Typescript errors.
const untypedCreateNavigatorFn = () => createNavigator<any, any>(null);

export interface IAppNavigatorProps<Params extends { [K in keyof Params]?: string } = any>
    extends RouteComponentProps<Params>,
        ReturnType<typeof untypedCreateNavigatorFn> {}

const compose = (...funcs) =>
    funcs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args)),
        (arg) => arg
    );

const withCreateNavigator = () => (C) => (props) => {
    const navProps = createNavigator(props);
    return <C {...props} {...navProps} />;
};

// HOC
export const withAppNavigator = <P extends any>(): ComponentEnhancer<P & IAppNavigatorProps<{}>, P> =>
    compose(withRouter, withModuleRouteContext(), withAppNavContext(), withCreateNavigator());

// Hook
export function useAppNavigator<Params extends { [K in keyof Params]?: string } = any, QueryVars = any>() {
    let location = useLocation();
    let history = useHistory();
    let match = useRouteMatch<Params>();

    const moduleRouteContext = useModuleContext();
    const appNavContext = useAppNavContext();
    invariant(moduleRouteContext, 'useAppNavigator must be used inside <ModuleRoot>. Missing routeContext.');

    return useMemo(() => {
        const nav = createNavigator<Params, QueryVars>({
            routeContext: moduleRouteContext,
            appNavContext: appNavContext,
            history: history,
            location: location,
            match: match,
        });
        return { location, history, match, ...nav };
    }, [
        location, //
        history,
        match,
        moduleRouteContext,
        appNavContext,
    ]);
}
