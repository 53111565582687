import { graphql } from 'react-apollo';
import { mutatepretendQuery, mutateunpretendQuery } from './api-types/mutations-queries-custom';

export const withStaffPretend = () =>
    graphql(mutatepretendQuery, {
        props: (props: any) => {
            const { mutate } = props;
            return {
                pretend: async (staffId) => {
                    try {
                        await mutate({ variables: { input: { staffId: staffId } } });
                        window.location.reload();
                    } catch (e) {
                        console.error(e);
                    }
                },
            };
        },
    });

export const withStaffUnpretend = () =>
    graphql(mutateunpretendQuery, {
        props: (props) => {
            const { mutate } = props;
            return {
                unpretend: async () => {
                    try {
                        await mutate({ variables: { input: {} } });
                        window.location.reload();
                    } catch (e) {
                        console.error('Failed to unpretend', e);
                    }
                },
            };
        },
    });
