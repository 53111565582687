import * as React from 'react';
import { DetailsViewContainer, LeftSidebarContainer, PageContainer } from './page-layout';
import {
    ModuleNavLink,
    ModuleRedirect,
    ModuleRoot,
    ModuleRootSwitch,
    ModuleRoute,
} from '@poolware/react-app-navigator';
import VendIntegration from './VendIntegration';
import WaterlinkIntegration from './WaterlinkIntegration';
import { useViewer } from '@poolware/app-shell';
import { MenuBar, MenuBarHeaderItem, MenuBarSection, VStack } from '@ez/components';

const Integrations: React.FC = () => {
    const { modulesAccess } = useViewer();
    const canAccessVend = modulesAccess.VendIntegration?.admin;
    const canAccessWaterlink = modulesAccess.WaterlinkIntegration?.enabled;
    const defaultRedirect = canAccessVend ? '/vend' : canAccessWaterlink ? '/waterlink' : '/noaccess';
    return (
        <ModuleRoot>
            <VStack>
                <MenuBar.Root>
                    <MenuBarSection>
                        <MenuBarHeaderItem icon={'puzzle'}>Integrations</MenuBarHeaderItem>
                    </MenuBarSection>
                </MenuBar.Root>
                <PageContainer>
                    <LeftSidebarContainer>
                        <div className={'flex flex-col bg-white rounded p-2 mb-2 gap-2'}>
                            <div className={'p-2 px-4 has-[.active]:bg-gray-300 rounded'}>
                                <ModuleNavLink to={'/vend'} exact={false} className={'p-2'}>
                                    Vend
                                </ModuleNavLink>
                            </div>
                            <div className={'p-2 px-4 has-[.active]:bg-gray-300 rounded'}>
                                <ModuleNavLink to={'/waterlink'} exact={false}>
                                    Waterlink
                                </ModuleNavLink>
                            </div>
                        </div>
                    </LeftSidebarContainer>

                    <DetailsViewContainer>
                        <ModuleRootSwitch>
                            <ModuleRedirect path={'/'} exact to={defaultRedirect} />
                            <ModuleRoute path={'/vend'} component={VendIntegration} />
                            <ModuleRoute path={'/waterlink'} component={WaterlinkIntegration} />
                            <ModuleRoute path={'/noaccess'} component={() => <>No access</>} />
                        </ModuleRootSwitch>
                    </DetailsViewContainer>
                </PageContainer>
            </VStack>
        </ModuleRoot>
    );
};

export default Integrations;
