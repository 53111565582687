import * as React from 'react';
import { NodeType, useMutationWorkOrder } from '@poolware/api';
import { useServiceJobMutators } from '../../../queries/mutators-service-job';
import { useViewer } from '@poolware/app-shell';
import { useAppointmentMutators } from '../../../queries/use-appointment-mutators';
import { PanelWorkOrderInstantiated } from './PanelWorkOrder.Instantiated';
import { PanelWorkOrderPendingWo } from './PanelWorkOrder.PendingWo';
import { PanelWorkOrderAddSingleWO } from './PanelWorkOrder.AddSingleWO';
import { PanelWorkOrderConvertToRecur } from './PanelWorkOrder.ConvertToRecur';
import { Alert, DevOnly } from '@ez/components';

export const PanelWorkOrder: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    canEdit?: boolean;
    onCloseAppointment: () => any;
    workOrderMutator: ReturnType<typeof useMutationWorkOrder>;
    appointmentItemMutator: ReturnType<typeof useAppointmentMutators>;
    serviceJobMutator: ReturnType<typeof useServiceJobMutators>;
}> = (props) => {
    const {
        appointmentItem,
        canEdit,
        onCloseAppointment,
        serviceJobMutator,
        appointmentItemMutator,
        workOrderMutator,
    } = props;
    const { workOrder, serviceJob, isWorkOrderPending } = appointmentItem;
    const hasWo = !!workOrder?.id;
    const { modulesAccess } = useViewer();

    if (hasWo) {
        // Display full work order
        return (
            <PanelWorkOrderInstantiated
                canEdit={canEdit}
                appointmentItem={appointmentItem}
                workOrderMutator={workOrderMutator}
            />
        );
    }

    if (isWorkOrderPending) {
        return (
            <PanelWorkOrderPendingWo
                appointmentItem={appointmentItem}
                appointmentItemMutator={appointmentItemMutator}
            />
        );
    }

    if (serviceJob) {
        console.error("Shouldn't be here. Expected work order to exist.");
        return (
            <DevOnly>
                <Alert type={'error'}>Invalid state. Expected work order to exist. </Alert>
            </DevOnly>
        );
    }

    if (!appointmentItem?.isRecurrentOrigin && !appointmentItem.isRecurring) {
        // Non-recurring, simple appointment
        return (
            <PanelWorkOrderAddSingleWO //
                appointmentItem={appointmentItem}
                serviceJobMutator={serviceJobMutator}
                canEdit={canEdit}
            />
        );
    }

    //Simple appointment, not a Service Case
    if (appointmentItem?.isRecurring || appointmentItem?.isRecurrentOrigin) {
        if (modulesAccess.FieldServices.recurringCases || modulesAccess.FieldServices.calendarAutoCreateWo) {
            return (
                <PanelWorkOrderConvertToRecur
                    serviceJobMutator={serviceJobMutator}
                    appointmentItem={appointmentItem}
                    onCloseAppointment={onCloseAppointment}
                    canEdit={canEdit}
                />
            );
        }
    }

    return null;
};
