import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon, Table } from '@ez/components';
import { compose, toClass } from '@ez/tools';
import { DragSource, DropTarget, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import { styled } from 'twin.macro';
import { XYCoord } from 'dnd-core';
import immutableUpdate from 'immutability-helper';

const rowSource = {
    beginDrag(props) {
        return {
            id: props.id,
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor, component) {
        const item = monitor.getItem();
        props.dropRow && props.dropRow(item, props).then(() => {});
    },

    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // console.log(dragIndex);
        // console.log(hoverIndex);

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        // @ts-ignore
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
        // console.log(hoverBoundingRect);

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

export const connectDraggableRow = (name) =>
    compose(
        DragSource(name, rowSource, (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        })),
        DropTarget(name, rowTarget, (connect) => ({
            connectDropTarget: connect.dropTarget(),
        }))
    );

const TableRowWrapper = toClass(Table.Row);

//Note: Wrap Table in a Component, otherwise DND complains.
const TableWrapper = toClass(Table);

export const StyledHandleCell = styled(Table.Cell)`
    &&& {
        background-color: #f3f3f3;
        border-right: 1px solid #bfbfbf;
    }
`;

class TableRowDraggableComponent extends React.Component<any> {
    render() {
        const { connectDragSource, connectDragPreview, connectDropTarget, isDragging, children } = this.props;

        return (
            <TableRowWrapper
                ref={(instance) => {
                    const node = findDOMNode(instance);
                    connectDragPreview(node);
                    connectDropTarget(node);
                }}
                style={{
                    opacity: isDragging ? 0.1 : 1,
                    borderBottom: '1px solid #CCCCCC',
                }}
                positive={isDragging}
            >
                <StyledHandleCell width={'1'}>
                    {connectDragSource(
                        <div style={{ width: '15px', cursor: 'grab' }}>
                            <Icon name={'ellipsis vertical'} />
                        </div>
                    )}
                </StyledHandleCell>
                {children}
            </TableRowWrapper>
        );
    }
}

export const TableRowDraggable = connectDraggableRow('JobTodoItem')(TableRowDraggableComponent);

export const createTableRowDraggable = (name: string) => connectDraggableRow(name)(TableRowDraggableComponent);

export function reorderDNDRows<T extends any>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

export interface DraggableRowItem {
    index: number;
    type: string;
}

export const useDropRow = (props: { accept: string; dropRow; moveRow; ref; index }) => {
    const { accept, dropRow, moveRow, ref, index } = props;
    return useDrop({
        accept: accept,
        drop(item) {
            dropRow?.(item);
        },
        hover(item: DraggableRowItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveRow?.(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
};

export const useDndRow = ({ index, dropRow, moveRow, type }) => {
    const ref = useRef(null);

    const [, drop] = useDropRow({
        accept: type,
        ref: ref,
        index: index,
        dropRow: dropRow,
        moveRow: moveRow,
    });

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: type, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    preview(drop(ref));

    return {
        previewRef: ref,
        dragRef: drag,
        isDragging,
    };
};

export function useDndTable<T>(props: { items: T[]; onReorder: (items: T[]) => any }) {
    const { items, onReorder } = props;
    const [rowItems, setTableRowItems] = useState<T[]>(items);
    useEffect(() => {
        setTableRowItems(items);
    }, [items]);

    const dropRow = async (item) => {
        onReorder(rowItems);
    };

    const moveRow = (dragIndex: number, hoverIndex: number) => {
        const newOrder = reorderDNDRows(rowItems, dragIndex, hoverIndex);
        setTableRowItems(newOrder);
    };

    const appendRow = (item: T): T[] => {
        const newItems = immutableUpdate(items, {
            $push: [item],
        });
        setTableRowItems(newItems);
        return newItems;
    };

    return {
        dropRow,
        moveRow,
        appendRow,
        rowItems,
    };
}
