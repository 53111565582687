import Page from './Page';
import { compose, mapProps } from '@ez/tools';
import gql from 'graphql-tag';
import { withApolloLoading } from '@ez/components';
import { graphql } from 'react-apollo';
import { contactFragment, fromEdges, NodeType, TestReportMutatorsProps, withTestReportMutators } from '@poolware/api';
import { testReportFragment } from '../graphql-fragments';
import { IWithPageProps, withPageProps } from '../../../../components/withPageProps';

const QL = gql`
    query ViewWaterTest($reportId: ID!) {
        report: node(id: $reportId) {
            id
            ...TestReportFragment
            ... on TestReport {
                checkMutations {
                    delete
                    update
                }
                workOrder {
                    id
                    title
                    workOrderNumber
                }
                pool {
                    id
                    entity {
                        id
                        contacts {
                            edges {
                                node {
                                    id
                                    ...ContactFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${testReportFragment}
    ${contactFragment}
`;

export interface PageControlProps extends TestReportMutatorsProps, IWithPageProps {
    report: NodeType.TestReport;
    contacts: NodeType.Contact[];
    manualProblems: NodeType.ManualProblem[];
    chemicalTargets: NodeType.PoolChemicalTarget[];
    discoveredProblems: NodeType.TestProblem[];
    customerId: NodeType.ID;
    allowPhotoUpload: boolean;
    allowEmailReport: boolean;
    poolId: NodeType.ID;
    testId: NodeType.ID;
}

export default compose(
    withPageProps(),
    graphql(QL, {
        options: (props: any) => ({
            variables: { reportId: props?.params?.testId },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.report.id' }),
    mapProps((props): PageControlProps => {
        const report: NodeType.TestReport = props?.data?.report;
        if (!report)
            return {
                ...props,
            };
        const { customerId, poolId, testId } = props.params;

        let contacts = fromEdges<NodeType.Contact>(report?.pool?.entity?.contacts);
        let chemicalTargets = fromEdges(report?.pool?.chemicalTargets);
        let manualProblems = fromEdges(report?.manualProblems);
        let discoveredProblems = fromEdges(report?.discoveredProblems);

        return {
            ...props,
            report,
            manualProblems,
            chemicalTargets,
            discoveredProblems,
            customerId,
            poolId,
            testId,
            contacts,
        };
    }),
    withTestReportMutators(['ViewWaterTest'])
)(Page);
