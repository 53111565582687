import * as React from 'react';
import { useState } from 'react';
import { Button, ConnectionTableDef, DefaultConnectionTable, Icon, Panel } from '@ez/components';
import { useTableRowSelect } from '../useTableRowSelect';
import { useQueryProductsDocsConnection } from './use-query-products-docs-connection';
import { getIconNameForMimeType, truncateString } from './utils';
import { NodeType } from '@poolware/api';
import { useSafeState } from '@ez/tools';

export interface ProductPickerTableProps {
    type?: NodeType.ProductDocumentType;
    onChange: (selectedItems: NodeType.ProductDocument[]) => any;
}

export const ProductDocumentPickerTable: React.FC<ProductPickerTableProps> = ({ type, onChange }) => {
    const [selectedItems, setSelectedItems] = useState<NodeType.ProductDocument[]>([]);
    const { connectionData, connectionState } = useQueryProductsDocsConnection({ type });

    const handleOnChange = (selected: NodeType.ProductDocument[]) => {
        setSelectedItems(selected);
        onChange?.(selected);
    };

    const tableDefPartial: ConnectionTableDef<any> = [
        {
            header: 'Type',
            width: 1,
            cell: (r) => <>{r.type}</>,
        },
        {
            header: 'Title',
            sortKey: 'title',
            cell: (r) => (
                <>
                    <Icon name={getIconNameForMimeType(r.mimeType)} /> {truncateString(r.title, 60)}
                </>
            ),
        },
    ];

    const { tableDef, onRowClick } = useTableRowSelect({
        selectedItems,
        setSelectedItems: handleOnChange,
        connectionData,
        tableDef: tableDefPartial,
        multiSelect: false,
    });

    return (
        <DefaultConnectionTable
            onRowClick={onRowClick}
            connectionData={connectionData}
            connectionState={connectionState}
            tableDef={tableDef}
        />
    );
};

export const ProductDocumentPickerPanel: React.FC<{
    type?: NodeType.ProductDocumentType;
    onSubmit: (selectedItems: NodeType.ProductDocument[]) => any;
    onCancel: () => any;
}> = ({ onCancel, onSubmit, type }) => {
    const [selectedItems, setSelectedItems] = useState<NodeType.ProductDocument[]>([]);

    const [submitting, setSubmitting] = useSafeState(false);

    const handleOnCancel = () => {
        onCancel?.();
    };
    const handleOnSubmit = async () => {
        setSubmitting(true);
        await onSubmit?.(selectedItems);
        setSubmitting(false);
    };

    return (
        <Panel>
            <Panel.Header content={'Header'} />
            <Panel.Body>
                <ProductDocumentPickerTable onChange={setSelectedItems} type={type} />
            </Panel.Body>
            <Panel.Footer>
                <div className={'flex flex-row justify-between w-full'}>
                    <Button onClick={handleOnCancel} content={'Cancel'} variant={'secondary'} disabled={submitting} />
                    <Button
                        loading={submitting}
                        onClick={handleOnSubmit}
                        content={'Add'}
                        variant={'primary'}
                        disabled={submitting || selectedItems.length === 0}
                    />
                </div>
            </Panel.Footer>
        </Panel>
    );
};
