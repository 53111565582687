import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import { FormikDefaultForm, toastError } from '@ez/components';
import { Modal } from '@ez/components';
import { FormikCustomerAndLocationInputFields } from '@poolware/app-shell';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { PanelCustomerAndLocation } from '../../components/PanelCustomerAndLocation';

export const PanelFormJobCustomerAndLocation: React.FC<{ serviceJob: NodeType.ServiceJob; readOnly?: boolean }> = ({
    serviceJob,
    readOnly,
}) => {
    const [editing, setEditing] = useState(false);
    const { ServiceJobMutator } = useServiceJobMutators({ refetchQueries: ['QueryServiceJob'] });
    const initialValues = { ...serviceJob };

    const customer = serviceJob?.customer;
    const pool = serviceJob?.pool;
    const address = serviceJob?.address;

    if (!editing) {
        const editButton = !readOnly && {
            content: 'Edit',
            icon: 'edit',
            autoHideContent: false,
            onClick: () => setEditing(true),
        };
        return <PanelCustomerAndLocation customer={customer} pool={pool} address={address} headerButton={editButton} />;
    }

    const onSubmit = async (values: typeof initialValues, actions) => {
        const updateConf = {
            customer: values.customer,
            address: values.address,
            pool: values.pool,
        };
        try {
            await ServiceJobMutator.update(values.id, updateConf);
            setEditing(false);
            return updateConf;
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    return (
        <Modal size={'small'} open={true} centered={false}>
            <FormikDefaultForm
                debug={false}
                icon={'user'}
                initialValues={initialValues}
                onSubmit={onSubmit}
                header={'Customer'}
                onCancel={() => setEditing(false)}
            >
                <FormikCustomerAndLocationInputFields />
            </FormikDefaultForm>
        </Modal>
    );
};
