import React from 'react';

import Week from './Week';
import TimeGrid, { TimeGridProps } from '../TimeGrid';

function workWeekRange(date, options) {
    return Week.range(date, options).filter((d) => [6, 0].indexOf(d.getDay()) === -1);
}

interface WorkWeekProps extends TimeGridProps {}

class WorkWeek extends React.Component<WorkWeekProps> {
    public static defaultProps = TimeGrid.defaultProps;
    public static range = workWeekRange;
    public static navigate = Week.navigate;
    public static title = (date, { localizer }) => {
        let [start, ...rest] = workWeekRange(date, { localizer });
        return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
    };

    render() {
        let { date } = this.props;
        let range = workWeekRange(date, this.props);

        return <TimeGrid {...this.props} range={range} eventOffset={15} />;
    }
}

export default WorkWeek;
