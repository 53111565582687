import { MenuBarDropdownItemWithConfirm } from '@ez/components';
import * as React from 'react';

const DeleteMessage = ({ workOrder }) => {
    return (
        <div>
            <p>
                {workOrder ? <b>Work Order #{workOrder.workOrderNumber}</b> : <>Work Order</>}
                and all its Work Order Tasks will be permanently deleted. The parent Service Case will not be deleted.
            </p>
            <p>There is no undo for this action!</p>
        </div>
    );
};

export const DeleteWorkOrderMenuButtonItem: React.FC<{ onDeleteWorkOrder; workOrder? }> = ({
    onDeleteWorkOrder,
    workOrder,
}) => {
    return (
        <MenuBarDropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            content="Delete Work Order"
            onClick={onDeleteWorkOrder}
            confirm={{
                confirmMessage: {
                    header: `Delete Work Order?`,
                    content: <DeleteMessage workOrder={workOrder} />,
                },
                cancelButton: {
                    content: 'Keep',
                },
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                    negative: true,
                },
            }}
        />
    );
};
