import { SemanticICONS } from '@ez/components';

export enum PlatformKey {
    macos = 'macos',
    ios = 'ios',
    windows = 'windows',
    android = 'android',
    linux = 'linux',
    unknown = 'unknown',
}

export type PlatformType = {
    icon: SemanticICONS;
    text: string;
    value: PlatformKey;
};

export const platformOptions: PlatformType[] = [
    {
        icon: 'windows',
        text: 'Windows',
        value: PlatformKey.windows,
    },
    {
        icon: 'apple',
        text: 'macOS',
        value: PlatformKey.macos,
    },
    {
        icon: 'app store ios',
        text: 'iOS',
        value: PlatformKey.ios,
    },
    {
        icon: 'android',
        text: 'Android',
        value: PlatformKey.android,
    },
    {
        icon: 'linux',
        text: 'Lunux',
        value: PlatformKey.linux,
    },
    {
        icon: 'circle outline',
        text: 'Other',
        value: PlatformKey.unknown,
    },
];

export const getPlatformByKey = (platformKey: PlatformKey | string = PlatformKey.unknown): PlatformType => {
    const p =
        platformOptions.find((o) => {
            return o.value === platformKey;
        }) || platformOptions.find((o) => o.value === PlatformKey.unknown);

    return p;
};
