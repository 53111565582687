import * as React from 'react';
import { Field } from 'formik';
import * as _ from 'lodash';
import { TraitDeclarationPicker_TreeModal } from '../../../components-api-connected';
import { Form } from '@ez/components';

const ParentTraitDeclarationInput: React.FC<{ name: string }> = ({ name }) => {
    return (
        <Field
            name={name}
            render={(renderProps) => {
                const { form, field } = renderProps;
                const { name, value } = field;
                const errors = form.errors[name];
                const onSelect = (parent) => {
                    form.setFieldValue(field.name, parent);
                };
                return (
                    <Form.Input action label={'Product Declaration'} readOnly value={_.get(value, 'name')}>
                        <input style={{ backgroundColor: '#f6f6f6' }} />
                        <TraitDeclarationPicker_TreeModal onSelect={onSelect} />
                    </Form.Input>
                );
            }}
        />
    );
};

export default ParentTraitDeclarationInput;
