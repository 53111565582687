import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionTableDef, DefaultConnectionTable, Display, Icon } from '@ez/components';
import { IconServiceJobStage, ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { IconButton } from '../Components/IconButton';

const creatTableDef = (onDragStart, onDragEnd, onView): ConnectionTableDef<NodeType.WorkOrder> => [
    {
        header: 'Work Order',
        cell: (workOrder) => {
            const { serviceJob, assignedTo: staff, title = '--' } = workOrder;
            const groupTitle = serviceJob?.group?.title;
            const isRecurring = serviceJob?.isRecurring;
            const sjTitle = serviceJob?.title;

            return (
                <div
                    className={'flex flex-col cursor-move bg-white bg-opacity-50'}
                    draggable="true"
                    onDragStart={(e) => onDragStart(workOrder, e)}
                    onDragEnd={onDragEnd}
                >
                    <div>
                        <IconServiceJobStage stage={workOrder?.stage} />
                        {workOrder ? workOrder?.workOrderNumber : <i className={'text-gray-500 '}>pending...</i>}
                    </div>
                    <div>
                        <Icon name={ModuleIconNames.WorkOrder} />
                        {isRecurring && <Icon color={'grey'} name={ModuleIconNames.RecurringService} />}
                        {_.truncate(title, { length: 32 })}
                    </div>
                    <div className={'text-sm text-gray-700'} style={{ paddingLeft: 1 }}>
                        <span>
                            {groupTitle && <span className={'font-bold text-purple-600'}>{groupTitle}</span>}
                            {groupTitle && sjTitle && <Icon name={'caret right'} />}
                            {sjTitle && (
                                <>
                                    {!isRecurring && <Icon color={'grey'} name={ModuleIconNames.ServiceCase} />}
                                    {_.truncate(sjTitle, { length: 32 })}
                                </>
                            )}
                        </span>
                    </div>
                    {staff && (
                        <div>
                            <Icon name={'spy'} />
                            <Display.Entity value={staff} truncateLength={32} />
                        </div>
                    )}
                </div>
            );
        },
    },
    {
        header: 'Customer',
        cell: (workOrder) => {
            const { address, pool, customer } = workOrder;
            // const color = workOrder?.status === NodeType.WorkOrderStatusEnum.Open ? '#2185d0' : '#444444';

            return (
                <div className={'flex flex-row flex-nowrap'}>
                    <div className={'flex flex-col max-w-sm flex-1'}>
                        {customer && (
                            <div>
                                <Icon name={'user'} />
                                <code style={{ color: '#444444' }}>
                                    <b>{customer.crn}</b>
                                </code>{' '}
                                - <Display.Entity value={customer} />
                            </div>
                        )}
                        {customer?.companyName && (
                            <div>
                                <Icon name={'building'} />
                                {customer.companyName}
                            </div>
                        )}
                        {address && (
                            <div>
                                <Icon name={'map marker alternate'} />
                                <Display.Address value={address} />
                            </div>
                        )}
                        {pool && (
                            <div>
                                <Icon name={'life ring'} />
                                Pool - {pool.volume}L {pool.type?.name && `, ${pool.type?.name}`}
                            </div>
                        )}
                    </div>
                    {false && (
                        <div>
                            <IconButton icon={'chevron right'} onClick={() => onView(workOrder)} />
                        </div>
                    )}
                </div>
            );
        },
    },
];

const tableCellProps: any = {
    verticalAlign: 'top',
    style: {
        borderTop: '1px solid hsl(206, 50%, 85%)',
    },
};

export interface PageTableProps {
    onView: (item: NodeType.WorkOrder, refetchQuery?: any) => any;
    connectionState;
    connectionData;
    onDragStart: any;
    onDragEnd: any;
}

export const WorkOrdersTable: React.FC<PageTableProps> = ({
    onDragEnd,
    onDragStart,
    onView,
    connectionState,
    connectionData,
}) => {
    const tableDef = useMemo(() => creatTableDef(onDragStart, onDragEnd, onView), [onDragStart, onDragEnd]);

    return (
        <DefaultConnectionTable
            debug={false}
            tableProps={{ size: 'small', color: ModuleColorNames.WorkOrder }}
            tableDef={tableDef}
            tableCellProps={tableCellProps}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};
