import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Button, Modal, SectionHeader, Segment } from '@ez/components';

const ConfirmFutureModalContent: React.FC<{
    onConfirm: (future: boolean) => any;
    onCancel: () => any;
    open: boolean;
    component: React.ReactNode;
}> = ({ open, component, onConfirm, onCancel }) => {
    return (
        <Modal open={open} size={'small'} centered={false}>
            <SectionHeader icon="refresh" content="Changing recurring appointment" />
            <Modal.Content>
                {component}
                <Segment>
                    <Button
                        color={'purple'}
                        onClick={() => onConfirm(false)}
                        content={'Update Only This Appointment'}
                    />
                    <Button color={'red'} onClick={() => onConfirm(true)} content={'Update All Future Appointments'} />
                    <div className={'my-4'} />
                    <Button onClick={onCancel} icon={'cancel'} content={'Cancel'} />
                </Segment>
            </Modal.Content>
        </Modal>
    );
};

const confirmRoot = document.createElement('div');
const body = document.querySelector('body');
body.appendChild(confirmRoot);

export const confirmFutureImperative = async (confirm?): Promise<'cancel' | 'future' | 'current'> => {
    return new Promise((resolve) => {
        const giveAnswer = (answer: 'cancel' | 'future' | 'current') => {
            ReactDOM.unmountComponentAtNode(confirmRoot);
            resolve(answer);
        };

        ReactDOM.render(
            <ConfirmFutureModalContent
                onConfirm={(future) => giveAnswer(future ? 'future' : 'current')}
                onCancel={() => giveAnswer('cancel')}
                component={confirm}
                open={true}
            />,
            confirmRoot
        );
    });
};
