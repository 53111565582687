import * as React from 'react';
import { Panel } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { ProductTraitsTable } from './ProductTraitsTable';
import { useAppNavigator } from '@poolware/react-app-navigator';

export const ProductTraitsPanel: React.FC<{ product: NodeType.Product }> = ({ product }) => {
    const productTraits = fromEdges(product?.traits);
    const { AppNavigator } = useAppNavigator();
    const onOpen = () => {
        AppNavigator.navigate(`/products/${product.id}`, { moduleId: 'PRODUCT_CATALOG' });
    };

    return (
        <Panel>
            <Panel.Header
                content={'Technical Specification'}
                button={{ content: 'Open in Catalog', onClick: onOpen }}
            />
            <Panel.Body>
                <ProductTraitsTable traits={productTraits} />
            </Panel.Body>
        </Panel>
    );
};
