import * as React from 'react';

export const ToolbarContentContainer: React.FC<{
    actions?: React.ReactNode;
    main: React.ReactNode;
}> = ({ actions, main }) => {
    return (
        <div data-id={'ToolbarContentContainer'} className={'flex flex-col gap-4'}>
            <div data-id={'ToolbarContentContainer.main'} className={'flex-grow flex-shrink'}>
                {main}
            </div>
            <div data-id={'ToolbarContentContainer.actions'} className={'flex-grow'}>
                {actions}
            </div>
        </div>
    );
};
