import { NodeType } from './api-types';

export type ColorPaletteItem = { color?: string; label?: string };
export type ColorPalette = {
    group?: NodeType.AppointmentGroup;
    items: ColorPaletteItem[];
};

export const deserializeColorPalette = (ag: NodeType.AppointmentGroup): ColorPalette => {
    if (!ag) {
        return { group: null, items: [] };
    }

    let colorPalette = ag?.colorPalette || [];
    if (!(colorPalette instanceof Array)) {
        colorPalette = [];
    }

    const items = colorPalette.map((p) => {
        return {
            color: p?.color,
            label: p?.label,
        };
    });

    return {
        group: ag,
        items: items,
    };
};
