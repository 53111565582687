import * as React from 'react';
import gql from 'graphql-tag';
import _set from 'lodash/set';
import { NodeType } from '../api-types';
import { QueryConnectionExternalProps, useDefaultConnectionState, useQueryConnection } from '@ez/api-core';

// ************** staffTeams ***************** //

const connectionPath = 'viewer.staffTeams';
export const queryQueryStaffTeamsConnection = gql`
    query QueryStaffTeamsConnection(
        $after: String
        $first: Int
        $before: String
        $last: Int
        $page: Int
        $sort: StaffTeamViewerStaffTeamsSort
        $search: StaffTeamViewerStaffTeamsSearch
    ) {
        viewer {
            staffTeams(
                after: $after
                first: $first
                before: $before
                last: $last
                page: $page
                sort: $sort
                search: $search
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        id
                        title
                        tag
                        staff {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                        franchise {
                            id
                            name
                            organisationType {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export interface UseQueryStaffTeamsConnectionProps extends QueryConnectionExternalProps<NodeType.StaffTeam> {
    franchiseId?: NodeType.ID;
}

const mapPropsViewerStaffTeamsConnection = (props?: any) => {
    const search: NodeType.StaffTeamViewerStaffTeamsSearch = {};

    if (props?.franchiseId) {
        _set(search, 'franchise.id', props?.franchiseId);
    }
    return search;
};

export function useQueryStaffTeamsConnection(props?: UseQueryStaffTeamsConnectionProps) {
    const { variables, connectionState } = useDefaultConnectionState({
        ...props,
        search: mapPropsViewerStaffTeamsConnection(props),
    });

    return useQueryConnection<NodeType.StaffTeam>({
        query: props?.query || queryQueryStaffTeamsConnection,
        fetchPolicy: props?.fetchPolicy || 'cache-first',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
