import * as React from 'react';
import { useAppConfig } from '../app-config';
import { useViewer } from '../Viewer';
import { useApolloClient } from 'react-apollo';
import { Dropdown, Icon, Navbar } from '@ez/components';

export const DropdownMenuAccount = () => {
    const client = useApolloClient();
    const appConfig = useAppConfig();
    const { isAdmin, checkingAuthState, Auth } = useViewer();
    const profileLInk = appConfig?.apps?.account || '/profile';

    const logOut = async () => {
        try {
            await Auth.AuthAction.logout();
            await client.resetStore();
        } catch (e) {
            console.error(e);
        }
    };

    const label = null; //truncate(Auth.AuthState.user.username, { length: 10 });

    return (
        <>
            <Navbar.DropdownMenu icon={<Icon circular={true} name={'spy'} loading={checkingAuthState} />} label={label}>
                <Navbar.DropdownMenuItem icon={<Icon name={'user'} />}>
                    <a href={profileLInk} className={'group-hover:text-accent-foreground'}>
                        Profile
                    </a>
                </Navbar.DropdownMenuItem>
                <Dropdown.Divider />
                <Navbar.DropdownMenuItem icon={<Icon name={'log out'} />} content="Log Out" onClick={logOut} />
            </Navbar.DropdownMenu>
        </>
    );
};
