import * as React from 'react';
import { useMemo } from 'react';
import { clsxm, ConnectionTableDef, DefaultConnectionTable, Display, Icon, ScrollableLayout } from '@ez/components';
import { NodeType, QueryConnectionState } from '@poolware/api';
import { EZCDevicesLinkWithState } from '../use-query-ezc-device-states';
import { OnlineIcon } from '../common';
import { resolveCoordinates } from './data';
import { DeviceStateType, LifeGuardShadowType } from '../../View/TabDeviceState/types';

const useTableDef = (selectedDevice: EZCDevicesLinkWithState, isLoadingShadow?: boolean) => {
    return useMemo(() => {
        const tableDef: ConnectionTableDef<EZCDevicesLinkWithState> = [
            {
                header: 'Devices',
                cell: (item) => {
                    const address = item.pool?.address || item.owner?.primaryAddress;
                    const isSelected = selectedDevice?.id === item.id;
                    const shadowState: DeviceStateType<LifeGuardShadowType> = item.deviceState?.state?.lifeguard || {};
                    const coordinates = resolveCoordinates(item);
                    return (
                        <div className={clsxm('p-1 rounded', isSelected && 'bg-accent')}>
                            <div>
                                <OnlineIcon loading={isLoadingShadow} state={shadowState} />
                                {item.device?.title || '--'}
                            </div>
                            <div>
                                <Icon name={'user'} />
                                <Display.Entity value={item.owner} />
                            </div>
                            <div>
                                <Icon name={'map marker'} />
                                <Display.Address value={address} />
                                {coordinates && <Icon name={'map pin'} color={'green'} />}
                            </div>
                        </div>
                    );
                },
            },
        ];
        return tableDef;
    }, [selectedDevice, isLoadingShadow]);
};

export interface DeviceListProps {
    onView: (item: EZCDevicesLinkWithState) => void;
    connectionData: EZCDevicesLinkWithState[];
    connectionState: QueryConnectionState;
    selectedDevice?: EZCDevicesLinkWithState;
    isLoadingShadow?: boolean;
}

export const DeviceList: React.FC<DeviceListProps> = ({
    onView,
    connectionState,
    connectionData,
    isLoadingShadow,
    selectedDevice,
}) => {
    const tableDef = useTableDef(selectedDevice, isLoadingShadow);
    const onRowClick = (device: NodeType.EZCDeviceLink) => {
        onView(device);
    };

    return (
        <ScrollableLayout>
            <ScrollableLayout.BodyScroll>
                <DefaultConnectionTable
                    onRowClick={onRowClick}
                    tableDef={tableDef}
                    connectionData={connectionData}
                    connectionState={connectionState}
                />
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};
