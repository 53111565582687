import * as React from 'react';
import RichTextEditor from 'react-rte';
import { Display, HeaderButtonType, Modal, Panel, SemanticCOLORS } from '@ez/components';
import './rte-styles.css';
import { Prompt } from 'react-router-dom';
import { Button } from '@ez/components';

const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
    ],
};

interface NoteDisplayProps {
    header?: string;
    text?: string;
    active?: boolean;
    onSubmit: (htmlContent: string, context: any) => any;
    context?: any;
    color?: SemanticCOLORS;
    editorMode?: 'inline' | 'modal';
}

type NoteDisplayState = {
    originalText?: string;
    text: string;
    active: boolean;
    value?: any;
    isSubmitting: boolean;
};

export default class NoteDisplay extends React.Component<NoteDisplayProps, NoteDisplayState> {
    public static defaultProps = {
        editorMode: 'inline',
    };

    onChange = (value) => {
        this.setState({ value });
    };

    constructor(props) {
        super(props);
        const value = props.text ? props.text : '';
        this.state = {
            text: value,
            originalText: value,
            active: props.active,
            isSubmitting: false,
        };
    }

    activateEditor = () => {
        if (!this.state.active) {
            const { text } = this.state;
            this.setState({
                active: true,
                value: RichTextEditor.createValueFromString(text ? text : '', 'html'),
                originalText: this.state.text,
            });
        }
    };

    deactivateEditor = () => {
        if (this.state.active) {
            this.setState({ active: false, text: this.state.originalText });
        }
    };

    onCancel = () => {
        this.deactivateEditor();
    };

    onSubmit = async () => {
        if (this.state.active && this.props.onSubmit) {
            this.setState({ isSubmitting: true });
            const htmlContent = this.state.value.toString('html');
            await this.props.onSubmit(htmlContent, this.props.context);
            this.deactivateEditor();
            this.setState({ isSubmitting: false });
        }
    };

    static getDerivedStateFromProps = (props: NoteDisplayProps, state) => {
        const { text } = props;
        if (state.text !== text) {
            return { text: text };
        }
        return null;
    };

    renderModalEditor() {
        const { header = 'Notes' } = this.props;
        const { isSubmitting } = this.state;

        return (
            <Modal open={true} centered={false}>
                <Panel>
                    <Panel.Header size={'small'} icon={'clipboard check'} content={header} />
                    <Panel.Body>
                        <RichTextEditor
                            toolbarConfig={toolbarConfig}
                            value={this.state.value}
                            onChange={this.onChange}
                        />
                    </Panel.Body>
                    <Panel.Footer>
                        <div className={'flex flex-col-reverse md:flex-row md:justify-between gap-2'}>
                            <Button disabled={isSubmitting} onClick={this.onCancel} content={'Cancel'} />
                            <Button
                                variant={'primary'}
                                color={'primary'}
                                icon={'save'}
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                onClick={this.onSubmit}
                                content={'Save'}
                            />
                        </div>
                    </Panel.Footer>
                </Panel>
            </Modal>
        );
    }

    renderInlineEditor() {
        const { header = 'Notes' } = this.props;
        const { isSubmitting } = this.state;

        const buttons: HeaderButtonType[] = [
            {
                disabled: isSubmitting,
                onClick: this.onCancel,
                content: 'Cancel',
                variant: 'secondary',
            },
            {
                disabled: isSubmitting,
                loading: isSubmitting,
                onClick: this.onSubmit,
                content: 'Save',
                icon: 'save',
                variant: 'primary',
            },
        ];

        return (
            <>
                <Panel>
                    <Panel.Header size={'small'} icon={'clipboard check'} content={header} button={buttons} />
                    <Panel.Body>
                        <RichTextEditor
                            toolbarConfig={toolbarConfig}
                            value={this.state.value}
                            onChange={this.onChange}
                        />
                    </Panel.Body>
                </Panel>
                <Prompt message={'You have unsaved changes, are you sure you want to leave?'} />
            </>
        );
    }

    renderReadView() {
        const text = this.state.text;
        const { header = 'Notes' } = this.props;

        // Make it consistent with notes that are blank but were created with RichEditor.
        let defaultText = '<p><br/></p>';

        return (
            <Panel>
                <Panel.Header
                    color={this.props.color}
                    icon={'clipboard check'}
                    content={header}
                    button={{ content: 'Edit', icon: 'edit', onClick: this.activateEditor }}
                />
                <Panel.Body>
                    <div className={'bg-notes p-2 rounded break-words overflow-y-auto max-h-[250px]'}>
                        <Display.Text formatted={true} value={text ? text : defaultText} />
                    </div>
                </Panel.Body>
            </Panel>
        );
    }

    render() {
        const { active } = this.state;
        if (active) {
            if (this.props.editorMode === 'modal') {
                return this.renderModalEditor();
            } else {
                return this.renderInlineEditor();
            }
        } else {
            return this.renderReadView();
        }
    }
}
