import * as React from 'react';
import { FormikDefaultForm, FormikInputField, FormikSelectField, toastError } from '@ez/components';
import { getConfig } from '../../../config';
import { PageControlProps } from './Page.Control';
import { NodeType } from '@poolware/api';
import * as Yup from 'yup';
import { PageLayout } from '@ez/components';
import { useViewer } from '@poolware/app-shell';

const validationSchema = Yup.object().shape({
    roleId: Yup.string().required('Required'),
    firstName: Yup.string().max(100, 'Too Long!').required('Required'),
    lastName: Yup.string().max(100).required('Required'),
    email: Yup.string().email().required('Required'),
});

const StaffEditPage: React.FC<PageControlProps> = (props) => {
    const { staff, roles, AppNavigator } = props;
    const { isAdmin } = useViewer();

    const initialValues = {
        firstName: staff?.user?.firstName,
        lastName: staff?.user?.lastName,
        roleId: staff?.role?.id,
        email: staff?.registration?.email,
    };

    const roleOptions = roles?.map((node: any) => {
        return { text: node.name, value: node.id };
    });

    const onSubmit = async (values: typeof initialValues) => {
        const { mutateStaffRegistration } = props;
        try {
            let mutationConfig: NodeType.UpdateStaffRegistrationMutationInput = {
                id: staff?.registration?.id,
                email: isAdmin ? values.email?.trim() : undefined,
                staff: {
                    user: {
                        firstName: values.firstName?.trim(),
                        lastName: values.lastName?.trim(),
                    },
                },
            };
            if (values.roleId) {
                mutationConfig.staff.role = values.roleId;
            }

            await mutateStaffRegistration.update(mutationConfig);
            AppNavigator.navigateToOrigin();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };

    const resetPassword = () => {
        window.open(getConfig().api.passwordResetURL, '_blank');
    };

    const headerButton = {
        content: 'Reset Password',
        onClick: resetPassword,
        color: 'orange',
    };

    return (
        <PageLayout>
            <PageLayout.BodySection width={'screen-md'}>
                <FormikDefaultForm
                    validationSchema={validationSchema}
                    header={'Edit Staff Details'}
                    headerButton={headerButton}
                    initialValues={initialValues}
                    submitButton={{ content: 'Update' }}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                >
                    <FormikInputField name={'firstName'} label={'First Name'} required={true} />
                    <FormikInputField name={'lastName'} label={'Last Name'} required={true} />
                    <FormikSelectField options={roleOptions} name={'roleId'} label={'Role'} required={true} />
                    <FormikInputField readOnly={!isAdmin} name={'email'} label={'email'} />
                </FormikDefaultForm>
            </PageLayout.BodySection>
        </PageLayout>
    );
};

export default StaffEditPage;
