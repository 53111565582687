import * as React from 'react';
import { Segment } from '@ez/components';
import { SummaryStatsGraph } from '../components/SummaryStatsGraph';
import { DataPoint } from '../components/graph-helpers';

interface DataGraphInputProps {
    color?: string;
    dataPoints: DataPoint[];
}

interface DataGraphState {
    highlight?: any;
}

export class DataGraph extends React.PureComponent<DataGraphInputProps, DataGraphState> {
    render() {
        const series = this.props.dataPoints;
        if (!series) {
            return <Segment>No Recent Activity</Segment>;
        }

        return <SummaryStatsGraph dateFormat={'DD MMM'} data={series} color={this.props.color} />;
    }
}
