import * as React from 'react';
import {
    Button,
    ButtonWithConfirm,
    FormikDefaultForm,
    FormikRadioField,
    FormikTextareaField,
    Icon,
    Modal,
    Panel,
    useModalCtrl,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import { ActionButtonContainer } from './components';
import * as Yup from 'yup';
import { Stepper, StepperStep } from '../../components/Stepper';
import {
    getMessageForStatus,
    StageStatusOptionsMap,
    WarrantyClaimStatusGroupEnumFlowConfig,
    WarrantyClaimStatusGroupEnumFlowConfigType,
} from '../../status-configs';
import { StatusHistory } from '../../components/StatusHistory';

const validationSchema = Yup.object().shape({
    status: Yup.string().required('Required').nullable(),

    message: Yup.string().notRequired().max(2000).nullable(),
});

type StatusChangeType = {
    status: NodeType.WarrantyClaimStatusEnum;
    message?: string;
};

const NextStageOptions: React.FC<{
    stageConfig?: WarrantyClaimStatusGroupEnumFlowConfigType;
    onStatusChange: (data: StatusChangeType) => any;
}> = ({ stageConfig, onStatusChange }) => {
    const modal = useModalCtrl();

    const initialValues = { status: undefined, message: '' };

    const onSubmit = async (values: StatusChangeType) => {
        // await wait(1000);
        await onStatusChange({
            status: values.status,
            message: values.message?.trim(),
        });
        modal.onClose();
    };

    const onCancel = () => {
        modal.onClose();
    };

    if (!stageConfig) {
        return (
            <ButtonWithConfirm
                iconRight={'chevron right'}
                variant={'primary'}
                onClick={() => onSubmit({ status: NodeType.WarrantyClaimStatusEnum.PROCESSING })}
                content={'Start'}
            />
        );
    }

    const nextStages = stageConfig?.nextStages || [];

    return (
        <>
            {nextStages.map((nextStage) => {
                const stageOptions = StageStatusOptionsMap[nextStage.id];

                if (stageOptions.length < 2) {
                    return (
                        <ButtonWithConfirm
                            key={nextStage.id}
                            showLoaderOnClick={true}
                            confirm={nextStage.confirm}
                            skipConfirm={!nextStage.confirm}
                            iconRight={nextStage.icon || 'chevron right'}
                            variant={'primary'}
                            onClick={() => onSubmit({ status: stageOptions[0] })}
                            content={nextStage.label || 'Next'}
                        />
                    );
                }

                const radioOptions = stageOptions.map((ns) => ({
                    key: ns,
                    value: ns,
                    text: getMessageForStatus(ns),
                }));

                return (
                    <React.Fragment key={nextStage.id}>
                        <Button
                            iconRight={'chevron right'}
                            variant={'primary'}
                            onClick={modal.onOpen}
                            content={nextStage.label}
                        />

                        <Modal {...modal}>
                            <Panel>
                                <Panel.Header content={nextStage.label} />
                                <Panel.Body>
                                    <FormikDefaultForm
                                        validationSchema={validationSchema}
                                        debug={true}
                                        initialValues={initialValues}
                                        onSubmit={onSubmit}
                                        onCancel={onCancel}
                                        submitButton={{ content: 'Change Status' }}
                                    >
                                        <FormikRadioField
                                            name={'status'}
                                            options={radioOptions}
                                            label={'Status'}
                                            required={true}
                                        />
                                        <FormikTextareaField name={'message'} label={'Comment (optional)'} />
                                    </FormikDefaultForm>
                                </Panel.Body>
                            </Panel>
                        </Modal>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export interface PanelCaseStatusProps {
    claimCase: NodeType.WarrantyClaimCase;
    onStatusChange: (values: StatusChangeType) => any;
}

export const PanelCaseStatus: React.FC<PanelCaseStatusProps> = ({ claimCase, onStatusChange }) => {
    const modal = useModalCtrl(false);
    const status = claimCase?.claim?.status;
    const message = claimCase?.claim?.statusMessage;

    const stageProgressIndex = WarrantyClaimStatusGroupEnumFlowConfig.findIndex(
        (s) => s.statusOptions.findIndex((cs) => cs === status) != -1
    );

    const activeStageConfig = WarrantyClaimStatusGroupEnumFlowConfig[stageProgressIndex];

    const handleOnStatusChange = async (values: StatusChangeType) => {
        if (!values.status) {
            throw new Error('newStatus is invalid');
        }
        await onStatusChange(values);
    };

    return (
        <>
            <Panel>
                <Panel.Header
                    content={'Status'}
                    button={{ content: 'History', icon: 'history', onClick: modal.onOpen }}
                />
                <Panel.Body>
                    <Stepper className={'horizontal'}>
                        {WarrantyClaimStatusGroupEnumFlowConfig.map((s, i) => {
                            const isActive = stageProgressIndex == i;
                            const isComplete =
                                stageProgressIndex > i ||
                                stageProgressIndex === WarrantyClaimStatusGroupEnumFlowConfig.length - 1;
                            const progressStep = WarrantyClaimStatusGroupEnumFlowConfig[i];
                            return (
                                <StepperStep key={i} className={isActive ? 'active' : undefined}>
                                    {isComplete ? (
                                        <Icon name={'checkmark'} size={'large'} color={'teal'} />
                                    ) : (
                                        <span className={'stepper-number'}>{i + 1}</span>
                                    )}
                                    <span className={'title'}>{progressStep?.name}</span>
                                </StepperStep>
                            );
                        })}
                    </Stepper>

                    <ActionButtonContainer>
                        <div className={'stage-message'}>
                            <Panel.Item label={'Status'} labelWidth={'100%'}>
                                {getMessageForStatus(status)}
                            </Panel.Item>
                            {message && (
                                <Panel.ItemText
                                    labelWidth={'100%'}
                                    content={message}
                                    label={'Comment'}
                                    maxHeightPx={300}
                                />
                            )}
                        </div>
                        <div className={'stage-buttons'}>
                            <NextStageOptions stageConfig={activeStageConfig} onStatusChange={handleOnStatusChange} />
                        </div>
                    </ActionButtonContainer>
                </Panel.Body>
            </Panel>
            <Modal {...modal}>
                <Panel>
                    <Panel.Header
                        content={'Status change history'}
                        button={{ icon: 'close', onClick: modal.onClose }}
                    />
                    <Panel.Body>
                        <StatusHistory claim={claimCase?.claim} />
                    </Panel.Body>
                </Panel>
            </Modal>
        </>
    );
};
