import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@ez/components';
import { addressFragment, AuthConnectProps, connectWithAuth, fromEdges, NodeType } from '@poolware/api';

import TabSites from './TabSites';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { withViewer, WithViewerProps } from '@poolware/app-shell';

export const poolFragment = gql`
    fragment PoolFragment on Pool {
        id
        name
        volume
        bottleNumber
        sanitiser {
            id
            name
        }
        site {
            id
            address {
                id
                ...AddressFragment
            }
        }
        type {
            id
            name
            description
        }
        note
        entity {
            id
            user {
                id
                firstName
                lastName
                customer {
                    id
                    crn
                }
            }
        }
    }
    ${addressFragment}
`;

export const siteFragment = gql`
    fragment SiteFragment on Site {
        id
        name
        address {
            id
            ...AddressFragment
        }
        accessKeyLocation
        entity {
            id
            franchise {
                id
                organisationType {
                    id
                }
            }
            user {
                id
                firstName
                lastName
            }
        }
        pools(first: 1000) {
            edges {
                node {
                    id
                    ...PoolFragment
                    ezcDeviceLinks {
                        edges {
                            node {
                                id
                                label
                                deviceName
                                device {
                                    id
                                    ezcId
                                    title
                                    definition {
                                        id
                                        name
                                        definitionId
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        createdAt
    }
    ${poolFragment}
`;

const SitesQuery = gql`
    query CustomerSitesList($customerId: ID!) {
        customer: node(id: $customerId) {
            ... on Customer {
                id
                sites(first: 100) {
                    edges {
                        node {
                            id
                            ...SiteFragment
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }
        }
    }
    ${siteFragment}
`;

export default compose(
    connectWithAuth(),
    withAppNavigator(),
    withViewer(),
    graphql(SitesQuery, {
        options: (props: any) => ({
            variables: { customerId: props.customerId },
            // notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading({ loaderPaths: 'data.customer' }),
    mapProps((props) => {
        return {
            ...props,
            refetch: props?.data?.refetch,
            sites: fromEdges(props?.data?.customer?.sites),
        };
    })
)(TabSites);

export interface TabControlProps extends IAppNavigatorProps, AuthConnectProps, WithViewerProps {
    sites: NodeType.Site[];
    customerId: NodeType.ID;
}
