import * as React from 'react';
import * as URLBuilder from '../../url-builder';
import { Button, ButtonWithPopup, Panel } from '@ez/components';
import { useViewer } from '@poolware/app-shell';
import ActionButtons from './ActionButtons';
import VendSaleButton from './VendSaleButton';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppointmentDetailsViewCompProps } from './types';
import { AppointmentDetailsContentItems } from './AppointmentDetailsContentItems';
import { useCalendarActions } from '../../../redux';

export const AppointmentDetailsPreviewMode: React.FC<AppointmentDetailsViewCompProps> = (props) => {
    const {
        AppointmentMutator,
        canCreateSale,
        onDupe,
        appointmentItem,
        onEdit,
        onClose,
        appointmentGroups,
        onChangeStaff,
        onGroupChange,
        onColorChange,
        onStartWorkOrder,
        onChangeStatus,
        onShowInCalendar,
        onDelete,
        canEdit,
        showDockModeOption = false,
        onSwitchToDockMode,
    } = props;

    const { CalendarAction } = useCalendarActions();
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();

    const dockModeAllowed = !!showDockModeOption && modulesAccess.FieldServices?.calendarDockMode;

    const { isRecurring } = appointmentItem || { isRecurring: false };
    const headerTitle = `Appointment ${isRecurring ? '(Recurring)' : ''}`;
    const headerIcon = isRecurring ? 'refresh' : 'calendar outline';

    const onPrint = () => {
        const url = URLBuilder.Print().AppointmentItem(appointmentItem.id).view;
        AppNavigator.navigate(url, { setOrigin: true });
    };

    const renderActionButtons = () => {
        const canPrint = modulesAccess.System?.printAccess;
        const defaultProps: any = {
            size: 'mini',
            fluid: true,
            basic: true,
        };

        return (
            <>
                <ButtonWithPopup
                    {...{
                        ...defaultProps,
                        icon: 'copy',
                        popup: {
                            mouseEnterDelay: 600,
                            content: 'Duplicate appointment',
                        },
                        content: 'Duplicate',
                        onClick: onDupe,
                    }}
                />
                <VendSaleButton
                    AppointmentMutator={AppointmentMutator}
                    appointmentItem={appointmentItem}
                    canCreateSale={canCreateSale}
                />
                {onShowInCalendar && (
                    <Button
                        {...{
                            ...defaultProps,
                            icon: 'eye',
                            content: 'Show in Calendar',
                            color: 'grey',
                            onClick: onShowInCalendar,
                        }}
                    />
                )}
                {canPrint && (
                    <Button
                        {...{
                            ...defaultProps,
                            icon: 'print',
                            content: 'Print',
                            color: 'blue',
                            onClick: onPrint,
                        }}
                    />
                )}
                <ButtonWithPopup
                    {...{
                        ...defaultProps,
                        icon: 'edit',
                        content: 'Edit',
                        color: 'purple',
                        onClick: onEdit,
                        disabled: !canEdit,
                        popup: !canEdit && { content: 'Not enough permission' },
                    }}
                />
            </>
        );
    };

    return (
        <Panel>
            <Panel.Header
                icon={headerIcon}
                content={headerTitle}
                button={[
                    dockModeAllowed && {
                        icon: 'columns',
                        onClick: onSwitchToDockMode,
                        popup: {
                            content: 'Dock Mode',
                        },
                    },
                    {
                        icon: 'close',
                        onClick: onClose,
                    },
                ]}
            />
            <Panel.Body>
                <div className="flex flex-col space-y-10 space-x-0 sm:flex-row sm:space-y-0 sm:space-x-4">
                    <div className={'space-y-1 flex-grow sm:pr-2'}>
                        <AppointmentDetailsContentItems
                            canEdit={canEdit}
                            showStartWOButton={true}
                            appointmentItem={appointmentItem}
                            appointmentGroups={appointmentGroups}
                            onChangeStaff={onChangeStaff}
                            onGroupChange={onGroupChange}
                            onColorChange={onColorChange}
                            onStartWorkOrder={onStartWorkOrder}
                        />
                    </div>
                    <div className={'space-y-1 w-full sm:w-40 flex-shrink-0 pb-8'}>{renderActionButtons()}</div>
                </div>
            </Panel.Body>
            <Panel.Footer>
                <ActionButtons appointmentItem={appointmentItem} onChangeStatus={onChangeStatus} onDelete={onDelete} />
            </Panel.Footer>
        </Panel>
    );
};
