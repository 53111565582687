import DatePicker from '@poolware/react-datepicker';
import * as React from 'react';
import { useWorkOrderFeedActions, WorkOrderBookingStatus } from '../../redux';
import { DateRange } from '../../constants';
import { ActionBar, Button, DatePickerButton, Optionable, SegmentedSelectButtons } from '@ez/components';
import { CalNavigate, CalViewMode } from '../../Scheduler';
import moment from 'moment';
import * as _ from 'lodash';
import { ToolbarDatePicker } from '../../Scheduler/CalendarToolbar/ToolbarDatePicker';

const viewModeOptions: Optionable<CalViewMode>[] = [
    {
        text: 'Day',
        value: CalViewMode.DAY,
    },
    {
        text: 'Week',
        value: CalViewMode.WEEK,
    },
];

const formats = {
    dayFormat: (date: Date) => moment(date).format('ddd, DD MMM'),
    dayRangeFormat: (dateRange: DateRange) => {
        const { startDate: start, endDate: end } = dateRange;
        const isSameMonth = moment(start).isSame(end, 'month');
        if (isSameMonth) {
            return moment(start).format('DD') + ' - ' + moment(end).format('DD MMM');
        } else {
            return moment(start).format('DD MMM') + ' - ' + moment(end).format('DD MMM');
        }
    },
};

const _GETTERS = {
    [CalViewMode.DAY]: {
        getTitle: (input: { currentDate: Date; dateRange: DateRange }) => {
            return formats.dayFormat(input.currentDate);
        },
        getNextDate: (nav: CalNavigate, date: Date) => {
            switch (nav) {
                case CalNavigate.PREVIOUS:
                    return moment(date).subtract(1, 'd').toDate();
                case CalNavigate.NEXT:
                    return moment(date).add(1, 'd').toDate();
                default:
                    return date;
            }
        },
    },
    [CalViewMode.WEEK]: {
        getTitle: (input: { currentDate: Date; dateRange: DateRange }) => {
            return formats.dayRangeFormat(input.dateRange);
        },
        getNextDate: (nav: CalNavigate, date: Date) => {
            switch (nav) {
                case CalNavigate.PREVIOUS:
                    return moment(date).subtract(1, 'week').toDate();
                case CalNavigate.NEXT:
                    return moment(date).add(1, 'week').toDate();
                default:
                    return date;
            }
        },
    },
};

const getHighlightDate = (dateRange: DateRange) => {
    const start = moment(dateRange.startDate);
    const end = moment(dateRange.endDate);

    const dif = end.diff(start, 'd');
    if (dif <= 1) {
        return [];
    }

    const days = [];
    let breakCnt = 10;
    const today = moment();
    while (start.isBefore(end) && breakCnt > 0) {
        if (!start.isSame(today, 'd')) {
            days.push(start?.toDate());
        }
        start.add(1, 'd');
        breakCnt--;
    }

    return days;
};

const _now = new Date();

const highlightDates = [
    {
        'react-datepicker__day--highlighted-custom': [_now],
    },
];

const CalendarWidget = ({ selected, startDate, endDate, onChange }) => {
    const dates = getHighlightDate({ startDate, endDate });
    const hd = [
        { 'react-datepicker__day--highlighted-custom': [_now] },
        { 'react-datepicker__day--highlighted-range': dates },
    ];
    return (
        <DatePicker
            inline={true}
            calendarClassName={'pw-datepicker'}
            allowSameDay={true}
            icon={'calendar alternate outline'}
            size={'tiny'}
            basic
            color="teal"
            labelPosition={'left'}
            monthsShown={1}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat={'dd MMMM yyyy'}
            selected={selected}
            todayButton={'Today'}
            shouldCloseOnSelect={false}
            popperPlacement="bottom"
            arrowPosition={'center'}
            highlightDates={hd}
            onChange={onChange}
        />
    );
};

const DatePickerFilterButton: React.FC<{ label; activeDate; navigate }> = ({ label, activeDate, navigate }) => {
    const innerWidth = _.get(window, 'innerWidth', 1000);
    let monthsShown = 3;
    if (innerWidth >= 1280) {
        monthsShown = 3;
    } else if (innerWidth >= 768) {
        monthsShown = 2;
    } else {
        monthsShown = 1;
    }

    return (
        <DatePickerButton
            icon={'calendar alternate outline'}
            content={label}
            size={'tiny'}
            basic
            // labelPosition={'left'}
            monthsShown={monthsShown}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat={'dd MMMM yyyy'}
            selected={activeDate}
            todayButton={'Today'}
            shouldCloseOnSelect={false}
            popperPlacement="bottom"
            arrowPosition={'center'}
            highlightDates={highlightDates}
            onChange={(value) => {
                try {
                    const newDate = moment(value).toDate();
                    navigate(CalNavigate.DATE, newDate);
                } catch (e) {
                    navigate(CalNavigate.DATE, activeDate);
                }
            }}
        />
    );
};

export const ToolbarFilterByDateRange: React.FC = () => {
    const { State, Action } = useWorkOrderFeedActions();
    const { activeDate, displayingDateRange, viewMode, woBookingStatus } = State.filters;

    if (woBookingStatus !== WorkOrderBookingStatus.BOOKED) {
        return null;
    }

    const isToday = moment(activeDate).isSame(moment(), 'd');
    // const color: SemanticCOLORS  = 'black';
    const color: any = 'black';

    const getters = _GETTERS[viewMode] || _GETTERS[CalViewMode.DAY];
    const label = getters.getTitle({ currentDate: activeDate, dateRange: displayingDateRange });

    const _navigate = (mode: CalNavigate, date: Date = activeDate) => {
        switch (mode) {
            case CalNavigate.PREVIOUS:
                const prevDate = getters.getNextDate(mode, date);
                Action.setDateRangeFilter({ activeDate: prevDate });
                break;
            case CalNavigate.NEXT:
                const nextDate = getters.getNextDate(mode, date);
                Action.setDateRangeFilter({ activeDate: nextDate });
                break;
            case CalNavigate.TODAY:
                Action.setDateRangeFilter({ activeDate: new Date() });
                break;
            case CalNavigate.DATE:
                Action.setDateRangeFilter({ activeDate: date });
                break;
        }
    };

    // const setViewMode = (option: Optionable<CalViewMode>) => {
    //     Action.setDateRangeFilter({ viewMode: option.value });
    // };

    return (
        <div className={'flex flex-row'}>
            <Button segmentPosition={'first'} onClick={() => _navigate(CalNavigate.PREVIOUS)} icon={'chevron left'} />
            <ToolbarDatePicker activeDate={activeDate} label={label} navigate={_navigate} />
            <Button segmentPosition={'last'} onClick={() => _navigate(CalNavigate.NEXT)} icon={'right chevron'} />
        </div>
    );
};

export const SidebarFilterByDateRange: React.FC = () => {
    const { Action, State } = useWorkOrderFeedActions();
    const { activeDate, displayingDateRange, viewMode, woBookingStatus } = State.filters;

    if (woBookingStatus !== WorkOrderBookingStatus.BOOKED) {
        return null;
    }

    const getters = _GETTERS[viewMode] || _GETTERS[CalViewMode.DAY];
    const setViewMode = (option: Optionable<CalViewMode>) => {
        Action.setDateRangeFilter({ viewMode: option.value });
    };

    const _navigate = (mode: CalNavigate, date: Date = activeDate) => {
        switch (mode) {
            case CalNavigate.PREVIOUS:
                const prevDate = getters.getNextDate(mode, date);
                Action.setDateRangeFilter({ activeDate: prevDate });
                break;
            case CalNavigate.NEXT:
                const nextDate = getters.getNextDate(mode, date);
                Action.setDateRangeFilter({ activeDate: nextDate });
                break;
            case CalNavigate.TODAY:
                Action.setDateRangeFilter({ activeDate: new Date() });
                break;
            case CalNavigate.DATE:
                Action.setDateRangeFilter({ activeDate: date });
                break;
        }
    };

    return (
        <ActionBar>
            <SegmentedSelectButtons
                label={'Range'}
                color={'grey'}
                fluid={true}
                options={viewModeOptions}
                value={viewMode}
                onChange={setViewMode}
            />
            <div className={'pt-2 w-full'}>
                <div className={'w-60 mx-auto bg-red-50'}>
                    <CalendarWidget
                        selected={activeDate}
                        startDate={displayingDateRange.startDate}
                        endDate={displayingDateRange.endDate}
                        onChange={(value) => {
                            try {
                                const newDate = moment(value).toDate();
                                _navigate(CalNavigate.DATE, newDate);
                            } catch (e) {
                                _navigate(CalNavigate.DATE, activeDate);
                            }
                        }}
                    />
                </div>
            </div>
        </ActionBar>
    );
};
