import { compose, withProps } from '@ez/tools';
import {
    IMutateStaff,
    IMutateStaffRegistration,
    NodeType,
    withStaffMutator,
    withStaffRegistrationMutator,
} from './api-types';
import _trim from 'lodash/trim';

export interface MutatorsStaffInjected extends IMutateStaffRegistration, IMutateStaff {}

export interface CreateStaffRegistrationInputType {
    firstName?: string;
    lastName?: string;
    role: NodeType.NodeOrId<NodeType.Role>;
    franchise: NodeType.NodeOrId<NodeType.Franchise>;
    email: string;
    password: string;
}

const createMutator = ({ mutateStaffRegistration, mutateStaff }: MutatorsStaffInjected) => {
    const archiveStaff = async (staff: NodeType.NodeOrId<NodeType.Staff>) => {
        await mutateStaff.archive({ deleteRegistration: true, id: NodeType.extractId(staff) });
    };

    const createRegistration = async (
        values: CreateStaffRegistrationInputType
    ): Promise<NodeType.StaffRegistration> => {
        const mutationConfigMapper = (formValues: CreateStaffRegistrationInputType) => {
            let entityCreate = formValues.franchise ? { franchise: NodeType.extractId(formValues.franchise) } : {};

            return {
                email: _trim(formValues.email),
                password: formValues.password,
                staff: {
                    create: {
                        role: NodeType.extractId(formValues.role),
                        user: {
                            create: {
                                firstName: _trim(formValues.firstName),
                                lastName: _trim(formValues.lastName),
                                entity: {
                                    create: entityCreate,
                                },
                            },
                        },
                    },
                },
            };
        };

        try {
            const res = await mutateStaffRegistration.create(mutationConfigMapper(values));
            return res?.data?.StaffRegistration?.StaffRegistration;
        } catch (error) {
            // TODO: Update this when a better error handling is implemented by the backend.
            const gqlError = error?.graphQLErrors?.[0]?.name;
            if (gqlError === 'SequelizeUniqueConstraintError') {
                throw new Error('This email address is already in use, please use a different email address.');
            }
            throw error;
        }
    };

    const updateRegistration = async (values: any) => {
        const _mutationConfigMapper = (formValues) => {
            let mutationConfig: any = {
                id: formValues.registration.id,
                email: _trim(formValues.registration.email),
                staff: {
                    user: {
                        firstName: _trim(formValues.user.firstName),
                        lastName: _trim(formValues.user.lastName),
                    },
                },
            };
            const roleId = formValues?.role?.id;
            if (roleId) {
                mutationConfig.staff.role = roleId;
            }

            return mutationConfig;
        };

        return mutateStaffRegistration.update(_mutationConfigMapper(values));
    };

    return {
        StaffMutator: {
            updateRegistration: updateRegistration,
            createRegistration: createRegistration,
            archiveStaff: archiveStaff,
        },
    };
};

export const withStaffMutators = (refetchQueries?: any) =>
    compose(withStaffMutator(refetchQueries), withStaffRegistrationMutator(refetchQueries), withProps(createMutator));

export interface StaffMutatorProps extends MutatorsStaffInjected, ReturnType<typeof createMutator> {}
