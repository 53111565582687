import * as React from 'react';
import { useCallback, useState } from 'react';
import { ButtonWithDropdownMenu, Panel, useModalCtrl } from '@ez/components';
import { Modal } from '@ez/components';
import { BlockGroupType } from './types';
import { TableServiceSchedules } from './TableServiceSchedules';
import { FormNewSchedule } from './FormNewSchedule';
import { FormChangeEndDate } from './FormChangeEndDate';
import { useMemoPrepareBlocks } from './utils';
import { FormDeleteSchedule } from './FormDeleteSchedule';
import { useViewer } from '@poolware/app-shell';
import { useRecurCaseCtx } from '../RecurCaseProvider';

export interface PanelServiceScheduleProps {}

export const PanelServiceSchedules: React.FC<PanelServiceScheduleProps> = () => {
    const { serviceJob, refetchQueries, canEdit, isStopped } = useRecurCaseCtx();
    const { modulesAccess } = useViewer();
    const blockGroups = useMemoPrepareBlocks(serviceJob);
    const modalCtrlChangeEndDate = useModalCtrl(false);
    const modalCtrlDeleteSchedule = useModalCtrl(false);
    const modalCtrlNew = useModalCtrl(false);
    const [selectedBlockId, setSelectedBlockId] = useState<string>(blockGroups?.[0]?.patternBlockId);

    const onEdit = (block: BlockGroupType) => {
        setSelectedBlockId(block.patternBlockId);
        modalCtrlChangeEndDate.onOpen();
    };

    const onDelete = (block: BlockGroupType) => {
        setSelectedBlockId(block.patternBlockId);
        modalCtrlDeleteSchedule.onOpen();
    };

    const onClose = () => {
        setSelectedBlockId(null);
        modalCtrlChangeEndDate.onClose();
        modalCtrlDeleteSchedule.onClose();
    };

    const block = blockGroups.find((b) => b.patternBlockId === selectedBlockId);

    let locked: boolean | string = false;
    if (isStopped) {
        locked = 'Service Stopped';
    } else if (!canEdit) {
        locked = true;
    }

    const canAddSchedule =
        !!modulesAccess.FieldServices?.multiScheduledRecurringCases &&
        // && blockGroups.length > 0
        !locked;

    const button = canAddSchedule && {
        content: 'Add Schedule',
        onClick: modalCtrlNew.onOpen,
        locked: locked,
    };

    const actions = useCallback(
        (blockGroup: BlockGroupType) => (
            <ButtonWithDropdownMenu
                icon={'ellipsis vertical'}
                size={'sm'}
                options={[
                    {
                        content: 'Change End Date',
                        locked: locked,
                        onClick: () => onEdit(blockGroup),
                    },
                    {
                        content: 'Delete',
                        locked: locked,
                        onClick: () => onDelete(blockGroup),
                    },
                ]}
            />
        ),
        []
    );

    return (
        <>
            <Panel>
                <Panel.Header color={'grey'} content={'Service Schedules'} button={button} />
                <Panel.Body>
                    <TableServiceSchedules connectionData={blockGroups} actions={actions} />
                </Panel.Body>
            </Panel>

            {selectedBlockId && block && (
                <Modal {...modalCtrlChangeEndDate}>
                    <FormChangeEndDate
                        serviceJob={serviceJob}
                        onDone={onClose}
                        block={block}
                        onCancel={onClose}
                        refetchQueries={refetchQueries}
                    />
                </Modal>
            )}
            {selectedBlockId && block && (
                <Modal {...modalCtrlDeleteSchedule}>
                    <FormDeleteSchedule
                        block={block}
                        refetchQueries={refetchQueries}
                        onCancel={onClose}
                        onDone={onClose}
                    />
                </Modal>
            )}
            {modalCtrlNew.open && (
                <Modal {...modalCtrlNew}>
                    <FormNewSchedule
                        serviceJob={serviceJob}
                        onDone={modalCtrlNew.onClose}
                        refetchQueries={refetchQueries}
                    />
                </Modal>
            )}
        </>
    );
};
