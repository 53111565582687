import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionTableDef, DefaultConnectionTable, Display } from '@ez/components';
import { useViewer } from '@poolware/app-shell';
import { EZCDevicesLinkWithState } from '../use-query-ezc-device-states';
import { OnlineIcon } from '../common';
import { LayoutViewProps } from '../types';
import { DeviceStateType, LifeGuardShadowType } from '../../View/TabDeviceState/types';

const useTableDef = (isAdmin?: boolean) => {
    return useMemo(() => {
        const tableDef: ConnectionTableDef<EZCDevicesLinkWithState> = [
            {
                header: 'Device',
                width: '3',
                cell: (item) => {
                    const state: DeviceStateType<LifeGuardShadowType> = item.deviceState?.state?.lifeguard || {};
                    return (
                        <>
                            <OnlineIcon state={state} />
                            {item.device?.title || '--'}
                        </>
                    );
                },
            },
            {
                header: 'Owner',
                width: '3',
                cell: (item) => {
                    return <Display.Entity value={item.owner} />;
                },
            },
            {
                header: 'Pool',
                cell: (item) => {
                    if (!item.pool) return null;
                    return (
                        <div>
                            {item.pool?.name} ({item.pool?.volume || '--'} L)
                        </div>
                    );
                },
            },
            {
                header: 'Address',
                cell: (item) => {
                    const address = item?.pool?.address || item?.owner?.primaryAddress;
                    return <Display.Address value={address} />;
                },
            },
        ];

        if (isAdmin) {
            tableDef.push({
                header: 'Franchise',
                cell: (r) => {
                    return <>{r.franchise?.name}</>;
                },
            });
        }

        return tableDef;
    }, [isAdmin]);
};

export interface PageListProps extends LayoutViewProps {}

export const LayoutTable: React.FC<PageListProps> = ({ onView, connectionData, connectionState, isLoadingShadow }) => {
    const { isAdmin } = useViewer();
    const tableDef = useTableDef(isAdmin);

    return (
        <DefaultConnectionTable
            debug={true}
            onRowClick={onView}
            tableDef={tableDef}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};
