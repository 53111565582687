import * as React from 'react';
import { NodeType, useMutationAppointmentItem } from '@poolware/api';
import { Button, ConfirmButton, Display, Panel, toastError } from '@ez/components';
import { useBookAppointmentForWorkOrder } from './useBookAppointmentForWorkOrder';
import { useCalendarActions } from '../../redux';
import { getLabelForAppointmentStatus, IconAppointmentStatus } from '../../Scheduler';
import { ModuleIconNames } from '../../constants';

const ConfirmBookingDeleteButton = ({ onClick }) => (
    <ConfirmButton
        size={'sm'}
        showLoaderOnConfirm={true}
        onClick={onClick}
        icon={'trash'}
        negative={true}
        confirmButton={{
            content: 'Delete',
            icon: 'trash',
            negative: true,
        }}
        cancelButton={{
            content: 'Keep',
        }}
        confirmMessage={{
            header: 'Delete Appointment?',
        }}
    />
);

export const WorkOrderAppointmentPanel: React.FC<{
    workOrder: NodeType.WorkOrder;
    refetchQuery?: any;
    canDelete?: boolean;
}> = ({ workOrder, refetchQuery, canDelete }) => {
    const book = useBookAppointmentForWorkOrder({ workOrder });
    const { CalendarAction } = useCalendarActions();

    const appointmentItemMutator = useMutationAppointmentItem({
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery],
    });

    const onDelete = async () => {
        try {
            await appointmentItemMutator.delete({ id: workOrder.appointmentItem.id });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete appointment', description: e.message });
        }
    };

    let viewAppointmentButton = workOrder.appointmentItem && [
        canDelete && <ConfirmBookingDeleteButton onClick={onDelete} />,
        {
            content: 'Show in Calendar',
            icon: 'eye',
            color: 'green',
            onClick: () => CalendarAction.showAppointmentInCalendar(workOrder.appointmentItem),
        },
    ];

    const appointmentItem = workOrder.appointmentItem;
    return (
        <Panel>
            <Panel.Header
                basic={true}
                // color={'grey'}
                size={'small'}
                icon={'calendar alternate outline'}
                content={'Appointment'}
                button={viewAppointmentButton}
            />
            <Panel.Body>
                {appointmentItem ? (
                    <>
                        <Panel.Item labelWidth={130} label={'Date'} labelIcon={ModuleIconNames.Appointment}>
                            <Display.Date format={'LLLL'} value={appointmentItem.startDate} />
                            <div className={'pl-2 hidden'}>
                                <IconAppointmentStatus status={appointmentItem.status} />
                                {getLabelForAppointmentStatus(appointmentItem.status)}
                            </div>
                        </Panel.Item>
                        <Panel.Item labelWidth={130} label={'Status'} labelIcon={ModuleIconNames.Appointment}>
                            <IconAppointmentStatus status={appointmentItem.status} />
                            {getLabelForAppointmentStatus(appointmentItem.status)}
                        </Panel.Item>
                    </>
                ) : (
                    <Button
                        content="Book Appointment"
                        icon={'calendar alternate outline'}
                        color={'green'}
                        onClick={() => book(workOrder)}
                        size={'sm'}
                    />
                )}
            </Panel.Body>
        </Panel>
    );
};
