import * as React from 'react';
import { SegmentedButtonRadixProps, SegmentedSelectButtonsRadix } from '../buttons';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { useField } from 'formik';
import { Optionable } from '../optionable';
import { FormField, FormikFieldProps } from './FormField';
import { useId } from './useId';

export interface FormikSegmentedSelectButtonsProps<T = any>
    extends FormikFieldProps,
        Omit<SegmentedButtonRadixProps<T>, 'onChange' | 'value'> {
    options: Optionable<T>[];
    onDidChange?: (newValue: T) => any;
    color?: any;
}

export const FormikSegmentedSelectButtons: React.FC<FormikSegmentedSelectButtonsProps> = ({
    name,
    label,
    options,
    onDidChange,
    color,
    readOnly,
    disabled,
    id,
    ...rest
}) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField<any>({ name: name });
    const isDisabled = disabled || readOnly;

    const handleChange = async (item) => {
        await onDidChange?.(item.value);
        await helpers.setValue(item.value);
    };

    return (
        <FormField name={field.name}>
            {label && <FormikFormFieldLabel htmlFor={fieldId} label={label} name={name} />}
            <SegmentedSelectButtonsRadix
                id={fieldId}
                disabled={isDisabled}
                color={color}
                aria-invalid={meta.touched && !!meta.error}
                value={field.value}
                options={options}
                onChange={handleChange}
                {...rest}
            />
        </FormField>
    );
};
