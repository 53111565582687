import {
    Display,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionConsumer,
    PrintSectionHeader,
    PrintSectionItem,
} from '@ez/components';
import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { ContactItems, prepareCustomerContactItems } from '../../components/ContactItems';
import _truncate from 'lodash/truncate';

export const LocationDetailsSection: React.FC<{
    pool?: NodeType.Pool;
    address?: NodeType.Address;
}> = ({ pool, address }) => {
    const poolName = pool?.name || '--';
    const poolVolume = pool?.volume || '--';
    const poolType = pool?.type?.name;
    const poolString = `${_truncate(poolName, { length: 60 })} (${poolVolume} L, ${poolType})`;
    const poolSanitiser = pool?.sanitiser?.name;
    const poolSiteAccess = pool?.site?.accessKeyLocation;

    return (
        <PrintSectionConsumer name={'location'}>
            <PrintSectionHeader>Location</PrintSectionHeader>
            <PrintSectionBody>
                <PrintSectionColumn>
                    <PrintSectionItem label={'Address'}>
                        <Display.Address value={address} />
                    </PrintSectionItem>

                    {pool && (
                        <>
                            <PrintSectionItem label={'Pool'}>{poolString}</PrintSectionItem>

                            {poolSanitiser && (
                                <PrintSectionItem label="Pool Sanitiser">{poolSanitiser}</PrintSectionItem>
                            )}

                            {poolSiteAccess && (
                                <PrintSectionItem label="Site Access Key">
                                    <Display.FormattedText value={poolSiteAccess} limit={180} />
                                </PrintSectionItem>
                            )}
                        </>
                    )}
                </PrintSectionColumn>
            </PrintSectionBody>
        </PrintSectionConsumer>
    );
};

export const CustomerDetailsSection: React.FC<{
    customer?: NodeType.Customer;
}> = ({ customer }) => {
    const contactItems = prepareCustomerContactItems(fromEdges(customer?.user?.entity?.contacts));

    return (
        <PrintSectionConsumer name={'customer'}>
            <PrintSectionHeader>Customer {customer && `- ${customer.crn}`}</PrintSectionHeader>
            <PrintSectionBody>
                <PrintSectionColumn>
                    <PrintSectionItem label={'Customer'}>
                        <Display.Entity value={customer} />
                        {customer?.contactName && <> ({customer.contactName})</>}
                    </PrintSectionItem>
                    {customer && <PrintSectionItem label={'Customer CRN'}>{customer?.crn}</PrintSectionItem>}
                    {customer?.primaryAddress && (
                        <PrintSectionItem label={'Primary Address'}>
                            <Display.Address value={customer.primaryAddress} />
                        </PrintSectionItem>
                    )}
                    {customer?.billAddress && (
                        <PrintSectionItem label={'Bill Address'}>
                            <Display.Address value={customer.billAddress} />
                        </PrintSectionItem>
                    )}
                </PrintSectionColumn>
                <PrintSectionColumn>
                    {contactItems && (
                        <>
                            <ContactItems contactItems={contactItems} labelWidth={'100%'} as={PrintSectionItem} />
                        </>
                    )}
                </PrintSectionColumn>
            </PrintSectionBody>
        </PrintSectionConsumer>
    );
};
