import * as React from 'react';
import { useMemo, useState } from 'react';
import { useQueryServiceJobTemplateConnection } from '../queries/use-query-service-job-template-connection';
import { ConnectionTableDef, Icon, Segment } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { TablePickerPanel } from '../components/TablePickerPanel';
import { ServiceJobTemplatePreview } from '../components/ServiceJobTemplatePreview';
import { ModuleIconNames } from '../constants';
import { ActionBarFilterByServiceJobGroup } from '../components/ActionBarFilters/ActionBarFilterByServiceJobGroup';
import { useQueryServiceJobGroupConnection } from '../queries/use-query-service-job-group-connection';
import { mapServiceJobGroupsToOptions } from '../components/ActionBarFilters/ActionBarServiceGroups';

const WorkOrderPicker: React.FC<{
    serviceTemplate: NodeType.ServiceJobTemplate;
    multiSelect?: boolean;
    onSelect: (wo: NodeType.WorkOrderTemplate[]) => any;
    onCancel: () => any;
    preselectAllWO?: boolean;
}> = ({ serviceTemplate, onSelect, onCancel, multiSelect, preselectAllWO }) => {
    const woTemplates = fromEdges(serviceTemplate?.workOrderTemplateAssocs).map((a) => a.workOrderTemplate);

    const tableDef: ConnectionTableDef<NodeType.WorkOrderTemplate> = [
        {
            header: 'Work Order Template',
            width: 12,
            cell: (item) => {
                return <>{item.templateTitle}</>;
            },
        },
    ];

    const handleOnSelect = (wos: NodeType.WorkOrderTemplate[]) => {
        onSelect?.(wos);
    };

    return (
        <div>
            <Segment>
                <p>
                    <Icon name={ModuleIconNames.ServiceCase} /> Service Template: {serviceTemplate?.templateTitle}
                </p>
                <p>Please select work order template.</p>
            </Segment>
            <TablePickerPanel
                multiSelect={!!multiSelect}
                panelHeader={{ content: 'Work Order Template' }}
                onCancel={onCancel}
                onSubmit={handleOnSelect}
                defaultSelectedItems={preselectAllWO ? woTemplates : []}
                connectionData={woTemplates}
                tableDef={tableDef}
            />
        </div>
    );
};

export interface ServiceJobTemplatePickerProps {
    onSubmit: (
        serviceJobTemplate: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => any;
    onCancel?: () => any;
    // if true, optionally select single work order templates
    selectWorkOrder?: false | true | 'single' | 'multiple';
    requiredWorkOrder?: boolean;
    defaultSelectAllWorkOrders?: boolean;
}

export const ServiceJobTemplatePicker: React.FC<ServiceJobTemplatePickerProps> = ({
    onCancel,
    onSubmit,
    requiredWorkOrder = false,
    selectWorkOrder = false,
    defaultSelectAllWorkOrders = false,
}) => {
    const [groupId, setGroupId] = useState(null);
    const [selectedSJ, setSelectedSJ] = useState<NodeType.ServiceJobTemplate>(null);
    const [selectingWO, setSelectingWO] = useState(false);
    const { connectionData: serviceJobGroups } = useQueryServiceJobGroupConnection({});
    const { connectionState, connectionData } = useQueryServiceJobTemplateConnection({
        groupId: groupId,
        pageSize: 10,
    });

    const options = useMemo(
        () =>
            mapServiceJobGroupsToOptions(serviceJobGroups, {
                hideJobTemplatesL2: true,
                hideJobTemplatesL3: true,
            }),
        [serviceJobGroups]
    );

    const tableDef: ConnectionTableDef<NodeType.ServiceJobTemplate> = [
        {
            header: 'Template',
            width: 12,
            cell: (item) => {
                return <ServiceJobTemplatePreview serviceJobTemplate={item} />;
            },
        },
        {
            header: 'Group',
            width: 3,
            cellProps: { verticalAlign: 'top' },
            cell: (item) => {
                if (!item.group) {
                    return <i>--</i>;
                }
                return <span className={'font-bold text-purple-600'}>{item.group?.title}</span>;
            },
        },
    ];

    const handleOnSelectSJTemplate = (sjTemplates: NodeType.ServiceJobTemplate[]) => {
        const template = sjTemplates?.[0];
        if (!template) {
            throw new Error('Expected Template');
        }
        const woTemplates = fromEdges(template?.workOrderTemplateAssocs).map((a) => a.workOrderTemplate);

        if (!selectWorkOrder) {
            onSubmit?.(template, woTemplates);
        } else if (!!selectWorkOrder && woTemplates.length > 1) {
            setSelectedSJ(template);
            setSelectingWO(true);
        } else {
            onSubmit?.(template, woTemplates);
        }
    };

    const handleOnSelectWOTemplate = (woTemplates: NodeType.WorkOrderTemplate[]) => {
        setSelectedSJ(null);
        setSelectingWO(false);
        onSubmit?.(selectedSJ, woTemplates);
    };

    const getRowSelectable = (item: NodeType.ServiceJobTemplate) => {
        return !(requiredWorkOrder && fromEdges(item?.workOrderTemplateAssocs).length === 0);
    };

    if (selectingWO) {
        return (
            <Segment>
                <WorkOrderPicker
                    serviceTemplate={selectedSJ}
                    multiSelect={selectWorkOrder === 'multiple'}
                    onSelect={handleOnSelectWOTemplate}
                    preselectAllWO={defaultSelectAllWorkOrders}
                    onCancel={() => {
                        setSelectingWO(false);
                    }}
                />
            </Segment>
        );
    }

    return (
        <div className={'flex gap-2 p-2'}>
            <div className={'w-96'}>
                <ActionBarFilterByServiceJobGroup
                    setServiceJobGroup={setGroupId}
                    value={groupId}
                    options={options}
                    maxHeight={650}
                    collapsable={false}
                />
            </div>
            <div className={'flex-1'}>
                <TablePickerPanel
                    isRowSelectable={getRowSelectable}
                    multiSelect={false}
                    panelHeader={{ content: 'Service Case Template' }}
                    onCancel={onCancel}
                    onSubmit={handleOnSelectSJTemplate}
                    hideItems={[]}
                    connectionState={connectionState}
                    connectionData={connectionData}
                    tableDef={tableDef}
                />
            </div>
        </div>
    );
};
