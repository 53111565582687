import { NavbarMenuSection, NavbarRoot } from './NavbarRoot';
import { NavbarLogo } from './NavbarLogo';
import { NavbarMenuItem, NavbarMenuLabel } from './NavbarMenuItem';
import { NavbarDropdownMenu, NavbarDropdownMenuItem } from './NavbarDropdownMenu';

export const Navbar = {
    Root: NavbarRoot,
    MenuSection: NavbarMenuSection,
    Logo: NavbarLogo,
    MenuItem: NavbarMenuItem,
    MenuLabel: NavbarMenuLabel,
    DropdownMenu: NavbarDropdownMenu,
    DropdownMenuItem: NavbarDropdownMenuItem,
};
