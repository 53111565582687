import * as React from 'react';
import { PrintPageMenuBar } from './PrintPageMenuBar';
import styled from 'styled-components';

const PrintPageContainerDiv = styled.div`
    @page {
        size: A4;
    }

    padding: 0.5cm;

    @media screen {
        background-color: white;
    }

    @media print {
        background-color: unset;
    }

    -webkit-print-color-adjust: exact !important;
`;

const PrintAreaDiv = styled.div`
    font-family: sans-serif;
    font-size: 9pt;
    line-height: 1.2em;
    @media screen {
        margin: 80px 70px 100px 40px;
        max-width: 18cm;
        background-color: white;
    }

    @media screen and (max-width: 20cm) {
        margin-left: 0;
    }

    @media print {
        a {
            color: unset;
        }
    }
`;

interface PrintPageContainerProps {
    menuComponent?: any;
    onCancel?: any;
}

export class PrintPageContainer extends React.Component<PrintPageContainerProps> {
    render() {
        return (
            <PrintPageContainerDiv>
                <PrintPageMenuBar onCancel={this.props.onCancel}>{this.props.menuComponent}</PrintPageMenuBar>
                <PrintAreaDiv>{this.props.children}</PrintAreaDiv>
            </PrintPageContainerDiv>
        );
    }
}
