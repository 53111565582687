import React, { useContext } from 'react';
import { CalendarComponentsProps, CalendarGettersProps } from './CalendarPropsTypes';
import { CalEvent, CalResource, CalViewMode } from './types';
import { CalToolbarProps } from './Toolbar';
import { DateLocalizer } from './localizer';

export interface CalendarContextAccessorsFns {
    start: (any) => any;
    end: (any) => any;
    allDay: (any) => any;
    title: (any) => any;
    resource: (event: CalEvent) => any;
    resourceId: (resource: CalResource) => any;
    resourceTitle: (any) => any;
}

export interface CalendarContextValueType {
    localizer: DateLocalizer;
    culture: any;
    longPressThreshold: number;
    accessors: CalendarContextAccessorsFns;
    getters: CalendarGettersProps;
    rtl?: boolean;
    viewNames: (CalViewMode | string)[];
    components: {
        rootViewWrapper: React.ElementType;
        eventContainerWrapper: React.ElementType;
        event: React.ElementType;
        eventWrapper: React.ElementType;
        dateCellWrapper: React.ElementType;
        weekWrapper: React.ElementType;
        timeSlotWrapper: React.ElementType;
        timeGutterHeader: React.ElementType;
        resourceHeader: React.ElementType;
        toolbar: React.ElementType<CalToolbarProps>;
        header: React.ElementType;
    } & CalendarComponentsProps;
}

export const RBCContext = React.createContext<CalendarContextValueType>(null);

export const useRbcContext = () => useContext(RBCContext);
