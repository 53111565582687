import * as React from 'react';
import { useEffect, useState } from 'react';
import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps';
import { Icon } from '@ez/components';
import { AppErrorBoundary } from '@poolware/app-shell';
import { resolveCoordinates } from './data';
import { EZCDevicesLinkWithState } from '../use-query-ezc-device-states';
import { DeviceStateType, LifeGuardShadowType } from '../../View/TabDeviceState/types';

export interface DeviceMapProps {
    selectedDevice: EZCDevicesLinkWithState | null;
    onSelectDevice: (deviceLink: EZCDevicesLinkWithState | null) => void;
    connectionData: EZCDevicesLinkWithState[];
}

const defaults = {
    center: { lat: -33.7863024, lng: 151.1041411 },
    zoom: 10,
};

export const DeviceMap: React.FC<DeviceMapProps> = ({ onSelectDevice, selectedDevice, connectionData }) => {
    const [cameraCenter, setCameraCenter] = useState<any>(defaults.center);
    const [cameraZoom, setCameraZoom] = useState<any>(defaults.zoom);

    useEffect(() => {
        if (!selectedDevice) {
            // setCameraCenter(defaults.center);
            // setCameraZoom(defaults.zoom);
        } else {
            const loc = resolveCoordinates(selectedDevice);
            setCameraCenter(loc);
            // setCameraZoom(18);
        }
    }, [selectedDevice]);

    useEffect(() => {
        // find bounding box of all devices
        const bounds = connectionData.reduce(
            (acc, device) => {
                const coordinates = resolveCoordinates(device);
                if (!coordinates) return acc;
                if (!acc.min || !acc.max || !acc.center) {
                    return { min: coordinates, max: coordinates, center: coordinates };
                }
                const bound = {
                    min: {
                        lat: Math.min(acc.min.lat, coordinates.lat),
                        lng: Math.min(acc.min.lng, coordinates.lng),
                    },
                    max: {
                        lat: Math.max(acc.max.lat, coordinates.lat),
                        lng: Math.max(acc.max.lng, coordinates.lng),
                    },
                };
                const center = {
                    lat: (bound.min.lat + bound.max.lat) / 2,
                    lng: (bound.min.lng + bound.max.lng) / 2,
                };
                return { ...bound, center };
            },
            { min: undefined, max: undefined, center: undefined }
        );

        if (bounds.center) {
            setCameraCenter(bounds.center);
            setCameraZoom(12);
        }
    }, [connectionData]);

    const resetCenter = () => {
        setCameraCenter(undefined);
    };
    const resetZoom = () => {
        setCameraZoom(undefined);
    };

    return (
        <AppErrorBoundary>
            {/*<APIProvider apiKey={conf.google_maps.apiToken}>*/}
            <Map
                defaultCenter={cameraCenter}
                defaultZoom={cameraZoom}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                zoomControl={true}
                mapTypeControl={true}
                fullscreenControl={true}
                mapId={'bf51a910020fa25a'}
                id={'DEVICES'}
                reuseMaps={true}
                onDrag={resetCenter}
                onZoomChanged={resetZoom}
                center={cameraCenter}
                zoom={cameraZoom}
                controlled={false}
            >
                {connectionData.map((device, i) => {
                    const coordinates = resolveCoordinates(device);
                    if (!coordinates) return null;

                    const isActive = selectedDevice?.id === device?.id;
                    const shadowState: DeviceStateType<LifeGuardShadowType> =
                        device.deviceState?.state?.lifeguard || {};
                    const isOnline = shadowState?.isAlive;
                    const position = { lat: coordinates.lat, lng: coordinates.lng };
                    return (
                        <AdvancedMarker
                            key={device.id}
                            onClick={(event) => onSelectDevice(device)}
                            position={position}
                            title={'AdvancedMarker with customized pin.'}
                        >
                            <Pin
                                background={isOnline ? '#55b555' : '#AAAAAA'}
                                borderColor={isActive ? '#000000' : 'f6a000'}
                                scale={1}
                            >
                                <span>
                                    <Icon name={'microchip'} />
                                </span>
                            </Pin>
                        </AdvancedMarker>
                    );
                })}
            </Map>
            {/*</APIProvider>*/}
        </AppErrorBoundary>
    );
};
