import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

import { cn } from '../../utils';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function CalendarPicker({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('p-2 inline-flex', className)}
            classNames={{
                vhidden: 'rdp-vhidden',
                button: 'rdp-button',
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
                month: 'space-y-4',
                caption: 'flex flex-row justify-center pt-1 relative items-center',
                caption_label: 'rdp-caption_label',
                caption_dropdowns: 'flex gap-2',
                dropdown_month: 'rdp-dropdown_month',
                nav: 'space-x-1 flex items-center',
                nav_button: cn('ez-btn-base', 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'),
                nav_button_previous: 'absolute left-1 rounded',
                nav_button_next: 'absolute right-1 rounded',
                table: 'w-full border-collapse space-y-1',
                head_row: 'flex',
                head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
                row: 'rdp-row',
                cell: 'rdp-cell',
                day: 'rdp-day',
                day_selected: 'rdp-day_selected',
                day_today: 'rdp-day_today',
                day_outside: 'rdp-day_outside',
                day_disabled: 'rdp-day_disabled',
                day_hidden: 'invisible',
                weeknumber: 'rdp-weeknumber',
                day_range_start: 'rdp-day_range_start',
                day_range_end: 'rdp-day_range_end',
                day_range_middle: 'rdp-day_range_middle',
                ...classNames,
            }}
            components={{
                IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
                IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
            }}
            {...props}
        />
    );
}

CalendarPicker.displayName = 'CalendarPicker';

export { CalendarPicker };
