import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { CalResourceMode, CalViewMode } from '../types';
import { fromEdges, NodeType } from '@poolware/api';
import { stringFormatters } from '@ez/components';
import { CalendarDaySummaryItem, CalendarEventDensity, CalendarEventMode, CalendarEventType } from './types';
import { UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import { CalendarStateType } from '../../redux';
import { CalendarEventAddressAreaType, CalendarEventStreetType, getStreetSummaryKeys } from './street-summaries';
import { ModulesAccess } from '@poolware/app-shell';

const { formatEntityName } = stringFormatters;

export type CalendarResourceType = {
    id: string;
    plainTitle: string;
    title: string | React.ReactNode;
    teams?: NodeType.StaffTeam[];
};

export type CalendarEventGroupType = {
    events: CalendarEventType[];
    resources?: CalendarResourceType[];
    streetsSummary?: CalendarEventStreetType[];
    areaSummary?: CalendarEventAddressAreaType[];
};

const _UnsignedStaffObj: CalendarResourceType = {
    id: UNASSIGNED_APPOINTMENT_STAFF_ID,
    plainTitle: 'Unassigned',
    title: <i className={'text-gray-600 font-normal'}>Unassigned</i>,
};

export const convertAppointmentItemsToCalendarEvents = (input: {
    appointmentItems: NodeType.AppointmentItem[];
    staffList?: NodeType.Staff[];
    previewApptId?: NodeType.ID;
    CalendarState: CalendarStateType;
    modulesAccess: ModulesAccess;
}): CalendarEventGroupType => {
    const { previewApptId, appointmentItems, CalendarState, modulesAccess } = input;
    let { staffList = [] } = input;
    const { resourceMode, viewMode, highlightedAppointment } = CalendarState;

    /***
     * Performance optimisation heuristics
     */
    const totalCount = appointmentItems.length;
    let dataDensity = CalendarEventDensity.Normal;
    // if (totalCount > 500) {
    //     dataDensity = CalendarEventDensity.Low;
    // } else if (CalendarState.viewMode === CalViewMode.WEEK) {
    //     dataDensity = totalCount > 180 ? CalendarEventDensity.Reduced : CalendarEventDensity.Normal;
    // } else if (CalendarState.viewMode === CalViewMode.DAY) {
    //     dataDensity = totalCount > 60 ? CalendarEventDensity.Reduced : CalendarEventDensity.Normal;
    // }
    /****/

    const hasHoverStreets = CalendarState.filters.hoverStreets?.length > 0;
    const hasStreets = CalendarState.filters.streets?.length > 0;
    const teamIdFilter = CalendarState.filters?.teamId;
    const hasStaffFilter = CalendarState.filters?.staffIds?.length > 0;

    const shouldFilterByStaffId = !!teamIdFilter || hasStaffFilter;

    // Filter provided list of staff using teamID, and staffID filters if needed.
    if (!(staffList instanceof Array)) {
        staffList = [];
    }

    let whiteListedStaff = staffList
        .map<CalendarResourceType>((staff) => {
            const plainTitle = formatEntityName(staff);
            return {
                id: staff.id,
                plainTitle,
                title: plainTitle,
                teams: fromEdges(staff.teams),
            };
        })
        .filter((staff) => {
            // return true;
            if (!teamIdFilter) {
                return true;
            }
            return !!staff.teams?.find((t) => t.id === teamIdFilter);
        })
        .concat(_UnsignedStaffObj)
        .filter((staff) => {
            if (!hasStaffFilter) {
                return true;
            }
            const staffIds = CalendarState.filters.staffIds;
            return !!staffIds.find((id) => id === staff.id);
        });

    const filteredAppItems = !shouldFilterByStaffId
        ? appointmentItems
        : appointmentItems.filter((appointmentItem) => {
              const resourceId = appointmentItem?.staff?.id || UNASSIGNED_APPOINTMENT_STAFF_ID;
              return !!whiteListedStaff.find((s) => s.id === resourceId);
          });

    if (viewMode === CalViewMode.MONTH) {
        let eventMap = filteredAppItems.reduce((acc, ai) => {
            const timestamp = moment(ai.startDate).startOf('day').toISOString(true);
            const currentCount = acc[timestamp] || 0;
            acc[timestamp] = currentCount + 1;
            return acc;
        }, {});

        const events = Object.keys(eventMap).map<CalendarEventType>((key, i) => {
            const count = eventMap[key];
            const daySummary: CalendarDaySummaryItem = { count };
            const start = moment(key).toDate();
            return {
                id: `${key}_${i}`,
                highlighted: false,
                highlightedPop: false,
                resourceId: null,
                selected: false,
                staff: null,
                mode: CalendarEventMode.DAY_SUMMARY,
                item: null,
                daySummary: daySummary,
                start: start,
                end: moment(key).add(1, 'h').toDate(),
                duration: 60,
                formattedDate: '',
            };
        });

        return {
            events,
        };
    }

    let events = filteredAppItems
        .map<CalendarEventType>((appointmentItem) => {
            if (!appointmentItem) return null;
            const { startDate, duration } = appointmentItem;

            const start = moment(startDate);
            const end = start.clone().add(duration, 'm');
            const staff = appointmentItem.staff;
            const resourceId = appointmentItem.staff?.id || UNASSIGNED_APPOINTMENT_STAFF_ID;

            const keys = getStreetSummaryKeys(appointmentItem);

            if (CalendarState.filters.showWithAddressOnly && !Boolean(keys?.streetKey)) {
                return null;
            }

            const streetKey = keys?.streetKey;
            const areaKey = keys?.areaKey;

            const isStreetMatch =
                CalendarState.filters.areas?.includes(areaKey) ||
                CalendarState.filters.hoverAreas?.includes(areaKey) ||
                CalendarState.filters.streets?.includes(streetKey) ||
                CalendarState.filters.hoverStreets?.includes(streetKey);

            const highlighted = isStreetMatch;
            const highlightedPop = highlightedAppointment?.id === appointmentItem?.id;
            const selected = previewApptId === appointmentItem?.id;

            const isDimmed = ((hasHoverStreets || hasStreets) && !isStreetMatch) || (previewApptId && !selected);

            return {
                id: appointmentItem?.id,
                hidden: false,
                highlighted: highlighted,
                highlightedPop: highlightedPop,
                dimmed: isDimmed,
                streetKey: streetKey,
                mode: CalendarEventMode.APPOINTMENT_ITEM,
                item: appointmentItem,
                resourceId: resourceId,
                staff: staff,
                selected: selected,
                start: start.toDate(),
                end: end.toDate(),
                duration: duration,
                formattedDate: start.format('LT'),
                dataDensity: dataDensity,
            };
        })
        .filter(Boolean);

    let resources = undefined;

    const isSplit =
        !modulesAccess.FieldServices?.calendarSingleStaffMode &&
        resourceMode === CalResourceMode.SPLIT &&
        (viewMode === CalViewMode.DAY || (viewMode === CalViewMode.WEEK && whiteListedStaff.length < 5));

    if (isSplit) {
        let allResources;
        if (hasStaffFilter) {
            // Staff filter is set.
            allResources = whiteListedStaff;
        } else {
            const apptStaff = _.uniqBy(events, ({ resourceId }) => resourceId)
                .filter(Boolean)
                .map(({ resourceId, staff }) => {
                    if (resourceId === UNASSIGNED_APPOINTMENT_STAFF_ID) {
                        return _UnsignedStaffObj;
                    } else {
                        const plainTitle = formatEntityName(staff);
                        return {
                            id: staff.id,
                            plainTitle: plainTitle,
                            title: plainTitle,
                        };
                    }
                });
            const shouldAutoHide = CalendarState.filters.autoHideEmptyResources;
            if (shouldAutoHide) {
                allResources = apptStaff;
            } else {
                allResources = _.unionBy([_UnsignedStaffObj], whiteListedStaff, apptStaff, ({ id }) => id);
            }
        }

        const sortColumnsFN = (a: CalendarResourceType, b: CalendarResourceType) => {
            if (a.id === UNASSIGNED_APPOINTMENT_STAFF_ID) return -1;
            if (b.id === UNASSIGNED_APPOINTMENT_STAFF_ID) return 1;
            return a.plainTitle > b.plainTitle ? 1 : -1;
        };

        allResources = allResources.sort(sortColumnsFN);

        if (allResources.length > 0) {
            resources = allResources;
        }
    }

    let res: CalendarEventGroupType = {
        events: events,
        resources: resources,
    };

    return res;
};
