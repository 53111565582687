import * as React from 'react';
import { useState } from 'react';
import {
    Alert,
    Dropdown,
    DropdownProps,
    Icon,
    MenuBar,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    Modal,
    PageLayout,
    Panel,
    SectionHeader,
    StickyMenuBar,
    toastError,
    useModalCtrl,
    VStack,
} from '@ez/components';
import { Checkbox } from '@ez/components';
import { FormEditServiceTemplateGroup, FormNewServiceTemplateGroup } from './FormServiceTemplateGroup';
import { useQueryServiceJobGroupConnection } from '../../../queries/use-query-service-job-group-connection';
import { fromEdges, NodeType, useMutationServiceJobGroup } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useViewer } from '@poolware/app-shell';

export interface JobGroupsEditorProps {}

const DropdownButton: React.FC<{ content?: React.ReactNode; isHQ?: boolean } & DropdownProps> = ({
    content,
    isHQ,
    ...props
}) => {
    return (
        <Dropdown
            style={{ padding: '0.4em 0.5em' }}
            className="button tiny icon"
            floating
            size={'tiny'}
            basic={true}
            value={false}
            icon={isHQ ? 'building outline' : 'ellipsis vertical'}
            trigger={<>{content}</>}
            {...props}
        />
    );
};

export const ServiceGroupsEditor: React.FC<JobGroupsEditorProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const { viewer } = useViewer();
    const modalCtrlNew = useModalCtrl<{ parentGroup: NodeType.ServiceJobGroup }>();
    const modalCtrlEdit = useModalCtrl();
    const [selectedParent, setSelectedParent] = useState<NodeType.ServiceJobGroup>();
    const [selectedChild, setSelectedChild] = useState<NodeType.ServiceJobGroup>();
    const [ediGroup, setEditGroup] = useState<NodeType.ServiceJobGroup>(null);

    const { connectionData, refetchQuery } = useQueryServiceJobGroupConnection();

    const mutation = useMutationServiceJobGroup({ refetchQueries: [refetchQuery] });

    const myFranchiseId = viewer?.franchise?.id;
    const onGoBack = () => {
        AppNavigator.navigateRelative('/');
    };

    const onEditGroup = (ag: NodeType.ServiceJobGroup) => {
        setEditGroup(ag);
        modalCtrlEdit.onOpen();
    };

    const onDoneEditGroup = () => {
        setEditGroup(null);
        modalCtrlEdit.onClose();
    };

    const onDidDeleteGroup = () => {
        setEditGroup(null);
        modalCtrlEdit.onClose();
    };

    const onStartSubgroup = (parentGroup: NodeType.ServiceJobGroup) => {
        modalCtrlNew.onOpen({ parentGroup });
    };

    const groups = fromEdges(connectionData);

    const onStartSetParent = (group: NodeType.ServiceJobGroup) => {
        setSelectedChild(group);
    };

    const onDoneParentSelection = () => {
        setSelectedChild(null);
        setSelectedParent(null);
    };

    const onConfirmParentGroup = async () => {
        if (!selectedChild || !selectedParent) {
            console.error('Child and parent group must be selected');
            return;
        }
        try {
            await mutation.update({
                id: selectedChild.id,
                parent: selectedParent.id,
            });
            onDoneParentSelection();
        } catch (e) {
            toastError(e);
        }
    };

    const onRemoveParent = async (group: NodeType.ServiceJobGroup) => {
        if (!group) {
            console.error('group is not provided');
            return;
        }

        try {
            await mutation.update({ id: group.id, parent: null });
        } catch (e) {
            toastError(e);
        }
    };

    const isSelecting = !!selectedChild;

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBar.Item onClick={onGoBack} icon={'chevron left'} title={'Back'} />
                    <MenuBarHeaderItem>Service Groups</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    {/*<DebugJsonButton data={groups} />*/}
                    <MenuBarItem icon={'plus'} color={'green'} onClick={() => modalCtrlNew.onOpen()}>
                        New
                    </MenuBarItem>
                </MenuBarSection>
            </StickyMenuBar>

            <PageLayout.BodySection width={'screen-sm'}>
                <Panel>
                    <Panel.Header
                        button={
                            !!selectedChild && {
                                content: 'Cancel',
                                primary: true,
                                onClick: onDoneParentSelection,
                            }
                        }
                    >
                        Service Groups
                    </Panel.Header>
                    <Panel.Body>
                        {isSelecting && <Alert>Select Parent Group for "{selectedChild?.title}"</Alert>}
                        <div className={'flex flex-col gap-2'}>
                            {groups.map((group, index) => {
                                const subGroups = fromEdges(group.children);
                                const hasSubgroups = subGroups.length > 0;
                                const isSelectedParent = selectedParent?.id === group.id;
                                const isSelectedChild = selectedChild?.id === group.id;
                                const canSetParent = !hasSubgroups;
                                const canAddSubgroup = true;
                                const canEdit = group.checkMutations?.update && group.checkMutations?.delete;
                                const isHQ = !!group.franchise && group.franchise?.id !== myFranchiseId;
                                const options = [
                                    {
                                        key: 'edit',
                                        icon: 'edit',
                                        text: 'edit',
                                        value: 'edit',
                                        onClick: () => onEditGroup(group),
                                    },
                                    canSetParent && {
                                        key: '2',
                                        icon: 'linkify',
                                        text: 'Convert To Subgroup',
                                        value: 'edit',
                                        onClick: () => onStartSetParent(group),
                                    },
                                    canAddSubgroup && {
                                        key: '3',
                                        icon: 'plus',
                                        text: 'Add Subgroup',
                                        onClick: () => onStartSubgroup(group),
                                    },
                                ].filter(Boolean);

                                const titleDivStyle =
                                    isSelecting && !isSelectedChild
                                        ? {
                                              cursor: 'pointer',
                                          }
                                        : undefined;
                                return (
                                    <div key={index} className={'bg-gray-100 p-2 rounded'}>
                                        <div
                                            className={'flex flex-row h-6'}
                                            style={isSelectedChild ? { opacity: '0.8', color: '#004469' } : undefined}
                                        >
                                            {isSelecting && (
                                                <div className={'w-8'}>
                                                    {!isSelectedChild && (
                                                        <Checkbox
                                                            radio={true}
                                                            checked={isSelectedParent}
                                                            onClick={() => setSelectedParent(group)}
                                                        />
                                                    )}
                                                </div>
                                            )}

                                            <div
                                                className={'flex-grow'}
                                                style={titleDivStyle}
                                                onClick={isSelecting ? () => setSelectedParent(group) : undefined}
                                            >
                                                <Icon name={'folder outline'} />
                                                {group.title}
                                            </div>

                                            {isSelectedParent && (
                                                <SectionHeader.Button
                                                    size={'mini'}
                                                    basic={false}
                                                    color={'green'}
                                                    content={'Set'}
                                                    onClick={() => onConfirmParentGroup()}
                                                />
                                            )}

                                            {!isSelecting && (
                                                <div className={'pr-2'}>
                                                    <DropdownButton isHQ={isHQ} disabled={!canEdit} options={options} />
                                                </div>
                                            )}
                                        </div>
                                        {hasSubgroups && (
                                            <div
                                                className={'flex flex-col gap-2 mt-2'}
                                                style={isSelecting ? { opacity: '0.4' } : undefined}
                                            >
                                                {subGroups.map((sg, n) => {
                                                    const isSelectedChild = selectedChild?.id === sg.id;
                                                    const canEdit =
                                                        sg.checkMutations?.update && sg.checkMutations?.delete;
                                                    const isHQ = !!sg.franchise && sg.franchise?.id !== myFranchiseId;

                                                    const options = [
                                                        {
                                                            key: 'edit',
                                                            icon: 'edit',
                                                            text: 'edit',
                                                            value: 'edit',
                                                            onClick: () => onEditGroup(sg),
                                                        },
                                                        {
                                                            key: '2',
                                                            icon: 'linkify',
                                                            text: 'Change Parent Group',
                                                            value: '2',
                                                            onClick: () => onStartSetParent(sg),
                                                        },
                                                        {
                                                            key: '3',
                                                            icon: 'unlinkify',
                                                            text: 'Remove from subgroup',
                                                            value: '3',
                                                            onClick: () => onRemoveParent(sg),
                                                        },
                                                    ].filter(Boolean);

                                                    const style: any = {};
                                                    if (isSelecting) {
                                                        style.paddingLeft = '1em';
                                                    }
                                                    if (isSelectedChild) {
                                                        style.fontWeight = 'bold';
                                                        style.color = '#2e68b3';
                                                    }

                                                    return (
                                                        <div
                                                            key={n}
                                                            className={'ml-6 bg-gray-50 p-2 rounded flex flex-row'}
                                                        >
                                                            <div className={'flex-grow'} style={style}>
                                                                <Icon name={'folder outline'} />
                                                                {sg.title}
                                                            </div>
                                                            {!isSelecting && (
                                                                <DropdownButton
                                                                    isHQ={isHQ}
                                                                    disabled={!canEdit}
                                                                    options={options}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </Panel.Body>
                </Panel>
            </PageLayout.BodySection>

            <Modal {...(modalCtrlNew as any)}>
                <FormNewServiceTemplateGroup
                    onCancel={modalCtrlNew.onClose}
                    onDone={modalCtrlNew.onClose}
                    refetchQueries={refetchQuery}
                    parentGroup={modalCtrlNew.payload?.parentGroup}
                />
            </Modal>

            <Modal {...modalCtrlEdit}>
                <FormEditServiceTemplateGroup
                    onCancel={onDoneEditGroup}
                    onDone={onDoneEditGroup}
                    onDidDelete={onDidDeleteGroup}
                    group={ediGroup}
                    refetchQueries={refetchQuery}
                />
            </Modal>
        </VStack>
    );
};
