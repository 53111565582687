import * as React from 'react';
import { ConnectionErrorMessage, PageSkeletonLoader } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { InvoiceDocView } from './InvoiceDocView';
import { useQueryAbstractDoc } from '../queries/use-query-abstract-doc';
import { AppErrorBoundary, NotFoundPage } from '@poolware/app-shell';

export const InvoicePagePrintView: React.FC = () => {
    const { params } = useAppNavigator();
    const { loading, error, node: doc } = useQueryAbstractDoc(params.id);

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !doc) {
        return <NotFoundPage />;
    }

    return (
        <AppErrorBoundary>
            <InvoiceDocView doc={doc} />
        </AppErrorBoundary>
    );
};
