import * as React from 'react';
import { useServiceJobListActions } from '../../redux';
import { Optionable, SegmentedSelectButtons } from '@ez/components';
import { jobStageTypeOptions, ModuleColorNames } from '../../constants';
import { TopFilterBar } from '../../components/TopFilterBar';
import { RECUR_FILTER } from '../../redux/helpers';
import { StageFilterButtons } from '../../components/ActionBarFilters/ActionBarFilterByServiceJobStageType';

/*
export const FilterByStatusButtons: React.FC = () => {
    const { State, Actions } = useServiceJobListActions();

    const { includeStatuses } = State.filters;

    const ALL = '___ALL___';

    const allOptions = JobStatusOptions.map<Optionable<any>>((s) => ({
        text: s.text,
        icon: s.icon.name,
        value: s.value,
    }));

    allOptions.unshift({
        text: 'All',
        value: ALL,
    });

    const currentValue = includeStatuses[0] !== undefined ? includeStatuses[0] : ALL;

    return (
        <SegmentedSelectButtons
            fluid={false}
            color={ModuleColorNames.ServiceCall}
            options={allOptions}
            value={currentValue}
            onChange={(o) => {
                if (o.value === ALL) {
                    Actions.setStatusFilter([]);
                } else {
                    Actions.setStatusFilter([o.value]);
                }
            }}
        />
    );
};

 */

export const FilterByJobStageTypeButtons2: React.FC = () => {
    const { State, Actions } = useServiceJobListActions();

    const { serviceStageType } = State.filters;

    const ALL = '___ALL___';

    const allOptions = jobStageTypeOptions.map<Optionable<any>>((s) => ({
        text: s.text,
        icon: s.icon?.name,
        value: s.value,
    }));

    allOptions.unshift({
        text: 'All',
        value: ALL,
    });

    const currentValue = serviceStageType?.length > 0 ? serviceStageType : [ALL];

    const valueComparator = (value, option: Optionable<any>) => {
        return value?.includes(option?.value);
    };

    return (
        <SegmentedSelectButtons
            fluid={false}
            color={ModuleColorNames.ServiceCall}
            options={allOptions}
            value={currentValue}
            valueComparator={valueComparator}
            onChange={(o) => {
                if (o.value === ALL) {
                    Actions.setServiceStageType([]);
                } else {
                    Actions.setServiceStageType([o.value]);
                }
            }}
        />
    );
};

export const FilterByJobStageTypeButtons: React.FC = () => {
    const { State, Actions } = useServiceJobListActions();

    return (
        <StageFilterButtons
            color={ModuleColorNames.ServiceCall}
            setServiceJobStageType={Actions.setServiceStageType}
            setServiceJobStageStatus={Actions.setServiceStageStatus}
            valueStageTypes={State.filters.serviceStageType}
            valueStageStatus={State.filters.serviceStageStatus}
        />
    );
};

export const FilterByTypeButtons: React.FC = () => {
    const { State, Actions } = useServiceJobListActions();

    const { isRecurring } = State.filters;

    const allOptions: Optionable<any>[] = [
        {
            text: 'Recurring',
            value: RECUR_FILTER.RECURRING,
        },
        {
            text: 'One off',
            value: RECUR_FILTER.ONEOFF,
        },
    ];

    return (
        <SegmentedSelectButtons
            color={'blue'}
            fluid={false}
            options={allOptions}
            value={isRecurring}
            onChange={(v) => {
                Actions.setRecurFilter(v.value);
            }}
        />
    );
};

const FilterSummary: React.FC = () => {
    const { State, Actions } = useServiceJobListActions();
    const filters = State.filters;
    if (!filters?.hasActiveFilters) {
        return <div className={'filter-summary'} />;
    }

    return (
        <>
            <TopFilterBar.SummaryFilterIcon />
            {filters.serviceJobTemplateId || filters.serviceGroupId ? (
                <TopFilterBar.SummaryItem>Group</TopFilterBar.SummaryItem>
            ) : null}
            {filters.customerCRN ? <TopFilterBar.SummaryItem>CRN</TopFilterBar.SummaryItem> : null}
            {filters.staffIds?.length > 0 && <TopFilterBar.SummaryItem>Staff</TopFilterBar.SummaryItem>}
            {filters.workOrderNumber ? <TopFilterBar.SummaryItem>Work ID</TopFilterBar.SummaryItem> : null}
            {filters.serviceJobNumber ? <TopFilterBar.SummaryItem>Case ID</TopFilterBar.SummaryItem> : null}
            <TopFilterBar.SummaryClearButton onClick={Actions.clearAllFilters} />
        </>
    );
};

export const ToolbarQuickFilters = ({ showSidebar, setShowSidebar }) => {
    const { State } = useServiceJobListActions();
    const { hasActiveFilters } = State.filters;

    return (
        <TopFilterBar>
            <TopFilterBar.Row>
                <TopFilterBar.ShowHideToggle
                    showSidebar={showSidebar}
                    setShowSidebar={setShowSidebar}
                    hasActiveFilters={hasActiveFilters}
                />
                <TopFilterBar.Spacer />

                <FilterByJobStageTypeButtons />
                {/*<FilterByStatusButtons />*/}
            </TopFilterBar.Row>
            {hasActiveFilters && (
                <TopFilterBar.RowSummary>
                    <FilterSummary />
                </TopFilterBar.RowSummary>
            )}
        </TopFilterBar>
    );
};
