import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import Dashboard from './Dashboard';
import * as Stats from './Stats';

export const Router: React.FC = () => (
    <ModuleRootSwitch>
        <ModuleRoute path={'/'} exact component={Dashboard} />
        <ModuleRoute path={'/water-tests'} component={Stats.WaterTestStats} />
        <ModuleRoute path={'/new-customers'} component={Stats.NewCustomersStats} />
        <ModuleRoute path={'/appointments'} component={Stats.AppointmentsStats} />
    </ModuleRootSwitch>
);
