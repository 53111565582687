import * as React from 'react';
import { useState } from 'react';

import { useAppointmentReportsActions } from '../../../redux';
import { ActionBar, SuggestInput } from '@ez/components';
import { QueryFranchiseConnection } from '@poolware/api';

export interface FranchiseFilterExternalProps {
    label?: string;
}

export interface FranchiseFilterProps extends FranchiseFilterExternalProps {}

const FranchiseFilter: React.FC<FranchiseFilterProps> = ({ label }) => {
    const { AppointmentReportsState, AppointmentReportsAction } = useAppointmentReportsActions();
    const [acSearchQuery, setAcSearchQuery] = useState<string>('');

    const { franchise } = AppointmentReportsState.filters;

    const onChange = (value) => {
        AppointmentReportsAction.setFranchiseFilter(value);
    };

    const onQueryChange = (query: string) => {
        setAcSearchQuery(query);
    };

    const itemToText = (item) => {
        return item ? `${item.name}` : '';
    };

    return (
        <ActionBar>
            <ActionBar.Header content={'Franchise'} icon={'filter'} />
            <QueryFranchiseConnection searchName={acSearchQuery}>
                {({ connectionData, connectionState }) => {
                    return (
                        <SuggestInput
                            activeItem={franchise}
                            items={connectionData}
                            loading={connectionState.loading}
                            itemTextLabel={itemToText}
                            onItemSelect={onChange}
                            onQueryChange={onQueryChange}
                            isClearable={false}
                        />
                    );
                }}
            </QueryFranchiseConnection>
        </ActionBar>
    );
};

export default FranchiseFilter;
