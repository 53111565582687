import * as React from 'react';

import { SemanticCOLORS } from 'semantic-ui-react';
import { Icon, IconWithPopup, SemanticICONS } from '../Icon';
import { NodeTypeAddress, NodeTypeUser } from '../external-types';
import tw, { css, styled } from 'twin.macro';
import { Display } from '../display';
import { ViewJSON } from '../dev-tools';
import { clsxm } from '../utils';

const PanelItemDiv = styled.div<{ isWrap?: boolean }>(({ isWrap }) => [
    tw`flex flex-col items-start sm:flex-row flex-wrap sm:flex-nowrap`,
    tw`pb-2 sm:pb-1 last:pb-0`,
    isWrap === true ? tw`flex-wrap sm:flex-wrap` : undefined,
]);

const PanelItemLabelDiv = styled.div<{ preferredWidth?: string }>(({ preferredWidth }) => [
    tw`flex flex-grow-0 flex-shrink-0 sm:w-40 w-full pr-2 font-bold`,
    css`
        color: var(--ez-panel-item-label-color);
    `,
    preferredWidth &&
        css`
            @media (min-width: 640px) {
                flex-basis: ${preferredWidth};
            }
        `,
]);

const PanelItemContentDiv = tw.div`
    text-base
`;

export interface PanelItemProps {
    label?: String | React.ReactNode;
    labelInfo?: String | React.ReactNode;
    labelInfoIcon?: SemanticICONS;
    labelIcon?: SemanticICONS | React.ReactNode;
    content?: any;
    iconMarker?: SemanticICONS;
    debugOnly?: boolean;
    color?: SemanticCOLORS;
    labelWidth?: number | string;
    style?: any;
    wrap?: boolean;
}

export const PanelItem: React.FC<PanelItemProps> = ({
    label,
    labelIcon,
    labelInfo,
    labelInfoIcon,
    content,
    iconMarker,
    children,
    debugOnly,
    color,
    labelWidth,
    wrap = false,
    style: externalStyle = {},
}) => {
    if (process.env.NODE_ENV !== 'development' && debugOnly) {
        return null;
    }

    let style: any = externalStyle;
    if (debugOnly) {
        style = { color: '#eb685e', ...style };
    }
    if (color) {
        style = { ...style, color: color };
    }

    let lw = typeof labelWidth === 'number' ? `${labelWidth}px` : labelWidth;
    if (wrap) {
        lw = '100%';
    }

    const shouldWrap = Boolean(wrap || labelWidth); // Change to `wrap=true` if labelWidth is provided.

    let labelIconComp = undefined;
    if (labelIcon) {
        if (typeof labelIcon === 'string') {
            labelIconComp = <Icon name={labelIcon as SemanticICONS} />;
        } else {
            labelIconComp = labelIcon;
        }
    }
    return (
        <PanelItemDiv style={style} isWrap={shouldWrap}>
            <PanelItemLabelDiv preferredWidth={lw} style={{ color: style.color }}>
                {labelIconComp}
                {label}
                {labelInfo && (
                    <span className={'no-print'}>
                        <IconWithPopup
                            name={labelInfoIcon || 'info circle'}
                            style={{ color: '#bbbbbb', marginLeft: '0.5em' }}
                            popup={{ content: labelInfo }}
                        />
                    </span>
                )}
            </PanelItemLabelDiv>
            <div className={'flex flex-wrap flex-grow'} style={{ color: style.color }}>
                {iconMarker && <Icon name={iconMarker} />}
                {content}
                {children}
            </div>
        </PanelItemDiv>
    );
};

export type UserableNode =
    | NodeTypeUser
    | {
          user?: NodeTypeUser;
      };

export interface PanelItemDisplayProps<T> extends PanelItemProps {
    content?: T;
}

export const PanelItemEmail: React.FC<PanelItemDisplayProps<string>> = ({ label, content, ...rest }) => (
    <PanelItem label={label} {...rest}>
        <Display.Email value={content} />
    </PanelItem>
);

export const PanelItemPhone: React.FC<PanelItemDisplayProps<string>> = ({ label, content, ...rest }) => (
    <PanelItem label={label} {...rest}>
        <Display.Phone value={content} />
    </PanelItem>
);

export const PanelItemDate: React.FC<PanelItemDisplayProps<Date> & { format?: string; defaultString?: string }> = ({
    label,
    content,
    format = 'LLL',
    defaultString = '',
    ...rest
}) => (
    <PanelItem label={label} {...rest}>
        <Display.Date value={content} format={format} defaultString={defaultString} />
    </PanelItem>
);

export const PanelItemAddress: React.FC<PanelItemDisplayProps<NodeTypeAddress> & { showMapLink?: boolean }> = ({
    label,
    content,
    showMapLink,
    ...rest
}) => (
    <PanelItem label={label} {...rest}>
        <Display.Address value={content} showMapLink={showMapLink} />
    </PanelItem>
);

export const PanelItemEntity: React.FC<PanelItemDisplayProps<UserableNode>> = ({ label, content, ...rest }) => (
    <PanelItem label={label} {...rest}>
        <Display.Entity value={content} />
    </PanelItem>
);

export const PanelItemJSONView: React.FC<PanelItemDisplayProps<any>> = ({ label, content, ...rest }) => (
    <PanelItem label={label} {...rest}>
        <div className={'bg-tertiary my-1 p-0 w-full rounded text-sm'}>
            <ViewJSON data={content} />
        </div>
    </PanelItem>
);

export const PanelItemText: React.FC<
    PanelItemDisplayProps<string> & {
        limit?: number;
        formatted?: boolean;
        maxHeightPx?: number;
        backgroundColor?: string;
    }
> = ({ label, content, formatted, maxHeightPx = 260, limit, children, backgroundColor, ...rest }) => (
    <PanelItem label={label} wrap={true} {...rest}>
        <Display.Text
            limit={limit}
            formatted={formatted}
            maxHeightPx={maxHeightPx}
            value={content || ' '} // If empty, add a whitespace. It will display at least one empty line of the text box
            className={'bg-notes'}
            style={{
                // backgroundColor: backgroundColor || 'hsl(210, 12%, 94%)',
                padding: '0 0.5em',
                borderRadius: '4px',
                minHeight: '2em',
                width: '100%',
            }}
        />
    </PanelItem>
);

export const PanelItemDivider: React.FC<{ hidden?: boolean; className?: string }> = ({
    hidden,
    className,
    ...props
}) => {
    return <div className={clsxm('my-2', !hidden && 'border-t border-panel', className)} {...props} />;
};
