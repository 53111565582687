import Page from './Page';
import gql from 'graphql-tag';
import { compose, mapProps } from '@ez/tools';
import { graphql } from 'react-apollo';
import { fromEdges, NodeType, staffFragment, StaffMutatorProps, withStaffMutators } from '@poolware/api';
import { withApolloLoading } from '@ez/components';
import { IWithPageProps, withPageProps } from '../../../components/withPageProps';

const StaffDetailsQuery = gql`
    query StaffDetailsQuery($staffId: ID!) {
        staff: node(id: $staffId) {
            ...StaffFragment
        }
        viewer {
            roles(first: 1000) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
    ${staffFragment}
`;

export default compose(
    withPageProps(),
    graphql(StaffDetailsQuery, {
        options: (props: any) => ({
            variables: { staffId: props.staffId || props.params.staffId },
            fetchPolicy: 'network-only',
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.staff' }),
    withStaffMutators(['StaffDetailsQuery', 'StaffQuery']),
    mapProps((props) => {
        const staff = props?.data?.staff;
        const roles = fromEdges(props?.data?.viewer?.roles);

        // TODO: HACK: DUPE2
        // The filter below is a quick hack.
        // Remove it when the backend adds support for role lists.
        const { AuthState } = props;
        const isAdmin = AuthState.isAdmin;
        const filteredRoles = roles.filter((node: any) => {
            if (isAdmin) return true;
            return node.name !== 'Admin' && node.name !== 'Head Office' && node.name !== 'Organisation Admin';
        });
        // End of HACK

        return {
            ...props,
            staff,
            roles: filteredRoles,
        };
    })
)(Page);

export interface PageControlProps extends IWithPageProps, StaffMutatorProps {
    staff: NodeType.Staff;
    roles: any[];
}
