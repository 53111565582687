import { NodeType } from '@poolware/api';
import { mapServiceJobStageTypeToIconProps } from '../../../constants';

export type JobStageViewModelType = {
    type: NodeType.ServiceJobStageType;
    statusType: NodeType.ServiceJobStageStatus;
    icon: any; // IconProps; TODO: fix typings
    title: string;
};

export const JobStageViewModels: JobStageViewModelType[] = [
    {
        type: NodeType.ServiceJobStageType.Opened,
        icon: mapServiceJobStageTypeToIconProps(NodeType.ServiceJobStageType.Opened),
        title: 'Open',
        statusType: NodeType.ServiceJobStageStatus.Active,
    },
    {
        type: NodeType.ServiceJobStageType.InProgress,
        icon: mapServiceJobStageTypeToIconProps(NodeType.ServiceJobStageType.InProgress),
        title: 'In Progress',
        statusType: NodeType.ServiceJobStageStatus.Active,
    },
    {
        type: NodeType.ServiceJobStageType.ActionRequired,
        icon: mapServiceJobStageTypeToIconProps(NodeType.ServiceJobStageType.ActionRequired),
        title: 'Action Required',
        statusType: NodeType.ServiceJobStageStatus.Active,
    },
    {
        type: NodeType.ServiceJobStageType.Suspended,
        icon: mapServiceJobStageTypeToIconProps(NodeType.ServiceJobStageType.Suspended),
        title: 'Suspended',
        statusType: NodeType.ServiceJobStageStatus.Suspended,
    },
    {
        type: NodeType.ServiceJobStageType.Finished,
        icon: mapServiceJobStageTypeToIconProps(NodeType.ServiceJobStageType.Finished),
        title: 'Finished',
        statusType: NodeType.ServiceJobStageStatus.Closed,
    },
    {
        type: NodeType.ServiceJobStageType.Canceled,
        icon: mapServiceJobStageTypeToIconProps(NodeType.ServiceJobStageType.Canceled),
        title: 'Cancelled',
        statusType: NodeType.ServiceJobStageStatus.Closed,
    },
];
