import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeInterface } from './theme.interface';
import { getDefaultTheme } from './default-scheme';
import { HelmetProvider } from 'react-helmet-async';

const defaultTheme = getDefaultTheme('/themes');

export interface ThemeAction {
    setTheme: (theme: ThemeInterface) => any;
    setDefaultTheme: () => any;
}

export interface ThemeStateProps {
    ThemeState: { theme: ThemeInterface };
    ThemeAction: ThemeAction;
}

const AppThemeContext = React.createContext<ThemeStateProps>({
    ThemeAction: {
        setDefaultTheme: () => {},
        setTheme: (theme: ThemeInterface) => {},
    },
    ThemeState: {
        theme: defaultTheme,
    },
});

export const AppThemeProvider: React.FC<{
    defaultTheme?: ThemeInterface | null;
    persistThemeInStorage?: boolean;
    onThemeChange?: (theme: ThemeInterface) => void;
}> = (props) => {
    const [theme, setThemeState] = useState<ThemeInterface>(() => {
        return props.defaultTheme || defaultTheme;
    });

    const _setThemeChange = (newTheme: ThemeInterface) => {
        setThemeState(newTheme);
        props.onThemeChange?.(newTheme);
    };

    const ctx: ThemeStateProps = useMemo(
        () => ({
            ThemeState: {
                theme: theme,
            },
            ThemeAction: {
                setDefaultTheme: () => _setThemeChange(defaultTheme),
                setTheme: (theme: ThemeInterface) => _setThemeChange(theme),
            },
        }),
        [theme]
    );
    return (
        <HelmetProvider>
            <AppThemeContext.Provider value={ctx}>
                <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
            </AppThemeContext.Provider>
        </HelmetProvider>
    );
};

export const useTheme = () => {
    return useContext(AppThemeContext);
};
