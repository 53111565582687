import { compose, withProps } from '@ez/tools';
import {
    IMutateRecommendedTreatment,
    IMutateTestRecommendation,
    IMutateTestReport,
    NodeType,
    useMutationRecommendedTreatment,
    useMutationTestRecommendation,
    useMutationTestReport,
    withRecommendedTreatmentMutator,
    withTestRecommendationMutator,
    withTestReportMutator,
} from './api-types';
import _toNumber from 'lodash/toNumber';
import invariant from 'invariant';
import gql from 'graphql-tag';

export interface TestReportMutatorInjected
    extends IMutateRecommendedTreatment,
        IMutateTestRecommendation,
        IMutateTestReport {}

export interface TestReportAttachmentInput {
    file: string;
    comment?: string;
    hidden?: boolean;
    smallView?: boolean;
}

interface CreateTestReportInputType {
    pool: NodeType.Pool;
    samples: NodeType.CreateTestReportSwitchCreateSampleSetListSampleCreateInput[];
    manualProblemsToAssign?: NodeType.NodeOrId<NodeType.ManualProblem>[];
    workOrder?: NodeType.NodeOrId<NodeType.WorkOrder>;
    note?: string;
    refId?: string;
}

interface UpdateTestReportInputType {
    samplesToCreate?: NodeType.UpdateTestReportSwitchUpdateSampleSetListSampleCreateInput[];
    samplesToUpdate?: NodeType.UpdateTestReportSwitchUpdateSampleSetListSampleUpdateInput[];
    samplesToDelete?: NodeType.ID[];
    manualProblemsToAssign?: NodeType.ID[];
    manualProblemsToDelete?: NodeType.ID[];
    workOrder?: NodeType.NodeOrId<NodeType.WorkOrder>;
    note?: string;
}

const createMutator = ({
    mutateTestReport,
    mutateTestRecommendation,
    mutateRecommendedTreatment,
}: TestReportMutatorInjected) => {
    const createReport = async (input: CreateTestReportInputType) => {
        const { pool, samples, manualProblemsToAssign, note, refId } = input;
        const poolId = pool?.id;
        const entityId = pool?.entity?.id;

        invariant(poolId, 'pool id is not provided');
        invariant(entityId, 'pool.enity.id is not provided');

        let mutationConfig: NodeType.CreateTestReportMutationInput = {
            pool: poolId,
            reportTo: entityId,
            refId: refId,
            sampleSet: {
                create: {
                    samples: {
                        create: samples,
                    },
                },
            },
        };

        if (input.workOrder) {
            mutationConfig.workOrder = NodeType.extractId(input.workOrder);
        }

        if (manualProblemsToAssign && manualProblemsToAssign.length > 0) {
            mutationConfig.manualProblems = {};
            if (manualProblemsToAssign.length > 0) {
                mutationConfig.manualProblems.assign = manualProblemsToAssign.map(NodeType.extractId);
            }
        }

        if (note !== undefined) {
            mutationConfig.note = note;
        }

        const resp = await mutateTestReport.create(mutationConfig);
        return resp;
    };

    const updateReport = async (report: NodeType.NodeOrId<NodeType.TestReport>, input: UpdateTestReportInputType) => {
        const {
            samplesToCreate,
            samplesToUpdate,
            manualProblemsToAssign,
            manualProblemsToDelete,
            note,
            samplesToDelete,
        } = input;

        let mutationConfig: NodeType.UpdateTestReportMutationInput = {
            id: NodeType.extractId(report),
        };

        if (input.workOrder) {
            mutationConfig.workOrder = NodeType.extractId(input.workOrder);
        }

        mutationConfig.sampleSet = {
            update: {
                samples: {
                    create: samplesToCreate,
                    update: samplesToUpdate,
                    delete: samplesToDelete,
                },
            },
        };

        if (manualProblemsToAssign.length > 0 || manualProblemsToDelete.length > 0) {
            mutationConfig.manualProblems = {};

            if (manualProblemsToAssign.length > 0) {
                mutationConfig.manualProblems.assign = manualProblemsToAssign;
            }

            if (manualProblemsToDelete.length > 0) {
                mutationConfig.manualProblems.delete = manualProblemsToDelete;
            }
        }

        if (note !== undefined) {
            mutationConfig.note = note;
        }

        const resp = await mutateTestReport.update(mutationConfig);
        return resp;
    };

    const archiveReport = async (report: NodeType.NodeOrId<NodeType.TestReport>) => {
        let mutationConfig = {
            id: NodeType.extractId(report),
        };
        return await mutateTestReport.archiveReport(mutationConfig);
    };

    const deleteReport = async (report: NodeType.NodeOrId<NodeType.TestReport>, removeArchived: boolean = true) => {
        let mutationConfig = {
            id: NodeType.extractId(report),
            deleteSamples: true,
            removeArchived: removeArchived,
        };
        return await mutateTestReport.delete(mutationConfig);
    };

    const selectTreatment = async (input: {
        testRecommendation: NodeType.TestRecommendation;
        testTreatment: NodeType.TestTreatment | null;
    }) => {
        const { testRecommendation, testTreatment } = input;
        let mutationConfig: any = {
            id: testRecommendation.id,
        };
        if (testTreatment) {
            mutationConfig.treatmentId = testTreatment.id;
        }
        await mutateTestRecommendation.selectTreatment(mutationConfig);
    };

    const deleteSample = async (report: NodeType.TestReport, sample: NodeType.Sample) => {
        return mutateTestReport.update({
            id: report.id,
            sampleSet: {
                update: {
                    samples: {
                        delete: [sample.id],
                    },
                },
            },
        });
    };

    const attachFiles = async (report: NodeType.TestReport, attachments: TestReportAttachmentInput[]) => {
        return mutateTestReport.update({
            id: report.id,
            attachments: {
                create: attachments,
            },
        });
    };

    const deleteAttachments = async (report: NodeType.TestReport, attachmentsIds: NodeType.ID[]) => {
        return mutateTestReport.update({
            id: report.id,
            attachments: {
                delete: attachmentsIds,
            },
        });
    };

    const overrideRecommendedTreatment = async (
        recommendedTreatment: NodeType.RecommendedTreatment,
        data: { dosage?: number; comment?: string; instruction?: string }
    ) => {
        const { dosage, instruction, comment } = data;
        if (dosage === undefined && comment === undefined && instruction === undefined) {
            return;
        }

        return mutateRecommendedTreatment.override({
            id: recommendedTreatment.id,
            dosage: _toNumber(dosage),
            comment: comment,
            instruction: instruction,
        });
    };

    const resetRecommendedTreatment = async (recommendedTreatment: NodeType.RecommendedTreatment) => {
        return mutateRecommendedTreatment.reset({
            id: recommendedTreatment.id,
        });
    };

    return {
        TestReportMutator: {
            createReport,
            updateReport,
            attachFiles,
            deleteAttachments,
            archiveReport,
            deleteReport,
            selectTreatment,
            deleteSample,
            overrideRecommendedTreatment,
            resetRecommendedTreatment,
        },
    };
};

const mutateTestReportQuery = gql`
    mutation TestReportMutation($input: TestReportMutationInput!) {
        TestReport(input: $input) {
            TestReport {
                id
                pdfUrl
            }
            results {
                TestReport {
                    id
                    pdfUrl
                }
            }
        }
    }
`;

export const withTestReportMutators = (refetchQueries) =>
    compose(
        withRecommendedTreatmentMutator(refetchQueries),
        withTestRecommendationMutator(refetchQueries),
        withTestReportMutator(refetchQueries, mutateTestReportQuery),
        withProps(createMutator)
    );

export interface TestReportMutatorsProps extends TestReportMutatorInjected, ReturnType<typeof createMutator> {}

export const useTestReportMutators = (refetchQueries) => {
    const mutateRecommendedTreatment = useMutationRecommendedTreatment({ refetchQueries: refetchQueries });
    const mutateTestRecommendation = useMutationTestRecommendation({ refetchQueries: refetchQueries });
    const mutateTestReport = useMutationTestReport({ refetchQueries: refetchQueries, mutation: mutateTestReportQuery });

    return { ...createMutator({ mutateTestReport, mutateTestRecommendation, mutateRecommendedTreatment }) };
};
