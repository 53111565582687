import * as React from 'react';
import {
    ModuleNavLink,
    ModuleNavLinkProps,
    ModuleRoot,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import { PageList } from './Page.List';
import { PageView } from './View';
import { PageNew } from './Page.New';
import { PageEdit } from './Page.Edit';
import { useViewer } from '@poolware/app-shell';
import * as Message from './MessageInbox';
import { Icon, MenuBar } from '@ez/components';

export const MenuItemNavLink: React.FC<ModuleNavLinkProps> = (props) => {
    return (
        <div className={'m-1 p-1 px-4 has-[.active]:bg-tertiary rounded'}>
            <ModuleNavLink className={'p-2'} {...props} />
        </div>
    );
};

const SectionsNavbar = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    if (AppNavigator.isModal || !modulesAccess.Warranties.franchiseMsgInbox) {
        return null;
    }

    return (
        <MenuBar.Root>
            <MenuBar.Section position={'left'}>
                <MenuItemNavLink to={'/'} exact={true}>
                    <Icon name={'certificate'} /> Warranty Claims
                </MenuItemNavLink>
                <MenuItemNavLink to={'/messages'} exact={true}>
                    <Icon name={'mail'} />
                    Inbox
                </MenuItemNavLink>
            </MenuBar.Section>
            <MenuBar.Section position={'right'}>
                <MenuItemNavLink to={'/new'} exact={true}>
                    <Icon name={'plus'} color={'green'} />
                    New Claim
                </MenuItemNavLink>
            </MenuBar.Section>
        </MenuBar.Root>
    );
};

export const Router: React.FC = () => {
    return (
        <ModuleRoot>
            <ModuleRoute path={'/'} exact={true} component={SectionsNavbar} />
            <ModuleRoute path={'/messages'} exact={true} component={SectionsNavbar} />
            <ModuleSwitch>
                <ModuleRoute path={'/'} exact={true} component={PageList} />
                <ModuleRoute path={'/new'} component={PageNew} />
                <ModuleRoute path={'/messages'} component={Message.List} />
                <ModuleRoute path={'/:id/edit'} component={PageEdit} />
                <ModuleRoute path={'/:id'} component={PageView} />
            </ModuleSwitch>
        </ModuleRoot>
    );
};
