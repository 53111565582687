import React from 'react';
import { DateLocalizer } from './localizer';
import clsx from 'clsx';

export interface HeaderProps {
    label: any;
    date: Date;
    localizer: DateLocalizer;
    onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
    isToday?: boolean;
}

const Header: React.FC<HeaderProps> = ({ date, localizer, onClick, isToday }) => {
    let dateStr = localizer.format(date, 'dateFormat');
    let weekdayLong = localizer.format(date, 'dddd');
    let weekdayShot = localizer.format(date, 'ddd');
    return (
        <button
            className={clsx([
                'px-1.5 py-1 flex flex-col md:flex-row gap-1 items-center cursor-default',
                !!onClick && 'hover:cursor-pointer hover:bg-accent hover:text-accent-foreground rounded-lg',
            ])}
            onClick={onClick}
        >
            <span
                className={clsx(
                    'rounded-lg font-bold flex items-center justify-center text-base md:text-xl w-8 h-8 md:w-10 md:h-10',
                    isToday && 'bg-black text-white border-white border border-solid',
                    !isToday && 'bg-white text-black border-none'
                )}
            >
                {dateStr}
            </span>
            <span className={'text-base hidden lg:block'}>{weekdayLong}</span>
            <span className={'text-sm block lg:hidden'}>{weekdayShot}</span>
        </button>
    );
};

export default Header;
