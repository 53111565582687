import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import { SuggestInput } from '@ez/components';
import { ConnectedSuggestInputProps } from './types';
import { QueryProductCompanyConnection } from '../queries/QueryProductCompanyConnection';

export interface SuggestProductInputProps extends ConnectedSuggestInputProps<NodeType.ProductCompany> {}

export const SuggestProductCompanyInput: React.FC<SuggestProductInputProps> = ({
    value,
    onChange,
    label,
    placeholder,
    isClearable = true,
}) => {
    const [acSearchQuery, setAcSearchQuery] = useState<string>('');

    const handleOnSearchChange = (value: string) => {
        setAcSearchQuery(value);
    };

    const itemRenderer = (item) => {
        return item ? `${item.name}` : '';
    };

    return (
        <QueryProductCompanyConnection searchName={acSearchQuery}>
            {({ connectionData, connectionState }) => {
                return (
                    <SuggestInput
                        isClearable={isClearable}
                        activeItem={value}
                        items={connectionData}
                        loading={connectionState.loading}
                        onItemSelect={onChange}
                        onQueryChange={handleOnSearchChange}
                        itemTextLabel={itemRenderer}
                        label={label}
                        placeholder={placeholder}
                        getOptionValue={(item) => (item ? item.name : '')}
                    />
                );
            }}
        </QueryProductCompanyConnection>
    );
};
