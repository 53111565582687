import * as React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { clsxm } from './utils';

const pillBase = cva('inline-block rounded-sm px-1', {
    variants: {
        color: {
            purple: 'bg-purple-200 text-black',
            green: 'bg-green-200 text-black',
            blue: 'bg-blue-100 text-black',
            yellow: 'bg-yellow-50 border-yellow-100 border text-black',
            gray: 'bg-gray-200 text-black',
        },
    },
});

export interface PillProps
    extends Omit<React.ButtonHTMLAttributes<HTMLDivElement>, 'color'>,
        VariantProps<typeof pillBase> {}

export const Pill = React.forwardRef<HTMLDivElement, PillProps>(({ className, color, ...props }, ref) => {
    return <div ref={ref} className={clsxm([pillBase({ color: color }), className])} {...props} />;
});
