import * as React from 'react';
import { useState } from 'react';
import { Button, Panel, toastError } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { findTreeDescendants, unflattenToTree } from '../../../../utils';
import { TreeRenderer } from '../../../../components/TreeRenderer';

export interface SingleTraitRemoveProps {
    product: NodeType.Product;
    onCancel: () => any;
    onDeleteProductTrait: (productTrait: NodeType.ProductTrait) => any;
}

type TraitTreeItem = {
    trait: NodeType.ProductTrait;
    id: NodeType.ID;
    name: string;
    parent: NodeType.ProductTraitDeclaration;
};

export const SingleTraitRemove: React.FC<SingleTraitRemoveProps> = ({ product, onCancel, onDeleteProductTrait }) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [declarationToDelete, setDeclarationToDelete] = useState(null);
    const [declarationsToDelete, setDeclarationsToDelete] = useState([]);

    const treeItems: TraitTreeItem[] = fromEdges(product.traits).map((trait) => ({
        trait: trait,
        id: _.get(trait, 'declaration.id'),
        name: _.get(trait, 'declaration.name'),
        parent: _.get(trait, 'declaration.parent'),
    }));

    const tree = unflattenToTree(treeItems);
    const canUpdate = !!declarationToDelete;

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            const traitToDelete = treeItems.find((ti) => ti.id === declarationToDelete);
            if (!traitToDelete) {
                console.error('tree item is not found. This should not have happened.');
                return;
            }

            onDeleteProductTrait(traitToDelete.trait);
            setSubmitting(false);
        } catch (e) {
            setSubmitting(false);
            console.error(e);
            toastError({ title: 'Failed to Update', description: e.message });
        }
    };

    const handleToggle = (input: { id: NodeType.ID; toRemove: boolean }) => (e) => {
        e.preventDefault();

        const { id, toRemove } = input;
        if (toRemove) {
            setDeclarationToDelete(id);
            const idsToRemove = findTreeDescendants(tree, id).map((i) => i.id);
            setDeclarationsToDelete((items) => {
                return [...idsToRemove];
            });
        } else {
            setDeclarationToDelete(null);
            setDeclarationsToDelete([]);
        }
    };

    const itemRenderer = (pd: NodeType.ProductTraitDeclaration) => {
        const isToDelete = declarationsToDelete.includes(pd.id);
        const isSelected = declarationToDelete === pd.id;

        return (
            <div className={'flex flex-row gap-2 items-center'}>
                <span style={isToDelete ? { textDecoration: 'line-through' } : null}>{pd.name}</span>
                {!isSelected && !isToDelete && (
                    <Button
                        size={'xs'}
                        color={'red'}
                        variant={'tertiary'}
                        onClick={handleToggle({ id: pd.id, toRemove: true })}
                    >
                        Remove
                    </Button>
                )}{' '}
                {isSelected && (
                    <Button size={'xs'} variant={'tertiary'} onClick={handleToggle({ id: pd.id, toRemove: false })}>
                        Keep
                    </Button>
                )}
            </div>
        );
    };

    return (
        <Panel>
            <Panel.Header content={`Edit ${product.name} products`} />
            <Panel.Body>
                <Panel.Item label={'Traits'}>
                    <TreeRenderer tree={tree} itemRenderer={itemRenderer} />
                </Panel.Item>
            </Panel.Body>
            <Panel.Footer>
                <Button primary type="button" basic disabled={isSubmitting} floated={'left'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    primary
                    type={'submit'}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting || !canUpdate}
                    content={`Update`}
                    floated={'right'}
                />
            </Panel.Footer>
        </Panel>
    );
};
