import * as React from 'react';
import { DefaultConnectionTable, Icon, MenuBar, PageLayout, StickyMenuBar, TableDef } from '@ez/components';
import { useQueryServiceJobStageConnection } from './use-query-stage-connection';
import { fromEdges, NodeType } from '@poolware/api';

export interface PageProps {}

export const Page: React.FC<PageProps> = ({}) => {
    const { connectionState, connectionData } = useQueryServiceJobStageConnection();

    const tableDef: TableDef<NodeType.ServiceJobStage> = [
        {
            header: 'Status',
            cell: (r) => {
                return <>{r.status}</>;
            },
        },
        {
            header: 'Type',
            cell: (r) => {
                return <>{r.type}</>;
            },
        },
        {
            header: 'Title',
            cell: (r) => {
                return <>{r.title}</>;
            },
        },
        {
            header: 'Is Default',
            cell: (r) => {
                return <Icon name={r.isDefault ? 'check square outline' : 'square outline'} />;
            },
        },
        {
            header: 'Is Default',
            cell: (r) => {
                return <Icon name={r.isFallback ? 'check square outline' : 'square outline'} />;
            },
        },
        {
            header: 'Priority',
            cell: (r) => {
                return <>{r.priority}</>;
            },
        },
        {
            header: 'Groups',
            cell: (r) => {
                const groups = fromEdges(r.groups);
                return <>{groups?.join(', ')}</>;
            },
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem content={'Stages'} />
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item content={'Item 1'} />
                </MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.BodySection>
                <div>
                    <DefaultConnectionTable
                        debug={false}
                        tableDef={tableDef}
                        connectionData={connectionData}
                        connectionState={connectionState}
                    />
                </div>
            </PageLayout.BodySection>
        </PageLayout>
    );
};
