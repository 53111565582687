import * as React from 'react';
import { useWorkOrderListActions, WorkOrderRecurStatus } from '../../redux';
import { ActionBar, Optionable } from '@ez/components';
import { ModuleIconNames } from '../../constants';

export const FilterByRecurStatus: React.FC = () => {
    const { WorkOrderListAction, WorkOrderListState } = useWorkOrderListActions();
    const { recurStatus } = WorkOrderListState.filters;

    const statuses: Optionable<WorkOrderRecurStatus>[] = [
        {
            text: 'Recurring',
            icon: ModuleIconNames.RecurringService,
            value: WorkOrderRecurStatus.RECURRING,
        },
        {
            text: 'Non-recurring',
            icon: ModuleIconNames.ServiceCase,
            value: WorkOrderRecurStatus.ONEOFF,
        },
    ];

    const onShowAll = (checked: boolean) => {
        if (checked) {
            WorkOrderListAction.setRecurFilter(WorkOrderRecurStatus.ANY);
        }
    };

    const onFilterChange = (status: Optionable<WorkOrderRecurStatus>, checked: boolean) => {
        if (checked) {
            WorkOrderListAction.setRecurFilter(status.value);
        } else {
            WorkOrderListAction.setRecurFilter(WorkOrderRecurStatus.ANY);
        }
    };

    return (
        <ActionBar>
            <ActionBar.Header content={'Recurring'} icon={'filter'} />
            <ActionBar.ItemCheckbox
                dividing={true}
                checked={recurStatus === WorkOrderRecurStatus.ANY}
                onChange={(checked) => onShowAll(checked)}
                content={'All'}
            />
            {statuses.map((status) => {
                const isChecked = status.value === recurStatus;
                return (
                    <ActionBar.ItemCheckbox
                        radio={true}
                        key={status.value}
                        checked={isChecked}
                        onChange={(checked) => onFilterChange(status, checked)}
                        icon={status.icon}
                        content={status.text}
                    />
                );
            })}
        </ActionBar>
    );
};
