import * as React from 'react';
import { useCalendarActions } from '../../../redux';
import { ActionBar } from '@ez/components';
import { NodeType } from '@poolware/api';
import { SuggestInputFranchise } from '@poolware/app-shell';

export interface FranchiseFilterProps {}

const FilterByFranchise: React.FC<FranchiseFilterProps> = () => {
    const { CalendarState, CalendarAction } = useCalendarActions();
    const franchise = CalendarState.filters.franchise as NodeType.Franchise;

    const onChange = (value) => {
        CalendarAction.setFranchise(value);
    };

    return (
        <ActionBar>
            <ActionBar.Header content={'Franchise'} icon={'filter'} />
            <SuggestInputFranchise value={franchise} onChange={onChange} isClearable={false} />
        </ActionBar>
    );
};

export default FilterByFranchise;
