import { CalendarContextAccessorsFns } from '../CalendarContext';
import { CalEvent, CalResource } from '../types';

export const NONE = {};

export default function Resources(resources: CalResource[], accessors: CalendarContextAccessorsFns) {
    return {
        length: resources?.length,
        map(fn: (tuple: [any, CalResource], idx: number) => any) {
            if (!resources) {
                return [fn([NONE, null], 0)];
            }
            return resources.map((resource, idx) => fn([accessors.resourceId(resource), resource], idx));
        },

        groupEvents(events: CalEvent[]) {
            const eventsByResource = new Map();

            if (!resources) {
                // Return all events if resources are not provided
                eventsByResource.set(NONE, events);
                return eventsByResource;
            }

            events.forEach((event) => {
                const id = accessors.resource(event) || NONE;
                let resourceEvents = eventsByResource.get(id) || [];
                resourceEvents.push(event);
                eventsByResource.set(id, resourceEvents);
            });
            return eventsByResource;
        },
    };
}
