import * as React from 'react';

import * as Customers from './Customers';
import { New as NewCustomerPage } from './Customers';
import * as Site from './Site';
import * as Pool from './Pool';
import { Router as EquipmentRouter } from './Equipment';
import { Router as WaterTestRouter } from './WaterTest/Router';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { NotFoundPage } from '@poolware/app-shell';
import Breadcrumbs from './Breadcrumbs';
import { PageLayout } from '@ez/components';

const SiteRoute = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path="/:siteId/edit" component={Site.Edit} />
            <ModuleRoute path="/:siteId/transfer-ownership" component={Site.OwnerTransfer} />
            <ModuleRoute path="/:siteId/edit-access-key" component={Site.EditSiteAccessKey} />
        </ModuleRootSwitch>
    );
};

const PoolRoute = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path="/:poolId/equipment" component={EquipmentRouter} />
            <ModuleRoute path="/:poolId/edit" component={Pool.Edit} />
            <ModuleRoute path="/:poolId/tests" component={WaterTestRouter} />
            <ModuleRoute path="/:poolId" component={Pool.View} />
        </ModuleRootSwitch>
    );
};

export const Router = () => {
    return (
        <>
            <Breadcrumbs />
            <PageLayout width={'screen-wide'} className={'p-0 mb-0'}>
                <ModuleRootSwitch notFound={NotFoundPage}>
                    <ModuleRoute path={'/'} exact component={Customers.List} />
                    <ModuleRoute path="/new" component={Customers.New} />
                    <ModuleRoute path="/:customerId/add-site" exact component={Site.New} />
                    <ModuleRoute path="/:customerId/site" component={SiteRoute} />
                    <ModuleRoute path="/:customerId/add-pool" component={Pool.New} />
                    <ModuleRoute path="/:customerId/pools" component={PoolRoute} />
                    <ModuleRoute path="/:customerId/edit" component={Customers.Edit} />
                    <ModuleRoute path="/:customerId" component={Customers.View} />
                </ModuleRootSwitch>
            </PageLayout>
        </>
    );
};

export const NewCustomerRouter = () => {
    return <NewCustomerPage />;
};
