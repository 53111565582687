import * as React from 'react';
import { compose } from '@ez/tools';
import { IMutatePoolProductDeclaration, NodeType, withPoolProductDeclarationMutator } from '@poolware/api';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { Panel, toastError } from '@ez/components';
import * as Yup from 'yup';
import { FormBuilder, FormBuilderConfig, FormFieldPrimitiveType } from '../../../components/FormBuilder';
import ProductTraitDeclarationInput from './ProductTraitDeclarationInput';
import * as _ from 'lodash';
import { PageLayout } from '@ez/components';

const ValidationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    declaration: Yup.object().required('Required'),
});

const createFormConfig = (): FormBuilderConfig => ({
    fields: [
        {
            name: 'Name',
            key: 'name',
            type: FormFieldPrimitiveType.String,
            required: true,
        },
    ],
    initialValues: { name: '' },
    validationSchema: ValidationSchema,
});

class New extends React.Component<PageControlProps> {
    onSubmit = async (values, actions) => {
        try {
            const { name, declaration } = values;
            const mutationInput = {
                optional: true,
                priority: 0,
                name: name ? _.trim(name) : undefined,
                declaration: declaration ? NodeType.extractId(declaration) : undefined,
            };
            const res = await this.props.mutatePoolProductDeclaration.create(mutationInput);
            // console.log(res);
            const id = _.get(res, 'data.PoolProductDeclaration.PoolProductDeclaration.id');
            this.props.AppNavigator.replace(`/${id}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Error', description: e.message });
        }
    };

    onCancel = () => {
        this.props.AppNavigator.replaceToOrigin();
    };

    render() {
        const formConfig = createFormConfig();
        const { parent } = this.props.AppNavigator.state;
        const initialValues = { ...formConfig.initialValues, parent };

        return (
            <PageLayout.BodySection width={'screen-lg'}>
                <Panel>
                    <Panel.Header>New Trait Declaration</Panel.Header>
                    <Panel.Body>
                        <FormBuilder
                            debug={false}
                            config={formConfig}
                            initialValues={initialValues}
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}
                        >
                            {() => {
                                return (
                                    <>
                                        <ProductTraitDeclarationInput name={'declaration'} />
                                    </>
                                );
                            }}
                        </FormBuilder>
                    </Panel.Body>
                </Panel>
                {/*<DebugPanel value={{ appNavigator: this.props.AppNavigator, formConfig }} />*/}
            </PageLayout.BodySection>
        );
    }
}

export interface PageControlProps extends IAppNavigatorProps, IMutatePoolProductDeclaration {}

export default compose(withAppNavigator(), withPoolProductDeclarationMutator(['PoolProductDeclarations']))(New);
