import * as React from 'react';
import { useMobileAppointmentCtx } from '../Root';
import { extractRecurrenceData, recurrenceToString } from '../../utils';
import { Frame, SectionButton } from '../components';
import { clsxm, Display, Icon, PageLayout } from '@ez/components';
import { ModuleIconNames } from '../../../constants';
import { NodeType } from '@poolware/api';

const SectionItem: React.FC<{ icon: string; label?: React.ReactNode }> = ({ icon, label, children }) => {
    return (
        <div className={'flex flex-row gap-1 items-start'}>
            <Icon name={icon} />
            <div className={'flex flex-col gap-0'}>
                <div className={''}>{children}</div>
                {label && <div className={'text-tertiary text-sm'}> {label}</div>}
            </div>
        </div>
    );
};

const JobTitle: React.FC<{ appointmentItem: NodeType.AppointmentItem }> = ({ appointmentItem }) => {
    const { isRecurring, isRecurrentOrigin, serviceJob } = appointmentItem;
    const hasRecurrence = isRecurring || isRecurrentOrigin || serviceJob?.isRecurring;
    const jobTitle = serviceJob?.title;
    const groupTitle = serviceJob?.group?.title || 'Appointment';
    const isRecurringCase = !!serviceJob?.isRecurring;

    let recurIconColor: string = undefined;
    if (hasRecurrence) {
        if (isRecurringCase) {
            recurIconColor = 'bg-purple';
        } else if (!isRecurring && isRecurrentOrigin) {
            recurIconColor = 'bg-grey';
        } else {
            recurIconColor = 'bg-blue';
        }
    }

    return (
        <div>
            <div className={'flex flex-row gap-2 items-center'}>
                <div className={clsxm('rounded-full px-3 py-0 text-primary-foreground', recurIconColor)}>
                    {hasRecurrence ? <Icon name={'refresh'} /> : <Icon name={ModuleIconNames.ServiceCall} />}
                    {groupTitle && <span className={'text-primary-foreground'}>{groupTitle}</span>}
                </div>
                <span className={'text-strong'}>{jobTitle}</span>
            </div>
        </div>
    );
};

interface PageSectionProps {
    expanded?: boolean;
    onToggle?: () => void;
    appointmentItem?: NodeType.AppointmentItem;
}

export const PageSectionApptDetails: React.FC<PageSectionProps> = ({ expanded, onToggle }) => {
    const { appointmentItem } = useMobileAppointmentCtx();
    const { address, pool, staff, customer, isRecurring, isRecurrentOrigin } = appointmentItem;

    const _isRecurring = isRecurring || isRecurrentOrigin;
    const { recurrence } = extractRecurrenceData(appointmentItem);

    const poolVolume = pool?.volume || '--';
    const poolType = pool?.type?.name;
    const poolString = `${poolType}, ${poolVolume} L`;
    const poolSanitiser = pool?.sanitiser?.name;
    const poolSiteAccess = pool?.site?.accessKeyLocation;
    const jobAddress = address || pool?.address;

    return (
        <>
            <SectionButton expanded={expanded} onClick={onToggle}>
                Appointment Details
            </SectionButton>
            {expanded && (
                <PageLayout.BodySection vStack={true}>
                    {customer && (
                        <Frame bordered={true}>
                            <SectionItem icon={'user'}>
                                <div className={'text-strong font-bold'}>
                                    {customer ? (
                                        <>
                                            <Display.Entity value={customer} /> ({customer?.crn})
                                        </>
                                    ) : (
                                        '--'
                                    )}
                                </div>
                            </SectionItem>

                            <Frame className={'gap-2 p-0'}>
                                <div className={'w-full border-t'} />
                                {customer.primaryEmail && (
                                    <SectionItem icon={'mail'} label={customer.primaryEmail.label}>
                                        <Display.Email value={customer.primaryEmail?.data} />
                                    </SectionItem>
                                )}
                                {customer.primaryPhone && (
                                    <SectionItem icon={'phone'} label={customer.primaryPhone.label}>
                                        <Display.Phone value={customer.primaryPhone?.data} />
                                    </SectionItem>
                                )}
                            </Frame>
                        </Frame>
                    )}
                    <Frame bordered={true}>
                        <JobTitle appointmentItem={appointmentItem} />

                        <Frame className={'gap-2'}>
                            <SectionItem icon={'calendar'} label={_isRecurring && recurrenceToString(recurrence)}>
                                <Display.Date value={appointmentItem?.startDate} format={'lll'} />
                                <span className={'pl-2'}>({appointmentItem.duration} min)</span>
                            </SectionItem>
                            <SectionItem icon={'spy'}>
                                <Display.Entity value={staff} />
                            </SectionItem>
                            {jobAddress && (
                                <SectionItem icon={'marker'}>
                                    <Display.Address value={address || pool?.address} showMapLink={true} />
                                </SectionItem>
                            )}
                            {pool && (
                                <>
                                    <SectionItem icon={'life ring outline'}>
                                        {poolString} ({pool?.bottleNumber})
                                    </SectionItem>
                                    {poolSanitiser && <SectionItem icon="box">{poolSanitiser}</SectionItem>}
                                    {poolSiteAccess && (
                                        <SectionItem icon={'key'}>
                                            <div className={'max-h-40 text-tertiary italic'}>
                                                <Display.FormattedText value={poolSiteAccess} limit={180} />
                                            </div>
                                        </SectionItem>
                                    )}
                                </>
                            )}
                        </Frame>

                        {/*<strong>Previous Water test</strong>*/}
                        {/*<WaterTestSection />*/}
                    </Frame>
                    <Frame bordered={true}>
                        <div className={'test-strong font-bold'}>Job Description Notes</div>
                        <div className={'text-sm'}>{appointmentItem?.note || '--'}</div>
                    </Frame>
                </PageLayout.BodySection>
            )}
        </>
    );
};
