import * as React from 'react';
import { SemanticWIDTHS } from 'semantic-ui-react';
import { FormLabel } from '../form-components';
import { useSafeState } from '@ez/tools';
import { defaultOptionValueComparatorFn, OptionableComponent } from '../optionable';
import { ButtonRadixProps } from '../radix';
import { cn } from '../utils';
import { ButtonWithPopup } from './ButtonWithPopup';

export interface SegmentedButtonRadixProps<V = any>
    extends OptionableComponent<V>,
        Omit<ButtonRadixProps, 'onChange' | 'value' | 'variant' | 'name'> {
    label?: string | React.ReactNode;
    fluid?: boolean;
    widths?: SemanticWIDTHS | 'equal';
    vertical?: boolean;
    loading?: boolean;
    disabled?: boolean;
    separated?: boolean;
}

const NO_MUTATION = '____NO_MUTATIONS____';

export const SegmentedSelectButtonsRadix: React.FC<SegmentedButtonRadixProps> = (props) => {
    const {
        label,
        onChange,
        options,
        value,
        fluid = true,
        color,
        disabled,
        separated,
        size,
        valueComparator = defaultOptionValueComparatorFn,
        ...rest
    } = props;
    const [mutatingValue, setMutatingValue] = useSafeState(NO_MUTATION);
    const handleClick = (o) => async (e) => {
        if (!onChange) {
            return;
        }
        setMutatingValue(o.value);
        try {
            await onChange(o);
        } catch (e) {
            console.error(e);
        }
        setMutatingValue(NO_MUTATION);
    };

    return (
        <div className={fluid ? 'w-full' : undefined}>
            {label && <FormLabel>{label}</FormLabel>}
            <div className={cn(['flex flex-row', separated && 'gap-2 flex-wrap'])}>
                {options.map((o, index) => {
                    const isActive = valueComparator(value, o);
                    const isMutating = valueComparator(mutatingValue, o);
                    const isMutationInProgress = mutatingValue !== NO_MUTATION || disabled;
                    const segmentPosition = index === 0 ? 'first' : index === options.length - 1 ? 'last' : 'middle';
                    return (
                        <ButtonWithPopup
                            aria-invalid={rest['aria-invalid']}
                            key={index}
                            disabled={isMutationInProgress}
                            loading={isMutating}
                            segmentPosition={!separated ? segmentPosition : undefined}
                            variant={isActive ? 'primary' : 'secondary'}
                            onClick={handleClick(o)}
                            icon={o.icon}
                            className={fluid && 'flex-grow'}
                            color={color}
                            size={size}
                        >
                            {o.text || null}
                        </ButtonWithPopup>
                    );
                })}
            </div>
        </div>
    );
};

export const SegmentedSelectButtons = SegmentedSelectButtonsRadix;
