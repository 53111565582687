import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Alert, ConnectionErrorMessage, DebugJSON, DevOnly, PageSkeletonLoader, toastError } from '@ez/components';
import { useWarrantyActions } from '@poolware/app-warranty-claims';
import { useQueryResolveCustomer } from './common';

type URLParams = {};
type URLQuery = {
    crn: string;
};

// Required for LS Retail Integration
// Must not be removed
export const RedirectToNewWarrantyClaim: React.FC = () => {
    const { query } = useAppNavigator<URLParams, URLQuery>();
    const { crn } = query;
    const openedRef = useRef(false);

    const queryRes = useQueryResolveCustomer({ crn });
    const customer = queryRes?.customer;

    const { Action } = useWarrantyActions();

    useEffect(() => {
        if (!crn || openedRef.current || !customer) {
            return;
        }
        openedRef.current = true;
        try {
            Action.newWarranty({ details: { customer: customer } });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    }, [crn, customer, openedRef]);

    if (!crn) {
        return (
            <Alert type={'info'}>
                Expected a customer CRN number to be provided in the URL (e.g. .../new?crn='007-000123' )
            </Alert>
        );
    }

    if (queryRes.loading) {
        return <PageSkeletonLoader />;
    } else if (queryRes.error) {
        return <ConnectionErrorMessage error={queryRes.error} />;
    } else if (!queryRes.loading && !queryRes.customer) {
        return (
            <Alert type={'error'}>
                Customer with CRN Number <code style={{ color: 'blue' }}>{crn}</code> is not found
            </Alert>
        );
    }

    return (
        <div>
            <h3>Redirecting...</h3>
            <DevOnly>
                <div style={{ paddingTop: '3rem', color: '#888888', flexDirection: 'column', fontSize: 'small' }}>
                    <div>CRN: {crn}</div>
                    <DebugJSON data={queryRes.customer} />
                </div>
            </DevOnly>
        </div>
    );
};
