import * as React from 'react';
import { useMemo, useState } from 'react';
import { useQueryAppointmentItemsForServiceJob } from '../PanelAppointments/use-query-appointment-items-connection';
import { NodeType } from '@poolware/api';
import { Button, ConnectionTableDef, DefaultConnectionTable, Display, Icon } from '@ez/components';
import { mapAppointmentStatusToIconProps } from '../../../Scheduler';
import { IconServiceJobStage, ModuleIconNames } from '../../../constants';
import { isBefore, startOfDay } from 'date-fns';

const CellWrapper: React.FC<{ isDimmed?: boolean }> = ({ isDimmed, children }) => {
    return <div className={!!isDimmed && 'opacity-25 line-through'}>{children}</div>;
};

export type AppointmentsSplitterMode = 'split' | 'cancellation';

const TableAppointments: React.FC<{
    headerLeft: string;
    headerRight: string;
    mode: AppointmentsSplitterMode;
    selectedAppointment: NodeType.AppointmentItem;
    appointmentItems: NodeType.AppointmentItem[];
    onSelect: (appt: NodeType.AppointmentItem) => any;
}> = ({ appointmentItems, onSelect, selectedAppointment, headerLeft, headerRight, mode }) => {
    const viewDetails = (appointment: NodeType.AppointmentItem) => {
        onSelect?.(appointment);
    };

    const tableDef: ConnectionTableDef<NodeType.AppointmentItem> = useMemo(
        () => [
            {
                header: headerLeft,
                width: '8',
                cell: (r) => {
                    const isBeforeSelected = isBefore(new Date(r.startDate), new Date(selectedAppointment?.startDate));
                    const { workOrder } = r;

                    const shouldKeep = !selectedAppointment || isBeforeSelected || !!workOrder;
                    if (mode === 'cancellation' && !shouldKeep) {
                        return null;
                    }
                    return (
                        <CellWrapper isDimmed={!shouldKeep}>
                            <span>
                                {shouldKeep ? (
                                    <Icon {...mapAppointmentStatusToIconProps(r.status)} />
                                ) : (
                                    <Icon name={'cancel'} />
                                )}
                                <Icon name={ModuleIconNames.Appointment} />
                                <Display.Date value={r.startDate} format={'lll (ddd)'} />
                            </span>

                            {workOrder && (
                                <span className={'pl-2'}>
                                    <IconServiceJobStage stage={workOrder?.stage} />;
                                    <Icon name={ModuleIconNames.WorkOrder} />
                                    <span style={{ color: '#4183c4' }}>{workOrder.workOrderNumber}</span> -{' '}
                                    <span>{workOrder.title}</span>
                                </span>
                            )}
                        </CellWrapper>
                    );
                },
            },
            {
                header: headerRight,
                width: '8',
                cell: (r) => {
                    if (!selectedAppointment) {
                        return null;
                    }
                    const isBeforeSelected = isBefore(new Date(r.startDate), new Date(selectedAppointment?.startDate));
                    if (isBeforeSelected) {
                        return null;
                    }
                    const shouldDim = !isBeforeSelected && mode === 'cancellation';
                    return (
                        <CellWrapper isDimmed={shouldDim}>
                            <Icon name={ModuleIconNames.Appointment} />
                            <Display.Date value={r.startDate} format={'lll (ddd)'} />
                        </CellWrapper>
                    );
                },
            },
        ],
        [appointmentItems, selectedAppointment, mode]
    );

    return (
        <div>
            <DefaultConnectionTable
                // tableProps={{ color: 'grey' }}
                tableDef={tableDef}
                onRowClick={viewDetails}
                connectionData={appointmentItems}
                connectionState={undefined}
            />
        </div>
    );
};

const startDate = startOfDay(new Date());

export const AppointmentsSplitter: React.FC<{
    onSelect: (appt: NodeType.AppointmentItem) => any;
    onCancel: () => any;
    serviceJob: NodeType.ServiceJob;
    headerLeft: string;
    headerRight: string;
    mode: AppointmentsSplitterMode;
    defaultSelection: NodeType.AppointmentItem;
    patternBlockId?: string;
}> = ({ onSelect, onCancel, serviceJob, defaultSelection, headerLeft, headerRight, mode, patternBlockId }) => {
    const [selectedAppt, setSelectedAppt] = useState<NodeType.AppointmentItem>(defaultSelection);

    const { connectionData, connectionState, loadMore } = useQueryAppointmentItemsForServiceJob({
        serviceJobId: serviceJob.id,
        startDate: startDate,
        pageSize: 20,
        reverse: false,
        patternBlockId,
    });

    const hasNext = connectionState?.pageInfo?.hasNextPage;

    if (connectionData.length === 0) {
        return (
            <div>
                <small>
                    <i>No future appointments</i>
                </small>
            </div>
        );
    }

    const onChangeSelection = (item: NodeType.AppointmentItem) => {
        setSelectedAppt(item);
    };

    const handleSubmit = () => {
        onSelect?.(selectedAppt);
    };

    return (
        <>
            <div className={'py-4'}>
                <b>Selected Date: </b>
                <Display.Date value={selectedAppt?.startDate} defaultString={'--'} />
            </div>

            <TableAppointments
                mode={mode}
                headerLeft={headerLeft}
                headerRight={headerRight}
                appointmentItems={connectionData}
                selectedAppointment={selectedAppt}
                onSelect={onChangeSelection}
            />

            {hasNext && (
                <Button
                    size={'small'}
                    basic
                    fluid
                    loading={connectionState.isFetchingMore}
                    content={'Load more...'}
                    onClick={() => loadMore({ forward: true })}
                />
            )}

            <div className={'flex justify-between mt-2'}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button disabled={!selectedAppt} variant={'primary'} onClick={handleSubmit} className={'float-right'}>
                    Select
                </Button>
            </div>
        </>
    );
};
