import styled from 'styled-components';

const space = (props) => {
    return (props.space || 0.75) + 'rem';
};

export const VStack = styled.div.attrs({ 'data-testid': 'VStack' })<{ space?: number }>`
    display: flex;
    flex-direction: column;
    gap: ${space};
    //flex-grow: 1;
    //> * {
    //    :not(:last-child) {
    //        margin-bottom: ${space} !important;
    //    }
    //}
`;

// alias
export const Stack = VStack;

export const HStack = styled.div<{ space?: number }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: start;
    > * {
        display: block;
        flex: 1 1 auto;
        :not(:first-child) {
            margin-left: ${space} !important;
        }
    }
`;
