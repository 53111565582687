import gql from 'graphql-tag';

import { appointmentItemFragment, recurrenceFragment, staffFragment } from '@poolware/api';
import { jobTodoFragment } from '../../queries/fragments';

export const AppointmentItemDockViewQuery = gql`
    query AppointmentItemQueryDockView($id: ID!) {
        viewer {
            me {
                franchise {
                    id
                    vend {
                        id
                    }
                }
            }
        }
        appointmentItem: node(id: $id) {
            ...AppointmentItemFragment
            ... on AppointmentItem {
                isRecurrentOrigin
                isWorkOrderPending
                patternBlockId
                appointment {
                    id
                    startDate
                    recurrence {
                        ...RecurrenceFragment
                    }
                    parentAppointment {
                        id
                        patternBlockId
                        recurrence {
                            ...RecurrenceFragment
                        }
                    }
                }
                pool {
                    id
                    volume
                    type {
                        id
                        name
                    }
                    sanitiser {
                        id
                        name
                    }
                    site {
                        id
                        name
                        accessKeyLocation
                    }
                }
                customer {
                    id
                    note
                    primaryPhone {
                        id
                        data
                    }
                    primaryEmail {
                        id
                        data
                    }
                }
                workOrder {
                    id
                    title
                    description
                    workOrderNumber
                    pool {
                        id
                    }
                    testReports {
                        edges {
                            node {
                                id
                                createdAt
                                pdfUrl
                                pool {
                                    id
                                    entity {
                                        id
                                        user {
                                            id
                                            firstName
                                            lastName
                                            customer {
                                                id
                                                contactName
                                                crn
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    followUpFrom {
                        id
                        title
                        workOrderNumber
                        appointmentItem {
                            id
                            startDate
                            duration
                        }
                    }
                    stage {
                        id
                        title
                        type
                        status
                    }
                    jobs {
                        edges {
                            node {
                                id
                                ...JobTodoFragment
                            }
                        }
                    }
                    notes {
                        edges {
                            node {
                                id
                                note
                                createdAt
                                updatedAt
                                addedBy {
                                    id
                                    ...StaffFragment
                                }
                            }
                        }
                    }
                    files {
                        edges {
                            node {
                                id
                                note
                                fileName
                                mimeType
                                location
                                createdAt
                                isImage
                                imageUrl
                                url
                            }
                        }
                    }
                }
                serviceJob {
                    id
                    title
                    serviceJobNumber
                    isRecurring
                    stage {
                        id
                        title
                        type
                        status
                    }
                    implicitType
                    isImplicit
                    group {
                        id
                        title
                    }
                }
                franchise {
                    id
                    vend {
                        id
                    }
                }
                paymentUrl
                vendSale {
                    id
                    historyUrl
                    webRegistryUrl
                }
            }
        }
        viewer {
            appointmentGroups {
                edges {
                    node {
                        id
                        title
                        defaultColor
                        description
                        priority
                        colorPalette
                    }
                }
            }
        }
    }
    ${jobTodoFragment}
    ${appointmentItemFragment}
    ${staffFragment}
    ${recurrenceFragment}
`;
