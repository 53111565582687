import * as React from 'react';
import { useState } from 'react';
import { InputWithAction } from '@ez/components';
import { NodeType } from '@poolware/api';
import { NotesForm } from './NotesForm';

export interface JobNote {
    id: NodeType.ID;
    note?: string;
    createdAt?: Date;
    updatedAt?: Date;
    addedBy?: NodeType.Staff;
}

interface NotesFeedItemProps {
    placeholder?: string;
    submitButtonLabel?: string;
    onSubmit: (values: Omit<JobNote, 'id'>) => any;
}

export const NotesFeedNewItem: React.FC<NotesFeedItemProps> = ({
    placeholder = 'Post an update...',
    onSubmit,
    submitButtonLabel = 'Post',
}) => {
    const [isEditing, setEditing] = useState(false);

    const startEdit = () => setEditing(true);

    if (!isEditing) {
        return (
            <div className={'flex flex-row gap-1'}>
                <InputWithAction onClick={startEdit} placeholder={placeholder} actionLabel={submitButtonLabel} />
                {/*<Input onClick={startEdit} onChange={startEdit} placeholder={placeholder} />*/}
                {/*<Button content={submitButtonLabel} onClick={startEdit} />*/}
            </div>
        );
    }

    const handleOnSubmit = async (value: Omit<JobNote, 'id'>) => {
        await onSubmit(value);
        setEditing(false);
    };

    return (
        <NotesForm
            onSubmit={handleOnSubmit}
            submitButton={{ content: submitButtonLabel }}
            onCancel={() => {
                setEditing(false);
            }}
            initialValues={{
                note: '',
            }}
            placeholder={placeholder}
        />
    );
};
