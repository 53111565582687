import * as React from 'react';
import * as Menubar from '@radix-ui/react-menubar';
import { clsxm } from '../../utils';
import { Icon } from '../../Icon';

export const NavbarDropdownMenu: React.FC<{
    icon?: React.ReactElement;
    label?: React.ReactNode;
    value?: string;
}> = ({ icon, label, children }) => {
    return (
        <Menubar.Menu>
            <Menubar.Trigger className={clsxm(['ez-navbar-dropdown-menu'])}>
                {icon && <span>{icon}</span>}
                {label && <span className={'hidden md:inline-block whitespace-nowrap'}>{label}</span>}
                <Icon
                    name={'caret down'}
                    className={'!mr-0 !-mt-1 '}
                    style={{
                        // HACK: offset the caret down icon to align with the text
                        marginTop: '-4px !important',
                    }}
                />
            </Menubar.Trigger>
            <Menubar.Portal>
                <Menubar.Content
                    className={clsxm([
                        'z-[1000] min-w-[8rem] overflow-hidden rounded border p-2',
                        'bg-popover text-popover-foreground shadow-md',
                        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
                        'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                        'flex flex-col gap-1',
                    ])}
                    align="start"
                    sideOffset={5}
                    alignOffset={0}
                >
                    {children}
                </Menubar.Content>
            </Menubar.Portal>
        </Menubar.Menu>
    );
};

export const NavbarDropdownMenuItem = React.forwardRef<
    React.ElementRef<typeof Menubar.Item>,
    React.ComponentPropsWithoutRef<typeof Menubar.Item> & {
        icon?: React.ReactElement;
        content?: React.ReactNode;
    }
>(({ asChild, className, icon, children, content, ...props }, ref) => {
    const hasContent = content || children;
    return (
        <Menubar.Item
            ref={ref}
            className={clsxm([
                'focusable relative cursor-pointer select-none items-center rounded-sm text-base outline-none',
                'group focus:bg-accent focus:text-accent-foreground',
                'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                'flex flex-row gap-2 p-2 px-2',
                className,
            ])}
            asChild={asChild}
            {...props}
        >
            {asChild ? (
                <>{children}</>
            ) : (
                <>
                    {icon && <span>{icon}</span>}
                    {hasContent && (
                        <span className={'inline-block whitespace-nowrap'}>
                            {content}
                            {children}
                        </span>
                    )}
                </>
            )}
        </Menubar.Item>
    );
});
