import * as React from 'react';
import invariant from 'invariant';
import { Button, Icon } from '@ez/components';
import { NodeType } from '@poolware/api';
import { AppointmentStatusUiConf, mapConfFromStatusMap } from '../../utils';

export const ButtonChangeStatus: React.FC<{ onChangeStatus: (string) => any } & AppointmentStatusUiConf> = ({
    active,
    title,
    type,
    icon,
    onChangeStatus,
    ...props
}) => (
    <Button
        basic={!active}
        onClick={() => onChangeStatus(type)}
        icon={<Icon {...icon} />}
        variant={active ? 'primary' : 'secondary'}
        content={title}
        color={'teal'}
        {...props}
    />
);

const ActionButtonsFull: React.FC<ActionButtonProps & { buttonConfs: AppointmentStatusUiConf[] }> = ({
    buttonConfs,
    onChangeStatus,
    onDelete,
}) => {
    return (
        <div className={'flex flex-col-reverse gap-4 md:flex-row flex-wrap-reverse justify-between p-4'}>
            {onDelete && <Button color="red" onClick={onDelete} icon={'trash'} content={'Delete'} />}
            <div className={'flex flex-col md:flex-row gap-1'}>
                {buttonConfs.map((conf, index) => {
                    return <ButtonChangeStatus key={index} {...conf} onChangeStatus={onChangeStatus} />;
                })}
            </div>
        </div>
    );
};

type ActionButtonProps = {
    appointmentItem: NodeType.AppointmentItem;
    onChangeStatus: (string) => any;
    onDelete: () => any;
};

const ActionButtons: React.FC<ActionButtonProps> = ({ appointmentItem, ...rest }) => {
    invariant(appointmentItem, 'Expected appointment item');

    const { status } = appointmentItem;
    const buttonConfs = mapConfFromStatusMap(status);

    return <ActionButtonsFull {...rest} buttonConfs={buttonConfs} appointmentItem={appointmentItem} />;
};
export default ActionButtons;
