import * as React from 'react';
import { ButtonWithPopup, ButtonWithPopupProps } from './ButtonWithPopup';
import { Icon } from '../Icon';

export const IconButton: React.FC<
    ButtonWithPopupProps & {
        icon: string;
    }
> = ({ onClick, popup, className, name: icon, ...props }) => {
    return (
        <ButtonWithPopup
            popup={popup}
            icon={<Icon name={icon} className={'w-[1.4em] h-[1.4em]'} />}
            onClick={onClick}
            variant={'tertiary'}
            color={'tertiary'}
            className={className}
            {...props}
        />
    );
};
