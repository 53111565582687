import { useEffect, useMemo, useState } from 'react';
import { QueryConnectionConfigProps, SortDirection } from './types';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import { makeDebugger } from '@ez/tools';

const debug = makeDebugger('api:state');

const getQueryVariables = (connectionStateConfig: QueryConnectionConfigProps): any => {
    const variables: any = {};
    const setVar = (varPath: string, confPath: string) => {
        const v = connectionStateConfig[confPath] !== undefined ? connectionStateConfig[confPath] : undefined;
        _set(variables, varPath, v);
    };
    setVar('after', 'after');
    setVar('before', 'before');
    setVar('page', 'pageIndex');
    setVar('first', 'pageSize');

    const sortKey = connectionStateConfig['sortKey'];
    const sortDirection = connectionStateConfig['sortDirection'];
    if (sortKey) {
        const dir = sortDirection === SortDirection.ASC;
        _set(variables, 'sort.' + sortKey, dir);
    }

    const search = connectionStateConfig['search'];
    if (!_isEmpty(search)) {
        _set(variables, 'search', search);
    }

    return variables;
};

const ManagedKeys = [
    'after',
    'setAfter',
    'before',
    'setBefore',
    'pageIndex',
    'setPageIndex',
    'pageSize',
    'setPageSize',
    'sortKey',
    'setSortKey',
    'sortDirection',
    'setSortDirection',
    'search',
    'setSearch',
];

const toValOrUndefined = <T>(obj: T) => {
    if (!obj || _isEmpty(obj)) return undefined;
    else return obj;
};

const getDepArrayForKeys = (object: Object = {}, keys: string[]) => {
    return keys.map((key) => {
        let value = object[key];
        if (typeof value === 'function') {
            return value;
        } else {
            value = toValOrUndefined(value);
            try {
                // convert to string.
                return JSON.stringify(value);
            } catch (e) {
                return value;
            }
        }
    });
};

export const useDefaultConnectionState = <TVariablesSearch = any>(
    props: Partial<QueryConnectionConfigProps<TVariablesSearch>> = {}
) => {
    const [sortKey, setSortKey] = useState<string>(props.defaultSortKey || props.sortKey);
    const [sortDirection, setSortDirection] = useState<SortDirection>(
        props.defaultSortDirection || props.sortDirection || SortDirection.ASC
    );
    const [after, setAfter] = useState<string>(null);
    const [before, setBefore] = useState<string>(null);
    const [pageIndex, setPageIndex] = useState(props.pageIndex || 0);
    const [pageSize, setPageSize] = useState(props.pageSize || props.defaultPageSize || 30);
    const [search, setSearch] = useState(props.search || props.defaultSearch);

    const variablesExt = toValOrUndefined(props.variables);
    const searchExt = toValOrUndefined(props.search);
    const debugName = props.debugName || '-';

    let variablesExtString;
    let searchExtString;
    try {
        // convert to string, otherwise hook dependency comparison fails and connection re-renders.
        variablesExtString = variablesExt ? JSON.stringify(variablesExt) : variablesExt;
        searchExtString = searchExt ? JSON.stringify(search) : searchExt;
    } catch (e) {
        variablesExtString = variablesExt;
        searchExtString = searchExt;
    }

    useEffect(() => {
        const pendingResetIndex = !_isEqual(searchExt, search);
        // Reset page index if search query changed.
        if (pendingResetIndex) {
            if (!props.setPageIndex) {
                setSearch(searchExt);
                setPageIndex(0);
                setAfter(null);
                setBefore(null);
            }
        }
    }, [searchExt]);

    useEffect(() => {
        // Reset page index if sort key changed
        if (!props.setPageIndex && !props.setSortKey && pageIndex !== 0) {
            setPageIndex(0);
            setAfter(null);
            setBefore(null);
        }
    }, [sortKey]);

    useEffect(() => {
        // Reset page index if sort key changed
        if (!props.setPageIndex && !props.setSortDirection && pageIndex !== 0) {
            debug(`useEffect (${debugName}): sortDeriection->setPageIndex(0)`);
            setPageIndex(0);
            setAfter(null);
            setBefore(null);
        }
    }, [sortDirection]);

    const depArray = [
        ...getDepArrayForKeys(props, ManagedKeys),
        variablesExtString,
        searchExtString,
        sortKey,
        sortDirection,
        after,
        before,
        pageIndex,
        pageSize,
    ];

    return useMemo(() => {
        const connectionState: any = {};
        const setVar = (path: keyof QueryConnectionConfigProps, defaultVal) => {
            connectionState[path] = props[path] !== undefined ? props[path] : defaultVal;
        };

        setVar('after', after);
        setVar('setAfter', setAfter);
        setVar('before', before);
        setVar('setBefore', setBefore);
        setVar('pageIndex', pageIndex);
        setVar('setPageIndex', setPageIndex);
        setVar('pageSize', pageSize);
        setVar('setPageSize', setPageSize);
        setVar('sortKey', sortKey);
        setVar('setSortKey', setSortKey);
        setVar('sortDirection', sortDirection);
        setVar('setSortDirection', setSortDirection);
        setVar('search', search);
        setVar('setSearch', setSearch);

        const { variables: variablesExt } = props;

        const variables = getQueryVariables(connectionState);
        const v = { ...variables, ...variablesExt };

        if (false) {
            const debugName = props.debugName || '-';
            console.groupCollapsed(`useDefaultConnectionState (${debugName})`);
            console.info({
                depArray: depArray,
                depArrayObj: _pick(props, ManagedKeys),
                variablesExt: props.variables,
                variablesResult: v,
                search,
                sortKey,
                sortDirection,
                after,
                before,
                pageIndex,
                pageSize,
            });
            // console.trace();
            console.groupEnd();
        }

        return {
            variables: toValOrUndefined(v),
            connectionState: connectionState,
        };
    }, depArray);
};
