import * as React from 'react';
import gql from 'graphql-tag';
import * as _ from 'lodash';

import { Display, FormTableRowWithRadiobox, PageSkeletonLoader } from '@ez/components';
import { addressFragment, createUseQueryNodeHook, fromEdges, NodeType } from '@poolware/api';
import { Alert, Icon, Table } from '@ez/components';

const TableRowAddress = ({ label, address, onSelect, isSelected, icon, readOnly }) => {
    if (!address && !label && !onSelect) return null;

    const handleClick = () => {
        // deselect if selected
        onSelect && onSelect(isSelected ? null : address);
    };

    const formattedLabel = (
        <>
            <b>
                <Icon name={icon} /> {_.truncate(label, { length: 60 })}
            </b>
            {address && (
                <span style={{ paddingLeft: '0.5em', color: '#444444' }}>
                    <Display.Address value={address} />
                </span>
            )}
        </>
    );

    return (
        <FormTableRowWithRadiobox
            readOnly={readOnly}
            isSelected={isSelected}
            onClick={handleClick}
            label={formattedLabel}
        />
    );
};

const TableRowPool = ({ pool, onSelect, ...rest }: any) => {
    const { name, address } = pool;
    const label = `Pool: "${name}"`;

    const handelClick = () => {
        // deselect if selected
        onSelect && onSelect(rest.isSelected ? null : pool);
    };
    return <TableRowAddress {...rest} onSelect={handelClick} label={label} address={address} icon={'life ring'} />;
};

interface CustomerAddressListProps {
    customerId: NodeType.ID;
    selectedPoolId?: NodeType.ID;
    selectedAddressId?: NodeType.ID;
    onPoolSelect?: (pool: NodeType.Pool) => any;
    onAddressSelect?: (address: NodeType.Address) => any;
    readOnly?: boolean;
}

export const CustomerAddressList: React.FC<CustomerAddressListProps> = (props) => {
    const { customerId, onAddressSelect, onPoolSelect, selectedAddressId, selectedPoolId, readOnly } = props;
    const { error, loading, node: customer } = useQueryCustomerAddressList(customerId);
    if (loading) {
        return <PageSkeletonLoader />;
    }

    if (error) {
        return (
            <Alert type={'error'}>
                <pre>{JSON.stringify(error, null, 2)}</pre>
            </Alert>
        );
    }

    const billAddress = customer?.billAddress;
    const primaryAddress = customer?.primaryAddress;
    const sites: NodeType.Site[] = fromEdges(customer?.sites);

    return (
        <Table size={'small'} compact padded={false} celled={false} basic>
            <Table.Body>
                {sites.map((site) => {
                    const { pools } = site;
                    const poolRows = fromEdges(pools).map((pool) => (
                        <TableRowPool
                            readOnly={readOnly}
                            key={pool.id}
                            pool={pool}
                            isSelected={selectedPoolId === pool.id}
                            onSelect={onPoolSelect}
                        />
                    ));

                    return [...poolRows];
                })}
                {primaryAddress && (
                    <TableRowAddress
                        readOnly={readOnly}
                        label="Primary Address"
                        address={primaryAddress}
                        icon={'building outline'}
                        onSelect={onAddressSelect}
                        isSelected={selectedAddressId === primaryAddress.id}
                    />
                )}
                {billAddress && (
                    <TableRowAddress
                        readOnly={readOnly}
                        label="Billing Address"
                        icon={'building outline'}
                        address={billAddress}
                        onSelect={onAddressSelect}
                        isSelected={selectedAddressId === billAddress.id}
                    />
                )}
            </Table.Body>
        </Table>
    );
};

const QL = gql`
    query CustomerAddressListQuery($id: ID!) {
        node(id: $id) {
            ... on Customer {
                id
                crn
                contactName
                sites(first: 100) {
                    edges {
                        node {
                            id
                            name
                            address {
                                ...AddressFragment
                            }
                            pools {
                                edges {
                                    node {
                                        id
                                        name
                                        bottleNumber
                                        address {
                                            id
                                            ...AddressFragment
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                billAddress {
                    ...AddressFragment
                }
                primaryAddress {
                    ...AddressFragment
                }
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
    ${addressFragment}
`;

function useQueryCustomerAddressList(customerId: NodeType.ID) {
    return createUseQueryNodeHook<NodeType.Customer>(QL)(customerId, { fetchPolicy: 'cache-and-network' });
}
