import * as React from 'react';
import { DndContext } from '@poolware/react-big-calendar';
import { CalendarEventMode } from '../types';
import { CalendarEventAppItem } from './CalendarEventAppItem';
import { CalendarEventBaseProps } from './types';
import { CalendarEventDaySummary } from './CalendarEventDaySummary';
import { cn } from '@ez/tools';

export class CalendarEvent extends React.PureComponent<CalendarEventBaseProps> {
    render() {
        if (this.props.event?.mode === CalendarEventMode.DAY_SUMMARY) {
            return <CalendarEventDaySummary event={this.props.event} draggable={this.props.draggable} />;
        } else {
            return (
                <DndContext.Consumer>
                    {(context) => {
                        return <CalendarEventAppItem {...this.props} {...context} />;
                    }}
                </DndContext.Consumer>
            );
        }
    }
    static eventPropGetter = (event, start, end, isSelected) => {
        const className = cn({
            highlighted: event.highlighted,
            'highlighted-pop': event.highlightedPop,
            dimmed: event.dimmed,
            hidden: event.hidden,
            selected: event.selected,
        });

        return {
            className: className,
        };
    };
}
