import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Frame } from './components';
import { Button, Display } from '@ez/components';

const WaterTestMeasurementItem: React.FC = (props) => {
    return (
        <div className={'flex flex-row justify-between'}>
            <div className={'flex-1'}>Calcium Hardness</div>
            <div className={'w-20'}>
                <strong>60</strong> pH
            </div>
            <div className={'w-16 text-red'}>HIGH</div>
        </div>
    );
};

export const WaterTestSection: React.FC<{ testReport: NodeType.TestReport }> = ({ testReport }) => {
    const openPDFReport = () => {
        window.open(testReport.pdfUrl, '_blank');
    };
    return (
        <Frame className={'bg-tertiary rounded p-2'}>
            <Frame className={'bg-main rounded p-2 flex flex-col gap-4 text-sm'}>
                <div className={'gap-x-2 gap-y-1 flex-row flex'}>
                    <div className={'bg-blue text-white rounded-full px-2 max-w-fit'}>Water test</div>
                    <strong>
                        <Display.Date value={testReport.createdAt} />{' '}
                    </strong>
                </div>
                <div className={'rounded border p-2 hidden'}>
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                </div>
            </Frame>
            <div className={'flex flex-row items-center justify-between'}>
                <Button size={'lg'} variant={'tertiary'} color={'primary'} icon={'chart area'}>
                    View Report
                </Button>
                <Button size={'lg'} variant={'tertiary'} color={'primary'} icon={'download'} onClick={openPDFReport}>
                    Download PDF
                </Button>
            </div>
        </Frame>
    );
};
