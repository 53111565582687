import * as React from 'react';
import gql from 'graphql-tag';
import { NodeType } from '../api-types';
import { QueryConnectionExternalProps, useDefaultConnectionState, useQueryConnection } from '@ez/api-core';

const QL = gql`
    query QueryConnectionRoles {
        viewer {
            roles {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export interface QueryRoleConnectionExternalProps extends QueryConnectionExternalProps<NodeType.Role> {}

export const useQueryConnectionRoles = (props?: QueryRoleConnectionExternalProps) => {
    const connectionPath = 'viewer.roles';

    const { variables, connectionState } = useDefaultConnectionState(props);

    return useQueryConnection<NodeType.Role>({
        query: props?.query || QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
};
