import { compose, withProps } from '@ez/tools';
import { IMutateEmailMessage, NodeType, withEmailMessageMutator } from './api-types';
import _trim from 'lodash/trim';

interface EmailMessageMutatorInjected extends IMutateEmailMessage {}

const createMutator = ({ mutateEmailMessage }: EmailMessageMutatorInjected) => {
    const sendTestReport = async (
        testReport: NodeType.NodeOrId<NodeType.TestReport>,
        destinationEmail: string,
        note?: string
    ) => {
        let mutationConfig: NodeType.EmailMessageCreateForReportAndSendMutationInput = {
            recipient: destinationEmail,
            report: NodeType.extractId(testReport),
        };
        const trimmedNote = _trim(note);
        if (trimmedNote) {
            mutationConfig.note = trimmedNote;
        }
        return await mutateEmailMessage.createForReportAndSend(mutationConfig);
    };

    return {
        EmailMessageMutator: {
            sendTestReport,
        },
    };
};

export const withEmailMessageMutators = (refetchQueries?) =>
    compose(withEmailMessageMutator(refetchQueries), withProps(createMutator));

export interface IEmailMessageMutators extends EmailMessageMutatorInjected, ReturnType<typeof createMutator> {}
