export type CRUD = {
    root: string;
    paramName?: string;
    all?: string;
    new?: string;
    view?: string;
    edit?: string;
} & { [key: string]: any };

export const App = {
    home: '/',
    login: '/login',
};

export const Account = {
    viewProfile: '/profile',
    editDetails: '/profile/edit-details',
    editPassword: '/profile/edit-password',
};

export const Staff = (staffId?: string): CRUD => {
    const path = 'staff';
    const fullPath = `/${path}`;
    const CRUD = {
        root: path,
        paramName: 'staffId',
        all: `${fullPath}/all`,
        new: `${fullPath}/new`,
        view: `${fullPath}/${staffId}`,
        edit: `${fullPath}/${staffId}/edit`,
    };

    return { ...CRUD };
};

export const Franchise = (franchiseId?: string) => {
    const path = 'admin/franchises';
    const fullPath = `/${path}`;
    const franchiseCRUD = {
        root: path,
        paramName: 'franchiseId',
        all: `${fullPath}`,
        new: `${fullPath}/new`,
        view: `${fullPath}/${franchiseId}`,
        edit: `${fullPath}/${franchiseId}/edit`,
    };

    return {
        ...franchiseCRUD,
        Staff: (staffId?: string) => {
            const path = 'staff';
            const fullPath = `${franchiseCRUD.view}/${path}`;
            const CRUD: CRUD = {
                root: path,
                paramName: 'staffId',
                new: `${fullPath}/new`,
                view: `${fullPath}/${staffId}`,
                edit: `${fullPath}/${staffId}/edit`,
            };
            return CRUD;
        },
        FranchiseShop: (shopId?: string) => {
            const path = 'franchise-shop';
            const fullPath = `${franchiseCRUD.view}/${path}`;
            const CRUD: CRUD = {
                root: path,
                paramName: 'shopId',
                new: `${fullPath}/new`,
                view: `${fullPath}/${shopId}`,
                edit: `${fullPath}/${shopId}/edit`,
            };
            return CRUD;
        },
    };
};

export const Scheduler = {
    home: `/scheduler`,
    new: `/scheduler/new`,
    where: `/scheduler/where`,
    view: (id?: string) => `/scheduler/${id}`,
    edit: (id?: string) => `/scheduler/${id}/edit`,
    editAppointmentGroup: (id?: string) => `/scheduler/appointment-group/${id}`,
    newAppointmentGroup: `/scheduler/appointment-group/new`,
};

export const Jobs = (jobId?: string) => {
    const root = `/`;
    const subPath = 'jobs';
    const fullPath = root + subPath;
    const CRUD: CRUD = {
        root: fullPath,
        subPath: subPath,
        paramName: 'jobId',
        all: `${fullPath}`,
        new: `${fullPath}/new`,
        view: `${fullPath}/${jobId}`,
    };

    return { ...CRUD };
};

export const ServiceJobs = (serviceJobId?: string) => {
    const root = `/`;
    const subPath = 'service-jobs';
    const fullPath = root + subPath;
    const serviceJobCrud: CRUD = {
        root: fullPath,
        subPath: subPath,
        paramName: 'serviceJobId',
        all: `${fullPath}`,
        new: `${fullPath}/new`,
        view: `${fullPath}/${serviceJobId}`,
        edit: `${fullPath}/${serviceJobId}/edit`,
    };

    return {
        ...serviceJobCrud,
        JobTodo: (jobTodoId?: string) => {
            const path = 'job-todo';
            const fullPath = `${serviceJobCrud.view}/${path}`;
            const CRUD = {
                root: path,
                paramName: 'jobTodoId',
                new: `${fullPath}/new`,
                view: `${fullPath}/${jobTodoId}`,
            };
            return CRUD;
        },
    };
};

export const Search = {
    search: '/search',
};

export const Customers = {
    all: `/customers`,
    new: `/customer-new`,
};

export const Devices = (id?: string) => ({
    all: `/devices`,
    new: `/devices/new`,
    view: `/devices/${id}`,
});

export const WarrantyClaim = (id?: string) => ({
    all: `/warranty-claims`,
    new: `/warranty-claims/new`,
});

export const Customer = (customerId?: string) => {
    let customerView = `/customers/${customerId}`;
    return {
        all: `/customers`,
        new: `/customers/new`,
        view: customerView,
        edit: customerView + '/edit',

        addSite: `${customerView}/add-site`,
        Site: (siteId?: string) => {
            let siteView = `${customerView}/site/${siteId}`;
            return {
                edit: `${siteView}/edit`,
                editAccessKeyLocation: `${siteView}/edit-access-key`,
                transferOwnership: `${siteView}/transfer-ownership`,
            };
        },

        addPool: `${customerView}/add-pool`,
        Pool: (poolId?: string) => {
            let poolView = `${customerView}/pools/${poolId}`;
            return {
                view: poolView,
                edit: `${customerView}/pools/${poolId}/edit`,
                editEquipmentList: `${customerView}/pools/${poolId}/equipment`,
                addRawDataSample: `${customerView}/pools/${poolId}/add-raw-data-sample`,
                WaterTest: (testId?: string) => {
                    let testView = `${poolView}/tests/${testId}`;
                    return {
                        new: `${poolView}/tests/new`,
                        view: `${testView}`,
                        edit: `${testView}/edit`,
                        EmailLog: (emailId?: string) => ({
                            emailLogs: `${testView}/email-logs`,
                        }),
                    };
                },
            };
        },
    };
};

export const Dashboard = () => {
    return { view: '/dashboard' };
};

export const Reports = () => {
    return { view: '/reports' };
};

export const AllProducts = (id?: string) => {
    const path = 'products';
    const basepath = `/${path}`;
    const CRUD: CRUD = {
        root: path,
        paramName: 'productId',
        all: `/${basepath}`,
        new: `/${basepath}/new`,
        view: `/${basepath}/${id}`,
        edit: `/${basepath}/${id}/edit`,
    };
    return { ...CRUD };
};

export const Categories = (id?: string) => {
    const path = 'category';
    const basepath = `/${path}`;
    const CRUD = {
        root: path,
        paramName: 'admin/configurator/categoryId',
        all: `/${basepath}`,
        new: `/${basepath}/new`,
        view: `/${basepath}/${id}`,
        edit: `/${basepath}/${id}/edit`,
    };
    return { ...CRUD };
};

export const InstallableProduct = (id?: string) => {
    const path = 'installable-products';
    const basepath = `admin/configurator/${path}`;
    const CRUD = {
        root: path,
        paramName: 'productId',
        all: `/${basepath}`,

        new: `/${basepath}/new`,
        view: `/${basepath}/${id}`,
        edit: `/${basepath}/${id}/edit`,
    };

    return { ...CRUD };
};

export const ConsumableProduct = (id?: string) => {
    const path = 'consumable-products';
    const basepath = `admin/configurator/${path}`;
    const CRUD = {
        root: path,
        paramName: 'productId',
        all: `/${basepath}`,
        new: `/${basepath}/new`,
        view: `/${basepath}/${id}`,
        edit: `/${basepath}/${id}/edit`,
    };

    return { ...CRUD };
};

export const HQAdmin = () => {
    const root = `/admin`;
    return {
        root: root,
        Roles: (id?: string) => {
            const subPath = '/roles';
            const fullPath = root + subPath;
            const CRUD = {
                root: fullPath,
                subPath: subPath,
                paramName: 'roleId',
                view: `${fullPath}`,
            };

            return { ...CRUD };
        },
        Franchise: (franchiseId?: string) => {
            const subPath = '/franchises';
            const fullPath = root + subPath;
            const franchiseCRUD = {
                root: fullPath,
                subPath: subPath,
                paramName: 'franchiseId',
                all: `${fullPath}`,
                new: `${fullPath}/new`,
                view: `${fullPath}/${franchiseId}`,
                edit: `${fullPath}/${franchiseId}/edit`,
            };

            return {
                ...franchiseCRUD,
                Staff: (staffId?: string) => {
                    const path = 'staff';
                    const fullPath = `${franchiseCRUD.view}/${path}`;
                    const CRUD = {
                        root: path,
                        paramName: 'staffId',
                        new: `${fullPath}/new`,
                        view: `${fullPath}/${staffId}`,
                        edit: `${fullPath}/${staffId}/edit`,
                    };
                    return CRUD;
                },
                FranchiseShop: (shopId?: string) => {
                    const path = 'franchise-shop';
                    const fullPath = `${franchiseCRUD.view}/${path}`;
                    const CRUD = {
                        root: path,
                        paramName: 'shopId',
                        new: `${fullPath}/new`,
                        view: `${fullPath}/${shopId}`,
                        edit: `${fullPath}/${shopId}/edit`,
                    };
                    return CRUD;
                },
            };
        },
    };
};

export const FranchiseAdmin = () => {
    const root = `/franchise-admin`;
    return {
        root: root,
        Integrations: () => {
            const subPath = '/integrations';
            const fullPath = root + subPath;
            const CRUD = {
                root: fullPath,
                subPath: subPath,
                paramName: 'roleId',
                view: `${fullPath}`,
            };

            return { ...CRUD };
        },
        WaterTreatmentConfig: (id?: string) => {
            const subPath = '/product-config';
            const fullPath = root + subPath;
            const CRUD = {
                root: fullPath,
                subPath: subPath,
                paramName: 'id',
                all: `${fullPath}`,
                view: `${fullPath}/${id}`,
            };

            return { ...CRUD };
        },
        DataExport: () => {
            const subPath = '/export';
            const fullPath = root + subPath;
            const CRUD = {
                root: fullPath,
                subPath: subPath,
                view: `${fullPath}`,
            };

            return { ...CRUD };
        },
        CustomerTags: () => {
            const subPath = '/customer-tags';
            const fullPath = root + subPath;
            const CRUD = {
                root: fullPath,
                subPath: subPath,
                view: `${fullPath}`,
            };

            return { ...CRUD };
        },
        JobTodoTemplates: (jobTemplateId?: string) => {
            const subPath = '/job-todo-templates';
            const fullPath = root + subPath;
            const CRUD = {
                root: fullPath,
                subPath: subPath,
                paramName: 'jobTemplateId',
                all: `${fullPath}`,
                new: `${fullPath}/new`,
                view: `${fullPath}/${jobTemplateId}`,
            };

            return { ...CRUD };
        },
        Franchise: (franchiseId?: string) => {
            const subPath = '/my-franchise';
            const fullPath = root + subPath;
            const franchiseCRUD = {
                root: fullPath,
                subPath: subPath,
                paramName: 'franchiseId',
                view: `${fullPath}`,
                viewWithId: `${fullPath}/${franchiseId}`,
                edit: `${fullPath}/${franchiseId}/edit`,
            };

            return {
                ...franchiseCRUD,
                Staff: (staffId?: string) => {
                    const path = 'staff';
                    const fullPath = `${franchiseCRUD.viewWithId}/${path}`;
                    return {
                        root: path,
                        paramName: 'staffId',
                        new: `${fullPath}/new`,
                        view: `${fullPath}/${staffId}`,
                        edit: `${fullPath}/${staffId}/edit`,
                    };
                },
                FranchiseShop: (shopId?: string) => {
                    const path = 'shop';
                    const fullPath = `${franchiseCRUD.viewWithId}/${path}`;
                    return {
                        root: path,
                        paramName: 'shopId',
                        new: `${fullPath}/new`,
                        view: `${fullPath}/${shopId}`,
                        edit: `${fullPath}/${shopId}/edit`,
                    };
                },
            };
        },
    };
};

export const RecentActivity = () => {
    const path = 'recent-activity';
    const basepath = `${path}`;
    const CRUD = {
        root: path,
        view: `/${basepath}`,
    };

    return { ...CRUD };
};

export const Calculators = () => {
    return {
        all: '/calcs/combined',
    };
};

export const ProductCatalog = (id?: string) => {
    const pathName = 'catalog';
    const basepath = `/${pathName}`;
    const CRUD = {
        all: `${basepath}`,
    };

    return { ...CRUD };
};

export const Downloads = (id?: string) => {
    const pathName = 'downloads';
    const basepath = `/${pathName}`;
    const CRUD = {
        root: basepath,
        paramName: 'downloadableId',
        all: `${basepath}`,
        view: `${basepath}/${id}`,
    };

    return { ...CRUD };
};

export const linkToProductForItem = (item) => {
    // TODO: WaterTest configurator is not a part of the main app anymore.
    //       Need to fix the link

    return null;
};

export const Print = () => {
    const root = `/print`;
    return {
        root: root,
        Customer: (customerId?: string) => {
            const subPath = 'customers';
            const fullPath = `${root}/${subPath}`;
            return {
                subPath: subPath,
                paramName: ':customerId',
                view: `${fullPath}/${customerId}`,
            };
        },
        Pool: (poolId?: string) => {
            const subPath = 'pools';
            const fullPath = `${root}/${subPath}`;
            return {
                subPath: subPath,
                paramName: ':poolId',
                view: `${fullPath}/${poolId}`,
            };
        },
        AppointmentItem: (appointmentItemId?: string) => {
            const subPath = 'scheduler/appt';
            const fullPath = `${root}/${subPath}`;
            return {
                subPath: subPath,
                paramName: ':appointmentItemId',
                list: `${fullPath}/list`,
                view: `${fullPath}/${appointmentItemId}`,
            };
        },
    };
};
