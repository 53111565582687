import * as React from 'react';
import { Helmet, useViewer } from '@poolware/app-shell';

import {
    Alert,
    AlertHeader,
    Display,
    Icon,
    PageSkeletonLoader,
    PrintPageNoteText,
    PrintPageSubHeader,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionConsumer,
    PrintSectionHeader,
    PrintSectionItem,
    PrintSectionProvider,
    stringFormatters,
} from '@ez/components';

import { CustomerDetailsSection } from '../../../ServiceJobs/Print/CustomerAndLocationSection';
import { useAppointmentItemQuery } from '../../AppointmentPreview/use-appointment-item-query';
import { useAppNavigator } from '@poolware/react-app-navigator';

const Page: React.FC = () => {
    const { params } = useAppNavigator();
    const { modulesAccess } = useViewer();
    const { appointmentItem, isLoading } = useAppointmentItemQuery(params.appointmentItemId);

    if (!appointmentItem) {
        if (isLoading) {
            return (
                <div className={'p-2'}>
                    <PageSkeletonLoader lineCount={5} />
                </div>
            );
        } else {
            return (
                <Alert>
                    <AlertHeader>
                        <Icon name={'frown'} /> Failed to load appointment details.
                    </AlertHeader>
                </Alert>
            );
        }
    }

    const isRecurring = appointmentItem?.isRecurring;

    const headerTitle = `Appointment ${isRecurring ? '(Recurring)' : ''}`;
    const { startDate, duration, address, pool, staff, customer } = appointmentItem;

    const accessKeyLocation = pool?.site?.accessKeyLocation;
    const noPrintDefaults = {
        note: false,
        customer: false,
        location: false,
        jobs: false,
        staff: false,
        customer_note: false,
    };

    const printAppointmentCustomerNotes = modulesAccess?.FieldServices?.printAppointmentCustomerNotes;

    return (
        <PrintSectionProvider moduleName={'print.toggles.appointment-item'} defaultState={noPrintDefaults}>
            <Helmet title={headerTitle} />
            <div>
                <PrintPageSubHeader>
                    <Display.DateRange dayFormat={'dddd, DD MMM YYYY'} startDate={startDate} duration={duration} />
                </PrintPageSubHeader>

                <CustomerDetailsSection customer={customer} />

                {printAppointmentCustomerNotes && (
                    <PrintSectionConsumer name={'customer_note'}>
                        <PrintSectionHeader>Customer Notes</PrintSectionHeader>
                        <PrintSectionBody>
                            <PrintSectionColumn paddingVal={2}>
                                <PrintPageNoteText
                                    formatted={true}
                                    limit={10000}
                                    value={customer?.note}
                                    style={{ minHeight: '4cm' }}
                                />
                            </PrintSectionColumn>
                        </PrintSectionBody>
                    </PrintSectionConsumer>
                )}

                <PrintSectionConsumer name={'staff'}>
                    <PrintSectionHeader>Technician</PrintSectionHeader>
                    <PrintSectionBody>
                        <PrintSectionColumn>
                            <PrintSectionItem label="Name">
                                {stringFormatters.formatEntityName(staff?.user, '')}
                            </PrintSectionItem>
                        </PrintSectionColumn>
                    </PrintSectionBody>
                </PrintSectionConsumer>

                {address && (
                    <PrintSectionConsumer name={'address'}>
                        <PrintSectionHeader>Appointment Address</PrintSectionHeader>
                        <PrintSectionBody>
                            <PrintSectionColumn>
                                <PrintSectionItem label="Address">
                                    <Display.Address value={address} />
                                </PrintSectionItem>
                            </PrintSectionColumn>
                        </PrintSectionBody>
                    </PrintSectionConsumer>
                )}

                {pool && (
                    <PrintSectionConsumer name={'pool'}>
                        <PrintSectionHeader>Pool</PrintSectionHeader>
                        <PrintSectionBody>
                            <PrintSectionColumn>
                                <PrintSectionItem label="Pool Name" content={pool.name} />
                                <PrintSectionItem label="Volume" content={`${pool.volume || '--'} L`} />
                                <PrintSectionItem label="Type" content={pool?.type?.name || '--'} />
                                <PrintSectionItem label="Sanitiser" content={pool?.sanitiser?.name || '--'} />
                                <PrintSectionItem
                                    label="Pool Address"
                                    content={pool && stringFormatters.formatAddress(pool.address)}
                                />
                                <hr />
                                <PrintSectionItem label="Site Name" content={pool.site && pool.site.name} />
                                {accessKeyLocation && (
                                    <PrintSectionItem
                                        label="Site Access"
                                        content={pool.site && pool.site.accessKeyLocation}
                                    />
                                )}
                            </PrintSectionColumn>
                        </PrintSectionBody>
                    </PrintSectionConsumer>
                )}

                <PrintSectionConsumer name={'note'}>
                    <PrintSectionHeader>Notes</PrintSectionHeader>
                    <PrintSectionBody>
                        <PrintSectionColumn paddingVal={2}>
                            <PrintPageNoteText
                                formatted={false}
                                limit={10000}
                                value={appointmentItem.note}
                                style={{ minHeight: '4cm' }}
                            />
                        </PrintSectionColumn>
                    </PrintSectionBody>
                </PrintSectionConsumer>
            </div>
        </PrintSectionProvider>
    );
};

export default Page;
