import * as React from 'react';
import * as _ from 'lodash';
import { ActionBar, DebouncedInput } from '@ez/components';
import { useWorkOrderFeedActions } from '../../redux';

export const FilterByWorkOrderNumber: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { Action, State } = useWorkOrderFeedActions();
    const { woNumber } = State.filters;

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            Action.setWorkOrderNumber(null);
        } else {
            Action.setWorkOrderNumber(_.trim(term));
        }
    };

    return (
        <ActionBar>
            <ActionBar.Header content={label} icon={'filter'} />
            <DebouncedInput
                // label={label}
                initialValue={woNumber}
                placeholder={placeholder || 'Search by Work Order ID...'}
                onChange={handleChange}
            />
        </ActionBar>
    );
};
