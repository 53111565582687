import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { FormikFieldOptionType, FormikFormFieldLabel, FormikInputField, FormikSelectField } from '@ez/components';
import { AddressLookup } from './AddressLookup';
import { Field, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
    AddressType,
    CountryCode,
    CountryProfiles,
    CountryProfileType,
    getCountryForCode,
    StateType,
} from './constants';
import { useDefaultCountry } from './hooks';
import { FormGroup } from '@ez/components';
// import { AddressLookupDebug } from './AddressLookupDebug';

/* eslint-disable no-template-curly-in-string */
export let string = {
    length: 'must be exactly ${length} characters',
    min: 'must be at least ${min} characters',
    max: 'must be at most ${max} characters',
    matches: 'must match the following: "${regex}"',
    email: 'must be a valid email',
    url: 'must be a valid URL',
    trim: 'must be a trimmed string',
    lowercase: 'must be a lowercase string',
    uppercase: 'must be a upper case string',
};

Yup.setLocale({ string: string });

export const AddressValidationSchema = Yup.object().shape({
    city: Yup.string().min(1).max(100).notRequired().nullable(), //.required('Required'),
    state: Yup.string().min(1).max(50).required('Required'),
    postCode: Yup.string().min(4).max(8).required('Required'),
    country: Yup.string().notRequired().nullable(), //required('Required'),
});

const countryOptions = CountryProfiles.map((countryName) => {
    let displayName = countryName.full;
    return { text: displayName, value: countryName.short };
});

export interface FormikAddressInputFieldsProps {
    name: string;
    label?: string;
}

const getStateOptionsForCountry = (states: StateType[] = []): FormikFieldOptionType[] => {
    if (!states) return [];
    return states.map((stateName, i) => {
        let displayName = stateName.short + ' - ' + stateName.full;
        return { key: i, text: displayName, value: stateName.short };
    });
};
const getShowStatesAsSelect = (country: CountryProfileType) => {
    return country?.short === CountryCode.AU;
};

const getShowSuburb = (country: CountryProfileType) => {
    return country?.addressFields?.includes('suburb');
};

export const FormikAddressInputFields: React.FC<FormikAddressInputFieldsProps> = (props) => {
    const { defaultCountryCode, setDefaultCountryCode } = useDefaultCountry();
    const [statesOptions, setStatesOptions] = useState([]);
    const [activeCountry, setActiveCountry] = useState<CountryProfileType | undefined>();
    const { values } = useFormikContext();

    const nn = (n) => {
        return `${props.name}.${n}`;
    };

    const formCountryCode = getIn(values, nn('country'));

    useEffect(() => {
        const cp = getCountryForCode(formCountryCode || defaultCountryCode);
        const newStateOptions = getStateOptionsForCountry(cp?.states);
        setActiveCountry(cp);
        setStatesOptions(newStateOptions);
    }, [formCountryCode, defaultCountryCode]);

    const showStatesAsSelect = getShowStatesAsSelect(activeCountry);
    const showSuburb = useMemo(() => getShowSuburb(activeCountry), [activeCountry]);
    const statesOptionsDatalist = statesOptions;
    //useMemo(() => statesOptions.map((s) => s.value), [statesOptions]);
    const startLocation = activeCountry?.centerLoc;

    return (
        <Field name={props.name}>
            {({ field, form }) => {
                const onAddressSelect = (address: AddressType) => {
                    form.setFieldValue(field.name, address);
                    if (address?.country) {
                        setDefaultCountryCode(address?.country as CountryCode);
                    }
                };

                const onDidCountryChange = ({ newValue }) => {
                    setDefaultCountryCode(newValue);
                };

                return (
                    <>
                        {props.label && <FormikFormFieldLabel label={props.label} name={props.name} />}
                        <div className={'rounded bg-panel mb-2 border'}>
                            <AddressLookup onAddressSelect={onAddressSelect} startLocation={startLocation} />
                            {/*<AddressLookupDebug onAddressSelect={onAddressSelect} startLocation={startLocation} />*/}
                            <div className={'p-4 rounded-b'}>
                                <FormGroup>
                                    <FormikInputField
                                        required={false}
                                        name={nn('destinationName')}
                                        autoComplete={'off'}
                                        label="Unit/Flat/Destination Name"
                                        placeholder="e.g. Unit 42, Shop 17a, or Locked Bag 801"
                                    />
                                    <FormikInputField
                                        required={false}
                                        name={nn('buildingName')}
                                        label="Building Name"
                                        placeholder="e.g. City Tower"
                                    />
                                    <FormikInputField
                                        required={false}
                                        name={nn('poBoxNumber')}
                                        autoComplete={'off'}
                                        label="PO Box"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormikInputField
                                        required={false}
                                        name={nn('streetNumber')}
                                        autoComplete={'disabled'}
                                        label="Street No"
                                        placeholder="12a"
                                    />

                                    <FormikInputField
                                        required={false}
                                        name={nn('streetName')}
                                        autoComplete={'disabled'}
                                        label="Street Name"
                                        placeholder="e.g. Woods Road"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    {showSuburb && (
                                        <FormikInputField
                                            required={false}
                                            name={nn('suburb')}
                                            autoComplete={'disabled'}
                                            label="Suburb"
                                            placeholder=""
                                        />
                                    )}
                                    <FormikInputField
                                        // required={true}
                                        name={nn('city')}
                                        autoComplete={'disabled'}
                                        label="City"
                                        placeholder="e.g. Ryde"
                                    />
                                    <FormikInputField
                                        required={true}
                                        name={nn('postCode')}
                                        autoComplete={'disabled'}
                                        label="Postcode"
                                        placeholder="e.g. 2000"
                                        type={'number'}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    {!showStatesAsSelect ? (
                                        <FormikInputField
                                            datalist={statesOptionsDatalist}
                                            required={true}
                                            name={nn('state')}
                                            autoComplete={'disabled'}
                                            label="State"
                                        />
                                    ) : (
                                        <FormikSelectField
                                            required={true}
                                            name={nn('state')}
                                            options={statesOptions}
                                            label="State"
                                        />
                                    )}
                                    <FormikSelectField
                                        required={true}
                                        name={nn('country')}
                                        options={countryOptions}
                                        onDidChange={onDidCountryChange}
                                        label="Country"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </>
                );
            }}
        </Field>
    );
};
