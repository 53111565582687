import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../utils';

const labelVariants = cva(
    'text-sm font-bold leading-none'
    // + 'peer-disabled:cursor-not-allowed peer-disabled:opacity-70 peer-disabled:pointer-events-none'
);

export interface LabelProps
    extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
        VariantProps<typeof labelVariants> {}

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
    ({ className, ...props }, ref) => (
        <LabelPrimitive.Root ref={ref} className={cn('text-sm font-bold leading-none', className)} {...props} />
    )
);
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
