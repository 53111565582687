import { ThemeInterface, ThemeInterfaceFoundation } from './theme.interface';
import { createThemeByHue } from './create-theme-by-hue';

export const getDefaultTheme = (publicAssetPrefix): ThemeInterface => {
    const prefix = publicAssetPrefix || '';

    const primary_hue = 234;
    const primary_sat = 14;
    const primary_lum = 31;

    return createThemeByHue({
        publicPrefix: prefix,
        huePrim: primary_hue,
        primSat: primary_sat,
        primLum: primary_lum,
        hueSec: 20,
    });
};
