import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { useQueryCustomerDetails } from '../queries/user-query-customer-details';
import { Display, PageSkeletonLoader, Panel } from '@ez/components';
import { ContactItems, prepareCustomerContactItems } from './ContactItems';
import { CustomerLink } from '../Links';

export const CustomerContactsPanel: React.FC<{ customerId: NodeType.ID }> = ({ customerId }) => {
    const { node: customer, error, loading } = useQueryCustomerDetails(customerId);
    if (loading) {
        return <PageSkeletonLoader lineCount={5} />;
    }
    if (error) {
        return <div>Error</div>;
    }

    const contactItems = prepareCustomerContactItems(fromEdges(customer?.user?.entity?.contacts));

    return (
        <Panel>
            <Panel.Header icon={'address book outline'}>Customer Contact Details</Panel.Header>
            <Panel.Body>
                <>
                    <Panel.Item label={'Customer'}>
                        <CustomerLink id={customer.id}>
                            <code>{customer.crn}</code> - <Display.Entity value={customer} />
                        </CustomerLink>
                        {customer.contactName && <> ({customer.contactName})</>}
                    </Panel.Item>
                    <Panel.Divider />
                    {customer.primaryAddress && (
                        <Panel.Item label={'Primary Address'}>
                            <Display.Address value={customer.primaryAddress} />
                        </Panel.Item>
                    )}
                    {customer.billAddress && (
                        <Panel.Item label={'Billing Address'}>
                            <Display.Address value={customer.primaryAddress} />
                        </Panel.Item>
                    )}

                    <Panel.Divider />
                    {contactItems && (
                        <>
                            <ContactItems contactItems={contactItems} />
                        </>
                    )}
                </>
            </Panel.Body>
        </Panel>
    );
};
