import * as React from 'react';
import { Segment } from '../Segment';
import { clsxm } from '../utils';

export interface PanelFooterProps extends React.ComponentPropsWithoutRef<typeof Segment> {}

const PanelFooter: React.FC<PanelFooterProps> = ({ className, ...props }) => {
    return (
        <Segment
            className={clsxm('ez-panel-footer py-2 flex flex-row items-center justify-between', className)}
            {...props}
        />
    );
};

export default PanelFooter;
