import * as React from 'react';
import { useState } from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    Icon,
    LinkButton,
    MenuBar,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { fromEdges, NodeType, SortDirection, useQueryStaffTeamsConnection } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { queryQueryStaffTeamsConnection } from './use-query-staff-team';
import { StaffIcon } from '../../../components/AppIcons';
import { useViewer } from '@poolware/app-shell';

const TeamList: React.FC<{ team: NodeType.StaffTeam }> = ({ team }) => {
    const [expanded, setExpanded] = useState(false);
    let staff = fromEdges(team?.staff);

    const visibleLimitCount = 4;
    const collapseThreshold = 6;
    const totalCount = staff.length;
    const hiddenCount = totalCount - visibleLimitCount;
    const isOverLimit = staff.length > collapseThreshold;
    if (!expanded && isOverLimit) {
        staff = staff.slice(0, visibleLimitCount);
    }

    return (
        <div className={'flex flex-col gap-2'}>
            {staff.map((s) => {
                return (
                    <div key={s.id}>
                        <StaffIcon active={true} />
                        <Display.Entity value={s} key={s.id} /> <i>({s.role?.name})</i>
                    </div>
                );
            })}
            {isOverLimit && (
                <LinkButton onClick={() => setExpanded(!expanded)}>
                    <Icon name={expanded ? 'caret up' : 'caret down'} />
                    {expanded ? 'Hide' : `+ ${hiddenCount} members`}
                </LinkButton>
            )}
        </div>
    );
};

export const ListPageStaffTeams: React.FC = () => {
    const { viewer } = useViewer();
    const { AppNavigator } = useAppNavigator();
    const { connectionData, connectionState } = useQueryStaffTeamsConnection({
        query: queryQueryStaffTeamsConnection,
        fetchPolicy: 'cache-and-network',
        defaultSortKey: 'title',
        defaultSortDirection: SortDirection.ASC,
    });

    const canCreate = viewer.Permissions?.StaffTeam?.create;

    const onNew = () => {
        AppNavigator.navigateRelative('/teams/new', { modal: true, setOrigin: true });
    };

    const onView = (team) => {
        AppNavigator.navigateRelative(`/teams/${team.id}`);
    };

    const tableDef: ConnectionTableDef<NodeType.StaffTeam> = [
        {
            header: 'Team',
            sortKey: 'title',
            width: 2,
            cell: (r) => {
                return (
                    <div className={'rounded px-2 bg-gray-100 inline'}>
                        <LinkButton onClick={() => onView(r)}>
                            <Icon name={'users'} />
                            {r.title}
                        </LinkButton>
                    </div>
                );
            },
        },
        {
            header: 'Team Members',
            cell: (team) => {
                return <TeamList team={team} />;
            },
        },
    ];

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem>
                        <Icon name={'users'} />
                        Teams
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item
                        onClick={onNew}
                        icon={'plus'}
                        color={'green'}
                        title={'New Team'}
                        disabled={!canCreate}
                    />
                </MenuBar.Section>
            </StickyMenuBar>

            <DefaultConnectionTable
                tableProps={{ color: 'grey', striped: true }}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </VStack>
    );
};
