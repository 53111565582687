import * as React from 'react';
import { Alert, Panel, SectionHeader } from '@ez/components';
import { useWaterlink } from '../api-react';

export const WaterTestUnavailablePanel: React.FC = () => {
    const waterlink = useWaterlink();
    const canDoWaterTest = waterlink.device.online;
    if (canDoWaterTest) {
        return null;
    }

    const isServerOnline = waterlink.server.online;
    const isDeviceOnline = waterlink.device.online;

    return (
        <Panel>
            <Panel.Header>Water Test</Panel.Header>
            <Panel.Body>
                <Alert type={'warning'}>
                    <SectionHeader size={'small'}>Cannot perform water tests.</SectionHeader>
                    <Panel.Body>
                        <Panel.Item label={'Server'}>{isServerOnline ? 'Online' : 'Offline'}</Panel.Item>
                        <Panel.Item label={'Server URL'}>{waterlink.server.serverBaseUrl}</Panel.Item>
                    </Panel.Body>
                    <Panel.Body>
                        <Panel.Item label={'Device'}>{isDeviceOnline ? 'Online' : 'Offline'}</Panel.Item>
                    </Panel.Body>
                </Alert>
            </Panel.Body>
        </Panel>
    );
};
