import * as React from 'react';
import styled from 'styled-components';
import Parser from 'html-react-parser';
import { useState } from 'react';
import { useEffect } from 'react';
import { LinkButton } from '../buttons/LinkButton';
import sanitizeHtml from 'sanitize-html';

const maxHeightMix = (props: { maxHeight?: number }) => {
    if (props.maxHeight) {
        return `max-height: ${props.maxHeight}px; overflow-y: auto`;
    }
    return undefined;
};

const StyledPre = styled.pre<{ maxHeight?: number }>`
    margin: 2px 0;
    padding: 0;
    //word-break: break-all;
    white-space: pre-wrap;
    line-height: 1.4em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    ${maxHeightMix}
`;

export const DisplayPre = ({ value, ...rest }: any) => {
    return <StyledPre {...rest}>{value}</StyledPre>;
};

const StyledDiv = styled.div<{ maxHeight?: number }>`
    margin: 2px 0;
    padding: 0;
    //word-break: break-all;
    ${maxHeightMix}
`;

export interface DisplayTextProps extends React.HTMLAttributes<HTMLDivElement> {
    value: string;
    limit?: number;
    formatted?: boolean;
    maxHeightPx?: number;
}

export const DisplayText: React.FC<DisplayTextProps> = ({
    value,
    limit,
    formatted,
    maxHeightPx,
    className,
    ...rest
}) => {
    try {
        let description = value || '';
        limit = limit || 10000;
        const appendDots = description.length > limit;
        let desc: any = description.substring(0, Math.min(limit, description.length));

        if (formatted) {
            desc = sanitizeHtml(desc);
            desc = Parser(desc);
        } else {
            desc = <DisplayPre clasName={className} value={desc} />;
        }

        return (
            <StyledDiv className={className} {...rest} maxHeight={maxHeightPx}>
                {desc}
                {appendDots && '...'}
            </StyledDiv>
        );
    } catch (e) {
        console.error(e);
        return <StyledDiv {...rest}>Failed to render text.</StyledDiv>;
    }
};

export interface FormattedTextProps extends DisplayTextProps {
    usePre?: boolean;
}

export const DisplayFormattedText: React.FC<FormattedTextProps> = ({ usePre, ...rest }) => {
    return <DisplayText formatted={true} {...rest} />;
};

const DisplayTextContainer = styled.div<{ maxHeight?: string | number }>`
    max-height: ${({ maxHeight }) => maxHeight || 'auto'};
    overflow: hidden;
`;

export interface DisplayTextShortenedProps extends DisplayTextProps {
    showLines?: number;
    showChars?: number;
    expandOnly?: boolean;
}

export const DisplayTextShortened: React.FC<DisplayTextShortenedProps> = ({
    showChars = 700,
    value = '',
    showLines = 5,
    expandOnly = false,
    maxHeightPx = 1000,
    ...rest
}) => {
    const trimmedValue = value?.trim();
    const numOfChars = trimmedValue?.length;
    const numOfLines = trimmedValue?.split(/\r\n|\r|\n/).length;
    const showMore = numOfChars > showChars || numOfLines > showLines;
    const [showingFull, setShowingFull] = useState(false);

    useEffect(() => {
        setShowingFull(false);
    }, [value, showLines, showChars]);

    if (!showMore) {
        return <DisplayText value={trimmedValue} {...rest} />;
    } else {
        const buttonText = showingFull ? 'Show less' : 'Show more...';
        const maxHeight = `${showLines * 1.4}em`;
        return (
            <div>
                <DisplayTextContainer maxHeight={showingFull ? 'auto' : maxHeight}>
                    <DisplayText value={trimmedValue} {...rest} maxHeightPx={showingFull ? maxHeightPx : undefined} />
                </DisplayTextContainer>
                {expandOnly && showingFull ? null : (
                    <div style={{ textAlign: 'center', paddingRight: '0.5em' }}>
                        <LinkButton
                            style={{ textDecoration: 'underline', color: '#2385d0' }}
                            onClick={() => setShowingFull(!showingFull)}
                            content={buttonText}
                        />
                    </div>
                )}
            </div>
        );
    }
};
