import * as React from 'react';
import loadable from '@loadable/component';
import { PageSkeletonLoader } from '@ez/components';

interface ModuleLoadableInput {
    loader: any;
    loading?: any;
    delay?: number;
    timeout?: number;
}

export const ModuleLoadable = (opts: ModuleLoadableInput) => {
    return loadable(opts.loader, { fallback: opts.loading || <PageSkeletonLoader /> });
};

// export const ModuleLoadable = (opts: ModuleLoadableInput) => {
//     return Loadable({
//         loading: LoadPlaceholder,
//         delay: 200, // 0.2 seconds,
//         timeout: 10000, // 10 seconds
//         ...opts,
//     });
// };
