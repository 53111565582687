import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '../utils';

export interface SwitchProps extends React.ComponentProps<typeof SwitchPrimitives.Root> {}

const Switch = React.forwardRef<
    React.ElementRef<typeof SwitchPrimitives.Root>,
    React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={cn(
            'peer inline-flex h-6 w-12 shrink-0 cursor-pointer items-center rounded-full border border-primary border-solid data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
            'focusable',
            className
        )}
        {...props}
        ref={ref}
    >
        <SwitchPrimitives.Thumb
            className={cn(
                'pointer-events-none block size-5 rounded-full bg-secondary shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-6 data-[state=checked]:bg-white data-[state=unchecked]:translate-x-0 data-[state=unchecked]:bg-grey'
            )}
        />
    </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
