import { createThemeByHue } from '@ez/components';
import { ThemeInterface } from '../app-theme';

let _t: ThemeInterface = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    _t = createThemeByHue({ publicPrefix, huePrim: 208, hueSec: 24 });
    _t.themeName = 'asiapools';
    _t.navbar.separatorColor = 'hsl(24, 98%, 63%)';
    _t.logoPath = `${publicPrefix}/logo.png`;
    return _t;
};
