import * as React from 'react';
import { Alert, AlertHeader } from '../alert';
import { Icon } from '../Icon/Icon';

const NotFoundMessage = () => {
    return (
        <Alert type={'warning'}>
            <AlertHeader>
                Not Found <Icon name={'frown'} />
            </AlertHeader>
        </Alert>
    );
};

export default NotFoundMessage;
