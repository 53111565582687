import * as React from 'react';
import * as _ from 'lodash';
import { ActionBar, SemanticICONS } from '@ez/components';
import { getIconNameForAppointmentStatus, getLabelForAppointmentStatus } from '../../../utils';
import { NodeType } from '@poolware/api';

type StatusFilterItem = {
    token: string;
    displayString: string;
    icon: SemanticICONS;
};

const mapStatusToStatusFilterItem = (status: NodeType.AppointmentStatusEnum) => {
    return {
        token: status,
        displayString: getLabelForAppointmentStatus(status),
        icon: getIconNameForAppointmentStatus(status),
    };
};

const defaultStatuses: StatusFilterItem[] = [
    mapStatusToStatusFilterItem(NodeType.AppointmentStatusEnum.NotStarted),
    mapStatusToStatusFilterItem(NodeType.AppointmentStatusEnum.InProgress),
    mapStatusToStatusFilterItem(NodeType.AppointmentStatusEnum.Finished),
    mapStatusToStatusFilterItem(NodeType.AppointmentStatusEnum.Cancelled),
    mapStatusToStatusFilterItem(NodeType.AppointmentStatusEnum.ActionRequired),
];

export interface AppointmentStatusFilterProps {
    statuses?: StatusFilterItem[];
    includeStatuses: any[];
    setAppointmentStatusFilter: (val: { includeStatuses: StatusFilterItem[] }) => any;
}

export const AppointmentStatusFilter: React.FC<AppointmentStatusFilterProps> = (props) => {
    const { statuses = defaultStatuses, includeStatuses = [], setAppointmentStatusFilter } = props;

    const onFilterChange = (status: StatusFilterItem, checked: boolean) => {
        // Check whether this `status` is in the inclusion list
        const isInFilterArray = includeStatuses.findIndex((token) => token === status.token);

        if (checked) {
            // if status is checked, then add it to exclusion list.
            if (isInFilterArray < 0) {
                // it is not in the list, adding it.
                const newFilters = [...includeStatuses, status.token];
                setAppointmentStatusFilter({ includeStatuses: newFilters });
            }
        } else {
            // if status is unchecked, then remove it from the inclusion list.
            if (isInFilterArray >= 0) {
                // it is already in the list, removing it
                let a = [...includeStatuses];
                a.splice(isInFilterArray, 1);
                setAppointmentStatusFilter({ includeStatuses: a });
            }
        }
    };

    const onShowAll = (checked: boolean) => {
        if (checked) {
            setAppointmentStatusFilter({ includeStatuses: [] });
        }
    };

    const hasFilter = includeStatuses?.length > 0;
    return (
        <ActionBar active={hasFilter}>
            <ActionBar.Header content={'Filter by Status'} icon={'filter'} />
            <ActionBar.Body>
                <ActionBar.ItemCheckbox
                    dividing={true}
                    checked={includeStatuses.length === 0}
                    onChange={(checked) => onShowAll(checked)}
                    highlightChecked={false}
                    content={'All'}
                />
                {statuses.map((status) => {
                    const isChecked = _.findIndex(includeStatuses, (s) => s === status.token) >= 0;
                    return (
                        <ActionBar.ItemCheckbox
                            key={status.token}
                            checked={isChecked}
                            onChange={(checked) => onFilterChange(status, checked)}
                            icon={status.icon}
                            content={status.displayString}
                        />
                    );
                })}
            </ActionBar.Body>
        </ActionBar>
    );
};
