import * as React from 'react';
import { DefaultConnectionTable, Display } from '@ez/components';
import { QueryPoolTransfersConnection } from './QueryPoolTransfersConnection';
import { CustomerLink, PoolLink, StaffLink } from '../../../routes/Links';

const Page: React.FC = () => {
    return (
        <QueryPoolTransfersConnection pageSize={20}>
            {({ connectionState, connectionData }) => {
                return (
                    <DefaultConnectionTable
                        connectionData={connectionData}
                        connectionState={connectionState}
                        tableDef={[
                            {
                                header: 'Date',
                                cell: (h) => <Display.Date value={h.createdAt} format={'llll'} />,
                            },
                            {
                                header: 'Event Type',
                                cell: () => {
                                    return <div>Pool ownership change</div>;
                                },
                            },
                            {
                                header: 'Event Details',
                                cell: (h) => {
                                    const pool = h?.pool;
                                    if (!pool) return '--';
                                    const poolCustomerId = h?.pool?.entity?.user?.customer?.id;
                                    const fromCustomerId = h?.transferredFrom?.user?.customer?.id;
                                    const toCustomerId = h?.transferredTo?.user?.customer?.id;
                                    return (
                                        <div>
                                            <PoolLink customerId={poolCustomerId} poolId={pool.id}>
                                                {pool.bottleNumber} - {pool.name}
                                            </PoolLink>{' '}
                                            moved from{' '}
                                            <CustomerLink customerId={fromCustomerId}>
                                                <Display.Entity value={h.transferredFrom} />
                                            </CustomerLink>{' '}
                                            to{' '}
                                            <CustomerLink customerId={toCustomerId}>
                                                <Display.Entity value={h.transferredTo} />
                                            </CustomerLink>
                                        </div>
                                    );
                                },
                            },
                            {
                                header: 'Submitted by',
                                cell: (h) => {
                                    const transferredBy = h?.transferredBy;
                                    if (!transferredBy) {
                                        return '--';
                                    }

                                    return (
                                        <StaffLink staffId={transferredBy.id}>
                                            <Display.Entity value={transferredBy} />
                                        </StaffLink>
                                    );
                                },
                            },
                        ]}
                    />
                );
            }}
        </QueryPoolTransfersConnection>
    );
};

export default Page;
