import * as React from 'react';
import { useCallback, useState } from 'react';
import SearchResultsTable from './SearchResultTable';
import { NodeType } from '@poolware/api';
import { Button, Segment, VStack } from '@ez/components';

export type NewProductCreateContextType = {
    declaration?: NodeType.ProductTraitDeclaration;
    name?: string;
    brand?: NodeType.Brand;
};

export interface ProductPickerExternalProps {
    onSelect: (item: NodeType.Product) => any;
    onCancel: () => any;
    onNewProductCreate?: (context: NewProductCreateContextType) => any;
}

const ProductPicker: React.FC<ProductPickerExternalProps> = ({ onSelect, onCancel, onNewProductCreate }) => {
    const [selectedItems, setSelectedItems] = useState<NodeType.Product[]>([]);

    const handleSelectChange = useCallback((items) => {
        setSelectedItems(items);
    }, []);

    const handleSubmit = () => {
        onSelect(selectedItems[0]);
    };

    const hasSelected = selectedItems && selectedItems.length > 0;

    return (
        <VStack>
            <SearchResultsTable onNewProductCreate={onNewProductCreate} onSelectedItemsChange={handleSelectChange} />
            <Segment>
                <Button onClick={onCancel}>Cancel</Button>

                <Button disabled={!hasSelected} variant={'primary'} className={'float-right'} onClick={handleSubmit}>
                    Select
                </Button>
            </Segment>
        </VStack>
    );
};

export default ProductPicker;
