import * as React from 'react';
import { useMemo } from 'react';
import {
    Alert,
    Icon,
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    Panel,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { AppConfigInterface, useAppConfig, useViewer } from '@poolware/app-shell';

const useExportLinks = (config: AppConfigInterface, franchiseId: string) => {
    return useMemo(
        () => [
            {
                type: 'csv',
                title: 'Export customers and pools (CSV format)',
                url: `${config.api.restAPIBaseUrl}/export-csv/${franchiseId}`,
            },
            {
                type: 'csv-aggregate',
                title: 'Export customers and water test summary (CSV format)',
                url: `${config.api.restAPIBaseUrl}/export-aggregate-csv/${franchiseId}`,
            },
            {
                type: 'ls-retail-json',
                title: 'Export customers (Business Central JSON format)',
                url: `${config.api.restAPIBaseUrl}/export-json/${franchiseId}`,
            },
        ],
        [config, franchiseId]
    );
};

export const DataExportPage = () => {
    const { viewer, modulesAccess, isPretending } = useViewer();
    const config = useAppConfig();
    const exportLinks = useExportLinks(config, viewer.franchise?.id);

    // HACK: use isPretending to allow export in pretending mode
    const hasAccess = modulesAccess.Export?.enabled || isPretending;
    const isAccessOverwritten = !modulesAccess.Export?.enabled && isPretending;

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'share square'}>Data Export</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>
            <PageLayout.BodySection width={'screen-md'}>
                <Panel>
                    <Panel.Header>Export Data - {viewer?.franchise?.name}</Panel.Header>
                    <Panel.Body>
                        {hasAccess ? (
                            <div className={'flex flex-col gap-2'}>
                                {exportLinks.map((link, index) => {
                                    return (
                                        <div className={'flex flex-row items-center'} key={index}>
                                            <div className={'flex-grow'}>{link.title}</div>
                                            <a
                                                className={'ez-btn-base text-sm p-2 border-tertiary'}
                                                href={link.url}
                                                target={'_blank'}
                                            >
                                                <Icon name={'download'} />
                                                Download
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <Alert type="warning">Access denied</Alert>
                        )}
                        {isAccessOverwritten && (
                            <div className={'mt-4 p-2 rounded bg-secondary bg-opacity-50 text-sm'}>
                                Note: This user account does not have access to export data. Access to export is allowed
                                in pretend mode.
                            </div>
                        )}
                    </Panel.Body>
                </Panel>
            </PageLayout.BodySection>
        </PageLayout>
    );
};
