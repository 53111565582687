import Store from './store';
import { SemanticICONS } from '../Icon';
import * as React from 'react';
import { DebugJSON } from '../dev-tools';

export type SemanticToastType = 'info' | 'success' | 'error' | 'warning';

let id = 0;
export const store = new Store();

export interface Toast {
    type?: SemanticToastType;
    title: string;
    description?: string | React.ReactNode;
    icon?: boolean | SemanticICONS;
    time?: number;
    animation?: string;
    onClick?: any;
}

export const toast = (item: Toast, cb?) => {
    id += 1;
    store.add(Object.assign({ id, onClose: cb }, item));
};

export const toastSuccess = (item: Toast, cb?) => toast({ type: 'success', ...item }, cb);
export const toastWarning = (item: Toast, cb?) => toast({ type: 'warning', ...item }, cb);
export const toastInfo = (item: Toast, cb?) => toast({ type: 'info', ...item }, cb);

const NetworkErrorPresenter: React.FC<{ error: Error }> = ({ error }) => {
    // @ts-ignore
    const networkError = error?.networkError?.result;
    return (
        <div>
            <div>{error.message}</div>
            <DebugJSON data={networkError} />
        </div>
    );
};

export const toastNetworkError = (error: Error, cb?) => {
    toast({ type: 'error', title: 'Network Error', time: 0, description: <NetworkErrorPresenter error={error} /> }, cb);
};

export const toastError = (item: Toast | Error, cb?) => {
    if (item instanceof Error) {
        // @ts-ignore
        if (item?.networkError) {
            return toastNetworkError(item, cb);
        }
        toast({ type: 'error', title: 'Error', description: item.message }, cb);
    } else {
        toast({ type: 'error', ...item }, cb);
    }
};
