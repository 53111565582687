import * as React from 'react';
import { useState } from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { Query } from 'react-apollo';
import { CustomerPicker, PoolPicker } from './Pickers';
import { searchViaCustomerQuery, searchViaPoolQuery } from './queries';
import { Form, Icon, Panel } from '@ez/components';
import { isTouch, useDebouncedValue } from '@ez/tools';

export const createCustomerSearchQueryVariable = (input: SearchValuesType): NodeType.CustomerViewerCustomersSearch => {
    const { crn, customerName, contact, address } = input;
    const req = {};

    if (address) {
        _.set(req, 'user.entity.addresses.anyString', _.words(address));
    }

    if (crn) {
        _.set(req, 'crn.like', crn);
    }

    const customerNameWords = _.words(customerName);
    if (customerNameWords.length > 0) {
        _.set(req, 'user.anyString', customerNameWords);
    }

    if (contact) {
        let contactSanitized = contact;
        try {
            contactSanitized = contact.replace(/ /g, ''); // remove spaces from string.
        } catch (e) {
            console.error(e);
        }
        _.set(req, 'user.entity.contacts.data.like', contactSanitized);
    }

    return req;
};

type SearchValuesType = any;

export const createPoolSearchQueryVariables = (input: SearchValuesType): NodeType.PoolViewerPoolsSearch => {
    const { bottleNumber, address, customerName, crn } = input;
    let req: NodeType.PoolViewerPoolsSearch = {};

    const customerNameWords = _.words(customerName);
    if (customerNameWords.length > 0) {
        _.set(req, 'entity.user.anyString', customerNameWords);
    }

    if (bottleNumber) {
        req.bottleNumber = {
            like: _.trim(bottleNumber),
        };
    }

    if (address) {
        req.site = {
            address: {
                anyString: _.words(address),
            },
        };
    }

    if (crn) {
        _.set(req, 'entity.user.customer.cnt', _.trim(crn));
    }

    return req;
};

const computePoolSearchVariables = (searchInput: SearchValuesType, currentPage: number) => {
    return {
        pageSize: 8,
        page: currentPage,
        sort: {
            createdAt: false,
        },
        search: createPoolSearchQueryVariables(searchInput),
    };
};

const computeCustomerSearchVariables = (searchInput: SearchValuesType, currentPage: number) => {
    return {
        pageSize: 8,
        page: currentPage,
        sort: {
            chainUpdate: false,
        },
        search: createCustomerSearchQueryVariable(searchInput),
    };
};

interface FormInputProps {
    searchKey;
    searchValues;
    setSearch;
}

const SearchInput: React.FC<FormInputProps> = ({ searchKey, searchValues, setSearch, ...props }) => {
    const icon = searchValues[searchKey] ? (
        <Icon
            name="cancel"
            // link
            onClick={() => {
                setSearch(searchKey)('');
            }}
        />
    ) : (
        <Icon name="search" />
    );

    const handleSetSearch = (key: keyof SearchValuesType) => (value: string) => {
        if (setSearch && setSearch(key)) {
            setSearch(key)(value);
        }
    };

    return (
        <Form.Input
            {...props}
            value={searchValues[searchKey]}
            icon={icon}
            onChange={(e, { value }) => handleSetSearch(searchKey)(value)}
        />
    );
};

const NameInput = (props) => <SearchInput searchKey={'customerName'} {...props} />;
const CRNInput = (props) => <SearchInput searchKey={'crn'} {...props} />;
const PoolBottleNumberInput = (props) => <SearchInput searchKey={'bottleNumber'} {...props} />;
const PoolAddressInput = (props) => <SearchInput searchKey={'address'} {...props} />;

const autoFocus = !isTouch;

export interface SearchPageProps {
    selectedId?: string;
    onCancel?: () => any;
    onSubmit?: (data: { customer?: NodeType.Customer; pool?: NodeType.Pool }) => any;
    onNewCustomer?: () => any;
}

const SearchPage: React.FC<SearchPageProps> = ({ onSubmit, onNewCustomer, ...rest }) => {
    const [crn, setSearchCrn] = useState('');
    const [bottleNumber, setSearchBottleNumber] = useState('');
    const [contact, setSearchContact] = useState('');
    const [address, setSearchAddress] = useState('');
    const [customerName, setSearchName] = useState('');
    const [currentPage, setCurrentPage] = useState(0);

    const setSearch = (key: keyof SearchValuesType) => {
        setCurrentPage(0);
        switch (key) {
            case 'crn':
                return setSearchCrn;
            case 'contact':
                return setSearchContact;
            case 'customerName':
                return setSearchName;
            case 'bottleNumber':
                return setSearchBottleNumber;
            case 'address':
                return setSearchAddress;
            default: {
                console.error('Unsupported key', key);
            }
        }
    };

    const poolSearchVariables = useDebouncedValue(
        computePoolSearchVariables({ bottleNumber, address, customerName }, currentPage),
        600
    );

    const customerSearchVariables = useDebouncedValue(
        computeCustomerSearchVariables({ crn, customerName, contact }, currentPage),
        600
    );

    const handlePoolSubmit = (pool: NodeType.Pool) => {
        const customer = pool?.entity?.user?.customer;
        onSubmit?.({ customer, pool });
    };

    const handleCustomerSubmit = (customer: NodeType.Customer) => {
        onSubmit?.({ customer });
    };

    const searchValues = {
        address,
        bottleNumber,
        customerName,
        crn,
        contact,
    };

    // const searchViaCustomer = (customerName || crn) && !bottleNumber && !address;

    const searchViaPool = (bottleNumber || address) && !(customerName || crn);

    return (
        <Panel>
            <Panel.Header
                content={'Search'}
                button={onNewCustomer && { content: 'New Customer', icon: 'plus', onClick: onNewCustomer }}
            />
            <Panel.Body>
                <Form>
                    <Form.Group widths={'equal'}>
                        <NameInput
                            autoFocus={autoFocus}
                            searchValues={searchValues}
                            setSearch={setSearch}
                            label={'Customer Name'}
                        />
                        <CRNInput
                            searchValues={searchValues}
                            setSearch={setSearch}
                            label="Customer CRN"
                            placeholder={'001-000XXX'}
                        />
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        <PoolAddressInput searchValues={searchValues} setSearch={setSearch} label={'Pool Address'} />
                        <PoolBottleNumberInput
                            searchValues={searchValues}
                            setSearch={setSearch}
                            label="Pool Number"
                            placeholder={'P001-000XXX'}
                        />
                    </Form.Group>
                </Form>

                {!searchViaPool ? (
                    <Query query={searchViaCustomerQuery} variables={customerSearchVariables}>
                        {({ loading, data }) => {
                            const paginatedConnection = data?.viewer?.customers || {};
                            const { pageMeta = {} } = paginatedConnection;
                            const pageCount = pageMeta.pageCount || 1;
                            const canPaginate = pageCount > 1;

                            return (
                                <CustomerPicker
                                    items={fromEdges(paginatedConnection)}
                                    setPage={setCurrentPage}
                                    canPaginate={canPaginate}
                                    currentPage={currentPage}
                                    isLoadingPage={loading}
                                    pageCount={pageCount}
                                    onSubmit={handleCustomerSubmit}
                                    {...rest}
                                />
                            );
                        }}
                    </Query>
                ) : (
                    <Query query={searchViaPoolQuery} variables={poolSearchVariables}>
                        {({ loading, data }) => {
                            const paginatedConnection = data?.viewer?.pools || {};
                            const { pageMeta = {} } = paginatedConnection;
                            const pageCount = pageMeta.pageCount || 1;
                            const canPaginate = pageCount > 1;

                            return (
                                <PoolPicker
                                    items={fromEdges(paginatedConnection)}
                                    setPage={setCurrentPage}
                                    canPaginate={canPaginate}
                                    currentPage={currentPage}
                                    isLoadingPage={loading}
                                    pageCount={pageCount}
                                    onSubmit={handlePoolSubmit}
                                    {...rest}
                                />
                            );
                        }}
                    </Query>
                )}
            </Panel.Body>
        </Panel>
    );
};

export default SearchPage;
