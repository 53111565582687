import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from '@ez/tools';

import { withApolloLoading } from '@ez/components';
import { customerFragment, IMutateSite, NodeType, withSiteMutator } from '@poolware/api';

import Page from './Page';
import { IWithPageProps, withPageProps } from '../../../../components/withPageProps';

const CustomerQuery = gql`
    query CustomerQuery($customerId: ID!) {
        viewer {
            customer: resolveNode(id: $customerId) {
                id
                ...CustomerFragment
            }
        }
    }
    ${customerFragment}
`;

export default compose(
    withPageProps(),
    graphql(CustomerQuery, {
        options: (props: any) => ({
            variables: { customerId: props.params.customerId },
        }),
        props: (props: any) => ({
            ...props,
            customer: props?.data?.viewer?.customer,
        }),
    }),
    withApolloLoading(),
    withSiteMutator(['CustomerSitesList'])
)(Page);

export interface PageControl extends IWithPageProps, IMutateSite {
    customer: NodeType.Customer;
}
