import * as React from 'react';
import { NodeType } from '@poolware/api';
import { JSONView, Segment } from '@ez/components';

export const DeviceStateGeneric: React.FC<{ deviceState: NodeType.EZCDeviceState }> = ({ deviceState }) => {
    return (
        <>
            <Segment>TODO: Implement Generic Device state renderer</Segment>
            <Segment>
                <JSONView data={deviceState} />
            </Segment>
        </>
    );
};
