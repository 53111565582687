import { compose, withProps } from '@ez/tools';
import _trim from 'lodash/trim';

import { IMutateContact, NodeType, withContactMutator } from './api-types';

export interface ContactInput {
    contactType: NodeType.NodeOrId<NodeType.ContactType>;
    data: string;
    label: string;
    isPrimary?: boolean;
}

export type EntityLikeType = NodeType.Entity | NodeType.FranchiseShop;

export interface ContactMutator {
    createContact: (entity: NodeType.NodeOrId<EntityLikeType>, input: ContactInput) => Promise<any>;
    updateContact: (contact: NodeType.NodeOrId<NodeType.Contact>, input: ContactInput) => Promise<any>;
    deleteContact: (contact: NodeType.NodeOrId<NodeType.Contact>) => Promise<any>;
    setAsPrimary: (contact: NodeType.NodeOrId<NodeType.Contact>) => Promise<any>;
}

export interface IContactMutatorsInjected extends IMutateContact {}

export interface IContactsMutatorsAdded {
    ContactMutator: ContactMutator;
}

export interface IContactMutatorsProps extends IContactMutatorsInjected, IContactsMutatorsAdded {}

const createMutators = ({ mutateContact }: IContactMutatorsInjected): IContactsMutatorsAdded => {
    const createContact = async (entity: NodeType.NodeOrId<NodeType.Entity>, input: ContactInput) => {
        return mutateContact.create({
            entity: NodeType.extractId(entity),
            data: _trim(input.data),
            label: _trim(input.label),
            isPrimary: input.isPrimary !== undefined ? input.isPrimary : undefined,
            type: NodeType.extractId(input.contactType),
        });
    };

    const updateContact = async (contact: NodeType.NodeOrId<NodeType.Contact>, input: Partial<ContactInput>) => {
        return mutateContact.update({
            id: NodeType.extractId(contact),
            data: _trim(input.data),
            label: _trim(input.label),
            isPrimary: input.isPrimary !== undefined ? input.isPrimary : undefined,
        });
    };

    const setAsPrimary = async (contact: NodeType.NodeOrId<NodeType.Contact>) => {
        return mutateContact.makePrimary({
            id: NodeType.extractId(contact),
        });
    };

    const deleteContact = async (contact: NodeType.NodeOrId<NodeType.Contact>) => {
        return mutateContact.delete({ id: NodeType.extractId(contact) });
    };

    return {
        ContactMutator: {
            createContact,
            updateContact,
            deleteContact,
            setAsPrimary,
        },
    };
};

export const withContactMutators = (refetchQueries) =>
    compose(withContactMutator(refetchQueries), withProps(createMutators));
