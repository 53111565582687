import * as React from 'react';
import * as Pages from './index';
import { ModuleRedirect, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { MenuBarSection, StickyMenuBar, VStack } from '@ez/components';
import { ModulesAccess, NotFoundPage, useViewer } from '@poolware/app-shell';
import { HeaterRecommendationTable } from './HeatCalcRecommendationsFeed/HeaterRecommendationsTable';
import { MenuItemNavLink } from '../../components/MenuItemNavLink';

const HeatCalcRecommendations = () => {
    return <HeaterRecommendationTable />;
};

const EmptyPage: React.FC = () => {
    return <div>--</div>;
};

const NavMenuBar: React.FC = () => {
    const { modulesAccess } = useViewer();
    return (
        <StickyMenuBar>
            <MenuBarSection>
                {modulesAccess.WaterTest?.enabled && (
                    <MenuItemNavLink to={'/water-tests'} exact>
                        Water Tests
                    </MenuItemNavLink>
                )}
                {modulesAccess.Calculators?.saveRecommendations && (
                    <MenuItemNavLink to={'/heat-recommendations'} exact>
                        Recommendations
                    </MenuItemNavLink>
                )}
                {modulesAccess.Pools?.siteOwnershipChange && (
                    <MenuItemNavLink to={'/pool-transfers'}>Events</MenuItemNavLink>
                )}
            </MenuBarSection>
        </StickyMenuBar>
    );
};

const redirectPriority = [
    {
        path: '/water-tests',
        test: (modulesAccess: ModulesAccess) => modulesAccess.WaterTest?.enabled,
    },
    {
        path: '/heat-recommendations',
        test: (modulesAccess: ModulesAccess) => modulesAccess.Calculators?.saveRecommendations,
    },
    {
        path: '/pool-transfers',
        test: (modulesAccess: ModulesAccess) => modulesAccess.Pools?.enabled,
    },
];

const getDefaultRedirect = (modulesAccess: ModulesAccess): string => {
    let path = '/empty';
    for (const rd of redirectPriority) {
        if (rd.test(modulesAccess)) {
            path = rd.path;
            break;
        }
    }
    return path;
};

export const Router = () => {
    const { modulesAccess } = useViewer();
    const redirectTo = getDefaultRedirect(modulesAccess);
    return (
        <>
            <ModuleRoot>
                <VStack>
                    <NavMenuBar />
                    <ModuleSwitch notFound={NotFoundPage}>
                        <ModuleRedirect path={`/`} exact to={redirectTo} />
                        <ModuleRoute path={`/water-tests`} exact component={Pages.WaterTestReportsFeed} />
                        <ModuleRoute path={`/heat-recommendations`} component={HeatCalcRecommendations} />
                        <ModuleRoute path={`/pool-transfers`} component={Pages.CustomerUpdatesFeed} />
                        <ModuleRoute path={`/empty`} component={EmptyPage} />
                    </ModuleSwitch>
                </VStack>
            </ModuleRoot>
        </>
    );
};
