import * as React from 'react';
import _findIndex from 'lodash/findIndex';
import { fromEdges } from '@poolware/api';
import { Checkbox } from '@ez/components';
import { PageControl } from './Page.Control';
import { FormButtons } from './FormButtons';
import { LinkButton, Panel, Table } from '@ez/components';

interface ManualProblemPickerState {
    items: any[];
    pendingItems: any[];
}

interface ManualProblemPickerProps extends PageControl {
    onSubmit?: (items: any[]) => any;
    onCancel?: () => any;
    selectedItemIds: any[];
}

export default class ManualProblemPicker extends React.Component<ManualProblemPickerProps, ManualProblemPickerState> {
    constructor(props: ManualProblemPickerProps) {
        super(props);

        let allItems = fromEdges(props.items);
        let selectedItemIds = fromEdges(props.selectedItemIds);

        let items = allItems
            .map((item: any) => {
                let index = _findIndex(selectedItemIds, (selectedItemId) => selectedItemId === item.id);
                item.isSelected = index >= 0;
                return item;
            })
            .filter((item) => !item.isSelected);

        this.state = {
            items: items,
            pendingItems: [],
        };
    }

    registerCheckboxChange = (item) => (event, data) => {
        this.handleChange(item, data.checked);
    };

    registerLinkClick = (item) => (event, mouseEvent?) => {
        event.preventDefault();
        this.handleChange(item, true);
    };

    handleChange = (item, value) => {
        item.isSelected = value;
        this.forceUpdate();

        let selectedItems = this.state.items.filter((item) => item.isSelected);
        this.preselectItem(selectedItems);
    };

    preselectItem = (criteria) => {
        this.setState({ pendingItems: criteria });
    };

    onSubmit = () => {
        if (this.props.onSubmit) {
            let { pendingItems } = this.state;
            this.props.onSubmit(pendingItems);
        }
    };

    onCancel = () => {
        if (this.props.onCancel) this.props.onCancel();
    };

    renderTable() {
        let { items } = this.state;

        return (
            <Table celled color="teal" unstackable={true} compact="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1} textAlign="center" />
                        <Table.HeaderCell>Problem</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {items.map((item) => {
                        return (
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <Checkbox checked={item.isSelected} onChange={this.registerCheckboxChange(item)} />
                                </Table.Cell>

                                <Table.Cell>
                                    <LinkButton onClick={this.registerLinkClick(item)}>{item.name}</LinkButton>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
                {items.length === 0 && (
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan="1" />
                            <Table.HeaderCell colSpan="3">No preconfigured observations available</Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                )}
            </Table>
        );
    }

    render() {
        return (
            <Panel>
                <Panel.Header content="Observations" />
                <Panel.Body>
                    {this.renderTable()}
                    <FormButtons
                        onSubmit={this.onSubmit}
                        onCancel={this.props.onCancel}
                        submitButtonDisabled={this.state.pendingItems.length === 0}
                        submitButtonTitle="Add"
                    />
                </Panel.Body>
            </Panel>
        );
    }
}
