import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useWaterlink } from '../api-react';
import { Button, ButtonWithPopupProps, SectionHeader as Header, Tab, toastError } from '@ez/components';
import { DataTable, TableDef } from './DataTable';
import { WaterlinkAPI } from '../api-client';

const OptionButton: React.FC<ButtonWithPopupProps> = (props) => (
    <Button color={'blue'} size={'tiny'} basic={true} {...props} />
);

const OptionsTable: React.FC<{ data }> = ({ data }) => {
    const tableDef: TableDef<any> = [
        {
            header: 'Name',
            cell: (pd) => pd.Name,
        },
        {
            header: 'Value',
            cell: (pd) => pd.Value,
        },
        {
            header: 'Tags',
            cell: (pd) => (pd.Tags ? pd.Tags.join(', ') : '-'),
        },
    ];
    return <DataTable data={data} tableDef={tableDef} />;
};

const DeviceOptionViewer = ({ options }) => {
    if (!options) return <div>null</div>;

    return (
        <>
            <Header>SampleTypes</Header>
            <OptionsTable data={options.SampleTypes} />
            <Header>ReagentTypes</Header>
            <OptionsTable data={options.ReagentTypes} />

            {/*<pre>{JSON.stringify(options, null, 2)}</pre>*/}
        </>
    );
};

export const WaterlinkDevicesTestOptions = () => {
    const [waterlinkOptions, setWaterlinkOptions] = useState<WaterlinkAPI.OptionsReply>({});
    const waterlink = useWaterlink();

    const getWaterlinkOptions = useCallback(
        async (version?: string) => {
            try {
                const _options = await waterlink.client.getOptions(version);
                setWaterlinkOptions(_options);
            } catch (e) {
                toastError({ title: 'Error', description: e.message });
            }
        },
        [waterlink.client]
    );

    useEffect(() => {
        getWaterlinkOptions();
    }, [getWaterlinkOptions]);

    const deviceOptionsPanes = [
        {
            menuItem: 'All',
            render: () => (
                <Tab.Pane>
                    <Header>All</Header>
                    <DeviceOptionViewer options={waterlink.options.all} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Pool',
            render: () => (
                <Tab.Pane>
                    <Header>Pool</Header>
                    <DeviceOptionViewer options={waterlink.options.pool} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Spa',
            render: () => (
                <Tab.Pane>
                    <Header>Spa</Header>
                    <DeviceOptionViewer options={waterlink.options.spa} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Driniking water',
            render: () => (
                <Tab.Pane>
                    <Header>Drinking Water</Header>
                    <DeviceOptionViewer options={waterlink.options.drinkingwater} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Cooling water',
            render: () => (
                <Tab.Pane>
                    <Header>Cooling Water</Header>
                    <DeviceOptionViewer options={waterlink.options.coolingwater} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Fetcher',
            render: () => (
                <Tab.Pane>
                    <OptionButton content={'Get Options'} onClick={() => getWaterlinkOptions()} />
                    <OptionButton content={'Get Options - Pool'} onClick={() => getWaterlinkOptions('pool')} />
                    <OptionButton content={'Get Options - Spa'} onClick={() => getWaterlinkOptions('spa')} />
                    <OptionButton
                        content={'Get Options - coolingwater'}
                        onClick={() => getWaterlinkOptions('coolingwater')}
                    />
                    <OptionButton
                        content={'Get Options - drinkingwater'}
                        onClick={() => getWaterlinkOptions('drinkingwater')}
                    />
                    <DeviceOptionViewer options={waterlinkOptions} />
                </Tab.Pane>
            ),
        },
    ];

    return <Tab panes={deviceOptionsPanes} />;
};
