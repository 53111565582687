import * as React from 'react';
import { toastError } from '@ez/components';
import { fromEdges, NodeType, useMutationJobTodoTemplate } from '@poolware/api';
import * as _ from 'lodash';
import { JobTodoTemplateForm } from '../../../../JobTodoTemplate/JobTodoTemplateForm';

export interface JobTodoTemplateEditorProps {
    jobTodoTemplateAssoc: NodeType.JobTodoTemplateAssoc;
    onDone: () => any;
    refetchQuery: any;
}

export const JobTodoTemplateAssocEditForm: React.FC<JobTodoTemplateEditorProps> = ({
    jobTodoTemplateAssoc,
    onDone,
    refetchQuery,
}) => {
    const { update } = useMutationJobTodoTemplate({ refetchQueries: [refetchQuery] });
    const { jobTodoTemplate } = jobTodoTemplateAssoc;

    const initialValues = {
        title: jobTodoTemplate.title,
        description: jobTodoTemplate.description,
        items: fromEdges(jobTodoTemplate.items),
        timeEstimate: _.toNumber(jobTodoTemplate.timeEstimate),
        recommendedPrice: jobTodoTemplate.recommendedPrice,
    };

    const initialItemsIds = fromEdges(jobTodoTemplate.items).map((i) => i.id);

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            const newItemIds = values.items.map((i) => i.id);
            // Remove items with IDs that not present in the initial IDS array anymore
            const toDelete = initialItemsIds.filter((initialId) => !newItemIds.includes(initialId));

            const prepareItemPayload = (item) => {
                return {
                    title: item.title?.trim() || '',
                    description: item.description?.trim() || '',
                    orderIndex: item.orderIndex,
                };
            };

            const reIndexed = values.items
                .map((item, index) => ({ ...item, orderIndex: index }))
                .filter((i) => !!i.title); // remove items with empty titles

            const toUpdate = reIndexed
                .filter((item: any) => !item.isNew)
                .map((item) => ({
                    id: item.id,
                    ...prepareItemPayload(item),
                }));

            const toCreate = reIndexed
                .filter((item: any) => item.isNew)
                .map((item) => ({
                    ...prepareItemPayload(item),
                }));

            await update({
                id: jobTodoTemplate.id,
                title: values.title?.trim() || '',
                description: values.description?.trim() || '',
                timeEstimate: _.toNumber(values.timeEstimate),
                recommendedPrice: values.recommendedPrice ? _.toNumber(values.recommendedPrice) : undefined,
                items: {
                    delete: toDelete,
                    create: toCreate,
                    update: toUpdate,
                },
            });

            onDone();
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <JobTodoTemplateForm debug={false} initialValues={initialValues} onCancel={onDone} onSubmit={handleSubmit} />
    );
};
