import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryServiceJob } from '../../../queries/use-query-service-job';
import {
    Display,
    PageSkeletonLoader,
    PrintPageHeader,
    PrintPageNoteText,
    PrintPageSubHeader,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionConsumer,
    PrintSectionHeader,
    PrintSectionItem,
    PrintSectionProvider,
    stringFormatters,
} from '@ez/components';
import { NotFoundPage, Helmet } from '@poolware/app-shell';
import { WorkOrdersList } from './WorkOrdersList';
import * as _ from 'lodash';
import { ModuleIconNames } from '../../../constants';
import { Icon } from '@ez/components';
import { CustomerDetailsSection, LocationDetailsSection } from '../CustomerAndLocationSection';

export const ServiceJobPrintView: React.FC = () => {
    const { params } = useAppNavigator();
    const sjId = params.sjId;
    if (!sjId) {
        throw 'Expected jsId param';
    }

    const { node: serviceCase, loading, error } = useQueryServiceJob(sjId);
    const noPrintDefaults = {
        note: false,
        customer: false,
        location: false,
        workOrders: false,
        staff: false,
    };

    if (loading) {
        return <PageSkeletonLoader />;
    }

    if (error) {
        return (
            <div>
                <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
        );
    }

    if (!loading && !serviceCase) {
        return <NotFoundPage />;
    }

    const customer = serviceCase?.customer;

    return (
        <PrintSectionProvider moduleName={'print.toggles.service-call'} defaultState={noPrintDefaults}>
            <Helmet title={`Service Case - ${serviceCase.serviceJobNumber}`} />

            <PrintPageHeader>
                <Icon name={ModuleIconNames.ServiceCall} />
                {customer && (
                    <>
                        <b> Customer - {customer.crn}</b> <Icon name={'caret right'} />
                    </>
                )}
                <span>
                    Service Case - <small>{serviceCase.serviceJobNumber}</small>
                </span>
            </PrintPageHeader>
            <PrintPageSubHeader>{serviceCase.title}</PrintPageSubHeader>

            <CustomerDetailsSection customer={serviceCase?.customer} />

            <LocationDetailsSection address={serviceCase?.address} pool={serviceCase?.pool} />

            <PrintSectionConsumer name={'staff'}>
                <PrintSectionHeader>Assigned To</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn>
                        <PrintSectionItem label="Name">
                            {stringFormatters.formatEntityName(serviceCase?.staff)}
                        </PrintSectionItem>
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSectionConsumer>

            <PrintSectionConsumer>
                <PrintSectionHeader>Service Case</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn>
                        <PrintSectionItem label={'Service Case ID'} content={serviceCase.serviceJobNumber} />
                        <PrintSectionItem label={'Subject'} content={_.truncate(serviceCase.title, { length: 90 })} />
                        <PrintSectionItem label={'Opened'}>
                            <Display.Date value={serviceCase.createdAt} format={'lll'} />
                        </PrintSectionItem>
                        {serviceCase.dueDate && (
                            <PrintSectionItem label={'Due Date'}>
                                <Display.Date value={serviceCase.dueDate} format={'lll'} />
                            </PrintSectionItem>
                        )}
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSectionConsumer>

            <PrintSectionConsumer name={'note'}>
                <PrintSectionHeader>Description</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn paddingVal={2}>
                        <PrintPageNoteText
                            formatted={false}
                            limit={10000}
                            value={serviceCase.description}
                            style={{ minHeight: '4cm' }}
                        />
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSectionConsumer>

            <PrintSectionConsumer name={'workOrders'}>
                <PrintSectionHeader>Work Orders</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn>
                        <WorkOrdersList serviceJob={serviceCase} />
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSectionConsumer>
        </PrintSectionProvider>
    );
};
