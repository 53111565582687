import * as React from 'react';
import { Icon } from '@ez/components';

export const CalendarResourceHeader: React.FC<{
    label?: string | React.ReactNode;
    index: number;
    resource: any;
}> = ({ label, resource }) => {
    const icon = resource?.icon;
    return (
        <div className={'px-1'}>
            <div
                className={
                    'px-2 py-1 bg-popover text-popover-foreground rounded border border-tertiary border-solid text-sm font-bold flex flex-row items-center justify-between'
                }
            >
                <span className={'overflow-hidden whitespace-nowrap overflow-ellipsis'}>{label}</span>
                <span className={'flex-shrink-0'}>
                    <Icon circular={true} name={'spy'} />
                </span>
            </div>
        </div>
    );
};
