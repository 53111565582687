import { compose, withProps } from '@ez/tools';
import { IMutatePool, NodeType, withPoolMutator } from './api-types';
import invariant from 'invariant';
import _trim from 'lodash/trim';
import _toNumber from 'lodash/toNumber';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';

export interface PoolMutatorInjected extends IMutatePool {}

export interface PoolCreateInput {
    name: string;
    volume: number | string;
    site: NodeType.NodeOrId<NodeType.Site>;
    type: NodeType.NodeOrId<NodeType.PoolType>;
    sanitiser: NodeType.NodeOrId<NodeType.Sanitiser>;
    entity: NodeType.NodeOrId<NodeType.Entity>;
    note?: string;
}

export interface PoolUpdateInput {
    name?: string;
    volume?: number | string;
    type?: NodeType.NodeOrId<NodeType.PoolType>;
    sanitiser?: NodeType.NodeOrId<NodeType.Sanitiser>;
    note?: string;
}

const createMutator = ({ mutatePool }: PoolMutatorInjected) => {
    const createPool = async (input: PoolCreateInput) => {
        invariant(input, 'Expected PoolCreateInput');

        let mutationConfig: NodeType.CreatePoolMutationInput = {
            name: _trim(input.name),
            volume: _toNumber(input.volume),
            type: NodeType.extractId(input.type),
            sanitiser: NodeType.extractId(input.sanitiser),
            site: { id: NodeType.extractId(input.site) },
            entity: NodeType.extractId(input.entity),
        };
        return await mutatePool.create(mutationConfig);
    };

    const updatePool = async (pool: NodeType.NodeOrId<NodeType.Pool>, input: PoolUpdateInput): Promise<any> => {
        if (_isEmpty(input)) return null;

        let mutationConfig: NodeType.UpdatePoolMutationInput = {
            id: NodeType.extractId(pool),
        };

        if (input.note) {
            mutationConfig.note = input.note;
        }
        if (input.name) {
            mutationConfig.name = _trim(input.name);
        }
        if (input.type) {
            mutationConfig.type = NodeType.extractId(input.type);
        }
        if (input.sanitiser) {
            mutationConfig.sanitiser = NodeType.extractId(input.sanitiser);
        }
        if (input.volume) {
            mutationConfig.volume = _toNumber(input.volume);
        }
        return await mutatePool.update(mutationConfig);
    };

    const updateNote = (pool: NodeType.Pool, note: string) => {
        return updatePool(pool, { note: note });
    };

    const removeProductItem = async (
        pool: NodeType.NodeOrId<NodeType.Pool>,
        item: NodeType.NodeOrId<NodeType.Item>
    ) => {
        let mutationConfig = {
            id: NodeType.extractId(pool),
            items: {
                delete: [NodeType.extractId(item)],
            },
        };
        return await mutatePool.update(mutationConfig);
    };

    const _createProductItem = async (
        pool: NodeType.NodeOrId<NodeType.Pool>,
        input: NodeType.UpdatePoolListItemCreateInput
    ) => {
        const itemCreateConf: NodeType.UpdatePoolListItemCreateInput = {
            product: input.product,
            serial: input.serial,
            installedAt: input.installedAt,
            note: input.note,
            label: input.label,
        };

        let mutationConfig: NodeType.UpdatePoolMutationInput = {
            id: NodeType.extractId(pool),
            items: {
                create: [itemCreateConf],
            },
        };

        return await mutatePool.update(mutationConfig);
    };

    const createProductItemWithExistingProduct = async (
        pool: NodeType.NodeOrId<NodeType.Pool>,
        input: {
            product: NodeType.NodeOrId<NodeType.Product>;
        } & Omit<NodeType.UpdatePoolListItemCreateInput, 'product'>
    ) => {
        invariant(input, 'input is not provided');
        invariant(input.product, 'input.product is required');

        return await _createProductItem(pool, { ...input, product: { id: NodeType.extractId(input.product) } });
    };

    const createProductItemWithNewProduct = async (
        pool: NodeType.NodeOrId<NodeType.Pool>,
        input: {
            productCreateInput: NodeType.CreatePoolListItemCreateSwitchCreateProductInput;
        } & Omit<NodeType.UpdatePoolListItemCreateInput, 'product'>
    ) => {
        invariant(input, 'input is not provided');
        invariant(input.productCreateInput, 'input.newProductInput is required');

        const productCreateInput = _pick(input.productCreateInput, [
            'name',
            'description',
            'identification',
            'sku',
            'customSku',
            'isOneOff',
            'isGrouping',
            'createdAt',
            'updatedAt',
            'brand',
            'supplier',
            'organisationType',
            'franchise',
            'addedBy',
            'traits',
        ]);

        return await _createProductItem(pool, { ...input, product: { create: productCreateInput } });
    };

    return {
        PoolMutator: {
            createPool,
            updatePool,
            updateNote,
            removeProductItem,
            createProductItem: createProductItemWithExistingProduct,
            createProductItemWithNewProduct,
        },
    };
};

export const withPoolMutators = (refetchQueries) => compose(withPoolMutator(refetchQueries), withProps(createMutator));

export interface PoolMutatorProps extends PoolMutatorInjected, ReturnType<typeof createMutator> {}
