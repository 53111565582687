import clsx from 'clsx';
import getHeight from 'dom-helpers/height';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { findDOMNode } from 'react-dom';

import * as dates from './utils/dates';
import BackgroundCells from './BackgroundCells';
import EventRow from './EventRow';
import EventEndingRow from './EventEndingRow';
import * as DateSlotMetrics from './utils/DateSlotMetrics';
import { RBCContext } from './CalendarContext';

const propTypes = {
    date: PropTypes.instanceOf(Date),
    events: PropTypes.array.isRequired,
    range: PropTypes.array.isRequired,

    resourceId: PropTypes.any,
    renderForMeasure: PropTypes.bool,
    renderHeader: PropTypes.func,

    container: PropTypes.func,
    selected: PropTypes.object,

    onSelectSlot: PropTypes.func,
    onSelect: PropTypes.func,
    onSelectEnd: PropTypes.func,
    onSelectStart: PropTypes.func,
    onDoubleClick: PropTypes.func,
    dayPropGetter: PropTypes.func,

    getNow: PropTypes.func.isRequired,
    isAllDay: PropTypes.bool,

    minRows: PropTypes.number.isRequired,
    maxRows: PropTypes.number.isRequired,
};

const defaultProps = {
    minRows: 0,
    maxRows: Infinity,
};

interface DateContentRowProps extends InferProps<typeof propTypes> {
    className?: string;
    selectable?: boolean | 'ignoreEvents';
}

class DateContentRow extends React.Component<DateContentRowProps, any> {
    public static propTypes = propTypes;
    public static defaultProps = defaultProps;
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    private readonly slotMetrics: ReturnType<typeof DateSlotMetrics.getSlotMetrics>;
    private headingRow: any;
    private eventRow: HTMLElement;
    constructor(props, context) {
        super(props, context);

        this.slotMetrics = DateSlotMetrics.getSlotMetrics();
    }

    handleSelectSlot = (slot) => {
        const { range, onSelectSlot } = this.props;

        onSelectSlot(range.slice(slot.start, slot.end + 1), slot);
    };

    createHeadingRef = (r) => {
        this.headingRow = r;
    };

    createEventRef = (r) => {
        this.eventRow = r;
    };

    getContainer = () => {
        const { container } = this.props;
        return container ? container() : findDOMNode(this);
    };

    getRowLimit() {
        let eventHeight = getHeight(this.eventRow);
        let headingHeight = this.headingRow ? getHeight(this.headingRow) : 0;
        let eventSpace = getHeight(findDOMNode(this) as any) - headingHeight;

        return Math.max(Math.floor(eventSpace / eventHeight), 1);
    }

    renderHeadingCell = (date, index) => {
        let { renderHeader, getNow } = this.props;

        return renderHeader({
            date,
            key: `header_${index}`,
            className: clsx('rbc-date-cell', dates.eq(date, getNow(), 'day') && 'rbc-now'),
        });
    };

    renderDummy = () => {
        let { className, range, renderHeader } = this.props;
        return (
            <div className={className}>
                <div className="rbc-row-content">
                    {renderHeader && (
                        <div className="rbc-row" ref={this.createHeadingRef}>
                            {range.map(this.renderHeadingCell)}
                        </div>
                    )}
                    <div className="rbc-row" ref={this.createEventRef}>
                        <div className="rbc-row-segment">
                            <div className="rbc-event">
                                <div className="rbc-event-content">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const {
            date,
            range,
            className,
            selected,
            selectable,
            renderForMeasure,
            getNow,
            renderHeader,
            onSelect,
            onSelectStart,
            onSelectEnd,
            onDoubleClick,
            resourceId,
            isAllDay,
        } = this.props;
        const { getters, accessors, localizer, components } = this.context;

        if (renderForMeasure) return this.renderDummy();

        let metrics = this.slotMetrics({ ...this.props, accessors });
        let { levels, extra } = metrics;

        let WeekWrapper = components.weekWrapper;

        const eventRowProps = {
            selected,
            accessors,
            getters,
            localizer,
            components,
            onSelect,
            onDoubleClick,
            resourceId,
            slotMetrics: metrics,
        };

        return (
            <div className={className}>
                <BackgroundCells
                    date={date}
                    getNow={getNow}
                    range={range}
                    selectable={selectable}
                    container={this.getContainer}
                    onSelectStart={onSelectStart}
                    onSelectEnd={onSelectEnd}
                    onSelectSlot={this.handleSelectSlot}
                />

                <div className="rbc-row-content">
                    {renderHeader && (
                        <div className="rbc-row " ref={this.createHeadingRef}>
                            {range.map(this.renderHeadingCell)}
                        </div>
                    )}
                    <WeekWrapper isAllDay={isAllDay} {...eventRowProps}>
                        {levels.map((segs, idx) => (
                            <EventRow key={idx} segments={segs} {...eventRowProps} />
                        ))}
                        {!!extra.length && <EventEndingRow segments={extra} {...eventRowProps} />}
                    </WeekWrapper>
                </div>
            </div>
        );
    }
}

export default DateContentRow;
