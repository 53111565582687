import React from 'react';
import EventRowMixin, { EventRowMixinProps } from './EventRowMixin';
import { eventLevels } from './utils/eventLevels';
import range from 'lodash/range';
import { RBCContext } from './CalendarContext';

let isSegmentInSlot = (seg, slot) => seg.left <= slot && seg.right >= slot;
let eventsInSlot = (segments, slot) => segments.filter((seg) => isSegmentInSlot(seg, slot)).length;

const defaultProps = {
    ...EventRowMixin.defaultProps,
};

interface EventEndingRowProps extends EventRowMixinProps {
    segments: any[];
    // slots: number;
}

class EventEndingRow extends React.Component<EventEndingRowProps> {
    public static defaultProps = defaultProps;
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;
    render() {
        let {
            segments,
            slotMetrics: { slots },
        } = this.props;
        let rowSegments = eventLevels(segments).levels[0];

        let current = 1,
            lastEnd = 1,
            row = [];

        while (current <= slots) {
            let key = '_lvl_' + current;

            let { event, left, right, span } = rowSegments.filter((seg) => isSegmentInSlot(seg, current))[0] || {}; //eslint-disable-line

            if (!event) {
                current++;
                continue;
            }

            let gap = Math.max(0, left - lastEnd);

            if (this.canRenderSlotEvent(left, span)) {
                let content = EventRowMixin.renderEvent(this.props, event);

                if (gap) {
                    row.push(EventRowMixin.renderSpan(slots, gap, key + '_gap'));
                }

                row.push(EventRowMixin.renderSpan(slots, span, key, content));

                lastEnd = current = right + 1;
            } else {
                if (gap) {
                    row.push(EventRowMixin.renderSpan(slots, gap, key + '_gap'));
                }
                lastEnd = current = current + 1;
            }
        }

        return <div className="rbc-row">{row}</div>;
    }

    canRenderSlotEvent(slot, span) {
        let { segments } = this.props;

        return range(slot, slot + span).every((s) => {
            let count = eventsInSlot(segments, s);

            return count === 1;
        });
    }
}

export default EventEndingRow;
