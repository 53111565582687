import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
    Alert,
    ButtonWithDropdownMenu,
    confirmModalImperative,
    DropdownMenuOptionType,
    Icon,
    MenuBar,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    MenuButtonItemConf,
    Modal,
    PageLayout,
    ScrollableLayout,
    Segment,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { useWorkOrderMutators } from '../../../queries/mutators-work-order';
import { useDndTable } from '../../../components/table-dnd-helpers';
import {
    JobTodoBody,
    JobTodoContainer,
    JobTodoRowContainer,
    JobTodoRowDraggable,
} from '../../../ServiceJobTemplatesManager/ServiceJobTemplate/ServiceJobTemplateView/WorkOrderTemplatesAssocsEdit/WorkOrderTemplate/components';
import { JobTodoView } from '../../../ServiceJobTemplatesManager/ServiceJobTemplate/ServiceJobTemplateView/WorkOrderTemplatesAssocsEdit/WorkOrderTemplate/JobTodoTemplateView';
import { JobTodoEditForm } from './JobTodoEditForm';
import { JobTodoNewForm } from './JobTodoNewForm';
import { useJobTodoMutators } from '../../../queries/mutators-job-todos';
import { JobTodoTemplatePicker } from '../../../ServiceJobTemplatesManager/JobTodoTemplate/JobTodoTemplatePicker';
import { getWorkOrderSummary } from '../utils';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import { SummaryContainer } from './componets-styled';
import { ServiceJobTemplatePicker } from '../../../connected-components/ServiceJobTemplatePicker';
import { ServiceJobTemplatePreview } from '../../../components/ServiceJobTemplatePreview';

const confirmDelete = {
    confirmMessage: {
        header: 'Delete Job Task',
        content: 'There is no UNDO! This job task will be delete.',
    },
    confirmButton: {
        content: 'Delete',
        icon: 'trash',
        negative: true,
    },
};

export interface JobTodosEditProps {
    workOrder: NodeType.WorkOrder;
    workOrderMutators: ReturnType<typeof useWorkOrderMutators>;
    jobTodoMutators: ReturnType<typeof useJobTodoMutators>;
}

const JobTodosListEditModeContent: React.FC<JobTodosEditProps> = ({
    workOrder,
    workOrderMutators,
    jobTodoMutators,
}) => {
    const [appendingNew, setAppendingNew] = useState(false);
    const [editingJobTodo, setEditingJobTodo] = useState<NodeType.JobTodo>(null);
    const modalPicker = useModalCtrl();

    useEffect(() => {
        return () => {
            setEditingJobTodo(null);
            setAppendingNew(false);
        };
    }, [workOrder]);

    const sortedJobTodos = useMemo(() => {
        return fromEdges(workOrder?.jobs).sort((l, r) => {
            return l.index > r.index ? 1 : -1;
        });
    }, [workOrder]);

    const handleOnDelete = async (jobTodo: NodeType.JobTodo) => {
        const answer = await confirmModalImperative(confirmDelete);
        if (!answer) {
            return;
        }
        try {
            await jobTodoMutators.delete({ id: jobTodo.id });
        } catch (e) {
            toastError(e);
        }
    };

    const handleOnTemplatePickerSubmit = async (jobTodoTemplates: NodeType.JobTodoTemplate[]) => {
        try {
            const assocsListLength = sortedJobTodos.length;
            const newJobTodos = jobTodoTemplates.map<NodeType.UpdateWorkOrderListJobTodoCreateInput>(
                (jobTemplate, index) => {
                    return {
                        index: assocsListLength + index,
                        template: jobTemplate.id,
                    };
                }
            );
            await workOrderMutators.update({
                id: workOrder.id,
                jobs: {
                    create: newJobTodos,
                },
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onReorder = async (tableRowItems: NodeType.JobTodo[]) => {
        try {
            const reindexedItems = tableRowItems
                .map((jobTodo, index) => ({
                    id: jobTodo.id,
                    oldPriority: jobTodo.index,
                    newPriority: index,
                }))
                .filter((item) => item.oldPriority !== item.newPriority)
                .map((item) => ({ id: item.id, index: item.newPriority }));

            await workOrderMutators.update({
                id: workOrder.id,
                jobs: {
                    update: reindexedItems,
                },
            });
        } catch (e) {
            toastError(e);
        }
    };

    const { rowItems, dropRow, moveRow } = useDndTable({ items: sortedJobTodos, onReorder });

    function getMenuItems(assoc: NodeType.JobTodo) {
        const menuItems: DropdownMenuOptionType[] = [
            {
                content: 'Edit',
                icon: 'edit',
                onClick: () => setEditingJobTodo(assoc),
            },
            {
                content: 'Delete',
                icon: 'trash',
                onClick: () => handleOnDelete(assoc),
            },
        ];
        return menuItems;
    }

    const workOrderSummary = getWorkOrderSummary(sortedJobTodos);

    const totalTimeString = workOrderSummary.totalTime ? `${workOrderSummary.totalTime} min` : '--';
    const isEditingMode = !!editingJobTodo;

    return (
        <JobTodoContainer>
            <SummaryContainer>
                <div className={'summary-row'}>
                    <div className={'summary-row-label'}>Total Duration:</div>
                    <div className={'summary-row-value'}>{totalTimeString}</div>
                </div>
                {false && (
                    <div className={'summary-row'}>
                        <div className={'summary-row-label'}>Total Cost:</div>
                        <div className={'summary-row-value'}>$ {workOrderSummary.totalPrice}.00</div>
                    </div>
                )}
            </SummaryContainer>
            <JobTodoBody>
                {rowItems.map((jobTodo, index) => {
                    const isEditingAssoc = jobTodo.id === editingJobTodo?.id;
                    const menuItems = getMenuItems(jobTodo);
                    const jobTodoItems = fromEdges(jobTodo.items);

                    return (
                        <JobTodoRowDraggable
                            key={jobTodo.id}
                            verticalAlign={'top'}
                            color={'grey'}
                            index={index}
                            moveRow={moveRow}
                            dropRow={dropRow}
                        >
                            {isEditingAssoc ? (
                                <div className={'row-content-main edit'}>
                                    <JobTodoEditForm
                                        jobTodo={jobTodo}
                                        onDone={() => setEditingJobTodo(null)}
                                        jobTodoMutators={jobTodoMutators}
                                    />
                                </div>
                            ) : (
                                <JobTodoView
                                    title={jobTodo.title}
                                    description={jobTodo.description}
                                    menuItems={menuItems}
                                    isLinked={false}
                                    disabled={isEditingMode}
                                    time={jobTodo.timeEstimate}
                                    price={jobTodo.price}
                                    todoItems={jobTodoItems}
                                />
                            )}
                        </JobTodoRowDraggable>
                    );
                })}

                {appendingNew && (
                    <JobTodoRowContainer>
                        <div className={'row-content'}>
                            <div className={'drag-handle nondraggable'} />
                            <JobTodoNewForm
                                jobTodoMutators={jobTodoMutators}
                                workOrder={workOrder}
                                onDone={() => setAppendingNew(false)}
                            />
                        </div>
                    </JobTodoRowContainer>
                )}
            </JobTodoBody>

            <div className={'my-2'} />

            <ButtonWithDropdownMenu
                disabled={isEditingMode || appendingNew}
                icon={'plus'}
                color={'teal'}
                content={'Add Job Task'}
                options={[
                    {
                        content: 'Add New',
                        onClick: () => setAppendingNew(true),
                    },
                    {
                        content: 'Add from Task template',
                        onClick: modalPicker.onOpen,
                    },
                ]}
            />

            <Modal {...modalPicker}>
                <JobTodoTemplatePicker
                    onCancel={modalPicker.onClose}
                    onSubmit={async (d) => {
                        await handleOnTemplatePickerSubmit(d);
                        modalPicker.onClose();
                    }}
                />
            </Modal>
        </JobTodoContainer>
    );
};

type TemplatePickMode = 'replace' | 'append';

export const JobTodosListEditMode: React.FC<{
    workOrder: NodeType.WorkOrder;
    onDone: () => any;
    refetchQuery;
}> = ({ onDone, refetchQuery, workOrder }) => {
    const modalWOPicker = useModalCtrl();
    const [templatePickerMode, setTemplatePickerMode] = useState<TemplatePickMode>(null);
    const workOrderMutators = useWorkOrderMutators({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });
    const jobTodoMutators = useJobTodoMutators({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    const onSelectTemplate = (mode: TemplatePickMode) => {
        setTemplatePickerMode(mode);
        modalWOPicker.onOpen();
    };

    const replaceWorkOrder = async (
        template: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        const workOrderTemplate = workOrderTemplates?.[0];
        const res = await confirmModalImperative({
            confirmMessage: {
                header: 'Replace Work Order Template?',
                content: (
                    <div className={'flex flex-col gap-2'}>
                        <Alert type={'warning'}>All existing job tasks WILL BE REMOVED!</Alert>
                        <Alert>New job tasks will be added</Alert>
                        <div className={'rounded p-2 bg-tertiary'}>
                            <ServiceJobTemplatePreview
                                serviceJobTemplate={template}
                                full={true}
                                workOrderIds={[workOrderTemplate.id]}
                            />
                        </div>
                    </div>
                ),
            },
            confirmButton: { content: 'Replace' },
            cancelButton: { content: 'Cancel' },
        });

        if (!res) {
            return;
        }

        return await workOrderMutators.replaceWorkOrderTemplate({
            template: workOrderTemplate?.id,
            id: workOrder.id,
        });
    };

    const appendWorkOrder = async (
        template: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        const workOrderTemplate = workOrderTemplates?.[0];
        const res = await confirmModalImperative({
            confirmMessage: {
                header: 'Append Work Order Template?',
                content: (
                    <div>
                        <ServiceJobTemplatePreview
                            serviceJobTemplate={template}
                            full={true}
                            workOrderIds={[workOrderTemplate.id]}
                        />
                    </div>
                ),
            },
            confirmButton: { content: 'Append' },
            cancelButton: { content: 'Cancel' },
        });

        if (!res) {
            return;
        }

        return await workOrderMutators.appendWorkOrderTemplate({
            template: workOrderTemplate?.id,
            id: workOrder.id,
        });
    };

    const onSelectJobTemplate = async (
        template: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        modalWOPicker.onClose();
        if (!template) {
            return null;
        }
        try {
            switch (templatePickerMode) {
                case 'replace':
                    return await replaceWorkOrder(template, workOrderTemplates);
                case 'append':
                    return await appendWorkOrder(template, workOrderTemplates);
            }
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const menuGroup: MenuBarGroupProps = {
        menu: {
            icon: 'plus',
            color: 'blue',
            content: 'Service Template',
            collapsed: false,
            menuMode: MenuBarGroupMODE.DROPDOWN,
        },
        items: [
            { icon: 'retweet', onClick: () => onSelectTemplate('replace'), content: 'Replace With Template' },
            { icon: 'plus', onClick: () => onSelectTemplate('append'), content: 'Append From Template' },
        ],
    };

    return (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar>
                <MenuBarSection position={'left'}>
                    <MenuBarItem onClick={onDone} icon={'cancel'} />
                    <MenuBarHeaderItem>
                        <Icon color={ModuleColorNames.WorkOrderItem} name={ModuleIconNames.WorkOrderItem} />
                        Work Order Job Tasks
                    </MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBar.Group {...menuGroup} />
                </MenuBarSection>
            </ScrollableLayout.MenuBar>
            <ScrollableLayout.BodyScroll>
                <PageLayout.BodySection width={'screen-lg'}>
                    <Segment>
                        <JobTodosListEditModeContent
                            jobTodoMutators={jobTodoMutators}
                            workOrder={workOrder}
                            workOrderMutators={workOrderMutators}
                        />
                    </Segment>
                </PageLayout.BodySection>
            </ScrollableLayout.BodyScroll>
            {modalWOPicker.open && (
                <Modal {...modalWOPicker}>
                    <ServiceJobTemplatePicker
                        onCancel={modalWOPicker.onClose}
                        onSubmit={onSelectJobTemplate}
                        selectWorkOrder={'single'}
                        requiredWorkOrder={true}
                    />
                </Modal>
            )}
        </ScrollableLayout>
    );
};
