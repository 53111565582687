import * as React from 'react';
import * as _ from 'lodash';
import { ActionBar, DebouncedInput } from '@ez/components';
import { useWorkOrderListActions } from '../../redux';

export const FilterByWorkOrderNumber: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { WorkOrderListAction, WorkOrderListState } = useWorkOrderListActions();
    const { workOrderNumber } = WorkOrderListState.filters;

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            WorkOrderListAction.setWorkOrderId(null);
        } else {
            WorkOrderListAction.setWorkOrderId(_.trim(term));
        }
    };

    return (
        <ActionBar>
            <ActionBar.Header content={label} icon={'filter'} />
            <DebouncedInput
                // label={label}
                initialValue={workOrderNumber}
                placeholder={placeholder || 'Search by Work Order ID...'}
                onChange={handleChange}
            />
        </ActionBar>
    );
};
