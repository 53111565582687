import * as React from 'react';
import * as _ from 'lodash';
import * as URLBuilder from '../../url-builder';
import { Link } from 'react-router-dom';
import {
    ButtonWithPopup,
    ColorPillWithColorPicker,
    Display,
    Icon,
    Panel,
    Popup,
    stringFormatters,
} from '@ez/components';
import { deserializeColorPalette, NodeType } from '@poolware/api';
import { DropdownStaff } from '@poolware/app-shell';
import AppointmentGroupSingleSelector from '../../Components/AppointmentGroupSingleSelector';
import { IconButton } from '../../Components/IconButton';
import { recurrenceToString } from '../../utils';
import { ServiceJobLink, WorkOrderLink } from '../../../Links';
import { IconServiceJobStage, ModuleIconNames } from '../../../constants';

const NotesPopupIcon: React.FC<{ note?: string }> = ({ note }) => {
    if (!note) {
        return null;
    }

    return (
        <Popup
            closeOnTriggerMouseLeave={true}
            size={'mini'}
            style={{
                padding: '0.5rem',
            }}
            position={'right center'}
            trigger={<Icon name={'sticky note outline'} style={{ marginTop: '0.25em' }} />}
        >
            <div style={{ minWidth: '180px' }}>
                <Panel>
                    <Panel.Header>Customer notes</Panel.Header>
                    <Panel.Body>
                        <Display.Description value={note} />
                    </Panel.Body>
                </Panel>
            </div>
        </Popup>
    );
};

export const AppointmentPreviewServiceJobContentItems: React.FC<{
    appointmentItem?: NodeType.AppointmentItem;
    onStartWorkOrder: () => any;
    showStartWOButton: boolean;
}> = (props) => {
    const { appointmentItem, onStartWorkOrder, showStartWOButton } = props;
    const { serviceJob, workOrder, isWorkOrderPending } = appointmentItem;

    if (!serviceJob && !workOrder) {
        return null;
    }

    const isReccur = serviceJob?.isRecurring;
    const sjTitle = _.truncate(serviceJob?.title, { length: 40 });
    const woTitle = _.truncate(workOrder?.title, { length: 40 });
    const groupTitle = serviceJob?.group?.title;

    return (
        <>
            {groupTitle && (
                <Panel.Item labelIcon={ModuleIconNames.ServiceGroup} label={'Group'}>
                    {groupTitle && <span className={'pl-1 font-bold text-purple-600'}>{groupTitle}</span>}
                </Panel.Item>
            )}
            {serviceJob && isReccur && isWorkOrderPending && (
                <>
                    <Panel.Item
                        label={isReccur ? 'Recurring Service' : 'Service Case'}
                        labelIcon={ModuleIconNames.ServiceCase}
                    >
                        <ServiceJobLink id={serviceJob.id}>#{serviceJob.serviceJobNumber} </ServiceJobLink>
                        <span className={'pl-2'}> {isWorkOrderPending ? sjTitle : 'Recurring'}</span>
                    </Panel.Item>
                </>
            )}

            {workOrder ? (
                <Panel.Item label="Work Order" labelIcon={ModuleIconNames.WorkOrder}>
                    <IconServiceJobStage stage={workOrder?.stage} />
                    <WorkOrderLink id={workOrder.id}>#{workOrder.workOrderNumber}</WorkOrderLink>
                    <span className={'pl-2'}>{woTitle}</span>
                </Panel.Item>
            ) : (
                <Panel.Item label="Work Order" labelIcon={ModuleIconNames.WorkOrder}>
                    {isWorkOrderPending && showStartWOButton && (
                        <>
                            <ButtonWithPopup size={'tiny'} color={'blue'} onClick={onStartWorkOrder}>
                                Start Work Order
                            </ButtonWithPopup>
                        </>
                    )}
                    {isWorkOrderPending && !showStartWOButton && <i>pending...</i>}
                </Panel.Item>
            )}
            <Panel.Divider />
        </>
    );
};

export interface AppointmentPreviewContentItemsProps {
    appointmentItem?: NodeType.AppointmentItem;
    appointmentGroups;
    onGroupChange;
    onChangeStaff;
    onColorChange;
    onStartWorkOrder;
    showStartWOButton?: boolean;
    canEdit: boolean;
    showJobDetails?: boolean;
    showNote?: boolean;
}

export const AppointmentDetailsContentItems: React.FC<AppointmentPreviewContentItemsProps> = ({
    appointmentItem,
    appointmentGroups,
    onChangeStaff,
    onColorChange,
    onGroupChange,
    onStartWorkOrder,
    showStartWOButton,
    canEdit,
    showJobDetails = true,
    showNote = true,
}) => {
    const { startDate, duration, address, pool, staff, note, customer, group, color, isRecurring, appointment } =
        appointmentItem;
    const recurrence = appointment?.recurrence;
    const selectedGroup = group ? appointmentGroups.find((ag) => ag.id === group.id) : null;
    const palette = selectedGroup ? deserializeColorPalette(selectedGroup) : null;

    let poolURL = null;
    if (customer && pool) {
        poolURL = URLBuilder.Customer(customer.id).Pool(pool.id).view;
    }

    const poolName = _.truncate(pool?.name || '--', { length: 40 });
    const poolVolume = pool?.volume || '--';
    const poolType = pool?.type?.name;
    const poolString = `${poolName} (${poolVolume} L, ${poolType})`;
    const poolSanitiser = pool?.sanitiser?.name;
    const poolSiteAccess = pool?.site?.accessKeyLocation;

    const wrap = false;

    return (
        <>
            <Panel.Item label="Time" labelIcon="clock" wrap={wrap}>
                <Display.DateRange startDate={startDate} duration={duration} />
            </Panel.Item>
            <Panel.Item label="Duration" labelIcon="clock" wrap={wrap}>
                {duration} min
            </Panel.Item>
            {isRecurring && (
                <Panel.Item label="Recurring" labelIcon="refresh" wrap={wrap}>
                    <div style={{ maxWidth: '330px', fontSize: '0.95em' }}>{recurrenceToString(recurrence)}</div>
                </Panel.Item>
            )}

            <Panel.Divider />

            {showJobDetails && (
                <AppointmentPreviewServiceJobContentItems
                    appointmentItem={appointmentItem}
                    onStartWorkOrder={onStartWorkOrder}
                    showStartWOButton={showStartWOButton}
                />
            )}

            <Panel.Item label={'Appt. Group'} labelIcon={'circle'}>
                <AppointmentGroupSingleSelector
                    readonly={!canEdit}
                    selectedGroupId={group && group.id}
                    onChange={onGroupChange}
                />
            </Panel.Item>

            <Panel.Item label={'Color Tag'} labelIcon={'square'}>
                <ColorPillWithColorPicker
                    readonly={!canEdit}
                    palette={palette}
                    value={color}
                    onChange={onColorChange}
                />
                <IconButton hidden={!canEdit || !color} icon={'close'} onClick={() => onColorChange(null)} />
            </Panel.Item>

            <Panel.Item label="Technician" labelIcon="spy">
                <DropdownStaff readonly={!canEdit} value={staff?.id} onChange={onChangeStaff} />
            </Panel.Item>

            {(customer || address || pool || note) && <Panel.Divider />}

            {customer && (
                <Panel.Item label="Customer" labelIcon="user">
                    <Link to={URLBuilder.Customer(customer.id).view}>
                        {stringFormatters.formatEntityName(customer)} / {customer.crn}
                    </Link>
                    <NotesPopupIcon note={customer.note} />
                    {/*<VendButtonContainer>{vendSaleButton}</VendButtonContainer>*/}
                </Panel.Item>
            )}

            {address && (
                <Panel.Item label="Address" labelIcon="marker">
                    <Display.Address value={address} showMapLink={true} />
                </Panel.Item>
            )}

            {pool && (
                <>
                    <Panel.Item label="Pool" labelIcon="life ring">
                        {poolURL ? <Link to={poolURL}>{poolString}</Link> : poolString}
                    </Panel.Item>
                    {poolSanitiser && (
                        <Panel.Item label="Pool Sanitiser" labelIcon="box">
                            {poolSanitiser}
                        </Panel.Item>
                    )}
                    {!address && (
                        <Panel.ItemAddress
                            label="Pool Address"
                            labelIcon={'marker'}
                            showMapLink={true}
                            content={pool.address}
                        />
                    )}

                    {poolSiteAccess && (
                        <Panel.Item label="Site Access Key" labelIcon={'key'}>
                            <div className={'max-h-40'}>
                                <Display.FormattedText value={poolSiteAccess} limit={180} />
                            </div>
                        </Panel.Item>
                    )}
                </>
            )}

            {note && showNote && <Panel.ItemText label="Notes" labelIcon="sticky note" content={note} />}
        </>
    );
};
