import { fromEdges, NodeType } from '@poolware/api';
import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@ez/components';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Page from './ChemicalTargetList';

const ChemicalTargetsQuery = gql`
    query ChemicalTargetsQuery($poolId: ID!) {
        pool: node(id: $poolId) {
            id
            ... on Pool {
                id
                chemicalTargets(first: 150) {
                    edges {
                        node {
                            higherThreshold
                            lowerThreshold
                            target
                            isCalibrated
                            measurementType {
                                id
                                id
                                name
                                priority
                                unit {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default compose(
    graphql(ChemicalTargetsQuery, {
        options: (props: any) => ({
            variables: { poolId: props.poolId },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.pool.id' }),
    mapProps((props) => {
        const chemicalTargets = fromEdges(props?.data?.pool?.chemicalTargets);

        return {
            ...props,
            chemicalTargets,
        };
    })
)(Page);

export interface PageControlProps {
    chemicalTargets: NodeType.PoolChemicalTarget[];
}
