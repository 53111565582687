import * as React from 'react';
import { Popup, PopupProps, StrictPopupProps } from 'semantic-ui-react';

import { ButtonRadix, ButtonRadixProps } from '../radix';
import { useSafeState } from '@ez/tools';
import { Icon, SemanticICONS } from '../Icon';

export type { ButtonRadixProps } from '../radix/button/button';

export interface ButtonWithPopupProps extends Omit<ButtonRadixProps, 'content'> {
    popup?: StrictPopupProps;
    showLoaderOnClick?: boolean;
    // Default lock reason is "No Permission"
    // Provide string value to set custom lock reason.
    locked?: boolean | string;
    loading?: boolean;
    // content?: React.ReactNode;
    // color?: SemanticCOLORS;
    disabled?: boolean;
    content?: React.ReactNode;
    iconLeft?: SemanticICONS | string | React.ReactNode;
    iconRight?: SemanticICONS | string | React.ReactNode;

    // Deprecated
    // size?: any;
    icon?: SemanticICONS | React.ReactNode;
    negative?: boolean;
    primary?: boolean;
    fluid?: boolean;
    basic?: boolean;
    floated?: any;
    // [key: string]: any;
}

export const ButtonWithPopup = React.forwardRef<HTMLButtonElement, ButtonWithPopupProps>((props, ref) => {
    const {
        popup,
        showLoaderOnClick = true,
        onClick,
        locked = false,
        icon,
        iconLeft,
        iconRight,
        disabled,
        children,
        content,
        basic,
        floated,
        fluid,
        loading,
        ...rest
    } = props;
    const [isLoadingState, setLoadingState] = useSafeState(false);

    // if (popup && rest.floated) {
    //     console.warn(
    //         "WARNING: Popup with floated Buttons don't like each other. Don't use Popup and floated button together. See https://github.com/Semantic-Org/Semantic-UI-React/issues/2804"
    //     );
    // }

    const handleOnClick = async (event, data) => {
        // console.log('handleOnClick', { showLoaderOnClick, loading, disabled });
        if (showLoaderOnClick) {
            try {
                setLoadingState(true);
                await onClick?.(event, data);
                setLoadingState(false);
            } catch (e) {
                setLoadingState(false);
                throw e;
            }
        } else {
            return onClick?.(event, data);
        }
    };

    const prepareIcon = (icon: SemanticICONS | string | React.ReactNode) => {
        if (typeof icon === 'string') {
            return <Icon name={icon} />;
        } else {
            return icon;
        }
    };

    const prepareLeftIcon = (icon: SemanticICONS | string | React.ReactNode) => {
        let contentIcon;
        let _popup: PopupProps = popup;
        if (loading || isLoadingState) {
            contentIcon = <Icon size={rest.size} spin={true} name={'spinner'} />;
        } else if (locked) {
            if (!_popup && !!locked) {
                if (typeof locked === 'string') {
                    _popup = { content: locked };
                } else {
                    _popup = { content: 'No Permission' };
                }
            }
            contentIcon = <Icon name={'lock'} />;
        } else {
            contentIcon = prepareIcon(icon);
        }
        return { component: contentIcon, popup: _popup };
    };

    const iconCompLeft = prepareLeftIcon(iconLeft || icon);
    const iconCompRight = prepareIcon(iconRight);

    const renderButton = () => {
        //IMPORTANT: wrap in span so that Popup could appear when button is disabled.
        return (
            <ButtonRadix
                type="button"
                onClick={handleOnClick}
                disabled={loading || disabled || !!locked}
                ref={ref}
                {...rest}
            >
                {iconCompLeft.component}
                {content}
                {children}
                {iconCompRight}
            </ButtonRadix>
        );
    };

    // const renderButtonSUI = (fluid?: boolean) => {
    //     //IMPORTANT: wrap in span so that Popup could appear when button is disabled.
    //     return (
    //         <ButtonSUI
    //             basic={true}
    //             type="button"
    //             onClick={handleOnClick}
    //             icon={contentIcon}
    //             disabled={loading || disabled || !!locked}
    //             style={style}
    //             fluid={fluid}
    //             {...rest}
    //         />
    //     );
    // };

    if (iconCompLeft.popup?.content) {
        return <Popup trigger={renderButton()} position={'top center'} {...iconCompLeft.popup} />;
    }

    return renderButton();
});

// alias
export const Button = ButtonWithPopup;
