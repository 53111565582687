import { ThemeInterface } from '../app-theme';
import { createThemeByHue, getDefaultTheme } from '@ez/components';
import { hslParams } from '@ez/components/dist/app-theme/utils';

export const getTheme = (publicPrefix) => {
    // Dracula >:-[
    // https://github.com/dracula

    const huePrim = 234;
    const theme = createThemeByHue({
        publicPrefix: publicPrefix,
        huePrim: huePrim,
        hueSec: 28,
        primSat: 25,
        primLum: 30,
        customisation: {
            logoPath: `${publicPrefix}/logo.png`,
            themeName: 'poolware-admin',
            navbar: {
                logo: {
                    backgroundColor: hslParams(huePrim, 25, 20),
                },
            },
        },
    });

    return theme;
};
