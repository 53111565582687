import * as React from 'react';
import { useEffect, useState } from 'react';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { Button, Modal, Segment } from '@ez/components';
import { TraitDeclarationPicker_Tree } from './TraitDeclarationPicker_Tree';

export const TraitDeclarationPicker_TreeModal: React.FC<{
    rootTag?: string;
    selected?: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>;
    onSelect: (newValue: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>) => any;
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
}> = ({ onSelect, selected, rootTag = null, organisationType }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [_selected, setSelectedParent] = useState(selected);

    useEffect(() => {
        // reset selection on modal open;
        setSelectedParent(null);
    }, [modalOpen]);

    const onLocalSelect = (newSelected?: NodeType.ProductTraitDeclaration) => {
        setSelectedParent(newSelected);
    };
    const allowUpdate = !!_.get(_selected, 'id');

    const commitUpdate = async () => {
        if (!allowUpdate) return;
        await onSelect(_selected);
        setModalOpen(false);
    };

    let orgName = '';
    if (organisationType) {
        orgName = typeof organisationType === 'string' ? organisationType : organisationType?.name;
    }

    return (
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            trigger={<Button type={'button'} icon={'search'} onClick={() => setModalOpen(true)} />}
        >
            <Modal.Header>Select new parent</Modal.Header>
            <Modal.Content>
                {orgName && (
                    <div>
                        Organisation: <b className={'text-purple-500'}>{orgName}</b>
                    </div>
                )}
                <Segment className={'overflow-y-auto max-h-[500px]'}>
                    <TraitDeclarationPicker_Tree
                        rootTag={rootTag}
                        onSelect={(pd) => onLocalSelect(pd)}
                        current={[_selected]}
                        organisationType={organisationType}
                    />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button type={'button'} floated={'left'} onClick={() => setModalOpen(false)}>
                    Cancel
                </Button>
                <Button
                    type={'button'}
                    disabled={!allowUpdate}
                    variant={'primary'}
                    content="Select"
                    onClick={commitUpdate}
                />
            </Modal.Actions>
        </Modal>
    );
};
