import * as React from 'react';
import { NodeType } from '@poolware/api';
import { ConnectionTableDef, DefaultConnectionTable } from '@ez/components';
import { useQueryPoolTypeConnection } from './use-query-pool-type-connection';

export interface PoolTypeTableProps {
    onCancel: () => void;
    onSelect: (product: NodeType.PoolType) => void;
    organisationId?: string;
}

export const PoolTypeTable: React.FC<PoolTypeTableProps> = ({ onCancel, onSelect, organisationId }) => {
    const { connectionData, connectionState } = useQueryPoolTypeConnection({
        search: {
            organisationType: {
                id: organisationId,
            },
        },
    });
    const tableDef: ConnectionTableDef<NodeType.Sanitiser> = [
        {
            header: 'Name',
            cell: (row) => row.name,
        },
    ];
    return (
        <DefaultConnectionTable
            connectionData={connectionData}
            onRowClick={onSelect}
            connectionState={connectionState}
            tableDef={tableDef}
        />
    );
};
