import styled from 'styled-components';

export const TruncatedDiv = styled.div<{ maxWidth?: number }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${(props) => props.maxWidth || 300}px;
    overflow: hidden;
    padding: 3px 5px;
    margin: -3px -5px;
`;
