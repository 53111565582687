import { compose, withProps } from '@ez/tools';
import {
    IMutateVendAPI,
    IMutateVendCustomer,
    IMutateVendLink,
    IMutateVendSale,
    NodeType,
    useMutationVendAPI,
    useMutationVendCustomer,
    useMutationVendLink,
    useMutationVendSale,
    withVendAPIMutator,
    withVendCustomerMutator,
    withVendLinkMutator,
    withVendSaleMutator,
} from './api-types';
import _trim from 'lodash/trim';
import invariant from 'invariant';

export interface MutatorsVendInjected extends IMutateVendAPI, IMutateVendLink, IMutateVendCustomer, IMutateVendSale {}

export interface MutatorsVendAdded {
    VendMutator: VendMutator;
}

export interface VendMutatorProps extends MutatorsVendInjected, MutatorsVendAdded {}

export interface VendMutator {
    createVendAPI: (input: {
        key: string;
        shopUri: string;
        franchise?: NodeType.NodeOrId<NodeType.Franchise>;
    }) => Promise<any>;
    updateVendAPI: (input: { key: string; shopUri: string; vend: NodeType.NodeOrId<NodeType.VendAPI> }) => Promise<any>;
    deleteVendAPI: (vendApi: NodeType.NodeOrId<NodeType.VendAPI>) => Promise<any>;
    resyncVendCustomer: (vendLink: NodeType.NodeOrId<NodeType.VendLink>) => Promise<any>;
    pushCustomerToVend: (customer: NodeType.NodeOrId<NodeType.Customer>) => Promise<any>;
    createVendSale: (
        vendLink: NodeType.NodeOrId<NodeType.VendLink>,
        saleInput?: NodeType.CreateVendSaleMutationInput
    ) => Promise<any>;
}

const createMutator = ({
    mutateVendAPI,
    mutateVendLink,
    mutateVendCustomer,
    mutateVendSale,
}: MutatorsVendInjected): MutatorsVendAdded => {
    const createVendAPI = async (input: {
        key: string;
        shopUri: string;
        franchise?: NodeType.NodeOrId<NodeType.Franchise>;
    }) => {
        invariant(input, 'expected input');

        let mutationConfig: any = {
            key: _trim(input.key),
            shopUri: _trim(input.shopUri),
        };

        if (input.franchise) {
            mutationConfig.franchise = NodeType.extractId(input.franchise);
        }

        return mutateVendAPI.create(mutationConfig);
    };

    const updateVendAPI = async (input: {
        key: string;
        shopUri: string;
        vend: NodeType.NodeOrId<NodeType.VendAPI>;
    }) => {
        invariant(input, 'expected input');

        let mutationConfig: any = {
            id: NodeType.extractId(input.vend),
            key: _trim(input.key),
            shopUri: _trim(input.shopUri),
        };

        return await mutateVendAPI.update(mutationConfig);
    };

    const deleteVendAPI = async (vendApi: NodeType.NodeOrId<NodeType.VendAPI>) => {
        invariant(vendApi, 'expected input');

        let mutationConfig = {
            id: NodeType.extractId(vendApi),
        };

        return await mutateVendAPI.delete(mutationConfig);
    };

    const resyncVendCustomer = async (vendLink: NodeType.NodeOrId<NodeType.VendLink>) => {
        const vendLinkId = NodeType.extractId(vendLink);
        return mutateVendLink.sync({ id: vendLinkId });
    };

    const pushCustomerToVend = async (customer: NodeType.NodeOrId<NodeType.Customer>) => {
        await mutateVendCustomer.create({ customer: NodeType.extractId(customer) });
    };

    const createVendSale = async (
        vendLink: NodeType.NodeOrId<NodeType.VendLink>,
        saleInput?: NodeType.CreateVendSaleMutationInput
    ) => {
        const vendLinkId = NodeType.extractId(vendLink);
        return mutateVendSale.create({ vendLink: vendLinkId });
    };

    return {
        VendMutator: {
            createVendAPI,
            updateVendAPI,
            deleteVendAPI,
            resyncVendCustomer,
            pushCustomerToVend,
            createVendSale,
        },
    };
};

export const withVendMutators = (refetchQueries) =>
    compose(
        withVendCustomerMutator(refetchQueries),
        withVendLinkMutator(refetchQueries),
        withVendSaleMutator(refetchQueries),
        withVendAPIMutator(refetchQueries),
        withProps(createMutator)
    );

export const useVendMutators = (refetchQueries) => {
    const mutateVendCustomer = useMutationVendCustomer({ refetchQueries: refetchQueries });
    const mutateVendSale = useMutationVendSale({ refetchQueries: refetchQueries });
    const mutateVendAPI = useMutationVendAPI({ refetchQueries: refetchQueries });
    const mutateVendLink = useMutationVendLink({ refetchQueries: refetchQueries });

    return {
        mutateVendCustomer,
        mutateVendSale,
        ...createMutator({ mutateVendAPI, mutateVendCustomer, mutateVendLink, mutateVendSale }),
    };
};
