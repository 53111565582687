import * as React from 'react';
import {
    MenuBarDropdownItemWithConfirm,
    MenuBarGroup,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    Panel,
    StickyMenuBar,
    SuspenseSkeletonLoader,
    toastError,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationCustomerTag } from '@poolware/api';
import { NotFoundPage } from '@poolware/app-shell';
import { TagOwnershipLabel } from './components';
import { useQueryCustomerTag } from './use-query-customer-tag';

export interface PageProps {}

export const PageView: React.FC<PageProps> = () => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const { node: customerTag, error, loading } = useQueryCustomerTag(params.id);
    const { delete: deleteMutation } = useMutationCustomerTag({ refetchQueries: ['QueryCustomerTagsConnection'] });

    const goBack = () => {
        AppNavigator.navigateRelative('/');
    };

    const onDelete = async () => {
        try {
            await deleteMutation({ id: params.id });
            goBack();
        } catch (e) {
            toastError(e);
        }
    };

    if (loading || error) {
        return <SuspenseSkeletonLoader error={error} loading={loading} />;
    }

    if (!loading && !customerTag) {
        return <NotFoundPage />;
    }

    const canDelete = customerTag.checkMutations?.delete;

    const barGroupItemConf: MenuBarGroupProps = !canDelete
        ? undefined
        : {
              menu: { menuMode: MenuBarGroupMODE.DROPDOWN, icon: 'bars', color: 'red' },
              items: [
                  {
                      render: (
                          <MenuBarDropdownItemWithConfirm
                              icon={'trash'}
                              color="orange"
                              content="Delete"
                              onClick={onDelete}
                              popup={{ content: 'Delete Customer Tag' }}
                              confirm={{
                                  confirmMessage: {
                                      header: 'Delete Tag?',
                                      content: 'There is no undo! This tag will be deleted forever! ',
                                  },
                                  confirmButton: {
                                      content: 'Delete',
                                      icon: 'trash',
                                      negative: true,
                                  },
                              }}
                          />
                      ),
                  },
              ],
          };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back to list'} />
                    <MenuBarHeaderItem icon={'tag'}>Customer Tag</MenuBarHeaderItem>
                </MenuBarSection>
                {barGroupItemConf && (
                    <MenuBarSection position={'right'}>
                        <MenuBarGroup {...barGroupItemConf} />
                    </MenuBarSection>
                )}
            </StickyMenuBar>

            <Panel>
                <Panel.Header content={'Customer Tag'} />
                <Panel.Body>
                    <Panel.Item label={'Name'} content={customerTag?.name} />
                    <Panel.Item label={'Ownership'}>
                        <TagOwnershipLabel franchise={customerTag.franchise} />
                    </Panel.Item>
                    <Panel.ItemEntity label={'Create by'} content={customerTag?.addedBy} />
                    {customerTag?.createdAt && <Panel.ItemDate label={'Created'} content={customerTag?.createdAt} />}
                </Panel.Body>
            </Panel>
        </VStack>
    );
};
