import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { ModuleColorNames, ModuleIconNames } from '../../../../constants';
import { TabContainer, TabMenuContainer, TabMenuItem, TabPaneContainer } from './components';
import { EmptyBoxImage, SemanticICONS } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { WorkOrderTemplate } from './WorkOrderTemplate';

export type TabMenuPaneType = {
    assoc?: NodeType.WorkOrderTemplateAssoc;
    key?: string;
    title: string;
    icon: SemanticICONS;
    priority: number;
};

interface WorkOrderMenuItemsProps {
    panes: TabMenuPaneType[];
    onSelectPane: (TabNavControlledTabConf) => any;
    activePaneKey: string;
    serviceJobTemplate: NodeType.ServiceJobTemplate;
}

const WorkOrdersMenuItems: React.FC<WorkOrderMenuItemsProps> = ({ panes, activePaneKey, onSelectPane }) => {
    return (
        <>
            {panes.map((pane, index) => {
                const active = pane.key === activePaneKey;
                return (
                    <TabMenuItem
                        key={pane.key}
                        index={index}
                        label={pane.title}
                        onClick={() => onSelectPane(pane)}
                        active={active}
                        iconColor={ModuleColorNames.WorkOrder}
                        iconName={pane.icon}
                    />
                );
            })}
        </>
    );
};

const NoWorkOrdersMessage = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                padding: '2rem 0 1rem 0',
            }}
        >
            <EmptyBoxImage />
            <i style={{ textAlign: 'center', fontSize: 'x-small', color: '#777777' }}>No Work Order Templates</i>
        </div>
    );
};

export interface TabNavControlledProps {
    serviceJobTemplate: NodeType.ServiceJobTemplate;
}

export const WorkOrderTemplateAssocsView: React.FC<TabNavControlledProps> = ({ serviceJobTemplate }) => {
    const { AppNavigator } = useAppNavigator();

    const panes = useMemo(() => {
        const panes: TabMenuPaneType[] = fromEdges(serviceJobTemplate.workOrderTemplateAssocs)
            .sort((l, r) => {
                return l.priority > r.priority ? 1 : -1;
            })
            .map((assoc) => ({
                assoc: assoc,
                icon: ModuleIconNames.WorkOrder,
                key: assoc.workOrderTemplate?.id,
                title: assoc.workOrderTemplate?.templateTitle || 'unknown',
                priority: assoc.priority,
            }));
        return panes;
    }, [serviceJobTemplate]);

    // Get active pane
    let activePaneKey = AppNavigator.query['pane'] || panes[0]?.key;
    let activePane = panes.find((pane) => pane.key === activePaneKey);
    if (!activePane) {
        activePane = panes[0];
        activePaneKey = activePane?.key;
    }

    useEffect(() => {
        // Set active pane key in URL
        if (!AppNavigator.query['pane'] && activePaneKey) {
            AppNavigator.setSearchQueries({ pane: activePaneKey });
        }
    }, [activePaneKey]);

    const onSelectPane = (pane?: TabMenuPaneType) => {
        AppNavigator.setSearchQueries({ pane: pane.key });
    };

    return (
        <>
            <TabContainer>
                <TabMenuContainer>
                    <WorkOrdersMenuItems
                        serviceJobTemplate={serviceJobTemplate}
                        panes={panes}
                        activePaneKey={activePaneKey}
                        onSelectPane={onSelectPane}
                    />
                </TabMenuContainer>
                <TabPaneContainer>
                    {activePane ? (
                        <WorkOrderTemplate workOrderTemplateId={activePane.assoc.workOrderTemplate?.id} />
                    ) : (
                        <NoWorkOrdersMessage />
                    )}
                </TabPaneContainer>
            </TabContainer>
        </>
    );
};
