import * as React from 'react';
import {
    Form,
    FormGroup,
    FormikDatePickerInputField,
    FormikInputField,
    FormikTextareaField,
    FormSectionBody,
    FormSectionHeader,
    HeaderButtonType,
    PageLayout,
    stringFormatters,
    VStack,
} from '@ez/components';
import { FormikInputInvoiceItems } from './FormikInputInvoiceItems';
import { FormikInvoicePaymentMethod, FormikInvoiceSender } from './form-field-components';
import { useFormikContext } from 'formik';
import { InvoiceFormValueType } from './types';

const VStackPanel: React.FC = (props) => <VStack className={'bg-gray-50 p-6 mb-4 shadow rounded'} {...props} />;

const FormikInputSenderDetails: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<InvoiceFormValueType>();

    const onCopyFromAttachment = () => {
        const address = values.docMeta?.customer?.billAddress || values.docMeta?.customer?.primaryAddress;

        if (address) {
            setFieldValue('receiver.company.address', stringFormatters.formatAddress(address));
        }
        if (values.docMeta?.customer?.user?.firstName || values.docMeta?.customer?.user?.lastName) {
            setFieldValue('receiver.person.name', stringFormatters.formatEntityName(values.docMeta?.customer));
        }
        if (values.docMeta?.customer?.companyName) {
            setFieldValue('receiver.company.name', values.docMeta?.customer?.companyName);
            setFieldValue('receiver.company.abn', '');
        }
    };

    const button: HeaderButtonType = {
        onClick: onCopyFromAttachment,
        icon: 'copy',
        content: 'Copy From Customer',
        hidden: !values.docMeta?.customer,
    };

    return (
        <>
            <FormSectionHeader button={button}>Receiver</FormSectionHeader>
            <FormSectionBody>
                <FormGroup>
                    <FormikInputField label={'Company Name'} name={'receiver.company.name'} />
                    <FormikInputField label={'ABN'} name={'receiver.company.abn'} />
                </FormGroup>
                <FormikInputField label={'Attn.'} name={'receiver.person.name'} />
                <FormikInputField label={'Address'} name={'receiver.company.address'} />
            </FormSectionBody>
        </>
    );
};

export const FormEditInvoiceDoc: React.FC = () => {
    return (
        <Form>
            <VStackPanel>
                <div className={'text-center mb-8'}>
                    <h1>Tax Invoice</h1>
                </div>
                <FormSectionBody>
                    <FormGroup>
                        <FormikInputField label={'Invoice #'} name={'invoiceNumber'} />
                        <FormikDatePickerInputField label={'Issue Date'} name={'issueDate'} />
                        <FormikDatePickerInputField label={'Due Date'} name={'dueDate'} />
                    </FormGroup>
                </FormSectionBody>

                <FormikInputSenderDetails />
            </VStackPanel>

            <VStackPanel>
                <FormSectionHeader>Items</FormSectionHeader>
                <FormSectionBody>
                    <FormikInputInvoiceItems name={'items'} />
                </FormSectionBody>
                <FormSectionBody>
                    <FormikTextareaField label={'Description'} name={'description'} />
                </FormSectionBody>
            </VStackPanel>

            <VStackPanel>
                <FormikInvoiceSender />
                <FormikInvoicePaymentMethod />
            </VStackPanel>
            <PageLayout.BodySection className={'my-8'} />
        </Form>
    );
};
