import styled, { keyframes } from 'styled-components';

const baseColor = 'var(--ez-main-background-color-tertiary, #f3f3f3)';
const shineColor = 'var(--ez-main-background-color, rgba(229,229,229,0.8))';
const animationDuration = '2.0s';

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
const backgroundGradient = () => `
    background-image: linear-gradient(90deg, ${baseColor} 0px, ${shineColor} 40px, ${baseColor} 80px);
    background-size: 280px;
`;

const ShineLines = keyframes`
    0% {
        background-position: -100px;
    }
    100%{
        background-position: 200px;
    }
`;

export const TableLineLoader = styled.div`
    //float: left;
    width: 100%;
    min-width: 20px;
    height: 19px;
    border-radius: 7px;
    ${backgroundGradient()};
    animation: ${ShineLines} ${animationDuration} infinite ease-out;
`;
