import * as React from 'react';
import { useField } from 'formik';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { ButtonRadixProps, CalendarPicker, Input, Popover, PopoverContent, PopoverTrigger } from '../radix';
import { Button } from '../buttons';
import { Display } from '../display';
import { Slot } from '@radix-ui/react-slot';
import { FormField, FormikFieldProps } from './FormField';
import { formatDate } from '../string-formatters';
import { PopoverAnchor } from '@radix-ui/react-popover';
import { Icon } from '../Icon';
import { useId } from './useId';
import { cn } from '../utils';

export enum DatePickerMode {
    DATE,
    // TIME,
}

export interface FormikDatePickerButtonFieldProps extends ButtonRadixProps, FormikFieldProps {
    dateFormat?: string;
    monthsShown?: number;
    minDate?: Date;
    maxDate?: Date;
    isClearable?: boolean;
    shouldCloseOnSelect?: boolean;
    todayButton?: string;
    name: string;
    mode?: DatePickerMode;
}

export const FormikDatePickerButtonField: React.FC<FormikDatePickerButtonFieldProps> = ({
    name,
    label,
    required,
    disabled,
    readOnly,
    className,
    monthsShown = 3,
    mode = DatePickerMode.DATE,
    id,
    minDate,
    maxDate,
    ...rest
}) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField({ name: name });
    const [open, setOpen] = React.useState(false);

    const innerWidth = window?.innerWidth;
    const touched = meta.touched;
    const error = meta.error;
    let hasError = touched && !!error;

    let _monthsShown: number;
    if (innerWidth >= 1024) {
        _monthsShown = monthsShown || 3;
    } else if (innerWidth >= 768) {
        _monthsShown = 2;
    } else {
        _monthsShown = 1;
    }
    const isDisabled = disabled || readOnly;

    const minYear = minDate?.getFullYear() || 2020;
    const maxYear = maxDate?.getFullYear() || 2040;

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <FormField>
                <FormikFormFieldLabel htmlFor={fieldId} label={label} name={name} required={required} />
                <PopoverTrigger asChild>
                    <Button
                        aria-invalid={hasError}
                        iconLeft={'calendar'}
                        variant={'secondary'}
                        className={cn(className, 'border-input')}
                        onBlur={field.onBlur}
                        id={fieldId}
                        disabled={isDisabled}
                        content={<Display.Date format={'ll'} value={field.value} />}
                    />
                </PopoverTrigger>
            </FormField>
            <PopoverContent className="w-auto p-0">
                <CalendarPicker
                    numberOfMonths={_monthsShown}
                    captionLayout="dropdown-buttons"
                    mode="single"
                    fromYear={minYear}
                    toYear={maxYear}
                    selected={field.value}
                    initialFocus
                    onSelect={async (event) => {
                        await helpers.setValue(event);
                        setOpen(false);
                    }}
                />
            </PopoverContent>
        </Popover>
    );
};

export const FormikDatePickerInputField: React.FC<FormikDatePickerButtonFieldProps> = ({
    name,
    label,
    required,
    disabled,
    readOnly,
    className,
    monthsShown = 2,
    dataTestId,
    mode = DatePickerMode.DATE,
    isClearable = true,
    minDate,
    maxDate,
    id,
    ...rest
}) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField({ name: name });
    const [open, setOpen] = React.useState(false);
    const touched = meta.touched;
    const error = meta.error;
    let hasError = touched && !!error;

    const innerWidth = window?.innerWidth;

    let _monthsShown;
    if (innerWidth >= 1024) {
        _monthsShown = monthsShown || 3;
    } else if (innerWidth >= 768) {
        _monthsShown = 2;
    } else {
        _monthsShown = 1;
    }

    const onClear = () => {
        helpers.setValue(null);
    };

    const showClear = field.value && isClearable;
    const isDisabled = disabled || readOnly;
    return (
        <Popover open={open} onOpenChange={setOpen}>
            <FormField>
                <FormikFormFieldLabel
                    htmlFor={fieldId}
                    label={label}
                    name={name}
                    required={required}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                />
                <PopoverAnchor>
                    <div className={'flex flex-row flex-nowrap w-full'}>
                        <Input
                            onBlur={field.onBlur}
                            aria-invalid={hasError}
                            id={fieldId}
                            name={name}
                            readOnly={true}
                            value={field.value ? formatDate(field.value, 'll') : ''}
                            onClick={() => setOpen(true)}
                            data-testid={dataTestId}
                            disabled={isDisabled}
                            iconRight={
                                showClear &&
                                !isDisabled && (
                                    <Icon
                                        className={'hover:bg-accent rounded-full cursor-pointer'}
                                        name={'cancel'}
                                        onClick={onClear}
                                    />
                                )
                            }
                            className={'border-r-0 rounded-r-none text-center hover:cursor-pointer flex-grow'}
                        />
                        <Button
                            aria-invalid={hasError}
                            segmentPosition={'last'}
                            icon={'calendar'}
                            size={'md'}
                            disabled={isDisabled}
                            className={'border-input h-input-base'}
                            onClick={() => setOpen(true)}
                        />
                    </div>
                </PopoverAnchor>
            </FormField>
            <PopoverContent className="w-auto p-0">
                <Slot
                    onSelect={(event) => {
                        helpers.setValue(event);
                        setOpen(false);
                    }}
                >
                    <CalendarPicker
                        numberOfMonths={_monthsShown}
                        captionLayout="dropdown-buttons"
                        mode="single"
                        fromYear={minDate?.getFullYear() || 2000}
                        toYear={maxDate?.getFullYear() || 2040}
                        selected={field.value}
                        initialFocus
                    />
                </Slot>
            </PopoverContent>
        </Popover>
    );
};
