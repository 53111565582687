import * as React from 'react';
import { clsxm } from './utils';
import { cva, VariantProps } from 'class-variance-authority';

const segmentCVA = cva('', {
    variants: {
        color: {
            purple: 'border-t-2 border-t-purple',
            grey: 'border-t-2 border-t-gray-600',
            orange: 'border-t-2 border-t-orange',
            blue: 'border-t-2 border-t-blue',
            brown: 'border-t-2 border-t-brown',
        },
    },
});

export interface SegmentProps
    extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>,
        VariantProps<typeof segmentCVA> {}

export const Segment = React.forwardRef<HTMLDivElement, SegmentProps>(({ className, color, ...props }, ref) => {
    return (
        <div
            ref={ref}
            className={clsxm(['p-3 rounded border bg-panel', segmentCVA({ color: color }), className])}
            {...props}
        />
    );
});
