import * as React from 'react';
import { Button, Panel } from '@ez/components';
import { BookingActionProps, BookingReturnDest, withBookingActions } from '../../redux';

class PageWhereToGo extends React.Component<BookingActionProps> {
    render() {
        const go = this.props.BookingAction.navigateToAfterCreation;
        const returnToDetails = this.props.BookingState.returnToDetails;
        return (
            <Panel>
                <Panel.Header icon={'checkmark'} content={'green'}>
                    Booking Successful!
                </Panel.Header>
                <Panel.Footer>
                    <Button
                        basic
                        onClick={() => {
                            go(BookingReturnDest.CALENDAR);
                        }}
                        content={'Go To Calendar'}
                    />
                    <Button
                        basic
                        onClick={() => {
                            go(returnToDetails.returnDestination);
                        }}
                        content={returnToDetails.label}
                    />
                </Panel.Footer>
            </Panel>
        );
    }
}

export default withBookingActions()(PageWhereToGo) as React.ComponentType<BookingActionProps>;
