import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMobileAppointmentCtx } from '../Root';
import { Button, PageLayout } from '@ez/components';
import { Frame, SectionButton, WaterTestSection } from '../components';
import { fromEdges, NodeType } from '@poolware/api';
import { calcChecklistCount } from '../PageCheckList/utils';

interface PageSectionProps {
    expanded?: boolean;
    onToggle?: () => void;
    appointmentItem?: NodeType.AppointmentItem;
}

export const PageSectionJobDetails: React.FC<PageSectionProps> = ({ expanded, onToggle }) => {
    const { AppNavigator } = useAppNavigator();
    const { workOrder, isWorkOrderPending, startWorkOrder } = useMobileAppointmentCtx();

    const onViewChecklist = () => {
        AppNavigator.navigateRelative('/checklist');
    };

    if (isWorkOrderPending) {
        return (
            <>
                <SectionButton expanded={expanded} onClick={onToggle}>
                    Job Details
                </SectionButton>
                {expanded && (
                    <PageLayout.BodySection vStack={true}>
                        <Button fluid={true} variant={'primary'} color={'primary'} size={'lg'} onClick={startWorkOrder}>
                            Start W/O
                        </Button>
                    </PageLayout.BodySection>
                )}
            </>
        );
    }

    const checklistCount = calcChecklistCount(workOrder);

    return (
        <>
            <SectionButton expanded={expanded} onClick={onToggle}>
                Job Details
            </SectionButton>
            {expanded && (
                <PageLayout.BodySection vStack={true}>
                    <Button className={'mb-4'} fluid={true} color={'primary'} size={'lg'} onClick={onViewChecklist}>
                        View Checklist ({checklistCount.completed}/{checklistCount.total})
                    </Button>

                    <Frame>
                        {fromEdges(workOrder?.testReports).map((testReport) => {
                            return <WaterTestSection testReport={testReport} key={testReport.id} />;
                        })}
                    </Frame>
                </PageLayout.BodySection>
            )}
        </>
    );
};
