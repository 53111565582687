import * as React from 'react';
import {
    Alert,
    AlertContent,
    AlertHeader,
    ButtonWithPopup,
    ConnectionErrorMessage,
    Icon,
    PageSkeletonLoader,
    Panel,
    toastError,
    toastSuccess,
} from '@ez/components';
import { fromEdges } from '@poolware/api';
import { useQueryServiceJob } from '../../queries/use-query-service-job';
import gql from 'graphql-tag';
import { useRecurCaseCtx } from './RecurCaseProvider';

const QL = gql`
    query QueryServiceJob($id: ID!) {
        node(id: $id) {
            id
            ... on ServiceJob {
                files(sort: { createdAt: true }) {
                    edges {
                        node {
                            id
                            createdAt
                        }
                    }
                }
                workOrders {
                    edges {
                        node {
                            id
                            title
                            workOrderNumber
                            stage {
                                id
                                title
                                type
                                status
                            }
                            appointmentItem {
                                id
                                startDate
                            }
                            assignedTo {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                                role {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export interface PanelStopServiceProps {
    onDone: () => any;
}

const pluralise = (word, count) => {
    if (count > 1) {
        return word + 's';
    } else {
        return word;
    }
};

export const PanelDeleteService: React.FC<PanelStopServiceProps> = ({ onDone }) => {
    const { serviceJobMutators, serviceJob, isStopped } = useRecurCaseCtx();

    const { node, loading, error } = useQueryServiceJob(serviceJob.id, {
        query: QL,
        fetchPolicy: 'cache-and-network',
    });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <>Failed to load details</>;
    }

    const onDelete = async () => {
        try {
            const res = window.confirm('Are you absolutely sure you want to delete this service?');
            if (!res) {
                return;
            }
            await serviceJobMutators.archive({ id: serviceJob.id });
            // await serviceJobMutators.ServiceJobMutator.delete(serviceJob);
            toastSuccess({ title: 'Deleted successfully' });
            // AppNavigator.replaceToOrigin('/sj/recur', { relativeToModule: true });
            onDone?.();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const workOrders = fromEdges(node?.workOrders);
    const hasWorkOrders = false; // workOrders.length > 0;

    const canDelete = !hasWorkOrders && isStopped;

    return (
        <Panel>
            <Panel.Header content={'Delete Recurring Service'} />
            <Panel.Body>
                <Panel.Item label={'ID'} content={serviceJob.serviceJobNumber} />
                <Panel.Item label={'Recurring Service'} content={serviceJob.title} />
                <Panel.ItemEntity label={'Customer'} content={serviceJob?.customer} />
                {!canDelete && (
                    <>
                        <>
                            <Alert type={'warning'}>
                                <AlertHeader>Cannot delete recurring service.</AlertHeader>
                            </Alert>
                            {!isStopped && (
                                <Alert>
                                    <AlertHeader>
                                        <Icon name="warning sign" /> Service is active
                                    </AlertHeader>
                                    <AlertContent>Please stop the service first by changing its end date.</AlertContent>
                                </Alert>
                            )}
                            {hasWorkOrders && (
                                <Alert type={'warning'}>
                                    <AlertHeader>
                                        <Icon name="warning sign" /> Service has work orders
                                    </AlertHeader>
                                    <AlertContent>
                                        <p>
                                            Cannot to delete recurring service while it has work orders associated with
                                            it.
                                        </p>
                                        <p>Please delete all associated work orders first.</p>
                                        <p>
                                            This service has <b>{workOrders.length}</b> associated work{' '}
                                            {pluralise('order', workOrders.length)}.
                                        </p>
                                    </AlertContent>
                                </Alert>
                            )}
                        </>
                    </>
                )}
            </Panel.Body>
            <Panel.Footer>
                <ButtonWithPopup onClick={onDone}>Cancel</ButtonWithPopup>
                <div style={{ float: 'right' }}>
                    <ButtonWithPopup
                        showLoaderOnClick={true}
                        primary
                        disabled={!canDelete}
                        type={'button'}
                        negative={true}
                        onClick={onDelete}
                        content={`Delete`}
                        floated={'right'}
                    />
                </div>
            </Panel.Footer>
        </Panel>
    );
};
