import { ColorPill, DebugJSON, Display, Panel, stringFormatters } from '@ez/components';
import * as React from 'react';
import { recurrenceToString } from '../utils';
import { SectionHeader } from '@ez/components';

export const PanelAppointmentDiff: React.FC<{ diff: any }> = ({ diff = {} }) => {
    const { address, staff, group, color, customer, pool, note, startDate, duration, recurrence } = diff || ({} as any);
    return (
        <>
            <SectionHeader>Updated fields</SectionHeader>
            <Panel>
                <Panel.Body>
                    {startDate && (
                        <Panel.Item label="Appointment Time">
                            <Display.DateRange startDate={startDate} duration={duration} dayFormat={null} />
                        </Panel.Item>
                    )}
                    {duration && <Panel.Item label="Duration">{duration} mins</Panel.Item>}
                    {group !== undefined && (
                        <Panel.Item label={'Appointment Group'}>
                            {group === null ? 'Default Group' : group.title}
                        </Panel.Item>
                    )}
                    {color !== undefined && (
                        <Panel.Item label={'Color Tag'}>
                            {color === null ? 'Removed' : <ColorPill value={color} />}
                        </Panel.Item>
                    )}
                    {staff !== undefined && (
                        <Panel.Item label={'Staff'}>
                            {staff === null ? 'Removed' : stringFormatters.formatEntityName(staff)}
                        </Panel.Item>
                    )}
                    {customer !== undefined && (
                        <Panel.Item label={'Customer'}>
                            {customer === null ? 'Removed' : stringFormatters.formatEntityName(customer)}
                        </Panel.Item>
                    )}
                    {address !== undefined && (
                        <Panel.Item label="Address">
                            {address === null ? 'Removed' : <Display.Address value={address} />}
                        </Panel.Item>
                    )}
                    {pool && (
                        <>
                            <Panel.Item label="Pool">
                                {pool.name} {pool.volume && ` (${pool.volume} L)`}
                            </Panel.Item>
                            <Panel.Item label="Pool Address">{stringFormatters.formatAddress(pool.address)}</Panel.Item>
                        </>
                    )}
                    {pool === null && (
                        <>
                            <Panel.Item label="Pool">Removed</Panel.Item>
                            <Panel.Item label="Pool Address">Removed</Panel.Item>
                        </>
                    )}
                    {note !== undefined && (
                        <Panel.Item label="Notes">
                            <Display.FormattedText value={note} limit={60} />
                        </Panel.Item>
                    )}
                    {recurrence !== undefined && (
                        <Panel.Item label="Recurrence">
                            <div className={'flex flex-col'}>
                                <span>New recurrence pattern</span>
                                <span>{recurrenceToString(recurrence)}</span>
                            </div>
                        </Panel.Item>
                    )}
                </Panel.Body>
                <DebugJSON data={diff} />
            </Panel>
        </>
    );
};
