import { RouteComponentProps } from 'react-router';
import * as _ from 'lodash';
import { IWithModuleRouteContextProps } from './module-route';
import { parseCurrentRoute, preparePath } from './utils';

const queryString = require('query-string');

export type AppNavSearchQueryObjType = Record<any, string | null | undefined | number>;

export interface IAppNavigatorOptions {
    state?: any;
    search?: AppNavSearchQueryObjType;
    relativeToModule?: boolean;
    query?: AppNavSearchQueryObjType;
    moduleId?: string;
}

export interface IAppNavigatorReplaceOptions extends IAppNavigatorOptions {
    setOrigin?: boolean | string;
    setOriginSearchQueries?: AppNavSearchQueryObjType;
}

export interface IAppNavigatorNavigateOptions extends IAppNavigatorOptions {
    setOrigin?: boolean | string;
    setOriginSearchQueries?: AppNavSearchQueryObjType;
    modal?: boolean;
}

type NavPathType<T> = (pathname: string, options?: T) => void;
type NavLocationType = (location: Object) => void;

const _patchSearchQuery = (currentQuery: AppNavSearchQueryObjType, patchQueries: AppNavSearchQueryObjType) => {
    // make a copy
    let newquery = { ...currentQuery };

    Object.keys(patchQueries).forEach((key) => {
        const paramName = key;
        const value = patchQueries[key];
        const queryWithoutValue = _.omit(newquery, paramName);
        newquery = value ? { ...queryWithoutValue, [paramName]: value } : queryWithoutValue;
    });
    return newquery;
};

export function createNavigator<Params extends { [K in keyof Params]?: string } = any, QueryVars = any>(
    props: IWithModuleRouteContextProps & RouteComponentProps<Params, any, any>
) {
    const parsedRoute = parseCurrentRoute<Params, QueryVars>({ location: props.location, match: props.match });

    const navigateRelative = (pathname: string | Object, options?: IAppNavigatorNavigateOptions) => {
        return navigate(pathname, { relativeToModule: true, ...options });
    };

    const navigate = (pathname: string | Object, options?: IAppNavigatorNavigateOptions) => {
        if (typeof pathname !== 'string') {
            // location object
            return props.history.push(pathname);
        }

        if (!options) {
            // Important: if no options, then only push with `pathname` string.
            // Don't provide other options like search or state. Otherwise weird things will might happen
            return props.history.push(pathname);
        }

        const {
            setOrigin = false,
            modal = false,
            state = {},
            relativeToModule = false,
            query = {},
            search = '',
        } = options || {};

        if (setOrigin) {
            if (typeof setOrigin === 'boolean') {
                state.returnTo = props.location.pathname;
                if (options.setOriginSearchQueries) {
                    state.returnToSearch = queryString.stringify(
                        _patchSearchQuery(parsedRoute.query as any, options.setOriginSearchQueries)
                    );
                } else {
                    state.returnToSearch = props.location.search;
                }
            } else if (typeof setOrigin === 'string') {
                state.returnTo = setOrigin;
            }
        }

        if (modal) {
            state.modal = true;
        }

        pathname = preparePath({
            pathname,
            routeCtx: props.routeContext,
            appNavCtx: props.appNavContext,
            relativeToModule: relativeToModule,
            moduleId: options.moduleId,
        });

        props.history.push({
            pathname: pathname as string,
            state: state,
            search: search || queryString.stringify(query),
        });
    };

    const replace: NavPathType<IAppNavigatorReplaceOptions> | NavLocationType = (
        pathname: string | Object,
        options?: IAppNavigatorReplaceOptions
    ) => {
        if (typeof pathname !== 'string') {
            // location object
            return props.history.replace(pathname);
        }
        if (!options) {
            // Important: if no options, then only replace with `pathname` string.
            // Don't provide other options like search or state. Otherwise weird things will might happen
            return props.history.replace(pathname);
        }

        const { setOrigin, state = {}, relativeToModule = false, query, search } = options || ({} as any);

        if (setOrigin) {
            if (typeof setOrigin === 'boolean') {
                state.returnTo = props.location.pathname;
                state.returnToSearch = props.location.search;
            } else if (typeof setOrigin === 'string') {
                state.returnTo = setOrigin;
            }
        }

        pathname = preparePath({
            pathname,
            routeCtx: props.routeContext,
            appNavCtx: props.appNavContext,
            relativeToModule: relativeToModule,
            moduleId: options.moduleId,
        });

        props.history.replace({
            pathname: pathname as string,
            state: state,
            search: search || (query && queryString.stringify(query)),
        });
    };

    const navigateToOrigin = (fallback: string = '/', options?: IAppNavigatorNavigateOptions) => {
        const returnTo = props?.location?.state?.returnTo;
        const returnToSearch = props?.location?.state?.returnToSearch;
        if (returnToSearch && typeof returnToSearch === 'string') {
            props.history.push({ pathname: returnTo, search: returnToSearch });
        } else if (returnTo) {
            props.history.push(returnTo);
        } else {
            navigate(fallback, options);
        }
    };

    const replaceToOrigin = (fallback: string = '/', options?: IAppNavigatorNavigateOptions) => {
        const returnTo = _.get(props, 'location.state.returnTo');
        const returnToSearch = _.get(props, 'location.state.returnToSearch');
        if (returnToSearch && typeof returnToSearch === 'string') {
            props.history.replace({ pathname: returnTo, search: returnToSearch });
        } else if (returnTo) {
            props.history.replace(returnTo);
        } else {
            replace(fallback, options);
        }
    };

    const setSearchQueries = (queries: AppNavSearchQueryObjType) => {
        const currentPathName = props.history.location.pathname;
        let patchedQuery = _patchSearchQuery(parsedRoute.query as any, queries);
        props.history.replace({ pathname: currentPathName, search: queryString.stringify(patchedQuery) });
    };
    const setTab = (newTab?: string | number) => {
        setSearchQueries({ tab: newTab });
    };

    return {
        AppNavigator: {
            replace,
            navigate,
            navigateRelative,
            replaceToOrigin,
            navigateToOrigin,
            setTab,
            setSearchQueries,
            ...parsedRoute,
            routeContext: props.routeContext,
        },
        ...parsedRoute,
    };
}
