import * as React from 'react';
import { cn } from '../utils';

export interface FormikFieldProps {
    name: string;
    id?: string;
    label?: string | React.ReactNode;
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    dataTestId?: string;
}

export const FormField = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div
            ref={ref}
            className={cn(
                [
                    'w-full mb-2 inline-flex flex-col items-stretch gap-1.5',
                    'has-[:disabled]:cursor-not-allowed has-[:disabled]:opacity-70 has-[:disabled]:pointer-events-none',
                ],
                className
            )}
            {...props}
        />
    )
);
