import * as React from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import {
    Alert,
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikTextareaField,
    PageTabs,
    TabGroupMODE,
    TabPaneConf,
    useTabCtrl,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import { useFormikContext } from 'formik';
import { ModuleIconNames, ServiceJobModuleRouterID } from '../../constants';
import { FormTabWorkOrder } from './FormTabWorkOrder';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { FormTabRecurrence } from './FormTabRecurrence';
import { FormTabAppointmentDetails } from './FormTabAppointmentDetails';

export const AppointmentValidationSchema = Yup.object().shape({
    // note: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    duration: Yup.number().required('Required'),
});

export enum AppointmentFormMode {
    New = 'new',
    Edit = 'edit',
}

export type AppointmentFormValuesType = {
    mode: AppointmentFormMode;
    startDate: Date;
    duration?: number;
    defaultRecurrence: NodeType.Recurrence;
    isRecurring?: boolean;
    isRecurrenceLocked: boolean;
    recurrence?: NodeType.Recurrence;
    customer: NodeType.Customer | null;
    customerReadOnly?: boolean;
    staff: NodeType.Staff | null;
    staffReadOnly?: boolean;
    pool: NodeType.Pool | null;
    serviceJob: NodeType.ServiceJob | null;
    workOrder: NodeType.WorkOrder | null;
    followUpFromWorkOrder?: NodeType.WorkOrder | null;
    appointmentItem: NodeType.AppointmentItem | null;
    address: NodeType.Address | null;
    showRecurrenceTab: boolean;
    color?: string;
    group?: NodeType.AppointmentGroup;
    note?: string;
    isEditing: boolean;
    showWorkOrderTab: boolean;
    sjTemplate?: NodeType.ServiceJobTemplate;
    woTemplate?: NodeType.WorkOrderTemplate;
};

enum TABS {
    Appointment = 'Appointment',
    Job = 'Job',
    Notes = 'Notes',
    Recurrence = 'Recurrence',
}

export const AppointmentFormContent: React.FC = () => {
    const { values } = useFormikContext<AppointmentFormValuesType>();
    const { isEditing, workOrder, serviceJob } = values;
    const [tab, setTab] = useState<string | number>(TABS.Appointment);
    const { AppNavigator } = useAppNavigator();

    const renderNotesTab = () => {
        return (
            <FormikTextareaField
                data-testid={'TextareaField-notes'}
                rows={15}
                name={'note'}
                label="Notes"
                required={false}
            />
        );
    };

    const onOpenWorkOrder = (workOrder: NodeType.WorkOrder) => {
        AppNavigator.navigate(`/wo/${workOrder.id}`, {
            relativeToModule: true,
            setOrigin: true,
            moduleId: ServiceJobModuleRouterID,
        });
    };

    const showWorkOrderTab = values.showWorkOrderTab;

    const tabPanes: TabPaneConf[] = [
        {
            title: 'Appointment',
            icon: 'calendar alternate outline',
            key: TABS.Appointment,
            render: () => <FormTabAppointmentDetails />,
        },
        showWorkOrderTab && {
            title: 'Job',
            icon: ModuleIconNames.WorkOrder,
            key: TABS.Job,
            render: () => <FormTabWorkOrder onOpenWorkOrder={onOpenWorkOrder} refetchQuery={'AppointmentItem'} />,
        },
        {
            title: 'Notes',
            icon: 'sticky note outline',
            key: TABS.Notes,
            render: renderNotesTab,
        },
        values.showRecurrenceTab && {
            title: 'Recurrence',
            icon: 'refresh',
            key: TABS.Recurrence,
            render: () => <FormTabRecurrence />,
        },
    ];
    const tabCtrl = useTabCtrl({ panes: tabPanes, defaultTab: tab, onTabChange: setTab });

    return (
        <>
            {serviceJob && !isEditing && (
                <Alert type={'success'}>Booking Appointment for Service Case {serviceJob?.serviceJobNumber}</Alert>
            )}
            {workOrder && !isEditing && (
                <Alert type={'success'}>Booking Appointment for Work Order {workOrder?.workOrderNumber}</Alert>
            )}
            <PageTabs groupMode={TabGroupMODE.EXPANDED} paneStyle={{ minHeight: '460px' }} {...tabCtrl} />
        </>
    );
};

export interface AppointmentFormProps extends FormikDefaultFormProps<AppointmentFormValuesType> {}

export const AppointmentForm: React.FC<AppointmentFormProps> = (props) => {
    return (
        <div className={'mx-auto my-0 w-full max-w-[860px] min-h-[460px]'}>
            <FormikDefaultForm {...props}>
                <AppointmentFormContent />
            </FormikDefaultForm>
        </div>
    );
};
