import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Display, Icon } from '@ez/components';
import { getMessageForStatus } from '../status-configs';

export interface StatusHistoryProps {
    claim: NodeType.WarrantyClaim;
}

export const StatusHistory: React.FC<StatusHistoryProps> = ({ claim }) => {
    const history = fromEdges(claim.statusHistory);
    return (
        <div className={'flex flex-col gap-4'}>
            {history.map((h) => {
                return (
                    <div className={'flex flex-row items-center'}>
                        <div className={'w-16'}>
                            <Icon name="clock outline" size={'large'} />
                        </div>
                        <div>
                            <div className={'font-bold mb-2'}>{getMessageForStatus(h.status)}</div>
                            <div className={'text-sm text-secondary'}>
                                <Display.Date value={h.createdAt} />
                            </div>
                            {h.message && (
                                <div className={'mt-2'}>
                                    <Display.Text
                                        limit={1000}
                                        formatted={false}
                                        maxHeightPx={200}
                                        value={h.message || ' '}
                                        style={{
                                            backgroundColor: 'hsl(210, 12%, 94%)',
                                            padding: '0 0.5em',
                                            borderRadius: '4px',
                                            minHeight: '2rem',
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
