import * as React from 'react';

import { Field } from 'formik';
import AppointmentGroupSingleSelector from '../../Components/AppointmentGroupSingleSelector';
import { FormikFormFieldLabel } from '@ez/components';
import { FormField } from '@ez/components';

interface AppointmentGroupFieldProps {
    name: string;
    label: string;
    required?: boolean;
}

const AppointmentGroupField: React.FC<AppointmentGroupFieldProps> = ({ name, label, required }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (newValue) => form.setFieldValue(field.name, newValue);
                const value = field?.value?.id;
                return (
                    <FormField className={'max-w-xs'}>
                        <FormikFormFieldLabel label={label} name={name} required={required} />
                        <div className={'ez-btn-base border-input h-input-base'}>
                            <AppointmentGroupSingleSelector selectedGroupId={value} onChange={handleChange} />
                        </div>
                    </FormField>
                );
            }}
        </Field>
    );
};

export default AppointmentGroupField;
