import { EZCDevicesLinkWithState } from '../use-query-ezc-device-states';

export const fakeLocations = {
    B9YOzGM4a7cPjctSQO5NpKXisLr4HCG1: {
        lat: -33.7763024,
        lng: 151.1041411,
    },
    B9YOzGM4a7c4N1RyoJhEKJQU1H9wPr61: {
        lat: -33.7663024,
        lng: 151.1241411,
    },
    B9YOzGM4a7c_sLdBQsxPBaL6gkcdbMwd: {
        lat: -33.7863024,
        lng: 151.1141411,
    },
    B9YOzGM4a7deEViFokRGqLeIYPTYwcNI: {
        lat: -33.7863024,
        lng: 151.1081411,
    },
    B9YOzGM4a7fyXtt1SLlPApNZmM1xvkxg: {
        lat: -33.7803024,
        lng: 151.1031411,
    },
};

export const resolveCoordinates = (deviceLinks: EZCDevicesLinkWithState) => {
    const coords =
        deviceLinks.coordinates ||
        deviceLinks?.pool?.address?.coordinates ||
        deviceLinks?.owner?.primaryAddress?.coordinates;
    return {
        lat: coords?.lat,
        lng: coords?.lng,
    };
};
