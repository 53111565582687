import * as React from 'react';
import { SemanticCOLORS, Table } from '@ez/components';

export interface TableDefColumn<T = any> {
    header: string | React.ReactNode;
    cellProps?: (props: T, index?: number) => Object;
    cell: (props: T, index?: number) => any;

    [key: string]: any;
}

export type TableDef<T> = TableDefColumn<T>[];

export interface DataTableProps<T = any, B = any, C = any> {
    data: T[];
    tableDef: TableDef<T>;
    color?: SemanticCOLORS;
}

export const DataTable: React.FC<DataTableProps> = ({ tableDef, data, color }) => {
    const renderEmpty = () => {
        const columnNum = tableDef.length;
        return (
            <Table.Row>
                <Table.Cell colSpan={columnNum} textAlign={'center'}>
                    <span style={{ color: '#888' }}>No Data</span>
                </Table.Cell>
            </Table.Row>
        );
    };

    const renderBody = () => {
        if (!data || data.length === 0) {
            return renderEmpty();
        }

        return data.map((connectionItem, i) => {
            return (
                <Table.Row key={i}>
                    {tableDef.map((td, index) => {
                        const cp = td.cellProps ? td.cellProps(connectionItem, index) : undefined;
                        return (
                            <Table.Cell key={index} {...cp}>
                                {td.cell(connectionItem, i)}
                            </Table.Cell>
                        );
                    })}
                </Table.Row>
            );
        });
    };

    return (
        <Table size={'small'} compact={'very'} unstackable={true} celled color={color}>
            <Table.Header>
                <Table.Row>
                    {tableDef.map((td, index) => {
                        const { header, cell, cellProps, ...rest } = td;
                        const cp = cellProps ? cellProps(undefined, index) : undefined;
                        return (
                            <Table.HeaderCell textAlign={'center'} key={index} {...cp} {...rest}>
                                {td.header}
                            </Table.HeaderCell>
                        );
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>{renderBody()}</Table.Body>
        </Table>
    );
};
