import * as React from 'react';
import moment from 'moment';
import {
    ConnectionErrorMessage,
    Icon,
    MenuBarHeaderItem,
    MenuBarSection,
    PageSkeletonLoader,
    StickyMenuBar,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import { useQueryProjectHostingConnection } from './use-query-project-hosting-connection';
import { getPlatformByKey, PlatformType } from './utils';
import _groupBy from 'lodash/groupBy';
import { PageLayout, SectionHeader, Segment } from '@ez/components';

const PlatformGroup: React.FC<{ platformType: PlatformType; projects: NodeType.Project[] }> = ({
    projects,
    platformType,
}) => {
    // Remove project without recommended version
    projects = projects.filter((p) => p.recommendedVersion);

    if (projects.length === 0) {
        // Don't display empty group
        return null;
    }

    return (
        <div>
            <SectionHeader dividing={true}>
                <Icon name={platformType.icon} />
                {platformType.text}
            </SectionHeader>
            <div className={'flex flex-col gap-10'}>
                <div className={'flex flex-row flex-wrap gap-8 items-stretch '}>
                    {projects.map((project) => {
                        const { recommendedVersion } = project;
                        if (!recommendedVersion) {
                            return null;
                        }
                        const { enabledFrom, versionNumber } = recommendedVersion;
                        const releaseDate = enabledFrom ? moment(enabledFrom).format('LL') : null;

                        const platform = getPlatformByKey(project.platformKey);

                        const downloadLinkName =
                            project.platformKey !== 'unknown' ? `Download for ${platform.text}` : 'Download';
                        const downloadLinkIcon = platform.icon;
                        const downloadLink = recommendedVersion.fileURL || null;

                        return (
                            <div key={project.id} className={'flex'}>
                                <Segment className={'flex flex-row w-96 p-4 gap-4'}>
                                    <div className={'flex-shrink-0 w-20 m-0 flex justify-center items-start'}>
                                        <img
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                            }}
                                            src={project.thumbnailURL}
                                        />
                                    </div>
                                    <div className={'flex flex-col gap-6'}>
                                        <div className={'flex-grow'}>
                                            <h3>{project.name}</h3>
                                            <div>
                                                <small>Version: {versionNumber}</small>
                                                <br />
                                                {releaseDate && (
                                                    <small className="date">Release Date: {releaseDate}</small>
                                                )}
                                            </div>
                                            <div>{project.description}</div>
                                        </div>
                                        <div>
                                            {recommendedVersion.requirements && (
                                                <small>
                                                    <i>{recommendedVersion.requirements}</i>
                                                </small>
                                            )}
                                            {downloadLink ? (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={downloadLink ? { color: '#117dc1' } : { color: '#888888' }}
                                                    href={downloadLink}
                                                >
                                                    <Icon name={downloadLinkIcon} />
                                                    <span style={{ textDecoration: 'underline' }}>
                                                        {downloadLinkName}
                                                    </span>
                                                </a>
                                            ) : (
                                                <span>
                                                    <Icon name={downloadLinkIcon} />
                                                    <span style={{ textDecoration: 'underline' }}>
                                                        {downloadLinkName}
                                                    </span>
                                                </span>
                                            )}
                                            <br />
                                        </div>
                                    </div>
                                </Segment>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const Page: React.FC = () => {
    const { connectionState, connectionData } = useQueryProjectHostingConnection();

    if (connectionState.loading) {
        return <PageSkeletonLoader />;
    } else if (connectionState.error) {
        return <ConnectionErrorMessage error={connectionState.error} />;
    }

    const groupedByPlatform = _groupBy(connectionData, (p) => p.platformKey || 'unknown');

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'cloud download'}>Downloads</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>
            <PageLayout.BodySection width={'screen-md'}>
                <div className={'grid gap-8'}>
                    {Object.keys(groupedByPlatform)
                        .sort()
                        .map((key) => {
                            const p = getPlatformByKey(key);
                            const projects = groupedByPlatform[key];
                            return <PlatformGroup platformType={p} projects={projects} key={key} />;
                        })}
                </div>
            </PageLayout.BodySection>
        </PageLayout>
    );
};

export default Page;
