import clsx from 'clsx';
import * as animationFrame from 'dom-helpers/animationFrame';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import memoize from 'memoize-one';

import * as dates from './utils/dates';
import DayColumn from './DayColumn';
import { TimeGutter } from './TimeGutter';

import getWidth from 'dom-helpers/width';
import TimeGridHeader from './TimeGridHeader';
import { notify } from './utils/helpers';
import { inRange, sortEvents } from './utils/eventLevels';
import Resources from './utils/Resources';
import { CalendarViewBaseProps } from './CalendarPropsTypes';
import { CalendarContextAccessorsFns, RBCContext } from './CalendarContext';
import { CalEvent, CalResource } from './types';

const defaultProps = {
    step: 30,
    timeslots: 2,
    min: dates.startOf(new Date(), 'day'),
    max: dates.endOf(new Date(), 'day'),
    scrollToTime: dates.startOf(new Date(), 'day'),
    width: 64,
};

export interface TimeGridProps extends CalendarViewBaseProps {
    range: Date[];
    width: number;
    eventOffset: number;
}

interface TimeGridState {
    gutterWidth?: number;
    isOverflowing?: boolean;
}

export default class TimeGrid extends Component<TimeGridProps, TimeGridState> {
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    public static defaultProps = defaultProps;
    private readonly scrollRef: React.RefObject<any>;
    private readonly contentRef: React.RefObject<any>;
    private _scrollRatio: any;
    private rafHandle: number;
    private measureGutterAnimationFrameRequest: any;
    private gutter: any;
    private slots: any;
    private _selectTimer: number;
    private _updatingOverflow: boolean;

    constructor(props) {
        super(props);

        this.state = { gutterWidth: undefined, isOverflowing: null };

        this.scrollRef = React.createRef();
        this.contentRef = React.createRef();
        this._scrollRatio = null;
    }

    componentDidMount() {
        this.calculateScroll();

        // this.checkOverflow();

        if (this.props.width == null) {
            this.measureGutter();
        }

        this.applyScroll();

        window.addEventListener('resize', this.handleResize);
    }

    handleScroll = (e) => {
        if (this.scrollRef.current) {
            this.scrollRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    handleResize = () => {
        animationFrame.cancel(this.rafHandle);
        // this.rafHandle = animationFrame.request(this.checkOverflow);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        animationFrame.cancel(this.rafHandle);

        if (this.measureGutterAnimationFrameRequest) {
            window.cancelAnimationFrame(this.measureGutterAnimationFrameRequest);
        }
    }

    componentDidUpdate() {
        if (this.props.width == null) {
            this.measureGutter();
        }

        this.applyScroll();
        //this.checkOverflow()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { range, scrollToTime } = this.props;
        // When paginating, reset scroll
        if (
            !dates.eq(nextProps.range[0], range[0], 'minute') ||
            !dates.eq(nextProps.scrollToTime, scrollToTime, 'minute')
        ) {
            this.calculateScroll(nextProps);
        }
    }

    gutterRef = (ref) => {
        this.gutter = ref && findDOMNode(ref);
    };

    handleSelectAlldayEvent = (...args) => {
        //cancel any pending selections so only the event click goes through.
        this.clearSelection();
        notify(this.props.onSelectEvent, args);
    };

    handleSelectAllDaySlot = (slots, slotInfo) => {
        const { onSelectSlot } = this.props;
        notify(onSelectSlot, {
            slots,
            start: slots[0],
            end: slots[slots.length - 1],
            action: slotInfo.action,
        });
    };

    renderEvents(range: Date[], events: CalEvent[], now: Date) {
        let { min, max, dayLayoutAlgorithm } = this.props;
        const { accessors } = this.context;

        const resources = this.memoizedResources(this.props.resources, accessors);
        const groupedEvents = resources.groupEvents(events);

        return range.map((date, jj) => {
            return (
                <div test-id={'TimeGrid:renderEvents'} className={'resource-day'} key={jj}>
                    {resources.map(([id, resource], i) => {
                        let daysEvents = (groupedEvents.get(id) || []).filter((event) =>
                            dates.inRange(date, accessors.start(event), accessors.end(event), 'day')
                        );

                        return (
                            <DayColumn
                                {...this.props}
                                min={dates.merge(date, min)}
                                max={dates.merge(date, max)}
                                resourceId={resource && id}
                                isNow={dates.eq(date, now, 'day')}
                                key={i + '-' + jj}
                                date={date}
                                events={daysEvents}
                                dayLayoutAlgorithm={dayLayoutAlgorithm}
                            />
                        );
                    })}
                </div>
            );
        });
    }

    renderEventsByResource(range: Date[], events: CalEvent[], now: Date) {
        let { min, max, dayLayoutAlgorithm } = this.props;
        const { accessors } = this.context;

        const resources = this.memoizedResources(this.props.resources, accessors);
        const groupedEvents = resources.groupEvents(events);

        return resources.map(([id, resource], i) =>
            range.map((date, jj) => {
                let daysEvents = (groupedEvents.get(id) || []).filter((event) =>
                    dates.inRange(date, accessors.start(event), accessors.end(event), 'day')
                );

                return (
                    <DayColumn
                        {...this.props}
                        min={dates.merge(date, min)}
                        max={dates.merge(date, max)}
                        resourceId={resource && id}
                        isNow={dates.eq(date, now, 'day')}
                        key={i + '-' + jj}
                        date={date}
                        events={daysEvents}
                        dayLayoutAlgorithm={dayLayoutAlgorithm}
                    />
                );
            })
        );
    }

    render() {
        let { events, range, width, selected, getNow, resources, min, max, showMultiDayTimes } = this.props;
        const { accessors, components } = this.context;
        const { rootViewWrapper: RootViewWrapper = 'div' } = components;

        width = 64; // width || this.state.gutterWidth;

        let start = range[0];
        let end = range[range.length - 1];
        let allDayEvents = [];
        let rangeEvents = [];

        this.slots = range.length;

        events.forEach((event) => {
            if (inRange(event, start, end, accessors)) {
                let eStart = accessors.start(event),
                    eEnd = accessors.end(event);

                if (
                    accessors.allDay(event) ||
                    (dates.isJustDate(eStart) && dates.isJustDate(eEnd)) ||
                    (!showMultiDayTimes && !dates.eq(eStart, eEnd, 'day'))
                ) {
                    allDayEvents.push(event);
                } else {
                    rangeEvents.push(event);
                }
            }
        });

        allDayEvents.sort((a, b) => sortEvents(a, b, accessors));

        return (
            <RootViewWrapper className={clsx('rbc-time-view', resources && 'rbc-time-view-resources')}>
                <TimeGridHeader
                    range={range}
                    events={allDayEvents}
                    width={width}
                    getNow={getNow}
                    selected={selected}
                    resources={this.memoizedResources(resources, accessors)}
                    selectable={this.props.selectable}
                    scrollRef={this.scrollRef}
                    // isOverflowing={this.state.isOverflowing}
                    onSelectSlot={this.handleSelectAllDaySlot}
                    onSelectEvent={this.handleSelectAlldayEvent}
                    onDoubleClickEvent={this.props.onDoubleClickEvent}
                    onDrillDown={this.props.onDrillDown}
                    getDrilldownView={this.props.getDrilldownView}
                />
                <div
                    test-id={'TimeGrid-div'}
                    ref={this.contentRef}
                    className="rbc-time-content"
                    onScroll={this.handleScroll}
                >
                    <TimeGutter
                        width={width}
                        date={start}
                        ref={this.gutterRef}
                        min={dates.merge(start, min)}
                        max={dates.merge(start, max)}
                        step={this.props.step}
                        getNow={this.props.getNow}
                        timeslots={this.props.timeslots}
                        className="rbc-time-gutter"
                    />
                    <div test-id={'TimeGrid-div-2'} className={'rbc-time-view-day-resources-group'}>
                        {this.renderEvents(range, rangeEvents, getNow())}
                    </div>
                </div>
            </RootViewWrapper>
        );
    }

    clearSelection() {
        clearTimeout(this._selectTimer);
    }

    measureGutter() {
        if (this.measureGutterAnimationFrameRequest) {
            window.cancelAnimationFrame(this.measureGutterAnimationFrameRequest);
        }
        this.measureGutterAnimationFrameRequest = window.requestAnimationFrame(() => {
            const width = getWidth(this.gutter);

            if (width && this.state.gutterWidth !== width) {
                this.setState({ gutterWidth: width });
            }
        });
    }

    applyScroll() {
        if (this._scrollRatio != null) {
            const content = this.contentRef.current;
            content.scrollTop = content.scrollHeight * this._scrollRatio;
            // Only do this once
            this._scrollRatio = null;
        }
    }

    calculateScroll(props = this.props) {
        const { min, max, scrollToTime } = props;

        // @ts-ignore
        const diffMillis = scrollToTime - dates.startOf(scrollToTime, 'day');
        const totalMillis = dates.diff(max, min);

        this._scrollRatio = diffMillis / totalMillis;
    }

    // checkOverflow = () => {
    //     if (this._updatingOverflow) return;
    //
    //     const content = this.contentRef.current;
    //     let isOverflowing = content.scrollHeight > content.clientHeight;
    //
    //     if (this.state.isOverflowing !== isOverflowing) {
    //         this._updatingOverflow = true;
    //         this.setState({ isOverflowing }, () => {
    //             this._updatingOverflow = false;
    //         });
    //     }
    // };

    memoizedResources = memoize((resources: CalResource[], accessors: CalendarContextAccessorsFns) =>
        Resources(resources, accessors)
    );
}
