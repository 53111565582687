import { css } from 'twin.macro';
import { ThemeInterface } from './theme.interface';

export const generateCssVarsMap = (obj: ThemeInterface) => {
    // console.log('Generating CSS vars map for theme', obj.themeName);

    const fieldExceptions = {
        primary_25: 'primary-25',
    };

    const camelToSnakeCase = (str) => {
        if (fieldExceptions[str]) {
            return fieldExceptions[str];
        }
        return str.replace(/[A-Z0-9]/g, (letter) => `-${letter.toLowerCase()}`);
    };

    function flattenObj(obj, parent, res = {}) {
        for (let key in obj) {
            let propName = parent ? parent + '-' + camelToSnakeCase(key) : key;
            if (typeof obj[key] == 'object') {
                flattenObj(obj[key], propName, res);
            } else {
                res[propName] = obj[key];
            }
        }
        return res;
    }

    const varMap = flattenObj(obj, '--ez');
    // Custom variable. Used in semantic css.
    varMap['--ez-font-size-base'] = obj?.text?.size?.base;
    varMap['--ez-border-radius-default'] = obj?.border?.radius;
    return varMap;
};

// These variables are used in Fomantic CSS, Tailwind css and EZ Components
export const getThemeCssVars = () => {
    return css`
        :root {
            ${(props) => {
                const vars = generateCssVarsMap(props.theme);
                let cssVars = '';
                for (const key in vars) {
                    cssVars += `${key}: ${vars[key]};\n`;
                }
                return cssVars;
            }}
`;

    return css`
        :root {
            --ez-font-size-base: ${(props) => props.theme?.text?.size?.base || '14px'};

            --ez-color-primary: ${(props) => props.theme?.color?.primary};
            --ez-color-primary-25: ${(props) => props.theme?.color?.primary_25};
            --ez-color-primary-foreground: ${(props) => props.theme?.color?.primaryForeground};

            --ez-color-secondary: ${(props) => props.theme?.color?.secondary};
            --ez-color-secondary-foreground: ${(props) => props.theme?.color?.secondaryForeground};

            --ez-color-tertiary: ${(props) => props.theme?.color?.tertiary};
            --ez-color-tertiary-foreground: ${(props) => props.theme?.color?.tertiaryForeground};

            --ez-color-text-primary: ${(props) => props.theme?.text?.color?.primary};
            --ez-color-text-secondary: ${(props) => props.theme?.text?.color?.secondary};
            --ez-color-text-tertiary: ${(props) => props.theme?.text?.color?.tertiary};
            --ez-color-background: ${(props) => props.theme?.main?.backgroundColor || '#f5f5f5'};

            --ez-border-radius-sm: ${(props) => props.theme?.border?.radiusSm};
            --ez-border-radius-default: ${(props) => props.theme?.border?.radius};
            --ez-border-radius-md: ${(props) => props.theme?.border?.radiusMd};
            --ez-border-radius-lg: ${(props) => props.theme?.border?.radiusLg};
            --ez-border-radius-xl: ${(props) => props.theme?.border?.radiusXl};
            --ez-border-radius-2xl: ${(props) => props.theme?.border?.radius2xl};
            --ez-border-radius-3xl: ${(props) => props.theme?.border?.radius3xl};
            --ez-border-color: ${(props) => props.theme?.border?.color || '#f5f5f5'};
            --ez-border-color-strong: ${(props) => props.theme?.border?.colorStrong};

            --ez-panel-border-color: ${(props) =>
                props?.theme?.panel?.borderColor || props.theme?.border?.color || '#f5f5f5'};

            /* Navbar */
            --ez-navbar-background-color: ${(props) => props.theme?.navbar?.backgroundColor};
            --ez-navbar-logo-background-color: ${(props) => props.theme?.navbar?.logo?.backgroundColor};
            --ez-navbar-logo-text-color: ${(props) => props.theme?.navbar?.logo?.textColor};
            --ez-navbar-separator-color: ${(props) => props.theme?.navbar?.separatorColor};
            --ez-navbar-menu-item-background-color: ${(props) => props.theme?.navbar?.menuItem?.backgroundColor};
            --ez-navbar-menu-item-background-color-hover: ${(props) =>
                props.theme?.navbar?.menuItem?.backgroundColorHover};
            --ez-navbar-menu-item-background-color-active: ${(props) =>
                props.theme?.navbar?.menuItem?.backgroundColorActive};
            --ez-navbar-menu-item-text-color: ${(props) => props.theme?.navbar?.menuItem?.textColor};

            /* Sidebar */
            --ez-sidebar-background-color: ${(props) => props.theme?.sidebar?.backgroundColor};
            --ez-sidebar-shadow: ${(props) => props.theme?.sidebar?.shadow};
            --ez-sidebar-menu-group-separator-color: ${(props) => props.theme?.sidebar?.menuGroup?.separatorColor};
            --ez-sidebar-menu-group-padding: ${(props) => props.theme?.sidebar?.menuGroup?.padding};

            /* Sidebar Menu Item */
            --ez-sidebar-menu-item-text-color: ${(props) => props.theme?.sidebar?.menuItem?.textColor};

            --ez-sidebar-menu-item-height: ${(props) => props.theme?.sidebar?.menuItem?.height};
            --ez-sidebar-menu-item-background-color: ${(props) =>
                props.theme?.sidebar?.menuItem?.container?.backgroundColor};
            --ez-sidebar-menu-item-padding: ${(props) => props.theme?.sidebar?.menuItem?.container?.padding};
            --ez-sidebar-menu-item-border-left: ${(props) => props.theme?.sidebar?.menuItem?.container?.border?.left};
            --ez-sidebar-menu-item-border-right: ${(props) => props.theme?.sidebar?.menuItem?.container?.border?.right};
            --ez-sidebar-menu-item-border-radius: ${(props) =>
                props.theme?.sidebar?.menuItem?.container?.border?.radius};
            --ez-sidebar-menu-item-background-color-hover: ${(props) =>
                props.theme?.sidebar?.menuItem?.container?.backgroundColorHover};
            --ez-sidebar-menu-item-background-color-active: ${(props) =>
                props.theme?.sidebar?.menuItem?.container?.backgroundColorActive};

            --ez-sidebar-menu-item-ascent-color-active: ${(props) =>
                props.theme?.sidebar?.menuItem?.container?.ascentColorActive};

            --ez-sidebar-menu-item-content-padding: ${(props) => props.theme?.sidebar?.menuItem?.content?.padding};
            --ez-sidebar-menu-item-content-border-radius: ${(props) =>
                props.theme?.sidebar?.menuItem?.content?.border?.radius};
            --ez-sidebar-menu-item-content-border-left: ${(props) =>
                props.theme?.sidebar?.menuItem?.content?.border?.left};
            --ez-sidebar-menu-item-content-border-right: ${(props) =>
                props.theme?.sidebar?.menuItem?.content?.border?.right};
            --ez-sidebar-menu-item-content-background-color-active: ${(props) =>
                props.theme?.sidebar?.menuItem?.content?.backgroundColorActive};
            --ez-sidebar-menu-item-content-background-color-hover: ${(props) =>
                props.theme?.sidebar?.menuItem?.content?.backgroundColorHover};
            --ez-sidebar-menu-item-content-ascent-color: ${(props) =>
                props.theme?.sidebar?.menuItem?.content?.ascentColorActive};

        }
    }`;
};
