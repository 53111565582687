import * as React from 'react';
import { PrintConstants, pad } from '../constants';
import styled from 'styled-components';

// PrintSection's sub-component

export const PrintSectionHeader = styled.div`
    border-radius: ${PrintConstants.radius} ${PrintConstants.radius} 0 0;
    border: 1px solid ${PrintConstants.borderColor};
    margin: 0 0 0 0;
    padding: ${pad(1)};
    font-weight: bold;
    flex-direction: row;
    font-size: 1.3em;
    background-color: #eae9e9 !important;
    flex-grow: 1;
`;

export const PrintSectionBody = styled.div`
    border: 1px solid ${PrintConstants.borderColor};
    //border-radius: 0 0 ${PrintConstants.radius} ${PrintConstants.radius};
    margin: -1px 0 0 0;
    display: flex;
    flex-direction: row;
`;

export const PrintSectionBodyRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const PrintSectionColumn = styled.div<{ paddingVal?: number | number[] }>`
    :not(:first-child) {
        border-left: dashed 1px ${PrintConstants.borderColorLight};
        padding-left: ${pad(2)};
    }
    padding: ${({ paddingVal }) => {
        return pad(paddingVal !== undefined ? paddingVal : 1);
    }};
    flex: 1 1 0;
`;

export const PrintSectionBodySection = styled.div`
    border: none; //1px solid #3a3a3a;
    padding: 0;
    :not(:last-child) {
        margin-bottom: ${pad(1)};
    }
`;

const SectionItem = styled.div`
    //padding: ${pad(0)} ${pad(1)};
    line-height: 1.2em;
    display: flex;
    flex-wrap: wrap;
    column-gap: ${pad(1)};
`;

const SectionItemLabel = styled.div<{ minWidth?: string }>`
    flex: 0 0 auto;
    font-weight: bold;
    font-size: 0.8em;
    text-transform: uppercase;
    padding-right: 0.25em;
    min-width: ${({ minWidth }) => minWidth || '2.8cm'};
`;

const SectionItemContent = styled.div`
    flex: 1 1 auto;
`;

interface PrintSectionItemProps {
    label: string;
    labelWidth?: string;
    content?: any;
}
export const PrintSectionItem: React.FC<PrintSectionItemProps> = ({ label, labelWidth, content, children }) => {
    return (
        <SectionItem>
            <SectionItemLabel minWidth={labelWidth}>{label}</SectionItemLabel>
            <SectionItemContent>
                {content}
                {children}
            </SectionItemContent>
        </SectionItem>
    );
};
