import * as React from 'react';
import * as MenubarPrimitive from '@radix-ui/react-menubar';
import { clsxm } from '../utils';
import { PopupProps, SemanticCOLORS } from 'semantic-ui-react';
import { confirmModalImperative, ConfirmProps } from '../ConfirmModal';
import { ChevronDownIcon } from 'lucide-react';
import { resolveIconComponent } from '../Icon';

export interface MenuBarDropdownProps {
    collapsed?: boolean;
    icon?: React.ReactNode;
    content?: React.ReactNode;
    disabled?: boolean;
    responsive?: boolean;
    direction?: 'left' | 'right';
    className?: string;

    // TODO: deprecated
    color?: any;
    // title?: React.ReactNode;
}

export const MenuBarDropdown: React.FC<MenuBarDropdownProps> = ({
    collapsed,
    icon,
    content,
    disabled,
    children,
    responsive = true,
    className,
    color,
}) => {
    const IconComp = resolveIconComponent(icon, color);

    return (
        <MenubarPrimitive.Menu>
            <MenubarPrimitive.Trigger disabled={disabled} className={clsxm(['ez-menubar-item-base', className])}>
                <span className={'mr-1'}>{IconComp}</span>
                {!collapsed && (
                    <>
                        <span className={clsxm(responsive && 'hidden md:inline')}>{content}</span>
                    </>
                )}
                <ChevronDownIcon />
            </MenubarPrimitive.Trigger>
            <MenubarPrimitive.Portal>
                <MenubarPrimitive.Content
                    className={clsxm([
                        'z-[1000] min-w-[8rem] overflow-hidden rounded border p-1',
                        'flex flex-col gap-1',
                        'bg-popover text-popover-foreground shadow-md',
                        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
                        'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                    ])}
                    align="start"
                    sideOffset={8}
                    alignOffset={0}
                >
                    {children}
                </MenubarPrimitive.Content>
            </MenubarPrimitive.Portal>
        </MenubarPrimitive.Menu>
    );
};

export interface MenuBarDropdownItemProps
    extends Omit<React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Item>, 'title' | 'content'> {
    color?: SemanticCOLORS;
    icon?: React.ReactNode;
    position?;
    content?: React.ReactNode;
    onClick?;
    popup?: PopupProps;
    loading?: boolean;
    disabled?: boolean;
    responsive?: boolean;
    solid?: boolean;
    // Default lock reason is "No Permission"
    // Provide string value to set custom lock reason.
    locked?: boolean | string;

    // HACK
    // TODO: deprecated
    // title?: React.ReactNode;
    // name?: string;
}

export const MenuBarDropdownItem = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Item>,
    MenuBarDropdownItemProps
>(({ asChild, className, icon, children, content, popup, ...props }, ref) => {
    // TODO: implement popup

    const IconComp = resolveIconComponent(icon);
    return (
        <MenubarPrimitive.Item
            className={clsxm([
                'focusable relative flex cursor-pointer select-none items-center rounded-sm px-4 py-2 text-base outline-none transition-colors',
                'focus:bg-accent focus:text-accent-foreground',
                'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                className,
            ])}
            ref={ref}
            asChild={asChild}
            {...props}
        >
            {asChild ? (
                <>{children}</>
            ) : (
                <>
                    <span>{IconComp}</span>
                    {content}
                    {children}
                </>
            )}
        </MenubarPrimitive.Item>
    );
});

export interface MenuBarDropdownItemWithConfirmProps extends MenuBarDropdownItemProps {
    confirm?: ConfirmProps;
    onCancel?: () => void;
}

export const MenuBarDropdownItemWithConfirm: React.FC<MenuBarDropdownItemWithConfirmProps> = ({
    confirm,
    onCancel,
    onClick,
    ...rest
}) => {
    const handleClick = async () => {
        if (confirm) {
            const res = await confirmModalImperative(confirm);
            return res ? onClick?.() : onCancel?.();
        } else {
            return onClick?.();
        }
    };

    return <MenuBarDropdownItem {...rest} onClick={handleClick} />;
};
