import * as React from 'react';
import { compose } from '@ez/tools';
import { ConfirmButton, ConnectionTableDef, DefaultConnectionTable, toastError } from '@ez/components';
import { IAppNavigatorProps, ModuleLink } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { Icon } from '@ez/components';
import { QueryBrandConnection } from '../../../queries/QueryBrandConnection';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';

class BrandsTable extends React.Component<PageControlProps> {
    onDelete = async (brand: NodeType.Brand) => {
        try {
            await this.props.mutateBrand.delete({ id: brand.id });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    render() {
        const { companyId } = this.props;

        const tableDef: ConnectionTableDef<NodeType.Brand> = [
            {
                header: 'Brand Name',
                sortKey: 'name',
                cell: (brand) => {
                    if (companyId) {
                        return <ModuleLink to={`/${companyId}/brands/${brand.id}`}>{brand.name}</ModuleLink>;
                    } else {
                        return <ModuleLink to={`/brands/${brand.id}`}>{brand.name}</ModuleLink>;
                    }
                },
            },
            {
                header: 'Visibility',
                cellProps: {
                    width: 3,
                },
                cell: (brand) => {
                    const { franchise } = brand;
                    if (franchise) {
                        return (
                            <div>
                                <Icon name={'building'} />
                                {franchise.name}
                            </div>
                        );
                    } else {
                        return 'Global';
                    }
                },
            },
            {
                header: '',
                cellProps: {
                    textAlign: 'center',
                    width: 1,
                },
                cell: (brand) => {
                    return (
                        <ConfirmButton
                            icon={'trash'}
                            onClick={() => this.onDelete(brand)}
                            confirmMessage={{
                                header: 'Delete Brand?',
                            }}
                            confirmButton={{
                                negative: true,
                                icon: 'trash',
                                content: 'Delete',
                            }}
                        />
                    );
                },
            },
        ];

        return (
            <QueryBrandConnection companyId={companyId} pageSize={20}>
                {({ connectionData, connectionState }) => {
                    return (
                        <DefaultConnectionTable
                            tableProps={{ color: 'teal' }}
                            connectionData={connectionData}
                            connectionState={connectionState}
                            tableDef={tableDef}
                            noDataComponent={<span>-</span>}
                        />
                    );
                }}
            </QueryBrandConnection>
        );
    }
}

export default compose(withProductCatalogMutators(['BrandsList']))(BrandsTable);

interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {
    companyId: NodeType.ID;
}
