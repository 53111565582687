import * as React from 'react';
import { useServiceJobTemplateListActions } from './reducer';
import { ActionBar, VStack } from '@ez/components';
import { ServiceJobGroupsMenuPanel } from '../../ServiceJobGroup/ServiceGroupsMenuPanel';

export const SideBar: React.FC = () => {
    const { State, Actions } = useServiceJobTemplateListActions();

    return (
        <VStack>
            <ServiceJobGroupsMenuPanel
                selectedGroupId={State.filters.serviceGroupId}
                onSelectChange={Actions.setServiceJobGroup}
            />
            <ActionBar.ItemCheckbox
                checked={State.filters?.includeArchived}
                onChange={(checked) => Actions.setIncludeArchived(checked)}
                content={<small style={{ color: '#686868' }}>Show archived templates</small>}
            />
        </VStack>
    );
};
