import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { NodeType, useQueryStaffConnection } from '@poolware/api';
import { useWindowSize } from '@ez/tools';
import { toastError } from '@ez/components';
import { useViewer } from '@poolware/app-shell';
import { ACTION_NOTIFICATION, DnDInteractionInfoType } from '@poolware/react-big-calendar';
import CalendarView from './CalendarView';
import { CalendarZoomFactor, useCalendarActions } from '../../redux';
import { convertAppointmentItemsToCalendarEvents } from './convert-appointment-items-to-events';
import CalendarInfo from './CalendarInfo';
import { useQueryAppointmentItemsConnection } from '../Queries';
import { AppointmentsListQuery, QueryAppointmentItemsMonth } from '../Queries/query-appointment-items-calendar';
import { UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import { CalViewMode } from '../types';
import { useCalendarPageContext } from '../utils/CalendarPageContext';
import { PAGE_BOTTOM_OFFSET_CAL } from '../Pages/CalendarPageLayoutManager';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';
import { computeStreetSummaries } from './street-summaries';
import { useAppointmentViewCtx } from '../AppointmentDock';
import { CalendarEventType } from './types';
import moment from 'moment';
import { createGlobalStyle } from 'styled-components';
import { CalendarStyledContainer } from './CalendarStyledContainer';
// import './calendar.scss'

export const CalendarStyle = createGlobalStyle<{ cellMinHeight: string }>`
    :root {
        --calendar-cell-min-height: ${(props) => props.cellMinHeight};
    }
`;

const getCellMinHeight = (zoomFactor: CalendarZoomFactor): string => {
    const map = {
        1: '3.5vh',
        2: '4.5vh',
        3: '6vh',
        4: '7vh',
        5: '8vh',
        6: '9.5vh',
        7: '11vh',
        8: '12.5vh',
    };
    return map[zoomFactor] || map[3];
};

export type SlotInfoType = { startDate: Date; endDate: Date; resourceId: string; action: ACTION_NOTIFICATION };

interface ExternalProps {
    onSelectEvent: (slot: any, event: any) => any | Promise<any>;
    onSelectSlot: (slot: SlotInfoType) => any | Promise<any>;
    onClickPoint: (slot: SlotInfoType) => any | Promise<any>;
    onDropFromOutsideEvent: (event: DnDInteractionInfoType) => any | Promise<any>;
    lockEvents: boolean;
    onAppointmentItemEdit: (item: NodeType.AppointmentItem) => any | Promise<any>;
}

interface CalendarViewControlProps extends ExternalProps {
    staffConnection;
    appointmentsConnection;
}

const CalendarViewControl: React.FC<CalendarViewControlProps> = (props) => {
    const { innerHeight } = useWindowSize();
    const { modulesAccess } = useViewer();
    const { getDragFromOutsideItem, setAreasSummary, setStreetsSummary } = useCalendarPageContext();
    const { CalendarAction, CalendarState } = useCalendarActions();
    const { activeDate, viewMode, layoutMode } = CalendarState;

    const CalLayoutState = useCalendarLayoutState();

    const canDnD = !modulesAccess.FieldServices?.calendarSingleStaffMode;
    const isDraggable = canDnD && viewMode !== CalViewMode.MONTH && viewMode !== CalViewMode.AGENDA;
    const isResizable = canDnD && CalendarState.viewMode !== CalViewMode.MONTH;
    const isSelectable = CalendarState.viewMode !== CalViewMode.MONTH;

    const staffConnection = props.staffConnection;
    const { connectionData, connectionState } = props.appointmentsConnection;

    const previewItemCtx = useAppointmentViewCtx();

    const { events, resources } = useMemo(() => {
        let staffList = staffConnection.connectionState.loading ? [] : staffConnection.connectionData;
        const { events, resources, areaSummary } = convertAppointmentItemsToCalendarEvents({
            appointmentItems: connectionData,
            staffList: staffList,
            CalendarState,
            modulesAccess,
            previewApptId: previewItemCtx.appointmentItemId,
        });
        return { events, resources, areaSummary };
    }, [
        connectionData,
        CalendarState,
        previewItemCtx.appointmentItemId,
        staffConnection.connectionState.loading,
        modulesAccess.FieldServices,
    ]);

    const computeStreets = modulesAccess.FieldServices?.calendarStreetsSummary && CalLayoutState.isDockOpen;
    useEffect(() => {
        if (!computeStreets) {
            return;
        }
        const { areaSummary, streetsSummary } = computeStreetSummaries(connectionData);
        setAreasSummary(areaSummary);
        setStreetsSummary(streetsSummary);
    }, [connectionData, computeStreets]);

    const { isPolling, loading: isLoading } = connectionState;
    const isUpdating = isPolling || isLoading;

    // ======

    const onNavigate = (newActiveDate) => {
        CalendarAction.setCalendar({ activeDate: newActiveDate });
    };

    const setCalendarViewMode = (newViewMode: CalViewMode) => {
        CalendarAction.setCalendar({ viewMode: newViewMode });
    };

    const handleSelectSlot = async (slot) => {
        // const cb = () => {
        const { viewMode } = CalendarState;

        let { start, end, action, resourceId } = slot;

        resourceId = resourceId === UNASSIGNED_APPOINTMENT_STAFF_ID ? null : resourceId;

        if (viewMode === CalViewMode.WEEK || viewMode === CalViewMode.DAY) {
            // Ignore double click in month view

            if (action === ACTION_NOTIFICATION.doubleClick) {
                // On double click event, ignore end date provided by calendar and use 1 hour duration;
                await props.onSelectSlot({ startDate: start, endDate: end, resourceId: resourceId, action });
                return;
            }

            if (action === ACTION_NOTIFICATION.click) {
                await props.onClickPoint?.(slot);
                return;
            }
        }

        if (action === ACTION_NOTIFICATION.selected) {
            const rangeDuration = moment(end).diff(moment(start));
            if (rangeDuration > 60000 /* 1 min */) {
                await props.onSelectSlot({ startDate: start, endDate: end, resourceId: resourceId, action });
            } else {
                // console.warn("range is to short", rangeDuration);
            }
            return;
        }
        // };

        // HACK: BigCalendar issues a warning when the page navigates to fast.
        // As a workaround wait for a few ticks
        // setTimeout(cb, 1);
    };

    const handleSelectEvent = (event, e) => {
        const { lockEvents } = props;
        if (lockEvents === false) {
            return;
        }

        if (props.onSelectEvent) {
            props.onSelectEvent(event, e?.target);
        }
    };

    const onEventResize = async (e: DnDInteractionInfoType) => {
        const { start, end } = e;
        const event = e.event as unknown as CalendarEventType;
        let duration = moment(end).diff(start, 'minutes');
        if (duration < 1) {
            // Min allowed duration is 15 min.
            duration = 15;
        }
        try {
            return await previewItemCtx.resizeAppointment({
                item: event.item,
                startDate: start,
                duration: duration,
                invalidateId: event.selected,
            });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update appointment', description: e.message });
        }
    };

    const onEventMove = async (e: DnDInteractionInfoType) => {
        const { start, end, resourceId } = e;
        const event = e.event as unknown as CalendarEventType;

        const duration = moment(end).diff(start, 'minutes');
        try {
            return await previewItemCtx.moveAppointment({
                item: event.item,
                startDate: start,
                duration: duration,
                resourceId,
                invalidateId: event.selected,
            });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update appointment', description: e.message });
        }
    };

    const height = innerHeight - PAGE_BOTTOM_OFFSET_CAL;
    const cellMinHeight = getCellMinHeight(CalendarState.zoomFactor);

    return (
        <CalendarStyledContainer className="pw-scheduler" style={{ height: height }}>
            <CalendarStyle cellMinHeight={cellMinHeight} />
            <CalendarView
                events={events}
                resources={resources}
                date={activeDate}
                view={viewMode}
                layoutMode={layoutMode}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                onView={setCalendarViewMode}
                onNavigate={onNavigate}
                onEventResize={onEventResize}
                onEventMove={onEventMove}
                dragFromOutsideItem={getDragFromOutsideItem}
                onDropFromOutside={props.onDropFromOutsideEvent}
                // onDragOver={customOnDragOver}
                resizable={isResizable}
                draggable={isDraggable}
                selectable={isSelectable}
            />
            <CalendarInfo isLoading={isUpdating} />
        </CalendarStyledContainer>
    );
};

const CalendarViewDataControl: React.FC<ExternalProps> = (props) => {
    const { viewer, modulesAccess } = useViewer();
    const { CalendarState } = useCalendarActions();
    const viewerFranchiseId = viewer.franchise?.id;
    const calendarFranchiseId = CalendarState.filters?.franchise?.id;
    const franchiseId = calendarFranchiseId || viewerFranchiseId;
    let shouldSkip = !franchiseId;
    const isSingleStaffMode = modulesAccess.FieldServices?.calendarSingleStaffMode;
    const staffId = isSingleStaffMode ? viewer.me?.staff?.id : undefined;

    const staffConnection = useQueryStaffConnection({
        fetchPolicy: 'cache-first',
        franchiseId: franchiseId,
        showDisabled: false,
        showDeleted: false,
        defaultPageSize: 100,
    });

    const query = CalendarState.viewMode === CalViewMode.MONTH ? QueryAppointmentItemsMonth : AppointmentsListQuery;

    const appointmentsConnection = useQueryAppointmentItemsConnection({
        query: query,
        skip: shouldSkip,
        dateRange: CalendarState.displayingDateRange,
        franchiseId: franchiseId,
        staffId: staffId,
        filters: CalendarState.filters,
    });

    return (
        <CalendarViewControl
            staffConnection={staffConnection}
            appointmentsConnection={appointmentsConnection}
            {...props}
        />
    );
};

export default CalendarViewDataControl;
