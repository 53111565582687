import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import { NodeType } from '@poolware/api';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { FormikDefaultForm, FormikInputField, PageLayout, toastError, withApolloLoading } from '@ez/components';
import * as _ from 'lodash';

import * as Yup from 'yup';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../queries';
import { useViewer } from '@poolware/app-shell';

const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});

const New: React.FC<PageControlProps> = (props) => {
    const { isAdmin } = useViewer();
    const onSubmit = async (values) => {
        try {
            await props.mutateBrand.create({
                name: values.name,
                identification: values.identification,
                company: {
                    id: props.company.id,
                },
                franchise: isAdmin ? null : undefined,
            });
            props.AppNavigator.replaceToOrigin();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onCancel = () => {
        props.AppNavigator.replaceToOrigin();
    };

    const initialValues = { name: '' };
    return (
        <PageLayout.BodySection width={'screen-lg'}>
            <FormikDefaultForm
                header={`New Brand (${props.company.name})`}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
            >
                <FormikInputField label={'Brand Name'} name={'name'} required={true} />
            </FormikDefaultForm>
        </PageLayout.BodySection>
    );
};

export interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {
    company: NodeType.ProductCompany;
}

const QL = gql`
    query ProductCompany($id: ID!) {
        node(id: $id) {
            ... on ProductCompany {
                id
                name
                createdAt
                updatedAt
                franchise {
                    id
                    name
                }
                description
                parentCompany {
                    id
                    name
                }
            }
        }
    }
`;
export default compose(
    withAppNavigator(),
    graphql(QL, {
        options: (props: IAppNavigatorProps) => {
            return {
                variables: { id: props.query.companyId },
            };
        },
    }),
    withApolloLoading(),
    mapProps((props) => {
        const company = _.get(props, 'data.node');
        return {
            ...props,
            company,
        };
    }),
    withProductCatalogMutators(['ProductCompany', 'ProductCompanyDetails', 'ListBrands_for_company'])
)(New);
