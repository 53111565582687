import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import { NodeType, TraitValueType } from '@poolware/api';
import { EmptyBoxImage, Icon, withApolloLoading } from '@ez/components';
import { FormBuilder, FormBuilderConfig } from '../../../components/FormBuilder';
import * as _ from 'lodash';
import * as Yup from 'yup';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { flattenTraitDeclaration, ProductTraitFlattened } from '../../../utils';
import { ProductTraitInputFields } from './ProductTraitInputFields';
import { queryNames } from '../query-names';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';
import { SectionHeader } from '@ez/components';

const StyledEmptyBoxImgContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledEmptyBoxImg = () => (
    <StyledEmptyBoxImgContainer>
        <div>
            <EmptyBoxImage />
        </div>
        <div>
            <small>
                {' '}
                <Icon name={'arrow left'} /> Select Declaration
            </small>
        </div>
    </StyledEmptyBoxImgContainer>
);

const formConfig: FormBuilderConfig = {
    fields: [],
    validationSchema: Yup.object().shape({
        // product: Yup.string().required('Required'),
        declaration: Yup.string().required('Required'),
    }),
};

export interface ExternalProps {
    selectedTraitDeclarationId: string;
    products: NodeType.Product[];
    onSubmit: () => any;
    onCancel: () => any;
}

interface NewProductTraitFormProps extends IProductCatalogMutators, ExternalProps {
    traitDeclaration: NodeType.ProductTraitDeclaration;
}

class NewProductTraitForm extends React.Component<NewProductTraitFormProps, any> {
    createSingleMutationConfig = (values, product) => {
        const { traits, declaration } = values;

        const allValues = (traits as ProductTraitFlattened[]).flatMap((ft) => {
            return ft.values.map((f) => {
                let value;

                switch (f.type) {
                    case TraitValueType.Int:
                    case TraitValueType.Float:
                        if (f.formValue === undefined) {
                            value = undefined;
                        } else if (f.formValue === '') {
                            value = null;
                        } else {
                            value = Number(f.formValue);
                        }
                        break;
                    case TraitValueType.String:
                        const trimmed = _.trim(f.formValue);
                        value = trimmed ? trimmed : null;
                        break;
                    case TraitValueType.Selection:
                        value = f.formValue ? f.formValue : null;
                        break;
                }

                return {
                    type: f.type,
                    fieldId: f.traitField.id,
                    valueId: f.traitValue ? f.traitValue.id : undefined,
                    value: value,
                };
            });
        });

        const toCreate = allValues.filter((f) => f.valueId === undefined && f.value);

        return {
            declaration,
            product,
            createValues: toCreate,
        };
    };

    onSubmit = async (values, actions) => {
        try {
            const { products } = this.props;
            const mutationConfigs = products.map((product) => this.createSingleMutationConfig(values, product));
            await this.props.ProductCatalogMutator.createProductTraits(mutationConfigs);
            await this.props.onSubmit?.();
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e.message });
        }
    };

    onCancel = () => {
        this.props.onCancel();
    };

    render() {
        const { traitDeclaration } = this.props;

        if (!traitDeclaration) return <StyledEmptyBoxImg />;

        const flattenedParentTraits = traitDeclaration.parentDeclarations.map(flattenTraitDeclaration).reverse();
        const flattenedTraits = flattenTraitDeclaration(traitDeclaration);
        flattenedParentTraits.push(flattenedTraits);

        const initialValues = {
            traits: flattenedParentTraits,
            declaration: traitDeclaration,
        };

        return (
            <FormBuilder
                debug={false}
                config={formConfig}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
            >
                {() => {
                    return (
                        <>
                            <SectionHeader size={'small'}>{traitDeclaration.name}</SectionHeader>
                            <ProductTraitInputFields name={`traits`} flattenedTraits={flattenedParentTraits} />
                            {/*<DebugPanel showDimmer={false} value={{ initialValues }} />*/}
                        </>
                    );
                }}
            </FormBuilder>
        );
    }
}

export const productTraitDeclarationFragment = gql`
    fragment ProductTraitDeclarationFragment_Catalog on ProductTraitDeclaration {
        id
        tag
        name
        integerFields {
            id
            name
            tag
            hint
            unit
            #            optional
            #            maxValue
            #            minValue
            #            searchOrder
            #            searchAsDistinct
        }
        floatFields {
            id
            name
            tag
            hint
            unit
            #            optional
            #            maxValue
            #            minValue
            #            searchOrder
            #            searchAsDistinct
        }
        stringFields {
            id
            name
            tag
            isHtml
            #            optional
            #            hint
            #            maxLength
            #            searchOrder
            #            searchAsDistinct
        }
        selectionFields {
            id
            name
            tag
            #            hint
            #            optional
            #            searchOrder
            #            searchAsDistinct
            #            tag
            options {
                id
                name
                priority
                #                description
            }
        }
    }
`;

export const traitDeclarationQL = gql`
    query ProductDeclarationDetails($id: ID!) {
        node(id: $id) {
            ...ProductTraitDeclarationFragment_Catalog
            ... on ProductTraitDeclaration {
                parentDeclarations {
                    id
                    name
                    ...ProductTraitDeclarationFragment_Catalog
                }
            }
        }
    }
    ${productTraitDeclarationFragment}
`;

export default compose(
    graphql(traitDeclarationQL, {
        options: (props: any) => {
            return {
                variables: { id: props.selectedTraitDeclarationId },
            };
        },
        skip: (props) => !props.selectedTraitDeclarationId,
    }),
    withApolloLoading(),
    mapProps((props) => {
        const { data, ...rest } = props;
        const traitDeclaration = _.get(props, 'data.node');
        return {
            ...rest,
            traitDeclaration,
        };
    }),
    withProductCatalogMutators([...queryNames, 'ProductDetails_traitsTable'])
)(NewProductTraitForm) as React.ComponentType<ExternalProps>;
