import * as React from 'react';
import { FullScreen } from '../FullScreen';
import styled from 'styled-components';
import { useAppBreakpoints } from '../../use-app-breakpoints';

const ContainerGridDiv = styled.div<{ templateColumns?: string }>`
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: ${(props) => props.templateColumns || '300px 1fr'};
    grid-template-areas: 'sidebar main';
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    grid-template-rows: auto;
    @media print {
        grid-template-columns: minmax(0, 1fr);
        grid-template-areas: 'main';
    }
`;

const GridAreaSidebarDiv = styled.div`
    grid-area: sidebar;
    @media print {
        display: none;
    }
`;
const GridAreaMainDiv = styled.div`
    grid-area: main;
`;

export const SideBarLayout: React.FC<{
    leftShow?: boolean;
    leftSidebar?: React.ReactNode;
    leftWidth?: number;
    leftOnClose?: () => any;
    main: React.ReactNode;
}> = ({ leftSidebar, main, leftShow = true, leftOnClose, leftWidth = 230 }) => {
    if (!leftShow) {
        return <>{main}</>;
    }
    const bp = useAppBreakpoints();

    let gridTemplateColumns = 'minmax(0, 1fr)';
    if (leftShow && leftSidebar) {
        gridTemplateColumns = `${leftWidth}px ` + gridTemplateColumns;
    }

    if (bp.gteTablet || !leftOnClose) {
        return (
            <ContainerGridDiv templateColumns={gridTemplateColumns}>
                {leftShow && <GridAreaSidebarDiv>{leftSidebar}</GridAreaSidebarDiv>}
                <GridAreaMainDiv>{main}</GridAreaMainDiv>
            </ContainerGridDiv>
        );
    } else {
        return (
            <>
                <FullScreen hideOnPrint={true} open={leftShow} onClose={leftOnClose}>
                    {leftSidebar}
                </FullScreen>
                <div>{main}</div>
            </>
        );
    }
};
