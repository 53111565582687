import * as React from 'react';
import { FieldArray, getIn } from 'formik';
import * as _ from 'lodash';
import { Alert, FormikInputField, Segment } from '@ez/components';
import { Button, SectionHeader } from '@ez/components';

const getArrayFieldError = (form, name: string, index: number) => {
    if (typeof form.errors[name] === 'string') {
        return null;
    }

    const fieldName = `${name}[${index}]`;
    const error = getIn(form.errors, fieldName);
    const touch = getIn(form.touched, fieldName);
    return touch && error ? error : null;
};

const SelectionTypeFormFieldComponent: React.FC<{ name: string }> = ({ name }) => {
    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const errors = form.errors[name];
                const rootError = typeof errors === 'string' ? errors : null;
                const options = _.get(form.values, name);
                return (
                    <Segment>
                        <SectionHeader size={'small'}>Options</SectionHeader>
                        {options.map((option, index) => {
                            const fieldName = `${name}[${index}].name`;
                            return (
                                <FormikInputField key={index} name={fieldName} type={'text'}>
                                    <label>{index}</label>
                                    <input />
                                    {index !== 0 && (
                                        <Button
                                            type="button"
                                            disabled={index === 0}
                                            basic
                                            onClick={() => arrayHelpers.move(index, index - 1)}
                                            icon={'chevron up'}
                                        />
                                    )}
                                    {index !== options.length - 1 && (
                                        <Button
                                            type="button"
                                            basic
                                            disabled={index === options.length - 1}
                                            onClick={() => arrayHelpers.move(index, index + 1)}
                                            icon={'chevron down'}
                                        />
                                    )}
                                    <Button
                                        type="button"
                                        basic
                                        onClick={() => arrayHelpers.remove(index)}
                                        icon={'trash'}
                                    />
                                </FormikInputField>
                            );
                        })}

                        {rootError && <Alert type={'error'}>{errors}</Alert>}

                        <Button onClick={() => arrayHelpers.push('')}>Add Option</Button>
                    </Segment>
                );
            }}
        />
    );
};

export default SelectionTypeFormFieldComponent;
