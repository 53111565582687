import * as React from 'react';
import { useMemo } from 'react';
import { AppSwitcherMenuItems, DropdownMenuAccount, useViewer } from '@poolware/app-shell';
import * as URLBuilder from '../routes/url-builder';
import { IconServiceCall } from '../components/AppIcons';
import { useServiceJobActions } from '@poolware/app-service-jobs';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Icon, LayoutControlProps, Navbar } from '@ez/components';

interface Props extends LayoutControlProps {}

const AppNavbar: React.FC<Props> = (props) => {
    const { ServiceJobAction } = useServiceJobActions();
    const { isAuthenticated } = useViewer();
    const { AppNavigator, location } = useAppNavigator();
    const { modulesAccess } = useViewer();

    const onSearch = () => {
        AppNavigator.navigate(URLBuilder.Search.search);
    };

    const onServiceCase = () => {
        ServiceJobAction.newServiceJob({
            returnTo: location,
        });
    };

    const onNewCustomer = () => {
        AppNavigator.navigate(URLBuilder.Customers.new);
    };

    const onNewWarrantyClaim = () => {
        AppNavigator.navigate(URLBuilder.WarrantyClaim().new);
    };

    const onNewAppointment = () => {
        AppNavigator.navigate(URLBuilder.Scheduler.new, { modal: true });
    };

    const createDropdownItem = (input: { onClick: any; icon?: React.ReactElement; label: string }, index) => {
        return (
            <Navbar.DropdownMenuItem //
                onClick={input.onClick}
                key={index}
                icon={input.icon}
                content={input.label}
            />
        );
    };

    const plusItems = useMemo(() => {
        const items = [
            modulesAccess.FieldServices?.enabled && {
                icon: <IconServiceCall />,
                label: 'New Case',
                onClick: onServiceCase,
            },
            modulesAccess.Customers?.enabled && {
                icon: <Icon color={'blue'} name={'user'} />,
                label: 'New Customer',
                onClick: onNewCustomer,
            },
            modulesAccess.Warranties?.enabled && {
                icon: <Icon name={'certificate'} />,
                label: 'New Warranty Claim',
                onClick: onNewWarrantyClaim,
            },
        ];
        return items.filter(Boolean);
    }, [modulesAccess]);

    const renderPlusMenu = () => {
        if (plusItems.length === 0) return null;
        return (
            <Navbar.DropdownMenu icon={<Icon name={'plus'} />} label={'New'}>
                {plusItems.map(createDropdownItem)}
            </Navbar.DropdownMenu>
        );
    };

    const authenticatedNav = () => {
        return (
            <>
                <Navbar.MenuSection position="left">
                    <Navbar.MenuItem onClick={onSearch} icon={<Icon name={'search'} />} content={'Search'} />
                </Navbar.MenuSection>
                <Navbar.MenuSection key={'right'} position="right">
                    <AppSwitcherMenuItems />
                    {renderPlusMenu()}
                    <DropdownMenuAccount />
                </Navbar.MenuSection>
            </>
        );
    };

    const unauthenticatedNav = () => {
        return null;
    };

    return (
        <Navbar.Root {...props}>
            {isAuthenticated //
                ? authenticatedNav()
                : unauthenticatedNav()}
        </Navbar.Root>
    );
};

export default AppNavbar;
