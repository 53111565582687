import * as React from 'react';
import { useState } from 'react';
import { PoolVolumeCalculator } from '@poolware/app-calculators';
import { useField } from 'formik';
import { Button, FormikInputField, Popup } from '@ez/components';

interface PoolVolumeInputComponentProps {
    name: string;
    label: string;
    required?: boolean;
}

export const FormikPoolVolumeInputField: React.FC<PoolVolumeInputComponentProps> = ({ name, label, required }) => {
    const [showCalc, setShowCalc] = useState(false);
    const [field, meta, helpers] = useField<string>({ name: name });

    const onVolumeSubmit = (volume) => {
        helpers.setValue(volume);
        setShowCalc(false);
    };

    const style = {
        padding: '0',
        backgroundColor: '#ececeec',
    };

    return (
        <FormikInputField
            name={name}
            required={required}
            label={label}
            type={'number'}
            actionRight={
                <Popup
                    trigger={
                        <Button
                            segmentPosition={'last'}
                            className={'border-input h-input-base'}
                            onClick={() => setShowCalc(true)}
                            icon="calculator"
                        />
                    }
                    position="left center"
                    open={showCalc}
                    onClose={() => setShowCalc(false)}
                    size="mini"
                    on="click"
                    wide
                    style={style}
                    content={<PoolVolumeCalculator onVolumeSubmit={onVolumeSubmit} />}
                />
            }
        />
    );
};
