import * as React from 'react';
import { PageControl } from './Page.Control';
import { FormikDefaultForm, FormikTextareaField, toastError } from '@ez/components';

interface PageProps extends PageControl {}

class Page extends React.Component<PageProps, { isMutating: boolean }> {
    state = {
        isMutating: false,
    };

    onSubmit = async (values) => {
        try {
            const { site } = this.props;
            const { accessKeyLocation } = values;
            this.setState({ isMutating: true });
            await this.props.mutateSite.update({ id: site.id, accessKeyLocation: accessKeyLocation });
            this.setState({ isMutating: false });
            this.props.AppNavigator.navigateToOrigin();
        } catch (error) {
            console.error(error);
            this.setState({ isMutating: false });
            toastError(error);
        }
    };

    onDelete = async () => {
        try {
            this.setState({ isMutating: true });
            await this.props.mutateSite.delete({ id: this.props.site.id });
            this.setState({ isMutating: false });
            this.props.AppNavigator.replaceToOrigin();
        } catch (error) {
            this.setState({ isMutating: false });
            toastError(error);
        }
    };

    onCancel = () => {
        this.props.AppNavigator.navigateToOrigin();
    };

    render() {
        const initialValues = this.props.site;
        return (
            <FormikDefaultForm
                icon={'key'}
                header={'Edit Site Access Details'}
                initialValues={initialValues}
                submitButton={{ content: 'Update' }}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
            >
                <FormikTextareaField
                    rows={8}
                    label="Site Access Key"
                    placeholder={'e.g. Main gate access key ABC123. Beware of the dog.'}
                    name={'accessKeyLocation'}
                />
            </FormikDefaultForm>
        );
    }
}

export default Page;
