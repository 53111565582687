import * as React from 'react';
import { useMemo } from 'react';
import { NodeType } from '@poolware/api';
import { usePanelNavigationStack } from '../../Components/PanelNavigationStack';
import TimeFieldGroup from '../../AppointmentForm/FormFields/TimeField';
import { extractRecurrenceData, recurrenceToString } from '../../utils';
import {
    Alert,
    confirmModalImperative,
    FormikDefaultForm,
    Icon,
    MenuBar,
    Panel,
    ScrollableLayout,
    toastError,
} from '@ez/components';
import * as _ from 'lodash';
import { defaultRecurrence, getAppointmentChangeDiff } from '../../AppointmentForm/common';
import { PanelAppointmentDiff } from '../../AppointmentForm/PanelAppointmentDiff';
import { useAppointmentMutators } from '../../../queries/use-appointment-mutators';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import RecurrenceField, { validationSchemaRecurrencePattern } from '../../AppointmentForm/FormFields/RecurrenceField';
import * as Yup from 'yup';
import {
    RelatedAppointmentsMode,
    RelatedAppointmentsTable,
} from '../PanelRelatedAppointments/RelatedAppointmentsTable';
import { useAppointmentViewCtx } from '../AppointmentViewContext';

export interface AppointmentEditTimeProps {
    appointmentItem: NodeType.AppointmentItem;
    appointmentItemMutator: ReturnType<typeof useAppointmentMutators>;
}

const validationSchema = Yup.object().shape({
    startDate: Yup.date().required('Required').nullable(),
    duration: Yup.number().required('Required').nullable(),
    recurrence: validationSchemaRecurrencePattern.required('Required'),
});

type AppointmentFormValuesType = {
    startDate: Date;
    duration: number;
    recurrence: NodeType.Recurrence;
    appointmentItem: NodeType.AppointmentItem;
    isRecurrenceLocked: boolean;
};

export const AppointmentEditRecur: React.FC<AppointmentEditTimeProps> = ({ appointmentItem }) => {
    const panelStack = usePanelNavigationStack();
    const previewItemCtx = useAppointmentViewCtx();
    const { recurrence } = extractRecurrenceData(appointmentItem);

    const initialValues: Partial<AppointmentFormValuesType> = useMemo(
        () => ({
            appointmentItem: appointmentItem,
            recurrence: _.defaultsDeep(recurrence, defaultRecurrence),
            startDate: new Date(appointmentItem?.startDate),
            duration: appointmentItem.duration,
            isRecurrenceLocked: false,
        }),
        [appointmentItem]
    );

    const onCancel = () => {
        panelStack.popStackPanel();
    };

    const handleOnSubmit = async (values: AppointmentFormValuesType) => {
        try {
            const diff = getAppointmentChangeDiff(values, initialValues, true);
            if (_.isEmpty(diff)) {
                panelStack.popStackPanel();
                return;
            }

            const isYes = await confirmModalImperative({
                confirmMessage: {
                    header: 'Change Recurrence Pattern?',
                    content: <PanelAppointmentDiff diff={diff} />,
                },
                confirmButton: { content: 'Submit', negative: false },
                cancelButton: { content: 'Cancel' },
            });

            if (!isYes) {
                return;
            }

            const res = await previewItemCtx.appointmentItemMutator.AppointmentMutator.updateAppointmentItemRecurrence(
                values.appointmentItem,
                {
                    duration: values.duration,
                    startDate: values.startDate,
                    fromDate: appointmentItem.startDate,
                    recurrence: values.recurrence,
                }
            );
            previewItemCtx.refreshId(res.data?.AppointmentItem?.AppointmentItem?.id);
            panelStack.popStackPanel();
        } catch (error) {
            console.error(error);
            toastError(error);
        }
    };

    return (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar size={'tiny'}>
                <MenuBar.Section>
                    <MenuBar.Item onClick={onCancel} icon={'chevron left'} />
                    <MenuBar.HeaderItem>
                        <Icon color={ModuleColorNames.Appointment} name={ModuleIconNames.Appointment} />
                        {'Appointment'}
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>
            <ScrollableLayout.BodyScroll>
                <div className={'px-2 py-4 mb-4 space-y-4 text-sm'}>
                    <FormikDefaultForm
                        size={'small'}
                        enableReinitialize={false}
                        validationSchema={validationSchema}
                        header={'New Recurrence Pattern'}
                        icon={ModuleIconNames.Appointment}
                        initialValues={initialValues}
                        submitButton={{ content: 'Submit' }}
                        onSubmit={handleOnSubmit}
                        onCancel={onCancel}
                    >
                        <>
                            <Panel.SectionHeader>New Recurrence Pattern </Panel.SectionHeader>
                            <TimeFieldGroup monthsShown={2} showDay={true} size={'tiny'} />
                            <RecurrenceField compact={true} />
                        </>
                    </FormikDefaultForm>

                    <div className={'pt-6'}>
                        <Alert type={'info'}>
                            <p>All future appointments will be updated.</p>
                            <p></p>
                            <p>
                                Current recurrence pattern: <i>{recurrenceToString(recurrence)}</i>
                            </p>
                        </Alert>
                        <RelatedAppointmentsTable
                            appointmentItem={appointmentItem}
                            mode={RelatedAppointmentsMode.Future}
                        />
                    </div>
                </div>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};
