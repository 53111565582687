import * as React from 'react';
import {
    Button,
    ButtonWithPopup,
    ButtonWithPopupProps,
    Display,
    Form,
    FormGroup,
    FormikInputField,
    FormikSegmentedSelectButtons,
    FormSectionHeader,
    Icon,
    Modal,
    Optionable,
    Panel,
    toastError,
    useModalCtrl,
    VStack,
} from '@ez/components';
import { useMutationHeaterRecommendation } from '@poolware/api';
import { useFormikContext } from 'formik';
import { BrandingType, brandTypeToString, CalcDocType, FormikHeatCalcValueType } from './types';
import { serializeForm } from './state-serializer';
import { FormikCustomerLookupField, useViewer } from '@poolware/app-shell';
import { ModuleLink } from '@poolware/react-app-navigator';
import { CalcMode } from '../redux';

const SaveToCustomerForm: React.FC<{ onCancel }> = ({ onCancel }) => {
    const { values, setFieldValue, handleSubmit, isSubmitting } = useFormikContext<FormikHeatCalcValueType>();

    const headerButton = values.newCustomerMode
        ? {
              color: 'blue',
              content: 'Find Customer',
              icon: 'search',
              onClick: () => setFieldValue('newCustomerMode', false),
          }
        : {
              color: 'blue',
              content: 'New Customer',
              icon: 'plus',
              onClick: () => setFieldValue('newCustomerMode', true),
          };

    return (
        <Panel>
            <Panel.Header button={headerButton}>Personalized recommendation</Panel.Header>
            <Panel.Body className={'p-2'}>
                <Form>
                    {!values.newCustomerMode ? (
                        <FormikCustomerLookupField label={'Customer'} name={'customer'} required={true} />
                    ) : (
                        <>
                            <FormSectionHeader>New Customer Details</FormSectionHeader>
                            <FormGroup>
                                <FormikInputField
                                    name={'newCustomer.firstName'}
                                    label="First Name"
                                    autoComplete={'disabled'}
                                    required={true}
                                />
                                <FormikInputField
                                    name={'newCustomer.lastName'}
                                    autoComplete={'disabled'}
                                    label="Last Name"
                                    required={true}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormikInputField
                                    name={'newCustomer.contactName'}
                                    label="Contact Name"
                                    autoComplete={'disabled'}
                                />
                                <FormikInputField
                                    name={'newCustomer.companyName'}
                                    label="Company Name"
                                    autoComplete={'disabled'}
                                />
                            </FormGroup>
                            <FormSectionHeader>Contacts</FormSectionHeader>
                            <FormGroup>
                                <FormikInputField name={'newCustomer.email'} label={'Email'} required={false} />
                                <FormikInputField
                                    name={'newCustomer.mobile'}
                                    label={'Mobile'}
                                    // action
                                    required={false}
                                />
                            </FormGroup>
                        </>
                    )}
                </Form>
            </Panel.Body>
            <Panel.Footer>
                <div className={'flex flex-row justify-between w-full pt-4'}>
                    <Button size={'md'} variant={'secondary'} type="button" disabled={isSubmitting} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        size={'md'}
                        variant={'primary'}
                        onClick={() => handleSubmit()}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        content={`Create Recommendation`}
                    />
                </div>
            </Panel.Footer>
        </Panel>
    );
};

export interface PanelBrochureDetailsProps {}

export const PanelDocumentDetails: React.FC<PanelBrochureDetailsProps> = ({}) => {
    const { modulesAccess } = useViewer();
    const canSaveHR = modulesAccess.Calculators.saveRecommendations;
    const modalCtrlSearch = useModalCtrl(false);
    const { update } = useMutationHeaterRecommendation({
        refetchQueries: ['QueryHeaterRecommendationConnection'],
    });
    const { values } = useFormikContext<FormikHeatCalcValueType>();

    if (!canSaveHR) {
        return null;
    }

    const entity = values.entity;

    const onUpdate = async () => {
        try {
            const data = serializeForm(values);
            await update({
                id: values.heaterRecommendation.id,
                payload: data.payload,
                product: data.productId,
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onOpenPdf = async () => {
        try {
            await onUpdate();
            const linkPDF = values.heaterRecommendation.urlPdf;
            window.open(linkPDF, '_blank');
        } catch (e) {
            toastError(e);
        }
    };

    const actionButtons: ButtonWithPopupProps[] = [];
    if (values.mode === CalcMode.DEFAULT) {
        actionButtons.push({
            color: 'blue',
            basic: true,
            icon: 'save',
            content: 'Create Recommendation...',
            popup: { content: 'Save calculation results as a recommendation to customer' },
            onClick: modalCtrlSearch.onOpen,
        });
    } else if (values.mode === CalcMode.NEW_DOC) {
    } else if (values.mode === CalcMode.OPEN_DOC) {
        actionButtons.push({
            icon: <Icon name={'file pdf'} />,
            color: 'red',
            basic: false,
            content: 'Generate PDF',
            popup: { content: 'Generate personalized recommendation' },
            onClick: onOpenPdf,
        });
    }

    const brandingOptions: Optionable<BrandingType>[] = values.availableBrandings?.map((b) => {
        return {
            icon: null,
            value: b,
            text: brandTypeToString(b),
        };
    });

    const showPanel = actionButtons.length > 0 || entity;

    // HACK: TODO: restricted branding selector to HeatPumps only.
    const showBrandingSelector = values.docType === CalcDocType.HeatPump && brandingOptions?.length > 1;
    return (
        <VStack>
            {showPanel && (
                <Panel>
                    <Panel.Body color={'grey'}>
                        {entity && (
                            <>
                                <Panel.Item label={'Customer'}>
                                    <ModuleLink to={`/${entity?.user?.customer?.id}/`} moduleId={'CUSTOMERS'}>
                                        <Display.Entity value={entity} />
                                    </ModuleLink>
                                </Panel.Item>
                                <Panel.Item label={'Customer CRN'} content={entity?.user?.customer?.crn} />
                                <Panel.Divider />
                            </>
                        )}
                        {actionButtons.length > 0 && (
                            <div className={'flex flex-col space-y-2'}>
                                {actionButtons.map((b, index) => {
                                    return <ButtonWithPopup key={index} fluid={true} {...b} />;
                                })}
                            </div>
                        )}
                        <Panel.Divider />
                        {showBrandingSelector && (
                            <FormikSegmentedSelectButtons
                                color={'grey'}
                                label={'Report Brand Style'}
                                name={'brandingType'}
                                options={brandingOptions}
                            />
                        )}
                    </Panel.Body>
                </Panel>
            )}
            <Modal {...modalCtrlSearch}>
                <SaveToCustomerForm onCancel={modalCtrlSearch.onClose} />
            </Modal>
        </VStack>
    );
};
