import { compose, mapProps } from '@ez/tools';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _merge from 'lodash/merge';
import { NodeType } from './api-types';
import { FetchPolicy } from 'apollo-client/core/watchQueryOptions';

const PermissionQuery = gql`
    query PermissionsQuery {
        viewer {
            checkPermissions {
                AppointmentGroup {
                    create
                    delete
                    update
                }
                Site {
                    create
                    delete
                    update
                }
                Pool {
                    create
                    delete
                    update
                }
                Customer {
                    delete
                    update
                    create
                }
                StaffRegistration {
                    create
                    update
                    forcePassword
                    delete
                    disable
                    enable
                }
                Franchise {
                    create
                    delete
                    update
                }
                FranchiseShop {
                    create
                    delete
                    update
                }
                Staff {
                    create
                    update
                }
            }
        }
    }
`;

type availablePermissionKeys =
    | 'Site'
    | 'Pool'
    | 'AppointmentGroup'
    | 'Customer'
    | 'StaffRegistration'
    | 'Franchise'
    | 'FranchiseShop'
    | 'Staff';

type Permissions = Pick<NodeType.ViewerPermissions, availablePermissionKeys>;

const deafultPermissions = {
    Site: {
        delete: false,
        update: false,
        create: false,
    },
    Pool: {
        delete: false,
        update: false,
        create: false,
    },
    AppointmentGroup: {
        create: false,
        delete: false,
        update: false,
    },
    Customer: {
        delete: false,
        update: false,
        create: false,
    },
    StaffRegistration: {
        create: false,
        update: false,
        forcePassword: false,
        delete: false,
        disable: false,
        enable: false,
    },
    Franchise: {
        create: false,
        delete: false,
        update: false,
    },
    FranchiseShop: {
        create: false,
        delete: false,
        update: false,
    },
    Staff: {
        create: false,
        update: false,
    },
};

export const withPermissions = (conf?: { fetchPolicy: FetchPolicy }) =>
    compose(
        graphql(PermissionQuery, {
            options: (props: any) => ({
                fetchPolicy: conf?.fetchPolicy || 'cache-first',
            }),
        }),
        mapProps((props: any) => {
            const isLoading = !!props?.data?.loading;
            let permissions = deafultPermissions;
            if (!isLoading) {
                const receivedPermissions = props?.data?.viewer?.checkPermissions || {};
                permissions = _merge(deafultPermissions, receivedPermissions);
            }

            return {
                ...props,
                Permissions: {
                    loading: isLoading,
                    ...permissions,
                },
            };
        })
    );

export interface IWithPermissionsProps {
    Permissions: Permissions & {
        loading: boolean;
    };
}
