import { getTheme as getPoolwareTheme } from './theme-poolware';
import { getTheme as getPoolwareBappleTheme } from './theme-poolware-bapple';
import { getTheme as getRandomTheme } from './theme-random';
import { getTheme as getAquatestTheme } from './theme-aquatest';
import { getTheme as getAquatestAquapureTheme } from './theme-aquatest-aquapure';
import { getTheme as getPoolwareAdmin } from './theme-poolware-admin';
import { getTheme as getPoolwareAdminDark } from './theme-poolware-admin-dark';
import { getTheme as getAquatestAdmin } from './theme-aquatest-admin';
import { getTheme as getAsiapoolsTheme } from './theme-asiapools';
import { getTheme as getAutopoolTheme } from './theme-autopool';
import { getTheme as getDaveyTheme } from './theme-davey';
import { getTheme as getZaneTheme } from './theme-zane';
import { getTheme as getWatercoTheme } from './theme-waterco';
import { getDefaultTheme, ThemeInterface } from '@ez/components';

export enum ThemeName {
    Poolware = 'poolware',
    PoolwareBapple = 'poolware-bapple',
    PoolwareAdmin = 'poolware-admin',
    PoolwareAdminDark = 'poolware-admin-dark',
    AquatestAdmin = 'aquatest-admin',
    Aquatest = 'aquatest',
    AquatestAquaPure = 'aquatest-aquapure',
    Random = 'random',
    Asiapools = 'asiapools',
    Autopool = 'autopool',
    Zane = 'zane',
    WatercoAquatest = 'waterco-aquatest',
    Davey = 'davey',
}

/**
 * Returns directory name in the `public` folder where theme assets are located
 *
 * @param themeName
 */
const getPublicAssetsPrefix = (themeName: string | ThemeName): string => {
    let name = 'default';
    switch (themeName) {
        case ThemeName.PoolwareBapple:
        case ThemeName.Poolware:
        case ThemeName.Random:
        default:
            name = 'default';
            break;
        case ThemeName.Asiapools:
            name = 'asiapools';
            break;
        case ThemeName.Autopool:
            name = 'autopool';
            break;
        case ThemeName.PoolwareAdminDark:
        case ThemeName.PoolwareAdmin:
            name = 'poolware-admin';
            break;
        case ThemeName.AquatestAdmin:
        case ThemeName.Aquatest:
            name = 'aquatest';
            break;
        case ThemeName.Zane:
            name = 'zane';
            break;
        case ThemeName.WatercoAquatest:
            name = 'waterco-aquatest';
            break;
        case ThemeName.AquatestAquaPure:
            name = 'aquatest-aquapure';
            break;
        case ThemeName.Davey:
            name = 'davey';
            break;
    }

    return name;
};

export const getThemeByName = (baseURL, themeName: string | ThemeName, assetPrefix?: string): ThemeInterface => {
    let prefix = assetPrefix || getPublicAssetsPrefix(themeName);

    prefix = `${baseURL}/themes/${prefix}`;

    const themeMap = {
        [ThemeName.Aquatest]: getAquatestTheme,
        [ThemeName.PoolwareAdmin]: getPoolwareAdmin,
        [ThemeName.PoolwareAdminDark]: getPoolwareAdminDark,
        [ThemeName.AquatestAdmin]: getAquatestAdmin,
        [ThemeName.Poolware]: getPoolwareTheme,
        [ThemeName.PoolwareBapple]: getPoolwareBappleTheme,
        [ThemeName.Random]: getRandomTheme,
        [ThemeName.Asiapools]: getAsiapoolsTheme,
        [ThemeName.Zane]: getZaneTheme,
        [ThemeName.Autopool]: getAutopoolTheme,
        [ThemeName.WatercoAquatest]: getWatercoTheme,
        [ThemeName.AquatestAquaPure]: getAquatestAquapureTheme,
        [ThemeName.Davey]: getDaveyTheme,
    };

    const getTheme = themeMap[themeName] || getDefaultTheme;
    const theme: ThemeInterface = getTheme(prefix);
    // const isDevelopment = process.env.NODE_ENV === 'development';
    // if(isDevelopment) {
    //     theme.navbar.logo.backgroundColor = "#7c3697";
    // }

    return theme;
};
