import { QueryConnectionExternalProps, useDefaultConnectionState, useQueryConnection } from '@ez/api-core';
import gql from 'graphql-tag';
import _set from 'lodash/set';
import { NodeType } from '../api-types';

const QL = gql`
    query QueryCustomerTagsConnection(
        $first: Int
        $page: Int
        $sort: CustomerTagViewerCustomerTagsSort
        $search: CustomerTagViewerCustomerTagsSearch
    ) {
        viewer {
            customerTags(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        name
                        description
                        createdAt
                        franchise {
                            id
                            name
                        }
                        addedBy {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export interface UseQueryCustomerTagsConnectionProps extends QueryConnectionExternalProps<NodeType.CustomerTag> {
    searchName?: string;
}

function mapPropsToSearchQuery(props?: UseQueryCustomerTagsConnectionProps) {
    if (!props) {
        return undefined;
    }

    const { searchName } = props;
    const search = {};
    if (searchName) {
        _set(search, 'name.like', searchName);
    }
    return search;
}

export function useQueryCustomerTagsConnection(props?: UseQueryCustomerTagsConnectionProps) {
    const connectionPath = 'viewer.customerTags';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 20,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection<NodeType.CustomerTag>({
        query: props?.query || QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
