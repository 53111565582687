import { CalcDocType, FormikHeatCalcValueType, MonthlyHeatGasProfile, MonthlyHeatPumpProfile } from './types';
import * as React from 'react';
import { useFormikContext } from 'formik';
import { MonthlyDataTable as MonthlyDataTableGasHeater } from '../gas-heater-calculators/MonthlyDataTable';
import { MonthlyDataTable as MonthlyDataTableHeatPump } from '../heat-pump-calculators/MonthlyDataTable';
import { DevOnly } from '@ez/components';

export interface FormikMonthlyDataTableFormikMonthlyDataTableProps {
    monthlyHeatProfiles: MonthlyHeatGasProfile[] | MonthlyHeatPumpProfile[];
}

export const FormikMonthlyDataTable: React.FC<FormikMonthlyDataTableFormikMonthlyDataTableProps> = ({
    monthlyHeatProfiles,
}) => {
    const { setFieldValue, values } = useFormikContext<FormikHeatCalcValueType>();

    const selectedMonths = values.selectedMonths;
    const setSelectedItems = (selectedItems) => {
        setFieldValue('selectedMonths', selectedItems);
    };

    if (values.docType === CalcDocType.GasHeater) {
        const costEstimation = values.costEstimation?.gas;
        return (
            <MonthlyDataTableGasHeater
                monthlyHeatProfiles={monthlyHeatProfiles as MonthlyHeatGasProfile[]}
                costEstimation={costEstimation}
                selectedMonths={selectedMonths}
                setSelectedItems={setSelectedItems}
            />
        );
    }
    if (values.docType === CalcDocType.HeatPump) {
        const costEstimation = values.costEstimation?.hpump;
        return (
            <MonthlyDataTableHeatPump
                monthlyHeatProfiles={monthlyHeatProfiles as MonthlyHeatPumpProfile[]}
                costEstimation={costEstimation}
                selectedMonths={selectedMonths}
                setSelectedItems={setSelectedItems}
            />
        );
    }

    return <DevOnly>Unknown doc type</DevOnly>;
};
