import * as React from 'react';
import {
    Button,
    MenuBar,
    MenuBarDropdownItemProps,
    MenuBarGroup,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageSkeletonLoader,
    Panel,
    StickyMenuBar,
    toastError,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { fromEdges, NodeType, useMutationWorkOrderTemplate } from '@poolware/api';
import { NotFoundPage } from '@poolware/app-shell';
import { useQueryWorkOrderTemplate } from '../use-query-work-order-template';
import { JobTodoTemplatePickerModal } from '../../JobTodoTemplate/JobTodoTemplatePicker';
import { WorkOrderDetailsPanel } from './WorkOrderDetailsPanel';
import { JobTodoTemplateAssocDNDTable } from './JobTodoTemplateAssocDNDTable';

export interface ServiceJobTemplateViewProps {}

const TemplateDeleteMenuItem: React.FC<MenuBarDropdownItemProps> = (props) => {
    return (
        <MenuBar.DropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            content="Delete"
            confirm={{
                confirmMessage: {
                    header: 'Delete?',
                    content: 'There is no undo! This template will be deleted forever!',
                },
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                    negative: true,
                },
            }}
            {...props}
        />
    );
};

export const WorkOrderTemplateView: React.FC<ServiceJobTemplateViewProps> = ({}) => {
    const { AppNavigator, params } = useAppNavigator();
    const { loading, error, node: workOrderTemplate, refetchQuery } = useQueryWorkOrderTemplate(params.id);
    const canDelete = workOrderTemplate?.checkMutations?.delete;

    const templateMutation = useMutationWorkOrderTemplate({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });
    const { delete: deleteMutation } = useMutationWorkOrderTemplate({
        refetchQueries: ['QueryWorkOrderTemplateConnection'],
    });

    if (loading) {
        return <PageSkeletonLoader />;
    }

    if (error) {
        return (
            <div>
                <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
        );
    }

    if (!loading && !workOrderTemplate) {
        return <NotFoundPage />;
    }

    const goToList = () => {
        AppNavigator.replaceToOrigin('/wo-template', { relativeToModule: true });
    };

    const onDelete = async () => {
        try {
            await deleteMutation({ id: workOrderTemplate.id });
            goToList();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const attachedJobTemplates = fromEdges(workOrderTemplate?.jobTodoTemplateAssocs).map(
        (assoc) => assoc.jobTodoTemplate
    );

    const handleOnTemplatePickerSubmit = async (selectedItems: NodeType.JobTodoTemplate[]) => {
        try {
            const assocsListLength = workOrderTemplate.jobTodoTemplateAssocs?.edges?.length;
            const newAssocs = selectedItems.map<NodeType.UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateInput>(
                // @ts-ignore
                (jobTemplate, index) => {
                    return {
                        isSuggestion: false,
                        priority: assocsListLength + index,
                        jobTodoTemplate: { id: jobTemplate.id },
                    };
                }
            );
            await templateMutation.update({
                id: workOrderTemplate.id,
                jobTodoTemplateAssocs: {
                    create: newAssocs,
                },
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const handleOnEditJobTodoTemplate = (assoc: NodeType.JobTodoTemplateAssoc) => {
        AppNavigator.navigateRelative(`/job-todo-template/${assoc.jobTodoTemplate?.id}`, { setOrigin: true });
    };

    const handleOnRemoveAssoc = async (assoc: NodeType.WorkOrderTemplateAssoc | any) => {
        try {
            await templateMutation.update({
                id: workOrderTemplate.id,
                jobTodoTemplateAssocs: {
                    delete: [assoc.id],
                },
            });
        } catch (e) {
            toastError(e);
        }
    };

    const barGroupItemConf: MenuBarGroupProps = !canDelete
        ? undefined
        : {
              menu: { menuMode: MenuBarGroupMODE.DROPDOWN, icon: 'bars', color: 'red' },
              items: [
                  {
                      render: <TemplateDeleteMenuItem onClick={onDelete} disabled={!canDelete} />,
                  },
              ],
          };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goToList} icon={'chevron left'}>
                        To List
                    </MenuBarItem>
                    <MenuBarHeaderItem>Work Order Template</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    {barGroupItemConf && <MenuBarGroup {...barGroupItemConf} />}
                </MenuBarSection>
            </StickyMenuBar>

            <WorkOrderDetailsPanel refetchQuery={refetchQuery} workOrderTemplate={workOrderTemplate} />

            <Panel>
                <Panel.Header content={'Job Task'} />
                <Panel.Body>
                    <JobTodoTemplateAssocDNDTable
                        templateMutation={templateMutation}
                        workOrderTemplate={workOrderTemplate}
                        onEditJobTodoTemplate={handleOnEditJobTodoTemplate}
                        onRemoveAssoc={handleOnRemoveAssoc}
                    />
                </Panel.Body>
                <Panel.Footer>
                    <JobTodoTemplatePickerModal
                        trigger={<Button floated={'right'} icon={'plus'} content={'Add Job Task'} />}
                        onSubmit={handleOnTemplatePickerSubmit}
                        selectedItems={attachedJobTemplates}
                    />
                </Panel.Footer>
            </Panel>
        </VStack>
    );
};
