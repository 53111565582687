import * as React from 'react';
import { MeasurementInputFieldsProps } from './types';
import { Display, FormikInputField, Table } from '@ez/components';
import { InputFieldsConfType } from './preapare-input-conf';
import { useAppLayoutMode } from '@poolware/app-shell';

const MobileWaterTestFields: React.FC<{ inputFieldsConf: InputFieldsConfType }> = ({ inputFieldsConf }) => {
    return (
        <>
            {inputFieldsConf.map((confItem) => {
                const {
                    measurementTypeId,
                    measurementName,
                    lowerThresholdValue,
                    targetValue,
                    higherThresholdValue,
                    fieldName,
                } = confItem;

                let label = `${measurementName}`;
                let range = `Min: ${lowerThresholdValue}, Target: ${targetValue}, Max: ${higherThresholdValue}`;
                return (
                    <FormikInputField
                        dataTestId={measurementName}
                        name={fieldName}
                        enterKeyHint={'next'}
                        key={measurementTypeId}
                        label={`${label}  (${range})`}
                        type={'number'}
                    />
                );
            })}
        </>
    );
};

const DesktopWaterTestFields: React.FC<MeasurementInputFieldsProps> = (props) => {
    const { inputFieldsConf, prevSampleSets } = props;

    let inputs = inputFieldsConf.map((inputItem, index) => {
        const {
            measurementTypeId,
            prevSampleValues,
            measurementName,
            lowerThresholdValue,
            targetValue,
            higherThresholdValue,
            measurementUnitName,
            fieldName,
            // tagIdentifier,
        } = inputItem;

        let label = `${measurementName}`;

        return (
            <Table.Row key={index}>
                <Table.Cell textAlign={'right'} width={2}>
                    <label htmlFor={fieldName}>{label}</label>
                </Table.Cell>
                <Table.Cell width={3}>
                    <FormikInputField
                        name={fieldName}
                        dataTestId={measurementName}
                        enterKeyHint="next"
                        size={'small'}
                        key={measurementTypeId}
                        type={'number'}
                        subLabel={measurementUnitName}
                        subLabelStyle={{ width: '50px', textAlign: 'center' }}
                    />
                </Table.Cell>
                <Table.Cell className={'target'} textAlign={'center'} width={1}>
                    {lowerThresholdValue}
                </Table.Cell>
                <Table.Cell className={'target'} textAlign={'center'} width={1}>
                    {targetValue}
                </Table.Cell>
                <Table.Cell className={'target'} textAlign={'center'} width={1}>
                    {higherThresholdValue}
                </Table.Cell>
                <Table.Cell>
                    {
                        // tagIdentifier
                    }
                </Table.Cell>

                {prevSampleValues.map((prev, index) => {
                    return (
                        <Table.Cell key={index} className={'previous'} width={1} textAlign={'center'}>
                            {prev || '--'}
                        </Table.Cell>
                    );
                })}
            </Table.Row>
        );
    });

    return (
        <>
            <Table
                className={'water-test-input-table'}
                stackable={false}
                size={'small'}
                structured={true}
                unstackable={true}
                compact={'very'}
                singleLine={true}
                celled={false}
                basic
            >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                        <Table.HeaderCell className={'target'} textAlign={'center'}>
                            MIN
                        </Table.HeaderCell>
                        <Table.HeaderCell className={'target'} textAlign={'center'}>
                            TARGET
                        </Table.HeaderCell>
                        <Table.HeaderCell className={'target'} textAlign={'center'}>
                            MAX
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                        {prevSampleSets &&
                            prevSampleSets.map((set, index) => {
                                return (
                                    <Table.HeaderCell textAlign={'center'} key={index}>
                                        <Display.Date format={'ll'} value={set.createdAt} />
                                    </Table.HeaderCell>
                                );
                            })}
                    </Table.Row>
                </Table.Header>
                <Table.Body>{inputs}</Table.Body>
            </Table>
        </>
    );
};

export const WaterTestFields: React.FC<MeasurementInputFieldsProps> = ({
    inputFieldsConf,
    chemicalTargets,
    prevSampleSets,
}) => {
    const { gteTablet } = useAppLayoutMode();

    return (
        <>
            {!gteTablet ? (
                <MobileWaterTestFields inputFieldsConf={inputFieldsConf} />
            ) : (
                <DesktopWaterTestFields
                    inputFieldsConf={inputFieldsConf}
                    chemicalTargets={chemicalTargets}
                    prevSampleSets={prevSampleSets}
                />
            )}
        </>
    );
};
