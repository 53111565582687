import { Button, confirmModalImperative, Form, Modal, toastError, useModalCtrl } from '@ez/components';
import * as React from 'react';
import { useFormikContext } from 'formik';
import { AppointmentFormValuesType } from '../AppointmentForm';
import { NodeType } from '@poolware/api';
import { ModuleIconNames } from '../../../constants';
import { ServiceJobTemplatePicker } from '../../../connected-components/ServiceJobTemplatePicker';
import { ServiceJobTemplatePreview } from '../../../components/ServiceJobTemplatePreview';
import { useServiceJobMutators } from '../../../queries/mutators-service-job';

export const AddServiceTemplateView: React.FC<{ refetchQuery: any }> = ({ refetchQuery }) => {
    const modalWOPicker = useModalCtrl();
    const { values } = useFormikContext<AppointmentFormValuesType>();
    const { appointmentItem } = values;

    const { createForSingleAppointment } = useServiceJobMutators({ refetchQueries: [refetchQuery] });

    const onSelectJobTemplate = async (
        template: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        modalWOPicker.onClose();
        if (!template) {
            return null;
        }

        const workOrderTemplate = workOrderTemplates?.[0];

        try {
            const res = await confirmModalImperative({
                confirmMessage: {
                    header: 'Add Service Template to appointment?',
                    content: (
                        <ServiceJobTemplatePreview
                            serviceJobTemplate={template}
                            full={true}
                            workOrderIds={[workOrderTemplate.id]}
                        />
                    ),
                },
                confirmButton: { content: 'Add' },
                cancelButton: { content: 'Cancel' },
            });

            if (!res) {
                return;
            }

            await createForSingleAppointment({
                serviceTemplate: template?.id,
                workOrderTemplate: workOrderTemplate?.id,
                appointmentItem: appointmentItem.id,
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <div>
            <div>
                <Form.Input value={''} type={'text'} label={'Job Template'} readOnly={true} action>
                    <input style={{ cursor: 'pointer', backgroundColor: '#f8f8f8' }} onClick={modalWOPicker.onOpen} />
                    <Button
                        type={'button'}
                        icon={ModuleIconNames.ServiceCall}
                        content={'Select'}
                        onClick={modalWOPicker.onOpen}
                    />
                </Form.Input>
            </div>
            <Modal {...modalWOPicker}>
                {modalWOPicker.open && (
                    <ServiceJobTemplatePicker
                        onCancel={modalWOPicker.onClose}
                        onSubmit={onSelectJobTemplate}
                        selectWorkOrder={'single'}
                        requiredWorkOrder={true}
                    />
                )}
            </Modal>
        </div>
    );
};
