import * as React from 'react';
import { Display, Table } from '@ez/components';
import { PageControlProps } from './ChemicalTargetList.Control';

const ChemicalTargetList: React.FC<PageControlProps> = ({ chemicalTargets }) => {
    return (
        <Table celled color="grey" unstackable compact size={'small'}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="4">Parameter</Table.HeaderCell>
                    <Table.HeaderCell width="2">Min</Table.HeaderCell>
                    <Table.HeaderCell width="2">Target</Table.HeaderCell>
                    <Table.HeaderCell width="2">Max</Table.HeaderCell>
                    <Table.HeaderCell width="2">Unit</Table.HeaderCell>
                    {/*<Table.HeaderCell>Overrides</Table.HeaderCell>*/}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {chemicalTargets.map((chTarget, index) => {
                    let unit = chTarget?.measurementType?.unit?.name || '';
                    let low = chTarget?.lowerThreshold;
                    let target = chTarget?.target;
                    let high = chTarget?.higherThreshold;

                    return (
                        <Table.Row key={chTarget?.measurementType?.id || index}>
                            <Table.Cell>{chTarget?.measurementType?.name}</Table.Cell>
                            <Table.Cell>
                                <Display.Num value={low} defaultString="-" />
                            </Table.Cell>
                            <Table.Cell>
                                <Display.Num value={target} defaultString="-" />
                            </Table.Cell>
                            <Table.Cell>
                                <Display.Num value={high} defaultString="-" />
                            </Table.Cell>
                            <Table.Cell>{unit}</Table.Cell>
                            {/*<Table.Cell>*/}
                            {/*{isCalibrated && <Icon name="star"/>}*/}
                            {/*</Table.Cell>*/}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export default ChemicalTargetList;
