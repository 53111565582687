import * as React from 'react';
import { ModuleNavLink, ModuleNavLinkProps } from '@poolware/react-app-navigator';

export const MenuItemNavLink: React.FC<ModuleNavLinkProps> = (props) => {
    return (
        <div className={'h-full px-4 has-[.active]:bg-tertiary rounded items-center flex'}>
            <ModuleNavLink {...props} />
        </div>
    );
};
