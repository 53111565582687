import * as React from 'react';
import * as URLBuilder from '../../../../routes/url-builder';
import { ConnectionErrorMessage, PageSkeletonLoader, toastError } from '@ez/components';
import { fromEdges, NodeType, useTestReportMutators } from '@poolware/api';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import { WaterTestForm } from '../WaterTestForm';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryWaterTestReport } from './use-query-water-test-report';
import _toNumber from 'lodash/toNumber';
import _keys from 'lodash/keys';
import _findIndex from 'lodash/findIndex';
import { PageLayout } from '@ez/components';

const Page: React.FC<{ testId?: NodeType.ID }> = (props) => {
    const { modulesAccess } = useViewer();
    const { AppNavigator, params } = useAppNavigator();
    const testId = params.testId || props.testId;
    const {
        loading,
        error,
        report,
        pool,
        measurementIds,
        measurements,
        manualProblems,
        chemicalTargets,
        refetchQuery,
    } = useQueryWaterTestReport(testId);
    const { TestReportMutator } = useTestReportMutators([refetchQuery]);

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !report) {
        return <NotFoundPage />;
    }

    const waterlinkEnabled = modulesAccess.WaterTest?.waterlink;
    const poolId = pool.id;
    const customerId = params.customerId;
    const initialValues = {
        note: report.note,
        refId: report.refId,
        measurements: measurements,
        manualProblems: manualProblems,
    };

    const navigateOnDone = (report: NodeType.TestReport) => {
        if (AppNavigator.query.workOrderId) {
            AppNavigator.navigate(AppNavigator.returnTo);
        }

        let reportId = report.id;
        if (reportId) {
            const link = URLBuilder.Customer(customerId).Pool(poolId).WaterTest(reportId).view;
            AppNavigator.replace(link);
        } else {
            throw new Error('Error. No ID');
        }
    };

    const onCancel = () => {
        AppNavigator.navigateToOrigin();
    };

    const onSubmit = async (formValues: typeof initialValues) => {
        try {
            const measurementTypeIdsToSubmit = _keys(formValues.measurements);

            const samplesToCreate = measurementTypeIdsToSubmit
                .filter((measurementTypeId) => {
                    return !measurementIds[measurementTypeId];
                })
                .map((measurementTypeId) => ({
                    value: _toNumber(formValues.measurements[measurementTypeId]),
                    measurementType: measurementTypeId,
                    source: '',
                }));

            const samplesChanged = measurementTypeIdsToSubmit.filter((measurementTypeId) => {
                return measurementIds[measurementTypeId];
            });

            const samplesToUpdate = samplesChanged
                .filter((measurementTypeId) => {
                    return formValues.measurements[measurementTypeId] !== '';
                })
                .map((measurementTypeId) => ({
                    value: _toNumber(formValues.measurements[measurementTypeId]),
                    id: measurementIds[measurementTypeId],
                }));

            const samplesToDelete = samplesChanged
                .filter((measurementTypeId) => {
                    return formValues.measurements[measurementTypeId] === '';
                })
                .map((measurementTypeId) => measurementIds[measurementTypeId]);

            const manualProblemsToAssign = formValues.manualProblems.map((p) => p.id);

            const origManualProblemIds = fromEdges<NodeType.ManualProblem>(report?.manualProblems).map((p) => p.id);
            const manualProblemsToDelete = origManualProblemIds.filter((opId) => {
                return _findIndex(manualProblemsToAssign, (p) => p === opId) === -1;
            });

            const note = formValues.note;

            await TestReportMutator.updateReport(report, {
                samplesToCreate,
                samplesToUpdate,
                samplesToDelete,
                manualProblemsToDelete,
                manualProblemsToAssign,
                note: note,
            });

            navigateOnDone(report);
        } catch (error) {
            console.error('there was an error sending the query', error);
            toastError({
                title: 'Failed to update report',
                description: error.message,
            });
        }
    };

    return (
        <PageLayout sectionWidth={'screen-xl'}>
            <PageLayout.BodySection>
                <WaterTestForm
                    waterlinkAllowed={waterlinkEnabled}
                    header={'Edit Water Test'}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    initialValues={initialValues}
                    chemicalTargets={chemicalTargets}
                    pool={pool}
                    showOpenPDFCheckbox={false}
                />
            </PageLayout.BodySection>
        </PageLayout>
    );
};

export default Page;
