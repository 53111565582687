import * as React from 'react';
import * as _ from 'lodash';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';
import { NodeType } from '@poolware/api';
import { toastError, withApolloLoading } from '@ez/components';
import { AppointmentForm, AppointmentFormMode, AppointmentFormValuesType } from './AppointmentForm';
import { defaultRecurrence, getAppointmentChangeDiff } from './common';
import { refetchAppointments } from '../Queries/query-appointment-items-calendar';
import { CalendarActionProps, withCalendarActions } from '../../redux';
import { confirmFutureImperative } from '../Components/ConfirmFuture';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { AppointmentItemQuery } from './queries';
import { AppointmentMutatorProps, withAppointmentMutators } from '../../queries/use-appointment-mutators';
import { ModuleIconNames, SchedulerModuleRouterID } from '../../constants';
import { useViewer, withViewer, WithViewerProps } from '@poolware/app-shell';
import { PanelAppointmentDiff } from './PanelAppointmentDiff';
import { Alert } from '@ez/components';

interface PageProps extends PageControlProps {}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */

const Page: React.FC<PageProps> = (props) => {
    const { modulesAccess } = useViewer();
    const caseTemplatesEnabled = modulesAccess.FieldServices?.caseTemplates;

    const { appointmentItem, initialValues } = props;
    let showWorkOrderTab = caseTemplatesEnabled;
    if (!initialValues?.serviceJob && appointmentItem?.isRecurring) {
        /// Changing work order templates for a recurring appointment is not supported yet;
        showWorkOrderTab = false;
    }

    let _initialValues: AppointmentFormValuesType = {
        ...initialValues,
        showWorkOrderTab: showWorkOrderTab,
    };

    const onCancel = () => {
        props.AppNavigator.navigateToOrigin();
    };

    const performMutation = async (input: { values: AppointmentFormValuesType; diff: any; future: boolean }) => {
        const { values, diff, future } = input;

        if (_.isEmpty(diff)) {
            props.AppNavigator.navigateToOrigin();
            return;
        }

        const { isRecurrenceLocked, appointmentItem } = values;
        const { recurrence, startDate, duration, ...restDiff } = diff;

        const newRecurrencePattern = !isRecurrenceLocked && diff.recurrence ? values.recurrence : undefined;
        const shouldSetFuture = Boolean(future || newRecurrencePattern);

        const mutationValues: any = {
            ...restDiff,
        };

        if (shouldSetFuture) {
            mutationValues.future = true;
            mutationValues.mergeStates = true;
        }

        if (newRecurrencePattern) {
            mutationValues.recurrence = newRecurrencePattern;
        }

        if (diff.startDate || diff.duration) {
            // Start Date or duration changed.
            // Both startDate and duration values are required.
            mutationValues.startDate = values.startDate;
            mutationValues.duration = values.duration;
            if (shouldSetFuture) {
                mutationValues.recurrence = values.recurrence;
            }
        }
        await props.AppointmentMutator.updateAppointmentItem(appointmentItem, mutationValues);
    };

    const handleOnSubmit = async (input: { values: AppointmentFormValuesType; diff: any; future: boolean }) => {
        try {
            await performMutation(input);
            props.AppNavigator.navigateToOrigin('/', { moduleId: SchedulerModuleRouterID });
        } catch (error) {
            console.error(error);
            toastError({
                title: 'Failed to update appointment',
                description: error.message,
            });
        }
    };

    const handleOnSubmitWithConfirm = async (values: AppointmentFormValuesType) => {
        const { appointmentItem, initialValues } = props;
        const diff = getAppointmentChangeDiff(values, initialValues);
        // console.log('Diff', diff);

        if (!appointmentItem.isRecurring || _.isEmpty(diff)) {
            return handleOnSubmit({ values, diff, future: false });
        }

        const diffDetailsComponent = <PanelAppointmentDiff diff={diff} />;

        const res = await confirmFutureImperative(diffDetailsComponent);
        if (res === 'cancel') {
            return;
        }
        const future = res === 'future';

        return handleOnSubmit({ values, diff, future });
    };

    if (!appointmentItem)
        return (
            <Alert
                type={'warning'}
                // onDismiss={() => props.AppNavigator.navigateToOrigin()}
                header={'Error!'}
                content={'Could not fetch appointment item.'}
            />
        );

    return (
        <AppointmentForm
            icon={ModuleIconNames.Appointment}
            header={'Edit Appointment'}
            initialValues={_initialValues}
            onCancel={onCancel}
            onSubmit={handleOnSubmitWithConfirm}
            submitButton={{ content: 'Update' }}
        />
    );
};

export default compose(
    withViewer(),
    withAppNavigator(),
    withCalendarActions(),
    graphql(AppointmentItemQuery, {
        options: (props: any) => ({
            variables: {
                itemId: props.params.appointmentItem || props.appointmentItemId,
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.appointmentItem' }),
    mapProps(({ data, ...props }) => {
        const appointmentItem = data?.appointmentItem as NodeType.AppointmentItem;
        const appointmentItemStartDateString = appointmentItem?.startDate;
        const isRecurring = appointmentItem?.isRecurring;
        const recurrence = appointmentItem?.appointment?.recurrence;
        const endDateString = recurrence?.range?.endDate;
        const modulesAccess = (props as WithViewerProps).viewerContext?.modulesAccess;
        if (endDateString) {
            _.set(recurrence, 'range.endDate', new Date(endDateString));
        }

        const customerReadOnly = !!appointmentItem.serviceJob?.isRecurring;
        const staffReadOnly = !!modulesAccess.FieldServices?.calendarSingleStaffMode;

        const initialValues: AppointmentFormValuesType = {
            mode: AppointmentFormMode.Edit,
            serviceJob: appointmentItem.serviceJob,
            workOrder: appointmentItem.workOrder,
            staff: appointmentItem.staff,
            customer: appointmentItem.customer,
            staffReadOnly: staffReadOnly,
            customerReadOnly: customerReadOnly,
            address: appointmentItem.address,
            pool: appointmentItem.pool,
            note: appointmentItem.note,
            group: appointmentItem.group,
            color: appointmentItem.color,
            appointmentItem: appointmentItem,
            isRecurring: isRecurring,
            isRecurrenceLocked: true,
            startDate: new Date(appointmentItemStartDateString),
            duration: appointmentItem.duration,
            showRecurrenceTab: isRecurring,
            showWorkOrderTab: false,
            recurrence,
            defaultRecurrence,
            isEditing: true,
        };

        return {
            ...props,
            isLoading: data.loading,
            appointmentItem,
            initialValues,
        };
    }),
    withAppointmentMutators([refetchAppointments])
)(Page);

interface PageControlProps extends IAppNavigatorProps, CalendarActionProps, AppointmentMutatorProps {
    appointmentItem: NodeType.AppointmentItem;
    recurrence: NodeType.Recurrence;
    isLoading: boolean;
    initialValues: AppointmentFormValuesType;
}
