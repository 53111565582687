import * as React from 'react';
import * as PIs from './PanelItem';
import { PanelItems } from './PanelItems';

import { PanelHeader } from './PanelHeader';
import PanelBody from './PanelBody';
import PanelFooter from './PanelFooter';
import { SectionHeader } from './SectionHeader';
import styled from 'styled-components';
import { Segment } from '../Segment';

const PanelRoot = styled.div`
    width: 100%;

    & > .ez-panel-footer:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    & > .ez-panel-body:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
    }

    & > .ez-panel-body:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 0;
    }
`;

export interface PanelProps {}

export class Panel extends React.Component<PanelProps> {
    public static Header = PanelHeader;
    public static Body = PanelBody;
    public static Footer = PanelFooter;
    public static Divider = PIs.PanelItemDivider;
    public static SectionHeader = SectionHeader;
    public static Item = PIs.PanelItem;
    public static Items = PanelItems;
    public static ItemEmail = PIs.PanelItemEmail;
    public static ItemPhone = PIs.PanelItemPhone;
    public static ItemDate = PIs.PanelItemDate;
    public static ItemAddress = PIs.PanelItemAddress;
    public static ItemEntity = PIs.PanelItemEntity;
    public static ItemText = PIs.PanelItemText;
    public static ItemJSONView = PIs.PanelItemJSONView;

    render() {
        return (
            <PanelRoot>
                <>{this.props.children}</>
            </PanelRoot>
        );
    }
}

export interface Panel extends React.Component<PanelProps> {
    Header: PanelHeader;
    Body: typeof PanelBody;
    Footer: typeof PanelFooter;
    Segment: typeof Segment;
    // Divider: typeof Divider;
    SectionHeader: SectionHeader;
    Item: typeof PIs.PanelItem;
    Items: typeof PanelItems;
    ItemEmail: typeof PIs.PanelItemEmail;
    ItemPhone: typeof PIs.PanelItemPhone;
    ItemDate: typeof PIs.PanelItemDate;
    ItemAddress: typeof PIs.PanelItemAddress;
    ItemEntity: typeof PIs.PanelItemEntity;
    ItemText: typeof PIs.PanelItemText;
}
