import * as React from 'react';
import { useMemo } from 'react';
import * as _ from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from '@ez/tools';
import { NodeType } from '@poolware/api';
import { useViewer } from '@poolware/app-shell';
import { toastError, withApolloLoading } from '@ez/components';
import { defaultRecurrence } from './common';
import { useBookingActions } from '../../redux';
import {
    AppointmentForm,
    AppointmentFormMode,
    AppointmentFormValuesType,
    AppointmentValidationSchema,
} from './AppointmentForm';
import { useAppNavigator } from '@poolware/react-app-navigator';
// import {CustomerQuery, useCustomerDetailsQuery} from './queries';
import { useApptCreationMutations } from './use-appt-creation-mutations';
import { ModuleIconNames } from '../../constants';

const Page: React.FC = (props) => {
    const { modulesAccess, viewer } = useViewer();
    const Mutators = useApptCreationMutations();
    const { BookingAction, BookingState } = useBookingActions();
    const { AppNavigator } = useAppNavigator();
    // const { loading, error, data} = useCustomerDetailsQuery(props.customer?.id)
    // const customer = data?.customer;

    const defaultDateRange = useMemo(
        () => ({
            startDate: new Date(),
            duration: 60,
        }),
        []
    );

    const recurrenceEnabled = modulesAccess.FieldServices?.recurringAppointment;
    const caseTemplatesEnabled = modulesAccess.FieldServices?.caseTemplates;
    const isSagaMode = BookingState.isSagaMode;

    let initialValues: AppointmentFormValuesType = {
        mode: AppointmentFormMode.New,
        address: undefined,
        appointmentItem: undefined,
        customer: undefined,
        pool: undefined,
        serviceJob: undefined,
        staff: undefined,
        staffReadOnly: false,
        sjTemplate: undefined,
        woTemplate: undefined,
        workOrder: undefined,
        startDate: defaultDateRange.startDate,
        duration: defaultDateRange.duration,
        defaultRecurrence: defaultRecurrence,
        recurrence: null,
        isRecurrenceLocked: false,
        isRecurring: false,
        showRecurrenceTab: recurrenceEnabled,
        showWorkOrderTab: caseTemplatesEnabled,
        isEditing: false,
    };

    if (modulesAccess.FieldServices?.calendarSingleStaffMode) {
        // lock field only if `me.staff` is available
        initialValues.staffReadOnly = !!viewer.me?.staff;
        initialValues.staff = viewer.me?.staff;
    }

    if (isSagaMode) {
        const { details } = BookingState;
        const dStartDate = details?.startDate || defaultDateRange.startDate;
        const dDuraton = details?.duration || defaultDateRange.duration;
        const showRecurrenceTab = recurrenceEnabled && !details?.workOrder && !details.serviceJob;
        const showSJTemplatePicker = caseTemplatesEnabled && !details.workOrder;
        initialValues = {
            ...initialValues,
            ...details,
            startDate: new Date(dStartDate),
            duration: dDuraton,
            showRecurrenceTab,
            showWorkOrderTab: showSJTemplatePicker,
        };
    }

    // Make sure endDate is converted to Date object.
    const rangeEndDateString = _.get(initialValues, 'recurrence.range.endDate');
    if (rangeEndDateString) {
        _.set(initialValues, 'recurrence.range.endDate', new Date(rangeEndDateString));
    }

    const onSubmit = async (values: AppointmentFormValuesType) => {
        try {
            await Mutators.onSubmit(values);

            if (BookingState.isSagaMode) {
                BookingAction.bookingCreated();
            } else {
                AppNavigator.navigateToOrigin();
            }
        } catch (error) {
            toastError({ title: 'Failed to create appointment', description: error.message });
            console.error('there was an error sending the query', error);
        }
    };

    const onCancel = () => {
        if (BookingState.isSagaMode) {
            BookingAction.abort();
        } else {
            AppNavigator.navigateToOrigin('/scheduler');
        }
    };

    return (
        <AppointmentForm
            icon={ModuleIconNames.Appointment}
            header={'New Appointment'}
            enableReinitialize={true}
            // debug={true}
            validationSchema={AppointmentValidationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitButton={{ content: 'Create' }}
        />
    );
};

// export default compose(
//     graphql(CustomerQuery, {
//         skip: (ownProps) => !_.get(ownProps, 'customer.id'),
//         options: (ownProps) => ({
//             variables: { customerId: _.get(ownProps, 'customer.id') },
//         }),
//         props: (props) => ({
//             ...props,
//             customer: _.get(props, 'data.customer'),
//         }),
//     }),
//     withApolloLoading()
// )(Page);

export default Page;
