import { createThemeByHue } from '@ez/components';

let _t = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    const huePrim = 210;
    _t = createThemeByHue({
        publicPrefix: publicPrefix,
        huePrim: 210,
        hueSec: 42,
        customisation: {
            themeName: 'poolware-bapple',
            color: {
                // primary: '60 115 174',
                // secondary: '240 190 79',
            },
            text: {
                size: {
                    base: '14px',
                },
                color: {
                    primary: `${huePrim} 10 20`,
                },
            },
            border: {
                radiusSm: '0.25rem',
                radius: '0.375rem',
                radiusMd: '0.5rem',
                radiusLg: '0.75rem',
                radiusXl: '1.0rem',
                radius2xl: '1.5rem',
                radius3xl: '2.0rem',
                color: `hsl(${huePrim}, 10%, 85%)`,
                colorStrong: `hsl(${huePrim}, 40%, 80%)`,
            },
            main: {
                backgroundColor: `hsl(210, 22%, 90%)`,
            },
        },
    });
    return _t;
};
