import {
    NodeType,
    QueryConnectionConfigProps,
    QueryConnectionExternalProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';

const defaultQL = gql`
    query QueryServiceJobStageConnection(
        $first: Int
        $page: Int
        $search: ServiceJobStageViewerServiceJobStagesSearch
    ) {
        viewer {
            serviceJobStages(first: $first, page: $page, search: $search) {
                edges {
                    node {
                        id
                        status
                        type
                        title
                        isDefault
                        tag
                        priority
                        isFallback
                        groups {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                        }
                        checkMutations {
                            update
                            delete
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
`;

export interface QueryServiceJobStageConnectionProps extends Partial<QueryConnectionConfigProps> {
    organisationId?: string;
    franchiseId?: string;
    includeSubgroups?: boolean;
}

const mapPropsToSearchQuery = (
    props: QueryServiceJobStageConnectionProps
): NodeType.ServiceJobStageViewerServiceJobStagesSearch => {
    let variables: NodeType.ServiceJobStageViewerServiceJobStagesSearch = {};
    if (props?.franchiseId) {
        variables.franchise = { id: props.franchiseId };
    }
    if (props?.organisationId) {
        variables.organisationType = { id: props.organisationId };
    }

    return variables;
};

export function useQueryServiceJobStageConnection(
    props?: QueryConnectionExternalProps<NodeType.ServiceJobStage> & QueryServiceJobStageConnectionProps
) {
    const connectionPath = 'viewer.serviceJobStages';
    const { variables, connectionState } = useDefaultConnectionState({
        debugName: 'useQueryServiceJobStageConnection',
        defaultSortKey: 'priority',
        defaultPageSize: 1000,
        defaultSortDirection: SortDirection.DES,
        ...props,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection<NodeType.ServiceJobStage>({
        query: props?.query || defaultQL,
        fetchPolicy: props?.fetchPolicy,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
