import * as React from 'react';
import { fromEdges, NodeType, useMutationPoolBottleLease } from '@poolware/api';
import {
    ButtonWithConfirm,
    ConfirmProps,
    Display,
    Form,
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikFieldProps,
    FormikFormFieldLabel,
    Icon,
    Modal,
    Panel,
    Table,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { Field, getIn } from 'formik';
import { BarcodeScannerInput } from '../../../../../components/BarcodeScannerInput';

const FormikBarcodeScannerInput: React.FC<FormikFieldProps> = ({ label, name, required, ...rest }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (value) => form.setFieldValue(field.name, value);
                const handleOnBlur = (e) => field.onBlur(e);
                let value = field?.value || '';
                const touched = getIn(form.touched, name);
                const error = getIn(form.errors, name);
                let hasError = touched && !!error;
                const labelComp = <FormikFormFieldLabel htmlFor={name} label={label} name={name} required={required} />;
                return (
                    <Form.Field>
                        <FormikFormFieldLabel label={labelComp} name={name} required={required} />
                        <BarcodeScannerInput
                            {...rest}
                            onBlur={handleOnBlur}
                            error={hasError}
                            value={value}
                            onChange={handleChange}
                        />
                    </Form.Field>
                );
            }}
        </Field>
    );
};

const FormLeaseNewBottle: React.FC<FormikDefaultFormProps> = (props) => {
    return (
        <FormikDefaultForm debug={true} submitButton={{ content: 'Register' }} {...props}>
            <FormikBarcodeScannerInput label={'Bottle Barcode'} name={'scanCode'} />
        </FormikDefaultForm>
    );
};

const confirm: ConfirmProps = {
    negative: true,
    confirmMessage: {
        header: 'Unlink bottle from pool?',
        content: 'If you proceed, this bottle will not be linked to this pool anymore',
    },
    confirmButton: {
        content: 'Unlink',
    },
    cancelButton: {
        content: 'Keep',
    },
};

const PanelEditBottles: React.FC<{ pool: NodeType.Pool; onDone; onNew }> = ({ pool, onDone, onNew }) => {
    const leases = fromEdges(pool?.poolBottleLeases);
    const button = [
        {
            content: 'Add New',
            icon: 'plus',
            onClick: onNew,
        },
        {
            content: 'Close',
            onClick: onDone,
        },
    ];
    const { processReturn } = useMutationPoolBottleLease({ refetchQueries: ['PoolDetailsQuery'] });

    const onCancel = async (lease: NodeType.PoolBottleLease) => {
        try {
            await processReturn({ id: lease.id });
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Header basic={true} icon={'barcode'} content={'Linked Bottles'} button={button} />
            <Panel.Body>
                {leases.length === 0 && (
                    <div>
                        <small>
                            <i>No bottles linked with this pool</i>
                        </small>
                    </div>
                )}
                <Table>
                    <Table.Body>
                        {leases.map((l) => {
                            return (
                                <Table.Row key={l.id}>
                                    <Table.Cell>
                                        <Icon name="barcode" /> {l.bottle?.scanCode}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Panel.Item label={'Lease Date'}>
                                                <Display.Date value={l.leasedAt} />
                                            </Panel.Item>
                                            {l.returnedAt && (
                                                <Panel.Item label={'Return Date'}>
                                                    <Display.Date value={l.returnedAt} defaultValue={'--'} />
                                                </Panel.Item>
                                            )}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell textAlign={'right'}>
                                        <ButtonWithConfirm confirm={confirm} basic={true} onClick={() => onCancel(l)}>
                                            Remove
                                        </ButtonWithConfirm>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Panel.Body>
        </Panel>
    );
};

export const PanelBottleLeases: React.FC<{ pool: NodeType.Pool }> = ({ pool }) => {
    const modalCtlNew = useModalCtrl(false);
    const modalCtlEdit = useModalCtrl(false);

    const { issueLease } = useMutationPoolBottleLease({ refetchQueries: ['PoolDetailsQuery'] });

    const leases = fromEdges(pool?.poolBottleLeases);

    const onAdd = () => {
        modalCtlEdit.onClose();
        modalCtlNew.onOpen();
    };
    const onEdit = () => {
        modalCtlNew.onClose();
        modalCtlEdit.onOpen();
    };

    const button = [
        {
            content: 'Add Bottle',
            icon: 'plus',
            onClick: onAdd,
        },
    ];

    if (leases.length > 0) {
        button.push({
            content: 'Manage',
            icon: 'edit',
            onClick: onEdit,
        });
    }

    const initialValues = {
        pool: pool,
        scanCode: null,
    };

    const onRegisterNew = async (values: typeof initialValues) => {
        try {
            await issueLease({
                pool: pool.id,
                scanCode: values.scanCode,
            });
            modalCtlNew.onClose();
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <>
            <Panel>
                <Panel.Header basic={true} icon={'barcode'} content={'Linked Bottles'} button={button} />
                <Panel.Body>
                    {leases.length > 0 && (
                        <div className={'flex flex-row gap-4 items-center'}>
                            {leases.map((l) => {
                                return (
                                    <div className={'py-0 px-2 border rounded-sm'} key={l.id}>
                                        <Icon name="barcode" /> {l.bottle?.scanCode}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Panel.Body>
            </Panel>

            {modalCtlNew.open && (
                <Modal {...modalCtlNew}>
                    <FormLeaseNewBottle
                        initialValues={initialValues}
                        onSubmit={onRegisterNew}
                        onCancel={modalCtlNew.onClose}
                    />
                </Modal>
            )}

            {modalCtlEdit.open && (
                <Modal {...modalCtlEdit}>
                    <PanelEditBottles onDone={modalCtlEdit.onClose} pool={pool} onNew={onAdd} />
                </Modal>
            )}
        </>
    );
};
