import React from 'react';
import { findDOMNode } from 'react-dom';
import clsx from 'clsx';

import * as dates from './utils/dates';
import { notify } from './utils/helpers';
import { dateCellSelection, getSlotAtX, pointInBox } from './utils/selection';
import Selection, { getBoundsForNode, isEvent } from './Selection';
import { ACTION_NOTIFICATION } from './types';
import { RBCContext } from './CalendarContext';

export interface BackgroundCellsProps {
    date?: Date;
    getNow: () => Date;
    container?: () => HTMLElement;
    dayPropGetter?: () => any;
    selectable: boolean | 'ignoreEvents';
    onSelectSlot: (slotInfo: any) => void;
    onSelectEnd?: (slotInfo: any) => void;
    onSelectStart?: (slotInfo: any) => void;
    range: Date[];
    type?: string;
}

class BackgroundCells extends React.Component<BackgroundCellsProps, any> {
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;
    private _selector: Selection;
    private _initial = {};

    constructor(props, context) {
        super(props, context);

        this.state = {
            selecting: false,
        };
    }

    componentDidMount() {
        this.props.selectable && this._selectable();
    }

    componentWillUnmount() {
        this._teardownSelectable();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectable && !this.props.selectable) this._selectable();
        if (!nextProps.selectable && this.props.selectable) this._teardownSelectable();
    }

    render() {
        let { range, getNow, date: currentDate } = this.props;
        const {
            getters,
            components: { dateCellWrapper: Wrapper },
        } = this.context;
        let { selecting, startIdx, endIdx } = this.state;
        let current = getNow();

        return (
            <div>
                {range.map((date, index) => {
                    let selected = selecting && index >= startIdx && index <= endIdx;
                    const { className, style } = getters.dayProp(date);

                    const c = clsx(
                        'rbc-day-bg',
                        className,
                        selected && 'rbc-selected-cell',
                        dates.eq(date, current, 'day') && 'rbc-today',
                        currentDate && dates.month(currentDate) !== dates.month(date) && 'rbc-off-range-bg'
                    );

                    return (
                        <Wrapper key={index} value={date} range={range}>
                            <div style={style} className={c} />
                        </Wrapper>
                    );
                })}
            </div>
        );
    }

    _selectable() {
        let node = findDOMNode(this);
        let selector = (this._selector = new Selection(this.props.container, {
            longPressThreshold: this.context.longPressThreshold,
            debugKey: 'BackgroundCell',
        }));

        let selectorClicksHandler = (point, actionType) => {
            if (!isEvent(findDOMNode(this), point)) {
                let rowBox = getBoundsForNode(node);
                let { range } = this.props;
                const { rtl } = this.context;

                if (pointInBox(rowBox, point)) {
                    let currentCell = getSlotAtX(rowBox, point.x, rtl, range.length);

                    this._selectSlot({
                        startIdx: currentCell,
                        endIdx: currentCell,
                        action: actionType,
                        box: point,
                    });
                }
            }

            this._initial = {};
            this.setState({ selecting: false });
        };

        selector.on(ACTION_NOTIFICATION.selecting, (box) => {
            const { range } = this.props;
            const { rtl } = this.context;

            let startIdx = -1;
            let endIdx = -1;

            if (!this.state.selecting) {
                notify(this.props.onSelectStart, [box]);
                this._initial = { x: box.x, y: box.y };
            }
            if (selector.isSelected(node)) {
                let nodeBox = getBoundsForNode(node);
                ({ startIdx, endIdx } = dateCellSelection(this._initial, nodeBox, box, range.length, rtl));
            }

            this.setState({
                selecting: true,
                startIdx,
                endIdx,
            });
        });

        selector.on(ACTION_NOTIFICATION.beforeSelect, (box) => {
            if (this.props.selectable !== 'ignoreEvents') return;

            return !isEvent(findDOMNode(this), box);
        });

        selector.on(ACTION_NOTIFICATION.click, (point) => selectorClicksHandler(point, ACTION_NOTIFICATION.click));

        selector.on(ACTION_NOTIFICATION.doubleClick, (point) =>
            selectorClicksHandler(point, ACTION_NOTIFICATION.doubleClick)
        );

        selector.on(ACTION_NOTIFICATION.selected, (bounds) => {
            this._selectSlot({
                ...this.state,
                action: ACTION_NOTIFICATION.selected,
                bounds,
            });
            this._initial = {};
            this.setState({ selecting: false });
            notify(this.props.onSelectEnd, [this.state]);
        });
    }

    _teardownSelectable() {
        if (!this._selector) return;
        this._selector.teardown();
        this._selector = null;
    }

    _selectSlot(conf: any) {
        const { endIdx, startIdx, action, bounds, box } = conf;
        if (endIdx !== -1 && startIdx !== -1) {
            this.props.onSelectSlot?.({
                start: startIdx,
                end: endIdx,
                action,
                bounds,
                box,
            });
        }
    }
}

export default BackgroundCells;
