import * as React from 'react';
import {
    FormikDefaultForm,
    FormikFormFieldLabel,
    FormikInputField,
    FormikSelectField,
    Optionable,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { getApiClient, NodeType, useMutationCustomerTag } from '@poolware/api';
import { useField } from 'formik';
import { FormikSuggestInputOrganisationType, useOrgSwitcher } from '@poolware/app-shell';

const apiClient = getApiClient();

export interface PageProps {}

const FormikFileInput: React.FC<{ name; label?: string; onDidChange?: (files: FileList) => any }> = ({
    name,
    label,
    onDidChange,
}) => {
    const [field, meta, helpers] = useField({ name: name });

    const onChange = (event) => {
        const fileList = event?.currentTarget?.files;
        helpers.setValue(fileList);
        onDidChange?.(fileList);
    };

    return (
        <div className={'field'}>
            <FormikFormFieldLabel name={name} htmlFor={name} label={label} />
            <input id={name} name={name} type="file" onChange={onChange} />
        </div>
    );
};

const typeOptions: Optionable<NodeType.ProductDocumentType>[] = [
    {
        value: NodeType.ProductDocumentType.Brochure,
        text: NodeType.ProductDocumentType.Brochure,
    },
    {
        value: NodeType.ProductDocumentType.Manual,
        text: NodeType.ProductDocumentType.Manual,
    },
    {
        value: NodeType.ProductDocumentType.Photo,
        text: NodeType.ProductDocumentType.Photo,
    },
    {
        value: NodeType.ProductDocumentType.Other,
        text: NodeType.ProductDocumentType.Other,
    },
];

export const PageNew: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator<{ id?: string }>();
    const { create } = useMutationCustomerTag({ refetchQueries: ['QueryCustomerTagsConnection'] });
    const { organisation } = useOrgSwitcher();

    const initialValues = {
        title: '',
        files: [] as File[],
        type: NodeType.ProductDocumentType.Brochure,
        organisation: organisation,
    };

    const goBack = () => {
        AppNavigator.navigateRelative('/');
    };

    const onSubmit = async (values: typeof initialValues) => {
        for (const file of values.files) {
            const result = await apiClient.uploadProductDocumentFile({
                file: file,
                title: values.title,
                type: NodeType.ProductDocumentType.Brochure,
                organisationType: values?.organisation?.id,
            });
            AppNavigator.navigateRelative(`/${result.id}`);
        }
    };

    return (
        <FormikDefaultForm
            header={'New Product Document'}
            icon={'file'}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={goBack}
            debug={true}
        >
            {(bag) => {
                const onFileSelected = (files: FileList) => {
                    if (!bag.touched?.title) {
                        bag.setFieldValue('title', files?.[0]?.name);
                    }
                };
                return (
                    <>
                        <FormikFileInput name={'files'} label={'File'} onDidChange={onFileSelected} />
                        <FormikInputField name={'title'} label={'Document Title'} />
                        <FormikSelectField name={'type'} label={'Type'} options={typeOptions} />
                        <FormikSuggestInputOrganisationType name={'organisation'} label={'Organisation'} />
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};
