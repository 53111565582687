import * as React from 'react';

import { Display, FormTableRowWithRadiobox, Icon, Table } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';

const TableRowAddress = ({ label, address, onSelect, isSelected, icon }) => {
    if (!address && !label && !onSelect) return null;

    const handelClick = () => {
        // deselect if selected
        onSelect && onSelect(isSelected ? null : address);
    };

    const formattedLabel = (
        <>
            <Icon name={icon} /> <b>{label}</b> {address && <Display.Address value={address} />}
        </>
    );

    return <FormTableRowWithRadiobox onClick={handelClick} isSelected={isSelected} label={formattedLabel} />;
};

const TableRowPool = ({ pool, onSelect, ...rest }: any) => {
    const { name, address } = pool;
    const label = `Pool: "${name}"`;

    const handelClick = () => {
        // deselect if selected
        onSelect && onSelect(rest.isSelected ? null : pool);
    };
    return <TableRowAddress {...rest} onSelect={handelClick} label={label} address={address} icon={'life ring'} />;
};

interface CustomerAddressListProps {
    customer: NodeType.Customer;
    selectedPoolId?: NodeType.ID;
    selectedAddressId?: NodeType.ID;
    onPoolSelect?: (pool: NodeType.Pool) => any;
    onAddressSelect?: (address: NodeType.Address) => any;
}

export const CustomerAddressList: React.FC<CustomerAddressListProps> = (props) => {
    const { customer, onAddressSelect, onPoolSelect, selectedAddressId, selectedPoolId } = props;
    const billAddress = customer?.billAddress;
    const primaryAddress = customer?.primaryAddress;
    const sites: NodeType.Site[] = []; // fromEdges(_.get(customer, 'sites'));

    return (
        <Table size={'small'} compact padded={false} celled={false} basic>
            <Table.Body>
                {sites.map((site) => {
                    const { pools } = site;
                    const poolRows = fromEdges(pools).map((pool) => (
                        <TableRowPool
                            key={pool.id}
                            pool={pool}
                            isSelected={selectedPoolId === pool.id}
                            onSelect={onPoolSelect}
                        />
                    ));

                    return [...poolRows];
                })}
                {primaryAddress && (
                    <TableRowAddress
                        label="Primary Address"
                        address={primaryAddress}
                        icon={'building outline'}
                        onSelect={onAddressSelect}
                        isSelected={selectedAddressId === primaryAddress.id}
                    />
                )}
                {billAddress && (
                    <TableRowAddress
                        label="Billing Address"
                        icon={'building outline'}
                        address={billAddress}
                        onSelect={onAddressSelect}
                        isSelected={selectedAddressId === billAddress.id}
                    />
                )}
            </Table.Body>
        </Table>
    );
};
