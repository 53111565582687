import * as React from 'react';
import invariant from 'invariant';
import { Alert, AlertHeader, Icon, Modal, PageSkeletonLoader, toastError, useModalCtrl } from '@ez/components';
import { NodeType, useMutationWorkOrder } from '@poolware/api';
import { useAppointmentItemQuery } from '../use-appointment-item-query';
import { ApptDeleteMode, DeleteAppointmentButtons } from './DeleteAppointmentButtons';
import { confirmFutureImperative } from '../../Components/ConfirmFuture';
import { useBookingActions } from '../../../redux';
import { useAppointmentMutators } from '../../../queries/use-appointment-mutators';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppointmentDetailsPageMode } from './AppointmentDetailsContent.PageMode';
import { AppointmentDetailsPreviewMode } from './AppointmentDetailsContent.PreviewMode';
import { AppointmentDetailsViewCompProps } from './types';
import { useViewer, withErrorBoundary } from '@poolware/app-shell';

export interface AppointmentDetailsProps {
    appointmentItem?: NodeType.AppointmentItem;
    onClose: () => any | Promise<any>;
    onEdit: (appointmentItem: NodeType.AppointmentItem) => any | Promise<any>;
    onDidDelete?: () => any | Promise<any>;
    onShowInCalendar?: (item: NodeType.AppointmentItem) => any | Promise<any>;
    onSwitchToDockMode?: (item: NodeType.AppointmentItem) => any | Promise<any>;
    showServiceJobLink?: boolean;
    mode: 'preview' | 'page';
    appointmentItemId?: NodeType.ID;
    allowDockModeOption?: boolean;
}

const _AppointmentPreviewControl: React.FC<AppointmentDetailsProps> = (props) => {
    const { mode } = props;
    const { params } = useAppNavigator();
    const { modulesAccess } = useViewer();
    const { BookingState, BookingAction } = useBookingActions();
    const id = props.appointmentItemId || params.appointmentItem;
    const {
        appointmentItem: fetchedAI,
        isLoading,
        appointmentGroups,
        canCreateSale,
        refetchQuery,
    } = useAppointmentItemQuery(id);

    const appointmentItem = fetchedAI || props.appointmentItem;

    const refetchQueries = [refetchQuery, 'SchedulerAppointmentsList', 'AppointmentItemsConnectionForServiceCase'];

    const { AppointmentMutator, mutateAppointmentItem } = useAppointmentMutators(refetchQueries);
    const mutateWorkOrder = useMutationWorkOrder({ refetchQueries: refetchQueries });

    const canDelete = !modulesAccess.FieldServices?.calendarSingleStaffMode;
    const canEdit = !modulesAccess.FieldServices?.calendarSingleStaffMode;
    const deleteModal = useModalCtrl();
    const onShowDeleteModal = deleteModal.onOpen;
    const onDeleteCancel = deleteModal.onClose;

    const onClose = () => {
        props.onClose?.();
    };

    const onDelete = async (deleteMode: ApptDeleteMode) => {
        if (!canDelete) {
            toastError({ title: 'Failed to delete appointment', description: 'No enough permissions' });
            onClose();
            return;
        }
        try {
            if (deleteMode === ApptDeleteMode.SINGLE) {
                await mutateAppointmentItem.delete({ id: appointmentItem.id, future: false });
            } else if (deleteMode === ApptDeleteMode.ALLFUTURE) {
                await mutateAppointmentItem.delete({ id: appointmentItem.id, future: true });
            } else if (deleteMode === ApptDeleteMode.WO_AND_APPT) {
                await mutateWorkOrder.delete({
                    id: appointmentItem?.workOrder?.id,
                    keepImplicit: false,
                    keepAppointment: false,
                });
            }
            await props.onDidDelete?.();
            props.onClose();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete appointment', description: e.message });
        }
    };

    const onEdit = () => {
        invariant(props.onEdit, 'onEdit props is required');
        invariant(appointmentItem, 'appointmentItem is required');
        appointmentItem && props.onEdit?.(appointmentItem);
    };

    const onChangeStatus = async (newStatus) => {
        try {
            await mutateAppointmentItem.update({ id: NodeType.extractId(appointmentItem), status: newStatus });

            /// HACK: move this logic to backend.
            const wo = appointmentItem?.workOrder;
            if (!wo?.stage) {
                return;
            }
            if (
                wo.stage?.type === NodeType.ServiceJobStageType.Opened ||
                wo.stage?.type !== NodeType.ServiceJobStageType.InProgress
            ) {
                // Change WO status only if appointment status is changed.
                const smap = {
                    finished: NodeType.ServiceJobStageType.Finished,
                    failed: NodeType.ServiceJobStageType.Canceled,
                };
                const woStatus = smap[newStatus];
                if (woStatus !== undefined) {
                    await mutateWorkOrder.update({ id: wo?.id, stageType: woStatus });
                }
            }

            ///
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change status', description: error.message });
        }
    };

    const onChangeStaff = async (newStaff) => {
        const currentStaffId = appointmentItem?.staff?.id;
        const newStaffId = newStaff?.id;

        if (currentStaffId === newStaffId) {
            return;
        }

        try {
            await AppointmentMutator.changeAppointmentItemStaff(appointmentItem, newStaff);
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change staff', description: error.message });
        }
    };

    const onGroupChange = async (newGroup: NodeType.AppointmentGroup) => {
        const { isRecurring } = appointmentItem;

        const currentGroupId = appointmentItem?.group?.id;
        const newGroupId = newGroup?.id;

        if (currentGroupId === newGroupId) {
            return;
        }

        let future = false;
        if (isRecurring) {
            const res = await confirmFutureImperative();
            if (res === 'cancel') {
                return;
            }
            future = res === 'future';
        }

        try {
            await AppointmentMutator.changeAppointmentItemGroup(appointmentItem, newGroup, future);
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change group', description: error.message });
        }

        if (isRecurring && future) {
            // TODO: HACK: TOFIX:
            // Closing window after updating recurring appointment.
            // Appointment IDs are not fixed for recurrent appointment, they can change after update if future flag is set.
            // The current appointment id can become invalid.
            // By closing this window, I discard current appointment.
            // TODO: need to find a better solution
            onClose();
        }
    };

    const onColorChange = async (newColor) => {
        const { color, isRecurring } = appointmentItem;
        if (color === newColor) {
            return;
        }

        let future = false;
        if (isRecurring) {
            const res = await confirmFutureImperative();
            if (res === 'cancel') {
                return;
            }
            future = res === 'future';
        }

        try {
            await AppointmentMutator.changeAppointmentItemColor(appointmentItem, newColor, future);
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change color', description: error.message });
        }

        if (isRecurring && future) {
            // TODO: HACK: TOFIX:
            // Closing window after updating recurring appointment.
            // Appointment IDs are not fixed for recurrent appointment, they can change after update if future flag is set.
            // The current appointment id can become invalid.
            // By closing this window, I discard current appointment.
            // TODO: need to find a better solution
            onClose();
        }
    };

    const onChangeDescription = async (newDescription: string) => {
        try {
            await AppointmentMutator.updateAppointmentItem(appointmentItem, { note: newDescription });
        } catch (e) {
            console.error(e);
        }
    };
    const onDupe = () => {
        // if not in saga mode yet...
        if (!BookingState.isSagaMode) {
            // Start a new saga
            BookingAction.startAppointmentDupe(appointmentItem);
        }
        onClose();
    };

    const onStartWorkOrder = async () => {
        try {
            await mutateAppointmentItem.startWorkOrder({ id: appointmentItem.id });
        } catch (e) {
            toastError(e);
        }
    };

    const onShowInCalendar = () => {
        props.onShowInCalendar?.(appointmentItem);
    };

    if (isLoading && !appointmentItem) {
        return (
            <div className={'p-2'}>
                <PageSkeletonLoader lineCount={5} />
            </div>
        );
    }

    if (!isLoading && !appointmentItem) {
        return (
            <Alert type={'error'}>
                <AlertHeader>
                    <Icon name={'frown'} /> Failed to load appointment details.
                </AlertHeader>
            </Alert>
        );
    }

    const detailsViewProps: AppointmentDetailsViewCompProps = {
        appointmentItem: appointmentItem,
        appointmentGroups: appointmentGroups,
        AppointmentMutator: AppointmentMutator,
        canCreateSale: canCreateSale,
        canEdit: canEdit,
        onDupe: onDupe,
        onClose: onClose,
        onEdit: onEdit,
        onDelete: canDelete ? onShowDeleteModal : undefined,
        onChangeStatus: onChangeStatus,
        onShowInCalendar: onShowInCalendar,
        onChangeStaff: onChangeStaff,
        onGroupChange: onGroupChange,
        onColorChange: onColorChange,
        onStartWorkOrder: onStartWorkOrder,
        onChangeDescription: onChangeDescription,
        showDockModeOption: props.allowDockModeOption,
        onSwitchToDockMode: () => props.onSwitchToDockMode?.(appointmentItem),
    };

    return (
        <div>
            {mode === 'page' && <AppointmentDetailsPageMode {...detailsViewProps} />}
            {mode === 'preview' && <AppointmentDetailsPreviewMode {...detailsViewProps} />}
            {deleteModal.open && (
                <Modal open={deleteModal.open} onClose={onDeleteCancel} size={'tiny'}>
                    <DeleteAppointmentButtons
                        appointmentItem={appointmentItem}
                        onDelete={onDelete}
                        onCancel={onDeleteCancel}
                    />
                </Modal>
            )}
        </div>
    );
};

export const AppointmentPreviewControl = withErrorBoundary()(
    _AppointmentPreviewControl
) as React.FC<AppointmentDetailsProps>;
