import * as React from 'react';
import { TreeNode, unboxTreeNode } from '../utils';
import { Icon } from '@ez/components';

const defaultItemRenderer = (value) => <span>{value}</span>;

export interface TreeRendererProps<T = any> {
    tree: TreeNode<T>[];
    itemRenderer?: (pd: T) => any;
}

export const TreeRenderer: React.FC<TreeRendererProps> = ({ tree, itemRenderer = defaultItemRenderer }) => {
    const renderTreeItem = (node: TreeNode<any>) => {
        const value = unboxTreeNode(node);
        return (
            <div className={'flex flex-row flex-nowrap'}>
                <Icon name={node.parentId ? 'caret right' : 'caret right'} color={'grey'} />
                <div>
                    {itemRenderer(value)}
                    {node.children && renderTree(node.children)}
                </div>
            </div>
        );
    };

    const renderTree = (tree: any[], root = false) => {
        if (tree.length === 0) return null;
        return (
            <div className={'grid gap-1 text-sm'}>
                {tree.map((pd) => {
                    return renderTreeItem(pd);
                })}
            </div>
        );
    };

    return renderTree(tree, true);
};
