import * as React from 'react';
import { Button, Icon } from '@ez/components';

export const SelectSlotTopMessageBar = ({ leftLabel, centerLabel, onCancel }) => {
    const handleCancel = (e) => {
        e.preventDefault();
        onCancel?.();
    };
    return (
        <div className={'fixed top-0 left-0 right-0 shadow-md h-[46px] bg-orange z-[999]'}>
            <div className={'flex flex-row items-center space-x-4 h-full p-2'}>
                <div className={'flex-1 text-left px-4'}>
                    <Button onClick={handleCancel}>
                        <Icon name={'cancel'} /> Cancel
                    </Button>
                </div>
                <div className={'flex-1 text-center'}>
                    <span className={'text-base md:text-xl font-bold'}>{centerLabel}</span>
                </div>

                <div className={'flex-1 text-right px-4'}>
                    {leftLabel && (
                        <div className={'overflow-hidden text-ellipsis text-white font-bold whitespace-nowrap'}>
                            {leftLabel}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
