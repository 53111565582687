import * as React from 'react';
import { Table, DefaultConnectionTable, Display, PageLayout, PanelItemProps, TableDef } from '@ez/components';
import { InvoiceDoc } from '../constants';
import { NodeType } from '@poolware/api';
import { DisplayCurrency } from '../components/DisplayCurrency';
import { Helmet } from '@poolware/app-shell';

/**
 * 0022060602
 * {
 *     seqGroup: "invoice",
 *     prefix: "INV",
 * }
 * INV 0000001
 *
 *
 * refNumber : {
 *     seqGroup: "invoice",
 *     seqKey: "220606",
 *     digitCount: 7,
 *     prefix: "INV",
 *     postfix: "/internal"
 * }
 */

export const PanelItem: React.FC<PanelItemProps> = (props) => (
    <div className={'flex flex-row pb-1'}>
        <div className={'w-32 font-bold'}>{props.label}</div>
        <div>{props.children}</div>
    </div>
);

const tableDef: TableDef<InvoiceDoc.ItemsType> = [
    {
        header: 'Description',
        cell: (item) => {
            return item.description;
        },
    },
    {
        header: 'Qty',
        cell: (item) => {
            return item.hours;
        },
    },
    {
        header: 'Unit Price',
        cell: (item) => {
            return <DisplayCurrency value={item.rate} />;
        },
    },
    {
        header: 'GST',
        cell: (item) => {
            return <DisplayCurrency value={item.tax} />;
        },
    },
    {
        header: 'Total (inc GST)',
        width: 3,
        cell: (item) => {
            return <DisplayCurrency value={item.total} />;
        },
    },
];

export const InvoiceDocView: React.FC<{ doc: NodeType.AbstractDocument }> = ({ doc }) => {
    const { jsonBody, issuedAt } = doc;
    const invoice = jsonBody as InvoiceDoc.DocContent;

    return (
        <PageLayout width={'screen-xl'}>
            <Helmet title={`Tax Invoice - ${invoice?.invoiceNumber}`} />
            <div className={'text-center mt-8'}>
                <h1>Tax Invoice</h1>
            </div>
            <PageLayout.BodySection>
                {invoice?.sender?.company && (
                    <>
                        <div>{invoice.sender.company.name}</div>
                        <div>ABN: {invoice.sender.company.abn}</div>
                        <div>{invoice.sender.company.address}</div>
                        <div className={'py-2'} />
                        <div>Ph: {invoice.sender.company.phone}</div>
                        <div>Email: {invoice.sender.company.email}</div>
                    </>
                )}
            </PageLayout.BodySection>
            <PageLayout.BodySection className={'mt-8'}>
                <PanelItem wrap={false} label={'Invoice #'}>
                    {invoice.invoiceNumber}
                </PanelItem>
                <PanelItem label={'Date Issued'}>
                    <Display.Date
                        // @ts-ignore
                        value={invoice.issueDate}
                        format={'LL'}
                    />
                </PanelItem>
                <PanelItem label={'To'}>{invoice.receiver?.company?.name}</PanelItem>
                <PanelItem label={''}>{invoice.receiver?.company?.address}</PanelItem>
                {invoice.receiver?.person?.name && (
                    <PanelItem label={'Attn.'}>{invoice.receiver?.person?.name}</PanelItem>
                )}
            </PageLayout.BodySection>
            <PageLayout.BodySection className={'mt-8'}>
                <h3 className={'text-center py-2'}>Details</h3>
                <div className={'text-sm text-gray-700'}>
                    <Display.Text value={invoice.description} />
                </div>
                <DefaultConnectionTable
                    showProgress={false}
                    tableDef={tableDef}
                    connectionData={invoice.items}
                    noDataComponent={null}
                    tableFooter={
                        <>
                            <Table.HeaderCell colSpan="4">TOTAL AMOUNT PAYABLE</Table.HeaderCell>
                            <Table.HeaderCell>
                                <DisplayCurrency value={invoice.totalAmountPayable} />
                            </Table.HeaderCell>
                        </>
                    }
                />
            </PageLayout.BodySection>

            {invoice.paymentOption?.bank && (
                <PageLayout.BodySection className={'mt-8'}>
                    <div className={'text-sm'}>
                        <div>Bank details</div>
                        <PanelItem label={'Bank'}>{invoice.paymentOption.bank.bankName}</PanelItem>
                        <PanelItem label={'BSB'}>{invoice.paymentOption.bank.bankBSB}</PanelItem>
                        <PanelItem label={'Account No'}>{invoice.paymentOption.bank.accountNumber}</PanelItem>
                        <PanelItem label={'Account Name'}>{invoice.paymentOption.bank.accountName}</PanelItem>
                    </div>
                </PageLayout.BodySection>
            )}
            <PageLayout.BodySection className={'my-8'} />
        </PageLayout>
    );
};
