import * as React from 'react';
import { useState } from 'react';
import {
    Alert,
    ConnectionErrorMessage,
    HeaderButtonType,
    PageSkeletonLoader,
    Panel,
    SectionHeader,
    Segment,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Icon, PageLayout } from '@ez/components';
import { useQueryServiceJobTemplate } from '../../queries/use-query-service-job-template';
import { WorkOrderTemplateAssocsView } from '../../ServiceJobTemplatesManager/ServiceJobTemplate/ServiceJobTemplateView/WorkOrderTemplatesAssocsView';
import { ServiceJobTemplateDetailsPanel } from '../../ServiceJobTemplatesManager/ServiceJobTemplate/ServiceJobTemplateView/ServiceJobTemplateDetailsPanel';
import { ReplaceTemplateWizard } from './ReplaceTemplateWizard/ReplaceTemplateWizard';
import { useRecurCaseCtx } from './RecurCaseProvider';

export const PanelServiceJobTemplate: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const [isReplacingTemplate, setReplacingTemplate] = useState(false);
    const { serviceJob, canEdit, isStopped } = useRecurCaseCtx();

    const template = serviceJob.usedTemplate;
    const { node: serviceJobTemplate, loading, error } = useQueryServiceJobTemplate(template?.id, { skip: !template });

    if (!template) {
        return <Alert>Template is not provided</Alert>;
    }
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!serviceJobTemplate) {
        return <Alert type={'error'}>Could not load template details</Alert>;
    }

    const onViewInEditor = () => {
        AppNavigator.navigate(`/service-jobs-templates/sj-template/${template.id}`);
    };

    const onChangeTemplate = () => {
        setReplacingTemplate(true);
    };

    let locked: boolean | string = false;
    if (isStopped) {
        locked = 'Service Stopped';
    } else if (!canEdit) {
        locked = true;
    }

    const button: HeaderButtonType[] = [
        {
            icon: 'external',
            content: 'View in Editor',
            onClick: onViewInEditor,
        },
        {
            icon: 'retweet',
            content: 'Change Template',
            locked: locked,
            onClick: onChangeTemplate,
        },
    ];

    if (isReplacingTemplate) {
        return <ReplaceTemplateWizard onCancel={() => setReplacingTemplate(false)} serviceJob={serviceJob} />;
    }

    return (
        <VStack>
            <SectionHeader button={button}>Service Job Template</SectionHeader>
            <Alert>
                <Icon name={'info circle'} /> Service Job Template that is used to create work orders and appointments.
            </Alert>

            <div className={'mb-4'} />

            <PageLayout.BodySection width={'screen-wide'}>
                <ServiceJobTemplateDetailsPanel template={serviceJobTemplate} readOnly={true} canEdit={canEdit} />
                <Panel.SectionHeader>Work Order Templates</Panel.SectionHeader>
                <Segment>
                    <WorkOrderTemplateAssocsView serviceJobTemplate={serviceJobTemplate} />
                </Segment>
            </PageLayout.BodySection>
        </VStack>
    );
};
