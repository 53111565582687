import * as React from 'react';
import { useMemo } from 'react';

import { SidebarSecondaryMenuItemNavLink, useAppConfig, useViewer } from '@poolware/app-shell';
import { AppSidebarConfig, AppSidebarMenuItem, AppSidebarSecondary, SemanticICONS } from '@ez/components';

export interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = ({}) => {
    const { viewer, modulesAccess, isPretending } = useViewer();
    const appConfig = useAppConfig();

    const createItemConf = (input: {
        to: string;
        icon?: SemanticICONS;
        secondaryIcon?: SemanticICONS;
        label: string | React.ReactNode;
        sub?: boolean;
        exact?: boolean;
        as?: any;
    }): AppSidebarMenuItem => {
        const { exact, icon, to, sub, label, secondaryIcon, as } = input;
        return {
            to,
            icon,
            exact,
            label: label,
            secondaryIcon: secondaryIcon,
            as: as || SidebarSecondaryMenuItemNavLink,
            sub: sub,
        };
    };

    const config: AppSidebarConfig = useMemo(() => {
        const hasIntegrations = modulesAccess.VendIntegration?.admin || modulesAccess.WaterlinkIntegration?.admin;
        // HACK: use isPretending to allow export in pretending mode
        const canExport = modulesAccess.Export?.enabled || isPretending;
        const settingsItems: AppSidebarMenuItem[] = [
            hasIntegrations &&
                createItemConf({
                    to: '/settings/integrations',
                    icon: 'puzzle',
                    label: 'Integrations',
                }),
            modulesAccess.FieldServices?.caseTemplates &&
                createItemConf({
                    to: '/settings/jobs-templates',
                    icon: 'clone outline',
                    label: 'Job Templates',
                }),
            modulesAccess.WaterTest?.enabled &&
                createItemConf({
                    to: '/settings/water-treatment',
                    icon: 'lab',
                    label: 'Water Treatment',
                }),
            modulesAccess.Customers?.enabled &&
                createItemConf({
                    to: '/settings/customer-tags',
                    icon: 'tags',
                    label: 'Customer Tags',
                }),
            modulesAccess.Emails?.admin &&
                createItemConf({
                    to: '/settings/sent-emails',
                    icon: 'mail',
                    label: 'Sent Emails',
                }),
            canExport &&
                createItemConf({
                    to: '/settings/export',
                    icon: 'share square',
                    label: 'Data Export',
                }),
        ].filter(Boolean);

        return {
            menu: [
                {
                    header: 'Settings',
                    items: settingsItems,
                },
            ],
        };
    }, [appConfig, viewer, isPretending, modulesAccess]);

    return <AppSidebarSecondary config={config} />;
};
