import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionErrorMessage, PageSkeletonLoader, Panel, SectionHeader } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { useQueryWorkOrderTemplate } from '../../../../WorkOrderTemplate/use-query-work-order-template';
import { NotFoundPage } from '@poolware/app-shell';
import { ModuleIconNames } from '../../../../../constants';
import { JobTodoTemplatePreview } from './JobTodoTemplatePreview';
import {
    JobsContainer,
    SummaryContainer,
} from '../../../../../ServiceJobs/WorkOrderView/JobTodosListViewMode/componets-styled';
import { getWorkOrderTemplateSummary } from '../../../../../ServiceJobs/WorkOrderView/utils';
import { JobTodoRowContainer } from '../../WorkOrderTemplatesAssocsEdit/WorkOrderTemplate/components';

export interface WorkOrderTemplateProps {
    workOrderTemplateId: NodeType.ID;
}

const WorkOrderTemplateDetails: React.FC<{ workOrderTemplate: NodeType.WorkOrderTemplate }> = ({
    workOrderTemplate,
}) => {
    return (
        <>
            <JobTodoRowContainer>
                <div className={'row-content'}>
                    <div className={'row-content-main'}>
                        <div className={'row-content-details'} style={{ paddingLeft: '0.5rem' }}>
                            <Panel.Item labelWidth={'100%'} label={'Work Order Title'}>
                                <h2 style={{ fontSize: '1.8em', marginBottom: '0.5em' }}>
                                    {workOrderTemplate.templateTitle}
                                </h2>
                            </Panel.Item>
                            <Panel.ItemText
                                labelWidth={'100%'}
                                label={'Work Order Description'}
                                content={workOrderTemplate.templateDescription || '--'}
                            />
                        </div>
                    </div>
                </div>
            </JobTodoRowContainer>
        </>
    );
};

const WorkOrderTemplateTodoAssoc = ({ workOrderTemplate }) => {
    const sortedJobTodoTemplateAssocs = useMemo(() => {
        return fromEdges<NodeType.JobTodoTemplateAssoc>(workOrderTemplate?.jobTodoTemplateAssocs).sort((l, r) => {
            return l.priority > r.priority ? 1 : -1;
        });
    }, [workOrderTemplate]);

    const workOrderSummary = useMemo(() => {
        return getWorkOrderTemplateSummary(sortedJobTodoTemplateAssocs.map((i) => i.jobTodoTemplate));
    }, [sortedJobTodoTemplateAssocs]);

    const totalTimeString = workOrderSummary.totalTime ? `${workOrderSummary.totalTime} min` : '--';

    return (
        <>
            <SectionHeader>Job Tasks</SectionHeader>
            <SummaryContainer>
                <div className={'summary-row'}>
                    <div className={'summary-row-label'}>Total Duration:</div>
                    <div className={'summary-row-value'}>{totalTimeString}</div>
                </div>
                {false && (
                    <div className={'summary-row'}>
                        <div className={'summary-row-label'}>Total Cost:</div>
                        <div className={'summary-row-value'}>$ {workOrderSummary.totalPrice}.00</div>
                    </div>
                )}
            </SummaryContainer>

            <JobsContainer>
                {sortedJobTodoTemplateAssocs.map((assoc, index) => {
                    return <JobTodoTemplatePreview key={index} jobTodoTemplate={assoc.jobTodoTemplate} />;
                })}
            </JobsContainer>
        </>
    );
};
export const WorkOrderTemplate: React.FC<WorkOrderTemplateProps> = ({ workOrderTemplateId }) => {
    const { loading, error, node: workOrderTemplate } = useQueryWorkOrderTemplate(workOrderTemplateId);

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !workOrderTemplate) {
        return <NotFoundPage />;
    }

    const isGlobal = !Boolean(workOrderTemplate.ownedByServiceJobTemplate?.id);

    return (
        <div>
            <SectionHeader icon={ModuleIconNames.WorkOrder}>
                Work Order Template {isGlobal ? ' - TEMPLATE' : ''}
            </SectionHeader>
            <WorkOrderTemplateDetails workOrderTemplate={workOrderTemplate} />
            <div className={'my-2'} />
            <WorkOrderTemplateTodoAssoc workOrderTemplate={workOrderTemplate} />
        </div>
    );
};
