import * as React from 'react';
import { useMemo, useState } from 'react';
import { deserializeColorPalette, NodeType } from '@poolware/api';
import { usePanelNavigationStack } from '../../Components/PanelNavigationStack';
import {
    Alert,
    confirmModalImperative,
    Display,
    FormGroup,
    FormikDefaultForm,
    FormikTextareaField,
    Icon,
    MenuBar,
    Panel,
    ScrollableLayout,
    Switch,
    toastError,
} from '@ez/components';
import TimeFieldGroup from '../../AppointmentForm/FormFields/TimeField';
import AppointmentGroupField from '../../AppointmentForm/FormFields/AppointmentGroupField';
import ColorField from '../../AppointmentForm/FormFields/ColorField';
import { FormikCustomerAndLocationInputFields, FormikSuggestInputStaff, useViewer } from '@poolware/app-shell';
import * as _ from 'lodash';
import { getAppointmentChangeDiff } from '../../AppointmentForm/common';
import { PanelAppointmentDiff } from '../../AppointmentForm/PanelAppointmentDiff';
import {
    UpdateAppointmentItemFutureInput,
    UpdateAppointmentItemSingleInput,
    useAppointmentMutators,
} from '../../../queries/use-appointment-mutators';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import { PanelItemServiceJob } from '../PanelWorkOrder/PanelWorkOrder.Instantiated';
import { useAppointmentViewCtx } from '../AppointmentViewContext';
import {
    RelatedAppointmentsMode,
    RelatedAppointmentsTable,
} from '../PanelRelatedAppointments/RelatedAppointmentsTable';
import { useFormikContext } from 'formik';
import { extractRecurrenceData } from '../../utils';
import { AppointmentEditRecur } from './AppointmentEdit.Recur';

export interface AppointmentEditTimeProps {
    appointmentItem: NodeType.AppointmentItem;
    appointmentItemMutator: ReturnType<typeof useAppointmentMutators>;
    future: boolean;
}

type AppointmentFormValuesType = {
    startDate: Date;
    duration?: number;
    isRecurring?: boolean;
    isRecurringService?: boolean;
    recurrence?: NodeType.Recurrence;
    customer: NodeType.Customer | null;
    customerReadOnly?: boolean;
    staff: NodeType.Staff | null;
    staffReadOnly?: boolean;
    pool: NodeType.Pool | null;
    serviceJob: NodeType.ServiceJob | null;
    workOrder: NodeType.WorkOrder | null;
    appointmentItem: NodeType.AppointmentItem | null;
    address: NodeType.Address | null;
    color?: string;
    group?: NodeType.AppointmentGroup;
    note?: string;
    sjTemplate?: NodeType.ServiceJobTemplate;
    woTemplate?: NodeType.WorkOrderTemplate;
};

interface AppointmentEditBaseDataForm {
    future?: boolean;
}

export const AppointmentEditBaseDataFormContent: React.FC<AppointmentEditBaseDataForm> = ({ future }) => {
    const formContext = useFormikContext<AppointmentFormValuesType>();
    const {
        staffReadOnly,
        customerReadOnly,
        appointmentItem,
        isRecurring,
        isRecurringService,
        customer,
        address,
        pool,
        group,
    } = formContext.values;

    const { recurrence } = extractRecurrenceData(appointmentItem);
    const palette = useMemo(() => deserializeColorPalette(group), [group]);

    let poolString = '--';
    if (pool) {
        const poolName = _.truncate(pool?.name || '--', { length: 40 });
        const poolVolume = pool?.volume || '--';
        const poolType = pool?.type?.name || '--';
        poolString = pool ? `${poolName} (${poolVolume} L, ${poolType})` : '--';
    }

    const onEditLocation = () => {
        formContext.setFieldValue('customerReadOnly', false);
    };

    const editLocationButton = {
        icon: 'edit',
        onClick: onEditLocation,
    };

    return (
        <>
            {(isRecurringService || customerReadOnly) && (
                <div className={'p-2 mb-8 bg-tertiary rounded border'}>
                    {isRecurringService && <PanelItemServiceJob serviceJob={appointmentItem?.serviceJob} />}
                    {customerReadOnly && (
                        <>
                            <Panel.SectionHeader
                                size={'small'}
                                icon={'address card outline'}
                                content={'Customer / Location'}
                                button={editLocationButton}
                            />
                            <Panel.Item label="Customer" labelIcon="user">
                                {customer ? (
                                    <>
                                        <Display.Entity value={customer} /> / {customer.crn}
                                    </>
                                ) : (
                                    '--'
                                )}
                            </Panel.Item>
                            <Panel.Item label="Address" labelIcon="marker">
                                <Display.Address value={address} showMapLink={false} />
                            </Panel.Item>
                            <Panel.Item label="Pool" labelIcon="life ring">
                                {poolString}
                            </Panel.Item>
                        </>
                    )}
                </div>
            )}

            {future && isRecurring && (
                <>
                    <Panel.ItemDate content={appointmentItem.startDate} label={'Apply from Date'} />
                </>
            )}

            <TimeFieldGroup
                monthsShown={2}
                showDay={!future}
                dateLabel={future ? 'Date' : 'Appointment Date'}
                timeLabel={future ? 'Appointment Time' : 'Time'}
                // size={'tiny'}
            />
            {recurrence && (
                <div className={'text-gray-500 text-sm mb-2'}>Appointment TZ: {recurrence?.range?.timeZone}</div>
            )}
            <FormGroup>
                <AppointmentGroupField label={'Appointment Group'} name={'group'} />
                <ColorField label={'Color Tag'} name={'color'} palette={palette} />
            </FormGroup>
            <FormikSuggestInputStaff label={'Assigned To'} readOnly={staffReadOnly} name={'staff'} />
            {!customerReadOnly && <FormikCustomerAndLocationInputFields readOnly={customerReadOnly} />}

            <Panel.Divider hidden={true} />
            <FormikTextareaField
                data-testid={'TextareaField-notes'}
                rows={10}
                name={'note'}
                label="Notes"
                required={false}
            />
        </>
    );
};

export const AppointmentEditBaseData: React.FC<AppointmentEditTimeProps> = ({ appointmentItem, future: futureExt }) => {
    const { modulesAccess } = useViewer();
    const previewItemCtx = useAppointmentViewCtx();
    const panelStack = usePanelNavigationStack();
    const [future, setFuture] = useState(futureExt);

    const { isRecurring, isRecurringService } = extractRecurrenceData(appointmentItem);
    const customerReadOnly = isRecurring;
    const staffReadOnly = !!modulesAccess.FieldServices?.calendarSingleStaffMode;

    const initialValues: AppointmentFormValuesType = {
        appointmentItem: appointmentItem,
        serviceJob: appointmentItem.serviceJob,
        workOrder: appointmentItem.workOrder,
        staff: appointmentItem.staff,
        customer: appointmentItem.customer,
        address: appointmentItem.address,
        pool: appointmentItem.pool,
        note: appointmentItem.note,
        group: appointmentItem.group,
        color: appointmentItem.color,
        startDate: new Date(appointmentItem?.startDate),
        duration: appointmentItem.duration,
        staffReadOnly: staffReadOnly,
        customerReadOnly: customerReadOnly,
        isRecurringService: isRecurringService,
    };

    const onCancel = () => {
        panelStack.popStackPanel();
    };

    const onEditRecurrence = () => {
        panelStack.replaceStackPanel({
            component: AppointmentEditRecur,
            props: { appointmentItem: appointmentItem },
        });
    };

    const performMutationSingle = async (input: { values: AppointmentFormValuesType; diff: any }) => {
        const { values, diff } = input;
        const { appointmentItem } = values;
        const { startDate, duration, ...restDiff } = diff;

        const mutationValues: UpdateAppointmentItemSingleInput = {
            ...restDiff,
        };

        if (diff.startDate || diff.duration) {
            // Start Date or duration changed.
            // Both startDate and duration values are required.
            mutationValues.date = values.startDate;
            mutationValues.duration = values.duration;
        }

        const res = await previewItemCtx.appointmentItemMutator.AppointmentMutator.updateAppointmentItemSingle(
            appointmentItem,
            mutationValues
        );
        previewItemCtx.refreshId(res.data?.AppointmentItem?.AppointmentItem?.id);
    };

    const performMutationFuture = async (input: { values: AppointmentFormValuesType; diff: any }) => {
        const { values, diff } = input;

        const { appointmentItem } = values;
        const { startDate, duration, ...restDiff } = diff;

        const mutationValues: UpdateAppointmentItemFutureInput = {
            ...restDiff,
        };

        if (diff.startDate || diff.duration) {
            // Start Date or duration changed.
            // Both startDate and duration values are required.
            mutationValues.dateTime = values.startDate;
            mutationValues.duration = values.duration;
        }
        const res = await previewItemCtx.appointmentItemMutator.AppointmentMutator.updateAppointmentItemFuture(
            appointmentItem,
            mutationValues
        );
        previewItemCtx.refreshId(res.data?.AppointmentItem?.AppointmentItem?.id);
    };

    const handleOnSubmit = async (values: AppointmentFormValuesType) => {
        try {
            const diff = getAppointmentChangeDiff(values, initialValues);
            if (_.isEmpty(diff)) {
                panelStack.popStackPanel();
                return;
            }

            if (_.isEmpty(diff)) {
                // TODO:
                return;
            }

            if (future) {
                const res = await confirmModalImperative({
                    confirmMessage: {
                        header: 'Update Future Appointments?',
                        content: <PanelAppointmentDiff diff={diff} />,
                    },
                    confirmButton: { content: 'Update Future', negative: false },
                    cancelButton: { content: 'Cancel' },
                });

                if (!res) {
                    return;
                }
                await performMutationFuture({ values, diff });
            } else {
                await performMutationSingle({ values, diff });
            }
            panelStack.popStackPanel();
        } catch (error) {
            console.error(error);
            toastError(error);
        }
    };

    let headerTitle = future ? 'Edit Future Appointments' : 'Edit Appointment';
    const submitButtonLabel = !isRecurring ? 'Update' : future ? 'Update Future' : 'Update Single';

    return (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={onCancel} icon={'chevron left'} />
                    <MenuBar.HeaderItem>
                        <Icon color={ModuleColorNames.Appointment} name={ModuleIconNames.Appointment} />
                        {headerTitle}
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    {isRecurring && <MenuBar.Item onClick={onEditRecurrence} icon={'edit'} title={'Edit Recurrence'} />}
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>

            <ScrollableLayout.BodyScroll>
                <div className={'py-4 mb-4 text-base'}>
                    <FormikDefaultForm
                        size={'small'}
                        // validationSchema={validationSchema}
                        header={headerTitle}
                        icon={ModuleIconNames.Appointment}
                        initialValues={initialValues}
                        submitButton={{ content: submitButtonLabel }}
                        onSubmit={handleOnSubmit}
                        onCancel={onCancel}
                    >
                        <AppointmentEditBaseDataFormContent future={future} />
                        {isRecurring && (
                            <div>
                                <div className={'my-4 py-2 px-4 rounded border flex flex-row justify-between'}>
                                    <span className={'text-base'}>Apply to future appointments</span>
                                    <Switch onCheckedChange={(checked) => setFuture(checked)} checked={future} />
                                </div>
                            </div>
                        )}
                    </FormikDefaultForm>

                    {future && isRecurring && (
                        <div className={'mt-2 p-2'}>
                            <Alert type={'info'}>
                                <p>
                                    Starting from the current appointment (
                                    <Display.Date value={appointmentItem.startDate} format={'l'} />)
                                    <br /> ALL future appointments will be updated.
                                </p>
                            </Alert>
                            <div className={'text-sm mt-2'}>
                                <RelatedAppointmentsTable
                                    appointmentItem={appointmentItem}
                                    mode={RelatedAppointmentsMode.Future}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};
