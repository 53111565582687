import * as React from 'react';
import moment from 'moment';
import {
    Display,
    PrintSection,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintSectionProps,
    Icon,
} from '@ez/components';

import { getLabelForAppointmentStatus, IconAppointmentStatus } from '@poolware/app-service-jobs';
import { SectionHeader } from '@ez/components';

const sortAndSplitAppointment = (appointments) => {
    const now = moment();

    appointments = appointments.map((a) => {
        return {
            ...a,
            startDate: moment(a.startDate), // convert startDate to moment object.
        };
    });

    const pastAppointments = appointments
        .filter(({ startDate }) => startDate.isBefore(now, 'd'))
        .sort((a, b) => {
            return a.startDate.isBefore(b.startDate) ? 1 : -1;
        });

    const futureAppointments = appointments
        .filter(({ startDate }) => startDate.isAfter(now, 'd'))
        .sort((a, b) => {
            return a.startDate.isBefore(b.startDate) ? -1 : 1;
        });

    const todayAppointments = appointments
        .filter(({ startDate }) => startDate.isSame(now, 'd'))
        .sort((a, b) => {
            return a.startDate.isBefore(b.startDate) ? -1 : 1;
        });

    return {
        pastAppointments: pastAppointments,
        todayAppointments: todayAppointments,
        futureAppointments: futureAppointments,
    };
};

const AppointmentsListItem = ({ appointment }) => {
    const { startDate, duration, staff, status } = appointment;
    return (
        <div className={'flex flex-row items-center gap-2 border-b border-gray-200 py-1'}>
            <div className={'w-4'}>
                <Icon name="calendar outline" />
            </div>
            <div className={'flex-grow'}>
                <div>
                    <Icon name={'clock'} />
                    <Display.DateRange startDate={startDate} duration={duration} />
                </div>
                <IconAppointmentStatus status={status} /> Status: {getLabelForAppointmentStatus(status)}
                {staff && (
                    <div>
                        <Icon name="spy" />
                        <Display.Entity value={staff} />
                        <br />
                    </div>
                )}
            </div>
        </div>
    );
};

const AppointmentsList: React.FC<{ appointments }> = ({ appointments }) => (
    <div className={'grid'}>
        {appointments.map((a) => (
            <AppointmentsListItem key={a.id} appointment={a} />
        ))}
    </div>
);

interface TabAppointmentsProps extends PrintSectionProps {
    customerId: string;
    appointments: any[];
}

class Appointments extends React.PureComponent<TabAppointmentsProps> {
    public static defaultProps = {
        appointments: [],
    };

    render() {
        const sortedAppointments = sortAndSplitAppointment(this.props.appointments);
        const { pastAppointments, todayAppointments, futureAppointments } = sortedAppointments;

        const { showPrintToggle, noPrint, onPrintChange } = this.props;

        const panelProps = { showPrintToggle, noPrint, onPrintChange };
        return (
            <PrintSection {...panelProps}>
                <PrintSectionHeader>Appointments</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn paddingVal={2}>
                        <SectionHeader size={'small'} content={'Past Appointments'} />
                        <AppointmentsList appointments={pastAppointments} />
                    </PrintSectionColumn>
                    <PrintSectionColumn paddingVal={2}>
                        {todayAppointments.length > 0 && (
                            <>
                                <SectionHeader size={'small'} content={'Today'} />
                                <AppointmentsList appointments={todayAppointments} />
                            </>
                        )}
                        <SectionHeader size={'small'} content={'Future Appointments'} />
                        <AppointmentsList appointments={futureAppointments} />
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSection>
        );
    }
}

export default Appointments;
