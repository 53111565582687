import { combineReducers } from 'redux';
import { reducer } from './reducer-warranty-booking';

export const reducerWarranty = combineReducers({
    booking: reducer,
});

export const deserializeServiceJob = (restoredState?: ReturnType<typeof reducerWarranty>) => {
    return {
        booking: restoredState?.booking,
    };
};

export const serializeServiceJob = (state?: ReturnType<typeof reducerWarranty>) => {
    return {
        booking: state?.booking,
    };
};
