import * as React from 'react';
import { useEffect, useState } from 'react';
import { fromEdges, NodeType, TestReportMutatorsProps } from '@poolware/api';
import Dosage from './Dosage';
import Instructions from '../Components/Instructions';
import { Alert, Checkbox, Icon, Segment, Table, toastError } from '@ez/components';
import { wait } from '@ez/tools';
import tw, { styled } from 'twin.macro';

const TableRowStyledSm = styled(Table.Row)`
    ${tw`table-row md:hidden`}
`;
const TableRowStyledLg = styled(Table.Row)`
    ${tw`hidden md:table-row`}
`;

const TableHeaderCellStyled = styled(Table.HeaderCell)`
    ${tw`hidden md:table-cell`}
`;

const PrintIcon = ({ isSelected, loading }) => {
    const color = isSelected ? 'green' : 'grey';
    const iconName = isSelected ? 'check' : 'cancel';
    const text = isSelected ? <span>Treatment will be printed</span> : <span>Treatment will not be printed</span>;

    return (
        <span>
            <Icon color={color} name={'print'} />
            {loading ? <Icon name={'spinner'} color={color} loading /> : <Icon color={color} name={iconName} />}
            <br />
            <span style={{ fontSize: '0.8em' }}>{text}</span>
        </span>
    );
};

interface TreatmentRecommendationsTableProps extends TestReportMutatorsProps {
    isArchived: boolean;
    recommendation: NodeType.TestRecommendation;
    data?: any;
}

const InstructionAccordion: React.FC<{ sampleInstruction: string; defaultExpand?: boolean }> = ({
    sampleInstruction,
    defaultExpand,
}) => {
    const [expanded, setExpanded] = useState(defaultExpand);
    return (
        <div>
            <div
                onClick={() => setExpanded(!expanded)}
                className={'font-bold mt-2 underline hover:cursor-pointer flex flex-row items-baseline'}
            >
                <div>Instructions</div>
                <Icon name={expanded ? 'caret down' : 'caret right'} />
            </div>
            {expanded && (
                <div>
                    <Instructions content={sampleInstruction || 'No instructions available'} />
                </div>
            )}
        </div>
    );
};

export default class TreatmentRecommendationsTable extends React.Component<TreatmentRecommendationsTableProps, any> {
    state = {
        isMutating: false,
    };

    onRecommendedTreatmentSelect = async (recommendedTreatment, isSelected) => {
        this.setState({ isMutating: true });

        const recommendation = this.props.recommendation;
        try {
            await this.props.TestReportMutator.selectTreatment({
                testRecommendation: recommendation,
                testTreatment: isSelected ? recommendedTreatment.treatment : null,
            });
        } catch (e) {
            toastError({
                title: 'Failed to perform operation',
                description: e.message,
            });
            console.error(e);
        }

        // HACK: Reduces visual glitch.
        // The switch flips back and forth due to to the delay happening
        // between mutation finish and a new data arrival.
        await wait(800);
        this.setState({ isMutating: false });
    };

    onOverrideDosage = async (recommendedTreatment: NodeType.RecommendedTreatment, newDosage: number) => {
        try {
            await this.props.TestReportMutator.overrideRecommendedTreatment(recommendedTreatment, {
                dosage: newDosage,
            });
        } catch (e) {
            toastError({
                title: 'Failed to update dosage',
                description: e.message,
            });
            console.error(e);
        }
    };

    onResetRecommendedTreatment = async (recommendedTreatment: NodeType.RecommendedTreatment) => {
        try {
            await this.props.TestReportMutator.resetRecommendedTreatment(recommendedTreatment);
        } catch (e) {
            toastError({
                title: 'Failed to perform operation',
                description: e.message,
            });
            console.error(e);
        }
    };

    render() {
        const { isMutating } = this.state;
        const { isArchived, recommendation, data = {} } = this.props;
        const { selectedTreatment, recommendedTreatments } = recommendation;

        const deEdgedRecommendedTreatments = fromEdges(recommendedTreatments);

        if (deEdgedRecommendedTreatments.length === 0) return <i>No treatment available</i>;

        const showSpinner = isMutating || data.loading;

        return (
            <div style={{ overflowX: 'auto' }}>
                <Table unstackable compact={'very'} className={'compact-header'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width="10">Available Treatments</Table.HeaderCell>
                            <TableHeaderCellStyled textAlign="center" width="3">
                                Dosage
                            </TableHeaderCellStyled>
                            <TableHeaderCellStyled textAlign="center" colSpan={2}>
                                Use Treatment
                            </TableHeaderCellStyled>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {deEdgedRecommendedTreatments.map((recommendedTreatment) => {
                            const radical = recommendedTreatment.treatment.isRadical;
                            const productName = recommendedTreatment?.treatment?.consumableProduct?.product?.name;
                            const isSelected = selectedTreatment?.id === recommendedTreatment.id;

                            return (
                                <React.Fragment key={recommendedTreatment.id}>
                                    <TableRowStyledSm>
                                        <Table.Cell colSpan={2}>
                                            <div>
                                                {productName && (
                                                    <div className={'p-2'}>
                                                        <Icon name="cube" color="blue" />
                                                        <b>{productName}</b>
                                                    </div>
                                                )}
                                                <div className={'rounded bg-panel gap-1 grid grid-cols-2'}>
                                                    <Segment
                                                        className={
                                                            'flex flex-col items-center justify-center text-center'
                                                        }
                                                    >
                                                        <Dosage
                                                            isArchived={isArchived}
                                                            recommendedTreatment={recommendedTreatment}
                                                            onOverrideDosage={this.onOverrideDosage}
                                                            onReset={this.onResetRecommendedTreatment}
                                                        />
                                                    </Segment>
                                                    <Segment
                                                        className={
                                                            'gap-2 flex flex-col items-center justify-center text-center rounded-l-0'
                                                        }
                                                    >
                                                        <Checkbox
                                                            checked={isSelected}
                                                            toggle
                                                            disabled={showSpinner || isArchived}
                                                            onChange={(e, data) =>
                                                                this.onRecommendedTreatmentSelect(
                                                                    recommendedTreatment,
                                                                    data.checked
                                                                )
                                                            }
                                                        />
                                                        <PrintIcon loading={showSpinner} isSelected={isSelected} />
                                                    </Segment>
                                                </div>
                                                <div className={'p-0'}>
                                                    <InstructionAccordion
                                                        defaultExpand={isSelected}
                                                        sampleInstruction={recommendedTreatment.sampleInstruction}
                                                    />
                                                </div>
                                                {radical && (
                                                    <Alert type={'warning'}>
                                                        <Icon name="warning sign" color="yellow" />
                                                        This treatment is drastic and will nullify all other treatments!
                                                    </Alert>
                                                )}
                                            </div>
                                        </Table.Cell>
                                    </TableRowStyledSm>

                                    <TableRowStyledLg>
                                        <Table.Cell verticalAlign={'top'}>
                                            {productName && (
                                                <>
                                                    <span
                                                        className={'mr-2 px-2 py-1 text-sm bg-blue text-white rounded'}
                                                    >
                                                        <Icon name="cube" />
                                                        Product
                                                    </span>
                                                    <b style={{ color: '#3784cf' }}>{productName}</b>
                                                </>
                                            )}
                                            {radical && (
                                                <Alert type={'warning'} className={'my-2'}>
                                                    <Icon name="warning sign" />
                                                    This treatment is drastic and will nullify all other treatments!
                                                </Alert>
                                            )}
                                            <InstructionAccordion
                                                defaultExpand={isSelected}
                                                sampleInstruction={recommendedTreatment.sampleInstruction}
                                            />
                                        </Table.Cell>

                                        <Table.Cell verticalAlign={'top'} textAlign={'center'}>
                                            <Dosage
                                                isArchived={isArchived}
                                                recommendedTreatment={recommendedTreatment}
                                                onOverrideDosage={this.onOverrideDosage}
                                                onReset={this.onResetRecommendedTreatment}
                                            />
                                        </Table.Cell>
                                        <Table.Cell verticalAlign={'top'} textAlign={'center'} width="3">
                                            <div style={{ fontSize: '0.9em', marginTop: '5px' }}>
                                                <PrintIcon loading={showSpinner} isSelected={isSelected} />
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell verticalAlign={'top'} textAlign={'center'} width="1">
                                            <div style={{ marginTop: '5px' }}>
                                                <Checkbox
                                                    checked={isSelected}
                                                    toggle
                                                    disabled={showSpinner || isArchived}
                                                    onChange={(e, data) =>
                                                        this.onRecommendedTreatmentSelect(
                                                            recommendedTreatment,
                                                            data.checked
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Table.Cell>
                                    </TableRowStyledLg>
                                </React.Fragment>
                            );
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}
