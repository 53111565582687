import * as React from 'react';
import Script from 'react-load-script';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { getConfig } from '../../../config';
import { parseGeocodeResults } from './geocode-result-parser';
import { AddressType, LatLng } from './constants';
import { makeDebugger } from '@ez/tools';
import { Button } from '@ez/components';

let debug = makeDebugger('gmaps:address-lookup');

const config = getConfig();

// declare global {
//     const google: any;
// }

interface AddressLookupProps {
    onAddressSelect?: (address: AddressType) => any;
    startLocation?: LatLng;
}

interface AddressLookupState {
    token?: string;
    address: string;
    details?: any;
    completerReady: boolean;
    completionError: boolean;
    fetchingDetails: boolean;
}

export class AddressLookup extends React.Component<AddressLookupProps, AddressLookupState> {
    constructor(props, context) {
        super(props, context);
        // @ts-ignore
        this.state = {
            address: '',
            completerReady: false,
            completionError: false,
            token: config.google_maps.enabled ? config.google_maps.apiToken : null,
            fetchingDetails: false,
        };
    }

    static defaultProps: AddressLookupProps = {
        startLocation: {
            lat: -34,
            lng: 141,
        },
    };

    onCompleterChange = async (address) => {
        this.setState({ address: address });
    };

    selectAddressCompletion = async () => {
        const address = this.state.address;
        try {
            this.setState({ fetchingDetails: true });
            const details = await geocodeByAddress(address);
            this.setState({ fetchingDetails: false });

            debug(details);
            const res = parseGeocodeResults(details?.[0]);
            debug(res);
            if (res) {
                this.props.onAddressSelect?.(res);
            }
        } catch (e) {
            this.setState({ fetchingDetails: false });
        }
    };

    makeCompletionReady = () => {
        this.setState({ completerReady: true });
    };

    completionError = () => {
        this.setState({ completionError: true });
    };

    render() {
        if (!this.state.token) {
            return null;
        }
        if (this.state.completionError) {
            return null;
        }

        const inputProps = {
            value: this.state.address || '',
            placeholder: 'Search Address ...',
            onChange: this.onCompleterChange,
            autoComplete: 'off',
        };

        if (this.state.completerReady) {
            const styles = {
                autocompleteContainer: { zIndex: 100 },
            };

            const { startLocation } = this.props;
            const loc = startLocation
                ? new google.maps.LatLng(startLocation.lat, startLocation.lng)
                : new google.maps.LatLng(-34, 141);

            const options = {
                types: ['address'],
                location: loc,
                radius: 4500,
            };

            return (
                <div className={'p-4 bg-panel-section rounded-t'}>
                    <div className={'font-bold mb-2'}>Address Look Up</div>
                    <div className={'flex flex-row gap-1 items-center'}>
                        <div className={'flex-grow'}>
                            <PlacesAutocomplete inputProps={inputProps} styles={styles} options={options} />
                        </div>
                        <Button
                            content="Select"
                            size={'md'}
                            loading={this.state.fetchingDetails}
                            onClick={this.selectAddressCompletion}
                        />
                    </div>
                </div>
            );
        } else {
            const token = this.state.token;
            if (token) {
                const apiUrl = `https://maps.googleapis.com/maps/api/js?key=${token}&libraries=places`;
                return <Script url={apiUrl} onError={this.completionError} onLoad={this.makeCompletionReady} />;
            } else {
                return null;
            }
        }
    }
}

export default AddressLookup;
