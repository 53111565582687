import * as React from 'react';
import { NodeType } from '@poolware/api';
import { AppointmentStatusUiConf } from '../utils';
import { useViewer } from '@poolware/app-shell';
import { ContainerButtonsChangeStatus } from '../../components/ButtonsChangeJobStage';
import { Button, ButtonWithPopup, ButtonWithPopupProps, Icon, Modal } from '@ez/components';

interface ButtonChangeStatusProps extends AppointmentStatusUiConf {
    relaxed?: boolean;
    onChangeStatus: (type: NodeType.AppointmentStatusEnum) => any;
    size?: ButtonWithPopupProps['size'];
}

const ButtonChangeStatus: React.FC<ButtonChangeStatusProps> = ({
    active,
    title,
    type,
    icon,
    size = 'md',
    onChangeStatus,
}) => {
    const iconComp = icon && <Icon {...icon} style={{ lineHeight: 1 }} inverted={active} />;
    return (
        <ButtonWithPopup
            variant={active ? 'primary' : 'secondary'}
            color={active ? 'primary' : 'tertiary'}
            onClick={() => onChangeStatus(type)}
            size={size}
            iconLeft={iconComp}
            className={'px-1 py-1 align-middle gap-0'}
        >
            {title}
        </ButtonWithPopup>
    );
};

export const AppointmentStatusButtons: React.FC<{
    buttonConfigs: AppointmentStatusUiConf[];
    onChangeStatus: (type: NodeType.AppointmentStatusEnum) => any;
}> = (props) => {
    const { appLayoutMode } = useViewer();

    if (appLayoutMode.gteTablet) {
        return (
            <div className={'p-2 py-4 border-0 border-b border-red-300 border-solid'}>
                <div className={'flex flex-row flex-wrap gap-1'}>
                    {props.buttonConfigs.map((conf, index) => {
                        return (
                            <ButtonChangeStatus
                                size={'sm'}
                                key={index}
                                {...conf}
                                onChangeStatus={props.onChangeStatus}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    const activeButton = props.buttonConfigs.find((c) => c.active);
    return (
        <>
            <ContainerButtonsChangeStatus>
                <div className={'flex flex-row'}>
                    <b>Status</b>:
                    <div className={'pl-3'}>
                        <Icon {...activeButton.icon} /> {activeButton?.title}
                    </div>
                </div>
                <Modal
                    centered={false}
                    trigger={
                        <Button //
                            size={'lg'}
                            color={'blue'}
                            content={'Change Status'}
                        />
                    }
                >
                    <div className={'flex-grow flex flex-col gap-4 p-4 bg-white rounded shadow'}>
                        {props.buttonConfigs.map((conf, index) => {
                            return (
                                <ButtonChangeStatus
                                    relaxed={true}
                                    key={index}
                                    size={'lg'}
                                    {...conf}
                                    onChangeStatus={props.onChangeStatus}
                                />
                            );
                        })}
                    </div>
                </Modal>
            </ContainerButtonsChangeStatus>
        </>
    );
};
