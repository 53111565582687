import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Button, Display, MenuBar, Panel, PanelItemProps, ScrollableLayout, VStack } from '@ez/components';
import { ModuleLink } from '@poolware/react-app-navigator';
import * as URLBuilder from '../../../../routes/url-builder';
import { AdminOnly } from '@poolware/app-shell';
import { useQueryEzcDeviceLink } from '../../View/use-query-ezc-device-link';
import { EZCDevicesLinkWithState } from '../use-query-ezc-device-states';
import { DeviceState } from '../LayoutCards/DeviceCard';

export interface DevicePreviewPanelProps {
    device: EZCDevicesLinkWithState;
    onClose: () => void;
    onView: (item: NodeType.EZCDeviceLink) => void;
}

export const DevicePreviewPanel: React.FC<DevicePreviewPanelProps> = ({ device: extDeviceLink, onClose, onView }) => {
    const { loading, node, error, refetchQuery } = useQueryEzcDeviceLink(extDeviceLink.id);
    const deviceLink = node || extDeviceLink;

    const owner = deviceLink?.owner;
    const pool = deviceLink?.pool;
    const device = deviceLink?.device;
    const deviceState = extDeviceLink?.deviceState;
    const poolLink = owner && pool ? URLBuilder.Customer(owner.id).Pool(pool.id).view : null;

    return (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar className={'rounded-b-none'}>
                <MenuBar.Section>
                    <MenuBar.HeaderItem content={'Device'} />
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item content={'Close'} icon={'close'} onClick={onClose} />
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>
            <ScrollableLayout.BodyScroll>
                <VStack className={'p-1'}>
                    <Button className={'mt-1'} variant={'primary'} onClick={() => onView(deviceLink)}>
                        View Device
                    </Button>
                    <Panel>
                        <Panel.Header content={'Owner'} />
                        <Panel.Body>
                            <PanelItem label={'Owner Name'}>
                                {owner && (
                                    <ModuleLink moduleId={'CUSTOMERS'} to={`/${owner?.id}`}>
                                        <Display.Entity value={owner} />
                                    </ModuleLink>
                                )}
                            </PanelItem>
                            <PanelItem label={'Address'}>
                                <Display.Address value={owner?.primaryAddress} defaultValue={' '} />
                            </PanelItem>
                            <PanelItem label={'Phone'}>
                                <Display.Phone value={owner?.primaryPhone?.data} />
                                {owner?.primaryPhone?.label && (
                                    <span className={'pl-2 text-tertiary italic'}>({owner?.primaryPhone?.label})</span>
                                )}
                            </PanelItem>
                            <PanelItem label={'Email'}>
                                <Display.Email value={owner?.primaryEmail?.data} />
                                {owner?.primaryEmail?.label && (
                                    <span className={'pl-2 text-tertiary italic'}>({owner?.primaryEmail?.label})</span>
                                )}
                            </PanelItem>
                            {pool && (
                                <>
                                    <Panel.Divider />
                                    <PanelItem label={'Pool'}>
                                        {poolLink ? (
                                            <ModuleLink relativeToModule={false} to={poolLink}>
                                                {pool.name}
                                            </ModuleLink>
                                        ) : (
                                            pool.name
                                        )}
                                    </PanelItem>
                                    <PanelItem label={'Pool Volume'}>{pool.volume}L</PanelItem>
                                </>
                            )}
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Header content={'Device'} />
                        <Panel.Body>
                            <PanelItem label={'Title'} content={device?.title} />
                            <PanelItem label={'Device ID'} content={device?.deviceId} />
                            <Panel.ItemDate label={'Registered'} content={device?.createdAt} labelWidth={80} />
                            <AdminOnly>
                                <PanelItem label={'Definition'} content={device?.definition?.name} />
                                <Panel.Divider />
                                <PanelItem
                                    label={'Organisation'}
                                    content={deviceLink.franchise?.organisationType?.name}
                                />
                                <PanelItem label={'Franchise'} content={deviceLink.franchise?.name} />
                            </AdminOnly>
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Header content={'Status'} />
                        <Panel.Body className={'p-1'}>
                            <DeviceState deviceState={deviceState} stateLoading={false} />
                        </Panel.Body>
                    </Panel>
                </VStack>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};

const PanelItem: React.FC<PanelItemProps> = (props) => <Panel.Item labelWidth={80} {...props} />;
