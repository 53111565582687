import * as React from 'react';
import { useEffect } from 'react';
import {
    Button,
    ButtonWithPopupProps,
    DebugJsonButton,
    Icon,
    MenuBar,
    MenuBarGroupMODE,
    ScrollableLayout,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import { extractRecurrenceData, mapConfFromStatusMap } from '../utils';
import { ModuleColorNames } from '../../constants';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useViewer } from '@poolware/app-shell';
import * as URLBuilder from '../url-builder';
import VendSaleButton from '../AppointmentPreview/AppointmentDetailsContent/VendSaleButton';
import { useBookingActions, useCalendarActions } from '../../redux';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';
import { usePanelNavigationStack } from '../Components/PanelNavigationStack';
import { PanelRelatedAppointments } from './PanelRelatedAppointments/PanelRelatedAppointments';
import { AppointmentStatusButtons } from './AppointmentView.HeaderStatusButtons';
import { AppointmentEditMode } from './components';
import { useAppointmentViewCtx } from './AppointmentViewContext';

const defaultProps: ButtonWithPopupProps = {
    size: 'xs',
    variant: 'secondary',
};

const MenuBarStyled = ScrollableLayout.MenuBar;
//const MenuBarStyled = styled(ScrollableLayout.MenuBar)`
//     &&& {
//         ${tw`border-0 border-t-2 border-red-500 border-solid rounded-t m-0 rounded-b-none`}
//     }
// `;

export interface AppointmentViewDockHeaderProps {
    appointmentItem?: NodeType.AppointmentItem;
    onEdit: (mode: AppointmentEditMode) => any | Promise<any>;
    onDelete?: (item: NodeType.AppointmentItem) => any | Promise<any>;
    onClose: (item: NodeType.AppointmentItem) => any | Promise<any>;
    appointmentGroups;
    onChangeStatus;
    canCreateSale: boolean;
    onShowInCalendar?: (item: NodeType.AppointmentItem) => any | Promise<any>;
    canEdit: boolean;
    canShowDockModePin: boolean;
}

export const AppointmentViewHeader: React.FC<AppointmentViewDockHeaderProps> = (props) => {
    const { modulesAccess } = useViewer();
    const { AppNavigator, location } = useAppNavigator();
    const { BookingState, BookingAction } = useBookingActions();
    const { CalendarState, CalendarAction } = useCalendarActions();
    const CalLayoutState = useCalendarLayoutState();
    const panelStack = usePanelNavigationStack();
    const previewItemCtx = useAppointmentViewCtx();

    const { appointmentItem } = props;
    const { AppointmentMutator } = previewItemCtx.appointmentItemMutator;

    const onViewRelatedAppointments = () => {
        panelStack.pushStackPanel({
            component: PanelRelatedAppointments,
            props: { appointmentItem: appointmentItem },
        });
    };

    useEffect(() => {
        // onViewRelatedAppointments();
    }, [appointmentItem]);

    const { isRecurring } = extractRecurrenceData(appointmentItem);
    let headerTitle = 'Appointment';
    if (appointmentItem?.serviceJob?.isRecurring) {
        headerTitle = 'Appointment (Recurring Service)';
    } else if (appointmentItem?.isRecurring) {
        headerTitle = 'Appointment (Recurring)';
    }

    const headerIcon = isRecurring ? 'refresh' : 'calendar outline';

    if (!appointmentItem) {
        return null;
    }

    const canPrint = modulesAccess.System?.printAccess;

    const onPrint = () => {
        const url = URLBuilder.Print().AppointmentItem(appointmentItem.id).view;
        AppNavigator.navigate(url, { setOrigin: true });
    };

    const onDupe = () => {
        // if not in saga mode yet...
        if (!BookingState.isSagaMode) {
            // Start a new saga
            BookingAction.startAppointmentDupe(appointmentItem);
        }
    };

    const onShowInCalendar = () => {
        CalendarAction.showAppointmentInCalendar(appointmentItem, CalendarState.viewMode);
    };

    const onReschedule = () => {
        // if not in saga mode yet...
        if (!BookingState.isSagaMode) {
            // Start a new saga
            BookingAction.startAppointmentReschedule({ appointmentItem, returnTo: location });
        }
    };

    const onDockModeEnable = () => {
        CalLayoutState.setDockState(true);
    };

    const { status } = appointmentItem;
    const buttonConfs = mapConfFromStatusMap(status);

    const showDockPin = props.canShowDockModePin && CalLayoutState.canOpenDock && !CalLayoutState.isDockOpen;

    return (
        <div className={'flex-none bg-panel z-10 shadow-md rounded-t'}>
            <ScrollableLayout.MenuBar className={'rounded-b-none'}>
                <MenuBar.Section>
                    <MenuBar.Item onClick={props.onClose} icon={'cancel'} />
                    <MenuBar.HeaderItem icon={<Icon color={ModuleColorNames.Appointment} name={headerIcon} />}>
                        {headerTitle}
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    {showDockPin && (
                        <MenuBar.Item
                            icon={'pin'}
                            popup={{ content: 'Show in Dock Mode' }}
                            onClick={onDockModeEnable}
                        />
                    )}
                    <MenuBar.Group
                        menu={{
                            color: 'red',
                            icon: 'bars',
                            direction: 'left',
                            menuMode: MenuBarGroupMODE.DROPDOWN,
                        }}
                        items={[
                            {
                                icon: 'trash',
                                content: 'Delete...',
                                onClick: props.onDelete,
                                disabled: !props.onDelete,
                            },
                        ]}
                    />
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>

            <div className={'m-0'}>
                <div className={'gap-2 p-2 py-4 w-full flex flex-row flex-wrap bg-tertiary'}>
                    {canPrint && (
                        <Button {...defaultProps} icon="print" content="Print" color="blue" onClick={onPrint} />
                    )}
                    <Button
                        {...defaultProps}
                        icon="edit"
                        content="Edit"
                        color="purple"
                        onClick={() => props.onEdit(AppointmentEditMode.Single)}
                        disabled={!props.canEdit}
                        locked={!props.canEdit}
                    />
                    <Button
                        {...defaultProps}
                        color={'grey'}
                        icon="eye"
                        content="Show in Calendar"
                        onClick={onShowInCalendar}
                    />

                    <Button
                        {...defaultProps} //
                        color={'grey'}
                        icon="copy"
                        content="Duplicate"
                        onClick={onDupe}
                    />
                    {props.canEdit && (
                        <Button
                            {...defaultProps}
                            icon={'clock'}
                            content="Reschedule"
                            color="grey"
                            onClick={onReschedule}
                        />
                    )}
                    {isRecurring && (
                        <Button
                            {...defaultProps}
                            icon={'list'}
                            content="Related Appointments"
                            color="grey"
                            onClick={onViewRelatedAppointments}
                        />
                    )}
                    <VendSaleButton
                        buttonProps={defaultProps}
                        AppointmentMutator={AppointmentMutator}
                        appointmentItem={appointmentItem}
                        canCreateSale={props.canCreateSale}
                    />

                    <DebugJsonButton
                        {...defaultProps} //
                        debug={{ jsonExpandLevel: 1 }}
                        data={appointmentItem}
                    />
                </div>
            </div>

            <AppointmentStatusButtons onChangeStatus={props.onChangeStatus} buttonConfigs={buttonConfs} />
        </div>
    );
};
