import * as React from 'react';
import { PageControlProps } from './Page.Control';
import {
    Display,
    Icon,
    PrintSection,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintSectionProps,
    PrintTable,
} from '@ez/components';

const { TABLE, TBODY, TD, THEAD, TR } = PrintTable;

class Page extends React.Component<PageControlProps & PrintSectionProps> {
    render() {
        const { reports, ...rest } = this.props;
        return (
            <PrintSection {...rest}>
                <PrintSectionHeader>Water Test History</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn>
                        {reports.length === 0 && <span>No water test report</span>}
                        <TABLE>
                            <THEAD>
                                <tr>
                                    <td />
                                    <td>Date</td>
                                    <td>Submitted by</td>
                                </tr>
                            </THEAD>
                            <TBODY>
                                {reports.map((report) => {
                                    return (
                                        <TR key={report.id}>
                                            <TD width={'4%'}>
                                                <Icon name={'lab'} />
                                            </TD>
                                            <TD width={'35%'}>
                                                <Display.Date value={report.createdAt} />
                                            </TD>
                                            <TD>
                                                <Display.Entity value={report.reportBy} />
                                            </TD>
                                        </TR>
                                    );
                                })}
                            </TBODY>
                        </TABLE>
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSection>
        );
    }
}

export default Page;
