import * as React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { clsxm } from '../utils';

const AlertHeader: React.FC = ({ children }) => {
    return <div className={'font-bold'}>{children}</div>;
};

const AlertContent: React.FC = ({ children }) => {
    return <div>{children}</div>;
};

const alertCVA = cva('ez-alert', {
    variants: {
        type: {
            info: 'p-4 rounded-md bg-blue-100 text-blue-900',
            success: 'p-4 rounded-md bg-green-100 text-green-900',
            warning: 'p-4 rounded-md bg-orange-100 text-yellow-900',
            error: 'p-4 rounded-md bg-red-100 text-red-900',
            default: 'p-4 rounded-md bg-gray-100 text-gray-900',
        },
    },
    defaultVariants: {
        type: 'default',
    },
});

export interface AlertProps extends VariantProps<typeof alertCVA> {
    className?: string;
    header?: React.ReactNode;
    content?: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({ type, className, header, content, children, ...props }) => {
    return (
        <div className={clsxm([alertCVA({ type }), className])} {...props}>
            {header && <AlertHeader>{header}</AlertHeader>}
            {content && <AlertContent>{content}</AlertContent>}
            <AlertContent>{children}</AlertContent>
        </div>
    );
};

export { Alert, AlertHeader, AlertContent };
