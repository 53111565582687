import * as React from 'react';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import {
    ModuleNavLink,
    ModuleRedirect,
    ModuleRoot,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import { ServiceJobTemplateNew } from './ServiceJobTemplate/New/Page';
import { ServiceJobTemplateList } from './ServiceJobTemplate/List/Page';
import { ServiceJobTemplateView } from './ServiceJobTemplate/ServiceJobTemplateView';
import { WorkOrderTemplateNew } from './WorkOrderTemplate/WorkOrderTemplateNew';
import { WorkOrderTemplateView } from './WorkOrderTemplate/WorkOrderTemplateView/WorkOrderTemplateView';
import { WorkOrderTemplateList } from './WorkOrderTemplate/WorkOrderTemplateList';
import { Icon } from '@ez/components';
import { JobTodoTemplateEdit, JobTodoTemplateList, JobTodoTemplateNew, JobTodoTemplateView } from './JobTodoTemplate';
import { ModuleIconNames } from '../constants';
import { ServiceGroupsEditor } from './ServiceJobGroup/ServiceGroupEdit/ServiceGroupsEditor';

const StubPage = () => {
    const nav = useAppNavigator();
    return (
        <div>
            Stub Page <button onClick={() => nav.history.goBack()}>go back</button>
        </div>
    );
};

const SectionsNavbar = () => {
    const { AppNavigator } = useAppNavigator();
    const { isAdmin, modulesAccess } = useViewer();
    const allowSJTemplates = modulesAccess.FieldServices?.caseTemplates;
    const allowTaskTemplates = modulesAccess.FieldServices?.serviceJobTaskTemplates;

    if (AppNavigator.isModal || !allowSJTemplates) {
        return null;
    }

    //  TODO: DUPE: Extract as separate Navbar Item component
    return (
        <div className={'flex flex-row bg-white rounded p-2 mb-2 gap-2'}>
            {allowSJTemplates && (
                <div className={'p-2 px-4 has-[.active]:bg-gray-300 rounded'}>
                    <ModuleNavLink to={'/sj-template'}>
                        <Icon name={ModuleIconNames.ServiceCase} />
                        Service Case Templates
                    </ModuleNavLink>
                </div>
            )}
            {allowTaskTemplates && (
                <div className={'p-2 px-4 has-[.active]:bg-gray-300 rounded'}>
                    <ModuleNavLink to={'/job-todo-template'}>
                        <Icon name={ModuleIconNames.WorkOrderItem} /> Job Task Templates
                    </ModuleNavLink>
                </div>
            )}

            {isAdmin && false && (
                <div className={'p-2 px-4 has-[.active]:bg-gray-300 rounded'}>
                    <ModuleNavLink to={'/wo-template'}>
                        <Icon name={ModuleIconNames.WorkOrder} />
                        Work Order Templates
                    </ModuleNavLink>
                </div>
            )}
        </div>
    );
};

export const Router = () => {
    const defaultRedirect = '/sj-template';

    return (
        <>
            <ModuleRoot>
                <ModuleRoute path={'/sj-template'} exact={true} component={SectionsNavbar} />
                <ModuleRoute path={'/job-todo-template'} exact={true} component={SectionsNavbar} />

                <ModuleSwitch notFound={NotFoundPage}>
                    <ModuleRedirect path={'/'} exact to={defaultRedirect} />

                    <ModuleRoute path={'/sj-template/edit-groups'} component={ServiceGroupsEditor} />
                    <ModuleRoute path={'/sj-template/new'} component={ServiceJobTemplateNew} />
                    <ModuleRoute path={`/sj-template/:id`} component={ServiceJobTemplateView} />
                    <ModuleRoute path={'/sj-template'} component={ServiceJobTemplateList} />

                    <ModuleRoute path={'/wo-template/new'} component={WorkOrderTemplateNew} />
                    <ModuleRoute path={`/wo-template/:id`} component={WorkOrderTemplateView} />
                    <ModuleRoute path={'/wo-template'} component={WorkOrderTemplateList} />

                    <ModuleRoute path={`/job-todo-template/new`} component={JobTodoTemplateNew} />
                    <ModuleRoute path={`/job-todo-template/:id/edit`} component={JobTodoTemplateEdit} />
                    <ModuleRoute path={`/job-todo-template/:id`} component={JobTodoTemplateView} />
                    <ModuleRoute path={`/job-todo-template`} component={JobTodoTemplateList} />

                    <ModuleRoute path={`/stub/:id`} component={StubPage} />
                </ModuleSwitch>
            </ModuleRoot>
        </>
    );
};
