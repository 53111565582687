import * as React from 'react';
import { SuspenseSkeletonLoader } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NotFoundPage } from '@poolware/app-shell';
import { useQueryWarrantyClaim } from '../use-query-warranty-claim';
import { PagePreview } from './Page.Preview';
import { PageSubmitted } from './Page.Submitted';

export interface PageProps {}

export const PageView: React.FC<PageProps> = () => {
    const { params } = useAppNavigator<{ id?: string }>();
    const { node: claim, error, loading, refetchQuery } = useQueryWarrantyClaim(params.id);
    if (loading || error) {
        return <SuspenseSkeletonLoader error={error} loading={loading} />;
    }

    if (!loading && !claim) {
        return <NotFoundPage />;
    }

    const isSubmitted = Boolean(claim.submittedAt);
    if (isSubmitted) {
        return <PageSubmitted claim={claim} refetchQuery={refetchQuery} />;
    } else {
        return <PagePreview claim={claim} refetchQuery={refetchQuery} />;
    }
};
