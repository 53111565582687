import * as React from 'react';
import { useMemo } from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Icon, ConnectionTableDef, DefaultConnectionTable, Display, Panel } from '@ez/components';
import { AppColorNames, AppIconNames } from '../../../../components/AppIcons';
import _truncate from 'lodash/truncate';
import { IconServiceJobRecurStatus } from '@poolware/app-service-jobs';

const useTableDefRecur = (): ConnectionTableDef<NodeType.ServiceJob> => {
    return useMemo(
        () => [
            {
                header: 'ID',
                width: 1,
                cell: (serviceJob) => {
                    return (
                        <div>
                            <IconServiceJobRecurStatus serviceJob={serviceJob} />
                            {serviceJob.serviceJobNumber}
                        </div>
                    );
                },
            },
            {
                header: 'Service',
                cell: (serviceJob) => {
                    const title = _truncate(serviceJob.title || '--', { length: 60 });
                    return (
                        <div>
                            <Icon name={AppIconNames.ServiceJob.RecurringService} /> {title}
                        </div>
                    );
                },
            },
            {
                header: 'Opened',
                width: 2,
                cell: (v) => <Display.Date format={'ll'} value={v.createdAt} />,
            },
            {
                header: 'Last Appt.',
                width: 2,
                cell: (v) => {
                    return (
                        <span>
                            <Display.Date format={'ll'} value={v.lastAppointmentDate} defaultString={<i>no end</i>} />
                        </span>
                    );
                },
            },
        ],
        []
    );
};

interface PanelCustomerNotesProps {
    customer: NodeType.Customer;
    onViewAll: () => any;
}

export const PanelServiceJobsSummary: React.FC<PanelCustomerNotesProps> = ({ customer, onViewAll }) => {
    const serviceJobs = fromEdges(customer?.serviceJobs);
    const tableDef = useTableDefRecur();
    if (serviceJobs.length === 0) {
        return null;
    }

    return (
        <Panel>
            <Panel.Header
                basic={true}
                content={'Recurring Services'}
                button={{ content: 'All Services', onClick: onViewAll }}
            />
            <Panel.Body>
                <DefaultConnectionTable
                    showHeader={false}
                    tableProps={{
                        color: AppColorNames.ServiceJob.ServiceCall,
                    }}
                    // onRowClick={onView}
                    tableDef={tableDef}
                    connectionData={serviceJobs}
                    connectionState={undefined}
                />
            </Panel.Body>
        </Panel>
    );
};
