import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionTableDef } from '@ez/components';
import { CostEstimationModel, MonthlyHeatPumpProfile } from '../common/types';
import { MonthlyDataTableBase } from '../common/MonthlyDataTableBase';
import { HeatPumpModel } from '../data';

interface DataTableControlProp {
    selectedMonths: string[];
    monthlyHeatProfiles: MonthlyHeatPumpProfile[];
    setSelectedItems?: (newItems) => any;
    costEstimation?: CostEstimationModel<HeatPumpModel>;
}

export const MonthlyDataTable: React.FC<DataTableControlProp> = ({
    selectedMonths,
    monthlyHeatProfiles,
    setSelectedItems,
    costEstimation,
}) => {
    const tableData = monthlyHeatProfiles.map((hp, index) => {
        const costMonth = costEstimation?.costByMonth.find((c) => c.monthId == hp?.monthProfile?.id);
        return {
            ...hp,
            id: hp.monthProfile.id,
            isSelected: hp.isSelected,
            heatTotal: hp.heatTotal,
            operatingTemp: hp.operatingTemp,
            model: costEstimation?.recommendedModel,
            costPerMonth: costMonth?.costRunningMonthly?.toFixed(2) || 0,
        };
    });

    const tableDef = useMemo(() => {
        const tableDef: ConnectionTableDef<(typeof tableData)[0]> = [
            {
                header: 'Month',
                cell: (pd) => pd.monthProfile?.name,
            },
            // { header: 'Days', cell: (pd) => pd.days },
            { header: 'Ambient Temp', cell: (pd) => pd.monthProfile?.temp },
            // { header: 'Op. Temp', cell: (pd) => pd.operatingTemp },
            // { header: 'Total Heat, kw/hr', cell: (pd) => pd.heatTotal?.toFixed(2) },
            {
                header: 'Total Heat, kw/hr',
                cell: (pd) => pd.heatTotal?.toFixed(2),
                cellProps: { textAlign: 'center' },
            },
            {
                header: 'Model Capacity',
                cell: (pd) => {
                    if (!pd.isSelected) return '--';
                    const m = pd.model;
                    return m ? m.capacity : '-';
                },
            },
            {
                header: 'Input, kw',
                cell: (pd) => {
                    if (!pd.isSelected) return '--';
                    const m = pd.model;
                    return m ? m.input : '-';
                },
            },
            { header: 'Monthly Cost', cell: (pd) => `$${pd.costPerMonth}` },
        ];
        return tableDef;
    }, [selectedMonths]);

    return (
        <MonthlyDataTableBase
            tableData={tableData}
            tableDefPartial={tableDef}
            selectedItems={selectedMonths}
            setSelectedItems={setSelectedItems}
        />
    );
};
