import { createThemeByHue } from '@ez/components';

let _t = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    const huePrim = 225;
    const hueSec = 48;
    _t = createThemeByHue({
        publicPrefix,
        huePrim: huePrim,
        primSat: 10,
        hueSec: hueSec,
        customisation: {
            themeName: 'davey',
            logoPath: `${publicPrefix}/logo.jpg`,
            text: {
                color: {
                    primary: `hsl(${huePrim}, 10%, 20%)`,
                },
            },
            button: {
                color: {
                    primary: `hsl(${huePrim}, 18%, 31%)`,
                    secondary: `hsl(${hueSec}, 25%, 50%)`,
                    tertiary: `hsl(${huePrim}, 15%, 85%)`,
                },
            },
            sidebar: {
                backgroundColor: '#272833',
                menuItem: {
                    backgroundColor: '#44475a',
                    backgroundColorHover: '#423f30',
                    backgroundColorActive: '#423f30',
                    textColor: '#f8f8e3',
                    ascentColorActive: 'hsl(48,100%,62%)',
                },
                menuGroup: {
                    separatorColor: 'hsl(48,70%,32%)',
                },
            },
            navbar: {
                backgroundColor: '#272833',
                separatorColor: 'hsl(48,100%,62%)',
                menuItem: {
                    backgroundColor: '#282a36',
                    backgroundColorHover: '#6272a4',
                    backgroundColorActive: '#44475a',
                    textColor: '#f8f8f2',
                },
                logo: {
                    backgroundColor: '#000000',
                },
            },
            // Disabled for now
            border: {
                radiusSm: '0.25rem',
                radius: '0.375rem',
                radiusMd: '0.5rem',
                radiusLg: '0.75rem',
                radiusXl: '1.0rem',
                radius2xl: '1.5rem',
                radius3xl: '2.0rem',
                color: `hsl(${huePrim}, 10%, 85%)`,
                colorStrong: `hsl(${huePrim}, 40%, 80%)`,
            },
            main: {
                backgroundColor: `hsl(210, 10%, 90%)`,
                // backgroundColor: '#cc4747',
            },
        },
    });

    return _t;
};
