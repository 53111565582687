import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Icon, IconProps, SemanticCOLORS, SemanticICONS } from '@ez/components';

export const getLabelForAppointmentStatus = (status: NodeType.AppointmentStatusEnum): string => {
    switch (status) {
        case NodeType.AppointmentStatusEnum.NotStarted:
            return 'Not Started';
        case NodeType.AppointmentStatusEnum.InProgress:
            return 'In Progress';
        case NodeType.AppointmentStatusEnum.ActionRequired:
            return 'Action Required';
        case NodeType.AppointmentStatusEnum.Finished:
            return 'Finished';
        case NodeType.AppointmentStatusEnum.Cancelled:
            return 'Cancelled';
        default:
            return status;
    }
};

export const getIconNameForAppointmentStatus = (status: NodeType.AppointmentStatusEnum): SemanticICONS => {
    switch (status) {
        default:
        case NodeType.AppointmentStatusEnum.InProgress:
            return 'dot circle outline';
        case NodeType.AppointmentStatusEnum.NotStarted:
            return 'circle outline';
        case NodeType.AppointmentStatusEnum.ActionRequired:
            return 'bell';
        case NodeType.AppointmentStatusEnum.Finished:
            return 'check circle outline';
        case NodeType.AppointmentStatusEnum.Cancelled:
            return 'thumbs down';
    }
};

export const getIconColorForAppointmentStatus = (status: NodeType.AppointmentStatusEnum): SemanticCOLORS => {
    switch (status) {
        default:
        case NodeType.AppointmentStatusEnum.NotStarted:
        case NodeType.AppointmentStatusEnum.InProgress:
            return 'blue';
        case NodeType.AppointmentStatusEnum.ActionRequired:
            return 'orange';
        case NodeType.AppointmentStatusEnum.Finished:
            return 'green';
        case NodeType.AppointmentStatusEnum.Cancelled:
            return 'grey';
    }
};

export const mapAppointmentStatusToIconProps = (status: NodeType.AppointmentStatusEnum): IconProps => {
    const name = getIconNameForAppointmentStatus(status);
    const color = getIconColorForAppointmentStatus(status);
    return { name, color };
};

export const IconAppointmentStatus: React.FC<{
    status: NodeType.AppointmentStatusEnum;
    defaultColor?: SemanticCOLORS;
}> = (props) => {
    const { status, defaultColor, ...rest } = props;
    const iconProps = mapAppointmentStatusToIconProps(status);

    return <Icon color={defaultColor} {...iconProps} {...rest} />;
};

export type AppointmentStatusUiConf = {
    title: string;
    icon: IconProps;
    type: NodeType.AppointmentStatusEnum;
    active: boolean;
    value: any;
};

export const mapConfFromStatusMap = (currentStatus: string): AppointmentStatusUiConf[] => {
    const buttonConf = (status: NodeType.AppointmentStatusEnum): AppointmentStatusUiConf => {
        return {
            title: getLabelForAppointmentStatus(status),
            icon: mapAppointmentStatusToIconProps(status),
            type: status,
            active: currentStatus === status,
            value: status,
        };
    };

    const statusArray = [
        NodeType.AppointmentStatusEnum.NotStarted,
        NodeType.AppointmentStatusEnum.InProgress,
        NodeType.AppointmentStatusEnum.ActionRequired,
        NodeType.AppointmentStatusEnum.Cancelled,
        NodeType.AppointmentStatusEnum.Finished,
    ];

    return statusArray.map(buttonConf);
};
