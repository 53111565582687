import * as React from 'react';
import { AppointmentDetailsProps, AppointmentPreviewControl } from './AppointmentDetailsContent';
import { NodeType } from '@poolware/api';
import { Modal, PageLayout } from '@ez/components';
import { useAppLayoutMode } from '@poolware/app-shell';

export interface AppointmentPreviewModalInputProps extends Omit<AppointmentDetailsProps, 'mode'> {
    onClose: () => any | Promise<any>;
    onEdit: (item: NodeType.AppointmentItem) => any | Promise<any>;
    onDidDelete?: () => any | Promise<any>;
    onShowInCalendar?: (item: NodeType.AppointmentItem) => any | Promise<any>;
    open: boolean;
}

export const AppointmentPreviewModal: React.FC<AppointmentPreviewModalInputProps> = (props) => {
    const { gteTablet } = useAppLayoutMode();

    const handleClose = () => {
        props.onClose?.();
    };

    let {
        appointmentItem,
        onShowInCalendar,
        appointmentItemId,
        onClose,
        onEdit,
        onDidDelete,
        showServiceJobLink,
        allowDockModeOption,
        onSwitchToDockMode,
        ...rest
    } = props;

    if (!gteTablet) {
        return (
            <PageLayout.FullScreen>
                <AppointmentPreviewControl
                    mode={'page'}
                    onDidDelete={onDidDelete}
                    appointmentItem={appointmentItem}
                    appointmentItemId={appointmentItemId}
                    onClose={onClose}
                    onEdit={onEdit}
                    onShowInCalendar={onShowInCalendar}
                    showServiceJobLink={showServiceJobLink}
                    onSwitchToDockMode={onSwitchToDockMode}
                />
            </PageLayout.FullScreen>
        );
    }

    const mp = {
        closeOnEscape: true,
        closeOnDimmerClick: true,
        // IMPORTANT: don't set `closeOnDocumentClick=true`
        // as it causes weird behaviour when a popup is shown inside the modal window.
        closeOnDocumentClick: false,
        onClose: handleClose,
        children: (
            <AppointmentPreviewControl
                mode={'preview'}
                onDidDelete={onDidDelete}
                appointmentItem={appointmentItem}
                appointmentItemId={appointmentItemId}
                onClose={onClose}
                onEdit={onEdit}
                onShowInCalendar={onShowInCalendar}
                showServiceJobLink={showServiceJobLink}
                allowDockModeOption={allowDockModeOption}
                onSwitchToDockMode={onSwitchToDockMode}
            />
        ),
    };
    // @ts-ignore
    return <Modal {...mp} {...rest} />;
};

export default AppointmentPreviewModal;
