import * as React from 'react';
import { useEffect, useRef } from 'react';
import {
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    MultiColumnGrid,
    Segment,
    StickyMenuBar,
    SuspenseSkeletonLoader,
    toastError,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType, useMutationWarrantyClaimCase, useMutationWarrantyClaimFileAttach } from '@poolware/api';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import { useQueryWarrantyClaimCase } from './use-query-warranty-claim-case';
import { WarrantyPreview } from '../../components/WarrantyPreview';
import { FileAttachmentPanel } from '../../components/FileAttachments/FileAttachmentPanel';
import { DiscussionPanelParticipantType, PanelDiscussion } from '../../components/PanelDiscussion';
import { PanelOfficeNotes } from './PanelOfficeNotes';
import { hasNewMessage } from '../utils';
import { PanelCaseStatus } from './PanelCaseStatus';
import { makeDebugger } from '@ez/tools';

const debug = makeDebugger('warranty:case:view');

export const PageView: React.FC = () => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const { isAdmin } = useViewer();
    const { node: claimCase, error, loading, refetchQuery } = useQueryWarrantyClaimCase(params.id);
    const { markMessagesAsRead, updateStatus } = useMutationWarrantyClaimCase({
        refetchQueries: ['QueryWarrantyClaimMessages', 'QueryWarranterClaimMessagesConnection', refetchQuery],
        awaitRefetchQueries: true,
    });

    const mutatorWarrantyClaimFileAttach = useMutationWarrantyClaimFileAttach({
        refetchQueries: ['QueryWarrantyClaimsConnection', refetchQuery],
        awaitRefetchQueries: true,
    });

    // Don't automatically mark messages as read if viewing as admin
    const autoMarkReadEnabled = !isAdmin;

    const readRef = useRef(false);

    let lastReadThreshold = undefined;
    if (claimCase) {
        if (claimCase.lastMessageReadAt) {
            lastReadThreshold = new Date(claimCase.lastMessageReadAt);
        } else if (claimCase.lastMessageAt) {
            // lastMessageAt present, but lastMessageReadAt is not.
            // set threshold to very far past.
            lastReadThreshold = new Date(0);
        }
    }

    const goBack = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    const claimCaseRef = useRef(claimCase);
    claimCaseRef.current = claimCase;

    const onMarkRead = async () => {
        try {
            const hasMessage = hasNewMessage(claimCaseRef.current);
            debug('onMarkRead: started');
            debug(' > hasMessage = ' + hasMessage);
            debug(' > autoMarkReadEnabled = ' + autoMarkReadEnabled);

            if (readRef.current || !hasMessage || !claimCaseRef.current || !autoMarkReadEnabled) {
                debug('onMarkRead: skipped READ');
                return;
            }
            readRef.current = true;
            await markMessagesAsRead({ id: claimCaseRef.current.id });
            debug('onMarkRead: finished');
        } catch (e) {
            toastError(e);
        }
    };

    const onStatusChange = async (values: { status: NodeType.WarrantyClaimStatusEnum; message?: string }) => {
        try {
            await updateStatus({ id: claimCase.id, status: values.status, message: values.message });
        } catch (e) {
            toastError(e);
        }
    };

    useEffect(() => {
        const timeout = 8000;
        // Set timer to mark messages as read after some timout
        debug('Scheduled auto READ in ' + timeout + 'ms');
        let timer = setTimeout(async () => {
            await onMarkRead();
        }, timeout);

        return () => {
            debug('Stopped scheduler', timer);
            clearTimeout(timer);
        };
    }, []);

    // @ts-ignore
    useEffect(() => {
        // Mark messages as read on page exit
        return async () => {
            debug('Marking READ on page exit');
            const hasMessage = hasNewMessage(claimCaseRef.current);
            if (!hasMessage) {
                debug('Abort: hasMessage ===  false');
                return;
            }
            debug('Marked READ');
            await onMarkRead();
        };
    }, []);

    if (loading || error) {
        return <SuspenseSkeletonLoader error={error} loading={loading} />;
    }

    if (!loading && !claimCase) {
        return <NotFoundPage />;
    }

    const claim = claimCase.claim;

    if (!claim) {
        return (
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back'} />
                        <MenuBarHeaderItem icon={'certificate'}>Warranty Claim Case</MenuBarHeaderItem>
                    </MenuBarSection>
                </StickyMenuBar>
                <Segment>Cannot to load claim details. Please contact support if you believe it is an error.</Segment>
            </VStack>
        );
    }

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back'} />
                    <MenuBarHeaderItem icon={'certificate'}>
                        Warranty Claim Case - {claim.warrantyClaimNumber}
                    </MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <MultiColumnGrid col1={'minmax(480px, 1fr)'} col2={'minmax(480px, 1fr)'} vStackBrkPt={800} gridGap={2}>
                <Segment>
                    <VStack>
                        <WarrantyPreview claim={claim} />
                        {/*TODO: enable file attachment once backend is fixed*/}
                        <FileAttachmentPanel
                            claim={claim}
                            readOnly={true}
                            mutatorWarrantyClaimFileAttach={mutatorWarrantyClaimFileAttach}
                        />
                    </VStack>
                </Segment>
                <VStack>
                    <PanelCaseStatus claimCase={claimCase} onStatusChange={onStatusChange} />
                    <PanelOfficeNotes refetchQuery={refetchQuery} claimCase={claimCase} />
                    <PanelDiscussion
                        refetchQueries={refetchQuery}
                        lastReadThreshold={lastReadThreshold}
                        viewerType={DiscussionPanelParticipantType.WARRANTER}
                        claim={claimCase.claim}
                    />
                </VStack>
            </MultiColumnGrid>
        </VStack>
    );
};
