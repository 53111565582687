import * as React from 'react';
import { compose } from '@ez/tools';
import { IProductCatalogMutators, Product_CreateInput, withProductCatalogMutators } from '../../../queries';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import {
    Alert,
    ButtonWithPopup,
    ButtonWithPopupProps,
    FormikFormDebug,
    FormikInputField,
    IconButton,
    MenuBarItem,
    MenuBarSection,
    ScrollX,
    StickyMenuBar,
    Table,
    toastError,
    VStack,
    Button,
    SectionHeader,
    Form,
} from '@ez/components';

import * as Yup from 'yup';
import {
    FormikSuggestInputOrganisationType,
    OrgProviderInjectedProps,
    withOrgSwitcher,
    withViewer,
    WithViewerProps,
} from '@poolware/app-shell';
import { Field, FieldArray, Formik, FormikHelpers as FormikActions, getIn, useFormikContext } from 'formik';
import * as _ from 'lodash';
import { TraitDeclarationPicker_TreeModal } from '../../../components-api-connected';
import { queryNames } from '../query-names';
import { FormikSuggestBrandInput } from '../../../components-formik-connected';
import { NodeType } from '@poolware/api';

const ProductSchema = Yup.object().shape({
    sku: Yup.string().max(100, 'Too Long!').notRequired(),
    description: Yup.string().max(500, 'Too Long!'),
    name: Yup.string().min(2, 'Too Short!').max(250, 'Too Long!').required('Required'),
});

const FormSchemaValidation = Yup.object().shape({
    products: Yup.array().of(ProductSchema).required('Must have at least one product').min(1, 'Minimum of 1 product'),
});

const FormInputButtonWithPopupField: React.FC<ButtonWithPopupProps> = (props) => {
    return (
        <div className={'field'}>
            {/*push button a few pixels down to be aligned with other form inputs*/}
            <label style={{ visibility: 'hidden' }}>{'.'}</label>
            <ButtonWithPopup {...props} />
        </div>
    );
};

export const TraitInputField: React.FC<{
    label: string;
    name: string;
    required?: boolean;
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
}> = ({ label, name, required = false, organisationType }) => {
    return (
        <Field
            name={name}
            render={(renderProps) => {
                const { form, field } = renderProps;
                const { value } = field;
                const onSelect = (parent) => {
                    form.setFieldValue(field.name, parent);
                };
                const handleRemove = () => {
                    form.setFieldValue(field.name, null);
                };

                return (
                    <Form.Input action label={label} readOnly value={_.get(value, 'name') || ''} required={required}>
                        <input style={{ maxWidth: '100%', backgroundColor: '#f8f8f8' }} />
                        {value && <Button basic size="tiny" icon={'delete'} onClick={handleRemove} />}
                        <TraitDeclarationPicker_TreeModal onSelect={onSelect} organisationType={organisationType} />
                    </Form.Input>
                );
            }}
        />
    );
};

const ProductRow: React.FC<{ arrayHelpers; index; product }> = ({ arrayHelpers, index, product }) => {
    const n = (name: string) => `products[${index}].${name}`;

    const { values } = useFormikContext();

    const productOrg = getIn(values, n('organisation'));

    return (
        <Table.Row>
            <Table.Cell singleLine style={{ width: '20px' }}>
                <div className={'field'} style={{ width: '20px', fontSize: '1.3em' }}>
                    <label style={{ visibility: 'hidden' }}>{'.'}</label>
                    <IconButton
                        popup={{ content: 'Remove' }}
                        color={'red'}
                        icon={'remove circle'}
                        onClick={() => arrayHelpers.remove(index)}
                    />
                </div>
            </Table.Cell>
            <Table.Cell width={1}>
                <FormikInputField name={n('sku')} label={'SKU'} />
            </Table.Cell>
            <Table.Cell singleLine>
                <FormikInputField name={n('name')} required={true} label={'Name'} />
            </Table.Cell>
            <Table.Cell>
                <FormikSuggestBrandInput name={n('brand')} label={'Brand'} />
            </Table.Cell>
            <Table.Cell>
                <TraitInputField
                    name={n('traitDeclaration')}
                    label={'Product Declaration'}
                    organisationType={productOrg}
                />
            </Table.Cell>
            <Table.Cell>
                <FormikInputField name={n('description')} label={'Description'} />
            </Table.Cell>
            <Table.Cell>
                <FormikSuggestInputOrganisationType name={n('organisation')} label={'Organisation'} />
            </Table.Cell>
            <Table.Cell style={{ width: '60px' }}>
                <FormInputButtonWithPopupField
                    type="button"
                    icon={'copy outline'}
                    popup={{
                        content: 'Duplicate',
                    }}
                    size={'small'}
                    onClick={() =>
                        arrayHelpers.insert(index, {
                            ...product,
                        })
                    }
                />
            </Table.Cell>
        </Table.Row>
    );
};

const TableInputFields = ({ values, defaultProductValues }) => {
    return (
        <Table size={'small'} unstackable>
            <Table.Body>
                <FieldArray
                    name={'products'}
                    render={(arrayHelpers) => {
                        const { products } = values;
                        return (
                            <>
                                {products &&
                                    products.length > 0 &&
                                    products.map((product, index) => {
                                        return (
                                            <ProductRow
                                                key={index}
                                                product={product}
                                                arrayHelpers={arrayHelpers}
                                                index={index}
                                            />
                                        );
                                    })}
                                <Table.Row>
                                    <Table.Cell colSpan={14} textAlign={'left'}>
                                        <Button
                                            icon={'plus'}
                                            content={'Add Product'}
                                            size={'sm'}
                                            onClick={() => arrayHelpers.push({ ...defaultProductValues })}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </>
                        );
                    }}
                />
            </Table.Body>
        </Table>
    );
};

const ProductsArrayErrors = (errors) =>
    typeof errors.products === 'string' ? <Alert type={'error'} content={errors.products} /> : null;

class Page extends React.Component<PageControlProps> {
    onSubmit = async (values, actions) => {
        try {
            const mapProductsToConfig = (product) => {
                const input: Product_CreateInput = {
                    name: product.name,
                    sku: product.sku,
                    description: product.description,
                    organisationType: product.organisation,
                    brand: product.brand,
                    traits: [product.traitDeclaration],
                };
                return input;
            };

            const conf = values.products.map(mapProductsToConfig);

            const productIds = await this.props.ProductCatalogMutator.createProducts(conf);
            if (productIds.length === 1) {
                const productId = productIds[0];
                this.props.AppNavigator.replace(`/${productId}`, { relativeToModule: true });
            } else {
                this.props.AppNavigator.replace(`/`, { relativeToModule: true });
            }
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e.message });
        }
    };

    onCancel = () => {
        this.props.AppNavigator.replaceToOrigin();
    };

    render() {
        const { viewerContext, orgSwitcher } = this.props;

        const defaultProductValues = {
            organisation: orgSwitcher?.organisation || viewerContext.viewer.organisation,
            name: '',
            description: '',
            sku: '',
            customSku: '',
            traitDeclaration: null,
        };
        let initialValues = {
            products: [
                {
                    ...defaultProductValues,
                },
            ],
        };

        const handleOnSubmit = async (values: any, actions: FormikActions<any>) => {
            actions.setSubmitting(true);
            try {
                await this.onSubmit(values, actions);
            } catch (e) {
                console.error(e);
                actions.setStatus({ error: e });
                toastError({ title: 'Failed', description: e.message });
            }
            actions.setSubmitting(false);
        };

        return (
            <Formik onSubmit={handleOnSubmit} initialValues={initialValues} validationSchema={FormSchemaValidation}>
                {(bag) => {
                    const { values, errors, submitForm } = bag;
                    const hasError = !_.isEmpty(errors);
                    return (
                        <VStack>
                            <StickyMenuBar>
                                <MenuBarSection>
                                    <MenuBarItem icon={'chevron left'} onClick={this.onCancel} title={'Cancel'} />
                                </MenuBarSection>
                                <MenuBarSection position={'right'}>
                                    <MenuBarItem
                                        onClick={submitForm}
                                        icon={'upload'}
                                        color={hasError ? 'grey' : 'green'}
                                        title={'Create'}
                                    />
                                </MenuBarSection>
                            </StickyMenuBar>

                            <SectionHeader>New Products</SectionHeader>

                            <Form error={hasError}>
                                <ScrollX style={{ paddingBottom: '300px' }}>
                                    {ProductsArrayErrors(errors)}
                                    <TableInputFields values={values} defaultProductValues={defaultProductValues} />
                                </ScrollX>

                                <FormikFormDebug hidden={false} />
                            </Form>
                        </VStack>
                    );
                }}
            </Formik>
        );
    }
}

export interface PageControlProps
    extends IAppNavigatorProps,
        IProductCatalogMutators,
        WithViewerProps,
        OrgProviderInjectedProps {}

export default compose(
    withAppNavigator(),
    withViewer(),
    withOrgSwitcher(),
    withProductCatalogMutators(queryNames)
)(Page);
