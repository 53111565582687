import * as React from 'react';
import {
    Alert,
    AlertContent,
    AlertHeader,
    Button,
    ButtonWithDropdownMenu,
    DebugJsonButton,
    DropdownMenuOptionType,
    Icon,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    Modal,
    Panel,
    SectionHeader,
    StickyMenuBar,
    Table,
    toastError,
    VStack,
} from '@ez/components';
import { PageControlProps } from './Page.Control';
import * as URLBuilder from '../../../../routes/url-builder';
import { VendCustomerDetails } from './VendCustomerDetails';
import { AppErrorBoundary } from '@poolware/app-shell';

const FormType = {
    None: 0,
    New: 1,
    Edit: 2,
    Delete: 3,
    SetPrimary: 4,
};

const TableRow: React.FC<{
    contactItem;
    children;
    onEdit?;
    onDelete?;
    onNew?;
    onMakePrimary?;
}> = ({ contactItem, children, onEdit, onDelete, onNew, onMakePrimary }) => (
    <Table.Row>
        <Table.Cell>
            <b>{contactItem.label}</b>
        </Table.Cell>
        <Table.Cell>{children}</Table.Cell>
        <Table.Cell textAlign="right">
            <div className={'flex flex-row gap-2 justify-end'}>
                {onMakePrimary && (
                    <Button name={`edit-${contactItem.label}`} basic size="sm" onClick={onMakePrimary}>
                        Set Primary
                    </Button>
                )}
                {onNew && (
                    <Button name={`new-${contactItem.label}`} basic size="sm" color="teal" onClick={onNew}>
                        New
                    </Button>
                )}
                {onDelete && (
                    <Button name={`delete-${contactItem.label}`} basic size="sm" color="red" onClick={onDelete}>
                        Delete
                    </Button>
                )}
                {onEdit && (
                    <Button name={`edit-${contactItem.label}`} basic size="sm" onClick={onEdit}>
                        Edit
                    </Button>
                )}
            </div>
        </Table.Cell>
    </Table.Row>
);

const WarningMessage = () => {
    return (
        <Alert type={'warning'}>
            <AlertHeader>
                <Icon name={'warning sign'} color={'yellow'} />
                DANGER!!!
                <Icon name={'warning sign'} color={'yellow'} />
            </AlertHeader>
            <AlertContent>
                <p />
                <p>This customer will be PERMANENTLY deleted!</p>
                <p>This action is irreversible!</p>
            </AlertContent>
        </Alert>
    );
};

const CustomerDetailsChangeDisclaimerWarning = () => {
    return (
        <Alert className={'bg-blue-100'}>
            <AlertHeader>
                <Icon name={'warning sign'} color={'yellow'} />
                Important note regarding changing customer details!
            </AlertHeader>
            <AlertContent>
                <p className={'pt-4'}>
                    When altering customer details, the changes are also reflected in Vend. Altering customer details is
                    appropriate for scenarios like updating contact information, rectifying errors, and correcting
                    typos.
                </p>
                <p>
                    However, if you modify the customer's name to a different individual, it will substantially impact
                    the customer's sales history in Vend.
                </p>
                <p>
                    If you intend to change pool ownership, it is strongly advisable to create a new customer
                    registration and transfer the site to the new customer. This approach will safeguard the existing
                    customer's sales history.
                </p>
                <p>You can initiate a site ownership transfer by accessing "Edit Site ⇒ Change Owner".</p>
            </AlertContent>
        </Alert>
    );
};

interface PageProps extends PageControlProps {}

class Page extends React.Component<PageProps, any> {
    state: any = {};

    constructor(props, context) {
        super(props, context);

        this.state = {
            openModal: false,
            openFormType: FormType.None,
            editContactItem: null,
        };
    }

    showForm = (contactItem: any, formType: any) => () => {
        this.setState({ openModal: true, editContactItem: contactItem, openFormType: formType });
    };

    hideForm = () => {
        this.setState({ openModal: false, editContactItem: null, openFormType: FormType.None });
    };

    onAddNewContactMenuClick = (menuItem: any) => {
        this.showForm(menuItem, FormType.New)();
    };

    onSubmit = () => {
        this.hideForm();
    };

    onDelete = async () => {
        const r = window.confirm('Delete Customer?');
        if (r !== true) {
            return;
        }

        try {
            await this.props.mutateCustomer.delete({ id: this.props.customer.id });
            await this.props.AppNavigator.navigate(URLBuilder.Customer().all);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete customer', description: e.message });
        }
    };

    onGoBack = () => {
        const customerLink = URLBuilder.Customer(this.props.customer.id).view;
        this.props.AppNavigator.navigate(customerLink);
    };

    render() {
        const { contactTypes, contactItems: displayItems, viewerContext, customer } = this.props;
        const { editContactItem, openModal, openFormType } = this.state;

        const showAddButton = contactTypes?.length > 0;

        const menuOptions = contactTypes.map<DropdownMenuOptionType>((contactType) => {
            return {
                key: contactType.label,
                content: contactType.label,
                onClick: () => this.onAddNewContactMenuClick(contactType),
            };
        });

        const canDelete = this.props.viewerContext?.viewer?.Permissions?.Customer?.delete;
        const isVendEnabled =
            viewerContext?.viewer?.isVendPresent && viewerContext?.modulesAccess?.VendIntegration?.enabled;
        return (
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem icon={'chevron left'} color={'grey'} onClick={this.onGoBack}>
                            To Customer
                        </MenuBarItem>
                        <MenuBarHeaderItem icon={'user'}>Edit Customer</MenuBarHeaderItem>
                    </MenuBarSection>
                    <MenuBarSection position={'right'}>
                        <DebugJsonButton data={customer} />
                        <MenuBarDropdown icon={'bars'} color={'red'}>
                            <MenuBarDropdownItemWithConfirm
                                icon={'trash'}
                                content={'Delete Customer'}
                                color="red"
                                disabled={!canDelete}
                                popup={canDelete ? undefined : { content: 'Not enough permissions' }}
                                confirm={{
                                    confirmMessage: {
                                        header: 'Delete Customer?',
                                        content: <WarningMessage />,
                                    },
                                    confirmButton: {
                                        content: 'Delete Customer',
                                        negative: true,
                                    },
                                }}
                                onClick={this.onDelete}
                            />
                        </MenuBarDropdown>
                    </MenuBarSection>
                </StickyMenuBar>

                <Table color="grey" selectable unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="1" width="3">
                                Type
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="1">Details</Table.HeaderCell>
                            <Table.HeaderCell colSpan="1" width="4" textAlign="center" />
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {displayItems.detailsItems.map((contactItem, index) => {
                            return (
                                <TableRow
                                    key={contactItem.label + index}
                                    contactItem={contactItem}
                                    onNew={contactItem.newView ? this.showForm(contactItem, FormType.New) : null}
                                    onEdit={contactItem.editView ? this.showForm(contactItem, FormType.Edit) : null}
                                    onDelete={
                                        contactItem.deleteView ? this.showForm(contactItem, FormType.Delete) : null
                                    }
                                >
                                    <contactItem.view />
                                </TableRow>
                            );
                        })}
                        {displayItems.entityContactItems.map((contactItem, index) => {
                            return (
                                <TableRow
                                    key={contactItem.label + index}
                                    contactItem={contactItem}
                                    onMakePrimary={
                                        contactItem.makePrimaryView
                                            ? this.showForm(contactItem, FormType.SetPrimary)
                                            : null
                                    }
                                    onNew={contactItem.newView ? this.showForm(contactItem, FormType.New) : null}
                                    onEdit={contactItem.editView ? this.showForm(contactItem, FormType.Edit) : null}
                                    onDelete={
                                        contactItem.deleteView ? this.showForm(contactItem, FormType.Delete) : null
                                    }
                                >
                                    <contactItem.view />
                                </TableRow>
                            );
                        })}
                    </Table.Body>
                </Table>
                <div>
                    {showAddButton && (
                        <ButtonWithDropdownMenu options={menuOptions} icon="plus" content="Add Contact" />
                    )}
                </div>

                <Modal size="small" centered={false} open={openModal}>
                    {editContactItem && (
                        <div>
                            {openFormType === FormType.SetPrimary && (
                                <editContactItem.makePrimaryView onCancel={this.hideForm} onSubmit={this.onSubmit} />
                            )}
                            {openFormType === FormType.Edit && (
                                <editContactItem.editView onCancel={this.hideForm} onSubmit={this.onSubmit} />
                            )}

                            {openFormType === FormType.Delete && (
                                <editContactItem.deleteView onCancel={this.hideForm} onSubmit={this.onSubmit} />
                            )}

                            {openFormType === FormType.New && (
                                <editContactItem.newView onCancel={this.hideForm} onSubmit={this.onSubmit} />
                            )}
                        </div>
                    )}
                </Modal>

                {isVendEnabled && (
                    <AppErrorBoundary>
                        <SectionHeader icon={'exchange'}>External Integrations</SectionHeader>
                        <div className={'flex flex-col lg:flex-row gap-4'}>
                            <div className={'max-w-screen-md w-full'}>
                                <VendCustomerDetails customerId={this.props.customer.id} />
                            </div>
                            {customer?.vendLink && (
                                <div className={'max-w-screen-sm w-full'}>
                                    <CustomerDetailsChangeDisclaimerWarning />
                                </div>
                            )}
                        </div>
                    </AppErrorBoundary>
                )}

                <div className={'max-w-screen-sm mt-4'}>
                    <Panel>
                        <Panel.Body>
                            <Panel.ItemDate
                                label={'Registered'}
                                content={customer?.registeredAt || customer?.createdAt}
                            />
                            <Panel.ItemEntity label={'Registered by'} content={customer?.addedBy} />
                        </Panel.Body>
                    </Panel>
                </div>
            </VStack>
        );
    }
}

export default Page;
