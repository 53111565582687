import * as React from 'react';
import { toastError } from '@ez/components';
import { defaultRecurrence } from './common';
import { useBookingActions } from '../../redux';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    AppointmentForm,
    AppointmentFormValuesType,
    AppointmentFormMode,
    AppointmentValidationSchema,
} from './AppointmentForm';
import { useAppointmentMutators } from '../../queries/use-appointment-mutators';

const Page: React.FC = () => {
    const { BookingAction, BookingState } = useBookingActions();
    const { AppointmentMutator } = useAppointmentMutators(['SchedulerAppointmentsList', 'QueryWorkOrdersConnection']);
    const { AppNavigator } = useAppNavigator();

    const isSagaMode = BookingState.isSagaMode;
    const details = BookingState.details;
    const customer = BookingState.details?.customer;

    const dStartDate = details.startDate || new Date();
    const dDuraton = details.duration || 60;

    let initialValues: AppointmentFormValuesType = {
        mode: AppointmentFormMode.New,
        address: undefined,
        appointmentItem: undefined,
        customer: undefined,
        pool: undefined,
        serviceJob: undefined,
        staff: undefined,
        workOrder: undefined,
        ...details,
        showWorkOrderTab: false,
        defaultRecurrence: defaultRecurrence,
        isRecurrenceLocked: true,
        isRecurring: false,
        showRecurrenceTab: false,
        startDate: new Date(dStartDate),
        duration: dDuraton,
        isEditing: false,
    };

    const onSubmit = async (values) => {
        try {
            await AppointmentMutator.createAppointment(values);

            if (BookingState.isSagaMode) {
                BookingAction.bookingCreated();
            } else {
                AppNavigator.navigateToOrigin();
            }
        } catch (error) {
            toastError({ title: 'Failed to create appointment', description: error.message });
            console.error('there was an error sending the query', error);
        }
    };

    const onCancel = () => {
        if (BookingState.isSagaMode) {
            BookingAction.abort();
        } else {
            AppNavigator.navigateToOrigin('/scheduler');
        }
    };

    return (
        <AppointmentForm
            debug={true}
            validationSchema={AppointmentValidationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitButton={{ content: 'Create' }}
        />
    );
};
export default Page;
