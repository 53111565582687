import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import { Panel, SectionHeader } from '@ez/components';
import styled from 'styled-components';
import NewProductTraitForm from './NewProductTraitForm';
import { TraitDeclarationPicker_Tree } from '../../../components-api-connected';

const DeclarationPickerContainer = styled.div`
    max-height: 500px;
    min-height: 400px;
    overflow-y: auto;
    padding: 8px;
`;

export interface NewProductTraitPageProps {
    products: NodeType.Product[];
    onSubmit: () => any;
    onCancel: () => any;
}

export const NewProductTrait: React.FC<NewProductTraitPageProps> = (props) => {
    const { products, onCancel, onSubmit } = props;
    const [selectedTraitDeclaration, setSelected] = useState(undefined);

    return (
        <Panel>
            <Panel.Header
                button={
                    !selectedTraitDeclaration && {
                        onClick: onCancel,
                        icon: 'close',
                    }
                }
            >
                New Trait Field
            </Panel.Header>
            <Panel.Body>
                <Panel.Item label={'Product'}>{products.map((p) => p.name).join(', ')}</Panel.Item>
                <Panel.Divider />

                <div className={'grid grid-cols-12 gap-4'}>
                    <div className={'col-span-4'}>
                        <SectionHeader size={'small'}>Trait Declarations</SectionHeader>
                        <DeclarationPickerContainer>
                            <TraitDeclarationPicker_Tree
                                current={[selectedTraitDeclaration]}
                                onSelect={(pd) => setSelected(pd)}
                            />
                        </DeclarationPickerContainer>
                    </div>
                    <div className={'col-span-8'}>
                        <NewProductTraitForm
                            selectedTraitDeclarationId={selectedTraitDeclaration && selectedTraitDeclaration.id}
                            products={products}
                            onCancel={onCancel}
                            onSubmit={onSubmit}
                        />
                    </div>
                </div>
            </Panel.Body>
        </Panel>
    );
};
