import * as React from 'react';
import { ConsumerProps, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthConnectProps, connectWithAuth, IWithPermissionsProps, NodeType, REST } from '@poolware/api';
import { compose } from '@ez/tools';
import { defaultViewerContextValue, ViewerContextType } from './ViewerContextType';
import { useQueryMe } from './use-query-me';
import { FeatureFlag } from './feature-flags';
import * as _ from 'lodash';
import { getModuleAccess } from './module-access';
import { AppBreakpoints, defaultAppBreakpointsValue, useAppBreakpoints } from '@ez/components';
import { storeHQAccess } from './module-access-store-hq';
import { orgAdminAccess } from './module-access-org-admin';

// alias
export type AppLayoutMode = AppBreakpoints;
export const useAppLayoutMode = useAppBreakpoints;
export const defaultAppLayoutValue = defaultAppBreakpointsValue;

export const ViewerContext = React.createContext<ViewerContextType>(defaultViewerContextValue);

export const useViewerContext = () => useContext(ViewerContext);

// useViewer is a alias for useViewerContext
export const useViewer = useViewerContext;

export const Viewer: React.FC<ConsumerProps<ViewerContextType>> = (props) => {
    return <ViewerContext.Consumer {...props} />;
};

export interface WithViewerProps {
    viewerContext: ViewerContextType;
}

export const withViewer =
    () =>
    (C: React.ElementType<WithViewerProps>): React.FC =>
    (props) => {
        const viewer = useViewerContext();
        return <C viewerContext={viewer} {...props} />;
    };

interface Props extends AuthConnectProps, IWithPermissionsProps {}

const _getFeatureFlag = (availableTags: string[], featureFlags: string[], checkFlags: string) => {
    if (_.includes(availableTags, checkFlags)) {
        return true;
    }
    return featureFlags?.[checkFlags];
};

const _canAccessFeature = (availableTags: string[], featureFlags: any, checkFlags: string | string[]): boolean => {
    if (!availableTags && !featureFlags) return undefined;
    if (!(checkFlags instanceof Array)) {
        checkFlags = [checkFlags];
    }
    const res1 = !!availableTags && checkFlags.some((r) => availableTags?.indexOf?.(r) >= 0);
    const res2 = !!featureFlags && checkFlags.some((r) => featureFlags[r] !== undefined);

    return res1 || res2;
};

const ViewerProviderComp: React.FC<Props> = (props) => {
    const appLayoutMode = useAppBreakpoints();
    const [fetchingREST, setFetchingREST] = useState(false);
    const [restMe, setRestMe] = useState<REST.UserProfile>({});
    const [error, setError] = useState<ViewerContextType['error']>();

    const meQueryRes = useQueryMe();
    const fetchRestMe = async (): Promise<REST.UserProfile> => {
        try {
            return await props.AuthAction.fetchUserProfile();
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    async function fetchProfile() {
        if (fetchingREST) {
            return;
        }
        try {
            setFetchingREST(true);
            await meQueryRes.refetch();
            const profile = await fetchRestMe();
            setRestMe(profile);
        } catch (e) {
            console.error(e);
            setError(e);
        }
        setFetchingREST(false);
    }

    useEffect(() => {
        // Do this only once on first mount
        const f = async () => {
            await fetchProfile();
        };
        f();
    }, []);

    useEffect(() => {
        try {
            if (!props.AuthState.isAuthenticated) {
                setRestMe({});
                return;
            }
            fetchProfile();
        } catch (e) {
            console.error(e);
        }
    }, [props.AuthState.isAuthenticated]);

    const canAccessFeature = useCallback(
        (flag: string | string[]) => {
            const tags = restMe?.flags?.map((f) => f.tag);
            const featureFlags = meQueryRes?.data?.me?.featureFlags;
            return _canAccessFeature(tags, featureFlags, flag);
        },
        [restMe, meQueryRes]
    );

    const getFeatureFlag = useCallback(
        (flag: string) => {
            const tags = restMe?.flags?.map((f) => f.tag);
            const featureFlags = meQueryRes?.data?.me?.featureFlags;
            return _getFeatureFlag(tags, featureFlags, flag);
        },
        [restMe, meQueryRes]
    );

    const viewer: ViewerContextType['viewer'] = useMemo(
        () => ({
            ...meQueryRes.data,
            trackId: restMe?.trackId,
            refetch: meQueryRes.refetch,
        }),
        [meQueryRes, restMe]
    );

    const contextValue: ViewerContextType = useMemo(() => {
        const isStoreHQ = viewer?.franchise?.types?.includes(NodeType.FranchiseType.StoreHQ);
        const isOrgAdmin = canAccessFeature(FeatureFlag.ORG_ADMIN);
        let modulesAccess;
        if (isOrgAdmin) {
            modulesAccess = orgAdminAccess;
        } else if (isStoreHQ) {
            modulesAccess = storeHQAccess;
        } else {
            modulesAccess = getModuleAccess({ getFeatureFlag, canAccessFeature });
        }

        const allowBackgroundAuthCheck =
            props.AuthState.isAuthenticated && props.AuthState.isProfileReady && !meQueryRes.loadingInitial;

        const isAdmin = canAccessFeature(FeatureFlag.ADMIN);
        const isFranchiseAdmin = canAccessFeature(FeatureFlag.FRANCHISE_ADMIN);

        return {
            viewer,
            Auth: {
                AuthState: props.AuthState,
                AuthAction: props.AuthAction,
            },
            canAccessFeature,
            getFeatureFlag,
            isPretending: Boolean(restMe?.pretending),
            checkingAuthState: props.AuthState.isFetchingProfile || meQueryRes.loadingInitial,
            allowBackgroundAuthCheck: allowBackgroundAuthCheck,
            isAuthenticated: props.AuthState.isAuthenticated,
            error: error || meQueryRes.error,
            isAdmin,
            isStoreHQ,
            isFranchiseAdmin,
            isOrgAdmin,
            modulesAccess,
            appLayoutMode,
        };
    }, [
        appLayoutMode,
        viewer,
        restMe,
        meQueryRes,
        props.AuthState,
        props.AuthAction,
        getFeatureFlag,
        canAccessFeature,
    ]);

    return <ViewerContext.Provider value={contextValue}>{props.children}</ViewerContext.Provider>;
};

export const ViewerProvider = compose(connectWithAuth())(ViewerProviderComp);
