import { EmptyBoxImage } from '../EmptyBoxImage';
import * as React from 'react';

export const NoDataMessage: React.FC<{}> = () => {
    return (
        <div className={'flex justify-center'}>
            <EmptyBoxImage className={'m-2 h-8'} />
        </div>
    );
};
