import * as React from 'react';
import styled from 'styled-components';
import { HeaderButtons, HeaderButtonType } from './HeaderButtons';
import { HeaderIconProp, iconPropToComp } from '../Icon/helpers';
import tw, { css } from 'twin.macro';
import { useAppBreakpoints } from '../use-app-breakpoints';
import { ButtonWithPopup, ButtonWithPopupProps } from '../buttons';
import { ButtonWithDropdownMenu, ButtonWithDropdownMenuProps } from '../buttons';
import { PropsWithChildren } from 'react';

const StyledContainer = styled.div<{ dividing?: boolean }>(({ dividing = true }) => [
    css`
        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-weight: bold;
        line-height: 1.5em;
        text-transform: none;
        //font-size: 1.1em;

        margin: 1em 0 0.5em;

        &:first-child {
            margin-top: 0;
        }
    `,
    tw`text-sm text-primary pb-1 md:text-base`,
    dividing && tw`pb-2 border-0 border-b border-tertiary border-solid`,
]);

const RightSpan = styled.span`
    float: right;
    margin-top: -4px;
`;

export interface SectionHeaderProps {
    content?: string | React.ReactNode;
    headerComp?: any;
    dividing?: boolean;
    icon?: HeaderIconProp;
    button?: HeaderButtonType | HeaderButtonType[];
    autoHideButtonsContent?: boolean;
    size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge';
}

const SectionHeaderComp: React.FC<SectionHeaderProps> = (props) => {
    const { isMobile } = useAppBreakpoints();
    let { size, autoHideButtonsContent, headerComp, content, icon, button, children, dividing } = props;

    let iconComp = iconPropToComp(icon);
    return (
        <StyledContainer dividing={dividing}>
            <span>
                {iconComp}
                {content}
                {children}
                {headerComp}
            </span>
            <RightSpan className={'no-print'}>
                <HeaderButtons buttons={button} autoHideButtonsContent={autoHideButtonsContent && isMobile} />
            </RightSpan>
        </StyledContainer>
    );
};

export const SectionHeaderButtonGroup: React.FC<PropsWithChildren<any>> = (props) => (
    <div className={'flex flex-nowrap flex-row'} {...props} />
);

export const SectionHeaderButton: React.FC<ButtonWithPopupProps> = (props) => {
    return (
        <ButtonWithPopup //
            variant={'secondary'}
            color={'tertiary'}
            size={'sm'}
            {...props}
        />
    );
};

export const SectionHeaderDropdownButton: React.FC<ButtonWithDropdownMenuProps> = (props) => {
    return (
        <ButtonWithDropdownMenu
            icon={'ellipsis vertical'}
            variant={'secondary'}
            color={'tertiary'}
            size={'sm'}
            {...props}
        />
    );
};

export class SectionHeader extends React.Component<SectionHeaderProps> {
    public static ButtonGroup = SectionHeaderButtonGroup;
    public static Button = SectionHeaderButton;
    public static DropdownButton = SectionHeaderDropdownButton;

    render() {
        return <SectionHeaderComp {...this.props} />;
    }
}

export interface SectionHeader extends React.FC<SectionHeaderProps> {
    ButtonGroup: React.FC<PropsWithChildren<any>>;
    Button: React.FC<ButtonWithPopupProps>;
    DropdownButton: React.FC<ButtonWithDropdownMenuProps>;
}
