import * as React from 'react';
import { useField } from 'formik';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { Optionable } from '../optionable';
import { FormField, FormikFieldProps } from './FormField';
import { Label, RadioGroup, RadioGroupItem, RadioGroupProps } from '../radix';
import { useId } from './useId';

export interface FormikRadioField extends RadioGroupProps, FormikFieldProps {
    options: Optionable<any>[];
    inline?: boolean;
    name: string;
}

export const FormikRadioField: React.FC<FormikRadioField> = ({
    label,
    name,
    required,
    options,
    inline,
    readOnly,
    disabled,
    id,
}) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField(name);
    const touched = meta.touched;
    const error = meta.error;
    let hasError = touched && !!error;

    return (
        <FormField>
            <FormikFormFieldLabel htmlFor={fieldId} label={label} name={name} required={required} />
            <RadioGroup
                className={inline ? 'flex-row gap-3' : undefined}
                disabled={disabled}
                aria-disabled={disabled}
                aria-readonly={readOnly}
                aria-invalid={hasError}
                value={field.value}
                onBlur={field.onBlur}
                onValueChange={helpers.setValue}
                id={fieldId}
            >
                {options.map((o, index) => {
                    const itemId = `${fieldId}-${index}`;
                    return (
                        <div key={index} className="flex items-center space-x-1">
                            <RadioGroupItem value={o.value} id={itemId} />
                            <Label htmlFor={itemId}>{o.text}</Label>
                        </div>
                    );
                })}
            </RadioGroup>
        </FormField>
    );
};
