import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import {
    Form,
    FormikInputField,
    FormikPanelForm,
    FormikTextareaField,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    Panel,
    StickyMenuBar,
    toastError,
    toastSuccess,
    withApolloLoading,
} from '@ez/components';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import { BrandEditableItem, FranchiseEditableItem, SuplierEditableItem } from './EditableItems';
import ProductTraitsPanel from './ProductTraitsPanel';
import { FormikHelpers as FormikActions } from 'formik';
import { queryNames } from '../query-names';
import { withProductNode } from '../../../queries/withProductNode';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';
import { PageLayout } from '@ez/components';

const ProductDescriptionFormPanel: React.FC<{ product: NodeType.Product; ProductCatalogMutator }> = ({
    product,
    ProductCatalogMutator,
}) => {
    const initialValues = product;

    const onSubmit = async (values: typeof initialValues, actions: FormikActions<typeof initialValues>) => {
        try {
            actions.setSubmitting(true);
            const updateFields = {
                name: values.name,
                sku: values.sku,
                customSku: values.customSku,
                familyCode: values.familyCode,
                description: values.description,
            };
            await ProductCatalogMutator.updateProducts([{ product: product, ...updateFields }]);
            actions.resetForm({ values: { ...product, ...updateFields } });
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e });
            toastError({ title: 'Failed to update', description: e.message });
        }
        actions.setSubmitting(false);
    };

    return (
        <FormikPanelForm header={'Product'} enableReinitialize={true} onSubmit={onSubmit} initialValues={initialValues}>
            <FormikInputField fluid label="Name" name={'name'} />
            <Form.Group widths={'equal'}>
                <FormikInputField fluid label="SKU" name={'sku'} />
                <FormikInputField fluid label="Custom SKU" name={'customSku'} />
                <FormikInputField fluid label="Family Code" name={'familyCode'} />
            </Form.Group>
            <FormikTextareaField rows={8} label={'Description'} name={'description'} />
        </FormikPanelForm>
    );
};

class Page extends React.Component<PageControlProps> {
    state = {
        updatingDeclarations: false,
    };

    goToList = () => {
        this.props.AppNavigator.navigate('/', { relativeToModule: true });
    };

    onUpdate = (name: string) => async (newValue: string | object) => {
        // console.log('Update', name, newValue);
        try {
            const values: any = { [name]: newValue };
            await this.props.ProductCatalogMutator.updateProducts([{ product: this.props.product, ...values }]);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    onDelete = async () => {
        try {
            await this.props.ProductCatalogMutator.deleteProducts([this.props.product]);
            toastSuccess({ title: 'Product deleted!', icon: 'archive' });
            this.goToList();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    renderStickyMenu() {
        return (
            <StickyMenuBar>
                <MenuBarSection position={'left'}>
                    <MenuBarItem icon={'chevron left'} color={'grey'} onClick={this.goToList}>
                        Products
                    </MenuBarItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarDropdown icon={'bars'} color={'red'}>
                        <MenuBarDropdownItemWithConfirm
                            icon={'trash'}
                            color="red"
                            content={'Delete Product'}
                            confirm={{
                                confirmMessage: {
                                    header: 'Delete Product?',
                                    content: 'Will be deleted permanently!!!',
                                },
                                confirmButton: {
                                    content: 'Delete Product',
                                    icon: 'trash',
                                },
                                negative: true,
                            }}
                            onClick={() => this.onDelete()}
                        />
                    </MenuBarDropdown>
                </MenuBarSection>
            </StickyMenuBar>
        );
    }

    render() {
        const { product } = this.props;
        return (
            <PageLayout>
                {this.renderStickyMenu()}

                <PageLayout.TwoColumns>
                    <div>
                        <ProductDescriptionFormPanel
                            product={product}
                            ProductCatalogMutator={this.props.ProductCatalogMutator}
                        />
                    </div>
                    <div>
                        <Panel>
                            <Panel.Header content={'Owner'} />
                            <Panel.Body>
                                <Panel.Item label={'Organisation'}>
                                    {_.get(product, 'organisationType.name')}
                                </Panel.Item>
                                <Panel.Item label={'Franchise'}>
                                    <FranchiseEditableItem
                                        value={product.franchise}
                                        onChange={(value) => this.onUpdate('franchise')(value)}
                                    />
                                </Panel.Item>
                                <Panel.Divider />
                                <Panel.Item label={'Supplier'}>
                                    <SuplierEditableItem
                                        value={_.get(product, 'supplier', null)}
                                        onChange={(value) => {
                                            this.onUpdate('supplier')(value);
                                        }}
                                    />
                                </Panel.Item>
                                <Panel.Item label={'Brand'}>
                                    <BrandEditableItem
                                        value={product.brand}
                                        onChange={(value) => this.onUpdate('brand')(value)}
                                    />
                                </Panel.Item>
                            </Panel.Body>
                        </Panel>
                    </div>
                </PageLayout.TwoColumns>

                <ProductTraitsPanel productId={product.id} />
            </PageLayout>
        );
    }
}

export default compose(
    withAppNavigator(),
    withProductNode((props: IAppNavigatorProps) => props.params.id),
    withApolloLoading({ show404ForPath: 'data.node.id' }),
    mapProps((props) => {
        const product = _.get(props, 'data.node');
        return {
            ...props,
            product,
        };
    }),
    withProductCatalogMutators(queryNames)
)(Page);

export interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {
    product: NodeType.Product;
    brands: NodeType.Brand[];
    companies: NodeType.ProductCompany[];
}
