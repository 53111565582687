import * as React from 'react';
import { useEffect } from 'react';
import { Button, ButtonWithDropdownMenu, Table } from '@ez/components';
import { InvoiceDoc } from '../constants';
import { fromEdges } from '@poolware/api';
import { Field, FieldArray, getIn, useField, useFormikContext } from 'formik';
import { DisplayCurrency } from '../components/DisplayCurrency';
import { ButtonGroup } from 'semantic-ui-react';

const InputField: React.FC<{ name; onChange?: (newValue: any) => any }> = ({ name, onChange }) => {
    return (
        <Field name={name}>
            {({
                field, // { name, value, onChange, onBlur }
                meta,
            }) => {
                const { onChange: _onChange, ...rest } = field;
                const handleOnChange = (e, d) => {
                    _onChange(e, d);
                    onChange?.(e.target.value);
                };

                return (
                    <div>
                        <input type="text" onChange={handleOnChange} {...rest} />
                        {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                );
            }}
        </Field>
    );
};

function InvoiceItemsTableHeader() {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Qty</Table.HeaderCell>
                <Table.HeaderCell>Unit Price/Rate</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Subtotal</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Tax</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Total</Table.HeaderCell>
                <Table.HeaderCell />
            </Table.Row>
        </Table.Header>
    );
}

const GST_COEF = 0.1;

interface TableRowViewOrEditProps {
    fieldName: string;
    onDelete: (any) => any;
    onDuplicate?: () => any;
}

export const InvoiceItemFieldHoursRate: React.FC<TableRowViewOrEditProps> = ({ fieldName, onDelete, onDuplicate }) => {
    const { values, setFieldValue } = useFormikContext();
    const hours = getIn(values, `${fieldName}.hours`);
    const rate = getIn(values, `${fieldName}.rate`);
    const tax = getIn(values, `${fieldName}.tax`);
    const totalIncGst = getIn(values, `${fieldName}.total`);
    const totalExGst = getIn(values, `${fieldName}.subTotal`);

    const reCalcItemPrice = (rate: number, hours: number) => {
        const newTotalExGst = rate * hours;
        const newGst = newTotalExGst * GST_COEF;
        const newTotalIncGst = newTotalExGst + newGst;
        setFieldValue(`${fieldName}.subTotal`, newTotalExGst);
        setFieldValue(`${fieldName}.tax`, newGst);
        setFieldValue(`${fieldName}.total`, newTotalIncGst);
    };

    const onHoursChange = (newHours: number) => {
        reCalcItemPrice(rate, newHours);
    };
    const onRateChange = (newRate: number) => {
        reCalcItemPrice(newRate, hours);
    };

    // const onChangeInvoiceItemMode = (newMode: InvoiceDoc.InvoiceItemMode) => {
    //     setFieldValue(`${fieldName}.mode`, newMode);
    // };

    return (
        <Table.Row>
            <Table.Cell width={6}>
                <InputField name={`${fieldName}.description`} />
            </Table.Cell>
            <Table.Cell width={2}>
                <InputField name={`${fieldName}.hours`} onChange={(newValue) => onHoursChange(newValue)} />
            </Table.Cell>
            <Table.Cell width={2}>
                <InputField name={`${fieldName}.rate`} onChange={(newValue) => onRateChange(newValue)} />
            </Table.Cell>
            <Table.Cell textAlign={'right'}>
                <DisplayCurrency value={totalExGst} />
            </Table.Cell>
            <Table.Cell textAlign={'right'}>
                <DisplayCurrency value={tax} />
            </Table.Cell>
            <Table.Cell textAlign={'right'}>
                <span className={'w-16'}>
                    <DisplayCurrency value={totalIncGst} />
                </span>
            </Table.Cell>
            <Table.Cell>
                <ButtonGroup>
                    <Button
                        size="xs"
                        icon={'trash'}
                        type={'button'}
                        basic
                        color={'grey'}
                        onClick={onDelete}
                        segmentPosition={'first'}
                    />
                    <ButtonWithDropdownMenu
                        size={'xs'}
                        segmentPosition={'last'}
                        icon={'ellipsis vertical'}
                        options={[
                            { content: 'Duplicate', onClick: onDuplicate },
                            // {
                            //     content: 'Hour/Rate',
                            //     onClick: () => onChangeInvoiceItemMode(InvoiceDoc.InvoiceItemMode.HourRate),
                            // },
                            // {
                            //     content: 'Fixed Cost',
                            //     onClick: () => onChangeInvoiceItemMode(InvoiceDoc.InvoiceItemMode.FixedCost),
                            // },
                        ]}
                    />
                </ButtonGroup>
            </Table.Cell>
        </Table.Row>
    );
};

export interface FormikInputInvoiceItemsProps {
    name: string;
}

export const FormikInputInvoiceItems: React.FC<FormikInputInvoiceItemsProps> = ({ name }) => {
    const { values, setFieldValue } = useFormikContext<InvoiceDoc.DocContent>();

    const formatName = (fieldName: string) => {
        return `${name}${fieldName}`;
    };
    const [fieldTotalPayable] = useField('totalAmountPayable');

    useEffect(() => {
        const items: InvoiceDoc.ItemsType[] = values.items || [];
        const total = items?.reduce((acc, item) => {
            return acc + item.total;
        }, 0);

        setFieldValue('totalAmountPayable', total);
    }, [values.items, setFieldValue]);

    return (
        <div>
            <FieldArray
                name={name}
                render={(arrayHelpers) => {
                    const { form } = arrayHelpers;

                    const items = fromEdges<InvoiceDoc.ItemsType>(getIn(form.values, name));

                    const appendItem = (data: Partial<InvoiceDoc.ItemsType>) => {
                        const item: InvoiceDoc.ItemsType = {
                            description: data.description,
                            tax: data.tax || 0,
                            hours: data.hours || 0,
                            rate: data.rate || 0,
                            subTotal: data.subTotal || 0,
                            total: data.total || 0,
                            // mode: data.mode || InvoiceDoc.InvoiceItemMode.HourRate,
                        };
                        arrayHelpers.push(item);
                        form.validateField(name);

                        // IMPORTANT:
                        // Construct the full path to the `title` field, otherwise it will crash `onBlur` event.
                        // `Touching` the fields triggers form validation.
                        form.setFieldTouched(formatName(`[${items.length}].description`));
                    };

                    return (
                        <>
                            <Table size={'small'}>
                                <InvoiceItemsTableHeader />
                                <Table.Body>
                                    {items.map((item, i) => {
                                        return (
                                            <InvoiceItemFieldHoursRate
                                                fieldName={`${name}[${i}]`}
                                                key={i}
                                                onDelete={() => arrayHelpers.remove(i)}
                                                onDuplicate={() => appendItem(item)}
                                            />
                                        );
                                    })}
                                </Table.Body>
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Button icon={'plus'} onClick={() => appendItem({ description: 'Item' })} />
                                        </Table.Cell>
                                        <Table.Cell colSpan={4} textAlign={'right'}>
                                            Total payable:
                                        </Table.Cell>
                                        <Table.Cell textAlign={'right'}>
                                            <b className={'text-lg'}>
                                                <DisplayCurrency value={fieldTotalPayable.value} />
                                            </b>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </>
                    );
                }}
            />
        </div>
    );
};
