import moment from 'moment';
import { NodeType } from '@poolware/api';
import React, { useMemo } from 'react';
import { Icon, IconWithPopupProps } from '@ez/components';
import { mapServiceJobRecurStatusToIconProps, RecurServiceJobStatus } from '../../constants';

export const isServiceActive = (serviceJob?: NodeType.ServiceJob) => {
    if (serviceJob?.lastAppointmentDate) {
        return moment(serviceJob.lastAppointmentDate).isAfter(moment());
    }
    return true;
};

export interface IconServiceJobRecurStatusProps extends IconWithPopupProps {
    serviceJob?: NodeType.ServiceJob;
    colored?: boolean;
}

export const IconServiceJobRecurStatus: React.FC<IconServiceJobRecurStatusProps> = ({
    serviceJob,
    colored = true,
    ...rest
}) => {
    const isActive = useMemo(() => isServiceActive(serviceJob), [serviceJob]);
    let iconProps = mapServiceJobRecurStatusToIconProps(
        !isActive ? RecurServiceJobStatus.Stopped : RecurServiceJobStatus.Active
    );
    if (!colored) {
        delete iconProps.color;
    }
    return <Icon {...iconProps} {...rest} />;
};
