import * as React from 'react';
import { useState } from 'react';
import * as URLBuilder from '../../../../../routes/url-builder';
import { NodeType, useVendMutators } from '@poolware/api';
import { useApolloClient } from 'react-apollo';

import { useQueryCustomerVendDetails } from './use-query-customer-vend-details';
import { Button, Icon, MenuBar, MenuBarItemProps, Modal, PageSkeletonLoader, toast, toastError } from '@ez/components';
import gql from 'graphql-tag';
import { useAppNavigator } from '@poolware/react-app-navigator';

const IconUnsyncedVend = () => {
    // TODO: fix icon
    return (
        // <Icon.Group>
        <Icon name="tags" color="grey" />
        // <Icon corner name="cancel" color="red" />
        // </Icon.Group>
    );
};

const MenuBarItemRestoreOnVend: React.FC<MenuBarItemProps> = ({ onClick }) => {
    return (
        <MenuBar.Item
            // onClick={onClick}
            disabled={true}
            title={'Vend'}
            color={'grey'}
            icon={'unlink'}
            popup={{
                position: 'right center',
                content: (
                    <>
                        <p>
                            Our system detected that a previously existed record of this customer does not exist on VEND
                            anymore. The most likely reason is that the customer was manually deleted from VEND.
                        </p>
                    </>
                ),
            }}
        />
    );
};

const MenuBarItemPushToVend: React.FC<MenuBarItemProps> = ({ onClick }) => {
    return (
        <MenuBar.Dropdown responsive={false} icon={<IconUnsyncedVend />} color={'grey'} content={'VEND'}>
            <MenuBar.DropdownItem onClick={onClick} content={'Push To Vend'} color={'purple'} icon={'upload'} />
        </MenuBar.Dropdown>
    );
};

const vendSaleDetailsQuery = gql`
    query VendSaleDetails($saleId: ID!) {
        vendSale: node(id: $saleId) {
            id
            ... on VendSale {
                webRegistryUrl
                historyUrl
                status
                date
            }
        }
    }
`;

export const VendButton: React.FC<{ customerId: NodeType.ID }> = ({ customerId }) => {
    const [vendSale, setVendSale] = useState(null);
    const [vendSaleCreating, setVendSaleCreating] = useState(false);
    const client = useApolloClient();
    const { AppNavigator } = useAppNavigator();
    const popupPosition = 'right center';
    const { VendMutator } = useVendMutators(['CustomerVendQuery', 'CustomerQuery']);
    const { loading, error, node: customer } = useQueryCustomerVendDetails(customerId);

    const gotoVendSettings = () => {
        const link = URLBuilder.FranchiseAdmin().Integrations().view;
        AppNavigator.navigate(link);
    };

    const openUrl = (url) => {
        window.open(url, '_blank');
    };

    const openVendRegistry = () => {
        if (vendSale?.webRegistryUrl) {
            openUrl(vendSale.webRegistryUrl);
        }
        setVendSale(null);
    };

    const closeModal = () => {
        setVendSale(null);
    };

    const onCreateSale = async () => {
        setVendSaleCreating(true);

        try {
            const { vendLink } = customer;

            // await wait(3000);
            // const res = null;

            const res = await VendMutator.createVendSale(vendLink);
            const saleId = res?.data?.VendSale?.VendSale?.id;
            if (saleId) {
                const sale = await client.query({
                    query: vendSaleDetailsQuery,
                    fetchPolicy: 'no-cache',
                    variables: { saleId: saleId },
                });
                setVendSale(sale?.data?.vendSale);
            } else {
                toastError({ title: 'Unable to create sale on VEND' });
            }
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to create sale on VEND', description: e.message });
        }
        setVendSaleCreating(false);
    };

    const pushToVend = async (customer: NodeType.Customer) => {
        try {
            await VendMutator.pushCustomerToVend(customer);
        } catch (error) {
            console.error(error);
            toastError({
                time: 4000,
                title: 'Failed to push customer to VEND',
                description: error.message,
            });
        }
    };

    const restoreOnVend = async (customer: NodeType.Customer) => {
        try {
            await VendMutator.resyncVendCustomer(customer.vendLink);
        } catch (error) {
            console.error(error);
            toast({
                type: 'error',
                time: 4000,
                title: 'Failed to restore customer on VEND',
                description: error.message,
            });
        }
    };

    if (loading) {
        return (
            <MenuBar.Dropdown responsive={false} icon={<Icon name={'tag'} color={'grey'} />} content={'VEND'}>
                <MenuBar.Item icon={'spinner'} loading={true} />
            </MenuBar.Dropdown>
        );
    }

    if (error) {
        return (
            <MenuBar.Dropdown responsive={false} icon={<Icon name={'cancel'} color={'red'} />} content={'VEND'}>
                <MenuBar.DropdownItem
                    icon={'frown'}
                    popup={{ content: error?.message }}
                    onClick={gotoVendSettings}
                    content={'Failed to fetch Vend'}
                />
            </MenuBar.Dropdown>
        );
    }

    const vend: NodeType.VendAPI = customer?.user?.entity?.franchise?.vend;
    if (!customer || !vend) {
        // Vend is not configured
        return null;
    }

    const { vendLink } = customer;
    const canCreateSale = true;

    if (!vendLink || !vendLink?.vendURL) {
        // Not synced yet. Show push button.
        return <MenuBarItemPushToVend onClick={() => pushToVend(customer)} />;
    }

    if (vendLink?.isDeleted) {
        // "Reactivate" (sync mutation)
        return <MenuBarItemRestoreOnVend onClick={() => restoreOnVend(customer)} />;
    }

    const openModal = Boolean(vendSaleCreating || vendSale);

    return (
        <>
            <MenuBar.Dropdown responsive={false} icon={<Icon name={'tag'} color={'green'} />} content={'VEND'}>
                <MenuBar.DropdownItem
                    responsive={false}
                    color={'purple'}
                    icon={'external'}
                    content={'Open In Vend'}
                    onClick={() => openUrl(customer?.vendLink?.vendURL)}
                    popup={{
                        position: popupPosition,
                        content: 'Open customer in Vend',
                    }}
                />
                {canCreateSale && (
                    <MenuBar.DropdownItem
                        responsive={false}
                        color={'green'}
                        icon={'tag'}
                        content={'Create Sale'}
                        onClick={onCreateSale}
                        popup={{
                            position: popupPosition,
                            content: 'Start new sale on VEND',
                        }}
                    />
                )}
            </MenuBar.Dropdown>

            <Modal size={'small'} open={openModal} centered={false} closeIcon onClose={closeModal}>
                <Modal.Header>Successfully created sale on VEND!</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {vendSaleCreating && <PageSkeletonLoader lineCount={2} />}
                        {vendSale && (
                            <Button
                                size={'md'}
                                variant={'primary'}
                                className={'w-full'}
                                onClick={openVendRegistry}
                                color={'green'}
                                // icon={'external'}
                                content={'Open in VEND Register'}
                            />
                        )}
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default VendButton;
