import * as React from 'react';
import {
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionConsumer,
    PrintSectionHeader,
    PrintSectionItem,
} from '@ez/components';
import { FormikHeatCalcValueType } from '../../common/types';
import { getCountryTextForValue } from '../../common/use-city-temperature-profile';
import { useFormikContext } from 'formik';

export const PoolProfilePanel: React.FC = () => {
    const { values } = useFormikContext<FormikHeatCalcValueType>();

    const poolVolume = values.poolVolume?.toFixed(1);
    return (
        <>
            <PrintSectionConsumer name={'poolConfig'}>
                <PrintSectionHeader>Pool Profile</PrintSectionHeader>
                {values.country && (
                    <PrintSectionBody>
                        <PrintSectionColumn>
                            <PrintSectionItem labelWidth={'5cm'} label={'Location'}>
                                {getCountryTextForValue(values.country)} / {values.state} / {values.city}
                            </PrintSectionItem>
                        </PrintSectionColumn>
                    </PrintSectionBody>
                )}
                <PrintSectionBody>
                    <PrintSectionColumn>
                        <PrintSectionItem labelWidth={'5cm'} label={'Pool Type'}>
                            {values.poolType}
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'5cm'} label={'Pool Position'}>
                            {values.poolPosition}
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'5cm'} label={'Pool Location'}>
                            {values.poolLocation}
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'5cm'} label={'With Cover'}>
                            {values.poolCover}
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'5cm'} label={'Pool Shape'}>
                            {values.poolShape}
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'5cm'} label={'Pool Volume'}>
                            {poolVolume} m<sup>3</sup>
                        </PrintSectionItem>
                    </PrintSectionColumn>
                    <PrintSectionColumn>
                        <PrintSectionItem labelWidth={'5cm'} label={'Required Pool Temp'}>
                            {values.poolTemp} C
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'5cm'} label={'Electricity Cost'}>
                            {values.electCost} $/kw
                        </PrintSectionItem>
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSectionConsumer>
        </>
    );
};
