import * as React from 'react';
import { ModuleNavLink, ModuleRedirect, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { NotFoundPage, SuggestInputOrgSwitcher, useViewer } from '@poolware/app-shell';
import * as PoolTraits from './PoolTraitDeclarations';
import * as Declaration from './Declarations';
import * as Product from './Products';
import * as ProductCompany from './ProductCompany';
import { MenuBar } from '@ez/components';

const Index = () => {
    return <ModuleRedirect to={'/products'} />;
};

const SectionsNavbar = () => {
    const { isAdmin } = useViewer();
    return (
        <MenuBar.Root className={'mb-2'}>
            <MenuBar.Section position={'left'}>
                <MenuBar.Item>
                    <ModuleNavLink to={'/products'}>Products</ModuleNavLink>
                </MenuBar.Item>
                <MenuBar.Item>
                    <ModuleNavLink to={'/declarations'}>Trait Declarations</ModuleNavLink>
                </MenuBar.Item>
                <MenuBar.Item>
                    <ModuleNavLink to={'/companies'}>Companies & Brands</ModuleNavLink>
                </MenuBar.Item>
                {isAdmin && (
                    <MenuBar.Item>
                        <ModuleNavLink to={'/pool-declarations'}>Pool Traits</ModuleNavLink>
                    </MenuBar.Item>
                )}
            </MenuBar.Section>
            <MenuBar.Section position={'right'}>
                <div className={'p-1 w-80'}>
                    <SuggestInputOrgSwitcher />
                </div>
            </MenuBar.Section>
        </MenuBar.Root>
    );
};

export const Router = () => {
    return (
        <ModuleRoot>
            <SectionsNavbar />
            <ModuleSwitch notFound={NotFoundPage}>
                <ModuleRoute path={'/'} exact component={Index} />
                <ModuleRoute path={'/declarations'} component={Declaration.Router} />
                <ModuleRoute path={'/products'} component={Product.Router} />
                <ModuleRoute path={'/companies'} component={ProductCompany.Router} />
                <ModuleRoute path={'/pool-declarations'} component={PoolTraits.Router} />
            </ModuleSwitch>
        </ModuleRoot>
    );
};
