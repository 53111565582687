import * as React from 'react';
import { Link } from 'react-router-dom';
import { Alert, AlertContent, AlertHeader, Icon, PageLayout } from '@ez/components';

export const NotFoundPage: React.FC = ({ children }) => {
    return (
        <PageLayout.BodySection width={'screen-md'}>
            <Alert type={'warning'}>
                <AlertHeader>
                    <Icon name={'warning sign'} /> 404 - Page Not Found
                </AlertHeader>
                <AlertContent>
                    <Link to={'/'}>
                        <Icon name={'home'} /> Take Me Home
                    </Link>
                </AlertContent>
            </Alert>
            {children}
        </PageLayout.BodySection>
    );
};
