import * as React from 'react';
import gql from 'graphql-tag';
import { QueryConnectionExternalProps, useDefaultConnectionState, useQueryConnection } from '@ez/api-core';
import { NodeType } from '../api-types';

const connectionPath = 'viewer.organisationTypes';
const QL = gql`
    query QueryOrganisationTypesConnection {
        viewer {
            organisationTypes {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export interface QueryOrganisationTypesConnectionExternalProps
    extends QueryConnectionExternalProps<NodeType.OrganisationType> {}

export const useQueryConnectionOrganisationTypes = (props?: QueryOrganisationTypesConnectionExternalProps) => {
    const { variables, connectionState } = useDefaultConnectionState(props);

    return useQueryConnection<NodeType.OrganisationType>({
        query: props?.query || QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
        fetchPolicy: 'cache-first',
    });
};
