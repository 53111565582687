import { compose, withProps } from '@ez/tools';
import _trim from 'lodash/trim';
import _set from 'lodash/set';
import invariant from 'invariant';

import { IMutateCustomer, NodeType, withCustomerMutator } from './api-types';
import { sanitizeAddressInput } from './utils/sanitize-address-input';

export interface CustomerCreateInput {
    user: {
        title?: string;
        firstName: string;
        lastName: string;
        contactName?: string;
        companyName?: string;
    };
    site?: {
        name: string;
    };
    isCreateSiteWithSameAddress?: boolean;
    franchiseId?: string;
    isBillingAddressSame: boolean;
    primaryAddress: {};
    billAddress?: {};
    contacts?: any[];
    tags?: any[];
    note?: string;
}

export interface ICustomerMutatorsInjected extends IMutateCustomer {}

const createMutators = ({ mutateCustomer }: ICustomerMutatorsInjected) => {
    const mutationConfigMapper = (input: CustomerCreateInput): NodeType.CreateCustomerMutationInput => {
        const { user, billAddress, primaryAddress, tags, contacts, note, franchiseId } = input;

        let mutationConfig: any = {};

        const mapContacts = (contacts = []) => {
            return contacts
                .filter((contact) => {
                    return !!contact;
                })
                .map((contact) => ({
                    data: _trim(contact.data),
                    isPrimary: contact.isPrimary,
                    label: _trim(contact.label),
                    type: contact.type.id,
                }))
                .filter((c) => {
                    // allow only contacts when both data and type are set.
                    const allow = c.data && c.type;
                    if (!allow) {
                        console.error('Contact object is malformed', c);
                        return false;
                    }
                    if (c.data && c.data.length > 100) {
                        throw new RangeError(
                            `The length of 'data' string must be withing 100 characters (${c.type.name})`
                        );
                    }
                    if (c.label && c.label.length > 100) {
                        throw new RangeError(
                            `The length of 'label' string must be withing 100 characters (${c.type.name})`
                        );
                    }
                    return true;
                });
        };

        if (user.companyName) {
            mutationConfig.companyName = _trim(user.companyName);
        }
        if (user.contactName) {
            mutationConfig.contactName = _trim(user.contactName);
        }

        const title = user?.title ? _trim(user.title) : null;

        mutationConfig.user = {
            create: {
                title: title,
                firstName: _trim(user.firstName),
                lastName: _trim(user.lastName),
                entity: {
                    create: franchiseId ? { franchise: franchiseId } : {},
                },
            },
        };

        if (note !== undefined) {
            mutationConfig.note = _trim(note);
        }

        if (contacts && contacts.length > 0) {
            mutationConfig = _set(mutationConfig, 'user.create.entity.create.contacts', mapContacts(contacts));
        }

        if (tags && tags.length > 0) {
            const ids = tags.map((marker) => marker.id);
            mutationConfig.tags = { assign: ids };
        }

        if (primaryAddress) {
            mutationConfig.primaryAddress = sanitizeAddressInput(primaryAddress);
            if (input.isBillingAddressSame === false) {
                mutationConfig.billAddress = sanitizeAddressInput(billAddress);
            }
        }

        if (input.isCreateSiteWithSameAddress && input.site) {
            invariant(primaryAddress, 'primary address is required if `site.name` is provided');
            mutationConfig.createSiteWithName = _trim(input.site?.name);
        }

        return mutationConfig;
    };

    const createCustomer = async (input: CustomerCreateInput): Promise<NodeType.ID> => {
        const mutationConfig = mutationConfigMapper(input);
        const resp = await mutateCustomer.create(mutationConfig);
        const customerId = resp?.data?.Customer?.Customer?.id;
        if (!customerId) {
            throw new Error('Server response did not return customer id');
        }

        return customerId;
    };

    const createCustomers = async (inputs: CustomerCreateInput[]): Promise<NodeType.ID[]> => {
        const mutationConfigs = inputs.map(mutationConfigMapper);
        const resp = await mutateCustomer.create(mutationConfigs);
        const results = resp?.data?.Customer?.results || [];
        const customerIds = results.map((r) => r?.Customer?.id);

        return customerIds;
    };

    const udpateAddress = async (
        customer: NodeType.NodeOrId<NodeType.Customer>,
        address: Partial<NodeType.Address>,
        type: 'primaryAddress' | 'billAddress'
    ): Promise<any> => {
        const sanitized = sanitizeAddressInput(address, true);
        delete sanitized['entity'];

        return await mutateCustomer.update({
            id: NodeType.extractId(customer),
            [type]: {
                update: sanitized,
            },
        });
    };

    const createAddress = async (
        customer: NodeType.NodeOrId<NodeType.Customer>,
        address: Partial<NodeType.Address>,
        type: 'primaryAddress' | 'billAddress'
    ): Promise<any> => {
        const sanitized = sanitizeAddressInput(address, true);
        delete sanitized['entity'];
        delete sanitized['id'];

        return await mutateCustomer.update({
            id: NodeType.extractId(customer),
            [type]: {
                create: sanitized,
            },
        });
    };

    const updatePrimaryAddress = async (
        customer: NodeType.NodeOrId<NodeType.Customer>,
        address: Partial<NodeType.Address>
    ) => udpateAddress(customer, address, 'primaryAddress');

    const createPrimaryAddress = (customer: NodeType.NodeOrId<NodeType.Customer>, address: Partial<NodeType.Address>) =>
        createAddress(customer, address, 'primaryAddress');

    const createBillAddress = (customer: NodeType.NodeOrId<NodeType.Customer>, address: Partial<NodeType.Address>) =>
        createAddress(customer, address, 'billAddress');

    const updateBillAddress = async (
        customer: NodeType.NodeOrId<NodeType.Customer>,
        address: Partial<NodeType.Address>
    ) => udpateAddress(customer, address, 'billAddress');

    const deleteBillAddress = async (customer: NodeType.NodeOrId<NodeType.Customer>) => {
        return mutateCustomer.update({ id: NodeType.extractId(customer), billAddress: { id: null } });
    };

    const updateUserName = async (
        customer: NodeType.NodeOrId<NodeType.Customer>,
        name: Partial<{ title?: string; firstName: string; lastName: string }>
    ): Promise<any> => {
        if (!name) return;

        const { firstName, lastName } = name;

        if (!firstName && !lastName) {
            return;
        }

        let user: any = {};
        if (firstName) {
            user.firstName = _trim(firstName);
        }
        if (lastName) {
            user.lastName = _trim(lastName);
        }
        const title = name?.title ? _trim(name.title) : null;

        user.title = title;

        return mutateCustomer.update({
            id: NodeType.extractId(customer),
            user: user,
        });
    };

    const updateCustomerNotes = async (customer: NodeType.NodeOrId<NodeType.Customer>, notes: string) => {
        return mutateCustomer.update({
            id: NodeType.extractId(customer),
            note: notes,
        });
    };

    const updateContactName = async (customer: NodeType.NodeOrId<NodeType.Customer>, contactName: string) => {
        return mutateCustomer.update({
            id: NodeType.extractId(customer),
            contactName: _trim(contactName),
        });
    };

    const deleteContactName = async (customer: NodeType.NodeOrId<NodeType.Customer>) => {
        return mutateCustomer.update({
            id: NodeType.extractId(customer),
            contactName: null,
        });
    };

    const updateCompanyName = async (customer: NodeType.NodeOrId<NodeType.Customer>, companyName: string) => {
        return mutateCustomer.update({
            id: NodeType.extractId(customer),
            companyName: _trim(companyName),
        });
    };

    const deleteCompanyName = async (customer: NodeType.NodeOrId<NodeType.Customer>) => {
        return mutateCustomer.update({ id: NodeType.extractId(customer), companyName: null });
    };

    return {
        CustomerMutator: {
            createCustomer,
            createCustomers,
            createPrimaryAddress,
            updatePrimaryAddress,
            createBillAddress,
            updateBillAddress,
            deleteBillAddress,
            updateUserName,
            updateCompanyName,
            deleteCompanyName,
            updateContactName,
            deleteContactName,
            updateCustomerNotes,
        },
    };
};

export const withCustomerMutators = (refetchQueries) =>
    compose(withCustomerMutator(refetchQueries), withProps(createMutators));

export interface ICustomerMutatorsProps extends ICustomerMutatorsInjected, ReturnType<typeof createMutators> {}
