import * as React from 'react';
import StaffFilter from './StaffFilter';
import AppointmentStatusFilter from './AppointmentStatusFilter';
import AppointmentGroupFilter from './AppointmentGroupFilter';
import ClearAllFilters from './ClearAllFilters';
import DateRangeFilter from './DateRangeFilter';
import FranchiseFilter from './FranchiseFilter';
import { AdminOnly } from '@poolware/app-shell';
import { VStack } from '@ez/components';

const SearchBar: React.FC<any> = () => {
    return (
        <VStack space={0.5}>
            <AdminOnly>
                <FranchiseFilter />
            </AdminOnly>
            <DateRangeFilter />
            <ClearAllFilters />
            <AppointmentGroupFilter />
            <AppointmentStatusFilter />
            <StaffFilter />
        </VStack>
    );
};

export default SearchBar;
