import * as React from 'react';
import NewCustomerForm from './NewCustomerForm';
import { toastError } from '@ez/components';
import * as Yup from 'yup';
import { AddressValidationSchema } from '../../../../CommonComponents/Address/FormikAddressInputFields';
import { compose } from '@ez/tools';
import { ICustomerMutatorsProps, NodeType, withCustomerMutators } from '@poolware/api';
import _omit from 'lodash/omit';

interface PageProps extends PageControlProps {}

const SimpleString = Yup.string().max(100, 'Too Long!');

const validationSchema = Yup.object({
    user: Yup.object({
        firstName: SimpleString.required('Required'),
        lastName: SimpleString.required('Required'),
        contactName: SimpleString.nullable().notRequired(),
        companyName: SimpleString.nullable().notRequired(),
    }),
    primaryAddress: AddressValidationSchema.required('Required'),
});

export const Page: React.FC<PageProps> = (props) => {
    const onCancel = async () => {
        props.onCancel();
    };

    const onSubmit = async (values) => {
        try {
            // throw new Error('DEBUG STOP!!!');
            const newCustomerId = await props.CustomerMutator.createCustomer(values);
            props.onSubmit(newCustomerId);
        } catch (error) {
            toastError({
                icon: 'user',
                title: 'Failed to create customer',
                description: error.message,
                time: 4000,
            });
        }
    };

    const initialValues: any = {
        isBillingAddressSame: true,
        user: {
            firstName: '',
            lastName: '',
            contactName: null,
            companyName: null,
        },
        primaryAddress: {
            city: '',
            state: '',
            postCode: '',
        },
    };

    if (props.defaultAddress) {
        initialValues.primaryAddress = _omit(props.defaultAddress, ['entity', 'id']);
    }

    return (
        <NewCustomerForm
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    );
};

export default compose(withCustomerMutators(['ListCustomers']))(Page) as React.ComponentType<ExternalProps>;

export interface ExternalProps {
    defaultAddress: NodeType.Address;
    onCancel: () => any;
    onSubmit: (customerId: NodeType.ID) => any;
}
export interface PageControlProps extends ICustomerMutatorsProps, ExternalProps {}
