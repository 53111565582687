import _pick from 'lodash/pick';

// These are fields available in `CreateAddressInput` used in GraphQL API mutations
export const addressKeys = [
    'id',
    'poBoxNumber',
    'destinationName',
    'buildingName',
    'streetNumber',
    'streetName',
    'suburb',
    'postCode',
    'state',
    'city',
    'entity',
    'country',
    'coordinates',
];

export const sanitizeInput = (inputs, whiteListFields = []) => {
    if (!inputs) throw new Error('Expected input');
    return _pick(inputs, whiteListFields);
};

export type AddressSanitizedType = {
    poBoxNumber?: string;
    destinationName?: string;
    buildingName?: string;
    streetNumber?: string;
    streetName?: string;
    postCode?: string;
    suburb?: string;
    state?: string;
    city?: string;
    country?: string;
    id?: string;
    coordinates?: {
        lat: number;
        lng: number;
    };
};

export const sanitizeAddressInput = (address, deleteId: boolean = false): AddressSanitizedType => {
    if (!address) throw new Error('Expected address');

    const s = sanitizeInput(address, addressKeys);
    if (deleteId) {
        delete s['id'];
    }
    return s;
};
