import * as React from 'react';
import { useField } from 'formik';
import { FormikFormFieldLabel, Modal, useModalCtrl } from '@ez/components';
import { NodeType } from '@poolware/api';
import { SanitisersTable } from './SanitisersTable';
import { PoolTypeTable } from './PoolTypeTable';
import { FormField, InputWithAction } from '@ez/components';

const PickerBase: React.FC<{
    label?: React.ReactNode;
    name: string;
    required?: boolean;
    value: any;
    onClear: () => void;
    onSearch: () => void;
}> = ({ label, name, required, value, onSearch, onClear }) => {
    return (
        <FormField>
            <FormikFormFieldLabel label={label} name={name} required={required} />
            <InputWithAction
                actionIcon={'search'}
                value={value || ''}
                isClearable={!!value}
                onClear={onClear}
                onClick={onSearch}
            />
        </FormField>
    );
};

interface FormikPoolSanitiserSelectFieldProps {
    name: string;
    label: string;
    required?: boolean;
    disabled?: boolean;
    organisationId?: string;
}

export const FormikPoolSanitiserSelectField: React.FC<FormikPoolSanitiserSelectFieldProps> = ({
    name,
    label,
    required,
    organisationId,
}) => {
    const modalCtrl = useModalCtrl();
    const [field, meta, helpers] = useField<NodeType.Sanitiser>({ name: name });

    const onProductSelect = (sanitiser: NodeType.Sanitiser) => {
        helpers.setValue(sanitiser);
        modalCtrl.onClose();
    };

    return (
        <>
            <PickerBase
                name={name}
                required={required}
                label={label}
                value={field.value?.name}
                onClear={() => helpers.setValue(null)}
                onSearch={modalCtrl.onOpen}
            />
            <Modal {...modalCtrl}>
                <Modal.Header>Select Sanitiser</Modal.Header>
                <div className={'p-4'}>
                    <SanitisersTable
                        organisationId={organisationId}
                        onCancel={modalCtrl.onClose}
                        onSelect={onProductSelect}
                    />
                </div>
            </Modal>
        </>
    );
};

export const FormikPoolTypeSelectField: React.FC<FormikPoolSanitiserSelectFieldProps> = ({
    name,
    label,
    required,
    organisationId,
}) => {
    const modalCtrl = useModalCtrl();
    const [field, meta, helpers] = useField<NodeType.PoolType>({ name: name });

    const onSelect = (sanitiser: NodeType.PoolType) => {
        helpers.setValue(sanitiser);
        modalCtrl.onClose();
    };

    return (
        <>
            <PickerBase
                name={name}
                required={required}
                label={label}
                value={field.value?.name}
                onClear={() => helpers.setValue(null)}
                onSearch={modalCtrl.onOpen}
            />
            <Modal {...modalCtrl}>
                <Modal.Header>Select Pool Type</Modal.Header>
                <div className={'p-4'}>
                    <PoolTypeTable organisationId={organisationId} onCancel={modalCtrl.onClose} onSelect={onSelect} />
                </div>
            </Modal>
        </>
    );
};
