import * as React from 'react';
import { useMemo } from 'react';
import { CalendarEventDensity, CalendarEventMode, CalendarEventType } from '../types';
import { SemanticCOLORS } from '@ez/components';
import { Display, Icon, Popup, stringFormatters } from '@ez/components';
import { getIconNameForAppointmentStatus } from '../../utils';
import { cn, isTouch } from '@ez/tools';
import { CalendarEventBaseProps } from './types';
import { ModuleIconNames } from '../../../constants';
import { NodeType } from '@poolware/api';
import { CalendarEventPopoverContent } from './CalendarEventPopoverContent';

export const CalendarBlockEvent: React.FC<{ event: CalendarEventType }> = React.memo(({ event }) => {
    const { mode } = event;
    if (mode !== CalendarEventMode.APPOINTMENT_ITEM) {
        return null;
    }

    // @ts-ignore
    const { formattedDate, item } = event;
    const {
        customer,
        isRecurring,
        isRecurrentOrigin,
        staff,
        address,
        pool,
        note,
        status,
        serviceJob,
        workOrder,
        color,
        group,
        duration,
        patternBlockId,
    } = item;

    const hasRecurrence = patternBlockId || isRecurrentOrigin || isRecurring || serviceJob?.isRecurring;
    const statusIcon =
        NodeType.AppointmentStatusEnum.NotStarted === status ? null : getIconNameForAppointmentStatus(status);
    const hasServiceJobIcon = !!serviceJob && !serviceJob?.isImplicit && !serviceJob?.isRecurring;
    const isRecurringCase = !!serviceJob?.isRecurring;
    // const hasWOIcon = !isRecurringCase && !hasServiceJobIcon && !!workOrder?.id;
    const hasIcon = statusIcon || hasRecurrence || hasServiceJobIcon || note;

    const serviceCaseTitle = serviceJob?.title;
    const showServiceCase = !!serviceJob && (!serviceJob?.isImplicit || serviceJob?.isRecurring);

    let recurIconColor: SemanticCOLORS = undefined;
    if (hasRecurrence) {
        if (isRecurringCase) {
            recurIconColor = 'purple';
        } else if (!isRecurring && isRecurrentOrigin) {
            recurIconColor = 'grey';
        } else {
            recurIconColor = 'blue';
        }
    }

    const groupColor = group?.defaultColor;

    if (color) {
        // debugger;
    }
    const style = {
        backgroundColor: color || 'hsl(0, 0%, 97%)',
        borderLeft: groupColor ? `6px solid ${groupColor}` : undefined,
    };

    /***
     * Performance optimisation heuristics.
     * Compute the max number of items to show in the event box
     * based on density flag and appointment duration.
     */
    let trimCount = 10;
    let showItemIcon = true;
    let showExtraInfo = true;
    let densityClassName = '';
    const dataDensity = event.dataDensity;

    if (dataDensity === CalendarEventDensity.Normal) {
        trimCount = Math.max(Math.floor(duration / 10), 5);
        densityClassName = 'density-normal';
    } else if (dataDensity === CalendarEventDensity.Reduced) {
        trimCount = Math.max(Math.floor(duration / 10), 4);
        showItemIcon = false;
        densityClassName = 'density-reduced';
    } else if (dataDensity === CalendarEventDensity.Low) {
        trimCount = Math.max(Math.floor(duration / 29), 1);
        showItemIcon = false;
        showExtraInfo = false;
        densityClassName = 'density-low';
    }

    //

    const className = cn(['pw-calendar-event', densityClassName], {
        highlighted: event.highlighted,
        dimmed: event.dimmed,
        selected: event.selected,
    });

    const items = useMemo(() => {
        return [
            customer && (
                <div className={'pw-item pw-customer'}>
                    {showItemIcon && <Icon name={'user'} />}
                    {stringFormatters.formatEntityName(customer, '')}
                </div>
            ),
            showServiceCase && serviceCaseTitle && (
                <div className={'pw-item pw-job'}>
                    {showItemIcon && <Icon name={ModuleIconNames.ServiceCase} />}
                    <span>{serviceCaseTitle}</span>
                </div>
            ),
            staff && (
                <div className={'pw-item'}>
                    {showItemIcon && <Icon name={'spy'} />}
                    {stringFormatters.formatEntityName(staff, '')}
                </div>
            ),
            address && (
                <div className={'pw-item pw-address'}>
                    {showItemIcon && <Icon name={'marker'} />}
                    {stringFormatters.formatAddress(address)}
                </div>
            ),

            showExtraInfo && pool && (
                <>
                    {!address && (
                        <div className={'pw-item pw-address'}>
                            {showItemIcon && <Icon name={'marker'} />}
                            {stringFormatters.formatAddress(pool.address)}
                        </div>
                    )}
                    {/*<div  className={'pw-item'}>*/}
                    {/*    {showItemIcon && <Icon name={'life ring'} />}*/}
                    {/*    <span className={'pw-pool-name'}>Pool: {pool.volume && `${pool.volume} L`}</span>*/}
                    {/*</div>*/}
                </>
            ),
            showExtraInfo && note && (
                <div className={'pw-item'}>
                    <Display.Text limit={120} value={note} className={'pw-note preview'} />
                </div>
            ),
        ].filter(Boolean);
    }, [item, showExtraInfo, showItemIcon]);

    const showTime = duration > 30;
    return (
        <div className={className} style={style}>
            {showTime && <div className={'pw-item pw-time'}>{formattedDate}</div>}
            {items.map((item, index) => {
                // This a performance optimisation.
                // Don't render details if appointment is too short.
                if (!item || index >= trimCount) return null;
                return <React.Fragment key={index}>{item}</React.Fragment>;
            })}
            {/*Icons*/}
            {hasIcon && (
                <div className={'pw-item pw-item-icons'}>
                    {/*<span className={'text-xs'}>{dataDensity}: {trimCount} </span>*/}
                    {/*{hasWOIcon && <Icon name={ModuleIconNames.WorkOrder} color={ModuleColorNames.WorkOrder} />}*/}
                    {hasServiceJobIcon && <Icon name={'briefcase'} color={'purple'} />}
                    {hasRecurrence && <Icon name={'refresh'} color={recurIconColor} />}
                    {note && <Icon name={'sticky note'} />}
                    {statusIcon && <Icon name={statusIcon} />}
                </div>
            )}
        </div>
    );
});

export class CalendarEventAppItem extends React.PureComponent<CalendarEventBaseProps> {
    render() {
        const { event, draggable } = this.props;

        let noPopup = isTouch; // || event.selected;

        if (
            draggable?.dragAndDropAction?.interacting // if an event is being dragged right now
        ) {
            noPopup = true;
        }

        if (noPopup) {
            // Don't use popup on mobile devices.
            return <CalendarBlockEvent event={event} />;
        }

        return (
            <Popup
                disabled={noPopup}
                hoverable
                mouseEnterDelay={500}
                trigger={
                    <div style={{ height: '100%' }}>
                        <CalendarBlockEvent event={event} />
                    </div>
                }
                position="top center"
                wide={true}
                inverted={true}
                content={<CalendarEventPopoverContent event={event} />}
                closeOnTriggerClick={true}
                closeOnDocumentClick={true}
            />
        );
    }
}
