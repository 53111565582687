import { NotFoundPage } from '@poolware/app-shell';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { ListPage } from './List';
import { ViewPage } from './View';
import { NewPage } from './New';
import * as React from 'react';

export const Router = () => {
    return (
        <ModuleRootSwitch notFound={NotFoundPage}>
            <ModuleRoute path="/new" exact={true} component={NewPage} />
            <ModuleRoute path="/:deviceId" component={ViewPage} />
            <ModuleRoute path={'/'} exact={true} component={ListPage} />
        </ModuleRootSwitch>
    );
};
