import * as Yup from 'yup';
import * as React from 'react';
import { FormikDefaultForm, FormikDefaultFormProps, FormikTextareaField } from '@ez/components';
import { DiscussionMessage } from './types';

const validationSchema = Yup.object({
    message: Yup.string().max(1024).required('Must not be empty'),
});

export const MessageForm: React.FC<FormikDefaultFormProps & { placeholder?: string }> = ({
    onSubmit,
    placeholder,
    ...props
}) => {
    const handleOnSubmit = async (value: Omit<DiscussionMessage, 'id'>, action) => {
        const { message, ...rest } = value;
        await onSubmit({ message: message?.trim(), ...rest }, action);
    };

    return (
        <FormikDefaultForm
            debug={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            submitButton={{ content: 'Post' }}
            {...props}
        >
            {({ errors }) => {
                const error = errors?.message;
                return (
                    <>
                        <FormikTextareaField autoFocus name={'message'} rows={8} placeholder={placeholder} />
                        <div style={{ color: 'red' }}>{error}</div>
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};
