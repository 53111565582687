import * as React from 'react';
import { useCallback } from 'react';
import gql from 'graphql-tag';
import _set from 'lodash/set';
import { staffFragment } from '../fragments';
import { NodeType } from '../api-types';
import {
    QueryConnection,
    QueryConnectionExternalProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@ez/api-core';

const connectionPath = 'viewer.staff';
const QL = gql`
    query QueryStaffConnection($first: Int, $page: Int, $search: StaffViewerStaffSearch, $sort: StaffViewerStaffSort) {
        viewer {
            staff(first: $first, page: $page, search: $search, sort: $sort) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        ...StaffFragment
                        teams {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${staffFragment}
`;

export interface QueryStaffConnectionExternalProps extends QueryConnectionExternalProps<NodeType.Staff> {
    franchiseId?: NodeType.ID;
    staffIds?: NodeType.ID[];
    showDisabled?: boolean;
    showDeleted?: boolean;
    email?: string;
    userName?: string;
}

const mapPropsToSearchQuery = (props: Omit<QueryStaffConnectionExternalProps, 'children'>) => {
    if (!props) {
        return undefined;
    }

    const { email, userName, franchiseId } = props;

    const search = {};

    if (franchiseId) {
        _set(search, 'user.entity.franchise.id', franchiseId);
    }

    if (email) {
        _set(search, 'registration.email.like', email);
    }

    if (userName) {
        const tokens = userName?.split(/[ ]+/).filter(Boolean);
        if (tokens.length > 0) {
            _set(search, 'user.anyString', tokens);
        }
    }
    return search;
};

const filterStaff =
    (props: { showDeleted?: boolean; showDisabled?: boolean }) =>
    (staff: NodeType.Staff): boolean => {
        const isDeleted = !staff?.registration;
        const isDisabled = !!staff?.registration?.disabledAt;
        if (props.showDeleted && isDeleted) return true;
        if (props.showDisabled && isDisabled && !isDeleted) return true;
        return !isDeleted && !isDisabled;
    };

export const QueryStaffConnection: React.FC<QueryStaffConnectionExternalProps> = (props) => {
    const { variables, connectionState } = useDefaultConnectionState({
        defaultSortKey: 'firstName',
        defaultSortDirection: SortDirection.ASC,
        ...props,
    });

    variables.search = mapPropsToSearchQuery(props);
    const predicate = filterStaff(props);
    const postfilter = (items: NodeType.Staff[]) => items.filter(predicate);
    return (
        <QueryConnection
            query={props.query || QL}
            variables={variables}
            connectionPath={connectionPath}
            connectionConfig={connectionState}
            postfetchFilter={postfilter}
            children={props.children}
        />
    );
};

export function useQueryStaffConnection(props?: Omit<QueryStaffConnectionExternalProps, 'children'>) {
    const connectionPath = 'viewer.staff';

    const { variables, connectionState } = useDefaultConnectionState({
        defaultSortKey: 'firstName',
        defaultSortDirection: SortDirection.ASC,
        search: mapPropsToSearchQuery(props),
        defaultPageSize: 100,
        debugName: 'useQueryStaffConnection',
        ...props,
    });

    const postfilter = useCallback(
        (items: NodeType.Staff[]) => {
            const predicate = filterStaff({ showDeleted: props?.showDeleted, showDisabled: props?.showDisabled });
            return items.filter(predicate);
        },
        [props]
    );

    return useQueryConnection<NodeType.Staff>({
        query: props?.query || QL,
        skip: props?.skip,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
        fetchPolicy: props?.fetchPolicy || 'cache-first',
        postfetchFilter: postfilter,
    });
}
