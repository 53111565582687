import { DateRangeOptionItem } from '@ez/components';
import moment from 'moment';

export const defaultDateRange = {
    startDate: moment().subtract(30, 'days').startOf('d').toDate(),
    endDate: moment()
        // .subtract(1, 'd')
        .endOf('d')
        .toDate(),
};

export const getDateRangeSelectOptions = (): DateRangeOptionItem[] => [
    {
        key: 'custom',
        text: 'Custom',
        value: 'custom',
    },
    {
        key: 'last7d',
        text: 'Prev 7 days',
        value: 'last7d',
        dateRange: {
            startDate: moment().subtract(6, 'd').startOf('d').toDate(),
            endDate: moment()
                // .subtract(1, 'd')
                .endOf('d')
                .toDate(),
        },
    },
    {
        key: 'last14d',
        text: 'Prev 14 days',
        value: 'last14d',
        dateRange: {
            startDate: moment().subtract(13, 'd').startOf('d').toDate(),
            endDate: moment()
                // .subtract(1, 'd')
                .endOf('d')
                .toDate(),
        },
    },
    {
        key: 'last60d',
        text: 'Prev 60 days',
        value: 'last60d',
        dateRange: {
            startDate: moment().subtract(60, 'd').startOf('d').toDate(),
            endDate: moment()
                // .subtract(1, 'd')
                .endOf('d')
                .toDate(),
        },
    },
    {
        key: 'last1year',
        text: 'Prev 1 year',
        value: 'prev1year',
        dateRange: {
            startDate: moment().subtract(1, 'year').startOf('d').toDate(),
            endDate: moment()
                // .subtract(1, 'day')
                .endOf('d')
                .toDate(),
        },
    },
    {
        key: 'last1m',
        text: 'Last month',
        value: 'last1m',
        dateRange: {
            startDate: moment().subtract(1, 'month').startOf('month').toDate(),
            endDate: moment().subtract(1, 'month').endOf('month').toDate(),
        },
    },
    {
        key: 'last6m',
        text: 'Last 6 month',
        value: 'last6m',
        dateRange: {
            startDate: moment().subtract(6, 'month').startOf('month').toDate(),
            endDate: moment().subtract(1, 'month').endOf('month').toDate(),
        },
    },
    {
        key: 'last12m',
        text: 'Last 12 month',
        value: 'last12m',
        dateRange: {
            startDate: moment().subtract(12, 'month').startOf('month').toDate(),
            endDate: moment().subtract(1, 'month').endOf('month').toDate(),
        },
    },
    {
        key: 'yearTodate',
        text: 'Year to date',
        value: 'yearTodate',
        dateRange: {
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('d').toDate(),
        },
    },
];
