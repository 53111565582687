import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Icon, MenuBar, PageLayout, StickyMenuBar, toastError } from '@ez/components';
import { QuickActionButton, SectionHeader } from '../components';
import { NodeType } from '@poolware/api';
import { useMobileAppointmentCtx } from '../Root';
import { PageSectionJobDetails } from './PageSectionJobDetails';
import { PageSectionApptDetails } from './PageSectionApptDetails';
import { AppointmentStatusButtons } from '../../AppointmentDock/AppointmentView.HeaderStatusButtons';
import { mapConfFromStatusMap } from '../../utils';
import { PageSectionNotesAndPhotos } from './PageSectionNotesAndPhotos';
import { wait } from '@ez/tools';

export interface PageProps {}

const StageButton = () => {
    const { appointmentItem, appointmentItemMutator, workOrderMutator } = useMobileAppointmentCtx();
    const { status } = appointmentItem;
    const buttonConfs = mapConfFromStatusMap(status);

    const onChangeStatus = async (newStatus) => {
        try {
            await appointmentItemMutator.mutateAppointmentItem.update({
                id: NodeType.extractId(appointmentItem),
                status: newStatus,
            });

            /// HACK: move this logic to backend.
            const wo = appointmentItem?.workOrder;
            if (!wo?.stage) {
                return;
            }
            if (
                wo?.stage?.type === NodeType.ServiceJobStageType.Opened ||
                wo?.stage?.type !== NodeType.ServiceJobStageType.InProgress
            ) {
                // Change WO status only if appointment status is changed.
                const smap = {
                    finished: NodeType.ServiceJobStageType.Finished,
                    failed: NodeType.ServiceJobStageType.Canceled,
                };
                const woStatus = smap[newStatus];
                if (woStatus !== undefined) {
                    await workOrderMutator.update({ id: wo?.id, stageType: woStatus });
                }
            }

            ///
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change status', description: error.message });
        }
    };
    return <AppointmentStatusButtons onChangeStatus={onChangeStatus} buttonConfigs={buttonConfs} />;
    return (
        <div className={'w-full h-[48px] border border-green flex flex-row items-center justify-between'}>
            <div className={'w-[48px] text-center'}>
                <Icon name={'chevron left'} />
            </div>
            <div className={'text-lg flex-grow max-w-fit'}>Stage Button</div>
            <div className={'w-[48px] text-center'}>
                <Icon name={'chevron right'} />
            </div>
        </div>
    );
};

const PageSectionQuickActions = () => {
    const { AppNavigator } = useAppNavigator();
    const { isWorkOrderPending, workOrder, serviceJob, startWorkOrder } = useMobileAppointmentCtx();
    if (!serviceJob) {
        return null;
    }

    const quickActions = [
        isWorkOrderPending && {
            icon: 'check',
            content: 'Start Work Order',
            onClick: async () => {
                startWorkOrder();
            },
        },
        {
            icon: 'camera',
            disabled: isWorkOrderPending,
            content: 'Take Photo',
            onClick: () => AppNavigator.navigateRelative('/photos'),
        },
        false && {
            icon: 'lab',
            disabled: isWorkOrderPending,
            content: 'Test Async',
            onClick: async () => {
                await wait(2000);
            },
        },
        {
            icon: 'bell',
            content: 'Additional Work',
            disabled: isWorkOrderPending,
            onClick: () => AppNavigator.navigateRelative('/new-actionable'),
        },
        {
            icon: 'lab',
            content: 'Start Water Test',
            disabled: isWorkOrderPending || !workOrder?.pool?.id || !workOrder?.id,
            onClick: () => {
                AppNavigator.navigate('/new', {
                    moduleId: 'WATER_TEST',
                    setOrigin: true,
                    query: {
                        poolId: workOrder?.pool?.id,
                        woId: workOrder?.id,
                    },
                    state: {
                        workOrder: workOrder,
                    },
                });
            },
        },
    ].filter(Boolean) as {
        icon: string;
        content: string;
        onClick: () => Promise<any>;
        disabled?: boolean;
    }[];

    return (
        <PageLayout.BodySection>
            <SectionHeader>Quick Actions</SectionHeader>
            <div className={'flex flex-row space-x-4 w-full overflow-x-auto pb-4 mb-4'}>
                {quickActions.map((action, i) => (
                    <QuickActionButton
                        disabled={action.disabled}
                        key={i}
                        label={action.content}
                        icon={action.icon}
                        onClick={action.onClick}
                    />
                ))}
            </div>
        </PageLayout.BodySection>
    );
};

export const Page: React.FC<PageProps> = ({}) => {
    const [showApptDetails, setShowApptDetails] = React.useState(true);
    const [showJobDetails, setJobApptDetails] = React.useState(true);
    const [showNotesAndAttachments, setShowNotesAndAttachments] = React.useState(true);
    const { AppNavigator } = useAppNavigator();

    const { isWorkOrderPending, workOrder, serviceJob } = useMobileAppointmentCtx();

    const onGoBack = () => {
        AppNavigator.navigateToOrigin('/scheduler');
    };

    return (
        <PageLayout width={'screen-sm'} className={'text-[15px]'}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item icon={'chevron left'} onClick={onGoBack} />
                    <MenuBar.HeaderItem content={'Pool Cleaning'} />
                </MenuBar.Section>
            </StickyMenuBar>

            <StageButton />

            <PageSectionQuickActions />

            <PageSectionApptDetails //
                expanded={showApptDetails}
                onToggle={() => setShowApptDetails(!showApptDetails)}
            />

            {serviceJob && (
                <PageSectionJobDetails
                    expanded={showJobDetails}
                    onToggle={() => {
                        setJobApptDetails(!showJobDetails);
                    }}
                />
            )}

            {!isWorkOrderPending && workOrder && (
                <PageSectionNotesAndPhotos
                    expanded={showNotesAndAttachments}
                    onToggle={() => setShowNotesAndAttachments(!showNotesAndAttachments)}
                />
            )}
        </PageLayout>
    );
};
