import * as React from 'react';
import { HeaderButtons, HeaderButtonType } from '../panel';
import tw, { styled } from 'twin.macro';

const SectionHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;

    &:first-child {
        margin-top: 0;
    }
`;

const LeftSpan = styled.span`
    flex-grow: 0;
    align-self: center;
`;

const RightSpan = styled.span`
    flex-grow: 2;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;

    &&& > * {
        margin: 0;
    }
`;

export interface FormSectionHeaderProps {
    button?: HeaderButtonType | HeaderButtonType[];
}

export const FormSectionHeader: React.FC<FormSectionHeaderProps> = ({ children, button, ...props }) => {
    return (
        <SectionHeaderContainer
            className={'text-tertiary text-lg font-bold mb-4 border-b-tertiary border-b border-solid'}
        >
            <LeftSpan>{children}</LeftSpan>
            <RightSpan>
                <HeaderButtons buttons={button} autoHideButtonsContent={false} />
            </RightSpan>
        </SectionHeaderContainer>
    );
};

export const FormSectionBody: React.FC = (props) => <div className={'py-1 mb-2'} {...props} />;
