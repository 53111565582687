import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';

import { withApolloLoading } from '@ez/components';
import { addressFragment, NodeType, PoolMutatorProps, siteFragment, withPoolMutators } from '@poolware/api';

import Page from './Page';
import { IWithPageProps, withPageProps } from '../../../../components/withPageProps';

const SiteQuery = gql`
    query SiteQuery($siteId: ID!) {
        site: node(id: $siteId) {
            ... on Site {
                id
                ...SiteFragment
            }
        }
    }
    ${siteFragment}
    ${addressFragment}
`;

export default compose(
    withPageProps(),
    graphql(SiteQuery, {
        options: (props: any) => {
            const siteId = props?.query?.siteId || props?.siteId;
            return {
                variables: { siteId: siteId },
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.site.id' }),
    mapProps((props) => ({
        ...props,
        site: props?.data?.site,
    })),
    withPoolMutators(['CustomerSitesList', 'SiteQuery'])
)(Page);

export interface PageControlProps extends IWithPageProps, PoolMutatorProps {
    site: NodeType.Site;
}
