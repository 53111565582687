import * as React from 'react';
import { FormikDefaultForm, FormikInputField, MenuBar, PageLayout, StickyMenuBar, toastError } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { EZCDevicePickerPanel, FormikEZCDevicePicker } from './EZCDevicePicker';
import { FormikCustomerAndLocationInputFields } from '@poolware/app-shell';
import { useMutationEZCDeviceLink } from '@poolware/api';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    device: Yup.mixed().required('Required'),
    customer: Yup.mixed().notRequired().nullable(),
    pool: Yup.mixed().notRequired().nullable(),
    label: Yup.string().notRequired().nullable().min(1).max(256),
});

export const NewPage: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { create } = useMutationEZCDeviceLink();
    const initialValues = {
        device: null,
        customer: null,
        pool: null,
        label: '',
    };
    const onSubmit = async (values: typeof initialValues) => {
        try {
            await create({
                label: values.label?.trim(),
                device: values.device?.id,
                owner: values.customer?.id,
                pool: values.pool?.id,
            });
            AppNavigator.navigateToOrigin('/', { relativeToModule: true });
        } catch (e) {
            toastError(e);
        }
    };

    const onCancel = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    return (
        <PageLayout sectionWidth={'screen-md'}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item
                        icon={'chevron left'}
                        content={'Cancel'}
                        onClick={() => AppNavigator.navigateToOrigin('/', { relativeToModule: true })}
                    />
                    <MenuBar.HeaderItem content={'Register Device'} />
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>{/*<MenuBar.Item content={'Item 1'}/>*/}</MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.BodySection>
                <FormikDefaultForm
                    header={'Register Device'}
                    initialValues={initialValues}
                    submitButton={{ content: 'Register' }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                >
                    <FormikEZCDevicePicker name={'device'} />
                    <FormikCustomerAndLocationInputFields />
                    <FormikInputField label={'Label'} name={'label'} />
                </FormikDefaultForm>

                {/*<EZCDevicePickerPanel onSubmit={() => {}} onCancel={() => {}} />*/}
            </PageLayout.BodySection>
        </PageLayout>
    );
};
