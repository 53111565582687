import * as React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { ButtonWithPopup, ButtonWithPopupProps } from '../buttons';

export interface FormikFormButtonsProps {
    handleCancel?: () => any;
    submitOnEnter?: boolean;
    activateOnDirty?: boolean;
    submitButton?: ButtonWithPopupProps;
    cancelButton?: ButtonWithPopupProps;
}

export const FormikFormButtons: React.FC<FormikFormButtonsProps> = (props) => {
    const { submitOnEnter = true, activateOnDirty = false, handleCancel, cancelButton, submitButton } = props;

    const formikContext = useFormikContext();
    const { handleSubmit, isSubmitting, dirty } = formikContext;

    if (!handleSubmit && !handleCancel) return null;

    return (
        <ButtonContainer>
            {handleCancel ? (
                <ButtonWithPopup
                    variant={'secondary'}
                    type="button"
                    disabled={isSubmitting}
                    onClick={handleCancel}
                    content={'Cancel'}
                    size={'lg'}
                    {...cancelButton}
                />
            ) : (
                <div />
            )}

            <ButtonWithPopup
                variant={'primary'}
                type={submitOnEnter ? 'submit' : 'button'}
                onClick={(e) => handleSubmit(e as any)}
                loading={isSubmitting}
                disabled={isSubmitting || (activateOnDirty && !dirty)}
                content={'Submit'}
                size={'lg'}
                {...submitButton}
            />
        </ButtonContainer>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;

    @media screen and (max-width: 480px) {
        flex-direction: column-reverse;
    }
`;
