import * as React from 'react';
import { NodeType } from '@poolware/api';
import { FormikSuggestInputProps, SuggestInput, withFormikSuggestField } from '@ez/components';
import { useQueryServiceJobGroupConnection } from '../queries/use-query-service-job-group-connection';
import { fromEdges } from '@poolware/api';
import { useMemo } from 'react';

export interface JobTodoTemplateSuggestInputProps extends FormikSuggestInputProps<NodeType.ServiceJobGroup> {
    filterByCompanyId?: string;
}

export const ServiceJobGroupSuggestInput: React.FC<JobTodoTemplateSuggestInputProps> = ({ onChange, ...rest }) => {
    const { connectionState, connectionData } = useQueryServiceJobGroupConnection();

    const flattenedGroups = useMemo(() => {
        return connectionData.reduce((acc, g) => {
            acc.push(g);
            const ch = fromEdges(g?.children);
            acc.push(...ch);
            return acc;
        }, []);
    }, [connectionData]);

    const onItemSelect = (item: NodeType.ServiceJobGroup) => {
        onChange(item);
    };

    const itemRenderer = (item: NodeType.ServiceJobGroup): string => {
        if (item?.parent) {
            return `${item?.parent?.title} > ${item?.title}`;
        }
        return item?.title;
    };

    const formatOptionLabel = (o, ctx) => {
        const isCtxValue = ctx.context === 'value';
        const item = o.item;
        if (item?.parent) {
            if (isCtxValue) {
                return (
                    <span>
                        {item?.parent?.title} {'>'} {item?.title}
                    </span>
                );
            } else {
                return <span className={'pl-6'}> - {item?.title}</span>;
            }
        }
        return <>{item?.title}</>;
    };

    return (
        <SuggestInput
            {...rest}
            formatOptionLabel={formatOptionLabel}
            items={flattenedGroups}
            loading={connectionState.loading}
            onItemSelect={onItemSelect}
            itemTextLabel={itemRenderer}
            getOptionValue={(item) => item?.id}
        />
    );
};

export const FormikServiceJobGroupSuggestInput = withFormikSuggestField(ServiceJobGroupSuggestInput);
