import { Modal, Panel, toastError, useModalCtrl } from '@ez/components';
import { fromEdges, NodeType, useMutationWarrantyClaimFileAttach } from '@poolware/api';
import * as React from 'react';
import { FileAttachmentUploader } from '@poolware/app-shell';
import { FileListTable } from './FileListTable';

export interface FileAttachmentPanelProps {
    claim: NodeType.WarrantyClaim;
    readOnly: boolean;
    locked?: string | boolean;
    mutatorWarrantyClaimFileAttach?: ReturnType<typeof useMutationWarrantyClaimFileAttach>;
}

export const FileAttachmentPanel: React.FC<FileAttachmentPanelProps> = ({
    claim,
    mutatorWarrantyClaimFileAttach,
    readOnly,
    locked,
}) => {
    const modalCtrl = useModalCtrl(false);

    const { create: createFileAttachment, delete: deleteAttachment } = mutatorWarrantyClaimFileAttach || {};

    const onFileAttachmentRemove = async (fileAttachment: NodeType.WarrantyClaimFileAttach) => {
        try {
            return deleteAttachment({ id: fileAttachment.id });
        } catch (e) {
            toastError(e);
        }
    };

    const onFileUploadComplete = async (fileId) => {
        await createFileAttachment({ claim: claim.id, file: fileId });
    };

    const fileAttachments = fromEdges(claim.files);

    const _readOnly = readOnly || !!locked || !mutatorWarrantyClaimFileAttach;

    return (
        <Panel>
            <Panel.Header
                basic={true}
                content={'File Attachments'}
                button={
                    !_readOnly && {
                        content: 'Add Files',
                        icon: 'plus',
                        color: 'blue',
                        onClick: modalCtrl.onOpen,
                        locked: locked,
                    }
                }
            />
            <Panel.Body>
                <FileListTable
                    fileAttachments={fileAttachments}
                    onFileAttachmentRemove={onFileAttachmentRemove}
                    readOnly={_readOnly}
                />
                {!_readOnly && (
                    <Modal {...modalCtrl}>
                        <Panel>
                            <Panel.Header
                                content={'Upload Files'}
                                button={{ content: 'Close', icon: 'cancel', onClick: modalCtrl.onClose }}
                            />
                            <Panel.Body>
                                <FileAttachmentUploader
                                    onFileUploadComplete={onFileUploadComplete}
                                    onDone={modalCtrl.onClose}
                                />
                            </Panel.Body>
                        </Panel>
                    </Modal>
                )}
            </Panel.Body>
        </Panel>
    );
};
