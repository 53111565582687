import gql from 'graphql-tag';

export const addressFragment = gql`
    fragment AddressFragment on Address {
        id
        poBoxNumber
        buildingName
        destinationName
        streetNumber
        streetName
        suburb
        state
        postCode
        city
        country
        entity {
            id
        }
        coordinates {
            lat
            lng
        }
    }
`;

export const poolFragment = gql`
    fragment PoolFragment on Pool {
        id
        name
        volume
        bottleNumber
        sanitiser {
            id
            name
        }
        site {
            id
            address {
                id
                ...AddressFragment
            }
        }
        type {
            id
            name
            description
        }
        note
        entity {
            id
            user {
                id
                firstName
                lastName
                customer {
                    id
                    crn
                }
            }
        }
    }
    ${addressFragment}
`;

export const siteFragment = gql`
    fragment SiteFragment on Site {
        id
        name
        address {
            id
            ...AddressFragment
        }
        accessKeyLocation
        entity {
            id
            franchise {
                id
                organisationType {
                    id
                }
            }
            user {
                id
                firstName
                lastName
            }
        }
        pools(first: 1000) {
            edges {
                node {
                    id
                    ...PoolFragment
                }
            }
        }
        createdAt
    }
    ${poolFragment}
`;

export const contactFragment = gql`
    fragment ContactFragment on Contact {
        id
        data
        label
        isPrimary
        type {
            id
            name
            iconPath
            validatorTag
        }
    }
`;

export const customerContactDetailsFragment = gql`
    fragment CustomerContactDetailsFragment on Customer {
        id
        crn
        contactName
        companyName
        user {
            id
            title
            firstName
            lastName
            entity {
                id
                franchise {
                    id
                    name
                    vend {
                        id
                    }
                }
                contacts {
                    edges {
                        node {
                            id
                            ...ContactFragment
                        }
                    }
                }
            }
        }
        primaryEmail {
            ...ContactFragment
        }
        primaryPhone {
            ...ContactFragment
        }
        primaryAddress {
            id
            ...AddressFragment
        }
        billAddress {
            id
            ...AddressFragment
        }
    }
    ${addressFragment}
    ${contactFragment}
`;

export const customerFragment = gql`
    fragment CustomerFragment on Customer {
        id
        ...CustomerContactDetailsFragment
        tags(first: 50) {
            edges {
                node {
                    id
                    name
                    description
                }
            }
        }
        billAddress {
            id
            ...AddressFragment
        }
        primaryAddress {
            id
            ...AddressFragment
        }
    }
    ${customerContactDetailsFragment}
`;

export const customerFragment_sites = gql`
    fragment CustomerFragment_sites on Customer {
        id
        __typename
        sites {
            edges {
                node {
                    id
                    ...SiteFragment
                }
            }
        }
    }
    ${siteFragment}
`;

export const staffFragment = gql`
    fragment StaffFragment on Staff {
        id
        registration {
            id
            email
            disabledAt
        }
        role {
            id
            name
        }
        user {
            id
            firstName
            lastName
            entity {
                id
                franchise {
                    id
                    name
                }
            }
        }
    }
`;

export const franchiseFragment = gql`
    fragment FranchiseFragment on Franchise {
        id
        name
        manager {
            id
            ...StaffFragment
        }
    }
    ${staffFragment}
`;

export const franchiseShopFragment = gql`
    fragment FranchiseShopFragment on FranchiseShop {
        id
        address {
            id
            ...AddressFragment
        }
        entity {
            id
            contacts(first: 1000) {
                edges {
                    node {
                        id
                        ...ContactFragment
                    }
                }
            }
        }
    }
    ${addressFragment}
    ${contactFragment}
`;

export const recurrenceFragment = gql`
    fragment RecurrenceFragment on Recurrence {
        pattern {
            dayOfMonth
            daysOfWeek
            interval
            month
            type
        }
        range {
            endDate
            interval
            numberOfOccurrences
            timeZone
            type
        }
    }
`;

export const appointmentItemFragment = gql`
    fragment AppointmentItemFragment on AppointmentItem {
        id
        startDate
        duration
        status
        isRecurring
        group {
            id
            title
            description
            defaultColor
            priority
            colorPalette
        }
        color
        appointment {
            id
            recurrence {
                ...RecurrenceFragment
            }
        }
        staff {
            ...StaffFragment
        }
        customer {
            ...CustomerContactDetailsFragment
        }
        address {
            ...AddressFragment
        }
        pool {
            id
            name
            volume
            bottleNumber
            address {
                ...AddressFragment
            }
        }
        deleted
        note
        workOrder {
            id
            title
            workOrderNumber
            stage {
                id
                title
                type
                status
            }
        }
        serviceJob {
            id
            title
            serviceJobNumber
            isRecurring
            stage {
                id
                title
                type
                status
            }
        }
    }
    ${customerContactDetailsFragment}
    ${staffFragment}
    ${recurrenceFragment}
`;

export const fragmentCustomerViaEntity = gql`
    fragment CustomerViaEntity on Entity {
        id
        user {
            id
            firstName
            lastName
            customer {
                id
            }
        }
    }
`;

export const fragmentPoolOwnershipHistory = gql`
    fragment PoolOwnershipHistoryFragment on PoolOwnershipHistory {
        id
        createdAt
        pool {
            id
            ...PoolFragment
        }
        transferredFrom {
            id
            ...CustomerViaEntity
        }
        transferredTo {
            id
            ...CustomerViaEntity
        }
        transferredBy {
            id
            user {
                id
                firstName
                lastName
            }
        }
    }
    ${poolFragment}
    ${fragmentCustomerViaEntity}
`;

export const productTraitFragment = gql`
    fragment ProductTraitFragment on ProductTrait {
        id
        product {
            id
            name
        }
        floatValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        integerValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        stringValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        selectionValues {
            id
            field {
                id
                tag
                name
            }
            value {
                id
                name
            }
        }
        declaration {
            id
            name
            tag
            parent {
                id
            }
        }
    }
`;
