import * as React from 'react';

import {
    MenuBarGroup,
    MenuBarGroupItemConfType,
    MenuBarGroupMODE,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    StickyMenuBar,
    toastError,
} from '@ez/components';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { Icon } from '@ez/components';
import { DeleteWorkOrderMenuButtonItem } from './DeleteWorkOrderMenuButtonItem';
import { NodeType } from '@poolware/api';
import { useBookAppointmentForWorkOrder } from './useBookAppointmentForWorkOrder';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useWorkOrderMutators } from '../../queries/mutators-work-order';
import { useAppLayoutMode } from '@poolware/app-shell';

export interface PageMenuBarProps {
    workOrder: NodeType.WorkOrder;
}

export const PageMenuBar: React.FC<PageMenuBarProps> = ({ workOrder }) => {
    const { isMobile } = useAppLayoutMode();
    const { AppNavigator, query } = useAppNavigator();

    const workOrderMutators = useWorkOrderMutators({
        awaitRefetchQueries: true,
    });
    const bookAppointmentFn = useBookAppointmentForWorkOrder({});

    const onAddFollowUpWorkOrder = () => {
        AppNavigator.navigate(`/wo/new`, {
            relativeToModule: true,
            modal: false,
            query: {
                sjId: workOrder.serviceJob?.id,
            },
            setOrigin: true,
        });
    };

    const goBack = () => {
        const viewingFromAppt = query['return-appt'];
        if (!viewingFromAppt) {
            AppNavigator.navigateToOrigin(`/sj/${workOrder.serviceJob.id}`, { relativeToModule: true });
        } else {
            AppNavigator.navigate(viewingFromAppt);
        }
    };

    const onPrint = () => {
        AppNavigator.navigate(`/print/service-jobs/wo/${workOrder.id}`, { setOrigin: true });
    };

    const onDeleteWorkOrder = async () => {
        try {
            await workOrderMutators.delete({ id: workOrder.id }, { awaitRefetchQueries: false });
            AppNavigator.replace(`/sj/${workOrder.serviceJob.id}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const menuA = [];
    const menuB = [];
    const menuC = [];

    const menuItemNewApp: MenuBarGroupItemConfType = !workOrder.appointmentItem && {
        onClick: () => bookAppointmentFn(workOrder),
        icon: 'calendar alternate outline',
        content: 'Book Appointment',
        color: 'green',
    };

    const menuItemNewWo = {
        onClick: onAddFollowUpWorkOrder,
        icon: ModuleIconNames.WorkOrder,
        title: 'New Work Order',
        color: ModuleColorNames.WorkOrder,
        popup: {
            position: 'right center',
            content: 'Create New Work Order and associate it with the parent Service Case',
        },
    };

    const menuItemPrint: MenuBarGroupItemConfType = {
        onClick: onPrint,
        icon: 'print',
        content: 'Print',
        color: 'blue',
    };

    const menuItemDelete = {
        render: <DeleteWorkOrderMenuButtonItem workOrder={workOrder} onDeleteWorkOrder={onDeleteWorkOrder} />,
    };

    if (!isMobile) {
        menuA.push(menuItemPrint);
        menuB.push(menuItemNewApp);
        menuB.push(menuItemNewWo);
        menuC.push(menuItemDelete);
    } else {
        menuC.push(menuItemPrint);
        menuC.push(menuItemNewApp);
        menuC.push(menuItemNewWo);
        menuC.push(menuItemDelete);
    }

    const backButtonTitle = AppNavigator.returnTo ? 'Back' : 'To Case';

    return (
        <StickyMenuBar>
            <MenuBarSection>
                <MenuBarItem onClick={goBack} icon={'chevron left'} title={backButtonTitle} />
                <MenuBarHeaderItem>
                    <Icon color={ModuleColorNames.WorkOrder} name={ModuleIconNames.WorkOrder} /> Work Order
                </MenuBarHeaderItem>
            </MenuBarSection>
            <MenuBarSection position={'right'}>
                <MenuBarGroup
                    menu={{
                        menuMode: MenuBarGroupMODE.EXPANDED,
                        color: 'blue',
                        icon: 'bar',
                        direction: 'left',
                    }}
                    items={menuA}
                />
                <MenuBarGroup
                    menu={{
                        color: 'blue',
                        icon: 'plus',
                        direction: 'left',
                    }}
                    items={menuB}
                />
                <MenuBarGroup
                    menu={{ color: 'red', icon: 'bars', direction: 'left', menuMode: MenuBarGroupMODE.DROPDOWN }}
                    items={menuC}
                />
            </MenuBarSection>
        </StickyMenuBar>
    );
};
