import * as React from 'react';
import { CalendarZoomFactor, useCalendarActions } from '../../redux';
import { IconButton } from '../Components/IconButton';
import { Button } from '@ez/components';

export interface CalendarTimeGutterHeaderProps {}

export const CalendarTimeGutterHeader: React.FC<CalendarTimeGutterHeaderProps> = ({}) => {
    const { CalendarState, CalendarAction } = useCalendarActions();
    const zooms: CalendarZoomFactor[] = [1, 2, 3, 4, 5, 6, 7, 8];

    const onZoomIn = () => {
        const nextValue = zooms.find((z) => z === CalendarState.zoomFactor + 1);
        if (nextValue) {
            CalendarAction.setZoomFactor(nextValue);
        }
    };
    const onZoomOut = () => {
        const nextValue = zooms.find((z) => z === CalendarState.zoomFactor - 1);
        if (nextValue) {
            CalendarAction.setZoomFactor(nextValue);
        }
    };

    return (
        <div className={'h-full flex flex-col pt-1 items-center justify-center'}>
            <Button variant={'tertiary'} size={'sm'} color={'tertiary'} onClick={onZoomIn} icon={'zoom in'} />
            <Button variant={'tertiary'} size={'sm'} color={'tertiary'} onClick={onZoomOut} icon={'zoom out'} />
        </div>
    );
};
