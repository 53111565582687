import * as React from 'react';
import { useMemo } from 'react';
import { useWindowSize } from '@ez/tools';
import tw, { css, styled, theme } from 'twin.macro';
import { SlideInDock } from '@ez/components';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';

export const PAGE_BOTTOM_OFFSET_GRID = 76;
export const PAGE_BOTTOM_OFFSET_CAL = PAGE_BOTTOM_OFFSET_GRID + 34;
const TEMPLATE_M = 'minmax(8em, 1fr)';
const TEMPLATE_L_M = '240px minmax(8em, 1fr)';
const TEMPLATE_L_M_R_WIDE = '230px minmax(8em, 1fr) 36em';
const TEMPLATE_L_M_R_MOB = '230px minmax(8em, 1fr) 30em';
const TEMPLATE_M_R_WIDE = 'minmax(8em, 1fr) 36em';
const TEMPLATE_M_R_MOB = 'minmax(8em, 1fr) 28em';

export const CalendarContainerGrid = styled.div`
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    grid-template-rows: auto;
    @media print {
        display: block;
    }
`;
export const SidebarContainer = styled.div<{ darker?: boolean; maxHeight?: number }>(({ darker, maxHeight }) => [
    tw`shadow rounded border-0 z-10 bg-tertiary`,
    css`
        max-height: ${maxHeight}px;
    `,
    // darker && tw`bg-gradient-to-b from-indigo-50 via-blue-50 to-gray-100`,
]);

export const CalendarPageLayoutManager: React.FC<{
    leftSidebar: React.ReactNode;
    rightSidebar: React.ReactNode;
    main: React.ReactNode;
}> = ({ leftSidebar, main, rightSidebar }) => {
    const { innerHeight } = useWindowSize();
    const CalLayoutState = useCalendarLayoutState();
    const height = innerHeight - PAGE_BOTTOM_OFFSET_GRID;

    const onCloseFilters = () => CalLayoutState.setFilterBarState(false);

    const showLeft = CalLayoutState.isFilterBarOpen && !!leftSidebar;
    const showRight = CalLayoutState.isDockOpen && !!rightSidebar;

    let gridStyle = useMemo(() => {
        const isWideScreen = CalLayoutState.appLayoutMode.gteDesktopMD;
        let gridTemplateColumns = TEMPLATE_M;
        if (CalLayoutState.layoutColumns === 3) {
            if (showLeft && showRight) {
                gridTemplateColumns = isWideScreen ? TEMPLATE_L_M_R_WIDE : TEMPLATE_L_M_R_MOB;
            } else if (showLeft) {
                gridTemplateColumns = TEMPLATE_L_M;
            } else if (showRight) {
                gridTemplateColumns = isWideScreen ? TEMPLATE_M_R_WIDE : TEMPLATE_M_R_MOB;
            } else {
                gridTemplateColumns = TEMPLATE_M;
            }

            return { gridTemplateColumns, height: height };
        } else if (CalLayoutState.layoutColumns === 2) {
            if (showRight) {
                gridTemplateColumns = TEMPLATE_M_R_MOB;
            } else {
                gridTemplateColumns = TEMPLATE_M;
            }

            return { gridTemplateColumns, height: height };
        } else {
            return { gridTemplateColumns, height: height };
        }
    }, [CalLayoutState.layoutColumns, CalLayoutState.appLayoutMode, showLeft, showRight, height, innerHeight]);

    if (CalLayoutState.layoutColumns === 3) {
        return (
            <CalendarContainerGrid style={gridStyle}>
                {showLeft && <>{leftSidebar}</>}
                {main}
                {showRight && (
                    <SidebarContainer darker={true} maxHeight={height} className={'no-print'}>
                        {rightSidebar}
                    </SidebarContainer>
                )}
            </CalendarContainerGrid>
        );
    } else if (CalLayoutState.layoutColumns === 2) {
        return (
            <>
                <SlideInDock onClose={onCloseFilters} open={showLeft} direction={'left'} animateSlide={false}>
                    {leftSidebar}
                </SlideInDock>
                <CalendarContainerGrid style={gridStyle}>
                    {main}
                    {CalLayoutState.isDockOpen && (
                        <SidebarContainer darker={true} maxHeight={height} className={'no-print'}>
                            {rightSidebar}
                        </SidebarContainer>
                    )}
                </CalendarContainerGrid>
            </>
        );
    } else {
        return (
            <>
                <SlideInDock onClose={onCloseFilters} open={showLeft} direction={'left'} animateSlide={false}>
                    {leftSidebar}
                </SlideInDock>
                <CalendarContainerGrid style={gridStyle}>{main}</CalendarContainerGrid>
            </>
        );
    }
};
