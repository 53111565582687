import * as React from 'react';
import { useMemo } from 'react';
import { Icon } from '@ez/components';
import { ConnectionTableDef, DefaultConnectionTable, Display, TruncatedDiv } from '@ez/components';
import { IconServiceJobStage, ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { useConnectedQueryWorkOrdersConnection } from './useConnectedQueryWorkOrdersConnection';
import styled from 'styled-components';

// const RenderServiceJobStatus = (serviceJob: NodeType.ServiceJob | NodeType.WorkOrder) => {
//     return <Icon {...mapServiceJobStatusToIconProps(serviceJob.status)} />;
// };

const RenderAddress = ({ address }) => {
    return <Display.Address value={address} />;
};

export interface PageTableProps {
    onView: any;
}

const CustomerCellContainer = styled(TruncatedDiv)`
    display: flex;
    flex-direction: column;
`;

const creatTableDef = (): ConnectionTableDef<NodeType.WorkOrder> => [
    {
        header: 'ID',
        width: 1,
        cellProps: { verticalAlign: 'top' },
        cell: (item) => <>{item.workOrderNumber}</>,
    },
    {
        header: 'Work Order',
        width: 5,
        cellProps: { verticalAlign: 'top' },
        cell: (item) => {
            const { serviceJob } = item;
            const color = undefined; // item.status === 0 ? '#2185d0' : '#444444';
            return (
                <div>
                    <div style={{ fontSize: '1.05em', color: color }}>
                        <IconServiceJobStage stage={item?.stage} />
                        <Icon name={ModuleIconNames.WorkOrder} />
                        {_.truncate(item.title, { length: 60 })}
                    </div>
                </div>
            );
        },
    },

    {
        header: 'Customer',
        width: 5,
        cellProps: { verticalAlign: 'top' },
        cell: (value) => {
            const customer = value?.customer;
            const address = value?.address;
            const pool = value?.pool;
            // const color = value.status === NodeType.WorkOrderStatusEnum.Open ? '#2185d0' : '#444444';

            return (
                <CustomerCellContainer maxWidth={300}>
                    {customer && (
                        <div>
                            <Icon name={'user'} />
                            <code style={{ color: '#444444' }}>
                                <b>{customer.crn}</b>
                            </code>{' '}
                            - <Display.Entity value={customer} />
                        </div>
                    )}
                    {customer?.companyName && (
                        <div>
                            <Icon name={'building'} />
                            {customer.companyName}
                        </div>
                    )}
                    {address && (
                        <div>
                            <Icon name={'map marker alternate'} />
                            <RenderAddress address={address} />
                        </div>
                    )}
                    {pool && (
                        <div>
                            <Icon name={'life ring'} />
                            Pool - {pool.volume}L {pool.type?.name && `, ${pool.type?.name}`}
                        </div>
                    )}
                </CustomerCellContainer>
            );
        },
    },
    {
        header: 'Assigned To',
        cellProps: { verticalAlign: 'top' },
        cell: (value) => {
            if (!value.assignedTo) {
                return null;
            }

            return (
                <TruncatedDiv maxWidth={200}>
                    <Display.Entity value={value.assignedTo} />
                </TruncatedDiv>
            );
        },
    },
    {
        header: 'Appointment',
        width: 2,
        cellProps: { verticalAlign: 'top' },
        cell: (item) => {
            if (!item.appointmentItem) return null;
            return (
                <>
                    <Icon name={'calendar alternate outline'} />
                    <Display.Date value={item.appointmentItem.startDate} format={'lll'} />
                </>
            );
        },
    },
    {
        header: 'Parent Case',
        width: 2,
        cellProps: { verticalAlign: 'top' },
        cell: (item) => {
            const { serviceJob } = item;

            return (
                <div style={{ marginLeft: '2px', fontSize: '0.8em' }}>
                    {serviceJob?.isRecurring ? (
                        <Icon name={ModuleIconNames.RecurringService} />
                    ) : (
                        <Icon name={ModuleIconNames.ServiceCall} />
                    )}
                    <code>{serviceJob?.serviceJobNumber}</code> - {_.truncate(serviceJob?.title, { length: 60 })}
                </div>
            );
        },
    },
];

export const WorkOrdersTable: React.FC<PageTableProps> = ({ onView }) => {
    const { connectionState, connectionData } = useConnectedQueryWorkOrdersConnection();

    const tableDef = useMemo(creatTableDef, []);

    return (
        <>
            <DefaultConnectionTable
                onRowClick={onView}
                tableProps={{
                    color: ModuleColorNames.WorkOrder,
                }}
                // tableRowDef={tableRowProps}
                tableDef={tableDef}
                tableCellProps={{
                    verticalAlign: 'top',
                    style: {
                        borderTop: '1px solid hsl(206, 50%, 85%)',
                    },
                }}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </>
    );
};
