import * as React from 'react';
import { MenuBar, Optionable, SegmentedSelectButtons, StickyMenuBar, VStack } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { useQueryDevices } from './use-query-ezc-device-link-connection';
import { useViewer } from '@poolware/app-shell';
import * as URLBuilder from '../../../routes/url-builder';
import { usePersistedState } from '@ez/tools';
import { LayoutTable } from './LayoutTable';
import { LayoutCards } from './LayoutCards';
import { LayoutMap } from './LayoutMap';
import { resolveCoordinates } from './LayoutMap/data';

export interface PageProps {}

type ViewMode = 'cards' | 'table' | 'map';

export const ListPage: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    const [viewMode, setViewMode] = usePersistedState<ViewMode>('pw.devices.list-mode', 'table');

    const { connectionData, connectionState, refetchQuery, isLoadingShadow } = useQueryDevices({
        // pageSize: 2,
        // search: {
        //     pool: {
        //         id: "8TAkJyw4X3P8IQAA"
        //     }
        // }
    });

    const onView = (deviceLink: NodeType.EZCDeviceLink) => {
        AppNavigator.navigateRelative(`/${deviceLink.id}`);
    };

    const options: Optionable<ViewMode>[] = [
        {
            value: 'table',
            icon: 'list layout',
            text: '',
        },
        {
            value: 'cards',
            icon: 'grid layout',
            text: '',
        },
        {
            value: 'map',
            icon: 'map',
            text: '',
        },
    ];

    const handleViewModeChange = (value: ViewMode) => {
        setViewMode(value);
    };

    const data = connectionData.map((device) => {
        return {
            ...device,
            coordinates: resolveCoordinates(device),
        };
    });

    const layoutViewProps = {
        onView,
        connectionData: data,
        connectionState,
        isLoadingShadow,
    };

    return (
        <VStack space={0.5}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem>Managed Devices</MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item
                        icon={'plus'}
                        content={'Add Device'}
                        color={'green'}
                        locked={!modulesAccess?.EZConnectIntegration?.accessDeviceRegistration}
                        onClick={() => AppNavigator.navigate(URLBuilder.Devices().new)}
                    />
                    <MenuBar.Item asChild={true}>
                        <SegmentedSelectButtons
                            fluid={false}
                            color={'tertiary'}
                            size={'sm'}
                            onChange={(o) => handleViewModeChange(o.value)}
                            value={viewMode}
                            options={options}
                        />
                    </MenuBar.Item>
                </MenuBar.Section>
            </StickyMenuBar>

            {viewMode === 'table' && <LayoutTable {...layoutViewProps} />}
            {viewMode === 'cards' && <LayoutCards {...layoutViewProps} />}
            {viewMode === 'map' && <LayoutMap {...layoutViewProps} />}
        </VStack>
    );
};
