//
// DO NOT EDIT!!!
// THIS IS AN AUTOMATICALLY GENERATED FILE.
// Date:  Fri Sep 06 2024 13:57:11 GMT+1000 (Australian Eastern Standard Time)
//

import { useMutation as useMutationApollo, MutationHookOptions, MutationTuple } from '@apollo/react-hooks';
import { OperationVariables } from '@apollo/react-common';

import { DocumentNode } from 'graphql';
import { NodeType } from './graphql-types';
import { getMutationQueryByName } from './mutations-queries-codegen';
const isDevelopment = process.env.NODE_ENV === 'development';
const debug = isDevelopment ? console.log : () => {};

export function useMutation<TData = any, TVariables = OperationVariables>(
    mutation: DocumentNode,
    options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
    const [mutationFn, res] = useMutationApollo(mutation, options);

    const loggableMutation = async (options, logName?: string) => {
        debug(`Mutation: ${logName}`, options);
        const res = await mutationFn(options);
        debug('Mutation result:', res);
        return res;
    };

    return [loggableMutation, res];
}

// ************** AbstractDocument ***************** //

// AbstractDocument: Mutator

export type TDataAbstractDocument = { AbstractDocument: NodeType.AbstractDocumentMutationPayload };
export type TVariablesAbstractDocument = { input: NodeType.AbstractDocumentMutationInput };
export type MutationHookOptionsAbstractDocument = MutationHookOptions<
    TDataAbstractDocument,
    TVariablesAbstractDocument
>;

export const useMutationAbstractDocument = (options?: MutationHookOptionsAbstractDocument) => {
    const QL = options?.mutation || getMutationQueryByName('AbstractDocument');
    const [mutate, mutationResult] = useMutation<TDataAbstractDocument, TVariablesAbstractDocument>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateAbstractDocumentMutationInput[] | NodeType.CreateAbstractDocumentMutationInput,
        options?: MutationHookOptionsAbstractDocument
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'AbstractDocument.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteAbstractDocumentMutationInput[] | NodeType.DeleteAbstractDocumentMutationInput,
        options?: MutationHookOptionsAbstractDocument
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'AbstractDocument.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateAbstractDocumentMutationInput[] | NodeType.UpdateAbstractDocumentMutationInput,
        options?: MutationHookOptionsAbstractDocument
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'AbstractDocument.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Address ***************** //

// Address: Mutator

export type TDataAddress = { Address: NodeType.AddressMutationPayload };
export type TVariablesAddress = { input: NodeType.AddressMutationInput };
export type MutationHookOptionsAddress = MutationHookOptions<TDataAddress, TVariablesAddress>;

export const useMutationAddress = (options?: MutationHookOptionsAddress) => {
    const QL = options?.mutation || getMutationQueryByName('Address');
    const [mutate, mutationResult] = useMutation<TDataAddress, TVariablesAddress>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateAddressMutationInput[] | NodeType.CreateAddressMutationInput,
        options?: MutationHookOptionsAddress
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Address.create'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateAddressMutationInput[] | NodeType.UpdateAddressMutationInput,
        options?: MutationHookOptionsAddress
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Address.update'
        );
    };

    return {
        create: mutateCreate,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Announcement ***************** //

// Announcement: Mutator

export type TDataAnnouncement = { Announcement: NodeType.AnnouncementMutationPayload };
export type TVariablesAnnouncement = { input: NodeType.AnnouncementMutationInput };
export type MutationHookOptionsAnnouncement = MutationHookOptions<TDataAnnouncement, TVariablesAnnouncement>;

export const useMutationAnnouncement = (options?: MutationHookOptionsAnnouncement) => {
    const QL = options?.mutation || getMutationQueryByName('Announcement');
    const [mutate, mutationResult] = useMutation<TDataAnnouncement, TVariablesAnnouncement>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateAnnouncementMutationInput[] | NodeType.CreateAnnouncementMutationInput,
        options?: MutationHookOptionsAnnouncement
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Announcement.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteAnnouncementMutationInput[] | NodeType.DeleteAnnouncementMutationInput,
        options?: MutationHookOptionsAnnouncement
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Announcement.delete'
        );
    };
    const mutatePublish = (
        input: NodeType.AnnouncementPublishMutationInput[] | NodeType.AnnouncementPublishMutationInput,
        options?: MutationHookOptionsAnnouncement
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        publish: input as any,
                    },
                },
            },
            // @ts-ignore
            'Announcement.publish'
        );
    };
    const mutateUnpublish = (
        input: NodeType.AnnouncementUnpublishMutationInput[] | NodeType.AnnouncementUnpublishMutationInput,
        options?: MutationHookOptionsAnnouncement
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        unpublish: input as any,
                    },
                },
            },
            // @ts-ignore
            'Announcement.unpublish'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateAnnouncementMutationInput[] | NodeType.UpdateAnnouncementMutationInput,
        options?: MutationHookOptionsAnnouncement
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Announcement.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        publish: mutatePublish,
        unpublish: mutateUnpublish,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Appointment ***************** //

// Appointment: Mutator

export type TDataAppointment = { Appointment: NodeType.AppointmentMutationPayload };
export type TVariablesAppointment = { input: NodeType.AppointmentMutationInput };
export type MutationHookOptionsAppointment = MutationHookOptions<TDataAppointment, TVariablesAppointment>;

export const useMutationAppointment = (options?: MutationHookOptionsAppointment) => {
    const QL = options?.mutation || getMutationQueryByName('Appointment');
    const [mutate, mutationResult] = useMutation<TDataAppointment, TVariablesAppointment>(QL, options);

    const mutateChangeBlockEndDate = (
        input:
            | NodeType.AppointmentChangeBlockEndDateMutationInput[]
            | NodeType.AppointmentChangeBlockEndDateMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        changeBlockEndDate: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.changeBlockEndDate'
        );
    };
    const mutateChangeEndDate = (
        input: NodeType.AppointmentChangeEndDateMutationInput[] | NodeType.AppointmentChangeEndDateMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        changeEndDate: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.changeEndDate'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateAppointmentMutationInput[] | NodeType.CreateAppointmentMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteAppointmentMutationInput[] | NodeType.DeleteAppointmentMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.delete'
        );
    };
    const mutateDeleteBlock = (
        input: NodeType.AppointmentDeleteBlockMutationInput[] | NodeType.AppointmentDeleteBlockMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        deleteBlock: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.deleteBlock'
        );
    };
    const mutateSplit = (
        input: NodeType.AppointmentSplitMutationInput[] | NodeType.AppointmentSplitMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        split: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.split'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateAppointmentMutationInput[] | NodeType.UpdateAppointmentMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.update'
        );
    };
    const mutateUpdateTimeZone = (
        input: NodeType.AppointmentUpdateTimeZoneMutationInput[] | NodeType.AppointmentUpdateTimeZoneMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        updateTimeZone: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.updateTimeZone'
        );
    };
    const mutateUpdateTimeZoneFrom = (
        input:
            | NodeType.AppointmentUpdateTimeZoneFromMutationInput[]
            | NodeType.AppointmentUpdateTimeZoneFromMutationInput,
        options?: MutationHookOptionsAppointment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        updateTimeZoneFrom: input as any,
                    },
                },
            },
            // @ts-ignore
            'Appointment.updateTimeZoneFrom'
        );
    };

    return {
        changeBlockEndDate: mutateChangeBlockEndDate,
        changeEndDate: mutateChangeEndDate,
        create: mutateCreate,
        delete: mutateDelete,
        deleteBlock: mutateDeleteBlock,
        split: mutateSplit,
        update: mutateUpdate,
        updateTimeZone: mutateUpdateTimeZone,
        updateTimeZoneFrom: mutateUpdateTimeZoneFrom,
        mutate,
        mutationResult,
    };
};

// ************** AppointmentGroup ***************** //

// AppointmentGroup: Mutator

export type TDataAppointmentGroup = { AppointmentGroup: NodeType.AppointmentGroupMutationPayload };
export type TVariablesAppointmentGroup = { input: NodeType.AppointmentGroupMutationInput };
export type MutationHookOptionsAppointmentGroup = MutationHookOptions<
    TDataAppointmentGroup,
    TVariablesAppointmentGroup
>;

export const useMutationAppointmentGroup = (options?: MutationHookOptionsAppointmentGroup) => {
    const QL = options?.mutation || getMutationQueryByName('AppointmentGroup');
    const [mutate, mutationResult] = useMutation<TDataAppointmentGroup, TVariablesAppointmentGroup>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateAppointmentGroupMutationInput[] | NodeType.CreateAppointmentGroupMutationInput,
        options?: MutationHookOptionsAppointmentGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentGroup.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteAppointmentGroupMutationInput[] | NodeType.DeleteAppointmentGroupMutationInput,
        options?: MutationHookOptionsAppointmentGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentGroup.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateAppointmentGroupMutationInput[] | NodeType.UpdateAppointmentGroupMutationInput,
        options?: MutationHookOptionsAppointmentGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentGroup.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** AppointmentInstance ***************** //

// AppointmentInstance: Mutator

export type TDataAppointmentInstance = { AppointmentInstance: NodeType.AppointmentInstanceMutationPayload };
export type TVariablesAppointmentInstance = { input: NodeType.AppointmentInstanceMutationInput };
export type MutationHookOptionsAppointmentInstance = MutationHookOptions<
    TDataAppointmentInstance,
    TVariablesAppointmentInstance
>;

export const useMutationAppointmentInstance = (options?: MutationHookOptionsAppointmentInstance) => {
    const QL = options?.mutation || getMutationQueryByName('AppointmentInstance');
    const [mutate, mutationResult] = useMutation<TDataAppointmentInstance, TVariablesAppointmentInstance>(QL, options);

    const mutateAddSale = (
        input: NodeType.AppointmentInstanceAddSaleMutationInput[] | NodeType.AppointmentInstanceAddSaleMutationInput,
        options?: MutationHookOptionsAppointmentInstance
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        addSale: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentInstance.addSale'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteAppointmentInstanceMutationInput[] | NodeType.DeleteAppointmentInstanceMutationInput,
        options?: MutationHookOptionsAppointmentInstance
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentInstance.delete'
        );
    };
    const mutateStartWorkOrder = (
        input:
            | NodeType.AppointmentInstanceStartWorkOrderMutationInput[]
            | NodeType.AppointmentInstanceStartWorkOrderMutationInput,
        options?: MutationHookOptionsAppointmentInstance
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        startWorkOrder: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentInstance.startWorkOrder'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateAppointmentInstanceMutationInput[] | NodeType.UpdateAppointmentInstanceMutationInput,
        options?: MutationHookOptionsAppointmentInstance
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentInstance.update'
        );
    };

    return {
        addSale: mutateAddSale,
        delete: mutateDelete,
        startWorkOrder: mutateStartWorkOrder,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** AppointmentItem ***************** //

// AppointmentItem: Mutator

export type TDataAppointmentItem = { AppointmentItem: NodeType.AppointmentItemMutationPayload };
export type TVariablesAppointmentItem = { input: NodeType.AppointmentItemMutationInput };
export type MutationHookOptionsAppointmentItem = MutationHookOptions<TDataAppointmentItem, TVariablesAppointmentItem>;

export const useMutationAppointmentItem = (options?: MutationHookOptionsAppointmentItem) => {
    const QL = options?.mutation || getMutationQueryByName('AppointmentItem');
    const [mutate, mutationResult] = useMutation<TDataAppointmentItem, TVariablesAppointmentItem>(QL, options);

    const mutateAddSale = (
        input: NodeType.AppointmentItemAddSaleMutationInput[] | NodeType.AppointmentItemAddSaleMutationInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        addSale: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentItem.addSale'
        );
    };
    const mutateChangeRecurrence = (
        input:
            | NodeType.AppointmentItemChangeRecurrenceMutationInput[]
            | NodeType.AppointmentItemChangeRecurrenceMutationInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        changeRecurrence: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentItem.changeRecurrence'
        );
    };
    const mutateChangeRecurrenceFrom = (
        input:
            | NodeType.AppointmentItemChangeRecurrenceFromMutationInput[]
            | NodeType.AppointmentItemChangeRecurrenceFromMutationInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        changeRecurrenceFrom: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentItem.changeRecurrenceFrom'
        );
    };
    const mutateDelete = (
        input: NodeType.AppointmentItemDeleteMutationInput[] | NodeType.AppointmentItemDeleteMutationInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentItem.delete'
        );
    };
    const mutateStartWorkOrder = (
        input:
            | NodeType.AppointmentItemStartWorkOrderMutationInput[]
            | NodeType.AppointmentItemStartWorkOrderMutationInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        startWorkOrder: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentItem.startWorkOrder'
        );
    };
    const mutateUpdate = (
        input: NodeType.AppointmentItemUpdateMutationInput[] | NodeType.AppointmentItemUpdateMutationInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentItem.update'
        );
    };
    const mutateUpdateFuture = (
        input: NodeType.AppointmentItemUpdateFutureMutationInput[] | NodeType.AppointmentItemUpdateFutureMutationInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        updateFuture: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentItem.updateFuture'
        );
    };
    const mutateUpdateSingle = (
        input: NodeType.AppointmentItemUpdateSingleMutationInput[] | NodeType.AppointmentItemUpdateSingleMutationInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        updateSingle: input as any,
                    },
                },
            },
            // @ts-ignore
            'AppointmentItem.updateSingle'
        );
    };

    return {
        addSale: mutateAddSale,
        changeRecurrence: mutateChangeRecurrence,
        changeRecurrenceFrom: mutateChangeRecurrenceFrom,
        delete: mutateDelete,
        startWorkOrder: mutateStartWorkOrder,
        update: mutateUpdate,
        updateFuture: mutateUpdateFuture,
        updateSingle: mutateUpdateSingle,
        mutate,
        mutationResult,
    };
};

// ************** Brand ***************** //

// Brand: Mutator

export type TDataBrand = { Brand: NodeType.BrandMutationPayload };
export type TVariablesBrand = { input: NodeType.BrandMutationInput };
export type MutationHookOptionsBrand = MutationHookOptions<TDataBrand, TVariablesBrand>;

export const useMutationBrand = (options?: MutationHookOptionsBrand) => {
    const QL = options?.mutation || getMutationQueryByName('Brand');
    const [mutate, mutationResult] = useMutation<TDataBrand, TVariablesBrand>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateBrandMutationInput[] | NodeType.CreateBrandMutationInput,
        options?: MutationHookOptionsBrand
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Brand.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteBrandMutationInput[] | NodeType.DeleteBrandMutationInput,
        options?: MutationHookOptionsBrand
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Brand.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateBrandMutationInput[] | NodeType.UpdateBrandMutationInput,
        options?: MutationHookOptionsBrand
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Brand.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ConsumableProduct ***************** //

// ConsumableProduct: Mutator

export type TDataConsumableProduct = { ConsumableProduct: NodeType.ConsumableProductMutationPayload };
export type TVariablesConsumableProduct = { input: NodeType.ConsumableProductMutationInput };
export type MutationHookOptionsConsumableProduct = MutationHookOptions<
    TDataConsumableProduct,
    TVariablesConsumableProduct
>;

export const useMutationConsumableProduct = (options?: MutationHookOptionsConsumableProduct) => {
    const QL = options?.mutation || getMutationQueryByName('ConsumableProduct');
    const [mutate, mutationResult] = useMutation<TDataConsumableProduct, TVariablesConsumableProduct>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateConsumableProductMutationInput[] | NodeType.CreateConsumableProductMutationInput,
        options?: MutationHookOptionsConsumableProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ConsumableProduct.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteConsumableProductMutationInput[] | NodeType.DeleteConsumableProductMutationInput,
        options?: MutationHookOptionsConsumableProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ConsumableProduct.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateConsumableProductMutationInput[] | NodeType.UpdateConsumableProductMutationInput,
        options?: MutationHookOptionsConsumableProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ConsumableProduct.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Contact ***************** //

// Contact: Mutator

export type TDataContact = { Contact: NodeType.ContactMutationPayload };
export type TVariablesContact = { input: NodeType.ContactMutationInput };
export type MutationHookOptionsContact = MutationHookOptions<TDataContact, TVariablesContact>;

export const useMutationContact = (options?: MutationHookOptionsContact) => {
    const QL = options?.mutation || getMutationQueryByName('Contact');
    const [mutate, mutationResult] = useMutation<TDataContact, TVariablesContact>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateContactMutationInput[] | NodeType.CreateContactMutationInput,
        options?: MutationHookOptionsContact
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Contact.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteContactMutationInput[] | NodeType.DeleteContactMutationInput,
        options?: MutationHookOptionsContact
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Contact.delete'
        );
    };
    const mutateMakePrimary = (
        input: NodeType.ContactMakePrimaryMutationInput[] | NodeType.ContactMakePrimaryMutationInput,
        options?: MutationHookOptionsContact
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        makePrimary: input as any,
                    },
                },
            },
            // @ts-ignore
            'Contact.makePrimary'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateContactMutationInput[] | NodeType.UpdateContactMutationInput,
        options?: MutationHookOptionsContact
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Contact.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        makePrimary: mutateMakePrimary,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ContactType ***************** //

// ContactType: Mutator

export type TDataContactType = { ContactType: NodeType.ContactTypeMutationPayload };
export type TVariablesContactType = { input: NodeType.ContactTypeMutationInput };
export type MutationHookOptionsContactType = MutationHookOptions<TDataContactType, TVariablesContactType>;

export const useMutationContactType = (options?: MutationHookOptionsContactType) => {
    const QL = options?.mutation || getMutationQueryByName('ContactType');
    const [mutate, mutationResult] = useMutation<TDataContactType, TVariablesContactType>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateContactTypeMutationInput[] | NodeType.CreateContactTypeMutationInput,
        options?: MutationHookOptionsContactType
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ContactType.create'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateContactTypeMutationInput[] | NodeType.UpdateContactTypeMutationInput,
        options?: MutationHookOptionsContactType
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ContactType.update'
        );
    };

    return {
        create: mutateCreate,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** CustomContract ***************** //

// CustomContract: Mutator

export type TDataCustomContract = { CustomContract: NodeType.CustomContractMutationPayload };
export type TVariablesCustomContract = { input: NodeType.CustomContractMutationInput };
export type MutationHookOptionsCustomContract = MutationHookOptions<TDataCustomContract, TVariablesCustomContract>;

export const useMutationCustomContract = (options?: MutationHookOptionsCustomContract) => {
    const QL = options?.mutation || getMutationQueryByName('CustomContract');
    const [mutate, mutationResult] = useMutation<TDataCustomContract, TVariablesCustomContract>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateCustomContractMutationInput[] | NodeType.CreateCustomContractMutationInput,
        options?: MutationHookOptionsCustomContract
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomContract.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteCustomContractMutationInput[] | NodeType.DeleteCustomContractMutationInput,
        options?: MutationHookOptionsCustomContract
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomContract.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateCustomContractMutationInput[] | NodeType.UpdateCustomContractMutationInput,
        options?: MutationHookOptionsCustomContract
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomContract.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Customer ***************** //

// Customer: Mutator

export type TDataCustomer = { Customer: NodeType.CustomerMutationPayload };
export type TVariablesCustomer = { input: NodeType.CustomerMutationInput };
export type MutationHookOptionsCustomer = MutationHookOptions<TDataCustomer, TVariablesCustomer>;

export const useMutationCustomer = (options?: MutationHookOptionsCustomer) => {
    const QL = options?.mutation || getMutationQueryByName('Customer');
    const [mutate, mutationResult] = useMutation<TDataCustomer, TVariablesCustomer>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateCustomerMutationInput[] | NodeType.CreateCustomerMutationInput,
        options?: MutationHookOptionsCustomer
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Customer.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteCustomerMutationInput[] | NodeType.DeleteCustomerMutationInput,
        options?: MutationHookOptionsCustomer
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Customer.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateCustomerMutationInput[] | NodeType.UpdateCustomerMutationInput,
        options?: MutationHookOptionsCustomer
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Customer.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** CustomerNote ***************** //

// CustomerNote: Mutator

export type TDataCustomerNote = { CustomerNote: NodeType.CustomerNoteMutationPayload };
export type TVariablesCustomerNote = { input: NodeType.CustomerNoteMutationInput };
export type MutationHookOptionsCustomerNote = MutationHookOptions<TDataCustomerNote, TVariablesCustomerNote>;

export const useMutationCustomerNote = (options?: MutationHookOptionsCustomerNote) => {
    const QL = options?.mutation || getMutationQueryByName('CustomerNote');
    const [mutate, mutationResult] = useMutation<TDataCustomerNote, TVariablesCustomerNote>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateCustomerNoteMutationInput[] | NodeType.CreateCustomerNoteMutationInput,
        options?: MutationHookOptionsCustomerNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomerNote.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteCustomerNoteMutationInput[] | NodeType.DeleteCustomerNoteMutationInput,
        options?: MutationHookOptionsCustomerNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomerNote.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateCustomerNoteMutationInput[] | NodeType.UpdateCustomerNoteMutationInput,
        options?: MutationHookOptionsCustomerNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomerNote.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** CustomerTag ***************** //

// CustomerTag: Mutator

export type TDataCustomerTag = { CustomerTag: NodeType.CustomerTagMutationPayload };
export type TVariablesCustomerTag = { input: NodeType.CustomerTagMutationInput };
export type MutationHookOptionsCustomerTag = MutationHookOptions<TDataCustomerTag, TVariablesCustomerTag>;

export const useMutationCustomerTag = (options?: MutationHookOptionsCustomerTag) => {
    const QL = options?.mutation || getMutationQueryByName('CustomerTag');
    const [mutate, mutationResult] = useMutation<TDataCustomerTag, TVariablesCustomerTag>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateCustomerTagMutationInput[] | NodeType.CreateCustomerTagMutationInput,
        options?: MutationHookOptionsCustomerTag
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomerTag.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteCustomerTagMutationInput[] | NodeType.DeleteCustomerTagMutationInput,
        options?: MutationHookOptionsCustomerTag
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomerTag.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateCustomerTagMutationInput[] | NodeType.UpdateCustomerTagMutationInput,
        options?: MutationHookOptionsCustomerTag
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'CustomerTag.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** EZCDeviceLink ***************** //

// EZCDeviceLink: Mutator

export type TDataEZCDeviceLink = { EZCDeviceLink: NodeType.EZCDeviceLinkMutationPayload };
export type TVariablesEZCDeviceLink = { input: NodeType.EZCDeviceLinkMutationInput };
export type MutationHookOptionsEZCDeviceLink = MutationHookOptions<TDataEZCDeviceLink, TVariablesEZCDeviceLink>;

export const useMutationEZCDeviceLink = (options?: MutationHookOptionsEZCDeviceLink) => {
    const QL = options?.mutation || getMutationQueryByName('EZCDeviceLink');
    const [mutate, mutationResult] = useMutation<TDataEZCDeviceLink, TVariablesEZCDeviceLink>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateEZCDeviceLinkMutationInput[] | NodeType.CreateEZCDeviceLinkMutationInput,
        options?: MutationHookOptionsEZCDeviceLink
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'EZCDeviceLink.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteEZCDeviceLinkMutationInput[] | NodeType.DeleteEZCDeviceLinkMutationInput,
        options?: MutationHookOptionsEZCDeviceLink
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'EZCDeviceLink.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateEZCDeviceLinkMutationInput[] | NodeType.UpdateEZCDeviceLinkMutationInput,
        options?: MutationHookOptionsEZCDeviceLink
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'EZCDeviceLink.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** EZCFranchiseLink ***************** //

// EZCFranchiseLink: Mutator

export type TDataEZCFranchiseLink = { EZCFranchiseLink: NodeType.EZCFranchiseLinkMutationPayload };
export type TVariablesEZCFranchiseLink = { input: NodeType.EZCFranchiseLinkMutationInput };
export type MutationHookOptionsEZCFranchiseLink = MutationHookOptions<
    TDataEZCFranchiseLink,
    TVariablesEZCFranchiseLink
>;

export const useMutationEZCFranchiseLink = (options?: MutationHookOptionsEZCFranchiseLink) => {
    const QL = options?.mutation || getMutationQueryByName('EZCFranchiseLink');
    const [mutate, mutationResult] = useMutation<TDataEZCFranchiseLink, TVariablesEZCFranchiseLink>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteEZCFranchiseLinkMutationInput[] | NodeType.DeleteEZCFranchiseLinkMutationInput,
        options?: MutationHookOptionsEZCFranchiseLink
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'EZCFranchiseLink.delete'
        );
    };
    const mutateLink = (
        input: NodeType.EZCFranchiseLinkLinkMutationInput[] | NodeType.EZCFranchiseLinkLinkMutationInput,
        options?: MutationHookOptionsEZCFranchiseLink
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        link: input as any,
                    },
                },
            },
            // @ts-ignore
            'EZCFranchiseLink.link'
        );
    };

    return {
        delete: mutateDelete,
        link: mutateLink,
        mutate,
        mutationResult,
    };
};

// ************** EmailGlobalSelection ***************** //

// EmailGlobalSelection: Mutator

export type TDataEmailGlobalSelection = { EmailGlobalSelection: NodeType.EmailGlobalSelectionMutationPayload };
export type TVariablesEmailGlobalSelection = { input: NodeType.EmailGlobalSelectionMutationInput };
export type MutationHookOptionsEmailGlobalSelection = MutationHookOptions<
    TDataEmailGlobalSelection,
    TVariablesEmailGlobalSelection
>;

export const useMutationEmailGlobalSelection = (options?: MutationHookOptionsEmailGlobalSelection) => {
    const QL = options?.mutation || getMutationQueryByName('EmailGlobalSelection');
    const [mutate, mutationResult] = useMutation<TDataEmailGlobalSelection, TVariablesEmailGlobalSelection>(
        QL,
        options
    );

    const mutateDelete = (
        input: NodeType.DeleteEmailGlobalSelectionMutationInput[] | NodeType.DeleteEmailGlobalSelectionMutationInput,
        options?: MutationHookOptionsEmailGlobalSelection
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailGlobalSelection.delete'
        );
    };

    return {
        delete: mutateDelete,
        mutate,
        mutationResult,
    };
};

// ************** EmailMessage ***************** //

// EmailMessage: Mutator

export type TDataEmailMessage = { EmailMessage: NodeType.EmailMessageMutationPayload };
export type TVariablesEmailMessage = { input: NodeType.EmailMessageMutationInput };
export type MutationHookOptionsEmailMessage = MutationHookOptions<TDataEmailMessage, TVariablesEmailMessage>;

export const useMutationEmailMessage = (options?: MutationHookOptionsEmailMessage) => {
    const QL = options?.mutation || getMutationQueryByName('EmailMessage');
    const [mutate, mutationResult] = useMutation<TDataEmailMessage, TVariablesEmailMessage>(QL, options);

    const mutateCreateForReportAndSend = (
        input:
            | NodeType.EmailMessageCreateForReportAndSendMutationInput[]
            | NodeType.EmailMessageCreateForReportAndSendMutationInput,
        options?: MutationHookOptionsEmailMessage
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        createForReportAndSend: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailMessage.createForReportAndSend'
        );
    };

    return {
        createForReportAndSend: mutateCreateForReportAndSend,
        mutate,
        mutationResult,
    };
};

// ************** EmailTemplate ***************** //

// EmailTemplate: Mutator

export type TDataEmailTemplate = { EmailTemplate: NodeType.EmailTemplateMutationPayload };
export type TVariablesEmailTemplate = { input: NodeType.EmailTemplateMutationInput };
export type MutationHookOptionsEmailTemplate = MutationHookOptions<TDataEmailTemplate, TVariablesEmailTemplate>;

export const useMutationEmailTemplate = (options?: MutationHookOptionsEmailTemplate) => {
    const QL = options?.mutation || getMutationQueryByName('EmailTemplate');
    const [mutate, mutationResult] = useMutation<TDataEmailTemplate, TVariablesEmailTemplate>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateEmailTemplateMutationInput[] | NodeType.CreateEmailTemplateMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteEmailTemplateMutationInput[] | NodeType.DeleteEmailTemplateMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.delete'
        );
    };
    const mutateSelectForFranchise = (
        input:
            | NodeType.EmailTemplateSelectForFranchiseMutationInput[]
            | NodeType.EmailTemplateSelectForFranchiseMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        selectForFranchise: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.selectForFranchise'
        );
    };
    const mutateSelectForOrganisation = (
        input:
            | NodeType.EmailTemplateSelectForOrganisationMutationInput[]
            | NodeType.EmailTemplateSelectForOrganisationMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        selectForOrganisation: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.selectForOrganisation'
        );
    };
    const mutateSelectGlobally = (
        input: NodeType.EmailTemplateSelectGloballyMutationInput[] | NodeType.EmailTemplateSelectGloballyMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        selectGlobally: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.selectGlobally'
        );
    };
    const mutateUnselectForFranchise = (
        input:
            | NodeType.EmailTemplateUnselectForFranchiseMutationInput[]
            | NodeType.EmailTemplateUnselectForFranchiseMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        unselectForFranchise: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.unselectForFranchise'
        );
    };
    const mutateUnselectForOrganisation = (
        input:
            | NodeType.EmailTemplateUnselectForOrganisationMutationInput[]
            | NodeType.EmailTemplateUnselectForOrganisationMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        unselectForOrganisation: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.unselectForOrganisation'
        );
    };
    const mutateUnselectGlobally = (
        input:
            | NodeType.EmailTemplateUnselectGloballyMutationInput[]
            | NodeType.EmailTemplateUnselectGloballyMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        unselectGlobally: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.unselectGlobally'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateEmailTemplateMutationInput[] | NodeType.UpdateEmailTemplateMutationInput,
        options?: MutationHookOptionsEmailTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplate.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        selectForFranchise: mutateSelectForFranchise,
        selectForOrganisation: mutateSelectForOrganisation,
        selectGlobally: mutateSelectGlobally,
        unselectForFranchise: mutateUnselectForFranchise,
        unselectForOrganisation: mutateUnselectForOrganisation,
        unselectGlobally: mutateUnselectGlobally,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** EmailTemplateOrganisationSelection ***************** //

// EmailTemplateOrganisationSelection: Mutator

export type TDataEmailTemplateOrganisationSelection = {
    EmailTemplateOrganisationSelection: NodeType.EmailTemplateOrganisationSelectionMutationPayload;
};
export type TVariablesEmailTemplateOrganisationSelection = {
    input: NodeType.EmailTemplateOrganisationSelectionMutationInput;
};
export type MutationHookOptionsEmailTemplateOrganisationSelection = MutationHookOptions<
    TDataEmailTemplateOrganisationSelection,
    TVariablesEmailTemplateOrganisationSelection
>;

export const useMutationEmailTemplateOrganisationSelection = (
    options?: MutationHookOptionsEmailTemplateOrganisationSelection
) => {
    const QL = options?.mutation || getMutationQueryByName('EmailTemplateOrganisationSelection');
    const [mutate, mutationResult] = useMutation<
        TDataEmailTemplateOrganisationSelection,
        TVariablesEmailTemplateOrganisationSelection
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateEmailTemplateOrganisationSelectionMutationInput[]
            | NodeType.CreateEmailTemplateOrganisationSelectionMutationInput,
        options?: MutationHookOptionsEmailTemplateOrganisationSelection
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplateOrganisationSelection.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteEmailTemplateOrganisationSelectionMutationInput[]
            | NodeType.DeleteEmailTemplateOrganisationSelectionMutationInput,
        options?: MutationHookOptionsEmailTemplateOrganisationSelection
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplateOrganisationSelection.delete'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        mutate,
        mutationResult,
    };
};

// ************** EmailTemplateSelection ***************** //

// EmailTemplateSelection: Mutator

export type TDataEmailTemplateSelection = { EmailTemplateSelection: NodeType.EmailTemplateSelectionMutationPayload };
export type TVariablesEmailTemplateSelection = { input: NodeType.EmailTemplateSelectionMutationInput };
export type MutationHookOptionsEmailTemplateSelection = MutationHookOptions<
    TDataEmailTemplateSelection,
    TVariablesEmailTemplateSelection
>;

export const useMutationEmailTemplateSelection = (options?: MutationHookOptionsEmailTemplateSelection) => {
    const QL = options?.mutation || getMutationQueryByName('EmailTemplateSelection');
    const [mutate, mutationResult] = useMutation<TDataEmailTemplateSelection, TVariablesEmailTemplateSelection>(
        QL,
        options
    );

    const mutateDelete = (
        input:
            | NodeType.DeleteEmailTemplateSelectionMutationInput[]
            | NodeType.DeleteEmailTemplateSelectionMutationInput,
        options?: MutationHookOptionsEmailTemplateSelection
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplateSelection.delete'
        );
    };
    const mutateSelect = (
        input:
            | NodeType.EmailTemplateSelectionSelectMutationInput[]
            | NodeType.EmailTemplateSelectionSelectMutationInput,
        options?: MutationHookOptionsEmailTemplateSelection
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        select: input as any,
                    },
                },
            },
            // @ts-ignore
            'EmailTemplateSelection.select'
        );
    };

    return {
        delete: mutateDelete,
        select: mutateSelect,
        mutate,
        mutationResult,
    };
};

// ************** Entity ***************** //

// Entity: Mutator

export type TDataEntity = { Entity: NodeType.EntityMutationPayload };
export type TVariablesEntity = { input: NodeType.EntityMutationInput };
export type MutationHookOptionsEntity = MutationHookOptions<TDataEntity, TVariablesEntity>;

export const useMutationEntity = (options?: MutationHookOptionsEntity) => {
    const QL = options?.mutation || getMutationQueryByName('Entity');
    const [mutate, mutationResult] = useMutation<TDataEntity, TVariablesEntity>(QL, options);

    const mutateSuperDelete = (
        input: NodeType.EntitySuperDeleteMutationInput[] | NodeType.EntitySuperDeleteMutationInput,
        options?: MutationHookOptionsEntity
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        superDelete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Entity.superDelete'
        );
    };

    return {
        superDelete: mutateSuperDelete,
        mutate,
        mutationResult,
    };
};

// ************** FeatureFlag ***************** //

// FeatureFlag: Mutator

export type TDataFeatureFlag = { FeatureFlag: NodeType.FeatureFlagMutationPayload };
export type TVariablesFeatureFlag = { input: NodeType.FeatureFlagMutationInput };
export type MutationHookOptionsFeatureFlag = MutationHookOptions<TDataFeatureFlag, TVariablesFeatureFlag>;

export const useMutationFeatureFlag = (options?: MutationHookOptionsFeatureFlag) => {
    const QL = options?.mutation || getMutationQueryByName('FeatureFlag');
    const [mutate, mutationResult] = useMutation<TDataFeatureFlag, TVariablesFeatureFlag>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteFeatureFlagMutationInput[] | NodeType.DeleteFeatureFlagMutationInput,
        options?: MutationHookOptionsFeatureFlag
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'FeatureFlag.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.FeatureFlagUpdateMutationInput[] | NodeType.FeatureFlagUpdateMutationInput,
        options?: MutationHookOptionsFeatureFlag
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'FeatureFlag.update'
        );
    };

    return {
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** FeatureFlagVariation ***************** //

// FeatureFlagVariation: Mutator

export type TDataFeatureFlagVariation = { FeatureFlagVariation: NodeType.FeatureFlagVariationMutationPayload };
export type TVariablesFeatureFlagVariation = { input: NodeType.FeatureFlagVariationMutationInput };
export type MutationHookOptionsFeatureFlagVariation = MutationHookOptions<
    TDataFeatureFlagVariation,
    TVariablesFeatureFlagVariation
>;

export const useMutationFeatureFlagVariation = (options?: MutationHookOptionsFeatureFlagVariation) => {
    const QL = options?.mutation || getMutationQueryByName('FeatureFlagVariation');
    const [mutate, mutationResult] = useMutation<TDataFeatureFlagVariation, TVariablesFeatureFlagVariation>(
        QL,
        options
    );

    const mutateCreate = (
        input: NodeType.CreateFeatureFlagVariationMutationInput[] | NodeType.CreateFeatureFlagVariationMutationInput,
        options?: MutationHookOptionsFeatureFlagVariation
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'FeatureFlagVariation.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteFeatureFlagVariationMutationInput[] | NodeType.DeleteFeatureFlagVariationMutationInput,
        options?: MutationHookOptionsFeatureFlagVariation
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'FeatureFlagVariation.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateFeatureFlagVariationMutationInput[] | NodeType.UpdateFeatureFlagVariationMutationInput,
        options?: MutationHookOptionsFeatureFlagVariation
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'FeatureFlagVariation.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** FileUpload ***************** //

// FileUpload: Mutator

export type TDataFileUpload = { FileUpload: NodeType.FileUploadMutationPayload };
export type TVariablesFileUpload = { input: NodeType.FileUploadMutationInput };
export type MutationHookOptionsFileUpload = MutationHookOptions<TDataFileUpload, TVariablesFileUpload>;

export const useMutationFileUpload = (options?: MutationHookOptionsFileUpload) => {
    const QL = options?.mutation || getMutationQueryByName('FileUpload');
    const [mutate, mutationResult] = useMutation<TDataFileUpload, TVariablesFileUpload>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteFileUploadMutationInput[] | NodeType.DeleteFileUploadMutationInput,
        options?: MutationHookOptionsFileUpload
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'FileUpload.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateFileUploadMutationInput[] | NodeType.UpdateFileUploadMutationInput,
        options?: MutationHookOptionsFileUpload
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'FileUpload.update'
        );
    };

    return {
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Franchise ***************** //

// Franchise: Mutator

export type TDataFranchise = { Franchise: NodeType.FranchiseMutationPayload };
export type TVariablesFranchise = { input: NodeType.FranchiseMutationInput };
export type MutationHookOptionsFranchise = MutationHookOptions<TDataFranchise, TVariablesFranchise>;

export const useMutationFranchise = (options?: MutationHookOptionsFranchise) => {
    const QL = options?.mutation || getMutationQueryByName('Franchise');
    const [mutate, mutationResult] = useMutation<TDataFranchise, TVariablesFranchise>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateFranchiseMutationInput[] | NodeType.CreateFranchiseMutationInput,
        options?: MutationHookOptionsFranchise
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Franchise.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteFranchiseMutationInput[] | NodeType.DeleteFranchiseMutationInput,
        options?: MutationHookOptionsFranchise
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Franchise.delete'
        );
    };
    const mutateSetParentFranchise = (
        input: NodeType.FranchiseSetParentFranchiseMutationInput[] | NodeType.FranchiseSetParentFranchiseMutationInput,
        options?: MutationHookOptionsFranchise
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setParentFranchise: input as any,
                    },
                },
            },
            // @ts-ignore
            'Franchise.setParentFranchise'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateFranchiseMutationInput[] | NodeType.UpdateFranchiseMutationInput,
        options?: MutationHookOptionsFranchise
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Franchise.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        setParentFranchise: mutateSetParentFranchise,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** FranchiseSequenceStore ***************** //

// FranchiseSequenceStore: Mutator

export type TDataFranchiseSequenceStore = { FranchiseSequenceStore: NodeType.FranchiseSequenceStoreMutationPayload };
export type TVariablesFranchiseSequenceStore = { input: NodeType.FranchiseSequenceStoreMutationInput };
export type MutationHookOptionsFranchiseSequenceStore = MutationHookOptions<
    TDataFranchiseSequenceStore,
    TVariablesFranchiseSequenceStore
>;

export const useMutationFranchiseSequenceStore = (options?: MutationHookOptionsFranchiseSequenceStore) => {
    const QL = options?.mutation || getMutationQueryByName('FranchiseSequenceStore');
    const [mutate, mutationResult] = useMutation<TDataFranchiseSequenceStore, TVariablesFranchiseSequenceStore>(
        QL,
        options
    );

    const mutateIncrement = (
        input:
            | NodeType.FranchiseSequenceStoreIncrementMutationInput[]
            | NodeType.FranchiseSequenceStoreIncrementMutationInput,
        options?: MutationHookOptionsFranchiseSequenceStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        increment: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseSequenceStore.increment'
        );
    };
    const mutateIncrementOwn = (
        input:
            | NodeType.FranchiseSequenceStoreIncrementOwnMutationInput[]
            | NodeType.FranchiseSequenceStoreIncrementOwnMutationInput,
        options?: MutationHookOptionsFranchiseSequenceStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        incrementOwn: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseSequenceStore.incrementOwn'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateFranchiseSequenceStoreMutationInput[]
            | NodeType.UpdateFranchiseSequenceStoreMutationInput,
        options?: MutationHookOptionsFranchiseSequenceStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseSequenceStore.update'
        );
    };

    return {
        increment: mutateIncrement,
        incrementOwn: mutateIncrementOwn,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** FranchiseShop ***************** //

// FranchiseShop: Mutator

export type TDataFranchiseShop = { FranchiseShop: NodeType.FranchiseShopMutationPayload };
export type TVariablesFranchiseShop = { input: NodeType.FranchiseShopMutationInput };
export type MutationHookOptionsFranchiseShop = MutationHookOptions<TDataFranchiseShop, TVariablesFranchiseShop>;

export const useMutationFranchiseShop = (options?: MutationHookOptionsFranchiseShop) => {
    const QL = options?.mutation || getMutationQueryByName('FranchiseShop');
    const [mutate, mutationResult] = useMutation<TDataFranchiseShop, TVariablesFranchiseShop>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateFranchiseShopMutationInput[] | NodeType.CreateFranchiseShopMutationInput,
        options?: MutationHookOptionsFranchiseShop
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseShop.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteFranchiseShopMutationInput[] | NodeType.DeleteFranchiseShopMutationInput,
        options?: MutationHookOptionsFranchiseShop
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseShop.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateFranchiseShopMutationInput[] | NodeType.UpdateFranchiseShopMutationInput,
        options?: MutationHookOptionsFranchiseShop
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseShop.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** FranchiseTestTreatmentView ***************** //

// FranchiseTestTreatmentView: Mutator

export type TDataFranchiseTestTreatmentView = {
    FranchiseTestTreatmentView: NodeType.FranchiseTestTreatmentViewMutationPayload;
};
export type TVariablesFranchiseTestTreatmentView = { input: NodeType.FranchiseTestTreatmentViewMutationInput };
export type MutationHookOptionsFranchiseTestTreatmentView = MutationHookOptions<
    TDataFranchiseTestTreatmentView,
    TVariablesFranchiseTestTreatmentView
>;

export const useMutationFranchiseTestTreatmentView = (options?: MutationHookOptionsFranchiseTestTreatmentView) => {
    const QL = options?.mutation || getMutationQueryByName('FranchiseTestTreatmentView');
    const [mutate, mutationResult] = useMutation<TDataFranchiseTestTreatmentView, TVariablesFranchiseTestTreatmentView>(
        QL,
        options
    );

    const mutateSetBlacklist = (
        input:
            | NodeType.FranchiseTestTreatmentViewSetBlacklistMutationInput[]
            | NodeType.FranchiseTestTreatmentViewSetBlacklistMutationInput,
        options?: MutationHookOptionsFranchiseTestTreatmentView
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setBlacklist: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseTestTreatmentView.setBlacklist'
        );
    };
    const mutateSetLocalPriority = (
        input:
            | NodeType.FranchiseTestTreatmentViewSetLocalPriorityMutationInput[]
            | NodeType.FranchiseTestTreatmentViewSetLocalPriorityMutationInput,
        options?: MutationHookOptionsFranchiseTestTreatmentView
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setLocalPriority: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseTestTreatmentView.setLocalPriority'
        );
    };

    return {
        setBlacklist: mutateSetBlacklist,
        setLocalPriority: mutateSetLocalPriority,
        mutate,
        mutationResult,
    };
};

// ************** FranchiseValueStore ***************** //

// FranchiseValueStore: Mutator

export type TDataFranchiseValueStore = { FranchiseValueStore: NodeType.FranchiseValueStoreMutationPayload };
export type TVariablesFranchiseValueStore = { input: NodeType.FranchiseValueStoreMutationInput };
export type MutationHookOptionsFranchiseValueStore = MutationHookOptions<
    TDataFranchiseValueStore,
    TVariablesFranchiseValueStore
>;

export const useMutationFranchiseValueStore = (options?: MutationHookOptionsFranchiseValueStore) => {
    const QL = options?.mutation || getMutationQueryByName('FranchiseValueStore');
    const [mutate, mutationResult] = useMutation<TDataFranchiseValueStore, TVariablesFranchiseValueStore>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteFranchiseValueStoreMutationInput[] | NodeType.DeleteFranchiseValueStoreMutationInput,
        options?: MutationHookOptionsFranchiseValueStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseValueStore.delete'
        );
    };
    const mutateSet = (
        input: NodeType.FranchiseValueStoreSetMutationInput[] | NodeType.FranchiseValueStoreSetMutationInput,
        options?: MutationHookOptionsFranchiseValueStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        set: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseValueStore.set'
        );
    };
    const mutateSetOwn = (
        input: NodeType.FranchiseValueStoreSetOwnMutationInput[] | NodeType.FranchiseValueStoreSetOwnMutationInput,
        options?: MutationHookOptionsFranchiseValueStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setOwn: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseValueStore.setOwn'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateFranchiseValueStoreMutationInput[] | NodeType.UpdateFranchiseValueStoreMutationInput,
        options?: MutationHookOptionsFranchiseValueStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseValueStore.update'
        );
    };

    return {
        delete: mutateDelete,
        set: mutateSet,
        setOwn: mutateSetOwn,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** FranchiseWarranter ***************** //

// FranchiseWarranter: Mutator

export type TDataFranchiseWarranter = { FranchiseWarranter: NodeType.FranchiseWarranterMutationPayload };
export type TVariablesFranchiseWarranter = { input: NodeType.FranchiseWarranterMutationInput };
export type MutationHookOptionsFranchiseWarranter = MutationHookOptions<
    TDataFranchiseWarranter,
    TVariablesFranchiseWarranter
>;

export const useMutationFranchiseWarranter = (options?: MutationHookOptionsFranchiseWarranter) => {
    const QL = options?.mutation || getMutationQueryByName('FranchiseWarranter');
    const [mutate, mutationResult] = useMutation<TDataFranchiseWarranter, TVariablesFranchiseWarranter>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateFranchiseWarranterMutationInput[] | NodeType.CreateFranchiseWarranterMutationInput,
        options?: MutationHookOptionsFranchiseWarranter
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseWarranter.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteFranchiseWarranterMutationInput[] | NodeType.DeleteFranchiseWarranterMutationInput,
        options?: MutationHookOptionsFranchiseWarranter
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseWarranter.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateFranchiseWarranterMutationInput[] | NodeType.UpdateFranchiseWarranterMutationInput,
        options?: MutationHookOptionsFranchiseWarranter
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'FranchiseWarranter.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** HeaterRecommendation ***************** //

// HeaterRecommendation: Mutator

export type TDataHeaterRecommendation = { HeaterRecommendation: NodeType.HeaterRecommendationMutationPayload };
export type TVariablesHeaterRecommendation = { input: NodeType.HeaterRecommendationMutationInput };
export type MutationHookOptionsHeaterRecommendation = MutationHookOptions<
    TDataHeaterRecommendation,
    TVariablesHeaterRecommendation
>;

export const useMutationHeaterRecommendation = (options?: MutationHookOptionsHeaterRecommendation) => {
    const QL = options?.mutation || getMutationQueryByName('HeaterRecommendation');
    const [mutate, mutationResult] = useMutation<TDataHeaterRecommendation, TVariablesHeaterRecommendation>(
        QL,
        options
    );

    const mutateCreate = (
        input: NodeType.CreateHeaterRecommendationMutationInput[] | NodeType.CreateHeaterRecommendationMutationInput,
        options?: MutationHookOptionsHeaterRecommendation
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'HeaterRecommendation.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteHeaterRecommendationMutationInput[] | NodeType.DeleteHeaterRecommendationMutationInput,
        options?: MutationHookOptionsHeaterRecommendation
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'HeaterRecommendation.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateHeaterRecommendationMutationInput[] | NodeType.UpdateHeaterRecommendationMutationInput,
        options?: MutationHookOptionsHeaterRecommendation
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'HeaterRecommendation.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** InstallableProduct ***************** //

// InstallableProduct: Mutator

export type TDataInstallableProduct = { InstallableProduct: NodeType.InstallableProductMutationPayload };
export type TVariablesInstallableProduct = { input: NodeType.InstallableProductMutationInput };
export type MutationHookOptionsInstallableProduct = MutationHookOptions<
    TDataInstallableProduct,
    TVariablesInstallableProduct
>;

export const useMutationInstallableProduct = (options?: MutationHookOptionsInstallableProduct) => {
    const QL = options?.mutation || getMutationQueryByName('InstallableProduct');
    const [mutate, mutationResult] = useMutation<TDataInstallableProduct, TVariablesInstallableProduct>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateInstallableProductMutationInput[] | NodeType.CreateInstallableProductMutationInput,
        options?: MutationHookOptionsInstallableProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'InstallableProduct.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteInstallableProductMutationInput[] | NodeType.DeleteInstallableProductMutationInput,
        options?: MutationHookOptionsInstallableProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'InstallableProduct.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateInstallableProductMutationInput[] | NodeType.UpdateInstallableProductMutationInput,
        options?: MutationHookOptionsInstallableProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'InstallableProduct.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Item ***************** //

// Item: Mutator

export type TDataItem = { Item: NodeType.ItemMutationPayload };
export type TVariablesItem = { input: NodeType.ItemMutationInput };
export type MutationHookOptionsItem = MutationHookOptions<TDataItem, TVariablesItem>;

export const useMutationItem = (options?: MutationHookOptionsItem) => {
    const QL = options?.mutation || getMutationQueryByName('Item');
    const [mutate, mutationResult] = useMutation<TDataItem, TVariablesItem>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateItemMutationInput[] | NodeType.CreateItemMutationInput,
        options?: MutationHookOptionsItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Item.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteItemMutationInput[] | NodeType.DeleteItemMutationInput,
        options?: MutationHookOptionsItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Item.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateItemMutationInput[] | NodeType.UpdateItemMutationInput,
        options?: MutationHookOptionsItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Item.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** JobTodo ***************** //

// JobTodo: Mutator

export type TDataJobTodo = { JobTodo: NodeType.JobTodoMutationPayload };
export type TVariablesJobTodo = { input: NodeType.JobTodoMutationInput };
export type MutationHookOptionsJobTodo = MutationHookOptions<TDataJobTodo, TVariablesJobTodo>;

export const useMutationJobTodo = (options?: MutationHookOptionsJobTodo) => {
    const QL = options?.mutation || getMutationQueryByName('JobTodo');
    const [mutate, mutationResult] = useMutation<TDataJobTodo, TVariablesJobTodo>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateJobTodoMutationInput[] | NodeType.CreateJobTodoMutationInput,
        options?: MutationHookOptionsJobTodo
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodo.create'
        );
    };
    const mutateCreateFromTemplate = (
        input: NodeType.JobTodoCreateFromTemplateMutationInput[] | NodeType.JobTodoCreateFromTemplateMutationInput,
        options?: MutationHookOptionsJobTodo
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        createFromTemplate: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodo.createFromTemplate'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteJobTodoMutationInput[] | NodeType.DeleteJobTodoMutationInput,
        options?: MutationHookOptionsJobTodo
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodo.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateJobTodoMutationInput[] | NodeType.UpdateJobTodoMutationInput,
        options?: MutationHookOptionsJobTodo
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodo.update'
        );
    };

    return {
        create: mutateCreate,
        createFromTemplate: mutateCreateFromTemplate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** JobTodoItem ***************** //

// JobTodoItem: Mutator

export type TDataJobTodoItem = { JobTodoItem: NodeType.JobTodoItemMutationPayload };
export type TVariablesJobTodoItem = { input: NodeType.JobTodoItemMutationInput };
export type MutationHookOptionsJobTodoItem = MutationHookOptions<TDataJobTodoItem, TVariablesJobTodoItem>;

export const useMutationJobTodoItem = (options?: MutationHookOptionsJobTodoItem) => {
    const QL = options?.mutation || getMutationQueryByName('JobTodoItem');
    const [mutate, mutationResult] = useMutation<TDataJobTodoItem, TVariablesJobTodoItem>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateJobTodoItemMutationInput[] | NodeType.CreateJobTodoItemMutationInput,
        options?: MutationHookOptionsJobTodoItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoItem.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteJobTodoItemMutationInput[] | NodeType.DeleteJobTodoItemMutationInput,
        options?: MutationHookOptionsJobTodoItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoItem.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateJobTodoItemMutationInput[] | NodeType.UpdateJobTodoItemMutationInput,
        options?: MutationHookOptionsJobTodoItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoItem.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** JobTodoTemplate ***************** //

// JobTodoTemplate: Mutator

export type TDataJobTodoTemplate = { JobTodoTemplate: NodeType.JobTodoTemplateMutationPayload };
export type TVariablesJobTodoTemplate = { input: NodeType.JobTodoTemplateMutationInput };
export type MutationHookOptionsJobTodoTemplate = MutationHookOptions<TDataJobTodoTemplate, TVariablesJobTodoTemplate>;

export const useMutationJobTodoTemplate = (options?: MutationHookOptionsJobTodoTemplate) => {
    const QL = options?.mutation || getMutationQueryByName('JobTodoTemplate');
    const [mutate, mutationResult] = useMutation<TDataJobTodoTemplate, TVariablesJobTodoTemplate>(QL, options);

    const mutateArchive = (
        input: NodeType.JobTodoTemplateArchiveMutationInput[] | NodeType.JobTodoTemplateArchiveMutationInput,
        options?: MutationHookOptionsJobTodoTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        archive: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplate.archive'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateJobTodoTemplateMutationInput[] | NodeType.CreateJobTodoTemplateMutationInput,
        options?: MutationHookOptionsJobTodoTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplate.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteJobTodoTemplateMutationInput[] | NodeType.DeleteJobTodoTemplateMutationInput,
        options?: MutationHookOptionsJobTodoTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplate.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateJobTodoTemplateMutationInput[] | NodeType.UpdateJobTodoTemplateMutationInput,
        options?: MutationHookOptionsJobTodoTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplate.update'
        );
    };

    return {
        archive: mutateArchive,
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** JobTodoTemplateAssoc ***************** //

// JobTodoTemplateAssoc: Mutator

export type TDataJobTodoTemplateAssoc = { JobTodoTemplateAssoc: NodeType.JobTodoTemplateAssocMutationPayload };
export type TVariablesJobTodoTemplateAssoc = { input: NodeType.JobTodoTemplateAssocMutationInput };
export type MutationHookOptionsJobTodoTemplateAssoc = MutationHookOptions<
    TDataJobTodoTemplateAssoc,
    TVariablesJobTodoTemplateAssoc
>;

export const useMutationJobTodoTemplateAssoc = (options?: MutationHookOptionsJobTodoTemplateAssoc) => {
    const QL = options?.mutation || getMutationQueryByName('JobTodoTemplateAssoc');
    const [mutate, mutationResult] = useMutation<TDataJobTodoTemplateAssoc, TVariablesJobTodoTemplateAssoc>(
        QL,
        options
    );

    const mutateCreate = (
        input: NodeType.CreateJobTodoTemplateAssocMutationInput[] | NodeType.CreateJobTodoTemplateAssocMutationInput,
        options?: MutationHookOptionsJobTodoTemplateAssoc
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplateAssoc.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteJobTodoTemplateAssocMutationInput[] | NodeType.DeleteJobTodoTemplateAssocMutationInput,
        options?: MutationHookOptionsJobTodoTemplateAssoc
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplateAssoc.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateJobTodoTemplateAssocMutationInput[] | NodeType.UpdateJobTodoTemplateAssocMutationInput,
        options?: MutationHookOptionsJobTodoTemplateAssoc
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplateAssoc.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** JobTodoTemplateItem ***************** //

// JobTodoTemplateItem: Mutator

export type TDataJobTodoTemplateItem = { JobTodoTemplateItem: NodeType.JobTodoTemplateItemMutationPayload };
export type TVariablesJobTodoTemplateItem = { input: NodeType.JobTodoTemplateItemMutationInput };
export type MutationHookOptionsJobTodoTemplateItem = MutationHookOptions<
    TDataJobTodoTemplateItem,
    TVariablesJobTodoTemplateItem
>;

export const useMutationJobTodoTemplateItem = (options?: MutationHookOptionsJobTodoTemplateItem) => {
    const QL = options?.mutation || getMutationQueryByName('JobTodoTemplateItem');
    const [mutate, mutationResult] = useMutation<TDataJobTodoTemplateItem, TVariablesJobTodoTemplateItem>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateJobTodoTemplateItemMutationInput[] | NodeType.CreateJobTodoTemplateItemMutationInput,
        options?: MutationHookOptionsJobTodoTemplateItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplateItem.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteJobTodoTemplateItemMutationInput[] | NodeType.DeleteJobTodoTemplateItemMutationInput,
        options?: MutationHookOptionsJobTodoTemplateItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplateItem.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateJobTodoTemplateItemMutationInput[] | NodeType.UpdateJobTodoTemplateItemMutationInput,
        options?: MutationHookOptionsJobTodoTemplateItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'JobTodoTemplateItem.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ManualProblem ***************** //

// ManualProblem: Mutator

export type TDataManualProblem = { ManualProblem: NodeType.ManualProblemMutationPayload };
export type TVariablesManualProblem = { input: NodeType.ManualProblemMutationInput };
export type MutationHookOptionsManualProblem = MutationHookOptions<TDataManualProblem, TVariablesManualProblem>;

export const useMutationManualProblem = (options?: MutationHookOptionsManualProblem) => {
    const QL = options?.mutation || getMutationQueryByName('ManualProblem');
    const [mutate, mutationResult] = useMutation<TDataManualProblem, TVariablesManualProblem>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateManualProblemMutationInput[] | NodeType.CreateManualProblemMutationInput,
        options?: MutationHookOptionsManualProblem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ManualProblem.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteManualProblemMutationInput[] | NodeType.DeleteManualProblemMutationInput,
        options?: MutationHookOptionsManualProblem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ManualProblem.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateManualProblemMutationInput[] | NodeType.UpdateManualProblemMutationInput,
        options?: MutationHookOptionsManualProblem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ManualProblem.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Measurement ***************** //

// Measurement: Mutator

export type TDataMeasurement = { Measurement: NodeType.MeasurementMutationPayload };
export type TVariablesMeasurement = { input: NodeType.MeasurementMutationInput };
export type MutationHookOptionsMeasurement = MutationHookOptions<TDataMeasurement, TVariablesMeasurement>;

export const useMutationMeasurement = (options?: MutationHookOptionsMeasurement) => {
    const QL = options?.mutation || getMutationQueryByName('Measurement');
    const [mutate, mutationResult] = useMutation<TDataMeasurement, TVariablesMeasurement>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateMeasurementMutationInput[] | NodeType.CreateMeasurementMutationInput,
        options?: MutationHookOptionsMeasurement
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Measurement.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteMeasurementMutationInput[] | NodeType.DeleteMeasurementMutationInput,
        options?: MutationHookOptionsMeasurement
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Measurement.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateMeasurementMutationInput[] | NodeType.UpdateMeasurementMutationInput,
        options?: MutationHookOptionsMeasurement
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Measurement.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Permission ***************** //

// Permission: Mutator

export type TDataPermission = { Permission: NodeType.PermissionMutationPayload };
export type TVariablesPermission = { input: NodeType.PermissionMutationInput };
export type MutationHookOptionsPermission = MutationHookOptions<TDataPermission, TVariablesPermission>;

export const useMutationPermission = (options?: MutationHookOptionsPermission) => {
    const QL = options?.mutation || getMutationQueryByName('Permission');
    const [mutate, mutationResult] = useMutation<TDataPermission, TVariablesPermission>(QL, options);

    const mutateCreate = (
        input: NodeType.CreatePermissionMutationInput[] | NodeType.CreatePermissionMutationInput,
        options?: MutationHookOptionsPermission
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Permission.create'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdatePermissionMutationInput[] | NodeType.UpdatePermissionMutationInput,
        options?: MutationHookOptionsPermission
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Permission.update'
        );
    };

    return {
        create: mutateCreate,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Pool ***************** //

// Pool: Mutator

export type TDataPool = { Pool: NodeType.PoolMutationPayload };
export type TVariablesPool = { input: NodeType.PoolMutationInput };
export type MutationHookOptionsPool = MutationHookOptions<TDataPool, TVariablesPool>;

export const useMutationPool = (options?: MutationHookOptionsPool) => {
    const QL = options?.mutation || getMutationQueryByName('Pool');
    const [mutate, mutationResult] = useMutation<TDataPool, TVariablesPool>(QL, options);

    const mutateCreate = (
        input: NodeType.CreatePoolMutationInput[] | NodeType.CreatePoolMutationInput,
        options?: MutationHookOptionsPool
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Pool.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeletePoolMutationInput[] | NodeType.DeletePoolMutationInput,
        options?: MutationHookOptionsPool
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Pool.delete'
        );
    };
    const mutateTransferToSite = (
        input: NodeType.PoolTransferToSiteMutationInput[] | NodeType.PoolTransferToSiteMutationInput,
        options?: MutationHookOptionsPool
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        transferToSite: input as any,
                    },
                },
            },
            // @ts-ignore
            'Pool.transferToSite'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdatePoolMutationInput[] | NodeType.UpdatePoolMutationInput,
        options?: MutationHookOptionsPool
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Pool.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        transferToSite: mutateTransferToSite,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** PoolAttachedFlagField ***************** //

// PoolAttachedFlagField: Mutator

export type TDataPoolAttachedFlagField = { PoolAttachedFlagField: NodeType.PoolAttachedFlagFieldMutationPayload };
export type TVariablesPoolAttachedFlagField = { input: NodeType.PoolAttachedFlagFieldMutationInput };
export type MutationHookOptionsPoolAttachedFlagField = MutationHookOptions<
    TDataPoolAttachedFlagField,
    TVariablesPoolAttachedFlagField
>;

export const useMutationPoolAttachedFlagField = (options?: MutationHookOptionsPoolAttachedFlagField) => {
    const QL = options?.mutation || getMutationQueryByName('PoolAttachedFlagField');
    const [mutate, mutationResult] = useMutation<TDataPoolAttachedFlagField, TVariablesPoolAttachedFlagField>(
        QL,
        options
    );

    const mutateSetValue = (
        input:
            | NodeType.PoolAttachedFlagFieldSetValueMutationInput[]
            | NodeType.PoolAttachedFlagFieldSetValueMutationInput,
        options?: MutationHookOptionsPoolAttachedFlagField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setValue: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolAttachedFlagField.setValue'
        );
    };

    return {
        setValue: mutateSetValue,
        mutate,
        mutationResult,
    };
};

// ************** PoolBottle ***************** //

// PoolBottle: Mutator

export type TDataPoolBottle = { PoolBottle: NodeType.PoolBottleMutationPayload };
export type TVariablesPoolBottle = { input: NodeType.PoolBottleMutationInput };
export type MutationHookOptionsPoolBottle = MutationHookOptions<TDataPoolBottle, TVariablesPoolBottle>;

export const useMutationPoolBottle = (options?: MutationHookOptionsPoolBottle) => {
    const QL = options?.mutation || getMutationQueryByName('PoolBottle');
    const [mutate, mutationResult] = useMutation<TDataPoolBottle, TVariablesPoolBottle>(QL, options);

    const mutateCreate = (
        input: NodeType.CreatePoolBottleMutationInput[] | NodeType.CreatePoolBottleMutationInput,
        options?: MutationHookOptionsPoolBottle
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolBottle.create'
        );
    };
    const mutateProcessReturn = (
        input: NodeType.PoolBottleProcessReturnMutationInput[] | NodeType.PoolBottleProcessReturnMutationInput,
        options?: MutationHookOptionsPoolBottle
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        processReturn: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolBottle.processReturn'
        );
    };

    return {
        create: mutateCreate,
        processReturn: mutateProcessReturn,
        mutate,
        mutationResult,
    };
};

// ************** PoolBottleLease ***************** //

// PoolBottleLease: Mutator

export type TDataPoolBottleLease = { PoolBottleLease: NodeType.PoolBottleLeaseMutationPayload };
export type TVariablesPoolBottleLease = { input: NodeType.PoolBottleLeaseMutationInput };
export type MutationHookOptionsPoolBottleLease = MutationHookOptions<TDataPoolBottleLease, TVariablesPoolBottleLease>;

export const useMutationPoolBottleLease = (options?: MutationHookOptionsPoolBottleLease) => {
    const QL = options?.mutation || getMutationQueryByName('PoolBottleLease');
    const [mutate, mutationResult] = useMutation<TDataPoolBottleLease, TVariablesPoolBottleLease>(QL, options);

    const mutateIssueLease = (
        input: NodeType.PoolBottleLeaseIssueLeaseMutationInput[] | NodeType.PoolBottleLeaseIssueLeaseMutationInput,
        options?: MutationHookOptionsPoolBottleLease
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        issueLease: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolBottleLease.issueLease'
        );
    };
    const mutateProcessReturn = (
        input:
            | NodeType.PoolBottleLeaseProcessReturnMutationInput[]
            | NodeType.PoolBottleLeaseProcessReturnMutationInput,
        options?: MutationHookOptionsPoolBottleLease
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        processReturn: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolBottleLease.processReturn'
        );
    };

    return {
        issueLease: mutateIssueLease,
        processReturn: mutateProcessReturn,
        mutate,
        mutationResult,
    };
};

// ************** PoolCalibration ***************** //

// PoolCalibration: Mutator

export type TDataPoolCalibration = { PoolCalibration: NodeType.PoolCalibrationMutationPayload };
export type TVariablesPoolCalibration = { input: NodeType.PoolCalibrationMutationInput };
export type MutationHookOptionsPoolCalibration = MutationHookOptions<TDataPoolCalibration, TVariablesPoolCalibration>;

export const useMutationPoolCalibration = (options?: MutationHookOptionsPoolCalibration) => {
    const QL = options?.mutation || getMutationQueryByName('PoolCalibration');
    const [mutate, mutationResult] = useMutation<TDataPoolCalibration, TVariablesPoolCalibration>(QL, options);

    const mutateCreate = (
        input: NodeType.CreatePoolCalibrationMutationInput[] | NodeType.CreatePoolCalibrationMutationInput,
        options?: MutationHookOptionsPoolCalibration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolCalibration.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeletePoolCalibrationMutationInput[] | NodeType.DeletePoolCalibrationMutationInput,
        options?: MutationHookOptionsPoolCalibration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolCalibration.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdatePoolCalibrationMutationInput[] | NodeType.UpdatePoolCalibrationMutationInput,
        options?: MutationHookOptionsPoolCalibration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolCalibration.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** PoolDeclarationFlagField ***************** //

// PoolDeclarationFlagField: Mutator

export type TDataPoolDeclarationFlagField = {
    PoolDeclarationFlagField: NodeType.PoolDeclarationFlagFieldMutationPayload;
};
export type TVariablesPoolDeclarationFlagField = { input: NodeType.PoolDeclarationFlagFieldMutationInput };
export type MutationHookOptionsPoolDeclarationFlagField = MutationHookOptions<
    TDataPoolDeclarationFlagField,
    TVariablesPoolDeclarationFlagField
>;

export const useMutationPoolDeclarationFlagField = (options?: MutationHookOptionsPoolDeclarationFlagField) => {
    const QL = options?.mutation || getMutationQueryByName('PoolDeclarationFlagField');
    const [mutate, mutationResult] = useMutation<TDataPoolDeclarationFlagField, TVariablesPoolDeclarationFlagField>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreatePoolDeclarationFlagFieldMutationInput[]
            | NodeType.CreatePoolDeclarationFlagFieldMutationInput,
        options?: MutationHookOptionsPoolDeclarationFlagField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolDeclarationFlagField.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeletePoolDeclarationFlagFieldMutationInput[]
            | NodeType.DeletePoolDeclarationFlagFieldMutationInput,
        options?: MutationHookOptionsPoolDeclarationFlagField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolDeclarationFlagField.delete'
        );
    };
    const mutateSetActive = (
        input:
            | NodeType.PoolDeclarationFlagFieldSetActiveMutationInput[]
            | NodeType.PoolDeclarationFlagFieldSetActiveMutationInput,
        options?: MutationHookOptionsPoolDeclarationFlagField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setActive: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolDeclarationFlagField.setActive'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdatePoolDeclarationFlagFieldMutationInput[]
            | NodeType.UpdatePoolDeclarationFlagFieldMutationInput,
        options?: MutationHookOptionsPoolDeclarationFlagField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolDeclarationFlagField.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        setActive: mutateSetActive,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** PoolNote ***************** //

// PoolNote: Mutator

export type TDataPoolNote = { PoolNote: NodeType.PoolNoteMutationPayload };
export type TVariablesPoolNote = { input: NodeType.PoolNoteMutationInput };
export type MutationHookOptionsPoolNote = MutationHookOptions<TDataPoolNote, TVariablesPoolNote>;

export const useMutationPoolNote = (options?: MutationHookOptionsPoolNote) => {
    const QL = options?.mutation || getMutationQueryByName('PoolNote');
    const [mutate, mutationResult] = useMutation<TDataPoolNote, TVariablesPoolNote>(QL, options);

    const mutateCreate = (
        input: NodeType.CreatePoolNoteMutationInput[] | NodeType.CreatePoolNoteMutationInput,
        options?: MutationHookOptionsPoolNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolNote.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeletePoolNoteMutationInput[] | NodeType.DeletePoolNoteMutationInput,
        options?: MutationHookOptionsPoolNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolNote.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdatePoolNoteMutationInput[] | NodeType.UpdatePoolNoteMutationInput,
        options?: MutationHookOptionsPoolNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolNote.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** PoolProductDeclaration ***************** //

// PoolProductDeclaration: Mutator

export type TDataPoolProductDeclaration = { PoolProductDeclaration: NodeType.PoolProductDeclarationMutationPayload };
export type TVariablesPoolProductDeclaration = { input: NodeType.PoolProductDeclarationMutationInput };
export type MutationHookOptionsPoolProductDeclaration = MutationHookOptions<
    TDataPoolProductDeclaration,
    TVariablesPoolProductDeclaration
>;

export const useMutationPoolProductDeclaration = (options?: MutationHookOptionsPoolProductDeclaration) => {
    const QL = options?.mutation || getMutationQueryByName('PoolProductDeclaration');
    const [mutate, mutationResult] = useMutation<TDataPoolProductDeclaration, TVariablesPoolProductDeclaration>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreatePoolProductDeclarationMutationInput[]
            | NodeType.CreatePoolProductDeclarationMutationInput,
        options?: MutationHookOptionsPoolProductDeclaration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolProductDeclaration.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeletePoolProductDeclarationMutationInput[]
            | NodeType.DeletePoolProductDeclarationMutationInput,
        options?: MutationHookOptionsPoolProductDeclaration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolProductDeclaration.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdatePoolProductDeclarationMutationInput[]
            | NodeType.UpdatePoolProductDeclarationMutationInput,
        options?: MutationHookOptionsPoolProductDeclaration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolProductDeclaration.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** PoolType ***************** //

// PoolType: Mutator

export type TDataPoolType = { PoolType: NodeType.PoolTypeMutationPayload };
export type TVariablesPoolType = { input: NodeType.PoolTypeMutationInput };
export type MutationHookOptionsPoolType = MutationHookOptions<TDataPoolType, TVariablesPoolType>;

export const useMutationPoolType = (options?: MutationHookOptionsPoolType) => {
    const QL = options?.mutation || getMutationQueryByName('PoolType');
    const [mutate, mutationResult] = useMutation<TDataPoolType, TVariablesPoolType>(QL, options);

    const mutateChangePublishedStatus = (
        input:
            | NodeType.PoolTypeChangePublishedStatusMutationInput[]
            | NodeType.PoolTypeChangePublishedStatusMutationInput,
        options?: MutationHookOptionsPoolType
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        changePublishedStatus: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolType.changePublishedStatus'
        );
    };
    const mutateCreate = (
        input: NodeType.CreatePoolTypeMutationInput[] | NodeType.CreatePoolTypeMutationInput,
        options?: MutationHookOptionsPoolType
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolType.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeletePoolTypeMutationInput[] | NodeType.DeletePoolTypeMutationInput,
        options?: MutationHookOptionsPoolType
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolType.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdatePoolTypeMutationInput[] | NodeType.UpdatePoolTypeMutationInput,
        options?: MutationHookOptionsPoolType
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'PoolType.update'
        );
    };

    return {
        changePublishedStatus: mutateChangePublishedStatus,
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Product ***************** //

// Product: Mutator

export type TDataProduct = { Product: NodeType.ProductMutationPayload };
export type TVariablesProduct = { input: NodeType.ProductMutationInput };
export type MutationHookOptionsProduct = MutationHookOptions<TDataProduct, TVariablesProduct>;

export const useMutationProduct = (options?: MutationHookOptionsProduct) => {
    const QL = options?.mutation || getMutationQueryByName('Product');
    const [mutate, mutationResult] = useMutation<TDataProduct, TVariablesProduct>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateProductMutationInput[] | NodeType.CreateProductMutationInput,
        options?: MutationHookOptionsProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Product.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteProductMutationInput[] | NodeType.DeleteProductMutationInput,
        options?: MutationHookOptionsProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Product.delete'
        );
    };
    const mutateRetire = (
        input: NodeType.ProductRetireMutationInput[] | NodeType.ProductRetireMutationInput,
        options?: MutationHookOptionsProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        retire: input as any,
                    },
                },
            },
            // @ts-ignore
            'Product.retire'
        );
    };
    const mutateUnretire = (
        input: NodeType.ProductUnretireMutationInput[] | NodeType.ProductUnretireMutationInput,
        options?: MutationHookOptionsProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        unretire: input as any,
                    },
                },
            },
            // @ts-ignore
            'Product.unretire'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateProductMutationInput[] | NodeType.UpdateProductMutationInput,
        options?: MutationHookOptionsProduct
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Product.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        retire: mutateRetire,
        unretire: mutateUnretire,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductCompany ***************** //

// ProductCompany: Mutator

export type TDataProductCompany = { ProductCompany: NodeType.ProductCompanyMutationPayload };
export type TVariablesProductCompany = { input: NodeType.ProductCompanyMutationInput };
export type MutationHookOptionsProductCompany = MutationHookOptions<TDataProductCompany, TVariablesProductCompany>;

export const useMutationProductCompany = (options?: MutationHookOptionsProductCompany) => {
    const QL = options?.mutation || getMutationQueryByName('ProductCompany');
    const [mutate, mutationResult] = useMutation<TDataProductCompany, TVariablesProductCompany>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateProductCompanyMutationInput[] | NodeType.CreateProductCompanyMutationInput,
        options?: MutationHookOptionsProductCompany
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductCompany.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteProductCompanyMutationInput[] | NodeType.DeleteProductCompanyMutationInput,
        options?: MutationHookOptionsProductCompany
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductCompany.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateProductCompanyMutationInput[] | NodeType.UpdateProductCompanyMutationInput,
        options?: MutationHookOptionsProductCompany
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductCompany.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDeclarationFlagField ***************** //

// ProductDeclarationFlagField: Mutator

export type TDataProductDeclarationFlagField = {
    ProductDeclarationFlagField: NodeType.ProductDeclarationFlagFieldMutationPayload;
};
export type TVariablesProductDeclarationFlagField = { input: NodeType.ProductDeclarationFlagFieldMutationInput };
export type MutationHookOptionsProductDeclarationFlagField = MutationHookOptions<
    TDataProductDeclarationFlagField,
    TVariablesProductDeclarationFlagField
>;

export const useMutationProductDeclarationFlagField = (options?: MutationHookOptionsProductDeclarationFlagField) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDeclarationFlagField');
    const [mutate, mutationResult] = useMutation<
        TDataProductDeclarationFlagField,
        TVariablesProductDeclarationFlagField
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductDeclarationFlagFieldMutationInput[]
            | NodeType.CreateProductDeclarationFlagFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationFlagField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationFlagField.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductDeclarationFlagFieldMutationInput[]
            | NodeType.DeleteProductDeclarationFlagFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationFlagField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationFlagField.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductDeclarationFlagFieldMutationInput[]
            | NodeType.UpdateProductDeclarationFlagFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationFlagField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationFlagField.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDeclarationFloatField ***************** //

// ProductDeclarationFloatField: Mutator

export type TDataProductDeclarationFloatField = {
    ProductDeclarationFloatField: NodeType.ProductDeclarationFloatFieldMutationPayload;
};
export type TVariablesProductDeclarationFloatField = { input: NodeType.ProductDeclarationFloatFieldMutationInput };
export type MutationHookOptionsProductDeclarationFloatField = MutationHookOptions<
    TDataProductDeclarationFloatField,
    TVariablesProductDeclarationFloatField
>;

export const useMutationProductDeclarationFloatField = (options?: MutationHookOptionsProductDeclarationFloatField) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDeclarationFloatField');
    const [mutate, mutationResult] = useMutation<
        TDataProductDeclarationFloatField,
        TVariablesProductDeclarationFloatField
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductDeclarationFloatFieldMutationInput[]
            | NodeType.CreateProductDeclarationFloatFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationFloatField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationFloatField.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductDeclarationFloatFieldMutationInput[]
            | NodeType.DeleteProductDeclarationFloatFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationFloatField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationFloatField.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductDeclarationFloatFieldMutationInput[]
            | NodeType.UpdateProductDeclarationFloatFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationFloatField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationFloatField.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDeclarationIntegerField ***************** //

// ProductDeclarationIntegerField: Mutator

export type TDataProductDeclarationIntegerField = {
    ProductDeclarationIntegerField: NodeType.ProductDeclarationIntegerFieldMutationPayload;
};
export type TVariablesProductDeclarationIntegerField = { input: NodeType.ProductDeclarationIntegerFieldMutationInput };
export type MutationHookOptionsProductDeclarationIntegerField = MutationHookOptions<
    TDataProductDeclarationIntegerField,
    TVariablesProductDeclarationIntegerField
>;

export const useMutationProductDeclarationIntegerField = (
    options?: MutationHookOptionsProductDeclarationIntegerField
) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDeclarationIntegerField');
    const [mutate, mutationResult] = useMutation<
        TDataProductDeclarationIntegerField,
        TVariablesProductDeclarationIntegerField
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductDeclarationIntegerFieldMutationInput[]
            | NodeType.CreateProductDeclarationIntegerFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationIntegerField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationIntegerField.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductDeclarationIntegerFieldMutationInput[]
            | NodeType.DeleteProductDeclarationIntegerFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationIntegerField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationIntegerField.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductDeclarationIntegerFieldMutationInput[]
            | NodeType.UpdateProductDeclarationIntegerFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationIntegerField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationIntegerField.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDeclarationMeasurementField ***************** //

// ProductDeclarationMeasurementField: Mutator

export type TDataProductDeclarationMeasurementField = {
    ProductDeclarationMeasurementField: NodeType.ProductDeclarationMeasurementFieldMutationPayload;
};
export type TVariablesProductDeclarationMeasurementField = {
    input: NodeType.ProductDeclarationMeasurementFieldMutationInput;
};
export type MutationHookOptionsProductDeclarationMeasurementField = MutationHookOptions<
    TDataProductDeclarationMeasurementField,
    TVariablesProductDeclarationMeasurementField
>;

export const useMutationProductDeclarationMeasurementField = (
    options?: MutationHookOptionsProductDeclarationMeasurementField
) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDeclarationMeasurementField');
    const [mutate, mutationResult] = useMutation<
        TDataProductDeclarationMeasurementField,
        TVariablesProductDeclarationMeasurementField
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductDeclarationMeasurementFieldMutationInput[]
            | NodeType.CreateProductDeclarationMeasurementFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationMeasurementField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationMeasurementField.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductDeclarationMeasurementFieldMutationInput[]
            | NodeType.DeleteProductDeclarationMeasurementFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationMeasurementField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationMeasurementField.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductDeclarationMeasurementFieldMutationInput[]
            | NodeType.UpdateProductDeclarationMeasurementFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationMeasurementField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationMeasurementField.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDeclarationSelectionChoice ***************** //

// ProductDeclarationSelectionChoice: Mutator

export type TDataProductDeclarationSelectionChoice = {
    ProductDeclarationSelectionChoice: NodeType.ProductDeclarationSelectionChoiceMutationPayload;
};
export type TVariablesProductDeclarationSelectionChoice = {
    input: NodeType.ProductDeclarationSelectionChoiceMutationInput;
};
export type MutationHookOptionsProductDeclarationSelectionChoice = MutationHookOptions<
    TDataProductDeclarationSelectionChoice,
    TVariablesProductDeclarationSelectionChoice
>;

export const useMutationProductDeclarationSelectionChoice = (
    options?: MutationHookOptionsProductDeclarationSelectionChoice
) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDeclarationSelectionChoice');
    const [mutate, mutationResult] = useMutation<
        TDataProductDeclarationSelectionChoice,
        TVariablesProductDeclarationSelectionChoice
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductDeclarationSelectionChoiceMutationInput[]
            | NodeType.CreateProductDeclarationSelectionChoiceMutationInput,
        options?: MutationHookOptionsProductDeclarationSelectionChoice
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationSelectionChoice.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductDeclarationSelectionChoiceMutationInput[]
            | NodeType.DeleteProductDeclarationSelectionChoiceMutationInput,
        options?: MutationHookOptionsProductDeclarationSelectionChoice
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationSelectionChoice.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductDeclarationSelectionChoiceMutationInput[]
            | NodeType.UpdateProductDeclarationSelectionChoiceMutationInput,
        options?: MutationHookOptionsProductDeclarationSelectionChoice
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationSelectionChoice.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDeclarationSelectionField ***************** //

// ProductDeclarationSelectionField: Mutator

export type TDataProductDeclarationSelectionField = {
    ProductDeclarationSelectionField: NodeType.ProductDeclarationSelectionFieldMutationPayload;
};
export type TVariablesProductDeclarationSelectionField = {
    input: NodeType.ProductDeclarationSelectionFieldMutationInput;
};
export type MutationHookOptionsProductDeclarationSelectionField = MutationHookOptions<
    TDataProductDeclarationSelectionField,
    TVariablesProductDeclarationSelectionField
>;

export const useMutationProductDeclarationSelectionField = (
    options?: MutationHookOptionsProductDeclarationSelectionField
) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDeclarationSelectionField');
    const [mutate, mutationResult] = useMutation<
        TDataProductDeclarationSelectionField,
        TVariablesProductDeclarationSelectionField
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductDeclarationSelectionFieldMutationInput[]
            | NodeType.CreateProductDeclarationSelectionFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationSelectionField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationSelectionField.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductDeclarationSelectionFieldMutationInput[]
            | NodeType.DeleteProductDeclarationSelectionFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationSelectionField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationSelectionField.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductDeclarationSelectionFieldMutationInput[]
            | NodeType.UpdateProductDeclarationSelectionFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationSelectionField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationSelectionField.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDeclarationStringField ***************** //

// ProductDeclarationStringField: Mutator

export type TDataProductDeclarationStringField = {
    ProductDeclarationStringField: NodeType.ProductDeclarationStringFieldMutationPayload;
};
export type TVariablesProductDeclarationStringField = { input: NodeType.ProductDeclarationStringFieldMutationInput };
export type MutationHookOptionsProductDeclarationStringField = MutationHookOptions<
    TDataProductDeclarationStringField,
    TVariablesProductDeclarationStringField
>;

export const useMutationProductDeclarationStringField = (
    options?: MutationHookOptionsProductDeclarationStringField
) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDeclarationStringField');
    const [mutate, mutationResult] = useMutation<
        TDataProductDeclarationStringField,
        TVariablesProductDeclarationStringField
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductDeclarationStringFieldMutationInput[]
            | NodeType.CreateProductDeclarationStringFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationStringField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationStringField.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductDeclarationStringFieldMutationInput[]
            | NodeType.DeleteProductDeclarationStringFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationStringField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationStringField.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductDeclarationStringFieldMutationInput[]
            | NodeType.UpdateProductDeclarationStringFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationStringField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationStringField.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDeclarationUnitField ***************** //

// ProductDeclarationUnitField: Mutator

export type TDataProductDeclarationUnitField = {
    ProductDeclarationUnitField: NodeType.ProductDeclarationUnitFieldMutationPayload;
};
export type TVariablesProductDeclarationUnitField = { input: NodeType.ProductDeclarationUnitFieldMutationInput };
export type MutationHookOptionsProductDeclarationUnitField = MutationHookOptions<
    TDataProductDeclarationUnitField,
    TVariablesProductDeclarationUnitField
>;

export const useMutationProductDeclarationUnitField = (options?: MutationHookOptionsProductDeclarationUnitField) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDeclarationUnitField');
    const [mutate, mutationResult] = useMutation<
        TDataProductDeclarationUnitField,
        TVariablesProductDeclarationUnitField
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductDeclarationUnitFieldMutationInput[]
            | NodeType.CreateProductDeclarationUnitFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationUnitField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationUnitField.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductDeclarationUnitFieldMutationInput[]
            | NodeType.DeleteProductDeclarationUnitFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationUnitField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationUnitField.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductDeclarationUnitFieldMutationInput[]
            | NodeType.UpdateProductDeclarationUnitFieldMutationInput,
        options?: MutationHookOptionsProductDeclarationUnitField
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDeclarationUnitField.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductDocument ***************** //

// ProductDocument: Mutator

export type TDataProductDocument = { ProductDocument: NodeType.ProductDocumentMutationPayload };
export type TVariablesProductDocument = { input: NodeType.ProductDocumentMutationInput };
export type MutationHookOptionsProductDocument = MutationHookOptions<TDataProductDocument, TVariablesProductDocument>;

export const useMutationProductDocument = (options?: MutationHookOptionsProductDocument) => {
    const QL = options?.mutation || getMutationQueryByName('ProductDocument');
    const [mutate, mutationResult] = useMutation<TDataProductDocument, TVariablesProductDocument>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteProductDocumentMutationInput[] | NodeType.DeleteProductDocumentMutationInput,
        options?: MutationHookOptionsProductDocument
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDocument.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateProductDocumentMutationInput[] | NodeType.UpdateProductDocumentMutationInput,
        options?: MutationHookOptionsProductDocument
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductDocument.update'
        );
    };

    return {
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTrait ***************** //

// ProductTrait: Mutator

export type TDataProductTrait = { ProductTrait: NodeType.ProductTraitMutationPayload };
export type TVariablesProductTrait = { input: NodeType.ProductTraitMutationInput };
export type MutationHookOptionsProductTrait = MutationHookOptions<TDataProductTrait, TVariablesProductTrait>;

export const useMutationProductTrait = (options?: MutationHookOptionsProductTrait) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTrait');
    const [mutate, mutationResult] = useMutation<TDataProductTrait, TVariablesProductTrait>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateProductTraitMutationInput[] | NodeType.CreateProductTraitMutationInput,
        options?: MutationHookOptionsProductTrait
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTrait.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteProductTraitMutationInput[] | NodeType.DeleteProductTraitMutationInput,
        options?: MutationHookOptionsProductTrait
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTrait.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateProductTraitMutationInput[] | NodeType.UpdateProductTraitMutationInput,
        options?: MutationHookOptionsProductTrait
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTrait.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTraitDeclaration ***************** //

// ProductTraitDeclaration: Mutator

export type TDataProductTraitDeclaration = { ProductTraitDeclaration: NodeType.ProductTraitDeclarationMutationPayload };
export type TVariablesProductTraitDeclaration = { input: NodeType.ProductTraitDeclarationMutationInput };
export type MutationHookOptionsProductTraitDeclaration = MutationHookOptions<
    TDataProductTraitDeclaration,
    TVariablesProductTraitDeclaration
>;

export const useMutationProductTraitDeclaration = (options?: MutationHookOptionsProductTraitDeclaration) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTraitDeclaration');
    const [mutate, mutationResult] = useMutation<TDataProductTraitDeclaration, TVariablesProductTraitDeclaration>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreateProductTraitDeclarationMutationInput[]
            | NodeType.CreateProductTraitDeclarationMutationInput,
        options?: MutationHookOptionsProductTraitDeclaration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitDeclaration.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductTraitDeclarationMutationInput[]
            | NodeType.DeleteProductTraitDeclarationMutationInput,
        options?: MutationHookOptionsProductTraitDeclaration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitDeclaration.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductTraitDeclarationMutationInput[]
            | NodeType.UpdateProductTraitDeclarationMutationInput,
        options?: MutationHookOptionsProductTraitDeclaration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitDeclaration.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTraitFlagValue ***************** //

// ProductTraitFlagValue: Mutator

export type TDataProductTraitFlagValue = { ProductTraitFlagValue: NodeType.ProductTraitFlagValueMutationPayload };
export type TVariablesProductTraitFlagValue = { input: NodeType.ProductTraitFlagValueMutationInput };
export type MutationHookOptionsProductTraitFlagValue = MutationHookOptions<
    TDataProductTraitFlagValue,
    TVariablesProductTraitFlagValue
>;

export const useMutationProductTraitFlagValue = (options?: MutationHookOptionsProductTraitFlagValue) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTraitFlagValue');
    const [mutate, mutationResult] = useMutation<TDataProductTraitFlagValue, TVariablesProductTraitFlagValue>(
        QL,
        options
    );

    const mutateAssign = (
        input: NodeType.ProductTraitFlagValueAssignMutationInput[] | NodeType.ProductTraitFlagValueAssignMutationInput,
        options?: MutationHookOptionsProductTraitFlagValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        assign: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitFlagValue.assign'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateProductTraitFlagValueMutationInput[] | NodeType.CreateProductTraitFlagValueMutationInput,
        options?: MutationHookOptionsProductTraitFlagValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitFlagValue.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteProductTraitFlagValueMutationInput[] | NodeType.DeleteProductTraitFlagValueMutationInput,
        options?: MutationHookOptionsProductTraitFlagValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitFlagValue.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateProductTraitFlagValueMutationInput[] | NodeType.UpdateProductTraitFlagValueMutationInput,
        options?: MutationHookOptionsProductTraitFlagValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitFlagValue.update'
        );
    };

    return {
        assign: mutateAssign,
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTraitFloatValue ***************** //

// ProductTraitFloatValue: Mutator

export type TDataProductTraitFloatValue = { ProductTraitFloatValue: NodeType.ProductTraitFloatValueMutationPayload };
export type TVariablesProductTraitFloatValue = { input: NodeType.ProductTraitFloatValueMutationInput };
export type MutationHookOptionsProductTraitFloatValue = MutationHookOptions<
    TDataProductTraitFloatValue,
    TVariablesProductTraitFloatValue
>;

export const useMutationProductTraitFloatValue = (options?: MutationHookOptionsProductTraitFloatValue) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTraitFloatValue');
    const [mutate, mutationResult] = useMutation<TDataProductTraitFloatValue, TVariablesProductTraitFloatValue>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreateProductTraitFloatValueMutationInput[]
            | NodeType.CreateProductTraitFloatValueMutationInput,
        options?: MutationHookOptionsProductTraitFloatValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitFloatValue.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductTraitFloatValueMutationInput[]
            | NodeType.DeleteProductTraitFloatValueMutationInput,
        options?: MutationHookOptionsProductTraitFloatValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitFloatValue.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductTraitFloatValueMutationInput[]
            | NodeType.UpdateProductTraitFloatValueMutationInput,
        options?: MutationHookOptionsProductTraitFloatValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitFloatValue.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTraitIntegerValue ***************** //

// ProductTraitIntegerValue: Mutator

export type TDataProductTraitIntegerValue = {
    ProductTraitIntegerValue: NodeType.ProductTraitIntegerValueMutationPayload;
};
export type TVariablesProductTraitIntegerValue = { input: NodeType.ProductTraitIntegerValueMutationInput };
export type MutationHookOptionsProductTraitIntegerValue = MutationHookOptions<
    TDataProductTraitIntegerValue,
    TVariablesProductTraitIntegerValue
>;

export const useMutationProductTraitIntegerValue = (options?: MutationHookOptionsProductTraitIntegerValue) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTraitIntegerValue');
    const [mutate, mutationResult] = useMutation<TDataProductTraitIntegerValue, TVariablesProductTraitIntegerValue>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreateProductTraitIntegerValueMutationInput[]
            | NodeType.CreateProductTraitIntegerValueMutationInput,
        options?: MutationHookOptionsProductTraitIntegerValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitIntegerValue.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductTraitIntegerValueMutationInput[]
            | NodeType.DeleteProductTraitIntegerValueMutationInput,
        options?: MutationHookOptionsProductTraitIntegerValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitIntegerValue.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductTraitIntegerValueMutationInput[]
            | NodeType.UpdateProductTraitIntegerValueMutationInput,
        options?: MutationHookOptionsProductTraitIntegerValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitIntegerValue.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTraitMeasurementValue ***************** //

// ProductTraitMeasurementValue: Mutator

export type TDataProductTraitMeasurementValue = {
    ProductTraitMeasurementValue: NodeType.ProductTraitMeasurementValueMutationPayload;
};
export type TVariablesProductTraitMeasurementValue = { input: NodeType.ProductTraitMeasurementValueMutationInput };
export type MutationHookOptionsProductTraitMeasurementValue = MutationHookOptions<
    TDataProductTraitMeasurementValue,
    TVariablesProductTraitMeasurementValue
>;

export const useMutationProductTraitMeasurementValue = (options?: MutationHookOptionsProductTraitMeasurementValue) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTraitMeasurementValue');
    const [mutate, mutationResult] = useMutation<
        TDataProductTraitMeasurementValue,
        TVariablesProductTraitMeasurementValue
    >(QL, options);

    const mutateCreate = (
        input:
            | NodeType.CreateProductTraitMeasurementValueMutationInput[]
            | NodeType.CreateProductTraitMeasurementValueMutationInput,
        options?: MutationHookOptionsProductTraitMeasurementValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitMeasurementValue.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductTraitMeasurementValueMutationInput[]
            | NodeType.DeleteProductTraitMeasurementValueMutationInput,
        options?: MutationHookOptionsProductTraitMeasurementValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitMeasurementValue.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductTraitMeasurementValueMutationInput[]
            | NodeType.UpdateProductTraitMeasurementValueMutationInput,
        options?: MutationHookOptionsProductTraitMeasurementValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitMeasurementValue.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTraitSelectionValue ***************** //

// ProductTraitSelectionValue: Mutator

export type TDataProductTraitSelectionValue = {
    ProductTraitSelectionValue: NodeType.ProductTraitSelectionValueMutationPayload;
};
export type TVariablesProductTraitSelectionValue = { input: NodeType.ProductTraitSelectionValueMutationInput };
export type MutationHookOptionsProductTraitSelectionValue = MutationHookOptions<
    TDataProductTraitSelectionValue,
    TVariablesProductTraitSelectionValue
>;

export const useMutationProductTraitSelectionValue = (options?: MutationHookOptionsProductTraitSelectionValue) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTraitSelectionValue');
    const [mutate, mutationResult] = useMutation<TDataProductTraitSelectionValue, TVariablesProductTraitSelectionValue>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreateProductTraitSelectionValueMutationInput[]
            | NodeType.CreateProductTraitSelectionValueMutationInput,
        options?: MutationHookOptionsProductTraitSelectionValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitSelectionValue.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductTraitSelectionValueMutationInput[]
            | NodeType.DeleteProductTraitSelectionValueMutationInput,
        options?: MutationHookOptionsProductTraitSelectionValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitSelectionValue.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductTraitSelectionValueMutationInput[]
            | NodeType.UpdateProductTraitSelectionValueMutationInput,
        options?: MutationHookOptionsProductTraitSelectionValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitSelectionValue.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTraitStringValue ***************** //

// ProductTraitStringValue: Mutator

export type TDataProductTraitStringValue = { ProductTraitStringValue: NodeType.ProductTraitStringValueMutationPayload };
export type TVariablesProductTraitStringValue = { input: NodeType.ProductTraitStringValueMutationInput };
export type MutationHookOptionsProductTraitStringValue = MutationHookOptions<
    TDataProductTraitStringValue,
    TVariablesProductTraitStringValue
>;

export const useMutationProductTraitStringValue = (options?: MutationHookOptionsProductTraitStringValue) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTraitStringValue');
    const [mutate, mutationResult] = useMutation<TDataProductTraitStringValue, TVariablesProductTraitStringValue>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreateProductTraitStringValueMutationInput[]
            | NodeType.CreateProductTraitStringValueMutationInput,
        options?: MutationHookOptionsProductTraitStringValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitStringValue.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteProductTraitStringValueMutationInput[]
            | NodeType.DeleteProductTraitStringValueMutationInput,
        options?: MutationHookOptionsProductTraitStringValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitStringValue.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateProductTraitStringValueMutationInput[]
            | NodeType.UpdateProductTraitStringValueMutationInput,
        options?: MutationHookOptionsProductTraitStringValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitStringValue.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProductTraitUnitValue ***************** //

// ProductTraitUnitValue: Mutator

export type TDataProductTraitUnitValue = { ProductTraitUnitValue: NodeType.ProductTraitUnitValueMutationPayload };
export type TVariablesProductTraitUnitValue = { input: NodeType.ProductTraitUnitValueMutationInput };
export type MutationHookOptionsProductTraitUnitValue = MutationHookOptions<
    TDataProductTraitUnitValue,
    TVariablesProductTraitUnitValue
>;

export const useMutationProductTraitUnitValue = (options?: MutationHookOptionsProductTraitUnitValue) => {
    const QL = options?.mutation || getMutationQueryByName('ProductTraitUnitValue');
    const [mutate, mutationResult] = useMutation<TDataProductTraitUnitValue, TVariablesProductTraitUnitValue>(
        QL,
        options
    );

    const mutateCreate = (
        input: NodeType.CreateProductTraitUnitValueMutationInput[] | NodeType.CreateProductTraitUnitValueMutationInput,
        options?: MutationHookOptionsProductTraitUnitValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitUnitValue.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteProductTraitUnitValueMutationInput[] | NodeType.DeleteProductTraitUnitValueMutationInput,
        options?: MutationHookOptionsProductTraitUnitValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitUnitValue.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateProductTraitUnitValueMutationInput[] | NodeType.UpdateProductTraitUnitValueMutationInput,
        options?: MutationHookOptionsProductTraitUnitValue
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProductTraitUnitValue.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Project ***************** //

// Project: Mutator

export type TDataProject = { Project: NodeType.ProjectMutationPayload };
export type TVariablesProject = { input: NodeType.ProjectMutationInput };
export type MutationHookOptionsProject = MutationHookOptions<TDataProject, TVariablesProject>;

export const useMutationProject = (options?: MutationHookOptionsProject) => {
    const QL = options?.mutation || getMutationQueryByName('Project');
    const [mutate, mutationResult] = useMutation<TDataProject, TVariablesProject>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateProjectMutationInput[] | NodeType.CreateProjectMutationInput,
        options?: MutationHookOptionsProject
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Project.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteProjectMutationInput[] | NodeType.DeleteProjectMutationInput,
        options?: MutationHookOptionsProject
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Project.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateProjectMutationInput[] | NodeType.UpdateProjectMutationInput,
        options?: MutationHookOptionsProject
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Project.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ProjectVersion ***************** //

// ProjectVersion: Mutator

export type TDataProjectVersion = { ProjectVersion: NodeType.ProjectVersionMutationPayload };
export type TVariablesProjectVersion = { input: NodeType.ProjectVersionMutationInput };
export type MutationHookOptionsProjectVersion = MutationHookOptions<TDataProjectVersion, TVariablesProjectVersion>;

export const useMutationProjectVersion = (options?: MutationHookOptionsProjectVersion) => {
    const QL = options?.mutation || getMutationQueryByName('ProjectVersion');
    const [mutate, mutationResult] = useMutation<TDataProjectVersion, TVariablesProjectVersion>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateProjectVersionMutationInput[] | NodeType.CreateProjectVersionMutationInput,
        options?: MutationHookOptionsProjectVersion
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProjectVersion.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteProjectVersionMutationInput[] | NodeType.DeleteProjectVersionMutationInput,
        options?: MutationHookOptionsProjectVersion
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProjectVersion.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateProjectVersionMutationInput[] | NodeType.UpdateProjectVersionMutationInput,
        options?: MutationHookOptionsProjectVersion
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ProjectVersion.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** RecommendedTreatment ***************** //

// RecommendedTreatment: Mutator

export type TDataRecommendedTreatment = { RecommendedTreatment: NodeType.RecommendedTreatmentMutationPayload };
export type TVariablesRecommendedTreatment = { input: NodeType.RecommendedTreatmentMutationInput };
export type MutationHookOptionsRecommendedTreatment = MutationHookOptions<
    TDataRecommendedTreatment,
    TVariablesRecommendedTreatment
>;

export const useMutationRecommendedTreatment = (options?: MutationHookOptionsRecommendedTreatment) => {
    const QL = options?.mutation || getMutationQueryByName('RecommendedTreatment');
    const [mutate, mutationResult] = useMutation<TDataRecommendedTreatment, TVariablesRecommendedTreatment>(
        QL,
        options
    );

    const mutateOverride = (
        input:
            | NodeType.RecommendedTreatmentOverrideMutationInput[]
            | NodeType.RecommendedTreatmentOverrideMutationInput,
        options?: MutationHookOptionsRecommendedTreatment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        override: input as any,
                    },
                },
            },
            // @ts-ignore
            'RecommendedTreatment.override'
        );
    };
    const mutateReset = (
        input: NodeType.RecommendedTreatmentResetMutationInput[] | NodeType.RecommendedTreatmentResetMutationInput,
        options?: MutationHookOptionsRecommendedTreatment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        reset: input as any,
                    },
                },
            },
            // @ts-ignore
            'RecommendedTreatment.reset'
        );
    };

    return {
        override: mutateOverride,
        reset: mutateReset,
        mutate,
        mutationResult,
    };
};

// ************** Role ***************** //

// Role: Mutator

export type TDataRole = { Role: NodeType.RoleMutationPayload };
export type TVariablesRole = { input: NodeType.RoleMutationInput };
export type MutationHookOptionsRole = MutationHookOptions<TDataRole, TVariablesRole>;

export const useMutationRole = (options?: MutationHookOptionsRole) => {
    const QL = options?.mutation || getMutationQueryByName('Role');
    const [mutate, mutationResult] = useMutation<TDataRole, TVariablesRole>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateRoleMutationInput[] | NodeType.CreateRoleMutationInput,
        options?: MutationHookOptionsRole
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Role.create'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateRoleMutationInput[] | NodeType.UpdateRoleMutationInput,
        options?: MutationHookOptionsRole
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Role.update'
        );
    };

    return {
        create: mutateCreate,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** RoleFlag ***************** //

// RoleFlag: Mutator

export type TDataRoleFlag = { RoleFlag: NodeType.RoleFlagMutationPayload };
export type TVariablesRoleFlag = { input: NodeType.RoleFlagMutationInput };
export type MutationHookOptionsRoleFlag = MutationHookOptions<TDataRoleFlag, TVariablesRoleFlag>;

export const useMutationRoleFlag = (options?: MutationHookOptionsRoleFlag) => {
    const QL = options?.mutation || getMutationQueryByName('RoleFlag');
    const [mutate, mutationResult] = useMutation<TDataRoleFlag, TVariablesRoleFlag>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateRoleFlagMutationInput[] | NodeType.CreateRoleFlagMutationInput,
        options?: MutationHookOptionsRoleFlag
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'RoleFlag.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteRoleFlagMutationInput[] | NodeType.DeleteRoleFlagMutationInput,
        options?: MutationHookOptionsRoleFlag
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'RoleFlag.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateRoleFlagMutationInput[] | NodeType.UpdateRoleFlagMutationInput,
        options?: MutationHookOptionsRoleFlag
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'RoleFlag.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Sample ***************** //

// Sample: Mutator

export type TDataSample = { Sample: NodeType.SampleMutationPayload };
export type TVariablesSample = { input: NodeType.SampleMutationInput };
export type MutationHookOptionsSample = MutationHookOptions<TDataSample, TVariablesSample>;

export const useMutationSample = (options?: MutationHookOptionsSample) => {
    const QL = options?.mutation || getMutationQueryByName('Sample');
    const [mutate, mutationResult] = useMutation<TDataSample, TVariablesSample>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateSampleMutationInput[] | NodeType.CreateSampleMutationInput,
        options?: MutationHookOptionsSample
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Sample.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteSampleMutationInput[] | NodeType.DeleteSampleMutationInput,
        options?: MutationHookOptionsSample
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Sample.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateSampleMutationInput[] | NodeType.UpdateSampleMutationInput,
        options?: MutationHookOptionsSample
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Sample.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** SampleConverter ***************** //

// SampleConverter: Mutator

export type TDataSampleConverter = { SampleConverter: NodeType.SampleConverterMutationPayload };
export type TVariablesSampleConverter = { input: NodeType.SampleConverterMutationInput };
export type MutationHookOptionsSampleConverter = MutationHookOptions<TDataSampleConverter, TVariablesSampleConverter>;

export const useMutationSampleConverter = (options?: MutationHookOptionsSampleConverter) => {
    const QL = options?.mutation || getMutationQueryByName('SampleConverter');
    const [mutate, mutationResult] = useMutation<TDataSampleConverter, TVariablesSampleConverter>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateSampleConverterMutationInput[] | NodeType.CreateSampleConverterMutationInput,
        options?: MutationHookOptionsSampleConverter
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleConverter.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteSampleConverterMutationInput[] | NodeType.DeleteSampleConverterMutationInput,
        options?: MutationHookOptionsSampleConverter
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleConverter.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateSampleConverterMutationInput[] | NodeType.UpdateSampleConverterMutationInput,
        options?: MutationHookOptionsSampleConverter
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleConverter.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** SampleConverterTemplate ***************** //

// SampleConverterTemplate: Mutator

export type TDataSampleConverterTemplate = { SampleConverterTemplate: NodeType.SampleConverterTemplateMutationPayload };
export type TVariablesSampleConverterTemplate = { input: NodeType.SampleConverterTemplateMutationInput };
export type MutationHookOptionsSampleConverterTemplate = MutationHookOptions<
    TDataSampleConverterTemplate,
    TVariablesSampleConverterTemplate
>;

export const useMutationSampleConverterTemplate = (options?: MutationHookOptionsSampleConverterTemplate) => {
    const QL = options?.mutation || getMutationQueryByName('SampleConverterTemplate');
    const [mutate, mutationResult] = useMutation<TDataSampleConverterTemplate, TVariablesSampleConverterTemplate>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreateSampleConverterTemplateMutationInput[]
            | NodeType.CreateSampleConverterTemplateMutationInput,
        options?: MutationHookOptionsSampleConverterTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleConverterTemplate.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteSampleConverterTemplateMutationInput[]
            | NodeType.DeleteSampleConverterTemplateMutationInput,
        options?: MutationHookOptionsSampleConverterTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleConverterTemplate.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateSampleConverterTemplateMutationInput[]
            | NodeType.UpdateSampleConverterTemplateMutationInput,
        options?: MutationHookOptionsSampleConverterTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleConverterTemplate.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** SampleSet ***************** //

// SampleSet: Mutator

export type TDataSampleSet = { SampleSet: NodeType.SampleSetMutationPayload };
export type TVariablesSampleSet = { input: NodeType.SampleSetMutationInput };
export type MutationHookOptionsSampleSet = MutationHookOptions<TDataSampleSet, TVariablesSampleSet>;

export const useMutationSampleSet = (options?: MutationHookOptionsSampleSet) => {
    const QL = options?.mutation || getMutationQueryByName('SampleSet');
    const [mutate, mutationResult] = useMutation<TDataSampleSet, TVariablesSampleSet>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateSampleSetMutationInput[] | NodeType.CreateSampleSetMutationInput,
        options?: MutationHookOptionsSampleSet
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleSet.create'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateSampleSetMutationInput[] | NodeType.UpdateSampleSetMutationInput,
        options?: MutationHookOptionsSampleSet
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleSet.update'
        );
    };

    return {
        create: mutateCreate,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** SampleSource ***************** //

// SampleSource: Mutator

export type TDataSampleSource = { SampleSource: NodeType.SampleSourceMutationPayload };
export type TVariablesSampleSource = { input: NodeType.SampleSourceMutationInput };
export type MutationHookOptionsSampleSource = MutationHookOptions<TDataSampleSource, TVariablesSampleSource>;

export const useMutationSampleSource = (options?: MutationHookOptionsSampleSource) => {
    const QL = options?.mutation || getMutationQueryByName('SampleSource');
    const [mutate, mutationResult] = useMutation<TDataSampleSource, TVariablesSampleSource>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateSampleSourceMutationInput[] | NodeType.CreateSampleSourceMutationInput,
        options?: MutationHookOptionsSampleSource
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleSource.create'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateSampleSourceMutationInput[] | NodeType.UpdateSampleSourceMutationInput,
        options?: MutationHookOptionsSampleSource
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'SampleSource.update'
        );
    };

    return {
        create: mutateCreate,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Sanitiser ***************** //

// Sanitiser: Mutator

export type TDataSanitiser = { Sanitiser: NodeType.SanitiserMutationPayload };
export type TVariablesSanitiser = { input: NodeType.SanitiserMutationInput };
export type MutationHookOptionsSanitiser = MutationHookOptions<TDataSanitiser, TVariablesSanitiser>;

export const useMutationSanitiser = (options?: MutationHookOptionsSanitiser) => {
    const QL = options?.mutation || getMutationQueryByName('Sanitiser');
    const [mutate, mutationResult] = useMutation<TDataSanitiser, TVariablesSanitiser>(QL, options);

    const mutateChangePublishedStatus = (
        input:
            | NodeType.SanitiserChangePublishedStatusMutationInput[]
            | NodeType.SanitiserChangePublishedStatusMutationInput,
        options?: MutationHookOptionsSanitiser
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        changePublishedStatus: input as any,
                    },
                },
            },
            // @ts-ignore
            'Sanitiser.changePublishedStatus'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateSanitiserMutationInput[] | NodeType.CreateSanitiserMutationInput,
        options?: MutationHookOptionsSanitiser
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Sanitiser.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteSanitiserMutationInput[] | NodeType.DeleteSanitiserMutationInput,
        options?: MutationHookOptionsSanitiser
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Sanitiser.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateSanitiserMutationInput[] | NodeType.UpdateSanitiserMutationInput,
        options?: MutationHookOptionsSanitiser
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Sanitiser.update'
        );
    };

    return {
        changePublishedStatus: mutateChangePublishedStatus,
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ScheduleEvent ***************** //

// ScheduleEvent: Mutator

export type TDataScheduleEvent = { ScheduleEvent: NodeType.ScheduleEventMutationPayload };
export type TVariablesScheduleEvent = { input: NodeType.ScheduleEventMutationInput };
export type MutationHookOptionsScheduleEvent = MutationHookOptions<TDataScheduleEvent, TVariablesScheduleEvent>;

export const useMutationScheduleEvent = (options?: MutationHookOptionsScheduleEvent) => {
    const QL = options?.mutation || getMutationQueryByName('ScheduleEvent');
    const [mutate, mutationResult] = useMutation<TDataScheduleEvent, TVariablesScheduleEvent>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteScheduleEventMutationInput[] | NodeType.DeleteScheduleEventMutationInput,
        options?: MutationHookOptionsScheduleEvent
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ScheduleEvent.delete'
        );
    };

    return {
        delete: mutateDelete,
        mutate,
        mutationResult,
    };
};

// ************** ServiceJob ***************** //

// ServiceJob: Mutator

export type TDataServiceJob = { ServiceJob: NodeType.ServiceJobMutationPayload };
export type TVariablesServiceJob = { input: NodeType.ServiceJobMutationInput };
export type MutationHookOptionsServiceJob = MutationHookOptions<TDataServiceJob, TVariablesServiceJob>;

export const useMutationServiceJob = (options?: MutationHookOptionsServiceJob) => {
    const QL = options?.mutation || getMutationQueryByName('ServiceJob');
    const [mutate, mutationResult] = useMutation<TDataServiceJob, TVariablesServiceJob>(QL, options);

    const mutateArchive = (
        input: NodeType.ServiceJobArchiveMutationInput[] | NodeType.ServiceJobArchiveMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        archive: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.archive'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateServiceJobMutationInput[] | NodeType.CreateServiceJobMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.create'
        );
    };
    const mutateCreateForRecurrentAppointment = (
        input:
            | NodeType.ServiceJobCreateForRecurrentAppointmentMutationInput[]
            | NodeType.ServiceJobCreateForRecurrentAppointmentMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        createForRecurrentAppointment: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.createForRecurrentAppointment'
        );
    };
    const mutateCreateForSingleAppointment = (
        input:
            | NodeType.ServiceJobCreateForSingleAppointmentMutationInput[]
            | NodeType.ServiceJobCreateForSingleAppointmentMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        createForSingleAppointment: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.createForSingleAppointment'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteServiceJobMutationInput[] | NodeType.DeleteServiceJobMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.delete'
        );
    };
    const mutateSetStopDate = (
        input: NodeType.ServiceJobSetStopDateMutationInput[] | NodeType.ServiceJobSetStopDateMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setStopDate: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.setStopDate'
        );
    };
    const mutateSplitRecurrentJob = (
        input: NodeType.ServiceJobSplitRecurrentJobMutationInput[] | NodeType.ServiceJobSplitRecurrentJobMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        splitRecurrentJob: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.splitRecurrentJob'
        );
    };
    const mutateUnArchive = (
        input: NodeType.ServiceJobUnArchiveMutationInput[] | NodeType.ServiceJobUnArchiveMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        unArchive: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.unArchive'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateServiceJobMutationInput[] | NodeType.UpdateServiceJobMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.update'
        );
    };
    const mutateUpdateRecurringJob = (
        input:
            | NodeType.ServiceJobUpdateRecurringJobMutationInput[]
            | NodeType.ServiceJobUpdateRecurringJobMutationInput,
        options?: MutationHookOptionsServiceJob
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        updateRecurringJob: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJob.updateRecurringJob'
        );
    };

    return {
        archive: mutateArchive,
        create: mutateCreate,
        createForRecurrentAppointment: mutateCreateForRecurrentAppointment,
        createForSingleAppointment: mutateCreateForSingleAppointment,
        delete: mutateDelete,
        setStopDate: mutateSetStopDate,
        splitRecurrentJob: mutateSplitRecurrentJob,
        unArchive: mutateUnArchive,
        update: mutateUpdate,
        updateRecurringJob: mutateUpdateRecurringJob,
        mutate,
        mutationResult,
    };
};

// ************** ServiceJobGroup ***************** //

// ServiceJobGroup: Mutator

export type TDataServiceJobGroup = { ServiceJobGroup: NodeType.ServiceJobGroupMutationPayload };
export type TVariablesServiceJobGroup = { input: NodeType.ServiceJobGroupMutationInput };
export type MutationHookOptionsServiceJobGroup = MutationHookOptions<TDataServiceJobGroup, TVariablesServiceJobGroup>;

export const useMutationServiceJobGroup = (options?: MutationHookOptionsServiceJobGroup) => {
    const QL = options?.mutation || getMutationQueryByName('ServiceJobGroup');
    const [mutate, mutationResult] = useMutation<TDataServiceJobGroup, TVariablesServiceJobGroup>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateServiceJobGroupMutationInput[] | NodeType.CreateServiceJobGroupMutationInput,
        options?: MutationHookOptionsServiceJobGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobGroup.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteServiceJobGroupMutationInput[] | NodeType.DeleteServiceJobGroupMutationInput,
        options?: MutationHookOptionsServiceJobGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobGroup.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateServiceJobGroupMutationInput[] | NodeType.UpdateServiceJobGroupMutationInput,
        options?: MutationHookOptionsServiceJobGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobGroup.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ServiceJobNote ***************** //

// ServiceJobNote: Mutator

export type TDataServiceJobNote = { ServiceJobNote: NodeType.ServiceJobNoteMutationPayload };
export type TVariablesServiceJobNote = { input: NodeType.ServiceJobNoteMutationInput };
export type MutationHookOptionsServiceJobNote = MutationHookOptions<TDataServiceJobNote, TVariablesServiceJobNote>;

export const useMutationServiceJobNote = (options?: MutationHookOptionsServiceJobNote) => {
    const QL = options?.mutation || getMutationQueryByName('ServiceJobNote');
    const [mutate, mutationResult] = useMutation<TDataServiceJobNote, TVariablesServiceJobNote>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateServiceJobNoteMutationInput[] | NodeType.CreateServiceJobNoteMutationInput,
        options?: MutationHookOptionsServiceJobNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobNote.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteServiceJobNoteMutationInput[] | NodeType.DeleteServiceJobNoteMutationInput,
        options?: MutationHookOptionsServiceJobNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobNote.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateServiceJobNoteMutationInput[] | NodeType.UpdateServiceJobNoteMutationInput,
        options?: MutationHookOptionsServiceJobNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobNote.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ServiceJobStage ***************** //

// ServiceJobStage: Mutator

export type TDataServiceJobStage = { ServiceJobStage: NodeType.ServiceJobStageMutationPayload };
export type TVariablesServiceJobStage = { input: NodeType.ServiceJobStageMutationInput };
export type MutationHookOptionsServiceJobStage = MutationHookOptions<TDataServiceJobStage, TVariablesServiceJobStage>;

export const useMutationServiceJobStage = (options?: MutationHookOptionsServiceJobStage) => {
    const QL = options?.mutation || getMutationQueryByName('ServiceJobStage');
    const [mutate, mutationResult] = useMutation<TDataServiceJobStage, TVariablesServiceJobStage>(QL, options);

    const mutateArchive = (
        input: NodeType.ServiceJobStageArchiveMutationInput[] | NodeType.ServiceJobStageArchiveMutationInput,
        options?: MutationHookOptionsServiceJobStage
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        archive: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobStage.archive'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateServiceJobStageMutationInput[] | NodeType.CreateServiceJobStageMutationInput,
        options?: MutationHookOptionsServiceJobStage
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobStage.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteServiceJobStageMutationInput[] | NodeType.DeleteServiceJobStageMutationInput,
        options?: MutationHookOptionsServiceJobStage
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobStage.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateServiceJobStageMutationInput[] | NodeType.UpdateServiceJobStageMutationInput,
        options?: MutationHookOptionsServiceJobStage
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobStage.update'
        );
    };

    return {
        archive: mutateArchive,
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** ServiceJobTemplate ***************** //

// ServiceJobTemplate: Mutator

export type TDataServiceJobTemplate = { ServiceJobTemplate: NodeType.ServiceJobTemplateMutationPayload };
export type TVariablesServiceJobTemplate = { input: NodeType.ServiceJobTemplateMutationInput };
export type MutationHookOptionsServiceJobTemplate = MutationHookOptions<
    TDataServiceJobTemplate,
    TVariablesServiceJobTemplate
>;

export const useMutationServiceJobTemplate = (options?: MutationHookOptionsServiceJobTemplate) => {
    const QL = options?.mutation || getMutationQueryByName('ServiceJobTemplate');
    const [mutate, mutationResult] = useMutation<TDataServiceJobTemplate, TVariablesServiceJobTemplate>(QL, options);

    const mutateArchive = (
        input: NodeType.ServiceJobTemplateArchiveMutationInput[] | NodeType.ServiceJobTemplateArchiveMutationInput,
        options?: MutationHookOptionsServiceJobTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        archive: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobTemplate.archive'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateServiceJobTemplateMutationInput[] | NodeType.CreateServiceJobTemplateMutationInput,
        options?: MutationHookOptionsServiceJobTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobTemplate.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteServiceJobTemplateMutationInput[] | NodeType.DeleteServiceJobTemplateMutationInput,
        options?: MutationHookOptionsServiceJobTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobTemplate.delete'
        );
    };
    const mutateSetAsDefault = (
        input:
            | NodeType.ServiceJobTemplateSetAsDefaultMutationInput[]
            | NodeType.ServiceJobTemplateSetAsDefaultMutationInput,
        options?: MutationHookOptionsServiceJobTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setAsDefault: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobTemplate.setAsDefault'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateServiceJobTemplateMutationInput[] | NodeType.UpdateServiceJobTemplateMutationInput,
        options?: MutationHookOptionsServiceJobTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'ServiceJobTemplate.update'
        );
    };

    return {
        archive: mutateArchive,
        create: mutateCreate,
        delete: mutateDelete,
        setAsDefault: mutateSetAsDefault,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** SettingStore ***************** //

// SettingStore: Mutator

export type TDataSettingStore = { SettingStore: NodeType.SettingStoreMutationPayload };
export type TVariablesSettingStore = { input: NodeType.SettingStoreMutationInput };
export type MutationHookOptionsSettingStore = MutationHookOptions<TDataSettingStore, TVariablesSettingStore>;

export const useMutationSettingStore = (options?: MutationHookOptionsSettingStore) => {
    const QL = options?.mutation || getMutationQueryByName('SettingStore');
    const [mutate, mutationResult] = useMutation<TDataSettingStore, TVariablesSettingStore>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateSettingStoreMutationInput[] | NodeType.CreateSettingStoreMutationInput,
        options?: MutationHookOptionsSettingStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'SettingStore.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteSettingStoreMutationInput[] | NodeType.DeleteSettingStoreMutationInput,
        options?: MutationHookOptionsSettingStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'SettingStore.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateSettingStoreMutationInput[] | NodeType.UpdateSettingStoreMutationInput,
        options?: MutationHookOptionsSettingStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'SettingStore.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** SharedEntity ***************** //

// SharedEntity: Mutator

export type TDataSharedEntity = { SharedEntity: NodeType.SharedEntityMutationPayload };
export type TVariablesSharedEntity = { input: NodeType.SharedEntityMutationInput };
export type MutationHookOptionsSharedEntity = MutationHookOptions<TDataSharedEntity, TVariablesSharedEntity>;

export const useMutationSharedEntity = (options?: MutationHookOptionsSharedEntity) => {
    const QL = options?.mutation || getMutationQueryByName('SharedEntity');
    const [mutate, mutationResult] = useMutation<TDataSharedEntity, TVariablesSharedEntity>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateSharedEntityMutationInput[] | NodeType.CreateSharedEntityMutationInput,
        options?: MutationHookOptionsSharedEntity
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'SharedEntity.create'
        );
    };

    return {
        create: mutateCreate,
        mutate,
        mutationResult,
    };
};

// ************** Site ***************** //

// Site: Mutator

export type TDataSite = { Site: NodeType.SiteMutationPayload };
export type TVariablesSite = { input: NodeType.SiteMutationInput };
export type MutationHookOptionsSite = MutationHookOptions<TDataSite, TVariablesSite>;

export const useMutationSite = (options?: MutationHookOptionsSite) => {
    const QL = options?.mutation || getMutationQueryByName('Site');
    const [mutate, mutationResult] = useMutation<TDataSite, TVariablesSite>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateSiteMutationInput[] | NodeType.CreateSiteMutationInput,
        options?: MutationHookOptionsSite
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Site.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteSiteMutationInput[] | NodeType.DeleteSiteMutationInput,
        options?: MutationHookOptionsSite
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Site.delete'
        );
    };
    const mutateTransferTo = (
        input: NodeType.SiteTransferToMutationInput[] | NodeType.SiteTransferToMutationInput,
        options?: MutationHookOptionsSite
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        transferTo: input as any,
                    },
                },
            },
            // @ts-ignore
            'Site.transferTo'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateSiteMutationInput[] | NodeType.UpdateSiteMutationInput,
        options?: MutationHookOptionsSite
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Site.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        transferTo: mutateTransferTo,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** SiteNote ***************** //

// SiteNote: Mutator

export type TDataSiteNote = { SiteNote: NodeType.SiteNoteMutationPayload };
export type TVariablesSiteNote = { input: NodeType.SiteNoteMutationInput };
export type MutationHookOptionsSiteNote = MutationHookOptions<TDataSiteNote, TVariablesSiteNote>;

export const useMutationSiteNote = (options?: MutationHookOptionsSiteNote) => {
    const QL = options?.mutation || getMutationQueryByName('SiteNote');
    const [mutate, mutationResult] = useMutation<TDataSiteNote, TVariablesSiteNote>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateSiteNoteMutationInput[] | NodeType.CreateSiteNoteMutationInput,
        options?: MutationHookOptionsSiteNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'SiteNote.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteSiteNoteMutationInput[] | NodeType.DeleteSiteNoteMutationInput,
        options?: MutationHookOptionsSiteNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'SiteNote.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateSiteNoteMutationInput[] | NodeType.UpdateSiteNoteMutationInput,
        options?: MutationHookOptionsSiteNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'SiteNote.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Staff ***************** //

// Staff: Mutator

export type TDataStaff = { Staff: NodeType.StaffMutationPayload };
export type TVariablesStaff = { input: NodeType.StaffMutationInput };
export type MutationHookOptionsStaff = MutationHookOptions<TDataStaff, TVariablesStaff>;

export const useMutationStaff = (options?: MutationHookOptionsStaff) => {
    const QL = options?.mutation || getMutationQueryByName('Staff');
    const [mutate, mutationResult] = useMutation<TDataStaff, TVariablesStaff>(QL, options);

    const mutateArchive = (
        input: NodeType.StaffArchiveMutationInput[] | NodeType.StaffArchiveMutationInput,
        options?: MutationHookOptionsStaff
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        archive: input as any,
                    },
                },
            },
            // @ts-ignore
            'Staff.archive'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateStaffMutationInput[] | NodeType.CreateStaffMutationInput,
        options?: MutationHookOptionsStaff
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Staff.create'
        );
    };
    const mutateUnarchive = (
        input: NodeType.StaffUnarchiveMutationInput[] | NodeType.StaffUnarchiveMutationInput,
        options?: MutationHookOptionsStaff
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        unarchive: input as any,
                    },
                },
            },
            // @ts-ignore
            'Staff.unarchive'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateStaffMutationInput[] | NodeType.UpdateStaffMutationInput,
        options?: MutationHookOptionsStaff
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Staff.update'
        );
    };

    return {
        archive: mutateArchive,
        create: mutateCreate,
        unarchive: mutateUnarchive,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** StaffAccessToken ***************** //

// StaffAccessToken: Mutator

export type TDataStaffAccessToken = { StaffAccessToken: NodeType.StaffAccessTokenMutationPayload };
export type TVariablesStaffAccessToken = { input: NodeType.StaffAccessTokenMutationInput };
export type MutationHookOptionsStaffAccessToken = MutationHookOptions<
    TDataStaffAccessToken,
    TVariablesStaffAccessToken
>;

export const useMutationStaffAccessToken = (options?: MutationHookOptionsStaffAccessToken) => {
    const QL = options?.mutation || getMutationQueryByName('StaffAccessToken');
    const [mutate, mutationResult] = useMutation<TDataStaffAccessToken, TVariablesStaffAccessToken>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteStaffAccessTokenMutationInput[] | NodeType.DeleteStaffAccessTokenMutationInput,
        options?: MutationHookOptionsStaffAccessToken
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffAccessToken.delete'
        );
    };

    return {
        delete: mutateDelete,
        mutate,
        mutationResult,
    };
};

// ************** StaffAccessTokenRegistration ***************** //

// StaffAccessTokenRegistration: Mutator

export type TDataStaffAccessTokenRegistration = {
    StaffAccessTokenRegistration: NodeType.StaffAccessTokenRegistrationMutationPayload;
};
export type TVariablesStaffAccessTokenRegistration = { input: NodeType.StaffAccessTokenRegistrationMutationInput };
export type MutationHookOptionsStaffAccessTokenRegistration = MutationHookOptions<
    TDataStaffAccessTokenRegistration,
    TVariablesStaffAccessTokenRegistration
>;

export const useMutationStaffAccessTokenRegistration = (options?: MutationHookOptionsStaffAccessTokenRegistration) => {
    const QL = options?.mutation || getMutationQueryByName('StaffAccessTokenRegistration');
    const [mutate, mutationResult] = useMutation<
        TDataStaffAccessTokenRegistration,
        TVariablesStaffAccessTokenRegistration
    >(QL, options);

    const mutateRegisterTokenForStaff = (
        input:
            | NodeType.StaffAccessTokenRegistrationRegisterTokenForStaffMutationInput[]
            | NodeType.StaffAccessTokenRegistrationRegisterTokenForStaffMutationInput,
        options?: MutationHookOptionsStaffAccessTokenRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        registerTokenForStaff: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffAccessTokenRegistration.registerTokenForStaff'
        );
    };
    const mutateResolveAndRefreshManagerToken = (
        input:
            | NodeType.StaffAccessTokenRegistrationResolveAndRefreshManagerTokenMutationInput[]
            | NodeType.StaffAccessTokenRegistrationResolveAndRefreshManagerTokenMutationInput,
        options?: MutationHookOptionsStaffAccessTokenRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        resolveAndRefreshManagerToken: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffAccessTokenRegistration.resolveAndRefreshManagerToken'
        );
    };

    return {
        registerTokenForStaff: mutateRegisterTokenForStaff,
        resolveAndRefreshManagerToken: mutateResolveAndRefreshManagerToken,
        mutate,
        mutationResult,
    };
};

// ************** StaffRegistration ***************** //

// StaffRegistration: Mutator

export type TDataStaffRegistration = { StaffRegistration: NodeType.StaffRegistrationMutationPayload };
export type TVariablesStaffRegistration = { input: NodeType.StaffRegistrationMutationInput };
export type MutationHookOptionsStaffRegistration = MutationHookOptions<
    TDataStaffRegistration,
    TVariablesStaffRegistration
>;

export const useMutationStaffRegistration = (options?: MutationHookOptionsStaffRegistration) => {
    const QL = options?.mutation || getMutationQueryByName('StaffRegistration');
    const [mutate, mutationResult] = useMutation<TDataStaffRegistration, TVariablesStaffRegistration>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateStaffRegistrationMutationInput[] | NodeType.CreateStaffRegistrationMutationInput,
        options?: MutationHookOptionsStaffRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffRegistration.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteStaffRegistrationMutationInput[] | NodeType.DeleteStaffRegistrationMutationInput,
        options?: MutationHookOptionsStaffRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffRegistration.delete'
        );
    };
    const mutateDisable = (
        input: NodeType.StaffRegistrationDisableMutationInput[] | NodeType.StaffRegistrationDisableMutationInput,
        options?: MutationHookOptionsStaffRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        disable: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffRegistration.disable'
        );
    };
    const mutateEnable = (
        input: NodeType.StaffRegistrationEnableMutationInput[] | NodeType.StaffRegistrationEnableMutationInput,
        options?: MutationHookOptionsStaffRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        enable: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffRegistration.enable'
        );
    };
    const mutateForcePassword = (
        input:
            | NodeType.StaffRegistrationForcePasswordMutationInput[]
            | NodeType.StaffRegistrationForcePasswordMutationInput,
        options?: MutationHookOptionsStaffRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        forcePassword: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffRegistration.forcePassword'
        );
    };
    const mutateInvalidatePassword = (
        input:
            | NodeType.StaffRegistrationInvalidatePasswordMutationInput[]
            | NodeType.StaffRegistrationInvalidatePasswordMutationInput,
        options?: MutationHookOptionsStaffRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        invalidatePassword: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffRegistration.invalidatePassword'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateStaffRegistrationMutationInput[] | NodeType.UpdateStaffRegistrationMutationInput,
        options?: MutationHookOptionsStaffRegistration
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffRegistration.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        disable: mutateDisable,
        enable: mutateEnable,
        forcePassword: mutateForcePassword,
        invalidatePassword: mutateInvalidatePassword,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** StaffTeam ***************** //

// StaffTeam: Mutator

export type TDataStaffTeam = { StaffTeam: NodeType.StaffTeamMutationPayload };
export type TVariablesStaffTeam = { input: NodeType.StaffTeamMutationInput };
export type MutationHookOptionsStaffTeam = MutationHookOptions<TDataStaffTeam, TVariablesStaffTeam>;

export const useMutationStaffTeam = (options?: MutationHookOptionsStaffTeam) => {
    const QL = options?.mutation || getMutationQueryByName('StaffTeam');
    const [mutate, mutationResult] = useMutation<TDataStaffTeam, TVariablesStaffTeam>(QL, options);

    const mutateAddStaff = (
        input: NodeType.StaffTeamAddStaffMutationInput[] | NodeType.StaffTeamAddStaffMutationInput,
        options?: MutationHookOptionsStaffTeam
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        addStaff: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffTeam.addStaff'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateStaffTeamMutationInput[] | NodeType.CreateStaffTeamMutationInput,
        options?: MutationHookOptionsStaffTeam
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffTeam.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteStaffTeamMutationInput[] | NodeType.DeleteStaffTeamMutationInput,
        options?: MutationHookOptionsStaffTeam
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffTeam.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateStaffTeamMutationInput[] | NodeType.UpdateStaffTeamMutationInput,
        options?: MutationHookOptionsStaffTeam
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffTeam.update'
        );
    };

    return {
        addStaff: mutateAddStaff,
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** StaffTeamLink ***************** //

// StaffTeamLink: Mutator

export type TDataStaffTeamLink = { StaffTeamLink: NodeType.StaffTeamLinkMutationPayload };
export type TVariablesStaffTeamLink = { input: NodeType.StaffTeamLinkMutationInput };
export type MutationHookOptionsStaffTeamLink = MutationHookOptions<TDataStaffTeamLink, TVariablesStaffTeamLink>;

export const useMutationStaffTeamLink = (options?: MutationHookOptionsStaffTeamLink) => {
    const QL = options?.mutation || getMutationQueryByName('StaffTeamLink');
    const [mutate, mutationResult] = useMutation<TDataStaffTeamLink, TVariablesStaffTeamLink>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteStaffTeamLinkMutationInput[] | NodeType.DeleteStaffTeamLinkMutationInput,
        options?: MutationHookOptionsStaffTeamLink
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffTeamLink.delete'
        );
    };

    return {
        delete: mutateDelete,
        mutate,
        mutationResult,
    };
};

// ************** StaffValueStore ***************** //

// StaffValueStore: Mutator

export type TDataStaffValueStore = { StaffValueStore: NodeType.StaffValueStoreMutationPayload };
export type TVariablesStaffValueStore = { input: NodeType.StaffValueStoreMutationInput };
export type MutationHookOptionsStaffValueStore = MutationHookOptions<TDataStaffValueStore, TVariablesStaffValueStore>;

export const useMutationStaffValueStore = (options?: MutationHookOptionsStaffValueStore) => {
    const QL = options?.mutation || getMutationQueryByName('StaffValueStore');
    const [mutate, mutationResult] = useMutation<TDataStaffValueStore, TVariablesStaffValueStore>(QL, options);

    const mutateDelete = (
        input: NodeType.DeleteStaffValueStoreMutationInput[] | NodeType.DeleteStaffValueStoreMutationInput,
        options?: MutationHookOptionsStaffValueStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffValueStore.delete'
        );
    };
    const mutateSet = (
        input: NodeType.StaffValueStoreSetMutationInput[] | NodeType.StaffValueStoreSetMutationInput,
        options?: MutationHookOptionsStaffValueStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        set: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffValueStore.set'
        );
    };
    const mutateSetOwn = (
        input: NodeType.StaffValueStoreSetOwnMutationInput[] | NodeType.StaffValueStoreSetOwnMutationInput,
        options?: MutationHookOptionsStaffValueStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setOwn: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffValueStore.setOwn'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateStaffValueStoreMutationInput[] | NodeType.UpdateStaffValueStoreMutationInput,
        options?: MutationHookOptionsStaffValueStore
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'StaffValueStore.update'
        );
    };

    return {
        delete: mutateDelete,
        set: mutateSet,
        setOwn: mutateSetOwn,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TestCriteria ***************** //

// TestCriteria: Mutator

export type TDataTestCriteria = { TestCriteria: NodeType.TestCriteriaMutationPayload };
export type TVariablesTestCriteria = { input: NodeType.TestCriteriaMutationInput };
export type MutationHookOptionsTestCriteria = MutationHookOptions<TDataTestCriteria, TVariablesTestCriteria>;

export const useMutationTestCriteria = (options?: MutationHookOptionsTestCriteria) => {
    const QL = options?.mutation || getMutationQueryByName('TestCriteria');
    const [mutate, mutationResult] = useMutation<TDataTestCriteria, TVariablesTestCriteria>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateTestCriteriaMutationInput[] | NodeType.CreateTestCriteriaMutationInput,
        options?: MutationHookOptionsTestCriteria
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestCriteria.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteTestCriteriaMutationInput[] | NodeType.DeleteTestCriteriaMutationInput,
        options?: MutationHookOptionsTestCriteria
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestCriteria.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateTestCriteriaMutationInput[] | NodeType.UpdateTestCriteriaMutationInput,
        options?: MutationHookOptionsTestCriteria
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestCriteria.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TestProblem ***************** //

// TestProblem: Mutator

export type TDataTestProblem = { TestProblem: NodeType.TestProblemMutationPayload };
export type TVariablesTestProblem = { input: NodeType.TestProblemMutationInput };
export type MutationHookOptionsTestProblem = MutationHookOptions<TDataTestProblem, TVariablesTestProblem>;

export const useMutationTestProblem = (options?: MutationHookOptionsTestProblem) => {
    const QL = options?.mutation || getMutationQueryByName('TestProblem');
    const [mutate, mutationResult] = useMutation<TDataTestProblem, TVariablesTestProblem>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateTestProblemMutationInput[] | NodeType.CreateTestProblemMutationInput,
        options?: MutationHookOptionsTestProblem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestProblem.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteTestProblemMutationInput[] | NodeType.DeleteTestProblemMutationInput,
        options?: MutationHookOptionsTestProblem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestProblem.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateTestProblemMutationInput[] | NodeType.UpdateTestProblemMutationInput,
        options?: MutationHookOptionsTestProblem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestProblem.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TestRecommendation ***************** //

// TestRecommendation: Mutator

export type TDataTestRecommendation = { TestRecommendation: NodeType.TestRecommendationMutationPayload };
export type TVariablesTestRecommendation = { input: NodeType.TestRecommendationMutationInput };
export type MutationHookOptionsTestRecommendation = MutationHookOptions<
    TDataTestRecommendation,
    TVariablesTestRecommendation
>;

export const useMutationTestRecommendation = (options?: MutationHookOptionsTestRecommendation) => {
    const QL = options?.mutation || getMutationQueryByName('TestRecommendation');
    const [mutate, mutationResult] = useMutation<TDataTestRecommendation, TVariablesTestRecommendation>(QL, options);

    const mutateSelectTreatment = (
        input:
            | NodeType.TestRecommendationSelectTreatmentMutationInput[]
            | NodeType.TestRecommendationSelectTreatmentMutationInput,
        options?: MutationHookOptionsTestRecommendation
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        selectTreatment: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestRecommendation.selectTreatment'
        );
    };

    return {
        selectTreatment: mutateSelectTreatment,
        mutate,
        mutationResult,
    };
};

// ************** TestReport ***************** //

// TestReport: Mutator

export type TDataTestReport = { TestReport: NodeType.TestReportMutationPayload };
export type TVariablesTestReport = { input: NodeType.TestReportMutationInput };
export type MutationHookOptionsTestReport = MutationHookOptions<TDataTestReport, TVariablesTestReport>;

export const useMutationTestReport = (options?: MutationHookOptionsTestReport) => {
    const QL = options?.mutation || getMutationQueryByName('TestReport');
    const [mutate, mutationResult] = useMutation<TDataTestReport, TVariablesTestReport>(QL, options);

    const mutateArchiveReport = (
        input: NodeType.TestReportArchiveReportMutationInput[] | NodeType.TestReportArchiveReportMutationInput,
        options?: MutationHookOptionsTestReport
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        archiveReport: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReport.archiveReport'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateTestReportMutationInput[] | NodeType.CreateTestReportMutationInput,
        options?: MutationHookOptionsTestReport
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReport.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteTestReportMutationInput[] | NodeType.DeleteTestReportMutationInput,
        options?: MutationHookOptionsTestReport
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReport.delete'
        );
    };
    const mutateFinaliseReport = (
        input: NodeType.TestReportFinaliseReportMutationInput[] | NodeType.TestReportFinaliseReportMutationInput,
        options?: MutationHookOptionsTestReport
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        finaliseReport: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReport.finaliseReport'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateTestReportMutationInput[] | NodeType.UpdateTestReportMutationInput,
        options?: MutationHookOptionsTestReport
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReport.update'
        );
    };

    return {
        archiveReport: mutateArchiveReport,
        create: mutateCreate,
        delete: mutateDelete,
        finaliseReport: mutateFinaliseReport,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TestReportAttachment ***************** //

// TestReportAttachment: Mutator

export type TDataTestReportAttachment = { TestReportAttachment: NodeType.TestReportAttachmentMutationPayload };
export type TVariablesTestReportAttachment = { input: NodeType.TestReportAttachmentMutationInput };
export type MutationHookOptionsTestReportAttachment = MutationHookOptions<
    TDataTestReportAttachment,
    TVariablesTestReportAttachment
>;

export const useMutationTestReportAttachment = (options?: MutationHookOptionsTestReportAttachment) => {
    const QL = options?.mutation || getMutationQueryByName('TestReportAttachment');
    const [mutate, mutationResult] = useMutation<TDataTestReportAttachment, TVariablesTestReportAttachment>(
        QL,
        options
    );

    const mutateCreate = (
        input: NodeType.CreateTestReportAttachmentMutationInput[] | NodeType.CreateTestReportAttachmentMutationInput,
        options?: MutationHookOptionsTestReportAttachment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReportAttachment.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteTestReportAttachmentMutationInput[] | NodeType.DeleteTestReportAttachmentMutationInput,
        options?: MutationHookOptionsTestReportAttachment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReportAttachment.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateTestReportAttachmentMutationInput[] | NodeType.UpdateTestReportAttachmentMutationInput,
        options?: MutationHookOptionsTestReportAttachment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReportAttachment.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TestReportSolution ***************** //

// TestReportSolution: Mutator

export type TDataTestReportSolution = { TestReportSolution: NodeType.TestReportSolutionMutationPayload };
export type TVariablesTestReportSolution = { input: NodeType.TestReportSolutionMutationInput };
export type MutationHookOptionsTestReportSolution = MutationHookOptions<
    TDataTestReportSolution,
    TVariablesTestReportSolution
>;

export const useMutationTestReportSolution = (options?: MutationHookOptionsTestReportSolution) => {
    const QL = options?.mutation || getMutationQueryByName('TestReportSolution');
    const [mutate, mutationResult] = useMutation<TDataTestReportSolution, TVariablesTestReportSolution>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateTestReportSolutionMutationInput[] | NodeType.CreateTestReportSolutionMutationInput,
        options?: MutationHookOptionsTestReportSolution
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReportSolution.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteTestReportSolutionMutationInput[] | NodeType.DeleteTestReportSolutionMutationInput,
        options?: MutationHookOptionsTestReportSolution
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReportSolution.delete'
        );
    };
    const mutateOverride = (
        input: NodeType.TestReportSolutionOverrideMutationInput[] | NodeType.TestReportSolutionOverrideMutationInput,
        options?: MutationHookOptionsTestReportSolution
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        override: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReportSolution.override'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateTestReportSolutionMutationInput[] | NodeType.UpdateTestReportSolutionMutationInput,
        options?: MutationHookOptionsTestReportSolution
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestReportSolution.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        override: mutateOverride,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TestTreatment ***************** //

// TestTreatment: Mutator

export type TDataTestTreatment = { TestTreatment: NodeType.TestTreatmentMutationPayload };
export type TVariablesTestTreatment = { input: NodeType.TestTreatmentMutationInput };
export type MutationHookOptionsTestTreatment = MutationHookOptions<TDataTestTreatment, TVariablesTestTreatment>;

export const useMutationTestTreatment = (options?: MutationHookOptionsTestTreatment) => {
    const QL = options?.mutation || getMutationQueryByName('TestTreatment');
    const [mutate, mutationResult] = useMutation<TDataTestTreatment, TVariablesTestTreatment>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateTestTreatmentMutationInput[] | NodeType.CreateTestTreatmentMutationInput,
        options?: MutationHookOptionsTestTreatment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatment.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteTestTreatmentMutationInput[] | NodeType.DeleteTestTreatmentMutationInput,
        options?: MutationHookOptionsTestTreatment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatment.delete'
        );
    };
    const mutateSetBlacklistForFranchise = (
        input:
            | NodeType.TestTreatmentSetBlacklistForFranchiseMutationInput[]
            | NodeType.TestTreatmentSetBlacklistForFranchiseMutationInput,
        options?: MutationHookOptionsTestTreatment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setBlacklistForFranchise: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatment.setBlacklistForFranchise'
        );
    };
    const mutateSetPriorityForFranchise = (
        input:
            | NodeType.TestTreatmentSetPriorityForFranchiseMutationInput[]
            | NodeType.TestTreatmentSetPriorityForFranchiseMutationInput,
        options?: MutationHookOptionsTestTreatment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setPriorityForFranchise: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatment.setPriorityForFranchise'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateTestTreatmentMutationInput[] | NodeType.UpdateTestTreatmentMutationInput,
        options?: MutationHookOptionsTestTreatment
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatment.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        setBlacklistForFranchise: mutateSetBlacklistForFranchise,
        setPriorityForFranchise: mutateSetPriorityForFranchise,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TestTreatmentFranchiseGroup ***************** //

// TestTreatmentFranchiseGroup: Mutator

export type TDataTestTreatmentFranchiseGroup = {
    TestTreatmentFranchiseGroup: NodeType.TestTreatmentFranchiseGroupMutationPayload;
};
export type TVariablesTestTreatmentFranchiseGroup = { input: NodeType.TestTreatmentFranchiseGroupMutationInput };
export type MutationHookOptionsTestTreatmentFranchiseGroup = MutationHookOptions<
    TDataTestTreatmentFranchiseGroup,
    TVariablesTestTreatmentFranchiseGroup
>;

export const useMutationTestTreatmentFranchiseGroup = (options?: MutationHookOptionsTestTreatmentFranchiseGroup) => {
    const QL = options?.mutation || getMutationQueryByName('TestTreatmentFranchiseGroup');
    const [mutate, mutationResult] = useMutation<
        TDataTestTreatmentFranchiseGroup,
        TVariablesTestTreatmentFranchiseGroup
    >(QL, options);

    const mutateActivate = (
        input:
            | NodeType.TestTreatmentFranchiseGroupActivateMutationInput[]
            | NodeType.TestTreatmentFranchiseGroupActivateMutationInput,
        options?: MutationHookOptionsTestTreatmentFranchiseGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        activate: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatmentFranchiseGroup.activate'
        );
    };
    const mutateDeactivate = (
        input:
            | NodeType.TestTreatmentFranchiseGroupDeactivateMutationInput[]
            | NodeType.TestTreatmentFranchiseGroupDeactivateMutationInput,
        options?: MutationHookOptionsTestTreatmentFranchiseGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        deactivate: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatmentFranchiseGroup.deactivate'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteTestTreatmentFranchiseGroupMutationInput[]
            | NodeType.DeleteTestTreatmentFranchiseGroupMutationInput,
        options?: MutationHookOptionsTestTreatmentFranchiseGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatmentFranchiseGroup.delete'
        );
    };

    return {
        activate: mutateActivate,
        deactivate: mutateDeactivate,
        delete: mutateDelete,
        mutate,
        mutationResult,
    };
};

// ************** TestTreatmentFranchisePriority ***************** //

// TestTreatmentFranchisePriority: Mutator

export type TDataTestTreatmentFranchisePriority = {
    TestTreatmentFranchisePriority: NodeType.TestTreatmentFranchisePriorityMutationPayload;
};
export type TVariablesTestTreatmentFranchisePriority = { input: NodeType.TestTreatmentFranchisePriorityMutationInput };
export type MutationHookOptionsTestTreatmentFranchisePriority = MutationHookOptions<
    TDataTestTreatmentFranchisePriority,
    TVariablesTestTreatmentFranchisePriority
>;

export const useMutationTestTreatmentFranchisePriority = (
    options?: MutationHookOptionsTestTreatmentFranchisePriority
) => {
    const QL = options?.mutation || getMutationQueryByName('TestTreatmentFranchisePriority');
    const [mutate, mutationResult] = useMutation<
        TDataTestTreatmentFranchisePriority,
        TVariablesTestTreatmentFranchisePriority
    >(QL, options);

    const mutateDelete = (
        input:
            | NodeType.DeleteTestTreatmentFranchisePriorityMutationInput[]
            | NodeType.DeleteTestTreatmentFranchisePriorityMutationInput,
        options?: MutationHookOptionsTestTreatmentFranchisePriority
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatmentFranchisePriority.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateTestTreatmentFranchisePriorityMutationInput[]
            | NodeType.UpdateTestTreatmentFranchisePriorityMutationInput,
        options?: MutationHookOptionsTestTreatmentFranchisePriority
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatmentFranchisePriority.update'
        );
    };

    return {
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TestTreatmentGroup ***************** //

// TestTreatmentGroup: Mutator

export type TDataTestTreatmentGroup = { TestTreatmentGroup: NodeType.TestTreatmentGroupMutationPayload };
export type TVariablesTestTreatmentGroup = { input: NodeType.TestTreatmentGroupMutationInput };
export type MutationHookOptionsTestTreatmentGroup = MutationHookOptions<
    TDataTestTreatmentGroup,
    TVariablesTestTreatmentGroup
>;

export const useMutationTestTreatmentGroup = (options?: MutationHookOptionsTestTreatmentGroup) => {
    const QL = options?.mutation || getMutationQueryByName('TestTreatmentGroup');
    const [mutate, mutationResult] = useMutation<TDataTestTreatmentGroup, TVariablesTestTreatmentGroup>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateTestTreatmentGroupMutationInput[] | NodeType.CreateTestTreatmentGroupMutationInput,
        options?: MutationHookOptionsTestTreatmentGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatmentGroup.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteTestTreatmentGroupMutationInput[] | NodeType.DeleteTestTreatmentGroupMutationInput,
        options?: MutationHookOptionsTestTreatmentGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatmentGroup.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateTestTreatmentGroupMutationInput[] | NodeType.UpdateTestTreatmentGroupMutationInput,
        options?: MutationHookOptionsTestTreatmentGroup
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TestTreatmentGroup.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** TreatmentAffect ***************** //

// TreatmentAffect: Mutator

export type TDataTreatmentAffect = { TreatmentAffect: NodeType.TreatmentAffectMutationPayload };
export type TVariablesTreatmentAffect = { input: NodeType.TreatmentAffectMutationInput };
export type MutationHookOptionsTreatmentAffect = MutationHookOptions<TDataTreatmentAffect, TVariablesTreatmentAffect>;

export const useMutationTreatmentAffect = (options?: MutationHookOptionsTreatmentAffect) => {
    const QL = options?.mutation || getMutationQueryByName('TreatmentAffect');
    const [mutate, mutationResult] = useMutation<TDataTreatmentAffect, TVariablesTreatmentAffect>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateTreatmentAffectMutationInput[] | NodeType.CreateTreatmentAffectMutationInput,
        options?: MutationHookOptionsTreatmentAffect
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'TreatmentAffect.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteTreatmentAffectMutationInput[] | NodeType.DeleteTreatmentAffectMutationInput,
        options?: MutationHookOptionsTreatmentAffect
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'TreatmentAffect.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateTreatmentAffectMutationInput[] | NodeType.UpdateTreatmentAffectMutationInput,
        options?: MutationHookOptionsTreatmentAffect
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'TreatmentAffect.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** Unit ***************** //

// Unit: Mutator

export type TDataUnit = { Unit: NodeType.UnitMutationPayload };
export type TVariablesUnit = { input: NodeType.UnitMutationInput };
export type MutationHookOptionsUnit = MutationHookOptions<TDataUnit, TVariablesUnit>;

export const useMutationUnit = (options?: MutationHookOptionsUnit) => {
    const QL = options?.mutation || getMutationQueryByName('Unit');
    const [mutate, mutationResult] = useMutation<TDataUnit, TVariablesUnit>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateUnitMutationInput[] | NodeType.CreateUnitMutationInput,
        options?: MutationHookOptionsUnit
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'Unit.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteUnitMutationInput[] | NodeType.DeleteUnitMutationInput,
        options?: MutationHookOptionsUnit
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'Unit.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateUnitMutationInput[] | NodeType.UpdateUnitMutationInput,
        options?: MutationHookOptionsUnit
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'Unit.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** UnitConversion ***************** //

// UnitConversion: Mutator

export type TDataUnitConversion = { UnitConversion: NodeType.UnitConversionMutationPayload };
export type TVariablesUnitConversion = { input: NodeType.UnitConversionMutationInput };
export type MutationHookOptionsUnitConversion = MutationHookOptions<TDataUnitConversion, TVariablesUnitConversion>;

export const useMutationUnitConversion = (options?: MutationHookOptionsUnitConversion) => {
    const QL = options?.mutation || getMutationQueryByName('UnitConversion');
    const [mutate, mutationResult] = useMutation<TDataUnitConversion, TVariablesUnitConversion>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateUnitConversionMutationInput[] | NodeType.CreateUnitConversionMutationInput,
        options?: MutationHookOptionsUnitConversion
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'UnitConversion.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteUnitConversionMutationInput[] | NodeType.DeleteUnitConversionMutationInput,
        options?: MutationHookOptionsUnitConversion
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'UnitConversion.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateUnitConversionMutationInput[] | NodeType.UpdateUnitConversionMutationInput,
        options?: MutationHookOptionsUnitConversion
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'UnitConversion.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** UniversalCriteria ***************** //

// UniversalCriteria: Mutator

export type TDataUniversalCriteria = { UniversalCriteria: NodeType.UniversalCriteriaMutationPayload };
export type TVariablesUniversalCriteria = { input: NodeType.UniversalCriteriaMutationInput };
export type MutationHookOptionsUniversalCriteria = MutationHookOptions<
    TDataUniversalCriteria,
    TVariablesUniversalCriteria
>;

export const useMutationUniversalCriteria = (options?: MutationHookOptionsUniversalCriteria) => {
    const QL = options?.mutation || getMutationQueryByName('UniversalCriteria');
    const [mutate, mutationResult] = useMutation<TDataUniversalCriteria, TVariablesUniversalCriteria>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateUniversalCriteriaMutationInput[] | NodeType.CreateUniversalCriteriaMutationInput,
        options?: MutationHookOptionsUniversalCriteria
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'UniversalCriteria.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteUniversalCriteriaMutationInput[] | NodeType.DeleteUniversalCriteriaMutationInput,
        options?: MutationHookOptionsUniversalCriteria
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'UniversalCriteria.delete'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        mutate,
        mutationResult,
    };
};

// ************** VendAPI ***************** //

// VendAPI: Mutator

export type TDataVendAPI = { VendAPI: NodeType.VendAPIMutationPayload };
export type TVariablesVendAPI = { input: NodeType.VendAPIMutationInput };
export type MutationHookOptionsVendAPI = MutationHookOptions<TDataVendAPI, TVariablesVendAPI>;

export const useMutationVendAPI = (options?: MutationHookOptionsVendAPI) => {
    const QL = options?.mutation || getMutationQueryByName('VendAPI');
    const [mutate, mutationResult] = useMutation<TDataVendAPI, TVariablesVendAPI>(QL, options);

    const mutateAddCustomer = (
        input: NodeType.VendAPIAddCustomerMutationInput[] | NodeType.VendAPIAddCustomerMutationInput,
        options?: MutationHookOptionsVendAPI
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        addCustomer: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendAPI.addCustomer'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateVendAPIMutationInput[] | NodeType.CreateVendAPIMutationInput,
        options?: MutationHookOptionsVendAPI
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendAPI.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteVendAPIMutationInput[] | NodeType.DeleteVendAPIMutationInput,
        options?: MutationHookOptionsVendAPI
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendAPI.delete'
        );
    };
    const mutateExportToVend = (
        input: NodeType.VendAPIExportToVendMutationInput[] | NodeType.VendAPIExportToVendMutationInput,
        options?: MutationHookOptionsVendAPI
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        exportToVend: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendAPI.exportToVend'
        );
    };
    const mutateImportFromVend = (
        input: NodeType.VendAPIImportFromVendMutationInput[] | NodeType.VendAPIImportFromVendMutationInput,
        options?: MutationHookOptionsVendAPI
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        importFromVend: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendAPI.importFromVend'
        );
    };
    const mutateLegacyReconnect = (
        input: NodeType.VendAPILegacyReconnectMutationInput[] | NodeType.VendAPILegacyReconnectMutationInput,
        options?: MutationHookOptionsVendAPI
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        legacyReconnect: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendAPI.legacyReconnect'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateVendAPIMutationInput[] | NodeType.UpdateVendAPIMutationInput,
        options?: MutationHookOptionsVendAPI
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendAPI.update'
        );
    };

    return {
        addCustomer: mutateAddCustomer,
        create: mutateCreate,
        delete: mutateDelete,
        exportToVend: mutateExportToVend,
        importFromVend: mutateImportFromVend,
        legacyReconnect: mutateLegacyReconnect,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** VendCustomer ***************** //

// VendCustomer: Mutator

export type TDataVendCustomer = { VendCustomer: NodeType.VendCustomerMutationPayload };
export type TVariablesVendCustomer = { input: NodeType.VendCustomerMutationInput };
export type MutationHookOptionsVendCustomer = MutationHookOptions<TDataVendCustomer, TVariablesVendCustomer>;

export const useMutationVendCustomer = (options?: MutationHookOptionsVendCustomer) => {
    const QL = options?.mutation || getMutationQueryByName('VendCustomer');
    const [mutate, mutationResult] = useMutation<TDataVendCustomer, TVariablesVendCustomer>(QL, options);

    const mutateCreate = (
        input: NodeType.VendCustomerCreateMutationInput[] | NodeType.VendCustomerCreateMutationInput,
        options?: MutationHookOptionsVendCustomer
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendCustomer.create'
        );
    };

    return {
        create: mutateCreate,
        mutate,
        mutationResult,
    };
};

// ************** VendLink ***************** //

// VendLink: Mutator

export type TDataVendLink = { VendLink: NodeType.VendLinkMutationPayload };
export type TVariablesVendLink = { input: NodeType.VendLinkMutationInput };
export type MutationHookOptionsVendLink = MutationHookOptions<TDataVendLink, TVariablesVendLink>;

export const useMutationVendLink = (options?: MutationHookOptionsVendLink) => {
    const QL = options?.mutation || getMutationQueryByName('VendLink');
    const [mutate, mutationResult] = useMutation<TDataVendLink, TVariablesVendLink>(QL, options);

    const mutateSync = (
        input: NodeType.VendLinkSyncMutationInput[] | NodeType.VendLinkSyncMutationInput,
        options?: MutationHookOptionsVendLink
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        sync: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendLink.sync'
        );
    };

    return {
        sync: mutateSync,
        mutate,
        mutationResult,
    };
};

// ************** VendSale ***************** //

// VendSale: Mutator

export type TDataVendSale = { VendSale: NodeType.VendSaleMutationPayload };
export type TVariablesVendSale = { input: NodeType.VendSaleMutationInput };
export type MutationHookOptionsVendSale = MutationHookOptions<TDataVendSale, TVariablesVendSale>;

export const useMutationVendSale = (options?: MutationHookOptionsVendSale) => {
    const QL = options?.mutation || getMutationQueryByName('VendSale');
    const [mutate, mutationResult] = useMutation<TDataVendSale, TVariablesVendSale>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateVendSaleMutationInput[] | NodeType.CreateVendSaleMutationInput,
        options?: MutationHookOptionsVendSale
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'VendSale.create'
        );
    };

    return {
        create: mutateCreate,
        mutate,
        mutationResult,
    };
};

// ************** WarrantyClaim ***************** //

// WarrantyClaim: Mutator

export type TDataWarrantyClaim = { WarrantyClaim: NodeType.WarrantyClaimMutationPayload };
export type TVariablesWarrantyClaim = { input: NodeType.WarrantyClaimMutationInput };
export type MutationHookOptionsWarrantyClaim = MutationHookOptions<TDataWarrantyClaim, TVariablesWarrantyClaim>;

export const useMutationWarrantyClaim = (options?: MutationHookOptionsWarrantyClaim) => {
    const QL = options?.mutation || getMutationQueryByName('WarrantyClaim');
    const [mutate, mutationResult] = useMutation<TDataWarrantyClaim, TVariablesWarrantyClaim>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateWarrantyClaimMutationInput[] | NodeType.CreateWarrantyClaimMutationInput,
        options?: MutationHookOptionsWarrantyClaim
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaim.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteWarrantyClaimMutationInput[] | NodeType.DeleteWarrantyClaimMutationInput,
        options?: MutationHookOptionsWarrantyClaim
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaim.delete'
        );
    };
    const mutateExecuteWarrantyClaimRequest = (
        input:
            | NodeType.WarrantyClaimExecuteWarrantyClaimRequestMutationInput[]
            | NodeType.WarrantyClaimExecuteWarrantyClaimRequestMutationInput,
        options?: MutationHookOptionsWarrantyClaim
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        executeWarrantyClaimRequest: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaim.executeWarrantyClaimRequest'
        );
    };
    const mutateFixUnsharedClaims = (
        input:
            | NodeType.WarrantyClaimFixUnsharedClaimsMutationInput[]
            | NodeType.WarrantyClaimFixUnsharedClaimsMutationInput,
        options?: MutationHookOptionsWarrantyClaim
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        fixUnsharedClaims: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaim.fixUnsharedClaims'
        );
    };
    const mutateForceClaimCase = (
        input: NodeType.WarrantyClaimForceClaimCaseMutationInput[] | NodeType.WarrantyClaimForceClaimCaseMutationInput,
        options?: MutationHookOptionsWarrantyClaim
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        forceClaimCase: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaim.forceClaimCase'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateWarrantyClaimMutationInput[] | NodeType.UpdateWarrantyClaimMutationInput,
        options?: MutationHookOptionsWarrantyClaim
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaim.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        executeWarrantyClaimRequest: mutateExecuteWarrantyClaimRequest,
        fixUnsharedClaims: mutateFixUnsharedClaims,
        forceClaimCase: mutateForceClaimCase,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** WarrantyClaimCase ***************** //

// WarrantyClaimCase: Mutator

export type TDataWarrantyClaimCase = { WarrantyClaimCase: NodeType.WarrantyClaimCaseMutationPayload };
export type TVariablesWarrantyClaimCase = { input: NodeType.WarrantyClaimCaseMutationInput };
export type MutationHookOptionsWarrantyClaimCase = MutationHookOptions<
    TDataWarrantyClaimCase,
    TVariablesWarrantyClaimCase
>;

export const useMutationWarrantyClaimCase = (options?: MutationHookOptionsWarrantyClaimCase) => {
    const QL = options?.mutation || getMutationQueryByName('WarrantyClaimCase');
    const [mutate, mutationResult] = useMutation<TDataWarrantyClaimCase, TVariablesWarrantyClaimCase>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateWarrantyClaimCaseMutationInput[] | NodeType.CreateWarrantyClaimCaseMutationInput,
        options?: MutationHookOptionsWarrantyClaimCase
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimCase.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteWarrantyClaimCaseMutationInput[] | NodeType.DeleteWarrantyClaimCaseMutationInput,
        options?: MutationHookOptionsWarrantyClaimCase
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimCase.delete'
        );
    };
    const mutateMarkMessagesAsRead = (
        input:
            | NodeType.WarrantyClaimCaseMarkMessagesAsReadMutationInput[]
            | NodeType.WarrantyClaimCaseMarkMessagesAsReadMutationInput,
        options?: MutationHookOptionsWarrantyClaimCase
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        markMessagesAsRead: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimCase.markMessagesAsRead'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateWarrantyClaimCaseMutationInput[] | NodeType.UpdateWarrantyClaimCaseMutationInput,
        options?: MutationHookOptionsWarrantyClaimCase
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimCase.update'
        );
    };
    const mutateUpdateStatus = (
        input:
            | NodeType.WarrantyClaimCaseUpdateStatusMutationInput[]
            | NodeType.WarrantyClaimCaseUpdateStatusMutationInput,
        options?: MutationHookOptionsWarrantyClaimCase
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        updateStatus: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimCase.updateStatus'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        markMessagesAsRead: mutateMarkMessagesAsRead,
        update: mutateUpdate,
        updateStatus: mutateUpdateStatus,
        mutate,
        mutationResult,
    };
};

// ************** WarrantyClaimFileAttach ***************** //

// WarrantyClaimFileAttach: Mutator

export type TDataWarrantyClaimFileAttach = { WarrantyClaimFileAttach: NodeType.WarrantyClaimFileAttachMutationPayload };
export type TVariablesWarrantyClaimFileAttach = { input: NodeType.WarrantyClaimFileAttachMutationInput };
export type MutationHookOptionsWarrantyClaimFileAttach = MutationHookOptions<
    TDataWarrantyClaimFileAttach,
    TVariablesWarrantyClaimFileAttach
>;

export const useMutationWarrantyClaimFileAttach = (options?: MutationHookOptionsWarrantyClaimFileAttach) => {
    const QL = options?.mutation || getMutationQueryByName('WarrantyClaimFileAttach');
    const [mutate, mutationResult] = useMutation<TDataWarrantyClaimFileAttach, TVariablesWarrantyClaimFileAttach>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreateWarrantyClaimFileAttachMutationInput[]
            | NodeType.CreateWarrantyClaimFileAttachMutationInput,
        options?: MutationHookOptionsWarrantyClaimFileAttach
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimFileAttach.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteWarrantyClaimFileAttachMutationInput[]
            | NodeType.DeleteWarrantyClaimFileAttachMutationInput,
        options?: MutationHookOptionsWarrantyClaimFileAttach
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimFileAttach.delete'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateWarrantyClaimFileAttachMutationInput[]
            | NodeType.UpdateWarrantyClaimFileAttachMutationInput,
        options?: MutationHookOptionsWarrantyClaimFileAttach
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimFileAttach.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** WarrantyClaimMessage ***************** //

// WarrantyClaimMessage: Mutator

export type TDataWarrantyClaimMessage = { WarrantyClaimMessage: NodeType.WarrantyClaimMessageMutationPayload };
export type TVariablesWarrantyClaimMessage = { input: NodeType.WarrantyClaimMessageMutationInput };
export type MutationHookOptionsWarrantyClaimMessage = MutationHookOptions<
    TDataWarrantyClaimMessage,
    TVariablesWarrantyClaimMessage
>;

export const useMutationWarrantyClaimMessage = (options?: MutationHookOptionsWarrantyClaimMessage) => {
    const QL = options?.mutation || getMutationQueryByName('WarrantyClaimMessage');
    const [mutate, mutationResult] = useMutation<TDataWarrantyClaimMessage, TVariablesWarrantyClaimMessage>(
        QL,
        options
    );

    const mutateDelete = (
        input: NodeType.DeleteWarrantyClaimMessageMutationInput[] | NodeType.DeleteWarrantyClaimMessageMutationInput,
        options?: MutationHookOptionsWarrantyClaimMessage
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimMessage.delete'
        );
    };
    const mutatePostMessage = (
        input:
            | NodeType.WarrantyClaimMessagePostMessageMutationInput[]
            | NodeType.WarrantyClaimMessagePostMessageMutationInput,
        options?: MutationHookOptionsWarrantyClaimMessage
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        postMessage: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimMessage.postMessage'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateWarrantyClaimMessageMutationInput[] | NodeType.UpdateWarrantyClaimMessageMutationInput,
        options?: MutationHookOptionsWarrantyClaimMessage
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'WarrantyClaimMessage.update'
        );
    };

    return {
        delete: mutateDelete,
        postMessage: mutatePostMessage,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** WaterTestingRevision ***************** //

// WaterTestingRevision: Mutator

export type TDataWaterTestingRevision = { WaterTestingRevision: NodeType.WaterTestingRevisionMutationPayload };
export type TVariablesWaterTestingRevision = { input: NodeType.WaterTestingRevisionMutationInput };
export type MutationHookOptionsWaterTestingRevision = MutationHookOptions<
    TDataWaterTestingRevision,
    TVariablesWaterTestingRevision
>;

export const useMutationWaterTestingRevision = (options?: MutationHookOptionsWaterTestingRevision) => {
    const QL = options?.mutation || getMutationQueryByName('WaterTestingRevision');
    const [mutate, mutationResult] = useMutation<TDataWaterTestingRevision, TVariablesWaterTestingRevision>(
        QL,
        options
    );

    const mutateCreate = (
        input: NodeType.CreateWaterTestingRevisionMutationInput[] | NodeType.CreateWaterTestingRevisionMutationInput,
        options?: MutationHookOptionsWaterTestingRevision
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'WaterTestingRevision.create'
        );
    };

    return {
        create: mutateCreate,
        mutate,
        mutationResult,
    };
};

// ************** WorkOrder ***************** //

// WorkOrder: Mutator

export type TDataWorkOrder = { WorkOrder: NodeType.WorkOrderMutationPayload };
export type TVariablesWorkOrder = { input: NodeType.WorkOrderMutationInput };
export type MutationHookOptionsWorkOrder = MutationHookOptions<TDataWorkOrder, TVariablesWorkOrder>;

export const useMutationWorkOrder = (options?: MutationHookOptionsWorkOrder) => {
    const QL = options?.mutation || getMutationQueryByName('WorkOrder');
    const [mutate, mutationResult] = useMutation<TDataWorkOrder, TVariablesWorkOrder>(QL, options);

    const mutateAddJobTodoFromTemplate = (
        input:
            | NodeType.WorkOrderAddJobTodoFromTemplateMutationInput[]
            | NodeType.WorkOrderAddJobTodoFromTemplateMutationInput,
        options?: MutationHookOptionsWorkOrder
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        addJobTodoFromTemplate: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrder.addJobTodoFromTemplate'
        );
    };
    const mutateAddVendSale = (
        input: NodeType.WorkOrderAddVendSaleMutationInput[] | NodeType.WorkOrderAddVendSaleMutationInput,
        options?: MutationHookOptionsWorkOrder
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        addVendSale: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrder.addVendSale'
        );
    };
    const mutateAppendWorkOrderTemplate = (
        input:
            | NodeType.WorkOrderAppendWorkOrderTemplateMutationInput[]
            | NodeType.WorkOrderAppendWorkOrderTemplateMutationInput,
        options?: MutationHookOptionsWorkOrder
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        appendWorkOrderTemplate: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrder.appendWorkOrderTemplate'
        );
    };
    const mutateAssignAppointmentItem = (
        input:
            | NodeType.WorkOrderAssignAppointmentItemMutationInput[]
            | NodeType.WorkOrderAssignAppointmentItemMutationInput,
        options?: MutationHookOptionsWorkOrder
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        assignAppointmentItem: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrder.assignAppointmentItem'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateWorkOrderMutationInput[] | NodeType.CreateWorkOrderMutationInput,
        options?: MutationHookOptionsWorkOrder
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrder.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteWorkOrderMutationInput[] | NodeType.DeleteWorkOrderMutationInput,
        options?: MutationHookOptionsWorkOrder
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrder.delete'
        );
    };
    const mutateReplaceWorkOrderTemplate = (
        input:
            | NodeType.WorkOrderReplaceWorkOrderTemplateMutationInput[]
            | NodeType.WorkOrderReplaceWorkOrderTemplateMutationInput,
        options?: MutationHookOptionsWorkOrder
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        replaceWorkOrderTemplate: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrder.replaceWorkOrderTemplate'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateWorkOrderMutationInput[] | NodeType.UpdateWorkOrderMutationInput,
        options?: MutationHookOptionsWorkOrder
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrder.update'
        );
    };

    return {
        addJobTodoFromTemplate: mutateAddJobTodoFromTemplate,
        addVendSale: mutateAddVendSale,
        appendWorkOrderTemplate: mutateAppendWorkOrderTemplate,
        assignAppointmentItem: mutateAssignAppointmentItem,
        create: mutateCreate,
        delete: mutateDelete,
        replaceWorkOrderTemplate: mutateReplaceWorkOrderTemplate,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** WorkOrderFeedItem ***************** //

// WorkOrderFeedItem: Mutator

export type TDataWorkOrderFeedItem = { WorkOrderFeedItem: NodeType.WorkOrderFeedItemMutationPayload };
export type TVariablesWorkOrderFeedItem = { input: NodeType.WorkOrderFeedItemMutationInput };
export type MutationHookOptionsWorkOrderFeedItem = MutationHookOptions<
    TDataWorkOrderFeedItem,
    TVariablesWorkOrderFeedItem
>;

export const useMutationWorkOrderFeedItem = (options?: MutationHookOptionsWorkOrderFeedItem) => {
    const QL = options?.mutation || getMutationQueryByName('WorkOrderFeedItem');
    const [mutate, mutationResult] = useMutation<TDataWorkOrderFeedItem, TVariablesWorkOrderFeedItem>(QL, options);

    const mutateStartWorkOrder = (
        input:
            | NodeType.WorkOrderFeedItemStartWorkOrderMutationInput[]
            | NodeType.WorkOrderFeedItemStartWorkOrderMutationInput,
        options?: MutationHookOptionsWorkOrderFeedItem
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        startWorkOrder: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderFeedItem.startWorkOrder'
        );
    };

    return {
        startWorkOrder: mutateStartWorkOrder,
        mutate,
        mutationResult,
    };
};

// ************** WorkOrderNote ***************** //

// WorkOrderNote: Mutator

export type TDataWorkOrderNote = { WorkOrderNote: NodeType.WorkOrderNoteMutationPayload };
export type TVariablesWorkOrderNote = { input: NodeType.WorkOrderNoteMutationInput };
export type MutationHookOptionsWorkOrderNote = MutationHookOptions<TDataWorkOrderNote, TVariablesWorkOrderNote>;

export const useMutationWorkOrderNote = (options?: MutationHookOptionsWorkOrderNote) => {
    const QL = options?.mutation || getMutationQueryByName('WorkOrderNote');
    const [mutate, mutationResult] = useMutation<TDataWorkOrderNote, TVariablesWorkOrderNote>(QL, options);

    const mutateCreate = (
        input: NodeType.CreateWorkOrderNoteMutationInput[] | NodeType.CreateWorkOrderNoteMutationInput,
        options?: MutationHookOptionsWorkOrderNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderNote.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteWorkOrderNoteMutationInput[] | NodeType.DeleteWorkOrderNoteMutationInput,
        options?: MutationHookOptionsWorkOrderNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderNote.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateWorkOrderNoteMutationInput[] | NodeType.UpdateWorkOrderNoteMutationInput,
        options?: MutationHookOptionsWorkOrderNote
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderNote.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** WorkOrderTemplate ***************** //

// WorkOrderTemplate: Mutator

export type TDataWorkOrderTemplate = { WorkOrderTemplate: NodeType.WorkOrderTemplateMutationPayload };
export type TVariablesWorkOrderTemplate = { input: NodeType.WorkOrderTemplateMutationInput };
export type MutationHookOptionsWorkOrderTemplate = MutationHookOptions<
    TDataWorkOrderTemplate,
    TVariablesWorkOrderTemplate
>;

export const useMutationWorkOrderTemplate = (options?: MutationHookOptionsWorkOrderTemplate) => {
    const QL = options?.mutation || getMutationQueryByName('WorkOrderTemplate');
    const [mutate, mutationResult] = useMutation<TDataWorkOrderTemplate, TVariablesWorkOrderTemplate>(QL, options);

    const mutateArchive = (
        input: NodeType.WorkOrderTemplateArchiveMutationInput[] | NodeType.WorkOrderTemplateArchiveMutationInput,
        options?: MutationHookOptionsWorkOrderTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        archive: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderTemplate.archive'
        );
    };
    const mutateCreate = (
        input: NodeType.CreateWorkOrderTemplateMutationInput[] | NodeType.CreateWorkOrderTemplateMutationInput,
        options?: MutationHookOptionsWorkOrderTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderTemplate.create'
        );
    };
    const mutateDelete = (
        input: NodeType.DeleteWorkOrderTemplateMutationInput[] | NodeType.DeleteWorkOrderTemplateMutationInput,
        options?: MutationHookOptionsWorkOrderTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderTemplate.delete'
        );
    };
    const mutateUpdate = (
        input: NodeType.UpdateWorkOrderTemplateMutationInput[] | NodeType.UpdateWorkOrderTemplateMutationInput,
        options?: MutationHookOptionsWorkOrderTemplate
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderTemplate.update'
        );
    };

    return {
        archive: mutateArchive,
        create: mutateCreate,
        delete: mutateDelete,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};

// ************** WorkOrderTemplateAssoc ***************** //

// WorkOrderTemplateAssoc: Mutator

export type TDataWorkOrderTemplateAssoc = { WorkOrderTemplateAssoc: NodeType.WorkOrderTemplateAssocMutationPayload };
export type TVariablesWorkOrderTemplateAssoc = { input: NodeType.WorkOrderTemplateAssocMutationInput };
export type MutationHookOptionsWorkOrderTemplateAssoc = MutationHookOptions<
    TDataWorkOrderTemplateAssoc,
    TVariablesWorkOrderTemplateAssoc
>;

export const useMutationWorkOrderTemplateAssoc = (options?: MutationHookOptionsWorkOrderTemplateAssoc) => {
    const QL = options?.mutation || getMutationQueryByName('WorkOrderTemplateAssoc');
    const [mutate, mutationResult] = useMutation<TDataWorkOrderTemplateAssoc, TVariablesWorkOrderTemplateAssoc>(
        QL,
        options
    );

    const mutateCreate = (
        input:
            | NodeType.CreateWorkOrderTemplateAssocMutationInput[]
            | NodeType.CreateWorkOrderTemplateAssocMutationInput,
        options?: MutationHookOptionsWorkOrderTemplateAssoc
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        create: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderTemplateAssoc.create'
        );
    };
    const mutateDelete = (
        input:
            | NodeType.DeleteWorkOrderTemplateAssocMutationInput[]
            | NodeType.DeleteWorkOrderTemplateAssocMutationInput,
        options?: MutationHookOptionsWorkOrderTemplateAssoc
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        delete: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderTemplateAssoc.delete'
        );
    };
    const mutateSetDefault = (
        input:
            | NodeType.WorkOrderTemplateAssocSetDefaultMutationInput[]
            | NodeType.WorkOrderTemplateAssocSetDefaultMutationInput,
        options?: MutationHookOptionsWorkOrderTemplateAssoc
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        setDefault: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderTemplateAssoc.setDefault'
        );
    };
    const mutateUpdate = (
        input:
            | NodeType.UpdateWorkOrderTemplateAssocMutationInput[]
            | NodeType.UpdateWorkOrderTemplateAssocMutationInput,
        options?: MutationHookOptionsWorkOrderTemplateAssoc
    ) => {
        return mutate(
            {
                ...options,
                variables: {
                    input: {
                        update: input as any,
                    },
                },
            },
            // @ts-ignore
            'WorkOrderTemplateAssoc.update'
        );
    };

    return {
        create: mutateCreate,
        delete: mutateDelete,
        setDefault: mutateSetDefault,
        update: mutateUpdate,
        mutate,
        mutationResult,
    };
};
