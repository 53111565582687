import * as React from 'react';
import { useEffect, useState } from 'react';
import { DevOnly, Button } from '@ez/components';
import * as _ from 'lodash';

export function createAutocompleteEditableItem<T = any>(input: {
    inputComp: React.ComponentType<any>;
    renderItem: (item: T) => React.ReactNode;
}): React.FC<{ value: T; onChange: (value: T) => any }> {
    const { inputComp: Input, renderItem } = input;
    return ({ value, onChange }) => {
        const [initalValue, setInitialValue] = useState(value);
        const [newValue, setNewValue] = useState(value);

        const [isEdit, setEdit] = useState(false);

        const handleItemSelect = (value: T | null) => {
            setNewValue(value);
        };

        useEffect(() => {
            setNewValue(value);
            setInitialValue(value);
        }, [isEdit]);

        if (!isEdit) {
            return (
                <div>
                    <span>{renderItem(value)}</span>
                    <Button size={'sm'} variant={'tertiary'} onClick={() => setEdit(!isEdit)} icon={'edit'} />
                </div>
            );
        }

        const canSave = _.get(initalValue, 'id') !== _.get(newValue, 'id');

        const handleOnSave = () => {
            if (canSave && onChange) {
                onChange(newValue);
            }
            setEdit(false);
        };

        return (
            <>
                <div className={'flex flex-row gap-2 items-center'}>
                    <div style={{ flex: '1 1 200px' }}>
                        <Input value={newValue} onChange={handleItemSelect} />
                    </div>
                    <div className={'flex flex-row gap-1'}>
                        <Button
                            size={'small'}
                            variant={'primary'}
                            disabled={!canSave}
                            icon={'save'}
                            onClick={handleOnSave}
                            content={'Save'}
                        />
                        <Button
                            size={'small'}
                            variant={'secondary'}
                            onClick={() => setEdit(false)}
                            content={'Cancel'}
                        />
                    </div>
                </div>
                <DevOnly hidden>
                    <pre>{JSON.stringify({ value, newValue }, null, 2)}</pre>
                </DevOnly>
            </>
        );
    };
}
