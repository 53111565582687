import * as React from 'react';
import { useField } from 'formik';
import { DatePickerButton, DatePickerButtonProps } from '@ez/components';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

interface FormikDatePickerProps extends Omit<DatePickerButtonProps, 'onChange'> {
    name: string;
}

export const FormikDatePicker: React.FC<FormikDatePickerProps> = ({ monthsShown, name, ...props }) => {
    const innerWidth = window?.innerWidth;

    const [field, meta, helpers] = useField(name);

    let _monthsShown;
    if (innerWidth >= 1024) {
        _monthsShown = monthsShown || 3;
    } else if (innerWidth >= 768) {
        _monthsShown = 2;
    } else {
        _monthsShown = 1;
    }

    const handleChange = (date, e) => {
        helpers.setValue(date);
    };

    // Date picker expects Date object
    const value = typeof field.value === 'string' ? new Date(field.value) : field.value;
    return (
        <DatePickerButton
            className={'w-full h-input-base border-input'}
            monthsShown={_monthsShown}
            selected={value}
            onChange={handleChange}
            {...props}
        />
    );
};
