import * as React from 'react';
import { Display } from '@ez/components';
import invariant from 'invariant';

const defaultStyle = {
    padding: '5px 5px',
    marginTop: '0',
    fontSize: '0.9em',
    backgroundColor: '#faf9f2',
    borderRadius: '5px',
    borderColor: '#333333',
    maxHeight: '200px',
    overflow: 'auto',
};

export const Instructions = ({ content, usePre, children, style }: any) => {
    invariant(!(children && content), 'Either children or content should be provided, but not both.');

    const providedStyle = style || {};

    const computedStyle = { ...defaultStyle, ...providedStyle };

    if (!content && !children) return null;

    return (
        <div style={computedStyle}>
            <Display.FormattedText usePre={usePre} value={content || children} />
        </div>
    );
};

export default Instructions;
