import { ConnectionErrorMessage, PageSkeletonLoader, toastError } from '@ez/components';
import { fromEdges, MutationHookOptionsPool, NodeType, useMutationPool } from '@poolware/api';
import * as React from 'react';
import { FileAttachmentsViewer } from '@poolware/app-shell';
import { useQueryPoolFiles } from './use-query-pool-files';
import { Alert } from '@ez/components';

export interface FileAttachmentPanelProps {
    pool: NodeType.Pool;
    readOnly?: boolean;
}

const usePoolMutator = (props: MutationHookOptionsPool) => {
    const mutationPool = useMutationPool(props);

    const addFiles = async (pool: NodeType.Pool, fileIds: NodeType.ID[]) => {
        return await mutationPool.update({
            id: pool.id,
            files: {
                assign: fileIds,
            },
        });
    };

    const removeFile = async (pool: NodeType.Pool, files: NodeType.FileUpload[]) => {
        const fileIds = files.map((f) => f.id);
        return await mutationPool.update({
            id: pool.id,
            files: {
                delete: fileIds,
            },
        });
    };
    return {
        addFiles,
        removeFile,
        ...mutationPool,
    };
};

export const PanelFileAttachments: React.FC<FileAttachmentPanelProps> = ({ pool, readOnly }) => {
    const { loading, error, node, refetchQuery } = useQueryPoolFiles(pool.id);
    const { removeFile, addFiles } = usePoolMutator({ refetchQueries: [refetchQuery] });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!node) {
        return <Alert type={'error'}>Could not load pool details</Alert>;
    }

    const onFileAttachmentRemove = async (files: NodeType.FileUpload[]) => {
        try {
            await removeFile(pool, files);
        } catch (e) {
            toastError(e);
        }
    };

    const onFileUploadComplete = async (fileId) => {
        try {
            await addFiles(pool, [fileId]);
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <FileAttachmentsViewer
            files={fromEdges(node.files)}
            onFileRemove={onFileAttachmentRemove}
            onFileDidUpload={onFileUploadComplete}
            readOnly={readOnly}
        />
    );
};
