import DatePicker, { ReactDatePickerProps } from '@ez/react-datepicker';
import * as React from 'react';
import { ButtonWithPopup, ButtonWithPopupProps } from '../buttons';

const DateCustomInput = React.forwardRef<HTMLButtonElement, ButtonWithPopupProps>((props, ref) => {
    const { onClick, content, value, ...rest } = props;
    return (
        <ButtonWithPopup
            ref={ref}
            variant={'secondary'}
            icon={'calendar'}
            onClick={onClick}
            content={content || value}
            {...rest}
        />
    );
});

export interface DatePickerButtonProps
    extends Omit<ReactDatePickerProps, 'onChange'>,
        Omit<ButtonWithPopupProps, 'tabIndex' | 'name' | 'onChange'> {
    arrowPosition?: 'center' | 'left';
    fluid?: boolean;
    readOnly?: boolean;
    labelPosition?: 'left' | 'right';
    onChange?: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void;
    // TODO: temporary fix while typings are not updated
    [key: string]: any;
}

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({
    arrowPosition,
    icon,
    content,
    size,
    fluid,
    labelPosition,
    color,
    readOnly,
    disabled,
    segmentPosition,
    className,
    ...props
}) => {
    let popperClassName = ['pw-datepicker-popper'];
    if (arrowPosition === 'center') {
        popperClassName.push('pw-react-datepicker__arrow--align-center');
    }
    if (arrowPosition === 'left') {
        popperClassName.push('pw-react-datepicker__arrow--align-left');
    }

    const isDisabled = readOnly || disabled;
    return (
        <>
            <DatePicker
                popperClassName={popperClassName.join(' ')}
                wrapperClassName={'!inline-block'}
                calendarClassName={'pw-datepicker'}
                allowSameDay={true}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={
                    <DateCustomInput
                        fluid={fluid}
                        icon={icon}
                        content={content}
                        size={size}
                        color={color}
                        className={className}
                        segmentPosition={segmentPosition}
                        // labelPosition={labelPosition}
                    />
                }
                disabled={isDisabled}
                {...props}
            />
        </>
    );
};
