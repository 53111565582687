import * as React from 'react';
import { Popup, PopupProps, SemanticCOLORS, StrictPopupProps } from 'semantic-ui-react';
import { useSafeState } from '@ez/tools';
import { ConfirmModal, ConfirmProps } from '../ConfirmModal';
import { clsxm } from '../utils';
import { Icon, SemanticICONS } from '../Icon';
import { Slot } from '@radix-ui/react-slot';

interface MenuItemWithPopupProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'content'> {
    popup?: StrictPopupProps;
    position?: 'top center' | 'bottom center' | 'left center' | 'right center';
    content?: React.ReactNode;
    asChild?: boolean;
}

const MenuBarItemWithPopup: React.FC<MenuItemWithPopupProps> = (props) => {
    const { children, popup, className, content, asChild, ...rest } = props;
    const Comp = asChild ? Slot : 'button';

    const renderMenuItem = (
        <Comp className={clsxm(['ez-menubar-item-base', className])} {...rest}>
            <>
                {content}
                {children}
            </>
        </Comp>
    );

    if (popup?.content) {
        return <Popup trigger={renderMenuItem} position={'top center'} {...popup} />;
    }

    return renderMenuItem;
};

export interface MenuBarItemProps extends MenuItemWithPopupProps {
    color?: SemanticCOLORS;
    icon?: SemanticICONS | React.ReactNode;
    onClick?;
    loading?: boolean;
    // disabled?: boolean;
    responsive?: boolean;
    // Default lock reason is "No Permission"
    // Provide string value to set custom lock reason.
    locked?: boolean | string;

    popup?: StrictPopupProps;
}

export const MenuBarItem: React.FC<MenuBarItemProps> = (props) => {
    const {
        color,
        icon,
        position,
        onClick,
        title,
        children,
        popup = undefined,
        loading = false,
        disabled = false,
        responsive = true,
        locked = false,
        asChild,
        className,
        content,
        ...rest
    } = props;

    const [isMutating, setMutating] = useSafeState(false);

    const handleClick = !!onClick
        ? async () => {
              setMutating(true);
              await onClick?.();
              setMutating(false);
          }
        : undefined;

    let contentIcon;
    let _popup: PopupProps = popup;
    if (isMutating) {
        contentIcon = <Icon loading={true} name={'spinner'} />;
    } else if (locked) {
        if (!_popup && !!locked) {
            if (typeof locked === 'string') {
                _popup = { content: locked };
            } else {
                _popup = { content: 'No Permission' };
            }
        }
        contentIcon = <Icon name={'lock'} />;
    } else if (typeof icon === 'string') {
        contentIcon = <Icon loading={loading} color={color} name={icon as SemanticICONS} />;
    } else {
        contentIcon = icon;
    }

    if (asChild) {
        return (
            <Slot
                className={clsxm(['ez-menubar-item-base', className])}
                // disabled={isMutating || disabled || !!locked}
                {...rest}
            >
                {children}
            </Slot>
        );
    }

    const contentBody =
        title || children || content ? (
            <span className={clsxm(responsive && 'hidden md:block')}>
                {title}
                {content}
                {children}
            </span>
        ) : undefined;

    return (
        <button
            className={clsxm(['ez-menubar-item-base', className])}
            onClick={handleClick}
            disabled={isMutating || disabled || !!locked}
            {...rest}
        >
            <>
                <span className={'text-center'}>{contentIcon}</span>
                {contentBody}
            </>
        </button>
    );
};

export interface MenuBarItemWithConfirmProps extends MenuBarItemProps {
    confirm?: ConfirmProps;
    onCancel?: () => any;
}

export const MenuBarItemWithConfirm: React.FC<MenuBarItemWithConfirmProps> = (props) => {
    const { confirm, onClick, onCancel, ...rest } = props;

    const handleClick = () => {
        return onClick && onClick();
    };

    if (!confirm) {
        return <MenuBarItem {...rest} onClick={handleClick} />;
    } else {
        const trigger = <MenuBarItem {...rest} onClick={undefined} />;
        return (
            <ConfirmModal
                onCancel={onCancel}
                onClick={handleClick}
                confirm={{ showLoaderOnConfirm: true, ...confirm }}
                trigger={trigger}
            />
        );
    }
};
