import * as React from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    StickyMenuBar,
    VStack,
    IconWithPopup,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryServiceJobTemplateConnection } from '../../../queries/use-query-service-job-template-connection';
import { NodeType } from '@poolware/api';
import { ServiceJobTemplatePreview } from '../../../components/ServiceJobTemplatePreview';
import { Icon } from '@ez/components';
import { useViewer } from '@poolware/app-shell';
import { useServiceJobTemplateListActions } from './reducer';
import { SideBarLayout } from './SideBarLayout';
import { ModuleIconNames } from '../../../constants';
import { SideBar } from './Sidebar';

export const ServiceJobTemplateList: React.FC = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const { isAdmin, viewer } = useViewer();
    const { State, Actions } = useServiceJobTemplateListActions();
    const { connectionState, connectionData } = useQueryServiceJobTemplateConnection({
        includeArchived: State.filters?.includeArchived,
        groupId: State.filters?.serviceGroupId,
        pageSize: State.page.size,
        pageIndex: State.page.index,
        includeUnpublished: true,
        setPageIndex: Actions.setPageIndex,
    });

    const onNew = () => {
        AppNavigator.navigateRelative('/sj-template/new', { setOrigin: true });
    };

    const onViewTemplate = (template: NodeType.ServiceJobTemplate) => {
        AppNavigator.navigateRelative(`/sj-template/${template.id}`);
    };

    const tableDef: ConnectionTableDef<NodeType.ServiceJobTemplate> = [
        {
            header: 'Template',
            width: 10,
            cell: (item) => {
                return <ServiceJobTemplatePreview serviceJobTemplate={item} />;
            },
        },
        {
            header: 'Group',
            // width: 3,
            cellProps: { verticalAlign: 'top' },
            cell: (item) => {
                const isGlobal = !item.franchise?.id && !!item.organisationType?.id;
                return (
                    <div>
                        <div>
                            <b className={'text-purple-600'}>{item.group?.title}</b>
                            {isGlobal && (
                                <span className={'text-sm'}>
                                    System Template <Icon name={'globe'} />
                                </span>
                            )}
                        </div>
                        {item.isDefault && (
                            <div>
                                <i>(default)</i>
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            header: '',
            width: 1,
            cellProps: { textAlign: 'center' },
            cell: (item) => {
                const isHQ = item?.franchise?.id !== viewer?.franchise?.id;
                return (
                    <>
                        {isHQ && (
                            <IconWithPopup
                                color={'grey'}
                                popup={{ content: 'HQ Template' }}
                                name={'building outline'}
                            />
                        )}
                        {!item.isPublished && (
                            <IconWithPopup color={'grey'} popup={{ content: 'Draft' }} name={'edit'} />
                        )}
                        {item.canBeRecurring && (
                            <IconWithPopup
                                color={'grey'}
                                popup={{ content: 'This template is preferred for recurring services' }}
                                name={ModuleIconNames.RecurringService}
                            />
                        )}
                    </>
                );
            },
        },

        isAdmin && {
            header: 'Ownership',
            width: 3,
            cell: (item) => (item.franchise?.id ? item.franchise?.name : item.organisationType?.name),
        },
    ];

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem>Service Case Templates</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem icon={'plus'} color={'green'} onClick={onNew}>
                        New
                    </MenuBarItem>
                </MenuBarSection>
            </StickyMenuBar>

            <SideBarLayout
                leftSidebar={<SideBar />}
                main={
                    <DefaultConnectionTable
                        // tableProps={{ fixed: true }}
                        onRowClick={onViewTemplate}
                        tableDef={tableDef}
                        debug={false}
                        connectionData={connectionData}
                        connectionState={connectionState}
                    />
                }
            />
        </VStack>
    );
};
