import * as React from 'react';
import { PageLayout } from '@ez/components';
import { useQueryEzcDeviceLink } from './use-query-ezc-device-state';
import { NodeType } from '@poolware/api';
import { pageSpinner } from '@poolware/app-shell';
import { DeviceStateLifeGuard } from './Lifeguard/DeviceStateLifeGuard';
import { DeviceStateGeneric } from './Generic/DeviceStateGeneric';

const deviceStateRenderer = (device: NodeType.EZCDeviceState) => {
    switch (device.device?.definition?.definitionId) {
        case 'DAVEY:DV_LG:DAVEY_LG:DAVEY_LG_IOT':
            return DeviceStateLifeGuard;
        default:
            return DeviceStateGeneric;
    }
};

export const TabDeviceState: React.FC<{ deviceLink: NodeType.EZCDeviceLink }> = ({ deviceLink }) => {
    const { error, loading, deviceState } = useQueryEzcDeviceLink(deviceLink?.device?.id, { pollInterval: 10000 });
    const spinner = pageSpinner(loading, error, deviceState);
    if (spinner) return spinner;

    const DeviceStateComponent = deviceStateRenderer(deviceState);

    return (
        <PageLayout.BodySection width={'screen-wide'} vStack={true} className={'px-0'}>
            <DeviceStateComponent deviceState={deviceState} />
        </PageLayout.BodySection>
    );
};
