import * as React from 'react';
import styled from 'styled-components';
import { SectionHeader, Segment } from '@ez/components';
import { FilterType, useProductCatalogActions } from '../redux';
import { NodeType } from '@poolware/api';
import { TraitDeclarationPicker_Tree } from '../components-api-connected';

const DeclarationPickerContainer = styled.div`
    max-height: 1000px;
    overflow-y: auto;
`;

export interface FilterByTraitExternalProps {
    header?: React.ReactNode | string;
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
}
export interface FilterByTraitProps extends FilterByTraitExternalProps {}

const FilterByTraitDeclaration_Tree: React.FC<FilterByTraitProps> = ({ header, organisationType }) => {
    const { ProductCatalogAction, ProductCatalogState } = useProductCatalogActions();

    const filterType = FilterType.DECLARATION;
    const traitFilters = ProductCatalogState.getFilterItemsByType(filterType);

    const current: any = traitFilters.map((i) => i.value);

    const onItemSelect = (node) => {
        const checkActive = (pdId) => {
            const found = current.find((c) => {
                return pdId === NodeType.extractId(c);
            });
            return !!found;
        };

        const isActive = checkActive(node.id);

        if (isActive) {
            ProductCatalogAction.removeFilterItem({ type: filterType, filterId: node.id, value: null });
        } else {
            ProductCatalogAction.addFilterItem({ type: filterType, filterId: node.id, value: node });
        }
    };

    const clearAllTraitFilters = () => {
        ProductCatalogAction.removeFilterItem({ type: filterType, filterId: null, value: null });
    };

    return (
        <div>
            <SectionHeader>
                {header || 'Search by Trait'}
                {traitFilters.length > 0 && (
                    <button style={{ float: 'right' }} onClick={clearAllTraitFilters}>
                        clear
                    </button>
                )}
            </SectionHeader>
            <DeclarationPickerContainer>
                <TraitDeclarationPicker_Tree
                    current={current}
                    onSelect={onItemSelect}
                    organisationType={organisationType}
                />
            </DeclarationPickerContainer>
        </div>
    );
};

export default FilterByTraitDeclaration_Tree;
