import * as React from 'react';
import {
    Button,
    ButtonWithPopupProps,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
    Icon,
    Modal,
    SemanticCOLORS,
    useModalCtrl,
} from '@ez/components';
import { mapServiceJobStageTypeToIconProps, ModuleColorNames } from '../constants';
import { NodeType } from '@poolware/api';
import { useSafeState } from '@ez/tools';
import { useViewer } from '@poolware/app-shell';
import { PanelStageHistory, PanelStageHistoryType } from './PanelStageHistory';

const mapBaseStageTypeToLabel = (st: NodeType.ServiceJobStageStatus): string => {
    switch (st) {
        default:
        case NodeType.ServiceJobStageStatus.Active:
            return 'Active';
        case NodeType.ServiceJobStageStatus.Suspended:
            return 'Suspend';
        case NodeType.ServiceJobStageStatus.Closed:
            return 'Closed';
    }
};

const NO_MUTATION = '____NO_MUTATIONS____';

const _stageButtonStyle: any = {
    whiteSpace: 'nowrap',
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
};

const StageButton: React.FC<{
    stages: NodeType.ServiceJobStage[];
    stageBasicType: NodeType.ServiceJobStageStatus;
    activeStage: NodeType.ServiceJobStage;
    onChange: (stage: NodeType.ServiceJobStage) => Promise<any>;
    color?: SemanticCOLORS;
    size?: any;
    position?: 'first' | 'last' | 'middle' | 'auto';
}> = ({
    onChange,
    stages,
    activeStage,
    color = ModuleColorNames.StatusChangeButton,
    size = 'sm',
    position = 'auto',
    stageBasicType,
}) => {
    const [mutatingValue, setMutatingValue] = useSafeState(NO_MUTATION);

    if (stages.length === 0) {
        return null;
    }

    const handleClick = async (stage: NodeType.ServiceJobStage) => {
        if (!onChange) return;
        setMutatingValue(stage?.id);
        try {
            await onChange(stage);
        } catch (e) {}
        setMutatingValue(NO_MUTATION);
    };

    const activeStageInGroup = stages.find((s) => !!activeStage && s.id === activeStage?.id);
    const isActiveGroup = !!activeStageInGroup;

    const _color = isActiveGroup ? 'primary' : 'grey';
    const isMutating = mutatingValue !== NO_MUTATION;

    const isLoading = isMutating;
    const buttonProps: Omit<ButtonWithPopupProps, 'content'> = {
        disabled: isMutating,
        color: _color,
        size: size,
    };

    if (stages.length === 1) {
        const stage = stages[0];
        const isActiveStage = activeStage?.id === stage.id;
        const icon = <Icon {...mapServiceJobStageTypeToIconProps(stage?.type)} />;
        const content = stage?.title;

        return (
            <Button
                {...buttonProps}
                loading={isLoading}
                size={size}
                variant={isActiveStage ? 'primary' : 'secondary'}
                onClick={() => handleClick(stage)}
                iconLeft={icon}
            >
                {content}
            </Button>
        );
    } else {
        const content = activeStageInGroup?.title || mapBaseStageTypeToLabel(stageBasicType);
        const icon = <Icon {...mapServiceJobStageTypeToIconProps(activeStageInGroup?.type)} />;
        const options = stages.map((s) => ({
            value: s,
            text: s.title,
            icon: <Icon {...mapServiceJobStageTypeToIconProps(s.type)} />,
            stage: s,
        }));

        return (
            <DropdownMenu>
                <DropdownMenuTrigger {...buttonProps} variant={isActiveGroup ? 'primary' : 'secondary'}>
                    {icon} {content}
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    value={activeStage}
                    options={options}
                    valueComparator={(a, b) => a.id === b.id}
                    onChange={(option) => handleClick(option.value)}
                />
            </DropdownMenu>
        );

        // const trigger = (
        //     <Button {...buttonProps} variant={isActiveGroup ? 'primary' : 'secondary'}>
        //         <span>{icon}</span> {content}
        //     </Button>
        // );
        //
        // return (
        //     <Dropdown icon={false} floating className="icon" fluid={true} trigger={trigger}>
        //         <Dropdown.Menu>
        //             {stages.map((s) => {
        //                 const isActive = s.id == activeStage?.id;
        //                 return (
        //                     <Dropdown.Item key={s.id} active={isActive} onClick={() => handleClick(s)}>
        //                         <Icon {...mapServiceJobStageTypeToIconProps(s.type)} />
        //                         {s.title}
        //                     </Dropdown.Item>
        //                 );
        //             })}
        //         </Dropdown.Menu>
        //     </Dropdown>
        // );
    }
};

export const ContainerButtonsChangeStatus: React.FC = (props) => (
    <div
        className={
            'px-2 py-4 mb-4 rounded-sm shadow-inner bg-panel border-solid border-blue-400 border border-l-0 border-r-0 w-full flex flex-col gap-2 '
        }
        {...props}
    />
);

export const ButtonsChangeJobStage: React.FC<{
    onChangeStage: (newStage: NodeType.ServiceJobStage) => Promise<any>;
    stages: NodeType.ServiceJobStage[];
    stageHistory?: PanelStageHistoryType;
    stage: NodeType.ServiceJobStage;
}> = ({ onChangeStage, stages, stage, stageHistory }) => {
    const { appLayoutMode } = useViewer();
    const modalCtrl = useModalCtrl();
    const stagesOpen = stages.filter((s) => s.type === NodeType.ServiceJobStageType.Opened);
    const stagesInProg = stages.filter((s) => s.type === NodeType.ServiceJobStageType.InProgress);
    const stagesAcReq = stages.filter((s) => s.type === NodeType.ServiceJobStageType.ActionRequired);
    const stagesSusp = stages.filter((s) => s.type === NodeType.ServiceJobStageType.Suspended);
    const stagesClosed = stages.filter(
        (s) => s.type === NodeType.ServiceJobStageType.Finished || s.type === NodeType.ServiceJobStageType.Canceled
    );

    const stageButtons = (
        <>
            <StageButton
                activeStage={stage}
                stages={stagesOpen}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Active}
                position={'first'}
            />
            <StageButton
                activeStage={stage}
                stages={stagesInProg}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Active}
            />
            <StageButton
                activeStage={stage}
                stages={stagesAcReq}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Active}
            />
            <StageButton
                activeStage={stage}
                stages={stagesSusp}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Suspended}
            />
            <StageButton
                activeStage={stage}
                stages={stagesClosed}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Closed}
                position={'last'}
            />
        </>
    );
    const icon = <Icon {...mapServiceJobStageTypeToIconProps(stage?.type)} />;
    return (
        <>
            <ContainerButtonsChangeStatus>
                {appLayoutMode.gteTablet ? (
                    <div className={'flex-grow flex flex-row gap-3'}>
                        {stageButtons}
                        {stageHistory && (
                            <>
                                <div className={'flex-grow'} />
                                <Button
                                    size={'small'}
                                    basic={true}
                                    style={_stageButtonStyle}
                                    icon={'history'}
                                    onClick={modalCtrl.onOpen}
                                />
                            </>
                        )}
                    </div>
                ) : (
                    <div className={'flex-grow flex flex-col gap-2'}>
                        <div className={'flex flex-row'}>
                            <b>Status</b>:
                            <div className={'pl-3'}>
                                {icon} {stage?.title}
                            </div>
                        </div>
                        <Modal
                            centered={false}
                            trigger={<Button size={'small'} color={'blue'} content={'Change Status'} />}
                        >
                            <div className={'flex-grow flex flex-col gap-6 p-4 bg-white rounded shadow'}>
                                {stageButtons}
                            </div>
                        </Modal>
                    </div>
                )}
            </ContainerButtonsChangeStatus>
            {stageHistory && (
                <Modal size={'tiny'} {...modalCtrl}>
                    <PanelStageHistory stageHistory={stageHistory} />
                </Modal>
            )}
        </>
    );
};
