import * as React from 'react';
import { deserializeColorPalette } from '@poolware/api';
import TimeFieldGroup from './FormFields/TimeField';
import { FormGroup, Icon, VStack } from '@ez/components';
import { recurrenceToString } from '../utils';
import AppointmentGroupField from './FormFields/AppointmentGroupField';
import ColorField from './FormFields/ColorField';
import { FormikCustomerAndLocationInputFields, FormikSuggestInputStaff } from '@poolware/app-shell';
import { useFormikContext } from 'formik';
import { AppointmentFormValuesType } from './AppointmentForm';

export interface AppointmentDetailsTabProps {}

export const FormTabAppointmentDetails: React.FC<AppointmentDetailsTabProps> = ({}) => {
    const { values } = useFormikContext<AppointmentFormValuesType>();

    const { group, isRecurring, recurrence } = values;
    const palette = deserializeColorPalette(group);

    return (
        <VStack>
            <TimeFieldGroup />
            {isRecurring && (
                <div className={'p-2 rounded bg-gray-50'}>
                    <Icon name={'refresh'} />
                    {recurrenceToString(recurrence)}
                </div>
            )}
            <FormGroup>
                <AppointmentGroupField label={'Appointment Group'} name={'group'} />
                <ColorField label={'Color Tag'} name={'color'} palette={palette} />
            </FormGroup>

            <FormikSuggestInputStaff label={'Assigned To'} readOnly={values.staffReadOnly} name={'staff'} />
            <FormikCustomerAndLocationInputFields readOnly={values.customerReadOnly} />
        </VStack>
    );
};
