import React from 'react';
import EventCell from './EventCell';
import { isSelected } from './utils/selection';
import { DateSlotMetricsType } from './utils/DateSlotMetrics';

export interface EventRowMixinProps {
    slotMetrics: ReturnType<DateSlotMetricsType>;
    selected?: any;
    isAllDay?: boolean;
    onSelect?: any;
    onDoubleClick?: any;
}

export default {
    defaultProps: {
        segments: [],
        selected: {},
    },

    renderEvent(props: EventRowMixinProps, event) {
        let { selected, onSelect, onDoubleClick, slotMetrics } = props;

        let continuesPrior = slotMetrics.continuesPrior(event);
        let continuesAfter = slotMetrics.continuesAfter(event);

        return (
            <EventCell
                event={event}
                onSelect={onSelect}
                onDoubleClick={onDoubleClick}
                continuesPrior={continuesPrior}
                continuesAfter={continuesAfter}
                slotStart={slotMetrics.first}
                slotEnd={slotMetrics.last}
                selected={isSelected(event, selected)}
            />
        );
    },

    renderSpan(slots, len, key, content: any = ' ') {
        let per = (Math.abs(len) / slots) * 100 + '%';

        return (
            <div
                key={key}
                className="rbc-row-segment"
                // IE10/11 need max-width. flex-basis doesn't respect box-sizing
                style={{ WebkitFlexBasis: per, flexBasis: per, maxWidth: per }}
            >
                {content}
            </div>
        );
    },
};
