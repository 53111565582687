import { ThemeInterface } from '../app-theme';
import { createThemeByHue } from '@ez/components';

let _t: ThemeInterface = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    const huePrim = 180;
    _t = createThemeByHue({
        publicPrefix,
        huePrim: huePrim,
        hueSec: 28,
        customisation: {
            logoPath: `${publicPrefix}/logo.svg`,
            themeName: 'aquatest',
            // @ts-ignore
            navbar: {
                separatorColor: 'hsl(48,100%,62%)',
                logo: {
                    backgroundColor: '#dae3e3',
                },
            },
            border: {
                radiusSm: '0.375rem',
                radius: '0.5rem',
                radiusMd: '0.75rem',
                radiusXl: '1.0rem',
                radius2xl: '1.5rem',
                radius3xl: '2rem',
                color: `hsl(${huePrim}, 10%, 85%)`,
                colorStrong: `hsl(${huePrim}, 40%, 80%)`,
            },
        },
    });

    return _t;
};
