import { createThemeByHue } from '@ez/components';
import moment from 'moment';

let _t = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    const huePrim = 210;
    _t = createThemeByHue({
        publicPrefix,
        huePrim: 210,
        hueSec: 42,
        customisation: {
            themeName: 'poolware',
        },
    });

    const today = moment();
    if (today.date() === 31 && today.month() === 9) {
        // Boo!
        _t.navbar.separatorColor = 'hsl(24, 98%, 63%)';
        _t.sidebar.menuItem.backgroundColorHover = 'hsl(22,100%,36%)';
        _t.logoPath = `${publicPrefix}/logo-halloween.png`;
    }
    return _t;
};
