import * as React from 'react';
import { NotesFeed } from '../../components/NotesFeed';
import { createUseQueryNodeHook, fromEdges, NodeType, staffFragment, useMutationWorkOrderNote } from '@poolware/api';
import { JobNote } from '../../components/NotesFeed/NotesFeedEvent';
import { PageSkeletonLoader, Panel, toastError } from '@ez/components';
import gql from 'graphql-tag';
import { NotFoundPage } from '@poolware/app-shell';

const QL = gql`
    query QueryWorkOrderNotes($id: ID!) {
        node(id: $id) {
            id
            ... on WorkOrder {
                workOrderNumber
                notes(sort: { createdAt: false }) {
                    edges {
                        node {
                            id
                            note
                            createdAt
                            updatedAt
                            addedBy {
                                ...StaffFragment
                            }
                            parent {
                                id
                            }
                            checkMutations {
                                delete
                                update
                            }
                        }
                    }
                }
            }
        }
    }
    ${staffFragment}
`;

export interface WorkOrderNotesPanelProps {
    workOrder: NodeType.WorkOrder;
    maxHeight?: number;
}

export const useQueryWorkOrder = createUseQueryNodeHook<NodeType.WorkOrder>(QL);

export const WorkOrderNotesPanel: React.FC<WorkOrderNotesPanelProps> = ({ workOrder, maxHeight = 2000 }) => {
    const { loading, error, node, refetchQuery } = useQueryWorkOrder(workOrder.id, { query: QL });
    const mutator = useMutationWorkOrderNote({
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery],
    });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (!node) {
        return <NotFoundPage />;
    } else if (error) {
        return <div>Error</div>;
    }

    const notes = fromEdges(node?.notes);

    const onUpdate = async (values: JobNote) => {
        try {
            await mutator.update({ id: values.id, note: values.note });
        } catch (e) {
            toastError(e);
        }
    };

    const onDelete = async (values: JobNote) => {
        try {
            await mutator.delete({ id: values.id });
        } catch (e) {
            toastError(e);
        }
    };

    const onAdd = async (values) => {
        try {
            await mutator.create({
                parent: workOrder?.id,
                note: values.note,
            });
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Header size={'small'} icon={'sticky note outline'}>
                Job Notes
            </Panel.Header>
            <Panel.Body>
                <NotesFeed
                    mode={'inputbottom'}
                    maxHeight={maxHeight}
                    onUpdate={onUpdate}
                    onAdd={onAdd}
                    notes={notes}
                    onDelete={onDelete}
                />
            </Panel.Body>
        </Panel>
    );
};
