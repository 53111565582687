import { NodeType } from '@poolware/api';
import * as React from 'react';
import {
    Alert,
    clsxm,
    ConnectionTableDef,
    DebugJsonButton,
    DefaultConnectionTable,
    Icon,
    PageLayout,
    Panel,
    VStack,
} from '@ez/components';
import { useAppConfig } from '@poolware/app-shell';
import { DeviceStateType, LifeGuardShadowType } from '../types';
import { DisplayLGTimer } from './DisplayLGTimer';

const tableDef: ConnectionTableDef<any> = [
    {
        header: 'Target',
        width: 1,
        cell: (s) => s.name,
    },
    {
        header: 'Value',
        cell: (s) => s.value,
    },
    {
        header: 'Setpoint',
        cell: (s) => s.setpoint,
    },
    {
        header: 'Connected',
        cell: (s) => {
            if (s.isConnected === undefined || s.isConnected === null) return '--';
            return s.isConnected ? 'Connected' : 'Not Connected';
        },
    },
];

const mapMeasurements = (state: DeviceStateType<LifeGuardShadowType>) => {
    const stateReported = state.reported;
    if (!stateReported) return [];
    return [
        {
            name: 'pH',
            value: stateReported?.phSensorValue,
            setpoint: stateReported?.phSetpoint,
            isConnected: stateReported.phSensorConnected,
        },
        {
            name: 'ORP',
            value: stateReported?.orpSensorValue,
            setpoint: stateReported?.orpSetpoint,
            isConnected: stateReported?.orpSensorConnected,
        },
        {
            name: 'Temperature',
            value: stateReported?.temperatureSensorValue,
            setpoint: stateReported?.temperatureSensorConnected,
            isConnected: stateReported?.temperatureSensorConnected,
        },
        // { name: 'Salinity', value: '8', setpoint: '2000', isConnected: true },
        // { name: 'VSD', value: 'ON', setpoint: '10', isConnected: false },
        { name: 'Cell output', value: stateReported?.cellOutput, setpoint: '--', isConnected: null },
        {
            name: 'Boost Duration',
            value: stateReported?.boostDuration,
            setpoint: stateReported?.boostDuration,
            isConnected: null,
        },
        {
            name: 'Backwash Duration',
            value: stateReported?.backwashDuration,
            setpoint: stateReported?.backwashDuration,
            isConnected: null,
        },
    ];
};

const ConnectionStatus: React.FC<{ isConnected: boolean }> = ({ isConnected }) => {
    const label = isConnected ? 'Connected' : 'Not Connected';
    return (
        <div className={'flex flex-row gap-1'}>
            <div className={clsxm('text-accent font-bold')}>{label}</div>
            <Icon name={'circle'} color={isConnected ? 'green' : 'red'} />
        </div>
    );
};

export interface DeviceStateLifeGuardProps {
    deviceState: NodeType.EZCDeviceState;
}

export const DeviceStateLifeGuard: React.FC<DeviceStateLifeGuardProps> = ({ deviceState }) => {
    const config = useAppConfig();

    if (!deviceState) {
        return <Alert type={'warning'}>Device state is not available</Alert>;
    }

    const state: DeviceStateType<LifeGuardShadowType> = deviceState.state?.lifeguard || {};
    const stateReported = state.reported;
    const isAlive = state.isAlive;

    const formatURLs = (device: NodeType.EZCDevice) => {
        const urlLG = config.apps.lifeguardPortal
            ? `${config.apps.lifeguardPortal}/deviceDetails?sn=${device.deviceId}`
            : null;
        const urlEZ = config.apps.ezConnectAdmin ? `${config.apps.ezConnectAdmin}/devices/${device.ezcId}` : null;
        return { urlLG, urlEZ };
    };
    const extURLs = formatURLs(deviceState.device);

    const measurements = mapMeasurements(state);
    return (
        <>
            <PageLayout.TwoColumns>
                <VStack>
                    <Panel>
                        <Panel.Header
                            content={
                                <div className={'flex flex-row gap-2'}>
                                    <span>Device Status</span>
                                </div>
                            }
                            button={[
                                <DebugJsonButton size={'xs'} data={deviceState} variant={'tertiary'} />,
                                <ConnectionStatus isConnected={isAlive} />,
                            ]}
                        />
                        <Panel.Body>
                            <DefaultConnectionTable
                                // onRowClick={onView}
                                tableDef={tableDef}
                                connectionData={measurements}
                                connectionState={undefined}
                            />
                        </Panel.Body>
                    </Panel>{' '}
                </VStack>

                <VStack>
                    <Panel>
                        <Panel.Header content={'Active Modes'} />
                        <Panel.Body>
                            <div className={'flex flex-row flex-wrap gap-1'}>
                                {stateReported?.modes?.map((mode, index) => (
                                    <div className={'rounded px-2 bg-accent text-accent-foreground'} key={index}>
                                        {mode}
                                    </div>
                                ))}
                            </div>
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Header content={'Schedules'} />
                        <Panel.Body>
                            <Panel.Item label={'Timer 1'}>
                                <DisplayLGTimer timer={stateReported.timer0} />
                            </Panel.Item>
                            <Panel.Item label={'Timer 2'}>
                                <DisplayLGTimer timer={stateReported?.timer1} />
                            </Panel.Item>
                        </Panel.Body>
                    </Panel>
                    {/*<Panel>*/}
                    {/*    <Panel.Header content={'Other Parameters'} />*/}
                    {/*    <Panel.Body>*/}
                    {/*        <div>*/}
                    {/*            <Panel.Item label={'Heat Pump'}>122222</Panel.Item>*/}
                    {/*            <Panel.Item label={'Acid Pump'}>Not connected</Panel.Item>*/}
                    {/*        </div>*/}
                    {/*    </Panel.Body>*/}
                    {/*</Panel>*/}
                    <Panel>
                        <Panel.Header content={'Configuration'} />
                        <Panel.Body>
                            <div>
                                <Panel.Item label={'Firmware Version'}>{stateReported?.firmwareVersion}</Panel.Item>
                                {/*<Panel.Item label={'Cell Size'}>grams</Panel.Item>*/}
                                {/*<Panel.Item label={'Pool SIze'}>litres</Panel.Item>*/}
                                {/*<Panel.Item label={'Region'}>ANZ</Panel.Item>*/}
                                {(extURLs.urlEZ || extURLs.urlLG) && (
                                    <Panel.Item label={'External Services'}>
                                        {extURLs.urlLG && (
                                            <a
                                                href={extURLs.urlLG}
                                                target={'_blank'}
                                                className={'pr-4'}
                                                rel="noreferrer"
                                            >
                                                Lifeguard Portal <Icon name={'external'} />
                                            </a>
                                        )}
                                        {extURLs.urlEZ && (
                                            <a href={extURLs.urlEZ} target={'_blank'} rel="noreferrer">
                                                EZ Connect <Icon name={'external'} />
                                            </a>
                                        )}
                                    </Panel.Item>
                                )}
                            </div>
                        </Panel.Body>
                    </Panel>
                </VStack>
                {/*<DebugJSON data={stateReported}/>*/}
            </PageLayout.TwoColumns>
        </>
    );
};
