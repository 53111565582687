import * as React from 'react';
import { useMemo } from 'react';
import * as _ from 'lodash';
import { NodeType, useQueryStaffConnection } from '@poolware/api';
import { Display, Dropdown, stringFormatters } from '@ez/components';
import { useSafeState } from '@ez/tools';

const STAFF_UNSET = '____STAFF_UNSET____';

const prepareOptions = (staffList: NodeType.Staff[], truncateLength?: number) => {
    let options = staffList.map((staff) => {
        let name = stringFormatters.formatEntityName(staff);
        if (truncateLength) {
            name = _.truncate(name, { length: truncateLength });
        }
        return {
            key: staff.id,
            text: name,
            value: staff.id,
            staff,
        };
    });

    return [{ key: '0', text: '-----', value: STAFF_UNSET, staff: null }].concat(options);
};

export interface StrictStaffDropdownExternalProps {
    onChange: (value: NodeType.Staff) => any | Promise<any>;
    value: NodeType.ID;
    component?: any;
    showDisabled?: boolean;
    showDeleted?: boolean;
    truncateLength?: number;
    readonly?: boolean;
}

export interface StaffDropdownExternalProps extends StrictStaffDropdownExternalProps {
    [key: string]: any;
}

export const DropdownStaff: React.FC<StaffDropdownExternalProps> = (props) => {
    const {
        value, //
        onChange,
        truncateLength = 0,
        showDeleted = false,
        showDisabled = false,
        readonly = false,
        ...rest
    } = props;

    const { connectionState, connectionData } = useQueryStaffConnection({
        showDeleted: showDeleted,
        showDisabled: showDisabled,
    });

    const [isUpdating, setUpdating] = useSafeState(false);

    const options = useMemo(() => {
        return prepareOptions(connectionData, truncateLength);
    }, [connectionData]);

    const handleChange = async (e, { value }) => {
        setUpdating(true);
        const option = _.find(options, { value: value }) || { staff: null };
        await props.onChange?.(option.staff);
        setUpdating(false);
    };

    if (readonly) {
        const opt = options.find((o) => o.value === value);
        return <Display.Entity value={opt?.staff} defaultString={'--'} />;
    }

    return (
        <Dropdown
            disabled={readonly}
            lazyLoad={true}
            options={options}
            value={value || STAFF_UNSET}
            closeOnChange={true}
            loading={connectionState.loading || isUpdating}
            scrolling
            item
            onChange={handleChange}
            {...rest}
        />
    );
};
