import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { useFormikContext } from 'formik';
import { AppointmentFormValuesType } from '../AppointmentForm';
import {
    Alert,
    confirmModalImperative,
    ConfirmProps,
    Dropdown,
    Icon,
    Modal,
    Panel,
    PrintSectionColumn,
    ScrollY,
    SectionHeader,
    toastError,
    useModalCtrl,
    VStack,
    Button,
} from '@ez/components';
import { useWorkOrderMutators } from '../../../queries/mutators-work-order';
import * as _ from 'lodash';
import { IconServiceJobStage, mapServiceJobStageTypeToName, ModuleIconNames } from '../../../constants';
import { ServiceJobLink, WorkOrderLink } from '../../../Links';
import { WorkOrderJobItemsPanel } from '../../../ServiceJobs/Print/WorkOrderPrintView/WorkOrderItemPanel';
import { ServiceJobTemplatePicker } from '../../../connected-components/ServiceJobTemplatePicker';

const confirmMessageReplaceTemplate: ConfirmProps = {
    confirmMessage: {
        header: 'Replace Work Order items?',
        content: (
            <div>
                <p>Existing work order items will be removed and replaced with the items from the selected template</p>
            </div>
        ),
    },
    confirmButton: { content: 'Replace' },
    cancelButton: { content: 'Cancel' },
};

const ButtonOpenWo: React.FC<{ onOpen; onReplace }> = ({ onReplace, onOpen }) => {
    if (!onReplace) {
        return (
            <Button color={'blue'} onClick={onOpen}>
                Open W/O
            </Button>
        );
    }

    const options = [
        { key: 'replace', icon: 'retweet', text: 'Replace W/O Template', value: 'replace', onClick: onReplace },
    ];

    return (
        <div>
            <Button style={{ marginRight: -1 }} onClick={onOpen}>
                Open W/O
            </Button>
            <Dropdown style={{ borderLeft: '0' }} className="button icon" floating options={options} trigger={<></>} />
        </div>
    );
};

export const WorkOrderDetailsView: React.FC<{
    refetchQuery: any;
    onOpenWorkOrder: (wo: NodeType.WorkOrder) => any;
}> = ({ refetchQuery, onOpenWorkOrder }) => {
    const modalWOPicker = useModalCtrl();
    const { values } = useFormikContext<AppointmentFormValuesType>();
    const { replaceWorkOrderTemplate } = useWorkOrderMutators({ refetchQueries: [refetchQuery] });
    const { serviceJob, workOrder } = values;

    if (serviceJob && !workOrder) {
        return (
            <Alert type={'error'}>
                <div>
                    <ServiceJobLink id={serviceJob.id}>
                        Service Case {serviceJob.serviceJobNumber} - {serviceJob.title}
                    </ServiceJobLink>
                </div>
                <div>Failed to load work order</div>
            </Alert>
        );
    }

    const onClickOpenTemplatePicker = () => {
        modalWOPicker.onOpen();
    };

    const groupTitle = serviceJob?.group?.title;
    const jobTodos = fromEdges(workOrder?.jobs).sort((l, r) => {
        return l.index > r.index ? 1 : -1;
    });

    const woTitle = _.truncate(workOrder?.title, { length: 40 });
    const headerTitle = `${mapServiceJobStageTypeToName(workOrder.stage?.type)} - ${woTitle}`;

    const onClickOpenWO = () => {
        onOpenWorkOrder(workOrder);
    };

    const onSelectJobTemplate = async (
        serviceTemplate: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        modalWOPicker.onClose();

        try {
            if (!workOrderTemplates || workOrderTemplates?.length === 0) {
                toastError({ title: 'Work order template required', message: 'Work order template is not provided' });
                return;
            }
            const res = await confirmModalImperative(confirmMessageReplaceTemplate);
            if (!res) {
                return;
            }
            await replaceWorkOrderTemplate({ template: workOrderTemplates?.[0]?.id, id: workOrder.id });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };
    return (
        <VStack>
            <SectionHeader
                size={'small'}
                icon={<IconServiceJobStage stage={workOrder?.stage} />}
                content={headerTitle}
                button={<ButtonOpenWo onOpen={onClickOpenWO} onReplace={onClickOpenTemplatePicker} />}
            />
            <div>
                {serviceJob && (
                    <Panel.Item iconMarker={ModuleIconNames.ServiceCase} label={'Service Case'}>
                        <ServiceJobLink id={serviceJob.id}>#{serviceJob.serviceJobNumber}</ServiceJobLink>
                        {serviceJob?.title && <span> - {serviceJob?.title}</span>}
                        {groupTitle && <span className={'pl-1 font-bold text-purple-600'}>({groupTitle})</span>}
                        {serviceJob.isRecurring && (
                            <span className={'pl-1'}>
                                / <Icon name={'refresh'} /> Recurring
                            </span>
                        )}
                    </Panel.Item>
                )}
                <Panel.Item iconMarker={ModuleIconNames.WorkOrder} label={'Work Order'}>
                    <WorkOrderLink id={workOrder.id}>#{workOrder.workOrderNumber}</WorkOrderLink> - {woTitle}
                </Panel.Item>
                <Panel.Divider />
                <ScrollY maxHeight={300}>
                    {jobTodos.map((jobTodo) => {
                        return (
                            <div key={jobTodo.id} className={'bg-gray-100 p-1'}>
                                <PrintSectionColumn>
                                    <WorkOrderJobItemsPanel jobTodo={jobTodo} />
                                </PrintSectionColumn>
                            </div>
                        );
                    })}
                </ScrollY>
            </div>
            <Modal {...modalWOPicker}>
                {modalWOPicker.open && (
                    <ServiceJobTemplatePicker
                        onCancel={modalWOPicker.onClose}
                        onSubmit={onSelectJobTemplate}
                        selectWorkOrder={'single'}
                    />
                )}
            </Modal>
        </VStack>
    );
};
