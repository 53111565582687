import * as React from 'react';
import { Button, Segment } from '@ez/components';
import { useServiceJobListActions } from '../../redux';

export const ClearAllFilters: React.FC = () => {
    const { Actions, State } = useServiceJobListActions();
    const clearAll = () => {
        Actions.clearAllFilters();
    };

    const { hasActiveFilters } = State.filters;
    return (
        <Segment>
            <Button
                disabled={!hasActiveFilters}
                size={'small'}
                icon={'close'}
                className={'w-full'}
                color={hasActiveFilters ? 'purple' : 'grey'}
                onClick={clearAll}
                content={'Clear all filters'}
            />
        </Segment>
    );
};
