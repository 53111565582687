import * as React from 'react';
import { useWorkOrderListActions, WorkOrderBookingStatus } from '../../redux';
import { LinkButton, Optionable, SegmentedSelectButtons } from '@ez/components';
import { ModuleColorNames } from '../../constants';
import { TopFilterBar } from '../../components/TopFilterBar';

export const FilterByBookingStatusButtons: React.FC = () => {
    const { WorkOrderListAction, WorkOrderListState } = useWorkOrderListActions();
    const { bookingStatus } = WorkOrderListState.filters;

    const allOptions: Optionable<WorkOrderBookingStatus>[] = [
        {
            text: 'All',
            value: WorkOrderBookingStatus.ANY,
        },
        {
            text: 'Scheduled',
            icon: 'calendar check outline',
            value: WorkOrderBookingStatus.BOOKED,
        },
        {
            text: 'Unscheduled',
            icon: 'calendar times outline',
            value: WorkOrderBookingStatus.UNBOOKED,
        },
    ];
    return (
        <SegmentedSelectButtons
            fluid={false}
            color={ModuleColorNames.WorkOrder}
            options={allOptions}
            value={bookingStatus}
            onChange={(option) => {
                WorkOrderListAction.setBookingFilter(option.value);
            }}
        />
    );
};

const FilterSummary: React.FC = () => {
    const { WorkOrderListAction, WorkOrderListState } = useWorkOrderListActions();
    const filters = WorkOrderListState.filters;
    if (!filters?.hasActiveFilters) {
        return <div className={' filter-summary'} />;
    }

    return (
        <>
            <div className={'label'}>Filtering by</div>
            {!!filters.staffIds?.length && <span>Staff</span>}
            {!!filters.workOrderNumber && <span>Work ID</span>}
            {/*{!!filters.includeStatuses?.length && <span>Status</span>}*/}
            {/*{filters.bookingStatus !== WorkOrderBookingStatus.ANY && <span>Booking</span>}*/}
            <LinkButton onClick={WorkOrderListAction.clearAllFilters} content={'clear x'} />
        </>
    );
};

export const ToolbarQuickFilters: React.FC<{ showSidebar?; setShowSidebar? }> = ({ showSidebar, setShowSidebar }) => {
    const { WorkOrderListState } = useWorkOrderListActions();
    const { hasActiveFilters } = WorkOrderListState.filters;

    return (
        <TopFilterBar data-testid={'ToolbarContainer'}>
            <TopFilterBar.Row>
                <FilterByBookingStatusButtons />
                {/*<ButtonWithPopup*/}
                {/*    popup={{ content: 'Search Filters' }}*/}
                {/*    basic*/}
                {/*    compact*/}
                {/*    color={showSidebar || hasActiveFilters ? 'purple' : 'grey'}*/}
                {/*    onClick={() => setShowSidebar(!showSidebar)}*/}
                {/*    // content={hasActiveFilters ? <span>Filtering</span> : undefined}*/}
                {/*    icon={'filter'}*/}
                {/*/>*/}
                <TopFilterBar.Spacer />
                {/*<FilterByStatusButtons />*/}
            </TopFilterBar.Row>
            <TopFilterBar.RowSummary>
                <FilterSummary />
            </TopFilterBar.RowSummary>
        </TopFilterBar>
    );
};
