import Page from './Page';

import { compose, mapProps } from '@ez/tools';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { fromEdges } from '@poolware/api';

import { withApolloLoading } from '@ez/components';

export const VendQuery = gql`
    query VendSalesQuery($customerId: ID!) {
        customer: node(id: $customerId) {
            id
            ... on Customer {
                id
                vendLink {
                    id
                    vendURL
                    vendCustomer {
                        id
                        sales(first: 100) {
                            edges {
                                node {
                                    id
                                    note
                                    status
                                    date
                                    webRegistryUrl
                                    historyUrl
                                    totalPrice
                                    totalPriceInclTax
                                    totalTax
                                    vendUser {
                                        displayName
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default compose(
    graphql(VendQuery, {
        options: (props: any) => ({
            variables: { customerId: props.customerId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.customer.id', loaderPaths: 'data.customer' }),
    mapProps((props) => {
        let sales = fromEdges(props?.data?.customer?.vendLink?.vendCustomer?.sales);
        return {
            ...props,
            sales,
        };
    })
)(Page);
