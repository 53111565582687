import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';

import { withApolloLoading } from '@ez/components';
import {
    addressFragment,
    customerFragment,
    fromEdges,
    IMutateSite,
    NodeType,
    siteFragment,
    withSiteMutator,
} from '@poolware/api';

import Page from './Page';
import { IWithPageProps, withPageProps } from '../../../../components/withPageProps';

const SiteEditQuery = gql`
    query SiteEditQuery($siteId: ID!) {
        viewer {
            site: resolveNode(id: $siteId) {
                ... on Site {
                    id
                    ...SiteFragment

                    entity {
                        id
                        user {
                            customer {
                                id
                                ...CustomerFragment
                            }
                        }
                        addresses(first: 1000) {
                            edges {
                                node {
                                    ...AddressFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${customerFragment}
    ${siteFragment}
    ${addressFragment}
`;

export default compose(
    withPageProps(),
    graphql(SiteEditQuery, {
        options: (props: any) => {
            const siteId = props.params.siteId || props.siteId;
            return {
                variables: { siteId: siteId },
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.viewer.site.id' }),
    mapProps((props) => {
        const site = props?.data?.viewer?.site;
        const pools = fromEdges(site?.pools);
        const customer = site?.entity?.user?.customer;
        return {
            ...props,
            site,
            pools,
            customer,
        };
    }),
    withSiteMutator(['CustomerSitesList', 'CustomerQuery'])
)(Page);

export interface PageControlProps extends IWithPageProps, IMutateSite {
    site: NodeType.Site;
    customer: NodeType.Customer;
    pools: NodeType.Pool[];
}
