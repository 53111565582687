import * as React from 'react';
import { Button } from '@ez/components';
import { useWorkOrderFeedActions } from '../../redux';

export const ClearAllFilters: React.FC = () => {
    const { Action, State } = useWorkOrderFeedActions();
    const clearAll = () => {
        Action.clearAllFilters();
    };

    const { hasActiveFilters } = State.filters;
    return (
        <div className={'p-1'}>
            <Button
                disabled={!hasActiveFilters}
                size={'sm'}
                icon={'close'}
                color={hasActiveFilters ? 'purple' : 'grey'}
                onClick={clearAll}
                content={'Clear all filters'}
                className={'w-full'}
            />
        </div>
    );
};
