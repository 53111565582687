import { compose, withProps } from '@ez/tools';
import { IMutateAppointmentGroup, NodeType, withAppointmentGroupMutator } from './api-types';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import invariant from 'invariant';
import { ColorPalette, deserializeColorPalette } from './colorPalette';

export interface AppointmentGroupMutatorInjectedProps extends IMutateAppointmentGroup {}

export const setOrSkip = (input: any, key: string, procFn: any) => {
    invariant(key, 'Expected Key');
    if (!key) {
        return undefined;
    }

    const value = _get(input, key);
    if (value) {
        if (typeof value !== 'string') {
            return undefined;
        } else {
            if (procFn) return procFn(value);
            return value;
        }
    }
    return undefined;
};

const createMutator = ({ mutateAppointmentGroup }: AppointmentGroupMutatorInjectedProps) => {
    const createAppointmentGroup = async (input: {
        title: string;
        description?: string;
        defaultColor?: string;
    }): Promise<NodeType.ID | null> => {
        const { title, defaultColor, description } = input;
        const response = await mutateAppointmentGroup.create({
            title: _trim(title),
            description: _trim(description),
            defaultColor: _trim(defaultColor),
            colorPalette: [],
        });
        return response?.data?.AppointmentGroup?.AppointmentGroup?.id || null;
    };

    const updateAppointmentGroup = async (
        appointmentGroup: NodeType.NodeOrId<NodeType.AppointmentGroup>,
        input: {
            title?: string;
            description?: string;
            defaultColor?: string;
        }
    ) => {
        const mutationConfig: NodeType.UpdateAppointmentGroupMutationInput = {
            id: NodeType.extractId(appointmentGroup),
        };

        mutationConfig.title = setOrSkip(input, 'title', _trim);
        mutationConfig.description = setOrSkip(input, 'description', _trim);
        mutationConfig.defaultColor = setOrSkip(input, 'defaultColor', _trim);

        await mutateAppointmentGroup.update(mutationConfig);
    };

    const deleteAppointmentGroup = async (appointmentGroup: NodeType.NodeOrId<NodeType.AppointmentGroup>) => {
        return mutateAppointmentGroup.delete({
            id: NodeType.extractId(appointmentGroup),
        });
    };

    const assertIndex = (colorPalette: ColorPalette, index: number) => {
        if (index >= colorPalette.items.length || index < 0) {
            throw new Error(`index = ${index} is out bounds [0...${colorPalette.items.length - 1}]`);
        }
    };

    const updatePaletteItem = async (
        appointmentGroup: NodeType.AppointmentGroup,
        index: number,
        updateValues: { color?: string; label?: string }
    ) => {
        const colorPalette = deserializeColorPalette(appointmentGroup);
        assertIndex(colorPalette, index);

        const { color, label } = updateValues;

        const newValue = colorPalette.items[index];
        if (color !== undefined) {
            newValue.color = color;
        }
        if (label !== undefined) {
            newValue.label = label;
        }

        colorPalette.items[index] = newValue;

        return mutateAppointmentGroup.update({ id: appointmentGroup.id, colorPalette: colorPalette.items });
    };

    const addPaletteItem = async (
        appointmentGroup: NodeType.AppointmentGroup,
        values: { color?: string; label?: string }
    ) => {
        const colorPalette = deserializeColorPalette(appointmentGroup);
        const { color, label } = values;
        const newValue: any = {};
        if (color !== undefined) {
            newValue.color = color;
        }
        if (label !== undefined) {
            newValue.label = label;
        }
        colorPalette.items.push(newValue);
        return mutateAppointmentGroup.update({ id: appointmentGroup.id, colorPalette: colorPalette.items });
    };

    const removePaletteItem = async (appointmentGroup: NodeType.AppointmentGroup, index: number) => {
        const colorPalette = deserializeColorPalette(appointmentGroup);
        assertIndex(colorPalette, index);
        const { items } = colorPalette;
        items.splice(index, 1);
        return mutateAppointmentGroup.update({ id: appointmentGroup.id, colorPalette: items });
    };

    return {
        AppointmentGroupMutator: {
            createAppointmentGroup,
            updateAppointmentGroup,
            deleteAppointmentGroup,
            updatePaletteItem,
            addPaletteItem,
            removePaletteItem,
        },
    };
};

export const withAppointmentGroupMutators = (refetchQueries) =>
    compose(withAppointmentGroupMutator(refetchQueries), withProps(createMutator));

export interface AppointmentGroupMutatorProps
    extends AppointmentGroupMutatorInjectedProps,
        ReturnType<typeof createMutator> {}
