import * as React from 'react';
import DatePicker from '@poolware/react-datepicker';
import moment from 'moment';
import { CalNavigate, CalViewMode } from '../../types';
import { DateRange } from '../../../constants';
import { useCalendarActions } from '../../../redux';
import { styled } from 'twin.macro';

const CalendarContainerStyled = styled.div`
    .react-datepicker-container {
        width: 100%;
    }

    .pw-dispatcher-widget {
        position: static;
        width: 100%;
        //height: 300px;
        background-color: unset;
        display: flex;
        justify-content: stretch;
        align-items: stretch;

        & .react-datepicker {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            border: none;
            border-radius: unset;

            &__header {
                background-color: #f2f2f2;
            }

            &__today-button {
                background-color: unset;
                border: unset;
                line-height: unset;
            }

            &__day-names,
            &__week {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                gap: 0.5em;
            }

            &__week {
                height: 2.5em;
            }

            &__day,
            &__day-name,
            &__time-name {
                width: auto;
                flex: 1 0 0;
                border: none;
                font-size: 1.1em;

                &--highlighted-custom:not(&--outside-month) {
                    border: 1px solid #ff6a6a;
                    border-radius: 5px;
                }

                &--selected,
                &--in-selecting-range,
                &--in-range,
                &--highlighted-range {
                    border-radius: 5px;
                }
            }

            &__current-month,
            &-time__header {
                font-size: 1.2em;
            }

            &__navigation {
                margin-top: 1em;
                border-width: 0.9em;
            }

            &__navigation--previous {
                border-right-color: #8a8a8a;
            }

            &__navigation--next {
                border-left-color: #8a8a8a;
            }
        }
    }
`;

const formats = {
    dayFormat: (date: Date) => moment(date).format('ddd, DD MMM'),
    dayRangeFormat: (dateRange: DateRange) => {
        const { startDate: start, endDate: end } = dateRange;
        const isSameMonth = moment(start).isSame(end, 'month');
        if (isSameMonth) {
            return moment(start).format('DD') + ' - ' + moment(end).format('DD MMM');
        } else {
            return moment(start).format('DD MMM') + ' - ' + moment(end).format('DD MMM');
        }
    },
};

const _GETTERS = {
    [CalViewMode.DAY]: {
        getTitle: (input: { currentDate: Date; dateRange: DateRange }) => {
            return formats.dayFormat(input.currentDate);
        },
        getNextDate: (nav: CalNavigate, date: Date) => {
            switch (nav) {
                case CalNavigate.PREVIOUS:
                    return moment(date).subtract(1, 'd').toDate();
                case CalNavigate.NEXT:
                    return moment(date).add(1, 'd').toDate();
                default:
                    return date;
            }
        },
    },
    [CalViewMode.WEEK]: {
        getTitle: (input: { currentDate: Date; dateRange: DateRange }) => {
            return formats.dayRangeFormat(input.dateRange);
        },
        getNextDate: (nav: CalNavigate, date: Date) => {
            switch (nav) {
                case CalNavigate.PREVIOUS:
                    return moment(date).subtract(1, 'week').toDate();
                case CalNavigate.NEXT:
                    return moment(date).add(1, 'week').toDate();
                default:
                    return date;
            }
        },
    },
    [CalViewMode.MONTH]: {
        getTitle: (input: { currentDate: Date; dateRange: DateRange }) => {
            return formats.dayRangeFormat(input.dateRange);
        },
        getNextDate: (nav: CalNavigate, date: Date) => {
            switch (nav) {
                case CalNavigate.PREVIOUS:
                    return moment(date).subtract(1, 'month').toDate();
                case CalNavigate.NEXT:
                    return moment(date).add(1, 'month').toDate();
                default:
                    return date;
            }
        },
    },
};

const getHighlightDate = (dateRange: DateRange) => {
    const start = moment(dateRange.startDate);
    const end = moment(dateRange.endDate);

    const dif = end.diff(start, 'd');
    if (dif <= 1) {
        return [];
    }

    const days = [];
    let breakCnt = 40;
    const today = moment();
    while (start.isBefore(end) && breakCnt > 0) {
        if (!start.isSame(today, 'd')) {
            days.push(start?.toDate());
        }
        start.add(1, 'd');
        breakCnt--;
    }

    return days;
};

const _now = new Date();
const minDate = moment().subtract(20, 'y').startOf('y').toDate();
const maxDate = moment().add(20, 'y').startOf('y').toDate();

const CalendarWidget = ({ selected, startDate, endDate, onChange }) => {
    const dates = getHighlightDate({ startDate, endDate });
    const hd = [
        { 'react-datepicker__day--highlighted-custom': [_now] },
        { 'react-datepicker__day--highlighted-range': dates },
    ];
    return (
        <CalendarContainerStyled>
            <div className={'pw-dispatcher-widget'}>
                <DatePicker
                    inline={true}
                    calendarClassName={'pw-datepicker '}
                    allowSameDay={true}
                    icon={'calendar alternate outline'}
                    size={'tiny'}
                    basic
                    color="teal"
                    labelPosition={'left'}
                    monthsShown={1}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat={'dd MMMM yyyy'}
                    selected={selected}
                    shouldCloseOnSelect={false}
                    highlightDates={hd}
                    onChange={onChange}
                    todayButton={'Today'}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </div>
        </CalendarContainerStyled>
    );
};

export const QuickActionCalendarWidget: React.FC = () => {
    const { CalendarAction, CalendarState } = useCalendarActions();
    const { activeDate, displayingDateRange, viewMode } = CalendarState;

    const isToday = moment(activeDate).isSame(moment(), 'd');

    const getters = _GETTERS[viewMode] || _GETTERS[CalViewMode.DAY];
    const label = getters.getTitle({ currentDate: activeDate, dateRange: displayingDateRange });

    const _navigate = (mode: CalNavigate, date: Date = activeDate) => {
        switch (mode) {
            case CalNavigate.PREVIOUS:
                const prevDate = getters.getNextDate(mode, date);
                CalendarAction.setCalendar({ activeDate: prevDate });
                break;
            case CalNavigate.NEXT:
                const nextDate = getters.getNextDate(mode, date);
                CalendarAction.setCalendar({ activeDate: nextDate });
                break;
            case CalNavigate.TODAY:
                CalendarAction.setCalendar({ activeDate: new Date() });
                break;
            case CalNavigate.DATE:
                CalendarAction.setCalendar({ activeDate: date });
                break;
        }
    };

    return (
        <div className={'w-full'}>
            <CalendarWidget
                selected={activeDate}
                startDate={displayingDateRange.startDate}
                endDate={displayingDateRange.endDate}
                onChange={(value) => {
                    try {
                        const newDate = moment(value).toDate();
                        _navigate(CalNavigate.DATE, newDate);
                    } catch (e) {
                        _navigate(CalNavigate.DATE, activeDate);
                    }
                }}
            />
        </div>
    );
};
