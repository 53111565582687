import * as React from 'react';
import { NodeType } from '@poolware/api';
import { DebugJSON } from '@ez/components';
import { ActionBarServiceGroups, JobOptionType, OptionableL3, OptionableL3OnChangeFn } from './ActionBarServiceGroups';

interface ServiceJobGroupFilterProps {
    setServiceJobGroup: (string: string | NodeType.ID) => any;
    setServiceJobTemplate?: (string: string | NodeType.ID) => any;
    value: string | NodeType.ID;
    options: OptionableL3<
        NodeType.ServiceJobGroup,
        NodeType.ServiceJobGroup | NodeType.ServiceJobTemplate,
        NodeType.ServiceJobTemplate,
        JobOptionType
    >[];
    persistStorageKey?: string;
    collapsable?: boolean;
    maxHeight?: number;
}

export const ActionBarFilterByServiceJobGroup: React.FC<ServiceJobGroupFilterProps> = ({
    setServiceJobGroup,
    setServiceJobTemplate,
    options,
    persistStorageKey,
    collapsable,
    value = null,
    maxHeight,
}) => {
    const handleOnSelect: OptionableL3OnChangeFn<
        NodeType.ServiceJobGroup,
        NodeType.ServiceJobGroup | NodeType.ServiceJobTemplate,
        NodeType.ServiceJobTemplate
    > = (optionL1, optionL2, optionL3) => {
        if (optionL3) {
            setServiceJobGroup(null);
            setServiceJobTemplate?.(optionL3?.value?.id || null);
        } else if (optionL2) {
            if (optionL2.type === JobOptionType.Group) {
                setServiceJobGroup(optionL2?.value.id || null);
                setServiceJobTemplate?.(null);
            } else if (optionL2.type === JobOptionType.Template) {
                setServiceJobGroup(null);
                setServiceJobTemplate?.(optionL2?.value?.id || null);
            }
        } else if (optionL1) {
            setServiceJobGroup(optionL1?.value.id || null);
            setServiceJobTemplate?.(null);
        } else {
            setServiceJobGroup(null);
            setServiceJobTemplate?.(null);
        }
    };

    return (
        <>
            <ActionBarServiceGroups
                value={value}
                onChange={handleOnSelect}
                options={options}
                headerTitle={'Service Group'}
                collapsable={collapsable}
                persistStorageKey={persistStorageKey}
                maxHeight={maxHeight}
            />
            {/*<DebugJSON data={{ value, options }} />*/}
        </>
    );

    // const options2 = data.map<OptionableL2<NodeType.ServiceJobGroup, NodeType.ServiceJobTemplate>>((group) => {
    //     const templates = fromEdges(group.templates);
    //     return {
    //         value: group,
    //         icon: 'folder',
    //         text: group.title,
    //         options: templates.map<Optionable<NodeType.ServiceJobTemplate>>((t) => ({
    //             value: t,
    //             text: t.templateTitle,
    //             icon: 'suitcase',
    //         })),
    //     };
    // });

    //
    // return (
    //     <ActionBarSingleSelect
    //         value={value}
    //         onChange={handleOnChange}
    //         options={options}
    //         persistStorageKey={'sj.sg.filter.toggle'}
    //         valueComparator={(v, o) => {
    //             return v === o?.value?.id;
    //         }}
    //         headerTitle={'Service Group'}
    //     />
    // );
};
