import * as React from 'react';
import { useState } from 'react';
import { MessageForm } from './MessageForm';
import { DiscussionMessage } from './types';
import { Button, toastError, Input } from '@ez/components';

interface NotesFeedItemProps {
    placeholder?: string;
    onSubmit: (values: Omit<DiscussionMessage, 'id'>) => any;
}

export const MessagesFeedNewItem: React.FC<NotesFeedItemProps> = (props) => {
    const [isEditing, setEditing] = useState(false);

    const { placeholder, onSubmit } = props;

    const startEdit = () => setEditing(true);

    if (!isEditing) {
        return (
            <div className={'flex flex-row items-center'}>
                <Input onClick={startEdit} onChange={startEdit} placeholder={'Send a message...'} />
                <Button variant={'primary'} size={'md'} content={'Post'} onClick={startEdit} />
            </div>
        );
    }

    const handleOnSubmit = async (value: Omit<DiscussionMessage, 'id'>) => {
        try {
            await onSubmit(value);
            setEditing(false);
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <MessageForm
            onSubmit={handleOnSubmit}
            submitButton={{ content: 'Post' }}
            onCancel={() => {
                setEditing(false);
            }}
            initialValues={{
                note: '',
            }}
            placeholder={placeholder}
        />
    );
};
