import * as React from 'react';
import { toastError } from '@ez/components';
import { fromEdges, NodeType, useMutationJobTodo } from '@poolware/api';
import * as _ from 'lodash';
import { JobTodoForm } from './JobTodoForm';
import { useJobTodoMutators } from '../../../queries/mutators-job-todos';

export interface JobTodoTemplateEditorProps {
    jobTodo: NodeType.JobTodo;
    onDone: () => any;
    jobTodoMutators: ReturnType<typeof useJobTodoMutators>;
}

export const JobTodoEditForm: React.FC<JobTodoTemplateEditorProps> = ({ jobTodo, onDone, jobTodoMutators }) => {
    const initialValues = {
        title: jobTodo.title,
        description: jobTodo.description,
        items: fromEdges(jobTodo.items),
        'items-item-pending': { title: '', description: '', orderIndex: 1000 },
        timeEstimate: _.toNumber(jobTodo.timeEstimate),
        price: jobTodo.price,
    };

    const initialItemsIds = fromEdges(jobTodo.items).map((i) => i.id);

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            const newItemIds = values.items.map((i) => i.id);
            // Remove items with IDs that not present in the initial IDS array anymore
            const toDelete = initialItemsIds.filter((initialId) => !newItemIds.includes(initialId));

            const prepareItemPayload = (item) => {
                return {
                    title: item.title?.trim() || '',
                    description: item.description?.trim() || '',
                    orderIndex: item.orderIndex,
                };
            };

            const reIndexed = values.items
                .map((item, index) => ({ ...item, orderIndex: index }))
                .filter((i) => !!i.title); // remove items with empty titles

            const toUpdate = reIndexed
                .filter((item: any) => !item.isNew)
                .map((item) => ({
                    id: item.id,
                    ...prepareItemPayload(item),
                }));

            const toCreate = reIndexed.filter((item: any) => item.isNew).map((item) => prepareItemPayload(item));

            if (values['items-item-pending']?.title) {
                toCreate.push(
                    prepareItemPayload({
                        ...values['items-item-pending'],
                        orderIndex: toCreate.length + toUpdate.length,
                    })
                );
            }

            await jobTodoMutators.update({
                id: jobTodo.id,
                title: values.title?.trim() || '',
                description: values.description?.trim() || '',
                timeEstimate: _.toNumber(values.timeEstimate),
                price: values.price ? _.toNumber(values.price) : undefined,
                items: {
                    delete: toDelete,
                    create: toCreate,
                    update: toUpdate,
                },
            });

            onDone();
        } catch (e) {
            toastError(e);
        }
    };

    return <JobTodoForm debug={true} initialValues={initialValues} onCancel={onDone} onSubmit={handleSubmit} />;
};
