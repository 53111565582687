import * as React from 'react';
import { useMemo } from 'react';
import { NodeType } from '@poolware/api';
import { Icon } from '@ez/components';
import { ConnectionTableDef, DefaultConnectionTable, Display } from '@ez/components';
import { IconServiceJobStage, ModuleIconNames } from '../../../constants';
import { mapAppointmentStatusToIconProps } from '../../../Scheduler';
import { getColorForPatternBlockId } from '../PanelServiceSchedules/utils';
import { CSSProperties } from 'styled-components';

const WoDetails: React.FC<{ appointmentItem: NodeType.AppointmentItem }> = ({ appointmentItem }) => {
    const { workOrder } = appointmentItem;

    if (!workOrder) {
        return (
            <div className={'flex flex-row'}>
                <Icon name={ModuleIconNames.WorkOrder} />
                {/*Use status appointment's status icon*/}
                <Icon {...mapAppointmentStatusToIconProps(appointmentItem.status)} />
                <i>pending...</i>
            </div>
        );
    } else {
        return (
            <div className={'flex flex-row'}>
                <Icon name={ModuleIconNames.WorkOrder} />
                <IconServiceJobStage stage={workOrder?.stage} />
                <span style={{ color: '#4183c4' }}>{workOrder.workOrderNumber}</span> - <span>{workOrder.title}</span>
            </div>
        );
    }
};

const CustomerDetails: React.FC<{ appointmentItem: NodeType.AppointmentItem }> = ({ appointmentItem }) => {
    const { customer } = appointmentItem;

    if (!customer) {
        return (
            <div className={'flex flex-row'}>
                <Icon name={ModuleIconNames.Customer} /> --
            </div>
        );
    } else {
        return (
            <div className={'flex flex-row'}>
                <Icon name={ModuleIconNames.Customer} />
                <Display.Entity value={customer} /> / {customer.crn}
            </div>
        );
    }
};

const StaffDetails: React.FC<{ appointmentItem: NodeType.AppointmentItem }> = ({ appointmentItem }) => {
    const apptStaff = appointmentItem.staff;
    const woStaff = appointmentItem.workOrder?.assignedTo;

    if (!appointmentItem.workOrder) {
        // No work order
        return (
            <span>
                <Icon name={ModuleIconNames.Staff} />
                <Display.Entity value={apptStaff} />
            </span>
        );
    }

    if (!apptStaff && !woStaff) {
        return (
            <span>
                <Icon name={ModuleIconNames.Staff} /> --
            </span>
        );
    }

    const isMismatched = apptStaff?.id !== woStaff?.id;

    if (isMismatched) {
        // Staff ID mismatch, Show warning.
        return (
            <div>
                <div>
                    <Icon name={'warning sign'} /> Staff don't match
                </div>
                <div>
                    <b>Work Order: </b> <Display.Entity value={woStaff} />
                </div>
                <div>
                    <b>Appointment:</b> <Display.Entity value={apptStaff} />
                </div>
            </div>
        );
    }
    return (
        <span>
            <Icon name={ModuleIconNames.Staff} />
            <Display.Entity value={apptStaff} />
        </span>
    );
};

export const TableAppointments: React.FC<{
    appointmentItems: NodeType.AppointmentItem[];
    onView;
}> = ({ appointmentItems, onView }) => {
    const viewDetails = (appointment: NodeType.AppointmentItem) => {
        onView && onView(appointment);
    };

    const tableDef: ConnectionTableDef<NodeType.AppointmentItem> = useMemo(
        () => [
            {
                header: 'Appointment',
                width: '8',
                cellProps: (r) => ({
                    style: {
                        paddingLeft: '0px',
                    },
                }),
                cell: (r) => {
                    const colorStyle: CSSProperties = {
                        borderLeft: '5px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: getColorForPatternBlockId(r.appointment?.patternBlockId) || 'transparent',
                    };
                    return (
                        <div style={colorStyle}>
                            <div className={'flex flex-col pl-2'}>
                                <div>
                                    <Icon name={ModuleIconNames.Appointment} />
                                    <Icon {...mapAppointmentStatusToIconProps(r.status)} />
                                    <Display.Date value={r.startDate} format={'lll (ddd)'} />
                                </div>
                                <WoDetails appointmentItem={r} />
                                <StaffDetails appointmentItem={r} />
                                <CustomerDetails appointmentItem={r} />
                            </div>
                        </div>
                    );
                },
            },
        ],
        [appointmentItems]
    );

    return (
        <DefaultConnectionTable
            tableProps={{ color: 'grey' }}
            tableDef={tableDef}
            onRowClick={viewDetails}
            connectionData={appointmentItems}
            connectionState={undefined}
        />
    );
};
