import * as React from 'react';
import {
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionConsumer,
    PrintSectionHeader,
    PrintSectionItem,
} from '@ez/components';
import { CalcDocType, CostEstimation } from '../../common/types';
import { getRecommendationSummary } from '../../common/compute-recommendation-summary';
import * as _ from 'lodash';

export const RecommendationPanel: React.FC<{ costEstimation: CostEstimation }> = ({ costEstimation }) => {
    let summary = getRecommendationSummary({ type: CalcDocType.GasHeater, costEstimation });

    if (!summary) {
        return (
            <PrintSectionConsumer name={'recommendedHeater'}>
                <div>
                    <PrintSectionHeader>Recommended Heater</PrintSectionHeader>
                    <PrintSectionBody>
                        <PrintSectionColumn>
                            <>No recommendations</>
                        </PrintSectionColumn>
                    </PrintSectionBody>
                </div>
            </PrintSectionConsumer>
        );
    }

    const coldestMonthCost = _.maxBy(costEstimation?.gas?.costByMonth, (c) => c.costRunningMonthly);

    return (
        <>
            <PrintSectionHeader>Recommended Heater</PrintSectionHeader>
            <PrintSectionBody>
                <PrintSectionColumn>
                    <PrintSectionConsumer name={'recommendedHeater'}>
                        <PrintSectionItem labelWidth={'8cm'} label={'Heater Model'}>
                            <b>{summary.model?.name}</b>
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Heat Output'}>
                            {summary.model.capacity} MJ
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Est. Cost (Yearly)'}>
                            <span className={'text-red-800 font-bold'}>${summary.costYearly}</span>
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Est. Cost (coldest month)'}>
                            <span className={'text-red-700 font-bold'}>
                                ${coldestMonthCost?.costRunningMonthly?.toFixed(2)}
                            </span>
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Cost of Gas'}>
                            {summary.resourcePrice}
                        </PrintSectionItem>
                    </PrintSectionConsumer>

                    {/*<PrintSectionConsumer name={'heatupCostEstimates'}>*/}
                    {/*    <Divider />*/}

                    {/*    <i>Coldest Month ({profile.monthProfile.name})</i>*/}

                    {/*    <PrintSectionItem labelWidth={'8cm'} label={'Initial Heating Cost (from ambient)'}>*/}
                    {/*        $AUD {profile.costToHeatUp.toFixed(2)}*/}
                    {/*    </PrintSectionItem>*/}
                    {/*    <PrintSectionItem labelWidth={'8cm'} label={'Daily Running Cost'}>*/}
                    {/*        $AUD {profile.dailyRunningCost.toFixed(2)}*/}
                    {/*    </PrintSectionItem>*/}
                    {/*    <PrintSectionItem labelWidth={'8cm'} label={'Weekly Running Cost'}>*/}
                    {/*        $AUD {(profile.dailyRunningCost * 7).toFixed(2)}*/}
                    {/*    </PrintSectionItem>*/}
                    {/*    <PrintSectionItem labelWidth={'8cm'} label={'Monthly Running Cost'}>*/}
                    {/*        $AUD {(profile.dailyRunningCost * profile.monthProfile.days).toFixed(2)}*/}
                    {/*    </PrintSectionItem>*/}
                    {/*    <PrintSectionItem labelWidth={'8cm'} label={'Gas Cost'}>*/}
                    {/*        {profile.gasCost.toFixed(2)} Cent per MJ*/}
                    {/*    </PrintSectionItem>*/}
                    {/*</PrintSectionConsumer>*/}
                </PrintSectionColumn>
            </PrintSectionBody>
        </>
    );
};
