import * as React from 'react';
import { NodeType } from '@poolware/api';
import { ActionBarSingleSelect, Icon, Optionable, OptionableL2, SemanticCOLORS } from '@ez/components';
import { jobStageTypes, mapServiceJobStageTypeToIconProps, mapServiceJobStageTypeToName } from '../../constants';
import { ButtonsSelectGroupL2 } from '../ButtonsSelectGroupL2';

const mapStageTypeToOptionable =
    (stageStatus: NodeType.ServiceJobStageStatus) => (stageType: NodeType.ServiceJobStageType) => ({
        text: mapServiceJobStageTypeToName(stageType),
        icon: <Icon {...mapServiceJobStageTypeToIconProps(stageType)} />,
        value: stageType,
    });

type StageTypeOptionableL1 = OptionableL2<NodeType.ServiceJobStageStatus, NodeType.ServiceJobStageType>;
type StageTypeOptionableL2 = Optionable<NodeType.ServiceJobStageType>;

export const StatusToType = {
    [NodeType.ServiceJobStageStatus.Active]: [
        NodeType.ServiceJobStageType.Opened,
        NodeType.ServiceJobStageType.InProgress,
        NodeType.ServiceJobStageType.ActionRequired,
    ],
    [NodeType.ServiceJobStageStatus.Suspended]: [
        NodeType.ServiceJobStageType.Suspended, //
    ],
    [NodeType.ServiceJobStageStatus.Closed]: [
        NodeType.ServiceJobStageType.Finished,
        NodeType.ServiceJobStageType.Canceled,
    ],
};

interface ActionBarFilterByServiceStageTypeProps {
    setServiceJobStageType: (stageTypes: NodeType.ServiceJobStageType[]) => any;
    setServiceJobStageStatus: (stageStatus: NodeType.ServiceJobStageStatus) => any;
    valueStageTypes: NodeType.ServiceJobStageType[] | null;
    valueStageStatus: NodeType.ServiceJobStageStatus | null;
    persistStorageKey?: string;
    headerTitle?: string | React.ReactNode;
}

const _options: StageTypeOptionableL1[] = [
    {
        text: 'Active',
        icon: <Icon name={'circle'} />,
        value: NodeType.ServiceJobStageStatus.Active,
        options: StatusToType[NodeType.ServiceJobStageStatus.Active].map(
            mapStageTypeToOptionable(NodeType.ServiceJobStageStatus.Active)
        ),
    },
    {
        text: 'Closed',
        icon: <Icon name={'check circle'} />,
        value: NodeType.ServiceJobStageStatus.Closed,
        options: StatusToType[NodeType.ServiceJobStageStatus.Closed].map(
            mapStageTypeToOptionable(NodeType.ServiceJobStageStatus.Closed)
        ),
    },
    {
        text: 'On Hold',
        icon: <Icon name={'pause'} />,
        value: NodeType.ServiceJobStageStatus.Suspended,
        // options: StatusToType[NodeType.ServiceJobStageStatus.Suspended].map(
        //     mapStageTypeToOptionable(NodeType.ServiceJobStageStatus.Suspended)
        // ),
    },
];

export const ActionBarFilterByServiceStageType: React.FC<ActionBarFilterByServiceStageTypeProps> = ({
    setServiceJobStageType,
    setServiceJobStageStatus,
    valueStageStatus = null,
    valueStageTypes = null,
    headerTitle,
    persistStorageKey,
}) => {
    const handleOnChange = (optionL1: StageTypeOptionableL1 | null, optionL2: StageTypeOptionableL2) => {
        if (optionL2) {
            setServiceJobStageStatus(null);
            setServiceJobStageType([optionL2?.value]);
        } else if (optionL1) {
            const types = StatusToType[optionL1?.value];
            setServiceJobStageType(types);
            setServiceJobStageStatus(optionL1?.value);
        } else {
            setServiceJobStageStatus(null);
            setServiceJobStageType(null);
        }
    };

    const valueComparator = (value, option: Optionable<any>) => {
        const optionValue = option?.value;
        if (valueStageStatus) {
            return optionValue === value?.valueStageStatus;
        } else if (valueStageTypes?.length > 0) {
            return optionValue === value?.valueStageTypes?.[0];
        } else {
            return false;
        }
    };

    let value = valueStageStatus || valueStageTypes?.length > 0 ? { valueStageTypes, valueStageStatus } : null;

    return (
        <ActionBarSingleSelect
            headerTitle={headerTitle || 'Stage Type'}
            value={value}
            valueComparator={valueComparator}
            onChange={handleOnChange}
            options={_options}
            persistStorageKey={persistStorageKey}
        />
    );
};
const ALL = '___ALL___';
const _optionsSg = [
    {
        text: 'All',
        value: ALL as any,
    },
    ..._options,
];

export interface SegmentButtonsFilterByServiceStageTypeGroupedProps extends ActionBarFilterByServiceStageTypeProps {
    color?: SemanticCOLORS;
}

export const StageFilterButtons: React.FC<SegmentButtonsFilterByServiceStageTypeGroupedProps> = ({
    setServiceJobStageType,
    setServiceJobStageStatus,
    valueStageStatus = null,
    valueStageTypes = null,
    color,
}) => {
    const handleOnChange = (optionL1: StageTypeOptionableL1 | null, optionL2: StageTypeOptionableL2) => {
        if (optionL2) {
            setServiceJobStageStatus(null);
            setServiceJobStageType([optionL2?.value]);
        } else if (optionL1) {
            const types = StatusToType[optionL1?.value];
            setServiceJobStageType(types);
            setServiceJobStageStatus(optionL1?.value);
        } else {
            setServiceJobStageStatus(null);
            setServiceJobStageType(null);
        }
    };

    const valueComparator = (value, option: Optionable<any>) => {
        if (!value && option?.value === ALL) {
            return true;
        }

        const optionValue = option?.value;
        if (valueStageStatus) {
            return optionValue === value?.valueStageStatus;
        } else if (valueStageTypes?.length > 0) {
            return optionValue === value?.valueStageTypes?.[0];
        } else {
            return false;
        }
    };

    let value = valueStageStatus || valueStageTypes?.length > 0 ? { valueStageTypes, valueStageStatus } : null;

    return (
        <ButtonsSelectGroupL2
            fluid={true}
            color={color}
            options={_optionsSg}
            value={value}
            valueComparator={valueComparator}
            onChange={handleOnChange}
        />
    );
};

export const ActionBarFilterByServiceJobStageType: React.FC<ActionBarFilterByServiceStageTypeProps> = ({
    setServiceJobStageType,
    setServiceJobStageStatus,
    valueStageStatus = null,
    valueStageTypes = null,
}) => {
    const options = jobStageTypes.map<OptionableL2<NodeType.ServiceJobStageType>>((stageType) => ({
        text: mapServiceJobStageTypeToName(stageType),
        icon: <Icon {...mapServiceJobStageTypeToIconProps(stageType)} />,
        value: stageType,
        options: [],
    }));

    const handleOnChange = (optionL1: Optionable<any> | null) => {
        setServiceJobStageType(optionL1?.value ? [optionL1?.value] : null);
    };

    const valueComparator = (value, option: Optionable<any>) => {
        return Boolean(value && value?.includes(option?.value));
    };

    let value = valueStageTypes?.length > 0 ? valueStageTypes : null;

    return (
        <ActionBarSingleSelect
            headerTitle={'Stage Type'}
            value={value}
            valueComparator={valueComparator}
            onChange={handleOnChange}
            options={options}
            persistStorageKey={'sj.filter.stagetype.toggle'}
        />
    );
};
