import * as React from 'react';
import {
    Icon,
    LinkButton,
    MenuBarHeaderItem,
    MenuBarItem,
    PageLayout,
    Panel,
    StickyMenuBar,
    toastError,
    toastSuccess,
    VStack,
} from '@ez/components';
import * as URLBuilder from '../../../routes/url-builder';
import delay from 'lodash/delay';
import { PageControlProps } from './Page.Control';
import {
    ConfirmArchiveStaffButton,
    ConfirmDeactivateButton,
    ConfirmInvalidatePasswordButton,
    ConfirmReactivateButton,
} from './ConfirmButtons';
import { fromEdges, NodeType } from '@poolware/api';
import { MenuBar } from '@ez/components';

interface Props extends PageControlProps {}

class EmployeePage extends React.Component<Props, { isMutating: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            isMutating: false,
        };
    }

    isActive = () => {
        return this.isRegistered() && !this.props.staff?.registration?.disabledAt;
    };

    isArchived = () => {
        return !!this.props.staff?.archivedAt;
    };

    isRegistered = () => {
        return !!this.props.staff?.registration;
    };

    isMe = () => {
        return this.props.viewerContext?.viewer?.me?.staff?.id === this.props.staff?.id;
    };

    onEdit = () => {
        const staffId = this.props.staff.id;
        const link = URLBuilder.Staff(staffId).edit;
        this.props.AppNavigator.navigate(link, { setOrigin: true, modal: true });
    };

    performAction = async (fn) => {
        try {
            this.setState({ isMutating: true });
            await fn();
            // HACK: Artificially introduce a delay to give the system some time until it finishes fetching fresh data.
            // Otherwise there is an unpleasant flickering happening.
            delay(() => {
                this.setState({ isMutating: false });
            }, 500);
        } catch (err) {
            toastError({ title: 'Action failed', description: err.message });
            this.setState({ isMutating: false });
        }
    };
    getRegId = () => {
        return this.props.staff?.registration?.id;
    };

    deactivateAccount = async () => {
        const id = this.getRegId();
        return await this.performAction(() => this.props.mutateStaffRegistration.disable({ id }));
    };

    reactivateAccount = async () => {
        const id = this.getRegId();
        return await this.performAction(() => this.props.mutateStaffRegistration.enable({ id }));
    };

    invalidatePassword = async () => {
        const id = this.getRegId();
        return await this.performAction(() => this.props.mutateStaffRegistration.invalidatePassword({ id }));
    };

    archiveStaff = async () => {
        const { staff } = this.props;
        await this.performAction(() => this.props.StaffMutator.archiveStaff(staff));
        toastSuccess({ title: 'Account Archived', icon: 'user cancel' });
    };

    canEditRegistration = (): boolean => {
        let { viewerContext } = this.props;

        const StaffRegistration = viewerContext?.viewer?.Permissions?.StaffRegistration;
        return !!StaffRegistration && StaffRegistration.enable && StaffRegistration.disable && StaffRegistration.delete;
    };

    canChangeActivation = (): boolean => {
        return this.canEditRegistration() && !this.isMe();
    };

    onGoBack = () => {
        this.props.AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    onPretend = async () => {
        let { staff } = this.props;
        try {
            const res = await this.props.pretend(staff.id);
            console.log(res);
        } catch (e) {
            console.error(e);
        }
    };

    onViewTeam = (team: NodeType.StaffTeam) => {
        this.props.AppNavigator.navigateRelative(`/teams/${team.id}`, { setOrigin: true });
    };

    render() {
        let { staff, viewerContext } = this.props;
        const { role } = staff;
        const allowTeams = viewerContext?.modulesAccess?.Staff?.teams;
        const isAdmin = viewerContext?.isAdmin || viewerContext?.isOrgAdmin;

        const isActive = this.isActive();
        const isArchived = this.isArchived();
        const isRegistered = this.isRegistered();
        const canUpdate = this.canEditRegistration();
        const enableActivationButton = this.canChangeActivation();
        const canEditProfileDetails = !canUpdate || isArchived || !isRegistered;

        const { isMutating } = this.state;
        const email = staff.registration?.email;
        const staffIcon = isActive ? 'user' : 'user cancel';
        const statusLabel = isArchived ? '(Archived)' : !isActive ? '(Deactivated)' : '';

        const accountActionButtons = [];
        if (enableActivationButton) {
            if (isActive) {
                accountActionButtons.push(<ConfirmDeactivateButton onClick={this.deactivateAccount} email={email} />);
                accountActionButtons.push(
                    <ConfirmInvalidatePasswordButton onClick={this.invalidatePassword} email={email} />
                );
            } else {
                if (!isArchived) {
                    accountActionButtons.push(<ConfirmArchiveStaffButton onClick={this.archiveStaff} email={email} />);
                }
                if (isRegistered) {
                    accountActionButtons.push(
                        <ConfirmReactivateButton onClick={this.reactivateAccount} email={email} />
                    );
                }
            }
        }

        const teams = fromEdges(this.props.staff?.teams);
        return (
            <PageLayout>
                <StickyMenuBar>
                    <MenuBar.Section position={'left'}>
                        <MenuBar.Item icon={'chevron left'} color={'grey'} onClick={this.onGoBack}>
                            Back
                        </MenuBar.Item>
                        <MenuBarHeaderItem
                            // color={!isActive ? 'orange' : null}
                            icon={staffIcon}
                        >
                            Staff
                        </MenuBarHeaderItem>
                    </MenuBar.Section>
                    <MenuBar.Section position={'right'}>
                        {isAdmin && (
                            <MenuBarItem
                                disabled={!isActive}
                                popup={!isActive ? { content: 'Deactivated account' } : null}
                                icon={'eye'}
                                onClick={this.onPretend}
                            >
                                Pretend
                            </MenuBarItem>
                        )}
                    </MenuBar.Section>
                </StickyMenuBar>
                <PageLayout.BodySection width={'screen-md'}>
                    <VStack>
                        <Panel>
                            <Panel.Header
                                content={`Details ${statusLabel}`}
                                icon={staffIcon}
                                color={!isActive ? 'orange' : null}
                                button={{
                                    content: 'Edit',
                                    disabled: canEditProfileDetails,
                                    icon: isArchived ? 'lock' : 'edit',
                                    onClick: this.onEdit,
                                }}
                            />
                            <Panel.Body>
                                <Panel.ItemEntity label="Name" content={staff} />
                                <Panel.Item label="Role" content={role ? role.name : 'Not assigned'} />
                                <Panel.Item label="Franchise" content={staff?.user?.entity?.franchise?.name || '-'} />
                                {isAdmin && (
                                    <Panel.Item
                                        label="Organisation"
                                        content={staff?.user?.entity?.franchise?.organisationType?.name || '-'}
                                    />
                                )}
                                {allowTeams && (
                                    <>
                                        <Panel.SectionHeader>Teams</Panel.SectionHeader>
                                        <Panel.Item label={'Teams'}>
                                            <div className={'flex flex-row gap-1'}>
                                                {teams.length == 0 && <>--</>}
                                                {teams.map((t) => {
                                                    return (
                                                        <div className={'rounded px-2 bg-gray-200'} key={t.id}>
                                                            <LinkButton onClick={() => this.onViewTeam(t)}>
                                                                {t.title}
                                                            </LinkButton>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Panel.Item>
                                    </>
                                )}
                            </Panel.Body>
                        </Panel>

                        <Panel>
                            <Panel.Header content={'Login Account'} />
                            <Panel.Body>
                                <>
                                    {isRegistered && <Panel.ItemEmail label="Account Email" content={email} />}
                                    {isMutating ? (
                                        <Panel.Item label="Status">
                                            <Icon name={'spinner'} loading /> Updating...
                                        </Panel.Item>
                                    ) : !isRegistered ? (
                                        <Panel.Item
                                            label="Account"
                                            content={'Accounts is not registered. Login is disabled.'}
                                        />
                                    ) : (
                                        <Panel.Item label="Status">
                                            {isActive ? (
                                                <span>
                                                    <Icon name={'check circle outline'} color={'green'} />
                                                    Active
                                                </span>
                                            ) : (
                                                <span>
                                                    <Icon name={'user cancel'} color={'orange'} />
                                                    Deactivated
                                                </span>
                                            )}
                                        </Panel.Item>
                                    )}
                                </>
                            </Panel.Body>
                            {accountActionButtons.length > 0 && (
                                <Panel.Footer>
                                    <div className={'flex flex-wrap gap-2'}>
                                        {accountActionButtons.map((b, i) => {
                                            return <div key={i}>{b}</div>;
                                        })}
                                    </div>
                                </Panel.Footer>
                            )}
                        </Panel>
                    </VStack>
                </PageLayout.BodySection>
            </PageLayout>
        );
    }
}

export default EmployeePage;
