import * as React from 'react';
import {
    AsyncCheckbox,
    Button,
    confirmModalImperative,
    ConnectionErrorMessage,
    ConnectionTableDef,
    DefaultConnectionTable,
    MenuBar,
    Modal,
    PageSkeletonLoader,
    Panel,
    StickyMenuBar,
    toastError,
    useModalCtrl,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NotFoundPage } from '@poolware/app-shell';
import { useQueryProduct } from './use-query-product';
import { ProductTraitsPanel } from './ProductTraitsPanel';
import { fromEdges } from '@ez/api-core';
import { ProductDocumentPickerPanel } from '../ProductDocs/DocumentPickerTable';
import { NodeType, useMutationProduct } from '@poolware/api';
import { AdminRouterModuleID } from '../Router';
import { IProductCatalogMutators, withProductCatalogMutators } from '@poolware/app-catalog';
import { changeUseInCalcFlag, isUseInCalc } from '../../common/use-equipment-catalog';

const DocumentsTable: React.FC<{
    connectionData: NodeType.ProductDocument[];
    onRemove: (productDoc: NodeType.ProductDocument) => Promise<any>;
}> = ({ connectionData, onRemove }) => {
    const { AppNavigator } = useAppNavigator();

    const handleOnRemove = async (e, productDoc: NodeType.ProductDocument) => {
        e.stopPropagation();
        const res = await confirmModalImperative({ confirmMessage: { header: 'Remove document' }, negative: true });
        if (res) {
            await onRemove?.(productDoc);
        }
    };

    const onClick = (productDoc: NodeType.ProductDocument) => {
        AppNavigator.navigateRelative(`/docs/${productDoc.id}`, { setOrigin: true, moduleId: AdminRouterModuleID });
    };

    const tableDef: ConnectionTableDef<NodeType.ProductDocument> = [
        {
            header: 'Title',
            cell: (r) => {
                return <div className={'text-blue-600'}>{r.title}</div>;
            },
        },
        {
            header: '',
            width: '1',
            cell: (r) => {
                return <Button basic={true} size={'tiny'} icon={'trash'} onClick={(e) => handleOnRemove(e, r)} />;
            },
        },
    ];

    return (
        <DefaultConnectionTable
            onRowClick={onClick}
            tableProps={{ color: 'grey' }}
            tableDef={tableDef}
            connectionData={connectionData}
        />
    );
};

const Page: React.FC<IProductCatalogMutators> = ({ ProductCatalogMutator }) => {
    const { AppNavigator } = useAppNavigator();
    const { node, loading, error, refetch, refetchQuery } = useQueryProduct(AppNavigator.params?.id);
    const { update } = useMutationProduct({ refetchQueries: [refetchQuery] });
    const modalPicker = useModalCtrl();
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <NotFoundPage />;
    }

    const onGoBack = () => {
        AppNavigator.navigateToOrigin('/');
    };

    const brand = node?.brand?.name;
    const company = node?.brand?.company?.name;
    const brandName = `${company ? `${company} > ` : ''} ${brand || ''} `;

    const documents = fromEdges(node.documents);

    const onAddDocument = async (selected: NodeType.ProductDocument[]) => {
        const ids = selected.map((s) => s.id);
        try {
            await update({ id: node.id, documents: { assign: ids } });
            modalPicker.onClose();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onRemoveDocument = async (selected: NodeType.ProductDocument) => {
        try {
            await update({ id: node.id, documents: { delete: [selected?.id] } });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };
    const onToggle = async (product: NodeType.Product, checked: boolean) => {
        try {
            await changeUseInCalcFlag(ProductCatalogMutator, product, checked);
            await refetch();
        } catch (e) {
            toastError(e);
        }
    };

    const hasDocs = documents?.length > 0;
    const headerButton = hasDocs ? null : { content: 'Add', icon: 'plus', onClick: modalPicker.onOpen };
    const isEnabledForCalc = isUseInCalc(node);

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item icon={'chevron left'} onClick={onGoBack}>
                        Back
                    </MenuBar.Item>
                    <MenuBar.HeaderItem icon={'calculator'}>Heater</MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'} />
            </StickyMenuBar>

            <Panel>
                <Panel.Header content={'Product'} />
                <Panel.Body>
                    <Panel.Item label={'Name'} content={node.name} />
                    <Panel.Item label={'Brand'} content={brandName} />
                    <Panel.Item label={'Use In Calculator'}>
                        <AsyncCheckbox
                            toggle={true}
                            checked={isEnabledForCalc}
                            onToggle={(checked) => onToggle(node, checked)}
                        />
                    </Panel.Item>
                </Panel.Body>
            </Panel>
            <ProductTraitsPanel product={node} />
            <Panel>
                <Panel.Header content={'Documents'} button={headerButton} />
                <Panel.Body>
                    <DocumentsTable connectionData={documents} onRemove={onRemoveDocument} />
                </Panel.Body>
            </Panel>

            <Modal {...modalPicker}>
                <ProductDocumentPickerPanel type={null} onSubmit={onAddDocument} onCancel={modalPicker.onClose} />
            </Modal>
        </VStack>
    );
};

export default withProductCatalogMutators()(Page);
