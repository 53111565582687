import * as React from 'react';
import { AdminOnly, FormikSuggestInputStaff, useViewer } from '@poolware/app-shell';
import {
    Display,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    Icon,
    Modal,
    Panel,
    Popup,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import {
    mapServiceJobRecurStatusToIconProps,
    ModuleColorNames,
    ModuleIconNames,
    RecurServiceJobStatus,
} from '../../constants';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import { isServiceActive } from './utils';
import { ServiceJobLink } from '../../Links';
import { useRecurCaseCtx } from './RecurCaseProvider';

interface DisplayServiceStatusProps {
    isActive: boolean;
    isStopped: boolean;
    isArchived: boolean;
}

const DisplayServiceStatus: React.FC<DisplayServiceStatusProps> = ({ isStopped, isActive, isArchived }) => {
    let iconProps = mapServiceJobRecurStatusToIconProps(
        !isActive ? RecurServiceJobStatus.Stopped : RecurServiceJobStatus.Active
    );

    if (isArchived) {
        return (
            <div className={'flex flex-row'}>
                <Icon name={'archive'} color={'grey'} /> Archived
            </div>
        );
    } else if (!isStopped) {
        if (isActive) {
            return (
                <div className={'flex flex-row'}>
                    <Icon {...iconProps} /> Active
                </div>
            );
        } else {
            return (
                <div className={'flex flex-row'}>
                    <Icon {...iconProps} /> Ended
                </div>
            );
        }
    } else {
        if (isActive) {
            return (
                <Popup
                    content={'Service Case has been stopped. Future appointments are still scheduled.'}
                    trigger={
                        <div className={'flex flex-row  cursor-pointer'}>
                            <Icon
                                {...mapServiceJobRecurStatusToIconProps(RecurServiceJobStatus.Stopped)}
                                color={'orange'}
                            />
                            <span className={'text-yellow-700 underline pr-2'}>Stopped</span>
                            <Icon
                                {...mapServiceJobRecurStatusToIconProps(RecurServiceJobStatus.Active)}
                                color={'green'}
                            />
                            <span className={'text-green-800 underline'}>Appointments Pending</span>
                        </div>
                    }
                />
            );
        } else {
            return (
                <div className={'flex flex-row'}>
                    <Icon {...mapServiceJobRecurStatusToIconProps(RecurServiceJobStatus.Stopped)} color={'grey'} />
                    <span className={'text-yellow-700'}>Stopped</span>
                </div>
            );
        }
    }
};

const PanelServiceCaseDetails: React.FC<{ serviceJob: NodeType.ServiceJob; onEdit }> = ({ serviceJob, onEdit }) => {
    const isActive = isServiceActive(serviceJob);
    const { modulesAccess } = useViewer();

    const isStopped = !!serviceJob?.stopsAt;
    const isArchived = !!serviceJob?.archivedAt;
    const canEdit = !isArchived && !modulesAccess.FieldServices.calendarSingleStaffMode;

    const title = (
        <>
            <b>Recurring Service</b> - {serviceJob.serviceJobNumber}
        </>
    );

    const editLockProp = isArchived ? 'Archived' : !canEdit;

    return (
        <Panel>
            <Panel.Header
                color={!isActive ? 'grey' : ModuleColorNames.RecurringService}
                icon={ModuleIconNames.RecurringService}
                content={title}
                button={{ icon: 'edit', content: 'Edit', onClick: onEdit, locked: editLockProp }}
            />
            <Panel.Body>
                <AdminOnly>
                    <Panel.Item color={'red'} label="Franchise">
                        {serviceJob?.entity?.franchise?.name}
                    </Panel.Item>
                </AdminOnly>
                {serviceJob.group?.title && (
                    <Panel.Item color={'purple'} label={'Group'}>
                        {serviceJob.group?.title}
                    </Panel.Item>
                )}
                <Panel.Item label={'Subject'} content={_.truncate(serviceJob.title, { length: 90 })} />
                <Panel.Item
                    iconMarker={'spy'}
                    label={'Assigned To'}
                    labelInfo={'Appointments will be assigned to the selected staff member by default.'}
                >
                    <Display.Entity value={serviceJob.staff} />
                </Panel.Item>
                <Panel.Divider />
                <Panel.Item label={'Status'}>
                    <DisplayServiceStatus isActive={isActive} isStopped={isStopped} isArchived={isArchived} />
                </Panel.Item>
                <Panel.ItemDate label={'Open Date'} content={serviceJob?.createdAt} format={'LL'} />
                {serviceJob.stopsAt && (
                    <Panel.Item label={'Stop Date'} labelInfo={'Date after which service is considered closed'}>
                        <Display.Date value={serviceJob.stopsAt} format={'LL'} />
                    </Panel.Item>
                )}
                <Panel.ItemDate
                    label={'Last Appointment'}
                    content={serviceJob?.lastAppointmentDate}
                    format={'LLL'}
                    defaultString={'Not set'}
                />
                {serviceJob.followedFrom && (
                    <Panel.Item
                        label={'Originated from'}
                        labelInfo={'Indicates that service case was originated from another case'}
                    >
                        <Icon name={'caret right'} />{' '}
                        <ServiceJobLink id={serviceJob.followedFrom?.id}>
                            #{serviceJob.followedFrom?.serviceJobNumber} {serviceJob.followedFrom?.title}
                        </ServiceJobLink>
                    </Panel.Item>
                )}
                <Panel.Divider />

                <Panel.ItemText
                    maxHeightPx={320}
                    label={'Notes'}
                    labelWidth={'100%'}
                    content={serviceJob.description || ''}
                />
            </Panel.Body>
        </Panel>
    );
};
export const FormServiceCaseDetailsEdit: React.FC<{
    serviceJob: NodeType.ServiceJob;
    onDone: () => any;
}> = ({ serviceJob, onDone }) => {
    const { updateRecurringJob } = useServiceJobMutators({
        refetchQueries: ['QueryServiceJob', 'AppointmentItemsConnectionForServiceCase'],
    });
    const initialValues = { ...serviceJob };

    const title = (
        <>
            <b>Recurring Service</b> - {serviceJob.serviceJobNumber}
        </>
    );

    const onSubmit = async (values: typeof initialValues) => {
        try {
            await updateRecurringJob({
                id: serviceJob.id,
                title: values.title?.trim() || '',
                description: values.description?.trim() || '',
                staff: values.staff?.id,
            });
            onDone?.();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    return (
        <FormikDefaultForm
            icon={ModuleIconNames.RecurringService}
            color={ModuleColorNames.RecurringService}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onDone}
            header={title}
        >
            <Panel.Item label={'Service Case ID'} content={serviceJob.serviceJobNumber} />
            <Panel.Divider />
            <FormikInputField name={'title'} label={'Subject'} />
            <FormikSuggestInputStaff name={'staff'} label={'Assigned To'} />
            <FormikTextareaField label={'Notes'} name={'description'} rows={14} />
        </FormikDefaultForm>
    );
};

export const PanelFormServiceJobDetails: React.FC = () => {
    const modalCtrl = useModalCtrl();
    const { serviceJob } = useRecurCaseCtx();
    return (
        <>
            <PanelServiceCaseDetails serviceJob={serviceJob} onEdit={modalCtrl.onOpen} />
            <Modal {...modalCtrl}>
                <FormServiceCaseDetailsEdit serviceJob={serviceJob} onDone={modalCtrl.onClose} />
            </Modal>
        </>
    );
};
