import * as React from 'react';
import { useMemo, useState } from 'react';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    EmbeddedPage,
    EmptyBoxImage,
    MenuBar,
    MenuBarGroup,
    MenuBarGroupMODE,
    Modal,
    PageTabs,
    SectionHeader,
    TabPaneConf,
    toastError,
    useModalCtrl,
    useTabCtrl,
    VStack,
} from '@ez/components';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import { WorkOrdersTable } from './WorkOrdersTable';
import { useWorkOrderMutators } from '../../../queries/mutators-work-order';
import { useAppLayoutMode, useViewer } from '@poolware/app-shell';
import invariant from 'invariant';
import { WorkOrderView } from '../../WorkOrderView';
import { DeleteWorkOrderMenuButtonItem } from '../../WorkOrderView/DeleteWorkOrderMenuButtonItem';
import { FormNewInvoiceDoc } from '../../Invoice/Edit/FormNewInvoiceDoc';
import { useQueryInvoicesConnection } from '../../Invoice/queries/use-query-abstract-doc-connection';
import { TabInvoices } from '../TabInvoices/TabInvoices';

export interface TabWorkOrdersProps {
    serviceJob: NodeType.ServiceJob;
    refetchQuery: any;
}

const WorkOrderEmbeddedContent: React.FC<{ id }> = ({ id }) => {
    const { connectionData } = useQueryInvoicesConnection({
        search: { workOrder: { id: id } },
    });

    const tabPanes: TabPaneConf[] = useMemo(
        () => [
            {
                title: 'Work Order',
                icon: 'calendar alternate outline',
                key: 'wo',
                render: () => <WorkOrderView id={id} embeddedMode={true} />,
            },
            {
                title: 'Invoices',
                icon: 'calendar alternate outline',
                key: 'inv',
                render: () => <TabInvoices workOrder={{ id: id }} />,
            },
        ],
        [connectionData.length, id]
    );

    const tabCtrl = useTabCtrl({ panes: tabPanes, defaultTab: 'wo' });
    if (connectionData?.length === 0) {
        return <WorkOrderView id={id} embeddedMode={true} />;
    }

    return <PageTabs {...tabCtrl} />;
};

const EmbeddedWorkOrderMenuBar: React.FC<{
    onClosePreview: () => any;
    onNewInvoice: () => any;
    onPrint: () => any;
    onViewWo: () => any;
    onDeleteWo: () => any;
}> = (props) => {
    const menuItemDelete = {
        render: <DeleteWorkOrderMenuButtonItem onDeleteWorkOrder={props.onDeleteWo} />,
    };

    return (
        <MenuBar.Root>
            <MenuBar.Section>
                <MenuBar.Item onClick={props.onClosePreview} icon={'cancel'} title={'Close'} />
                <MenuBar.HeaderItem color={ModuleColorNames.WorkOrder} icon={ModuleIconNames.WorkOrder}>
                    Work Order
                </MenuBar.HeaderItem>
            </MenuBar.Section>
            <MenuBar.Section position={'right'}>
                {props.onNewInvoice && (
                    <MenuBar.Item onClick={props.onNewInvoice} color={'blue'} title={'Add Invoice'} icon={'plus'} />
                )}
                <MenuBar.Item onClick={props.onPrint} color={'blue'} title={'Print'} icon={'print'} />
                <MenuBar.Item onClick={props.onViewWo} color={'black'} title={'Open'} icon={'external alternate'} />
                <MenuBarGroup
                    menu={{
                        color: 'red',
                        icon: 'bars',
                        direction: 'left',
                        menuMode: MenuBarGroupMODE.DROPDOWN,
                    }}
                    items={[menuItemDelete]}
                />
            </MenuBar.Section>
        </MenuBar.Root>
    );
};

export const WorkOrdersTab: React.FC<TabWorkOrdersProps> = ({ serviceJob, refetchQuery }) => {
    invariant(serviceJob, 'Expected service job');
    const { modulesAccess } = useViewer();
    const { gteDesktopAny } = useAppLayoutMode();
    const newInvoiceModalCtrl = useModalCtrl();
    const allowInvoices = !!modulesAccess.FieldServices?.invoices;

    const workOrderMutators = useWorkOrderMutators({
        awaitRefetchQueries: true,
    });

    const onDeleteWorkOrder = async (id: NodeType.ID) => {
        try {
            await workOrderMutators.delete({ id: id }, { awaitRefetchQueries: true, refetchQueries: [refetchQuery] });
            onClosePreview();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete work order', description: e.message });
        }
    };

    const { AppNavigator } = useAppNavigator();

    const [activeWOID, setActiveWOID] = useState(AppNavigator.query['wo']);

    const onViewWO = (woId: NodeType.ID) => {
        setActiveWOID(null);
        AppNavigator.navigate(`/wo/${woId}`, {
            relativeToModule: true,
            setOrigin: true,
            setOriginSearchQueries: { wo: null },
        });
    };

    const onPreview = (wo: NodeType.WorkOrder) => {
        if (!gteDesktopAny) {
            return onViewWO(wo.id);
        }

        if (activeWOID !== wo.id) {
            AppNavigator.setSearchQueries({ wo: wo.id });
            setActiveWOID(wo.id);
        } else {
            // AppNavigator.setTab(null);
            // setActiveWOID(null);
        }
    };

    const onClosePreview = () => {
        AppNavigator.setSearchQueries({ wo: null });
        setActiveWOID(null);
    };

    const onNewWorkOrder = () => {
        AppNavigator.navigate(`/wo/new`, {
            relativeToModule: true,
            modal: false,
            query: {
                sjId: serviceJob.id,
            },
            setOrigin: true,
        });
    };

    const noDataComponent = <EmptyBoxImage style={{ height: '20px', margin: '1em auto 0.5em' }} />;

    const onDidCreateNewInvoice = async (doc: NodeType.AbstractDocument) => {
        try {
            newInvoiceModalCtrl.onClose();
            AppNavigator.navigateRelative(`/invoices/${doc?.id}/edit`, { setOrigin: true });
        } catch (e) {
            toastError(e);
        }
    };

    const onPrintWO = () => {
        AppNavigator.navigate(`/print/service-jobs/wo/${activeWOID}`, { setOrigin: true });
    };

    return (
        <div>
            <VStack>
                <SectionHeader
                    icon={ModuleIconNames.WorkOrder}
                    content={'Work Orders and Appointments'}
                    button={{
                        content: 'Add',
                        icon: 'plus',
                        color: 'blue',
                        onClick: onNewWorkOrder,
                    }}
                />
                <WorkOrdersTable
                    serviceJob={serviceJob}
                    onView={onPreview}
                    noDataComponent={noDataComponent}
                    selectedWoId={activeWOID}
                />
            </VStack>
            {!!activeWOID && (
                <>
                    <div className={'my-2'} />
                    <EmbeddedPage>
                        <EmbeddedPage.Menu>
                            <EmbeddedWorkOrderMenuBar
                                onClosePreview={onClosePreview}
                                onDeleteWo={() => onDeleteWorkOrder(activeWOID)}
                                onViewWo={() => onViewWO(activeWOID)}
                                onPrint={() => onPrintWO()}
                                onNewInvoice={allowInvoices && newInvoiceModalCtrl.onOpen}
                            />
                        </EmbeddedPage.Menu>
                        <EmbeddedPage.Body>
                            <WorkOrderEmbeddedContent id={activeWOID} />
                        </EmbeddedPage.Body>
                    </EmbeddedPage>
                </>
            )}
            {allowInvoices && (
                <Modal closeOnDimmerClick={false} {...newInvoiceModalCtrl}>
                    <FormNewInvoiceDoc
                        initialValues={{
                            workOrderId: activeWOID,
                            serviceJobId: serviceJob?.id,
                            customerId: serviceJob?.customer?.id,
                        }}
                        onDidCreate={onDidCreateNewInvoice}
                        onCancel={newInvoiceModalCtrl.onClose}
                    />
                </Modal>
            )}
        </div>
    );
};
