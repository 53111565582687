import { ThemeInterface } from '../app-theme';
import { createThemeByHue } from '@ez/components';

let _t: ThemeInterface = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    _t = createThemeByHue({ publicPrefix, huePrim: 215, hueSec: 300 });
    _t.themeName = 'zane';
    _t.navbar.separatorColor = 'hsl(38,100%,60%)';
    _t.logoPath = `${publicPrefix}/logo.png`;
    return _t;
};
