import * as React from 'react';
import { Helmet, useViewer } from '@poolware/app-shell';

import { PrintAppointmentsQuery } from './appointment-items-print-query';
import { fromEdges, NodeType } from '@poolware/api';
import moment from 'moment';
import styled from 'styled-components';
import { ContactItems, ContactItemVM, prepareCustomerContactItems } from '../../../components/ContactItems';
import { useQueryAppointmentItemsConnection } from '../../Queries';
import { useCalendarActions } from '../../../redux';
import * as fp from 'lodash/fp';
import {
    Display,
    Icon,
    pad,
    PageSkeletonLoader,
    PrintPageHeader,
    PrintPageNoteText,
    PrintPreferNoPageBreak,
    PrintSection,
    PrintSectionBody,
    PrintSectionBodyRow,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintSectionItem,
    stringFormatters,
} from '@ez/components';

// NOTE: IMPORTANT: disable capping for `fp/map`,

// NOTE: IMPORTANT: disable capping for `fp/map`,
// otherwise it will not pass the group key after `groupBy` call.
// see lodash doc https://github.com/lodash/lodash/wiki/FP-Guide
// @ts-ignore
const fpMap = require('lodash/fp/map').convert({ cap: false });

const AppointmentDetailsRow = styled(PrintSectionBodyRow)`
    border-left: #444 1px solid;
    margin: ${pad([1, 1, 0, 2])};
    padding-bottom: ${pad(0)};
`;

const TimeHeader = styled.div`
    border-bottom: 1px solid black;
    font-size: 1.2em;
    font-weight: bold;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    width: 100%;
    margin: 0.25em 0;
`;

const PrintPagePanelBodyHeaderRow = styled(PrintSectionBodyRow)`
    text-decoration: underline;
    text-transform: uppercase;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding-bottom: ${pad(1)};

    :not(:first-child) {
        margin-top: ${pad(2)};
    }
`;

const NoteContainer = styled.div`
    padding: ${pad(1)}
    margin-top: ${pad(1)}
    //max-height: 8cm;
    //overflow: hidden;
    //border-left: 1px solid #dddddd;
    width: 100%
`;

const NoteCard = ({ note }) => {
    return (
        <>
            <PrintPagePanelBodyHeaderRow>Appointment Notes</PrintPagePanelBodyHeaderRow>
            <PrintSectionBodyRow>
                {note && (
                    <NoteContainer>
                        <PrintPageNoteText limit={1000} value={note} style={{ minHeight: '0cm' }} />
                    </NoteContainer>
                )}
            </PrintSectionBodyRow>
        </>
    );
};

const CustomerDetailsCard = ({ customer, address }) => {
    if (!customer) return null;
    return (
        <>
            <PrintPagePanelBodyHeaderRow>Customer</PrintPagePanelBodyHeaderRow>

            <PrintSectionBodyRow>
                <PrintSectionColumn>
                    <PrintSectionItem label="CRN" content={customer && customer.crn} />
                    <PrintSectionItem label="Name" content={customer && stringFormatters.formatEntityName(customer)} />
                    {customer && customer.contactName && (
                        <PrintSectionItem label="Contact Name" content={customer && customer.contactName} />
                    )}
                    {address && <PrintSectionItem label="Address" content={stringFormatters.formatAddress(address)} />}
                    {/* NOTE: Do not print bill address as per customers' feedback */}
                </PrintSectionColumn>
            </PrintSectionBodyRow>
        </>
    );
};

const CustomerContactsCard: React.FC<{ contactItems: ContactItemVM[][] }> = ({ contactItems }) => {
    if (!contactItems) {
        return null;
    }

    return (
        <>
            <PrintPagePanelBodyHeaderRow>Customers Contacts</PrintPagePanelBodyHeaderRow>
            <PrintSectionBodyRow>
                <PrintSectionColumn>
                    <ContactItems contactItems={contactItems} labelWidth={'100%'} as={PrintSectionItem} />
                </PrintSectionColumn>
            </PrintSectionBodyRow>
        </>
    );
};

const StaffCard = ({ staff }) => {
    if (!staff) return null;
    return (
        <>
            <PrintPagePanelBodyHeaderRow>Technician</PrintPagePanelBodyHeaderRow>
            <PrintSectionBodyRow>
                <PrintSectionColumn>
                    <PrintSectionItem label="Name">{stringFormatters.formatEntityName(staff, '')}</PrintSectionItem>
                </PrintSectionColumn>
            </PrintSectionBodyRow>
        </>
    );
};

const AppointmentAddressCard: React.FC<{ pool: NodeType.Pool; address: NodeType.Address }> = ({ pool, address }) => {
    if (!pool && !address) return null;

    const shouldPrintAddress = !!address?.id && pool?.address?.id !== address?.id;

    return (
        <>
            <PrintPagePanelBodyHeaderRow>Appointment Location</PrintPagePanelBodyHeaderRow>
            <PrintSectionBodyRow>
                <PrintSectionColumn>
                    {pool && (
                        <>
                            {pool.address && (
                                <PrintSectionItem
                                    label="Address"
                                    content={stringFormatters.formatAddress(pool.address)}
                                />
                            )}
                            {pool.name && <PrintSectionItem label="Name" content={pool.name} />}
                            {pool.volume && <PrintSectionItem label="Volume" content={`${pool.volume} L`} />}
                            {pool.type && <PrintSectionItem label="Type" content={pool?.type?.name || '--'} />}
                            {pool.sanitiser && (
                                <PrintSectionItem label="Sanitiser" content={pool?.sanitiser?.name || '--'} />
                            )}
                            {pool.site && (
                                <>
                                    <PrintSectionItem label="Site Name" content={pool.site.name} />
                                    {pool.site.accessKeyLocation && (
                                        <PrintSectionItem label="Site Access" content={pool.site.accessKeyLocation} />
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {shouldPrintAddress && (
                        <>
                            <PrintSectionItem label="Address">
                                <Display.Address value={address} />
                            </PrintSectionItem>
                        </>
                    )}
                </PrintSectionColumn>
            </PrintSectionBodyRow>
        </>
    );
};

const AppointmentItem: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    printAppointmentCustomerNotes: boolean;
}> = ({ appointmentItem, printAppointmentCustomerNotes }) => {
    const { isRecurring, startDate, duration, address, pool, staff, customer } = appointmentItem;

    // dont' show primary address if appointment items is provided and it is the same as customers's primaty addresss
    const primaryAddress = customer ? customer.primaryAddress : undefined;
    const appointmentAddress = address ? address : pool && pool.address;
    let shouldPrintCustomerAddress = true;
    if (primaryAddress && appointmentAddress) {
        shouldPrintCustomerAddress = primaryAddress.id !== appointmentAddress.id;
    }
    const contactItems = prepareCustomerContactItems(fromEdges(customer?.user?.entity?.contacts));

    return (
        <PrintSection>
            <PrintSectionBody style={{ border: 'none' }}>
                <PrintSectionColumn>
                    <PrintSectionBodyRow>
                        <TimeHeader>
                            <Icon name={isRecurring ? 'refresh' : 'clock'} />
                            <Display.DateRange
                                startDate={startDate}
                                duration={duration}
                                timeFormat={'LT'}
                                dayFormat={null}
                            />
                            {` (${duration} mins)`}
                        </TimeHeader>
                    </PrintSectionBodyRow>
                    <AppointmentDetailsRow>
                        <PrintSectionColumn paddingVal={[0, 0, 0, 1]}>
                            <PrintSectionBodyRow>
                                <PrintSectionColumn>
                                    {customer && (
                                        <CustomerDetailsCard
                                            customer={customer}
                                            address={shouldPrintCustomerAddress && primaryAddress}
                                        />
                                    )}
                                    <AppointmentAddressCard address={address} pool={pool} />
                                    <StaffCard staff={staff} />
                                </PrintSectionColumn>

                                <PrintSectionColumn>
                                    {contactItems && <CustomerContactsCard contactItems={contactItems} />}

                                    {printAppointmentCustomerNotes && customer?.note && (
                                        <>
                                            <PrintPagePanelBodyHeaderRow>Customer Notes</PrintPagePanelBodyHeaderRow>
                                            <PrintSectionBodyRow>
                                                <NoteContainer>
                                                    <PrintPageNoteText
                                                        formatted={true}
                                                        limit={2000}
                                                        value={customer?.note}
                                                        style={{ minHeight: '0cm' }}
                                                    />
                                                </NoteContainer>
                                            </PrintSectionBodyRow>
                                        </>
                                    )}
                                    <NoteCard note={appointmentItem.note} />
                                </PrintSectionColumn>
                            </PrintSectionBodyRow>
                        </PrintSectionColumn>
                    </AppointmentDetailsRow>
                </PrintSectionColumn>
            </PrintSectionBody>
        </PrintSection>
    );
};

type ApptDayGroup = { day: string; items: NodeType.AppointmentItem[] };

const groupAppointments = (appointmentItems: NodeType.AppointmentItem[]): ApptDayGroup[] => {
    const occurrenceDay = (appointmentItem: NodeType.AppointmentItem) => {
        return moment(appointmentItem?.startDate).startOf('day').format();
    };

    const groupToDay = (group: NodeType.AppointmentItem[], day: string): ApptDayGroup => {
        return {
            day: day,
            items: group,
        };
    };

    const filterByStaffId = (value: NodeType.AppointmentItem) => {
        return true;
    };

    const resultFP = fp.flow(
        fp.filter(filterByStaffId),
        fp.groupBy(occurrenceDay),
        fpMap(groupToDay),
        // fp.tap(console.log),
        fp.sortBy((d: ApptDayGroup) => {
            return moment(d.day).valueOf();
        })
    )(appointmentItems);

    //
    // const result = _.chain(appointmentItems)
    //     .filter(filterByStaffId)
    //     .groupBy(occurrenceDay)
    //     .map(groupToDay)
    //     .sortBy((d) => {
    //         return moment(d.day).valueOf();
    //     })
    //     .value();
    //

    return resultFP;
};

const formatPageHeader = ({ startDate, endDate }) => {
    if (moment(startDate).isSame(endDate, 'd')) {
        return `Appointments - ${stringFormatters.formatDate(startDate, 'LL')}`;
    } else {
        return `Appointments - ${stringFormatters.formatDate(startDate, 'LL')} -> ${stringFormatters.formatDate(
            endDate,
            'LL'
        )}`;
    }
};

const AppointmentsList: React.FC = () => {
    const { viewer, modulesAccess } = useViewer();
    const { CalendarState } = useCalendarActions();
    const isSingleStaffMode = modulesAccess.FieldServices?.calendarSingleStaffMode;
    const staffId = isSingleStaffMode ? viewer.me?.staff?.id : undefined;
    const { startDate, endDate } = CalendarState.displayingDateRange;
    const headerTitle = formatPageHeader(CalendarState.displayingDateRange);

    const appointmentsConnection = useQueryAppointmentItemsConnection({
        query: PrintAppointmentsQuery,
        dateRange: CalendarState.displayingDateRange,
        staffId: staffId,
        filters: CalendarState.filters,
        fetchPolicy: 'cache-and-network',
    });

    const printAppointmentCustomerNotes = modulesAccess?.FieldServices?.printAppointmentCustomerNotes;

    const groupedAppointments = groupAppointments(appointmentsConnection.connectionData);
    return (
        <>
            <Helmet title={headerTitle} />
            <div>
                <PrintPageHeader>
                    Appointments
                    {' - '}
                    <Display.DateRange startDate={startDate} endDate={endDate} dayFormat={'LL'} timeFormat={null} />
                </PrintPageHeader>
                {appointmentsConnection.connectionState?.loading ? (
                    <>
                        <PageSkeletonLoader />
                    </>
                ) : (
                    groupedAppointments.map((group) => {
                        const count = group.items.length;
                        const items = fp.sortBy<NodeType.AppointmentItem>((d) => {
                            return moment(d.startDate).valueOf();
                        })(group.items);
                        return (
                            <React.Fragment key={group.day}>
                                <PrintSectionHeader>
                                    {moment(group.day).format('dddd, LL')} ({count})
                                </PrintSectionHeader>
                                {items.map((item) => (
                                    <PrintPreferNoPageBreak key={item.id}>
                                        <AppointmentItem
                                            key={item.id}
                                            appointmentItem={item}
                                            printAppointmentCustomerNotes={printAppointmentCustomerNotes}
                                        />
                                    </PrintPreferNoPageBreak>
                                ))}
                                <PrintPreferNoPageBreak />
                            </React.Fragment>
                        );
                    })
                )}
            </div>
        </>
    );
};

export default AppointmentsList;
