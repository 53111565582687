import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import { SuggestInput } from '@ez/components';
import * as _ from 'lodash';
import { ConnectedSuggestInputProps } from './types';
import { QueryBrandConnection } from '../queries/QueryBrandConnection';

export interface SuggestBrandInputProps extends ConnectedSuggestInputProps<NodeType.Brand> {
    filterByCompanyId?: string;
}

export const SuggestBrandInput: React.FC<SuggestBrandInputProps> = ({
    value,
    label,
    onChange,
    isClearable,
    filterByCompanyId,
}) => {
    const [acSearchQuery, setAcSearchQuery] = useState<string>('');

    const handleOnSearchChange = (value: string) => {
        setAcSearchQuery(value);
    };

    const onItemSelect = (brand: NodeType.Brand) => {
        onChange(brand);
    };

    const itemRenderer = (item: NodeType.Brand): string => {
        return _.get(item, 'name', '');
    };

    const formatOptionLabel = (o, ctx) => {
        const isCtxValue = ctx.context === 'value';
        const item = o.item;
        const brandName = _.get(item, 'name');
        const companyName = _.get(item, 'company.name');

        if (isCtxValue) {
            return <>{brandName}</>;
        }

        return (
            <>
                {companyName ? (
                    <>
                        <>{brandName}</>
                        <span style={{ fontSize: '0.9em', float: 'right', fontStyle: 'italic' }}>
                            {companyName}
                        </span>{' '}
                    </>
                ) : (
                    <>{brandName}</>
                )}
            </>
        );
    };

    return (
        <QueryBrandConnection nameSearch={acSearchQuery} companyId={filterByCompanyId} fetchPolicy={'cache-first'}>
            {({ connectionData, connectionState }) => {
                return (
                    <SuggestInput
                        formatOptionLabel={formatOptionLabel}
                        isClearable={isClearable}
                        activeItem={value}
                        items={connectionData}
                        loading={connectionState.loading}
                        onItemSelect={onItemSelect}
                        onQueryChange={handleOnSearchChange}
                        itemTextLabel={itemRenderer}
                        label={label}
                        getOptionValue={(brand) => (brand ? brand.name : '')}
                    />
                );
            }}
        </QueryBrandConnection>
    );
};
