import * as React from 'react';
import { FormGroup, FormikDefaultForm, FormikInputField, SectionHeader } from '@ez/components';
import { FormikAddressInputFields } from '../../../../CommonComponents/Address/FormikAddressInputFields';

export default class NewCustomerForm extends React.Component<any> {
    render() {
        const { validationSchema, onSubmit, onCancel, initialValues } = this.props;
        return (
            <FormikDefaultForm
                validationSchema={validationSchema}
                debug={false}
                header={'New Customer'}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                submitButton={{ content: 'Create' }}
            >
                <SectionHeader>Contact Details</SectionHeader>
                <FormGroup>
                    <FormikInputField
                        name={'user.firstName'}
                        label="First Name"
                        autoComplete={'disabled'}
                        required={true}
                    />
                    <FormikInputField
                        name={'user.lastName'}
                        autoComplete={'disabled'}
                        label="Last Name"
                        required={true}
                    />
                </FormGroup>

                <FormGroup>
                    <FormikInputField name={'user.contactName'} label="Contact Name" autoComplete={'disabled'} />
                    <FormikInputField name={'user.companyName'} label="Company Name" autoComplete={'disabled'} />
                </FormGroup>

                <SectionHeader>Primary Address</SectionHeader>
                <FormikAddressInputFields name={'primaryAddress'} />
            </FormikDefaultForm>
        );
    }
}
