import * as React from 'react';
import { useState } from 'react';
import { useQuerySentEmailsConnection } from './use-query-sent-emails-connection';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    DisplayEntity,
    MenuBarHeaderItem,
    MenuBarSection,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import _truncate from 'lodash/truncate';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { SuggestInputEmailType } from './SuggestInputEmailType';
import { useViewer } from '@poolware/app-shell';

export interface PageProps {}

export const PageList: React.FC<PageProps> = () => {
    const [emailType, setEmailType] = useState<NodeType.EmailType>(null);
    const search: NodeType.EmailMessageViewerSentEmailsSearch = {};
    if (emailType?.id) {
        search.type = {
            id: emailType.id,
        };
    }

    const { connectionData, connectionState } = useQuerySentEmailsConnection({ search: search });
    const { AppNavigator } = useAppNavigator();
    const { isAdmin } = useViewer();

    const onView = (email) => {
        AppNavigator.navigateRelative(`/${email.id}`);
    };

    const tableDef: ConnectionTableDef<NodeType.EmailMessage> = [
        {
            header: 'Date',
            sortKey: 'createdAt',
            cellProps: { width: '3' },
            cell: (r) => <Display.Date format={'lll'} value={r.createdAt} />,
        },
        {
            header: 'type',
            cell: (r) => r.type?.name || '--',
        },
        {
            header: 'subject',
            cell: (r) => _truncate(r.subject, { length: 60 }),
        },
        {
            header: 'Recipient',
            cell: (r) => r.recipientEmail,
        },
        {
            header: 'Submitted By',
            cell: (r) => {
                return (
                    <span>
                        <DisplayEntity value={r?.submittedBy?.user} />
                    </span>
                );
            },
        },
        isAdmin && {
            header: 'Franchise',
            cell: (r) => {
                return <span>{r?.submittedBy?.franchise?.name}</span>;
            },
        },
    ];

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'mail'}>Emails log</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            {isAdmin && <SuggestInputEmailType value={emailType} onChange={setEmailType} />}

            <DefaultConnectionTable
                onRowClick={onView}
                // tableRowDef={tableRowProps}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </VStack>
    );
};
