import * as React from 'react';
import { Alert, ConnectionErrorMessage, PageLayout, PageSkeletonLoader, toastError } from '@ez/components';
import { NodeType, useTestReportMutators } from '@poolware/api';
import { useViewer } from '@poolware/app-shell';
import { WaterTestForm } from '../WaterTestForm';
import { useQueryPoolDetails } from './use-query-pool-details';
import { useAppNavigator } from '@poolware/react-app-navigator';
import _keys from 'lodash/keys';
import _toNumber from 'lodash/toNumber';
import { usePersistedFormState } from '../WaterTestForm/hooks';

type NavParams = {
    customerId?: string;
    poolId?: string;
    woId?: string;
    testRefId?: string;
};

const Page: React.FC<{ poolId?: NodeType.ID }> = (props) => {
    const { AppNavigator, params, query, state: navState } = useAppNavigator<NavParams, NavParams>();
    const { modulesAccess } = useViewer();

    const { autoSubmitReport, expandWaterlinkPanel, autoOpenPDF } = usePersistedFormState();

    const poolId = params.poolId || query.poolId || props.poolId;

    const { pool, prevSampleSets, chemicalTargets, loading, error } = useQueryPoolDetails(poolId);
    const { TestReportMutator } = useTestReportMutators(['PoolTestReportsList']);
    const waterlinkAllowed = modulesAccess.WaterTest?.waterlink;

    if (!poolId) {
        return <Alert type={'error'} header={'Error'} content={'Pool ID is not provided'} />;
    }

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !pool) {
        return (
            <Alert type={'error'}>
                Pool with the poolId <strong>{poolId}</strong> is not found
            </Alert>
        );
    }

    const customerId = params.customerId || query.customerId || pool?.entity?.user?.customer?.id;
    const workOrder = navState?.workOrder as NodeType.WorkOrder;
    const workOrderId = params.woId || query.woId || workOrder?.id;
    const refId = query.testRefId;

    const initialValues = {
        pool,
        manualProblems: [],
        note: '',
        measurements: {},
        refId,
    };

    const openPDF = (testReport: NodeType.TestReport) => {
        try {
            debugger;
            let fullURL = testReport?.pdfUrl;
            if (fullURL) {
                window.open(fullURL);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const { pool } = values;
            const samples = _keys(values.measurements).map((measurementTypeID) => ({
                value: _toNumber(values.measurements[measurementTypeID]),
                measurementType: measurementTypeID,
                source: '',
            }));
            const manualProblemsToAssign = values.manualProblems;

            const note = values?.note || '';

            const resp = await TestReportMutator.createReport({
                pool,
                samples,
                manualProblemsToAssign,
                note,
                workOrder: workOrderId,
                refId: values.refId,
            });

            let testReport = resp?.data?.TestReport?.TestReport;

            if (autoOpenPDF) {
                openPDF(testReport);
            }

            let reportId = testReport?.id;
            if (reportId) {
                AppNavigator.navigateRelative(`/${reportId}`, { state: navState });
            } else {
                throw new Error('Error. No ID');
            }
        } catch (error) {
            toastError({
                title: 'Failed to submit Water test report',
                description: error.message,
            });
            console.error('there was an error sending the query', error);
        }
    };

    const onCancel = () => {
        AppNavigator.navigateToOrigin();
    };

    return (
        <PageLayout width={'screen-xl'}>
            {workOrder && <Alert type={'info'}>Work Order: {workOrder?.workOrderNumber}</Alert>}
            <WaterTestForm
                waterlinkAllowed={waterlinkAllowed}
                header={'New Water Test'}
                onSubmit={onSubmit}
                onCancel={onCancel}
                initialValues={initialValues}
                chemicalTargets={chemicalTargets}
                prevSampleSets={prevSampleSets}
                pool={pool}
                showOpenPDFCheckbox={true}
            />
        </PageLayout>
    );
};

export default Page;
