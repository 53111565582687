import styled from 'styled-components';
import * as React from 'react';
import { Button, Icon, LinkButton } from '@ez/components';
import { clsxm } from '@ez/components';

const TopFilterBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    & > .row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: start;

        & > * + * {
            margin-left: 0.5rem;
        }
    }

    .filter-summary {
        font-size: 0.8em;
        height: 1em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & > * + * {
            margin-left: 0.25rem;
        }

        & > span {
            text-decoration: underline;
            font-weight: bold;
        }
    }

    .spacer {
        flex-grow: 1;
    }
`;

const ToolbarContainerRow: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={clsxm('flex flex-row flex-wrap gap-2 justify-between md:flex-nowrap', className)} {...props} />
);

const ToolbarContainerRowSummary: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={clsxm('flex flex-row flex-wrap gap-2 text-sm h-6 items-center', className)} {...props} />
);

const Spacer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={clsxm('flex-grow', className)} {...props} />
);
// const ToolbarContainerRowSummary = tw.div`flex flex-row flex-wrap gap-2 text-sm h-6 items-center`;
// const Spacer = tw.div`flex-grow`;

const ShowHideToggle: React.FC<{ showSidebar; setShowSidebar; hasActiveFilters }> = ({
    showSidebar,
    setShowSidebar,
    hasActiveFilters,
}) => (
    <Button
        basic
        color={hasActiveFilters ? 'purple' : 'grey'}
        onClick={() => setShowSidebar(!showSidebar)}
        icon={'filter'}
    />
);

const SummaryItem: React.FC = (props) => <span className={'underline font-bold'} {...props} />;

const SummaryClearButton: React.FC<{ onClick: () => any }> = ({ onClick }) => (
    <span className={'font-bold pl-2'}>
        <LinkButton onClick={onClick} content={'clear x'} />
    </span>
);

const SummaryFilterIcon: React.FC = () => (
    <div>
        <Icon name={'filter'} />
    </div>
);

export class TopFilterBar extends React.Component {
    public static Row = ToolbarContainerRow;
    public static RowSummary = ToolbarContainerRowSummary;
    public static Spacer = Spacer;
    public static ShowHideToggle = ShowHideToggle;
    public static SummaryItem = SummaryItem;
    public static SummaryClearButton = SummaryClearButton;
    public static SummaryFilterIcon = SummaryFilterIcon;

    render() {
        return <TopFilterBarContainer {...this.props} />;
    }
}
