import clsx from 'clsx';
import React, { Component } from 'react';

import * as TimeSlotUtils from './utils/TimeSlots';
import { TimeSlotGroup } from './TimeSlotGroup';
import { RBCContext } from './CalendarContext';
import { TimeSlotMetricsType } from './utils/TimeSlots';

interface TimeGutterProps {
    className?: string;
    width: number;
    min: Date;
    max: Date;
    step: number;
    timeslots: number;
    getNow: () => Date;
    resource?: string;
    date: Date;
}

export class TimeGutter extends Component<TimeGutterProps> {
    private slotMetrics: TimeSlotMetricsType;
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    constructor(props, context) {
        super(props, context);

        const { min, max, timeslots, step } = this.props;
        this.slotMetrics = TimeSlotUtils.getSlotMetrics({
            min,
            max,
            timeslots,
            step,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { min, max, timeslots, step } = nextProps;
        this.slotMetrics = this.slotMetrics.update({
            min,
            max,
            timeslots,
            step,
        });
    }

    renderSlot = (value, idx) => {
        if (idx !== 0) return null;
        const { getNow } = this.props;
        const { localizer } = this.context;

        const isNow = false; // this.slotMetrics.dateIsInGroup(getNow(), idx);
        return (
            <span className={clsx('rbc-time-gutter-label', isNow && 'rbc-now')}>
                {localizer.format(value, 'timeGutterFormat')}
            </span>
        );
    };

    render() {
        const { resource } = this.props;

        return (
            <div className="rbc-time-gutter">
                <div className={'rbc-time-gutter-content'}>
                    <div className={'rbc-time-column'}>
                        {this.slotMetrics.groups.map((grp, idx) => {
                            return (
                                <TimeSlotGroup key={idx} group={grp} resource={resource} renderSlot={this.renderSlot} />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
