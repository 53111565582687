//
// DO NOT EDIT!!!
// THIS IS AN AUTOMATICALLY GENERATED FILE.
// Date:  Fri Sep 06 2024 13:57:11 GMT+1000 (Australian Eastern Standard Time)
//

import gql from 'graphql-tag';

import { mutateloginStaffQuery, mutatepretendQuery, mutateunpretendQuery } from './mutations-queries-custom';

// ************** AbstractDocument ***************** //

// AbstractDocument: gql query
export const mutateAbstractDocumentQuery = gql`
    mutation AbstractDocumentMutation($input: AbstractDocumentMutationInput!) {
        AbstractDocument(input: $input) {
            AbstractDocument {
                id
            }
            results {
                AbstractDocument {
                    id
                }
            }
        }
    }
`;

// ************** Address ***************** //

// Address: gql query
export const mutateAddressQuery = gql`
    mutation AddressMutation($input: AddressMutationInput!) {
        Address(input: $input) {
            Address {
                id
            }
            results {
                Address {
                    id
                }
            }
        }
    }
`;

// ************** Announcement ***************** //

// Announcement: gql query
export const mutateAnnouncementQuery = gql`
    mutation AnnouncementMutation($input: AnnouncementMutationInput!) {
        Announcement(input: $input) {
            Announcement {
                id
            }
            results {
                Announcement {
                    id
                }
            }
        }
    }
`;

// ************** Appointment ***************** //

// Appointment: gql query
export const mutateAppointmentQuery = gql`
    mutation AppointmentMutation($input: AppointmentMutationInput!) {
        Appointment(input: $input) {
            Appointment {
                id
            }
            results {
                Appointment {
                    id
                }
            }
        }
    }
`;

// ************** AppointmentGroup ***************** //

// AppointmentGroup: gql query
export const mutateAppointmentGroupQuery = gql`
    mutation AppointmentGroupMutation($input: AppointmentGroupMutationInput!) {
        AppointmentGroup(input: $input) {
            AppointmentGroup {
                id
            }
            results {
                AppointmentGroup {
                    id
                }
            }
        }
    }
`;

// ************** AppointmentInstance ***************** //

// AppointmentInstance: gql query
export const mutateAppointmentInstanceQuery = gql`
    mutation AppointmentInstanceMutation($input: AppointmentInstanceMutationInput!) {
        AppointmentInstance(input: $input) {
            AppointmentInstance {
                id
            }
            results {
                AppointmentInstance {
                    id
                }
            }
        }
    }
`;

// ************** AppointmentItem ***************** //

// AppointmentItem: gql query
export const mutateAppointmentItemQuery = gql`
    mutation AppointmentItemMutation($input: AppointmentItemMutationInput!) {
        AppointmentItem(input: $input) {
            AppointmentItem {
                id
            }
            results {
                AppointmentItem {
                    id
                }
            }
        }
    }
`;

// ************** Brand ***************** //

// Brand: gql query
export const mutateBrandQuery = gql`
    mutation BrandMutation($input: BrandMutationInput!) {
        Brand(input: $input) {
            Brand {
                id
            }
            results {
                Brand {
                    id
                }
            }
        }
    }
`;

// ************** ConsumableProduct ***************** //

// ConsumableProduct: gql query
export const mutateConsumableProductQuery = gql`
    mutation ConsumableProductMutation($input: ConsumableProductMutationInput!) {
        ConsumableProduct(input: $input) {
            ConsumableProduct {
                id
            }
            results {
                ConsumableProduct {
                    id
                }
            }
        }
    }
`;

// ************** Contact ***************** //

// Contact: gql query
export const mutateContactQuery = gql`
    mutation ContactMutation($input: ContactMutationInput!) {
        Contact(input: $input) {
            Contact {
                id
            }
            results {
                Contact {
                    id
                }
            }
        }
    }
`;

// ************** ContactType ***************** //

// ContactType: gql query
export const mutateContactTypeQuery = gql`
    mutation ContactTypeMutation($input: ContactTypeMutationInput!) {
        ContactType(input: $input) {
            ContactType {
                id
            }
            results {
                ContactType {
                    id
                }
            }
        }
    }
`;

// ************** CustomContract ***************** //

// CustomContract: gql query
export const mutateCustomContractQuery = gql`
    mutation CustomContractMutation($input: CustomContractMutationInput!) {
        CustomContract(input: $input) {
            CustomContract {
                id
            }
            results {
                CustomContract {
                    id
                }
            }
        }
    }
`;

// ************** Customer ***************** //

// Customer: gql query
export const mutateCustomerQuery = gql`
    mutation CustomerMutation($input: CustomerMutationInput!) {
        Customer(input: $input) {
            Customer {
                id
            }
            results {
                Customer {
                    id
                }
            }
        }
    }
`;

// ************** CustomerNote ***************** //

// CustomerNote: gql query
export const mutateCustomerNoteQuery = gql`
    mutation CustomerNoteMutation($input: CustomerNoteMutationInput!) {
        CustomerNote(input: $input) {
            CustomerNote {
                id
            }
            results {
                CustomerNote {
                    id
                }
            }
        }
    }
`;

// ************** CustomerTag ***************** //

// CustomerTag: gql query
export const mutateCustomerTagQuery = gql`
    mutation CustomerTagMutation($input: CustomerTagMutationInput!) {
        CustomerTag(input: $input) {
            CustomerTag {
                id
            }
            results {
                CustomerTag {
                    id
                }
            }
        }
    }
`;

// ************** EZCDeviceLink ***************** //

// EZCDeviceLink: gql query
export const mutateEZCDeviceLinkQuery = gql`
    mutation EZCDeviceLinkMutation($input: EZCDeviceLinkMutationInput!) {
        EZCDeviceLink(input: $input) {
            EZCDeviceLink {
                id
            }
            results {
                EZCDeviceLink {
                    id
                }
            }
        }
    }
`;

// ************** EZCFranchiseLink ***************** //

// EZCFranchiseLink: gql query
export const mutateEZCFranchiseLinkQuery = gql`
    mutation EZCFranchiseLinkMutation($input: EZCFranchiseLinkMutationInput!) {
        EZCFranchiseLink(input: $input) {
            EZCFranchiseLink {
                id
            }
            results {
                EZCFranchiseLink {
                    id
                }
            }
        }
    }
`;

// ************** EmailGlobalSelection ***************** //

// EmailGlobalSelection: gql query
export const mutateEmailGlobalSelectionQuery = gql`
    mutation EmailGlobalSelectionMutation($input: EmailGlobalSelectionMutationInput!) {
        EmailGlobalSelection(input: $input) {
            EmailGlobalSelection {
                id
            }
            results {
                EmailGlobalSelection {
                    id
                }
            }
        }
    }
`;

// ************** EmailMessage ***************** //

// EmailMessage: gql query
export const mutateEmailMessageQuery = gql`
    mutation EmailMessageMutation($input: EmailMessageMutationInput!) {
        EmailMessage(input: $input) {
            EmailMessage {
                id
            }
            results {
                EmailMessage {
                    id
                }
            }
        }
    }
`;

// ************** EmailTemplate ***************** //

// EmailTemplate: gql query
export const mutateEmailTemplateQuery = gql`
    mutation EmailTemplateMutation($input: EmailTemplateMutationInput!) {
        EmailTemplate(input: $input) {
            EmailTemplate {
                id
            }
            results {
                EmailTemplate {
                    id
                }
            }
        }
    }
`;

// ************** EmailTemplateOrganisationSelection ***************** //

// EmailTemplateOrganisationSelection: gql query
export const mutateEmailTemplateOrganisationSelectionQuery = gql`
    mutation EmailTemplateOrganisationSelectionMutation($input: EmailTemplateOrganisationSelectionMutationInput!) {
        EmailTemplateOrganisationSelection(input: $input) {
            EmailTemplateOrganisationSelection {
                id
            }
            results {
                EmailTemplateOrganisationSelection {
                    id
                }
            }
        }
    }
`;

// ************** EmailTemplateSelection ***************** //

// EmailTemplateSelection: gql query
export const mutateEmailTemplateSelectionQuery = gql`
    mutation EmailTemplateSelectionMutation($input: EmailTemplateSelectionMutationInput!) {
        EmailTemplateSelection(input: $input) {
            EmailTemplateSelection {
                id
            }
            results {
                EmailTemplateSelection {
                    id
                }
            }
        }
    }
`;

// ************** Entity ***************** //

// Entity: gql query
export const mutateEntityQuery = gql`
    mutation EntityMutation($input: EntityMutationInput!) {
        Entity(input: $input) {
            Entity {
                id
            }
            results {
                Entity {
                    id
                }
            }
        }
    }
`;

// ************** FeatureFlag ***************** //

// FeatureFlag: gql query
export const mutateFeatureFlagQuery = gql`
    mutation FeatureFlagMutation($input: FeatureFlagMutationInput!) {
        FeatureFlag(input: $input) {
            FeatureFlag {
                id
            }
            results {
                FeatureFlag {
                    id
                }
            }
        }
    }
`;

// ************** FeatureFlagVariation ***************** //

// FeatureFlagVariation: gql query
export const mutateFeatureFlagVariationQuery = gql`
    mutation FeatureFlagVariationMutation($input: FeatureFlagVariationMutationInput!) {
        FeatureFlagVariation(input: $input) {
            FeatureFlagVariation {
                id
            }
            results {
                FeatureFlagVariation {
                    id
                }
            }
        }
    }
`;

// ************** FileUpload ***************** //

// FileUpload: gql query
export const mutateFileUploadQuery = gql`
    mutation FileUploadMutation($input: FileUploadMutationInput!) {
        FileUpload(input: $input) {
            FileUpload {
                id
            }
            results {
                FileUpload {
                    id
                }
            }
        }
    }
`;

// ************** Franchise ***************** //

// Franchise: gql query
export const mutateFranchiseQuery = gql`
    mutation FranchiseMutation($input: FranchiseMutationInput!) {
        Franchise(input: $input) {
            Franchise {
                id
            }
            results {
                Franchise {
                    id
                }
            }
        }
    }
`;

// ************** FranchiseSequenceStore ***************** //

// FranchiseSequenceStore: gql query
export const mutateFranchiseSequenceStoreQuery = gql`
    mutation FranchiseSequenceStoreMutation($input: FranchiseSequenceStoreMutationInput!) {
        FranchiseSequenceStore(input: $input) {
            FranchiseSequenceStore {
                id
            }
            results {
                FranchiseSequenceStore {
                    id
                }
            }
        }
    }
`;

// ************** FranchiseShop ***************** //

// FranchiseShop: gql query
export const mutateFranchiseShopQuery = gql`
    mutation FranchiseShopMutation($input: FranchiseShopMutationInput!) {
        FranchiseShop(input: $input) {
            FranchiseShop {
                id
            }
            results {
                FranchiseShop {
                    id
                }
            }
        }
    }
`;

// ************** FranchiseTestTreatmentView ***************** //

// FranchiseTestTreatmentView: gql query
export const mutateFranchiseTestTreatmentViewQuery = gql`
    mutation FranchiseTestTreatmentViewMutation($input: FranchiseTestTreatmentViewMutationInput!) {
        FranchiseTestTreatmentView(input: $input) {
            FranchiseTestTreatmentView {
                id
            }
            results {
                FranchiseTestTreatmentView {
                    id
                }
            }
        }
    }
`;

// ************** FranchiseValueStore ***************** //

// FranchiseValueStore: gql query
export const mutateFranchiseValueStoreQuery = gql`
    mutation FranchiseValueStoreMutation($input: FranchiseValueStoreMutationInput!) {
        FranchiseValueStore(input: $input) {
            FranchiseValueStore {
                id
            }
            results {
                FranchiseValueStore {
                    id
                }
            }
        }
    }
`;

// ************** FranchiseWarranter ***************** //

// FranchiseWarranter: gql query
export const mutateFranchiseWarranterQuery = gql`
    mutation FranchiseWarranterMutation($input: FranchiseWarranterMutationInput!) {
        FranchiseWarranter(input: $input) {
            FranchiseWarranter {
                id
            }
            results {
                FranchiseWarranter {
                    id
                }
            }
        }
    }
`;

// ************** HeaterRecommendation ***************** //

// HeaterRecommendation: gql query
export const mutateHeaterRecommendationQuery = gql`
    mutation HeaterRecommendationMutation($input: HeaterRecommendationMutationInput!) {
        HeaterRecommendation(input: $input) {
            HeaterRecommendation {
                id
            }
            results {
                HeaterRecommendation {
                    id
                }
            }
        }
    }
`;

// ************** InstallableProduct ***************** //

// InstallableProduct: gql query
export const mutateInstallableProductQuery = gql`
    mutation InstallableProductMutation($input: InstallableProductMutationInput!) {
        InstallableProduct(input: $input) {
            InstallableProduct {
                id
            }
            results {
                InstallableProduct {
                    id
                }
            }
        }
    }
`;

// ************** Item ***************** //

// Item: gql query
export const mutateItemQuery = gql`
    mutation ItemMutation($input: ItemMutationInput!) {
        Item(input: $input) {
            Item {
                id
            }
            results {
                Item {
                    id
                }
            }
        }
    }
`;

// ************** JobTodo ***************** //

// JobTodo: gql query
export const mutateJobTodoQuery = gql`
    mutation JobTodoMutation($input: JobTodoMutationInput!) {
        JobTodo(input: $input) {
            JobTodo {
                id
            }
            results {
                JobTodo {
                    id
                }
            }
        }
    }
`;

// ************** JobTodoItem ***************** //

// JobTodoItem: gql query
export const mutateJobTodoItemQuery = gql`
    mutation JobTodoItemMutation($input: JobTodoItemMutationInput!) {
        JobTodoItem(input: $input) {
            JobTodoItem {
                id
            }
            results {
                JobTodoItem {
                    id
                }
            }
        }
    }
`;

// ************** JobTodoTemplate ***************** //

// JobTodoTemplate: gql query
export const mutateJobTodoTemplateQuery = gql`
    mutation JobTodoTemplateMutation($input: JobTodoTemplateMutationInput!) {
        JobTodoTemplate(input: $input) {
            JobTodoTemplate {
                id
            }
            results {
                JobTodoTemplate {
                    id
                }
            }
        }
    }
`;

// ************** JobTodoTemplateAssoc ***************** //

// JobTodoTemplateAssoc: gql query
export const mutateJobTodoTemplateAssocQuery = gql`
    mutation JobTodoTemplateAssocMutation($input: JobTodoTemplateAssocMutationInput!) {
        JobTodoTemplateAssoc(input: $input) {
            JobTodoTemplateAssoc {
                id
            }
            results {
                JobTodoTemplateAssoc {
                    id
                }
            }
        }
    }
`;

// ************** JobTodoTemplateItem ***************** //

// JobTodoTemplateItem: gql query
export const mutateJobTodoTemplateItemQuery = gql`
    mutation JobTodoTemplateItemMutation($input: JobTodoTemplateItemMutationInput!) {
        JobTodoTemplateItem(input: $input) {
            JobTodoTemplateItem {
                id
            }
            results {
                JobTodoTemplateItem {
                    id
                }
            }
        }
    }
`;

// ************** ManualProblem ***************** //

// ManualProblem: gql query
export const mutateManualProblemQuery = gql`
    mutation ManualProblemMutation($input: ManualProblemMutationInput!) {
        ManualProblem(input: $input) {
            ManualProblem {
                id
            }
            results {
                ManualProblem {
                    id
                }
            }
        }
    }
`;

// ************** Measurement ***************** //

// Measurement: gql query
export const mutateMeasurementQuery = gql`
    mutation MeasurementMutation($input: MeasurementMutationInput!) {
        Measurement(input: $input) {
            Measurement {
                id
            }
            results {
                Measurement {
                    id
                }
            }
        }
    }
`;

// ************** Permission ***************** //

// Permission: gql query
export const mutatePermissionQuery = gql`
    mutation PermissionMutation($input: PermissionMutationInput!) {
        Permission(input: $input) {
            Permission {
                id
            }
            results {
                Permission {
                    id
                }
            }
        }
    }
`;

// ************** Pool ***************** //

// Pool: gql query
export const mutatePoolQuery = gql`
    mutation PoolMutation($input: PoolMutationInput!) {
        Pool(input: $input) {
            Pool {
                id
            }
            results {
                Pool {
                    id
                }
            }
        }
    }
`;

// ************** PoolAttachedFlagField ***************** //

// PoolAttachedFlagField: gql query
export const mutatePoolAttachedFlagFieldQuery = gql`
    mutation PoolAttachedFlagFieldMutation($input: PoolAttachedFlagFieldMutationInput!) {
        PoolAttachedFlagField(input: $input) {
            PoolAttachedFlagField {
                id
            }
            results {
                PoolAttachedFlagField {
                    id
                }
            }
        }
    }
`;

// ************** PoolBottle ***************** //

// PoolBottle: gql query
export const mutatePoolBottleQuery = gql`
    mutation PoolBottleMutation($input: PoolBottleMutationInput!) {
        PoolBottle(input: $input) {
            PoolBottle {
                id
            }
            results {
                PoolBottle {
                    id
                }
            }
        }
    }
`;

// ************** PoolBottleLease ***************** //

// PoolBottleLease: gql query
export const mutatePoolBottleLeaseQuery = gql`
    mutation PoolBottleLeaseMutation($input: PoolBottleLeaseMutationInput!) {
        PoolBottleLease(input: $input) {
            PoolBottleLease {
                id
            }
            results {
                PoolBottleLease {
                    id
                }
            }
        }
    }
`;

// ************** PoolCalibration ***************** //

// PoolCalibration: gql query
export const mutatePoolCalibrationQuery = gql`
    mutation PoolCalibrationMutation($input: PoolCalibrationMutationInput!) {
        PoolCalibration(input: $input) {
            PoolCalibration {
                id
            }
            results {
                PoolCalibration {
                    id
                }
            }
        }
    }
`;

// ************** PoolDeclarationFlagField ***************** //

// PoolDeclarationFlagField: gql query
export const mutatePoolDeclarationFlagFieldQuery = gql`
    mutation PoolDeclarationFlagFieldMutation($input: PoolDeclarationFlagFieldMutationInput!) {
        PoolDeclarationFlagField(input: $input) {
            PoolDeclarationFlagField {
                id
            }
            results {
                PoolDeclarationFlagField {
                    id
                }
            }
        }
    }
`;

// ************** PoolNote ***************** //

// PoolNote: gql query
export const mutatePoolNoteQuery = gql`
    mutation PoolNoteMutation($input: PoolNoteMutationInput!) {
        PoolNote(input: $input) {
            PoolNote {
                id
            }
            results {
                PoolNote {
                    id
                }
            }
        }
    }
`;

// ************** PoolProductDeclaration ***************** //

// PoolProductDeclaration: gql query
export const mutatePoolProductDeclarationQuery = gql`
    mutation PoolProductDeclarationMutation($input: PoolProductDeclarationMutationInput!) {
        PoolProductDeclaration(input: $input) {
            PoolProductDeclaration {
                id
            }
            results {
                PoolProductDeclaration {
                    id
                }
            }
        }
    }
`;

// ************** PoolType ***************** //

// PoolType: gql query
export const mutatePoolTypeQuery = gql`
    mutation PoolTypeMutation($input: PoolTypeMutationInput!) {
        PoolType(input: $input) {
            PoolType {
                id
            }
            results {
                PoolType {
                    id
                }
            }
        }
    }
`;

// ************** Product ***************** //

// Product: gql query
export const mutateProductQuery = gql`
    mutation ProductMutation($input: ProductMutationInput!) {
        Product(input: $input) {
            Product {
                id
            }
            results {
                Product {
                    id
                }
            }
        }
    }
`;

// ************** ProductCompany ***************** //

// ProductCompany: gql query
export const mutateProductCompanyQuery = gql`
    mutation ProductCompanyMutation($input: ProductCompanyMutationInput!) {
        ProductCompany(input: $input) {
            ProductCompany {
                id
            }
            results {
                ProductCompany {
                    id
                }
            }
        }
    }
`;

// ************** ProductDeclarationFlagField ***************** //

// ProductDeclarationFlagField: gql query
export const mutateProductDeclarationFlagFieldQuery = gql`
    mutation ProductDeclarationFlagFieldMutation($input: ProductDeclarationFlagFieldMutationInput!) {
        ProductDeclarationFlagField(input: $input) {
            ProductDeclarationFlagField {
                id
            }
            results {
                ProductDeclarationFlagField {
                    id
                }
            }
        }
    }
`;

// ************** ProductDeclarationFloatField ***************** //

// ProductDeclarationFloatField: gql query
export const mutateProductDeclarationFloatFieldQuery = gql`
    mutation ProductDeclarationFloatFieldMutation($input: ProductDeclarationFloatFieldMutationInput!) {
        ProductDeclarationFloatField(input: $input) {
            ProductDeclarationFloatField {
                id
            }
            results {
                ProductDeclarationFloatField {
                    id
                }
            }
        }
    }
`;

// ************** ProductDeclarationIntegerField ***************** //

// ProductDeclarationIntegerField: gql query
export const mutateProductDeclarationIntegerFieldQuery = gql`
    mutation ProductDeclarationIntegerFieldMutation($input: ProductDeclarationIntegerFieldMutationInput!) {
        ProductDeclarationIntegerField(input: $input) {
            ProductDeclarationIntegerField {
                id
            }
            results {
                ProductDeclarationIntegerField {
                    id
                }
            }
        }
    }
`;

// ************** ProductDeclarationMeasurementField ***************** //

// ProductDeclarationMeasurementField: gql query
export const mutateProductDeclarationMeasurementFieldQuery = gql`
    mutation ProductDeclarationMeasurementFieldMutation($input: ProductDeclarationMeasurementFieldMutationInput!) {
        ProductDeclarationMeasurementField(input: $input) {
            ProductDeclarationMeasurementField {
                id
            }
            results {
                ProductDeclarationMeasurementField {
                    id
                }
            }
        }
    }
`;

// ************** ProductDeclarationSelectionChoice ***************** //

// ProductDeclarationSelectionChoice: gql query
export const mutateProductDeclarationSelectionChoiceQuery = gql`
    mutation ProductDeclarationSelectionChoiceMutation($input: ProductDeclarationSelectionChoiceMutationInput!) {
        ProductDeclarationSelectionChoice(input: $input) {
            ProductDeclarationSelectionChoice {
                id
            }
            results {
                ProductDeclarationSelectionChoice {
                    id
                }
            }
        }
    }
`;

// ************** ProductDeclarationSelectionField ***************** //

// ProductDeclarationSelectionField: gql query
export const mutateProductDeclarationSelectionFieldQuery = gql`
    mutation ProductDeclarationSelectionFieldMutation($input: ProductDeclarationSelectionFieldMutationInput!) {
        ProductDeclarationSelectionField(input: $input) {
            ProductDeclarationSelectionField {
                id
            }
            results {
                ProductDeclarationSelectionField {
                    id
                }
            }
        }
    }
`;

// ************** ProductDeclarationStringField ***************** //

// ProductDeclarationStringField: gql query
export const mutateProductDeclarationStringFieldQuery = gql`
    mutation ProductDeclarationStringFieldMutation($input: ProductDeclarationStringFieldMutationInput!) {
        ProductDeclarationStringField(input: $input) {
            ProductDeclarationStringField {
                id
            }
            results {
                ProductDeclarationStringField {
                    id
                }
            }
        }
    }
`;

// ************** ProductDeclarationUnitField ***************** //

// ProductDeclarationUnitField: gql query
export const mutateProductDeclarationUnitFieldQuery = gql`
    mutation ProductDeclarationUnitFieldMutation($input: ProductDeclarationUnitFieldMutationInput!) {
        ProductDeclarationUnitField(input: $input) {
            ProductDeclarationUnitField {
                id
            }
            results {
                ProductDeclarationUnitField {
                    id
                }
            }
        }
    }
`;

// ************** ProductDocument ***************** //

// ProductDocument: gql query
export const mutateProductDocumentQuery = gql`
    mutation ProductDocumentMutation($input: ProductDocumentMutationInput!) {
        ProductDocument(input: $input) {
            ProductDocument {
                id
            }
            results {
                ProductDocument {
                    id
                }
            }
        }
    }
`;

// ************** ProductTrait ***************** //

// ProductTrait: gql query
export const mutateProductTraitQuery = gql`
    mutation ProductTraitMutation($input: ProductTraitMutationInput!) {
        ProductTrait(input: $input) {
            ProductTrait {
                id
            }
            results {
                ProductTrait {
                    id
                }
            }
        }
    }
`;

// ************** ProductTraitDeclaration ***************** //

// ProductTraitDeclaration: gql query
export const mutateProductTraitDeclarationQuery = gql`
    mutation ProductTraitDeclarationMutation($input: ProductTraitDeclarationMutationInput!) {
        ProductTraitDeclaration(input: $input) {
            ProductTraitDeclaration {
                id
            }
            results {
                ProductTraitDeclaration {
                    id
                }
            }
        }
    }
`;

// ************** ProductTraitFlagValue ***************** //

// ProductTraitFlagValue: gql query
export const mutateProductTraitFlagValueQuery = gql`
    mutation ProductTraitFlagValueMutation($input: ProductTraitFlagValueMutationInput!) {
        ProductTraitFlagValue(input: $input) {
            ProductTraitFlagValue {
                id
            }
            results {
                ProductTraitFlagValue {
                    id
                }
            }
        }
    }
`;

// ************** ProductTraitFloatValue ***************** //

// ProductTraitFloatValue: gql query
export const mutateProductTraitFloatValueQuery = gql`
    mutation ProductTraitFloatValueMutation($input: ProductTraitFloatValueMutationInput!) {
        ProductTraitFloatValue(input: $input) {
            ProductTraitFloatValue {
                id
            }
            results {
                ProductTraitFloatValue {
                    id
                }
            }
        }
    }
`;

// ************** ProductTraitIntegerValue ***************** //

// ProductTraitIntegerValue: gql query
export const mutateProductTraitIntegerValueQuery = gql`
    mutation ProductTraitIntegerValueMutation($input: ProductTraitIntegerValueMutationInput!) {
        ProductTraitIntegerValue(input: $input) {
            ProductTraitIntegerValue {
                id
            }
            results {
                ProductTraitIntegerValue {
                    id
                }
            }
        }
    }
`;

// ************** ProductTraitMeasurementValue ***************** //

// ProductTraitMeasurementValue: gql query
export const mutateProductTraitMeasurementValueQuery = gql`
    mutation ProductTraitMeasurementValueMutation($input: ProductTraitMeasurementValueMutationInput!) {
        ProductTraitMeasurementValue(input: $input) {
            ProductTraitMeasurementValue {
                id
            }
            results {
                ProductTraitMeasurementValue {
                    id
                }
            }
        }
    }
`;

// ************** ProductTraitSelectionValue ***************** //

// ProductTraitSelectionValue: gql query
export const mutateProductTraitSelectionValueQuery = gql`
    mutation ProductTraitSelectionValueMutation($input: ProductTraitSelectionValueMutationInput!) {
        ProductTraitSelectionValue(input: $input) {
            ProductTraitSelectionValue {
                id
            }
            results {
                ProductTraitSelectionValue {
                    id
                }
            }
        }
    }
`;

// ************** ProductTraitStringValue ***************** //

// ProductTraitStringValue: gql query
export const mutateProductTraitStringValueQuery = gql`
    mutation ProductTraitStringValueMutation($input: ProductTraitStringValueMutationInput!) {
        ProductTraitStringValue(input: $input) {
            ProductTraitStringValue {
                id
            }
            results {
                ProductTraitStringValue {
                    id
                }
            }
        }
    }
`;

// ************** ProductTraitUnitValue ***************** //

// ProductTraitUnitValue: gql query
export const mutateProductTraitUnitValueQuery = gql`
    mutation ProductTraitUnitValueMutation($input: ProductTraitUnitValueMutationInput!) {
        ProductTraitUnitValue(input: $input) {
            ProductTraitUnitValue {
                id
            }
            results {
                ProductTraitUnitValue {
                    id
                }
            }
        }
    }
`;

// ************** Project ***************** //

// Project: gql query
export const mutateProjectQuery = gql`
    mutation ProjectMutation($input: ProjectMutationInput!) {
        Project(input: $input) {
            Project {
                id
            }
            results {
                Project {
                    id
                }
            }
        }
    }
`;

// ************** ProjectVersion ***************** //

// ProjectVersion: gql query
export const mutateProjectVersionQuery = gql`
    mutation ProjectVersionMutation($input: ProjectVersionMutationInput!) {
        ProjectVersion(input: $input) {
            ProjectVersion {
                id
            }
            results {
                ProjectVersion {
                    id
                }
            }
        }
    }
`;

// ************** RecommendedTreatment ***************** //

// RecommendedTreatment: gql query
export const mutateRecommendedTreatmentQuery = gql`
    mutation RecommendedTreatmentMutation($input: RecommendedTreatmentMutationInput!) {
        RecommendedTreatment(input: $input) {
            RecommendedTreatment {
                id
            }
            results {
                RecommendedTreatment {
                    id
                }
            }
        }
    }
`;

// ************** Role ***************** //

// Role: gql query
export const mutateRoleQuery = gql`
    mutation RoleMutation($input: RoleMutationInput!) {
        Role(input: $input) {
            Role {
                id
            }
            results {
                Role {
                    id
                }
            }
        }
    }
`;

// ************** RoleFlag ***************** //

// RoleFlag: gql query
export const mutateRoleFlagQuery = gql`
    mutation RoleFlagMutation($input: RoleFlagMutationInput!) {
        RoleFlag(input: $input) {
            RoleFlag {
                id
            }
            results {
                RoleFlag {
                    id
                }
            }
        }
    }
`;

// ************** Sample ***************** //

// Sample: gql query
export const mutateSampleQuery = gql`
    mutation SampleMutation($input: SampleMutationInput!) {
        Sample(input: $input) {
            Sample {
                id
            }
            results {
                Sample {
                    id
                }
            }
        }
    }
`;

// ************** SampleConverter ***************** //

// SampleConverter: gql query
export const mutateSampleConverterQuery = gql`
    mutation SampleConverterMutation($input: SampleConverterMutationInput!) {
        SampleConverter(input: $input) {
            SampleConverter {
                id
            }
            results {
                SampleConverter {
                    id
                }
            }
        }
    }
`;

// ************** SampleConverterTemplate ***************** //

// SampleConverterTemplate: gql query
export const mutateSampleConverterTemplateQuery = gql`
    mutation SampleConverterTemplateMutation($input: SampleConverterTemplateMutationInput!) {
        SampleConverterTemplate(input: $input) {
            SampleConverterTemplate {
                id
            }
            results {
                SampleConverterTemplate {
                    id
                }
            }
        }
    }
`;

// ************** SampleSet ***************** //

// SampleSet: gql query
export const mutateSampleSetQuery = gql`
    mutation SampleSetMutation($input: SampleSetMutationInput!) {
        SampleSet(input: $input) {
            SampleSet {
                id
            }
            results {
                SampleSet {
                    id
                }
            }
        }
    }
`;

// ************** SampleSource ***************** //

// SampleSource: gql query
export const mutateSampleSourceQuery = gql`
    mutation SampleSourceMutation($input: SampleSourceMutationInput!) {
        SampleSource(input: $input) {
            SampleSource {
                id
            }
            results {
                SampleSource {
                    id
                }
            }
        }
    }
`;

// ************** Sanitiser ***************** //

// Sanitiser: gql query
export const mutateSanitiserQuery = gql`
    mutation SanitiserMutation($input: SanitiserMutationInput!) {
        Sanitiser(input: $input) {
            Sanitiser {
                id
            }
            results {
                Sanitiser {
                    id
                }
            }
        }
    }
`;

// ************** ScheduleEvent ***************** //

// ScheduleEvent: gql query
export const mutateScheduleEventQuery = gql`
    mutation ScheduleEventMutation($input: ScheduleEventMutationInput!) {
        ScheduleEvent(input: $input) {
            ScheduleEvent {
                id
            }
            results {
                ScheduleEvent {
                    id
                }
            }
        }
    }
`;

// ************** ServiceJob ***************** //

// ServiceJob: gql query
export const mutateServiceJobQuery = gql`
    mutation ServiceJobMutation($input: ServiceJobMutationInput!) {
        ServiceJob(input: $input) {
            ServiceJob {
                id
            }
            results {
                ServiceJob {
                    id
                }
            }
        }
    }
`;

// ************** ServiceJobGroup ***************** //

// ServiceJobGroup: gql query
export const mutateServiceJobGroupQuery = gql`
    mutation ServiceJobGroupMutation($input: ServiceJobGroupMutationInput!) {
        ServiceJobGroup(input: $input) {
            ServiceJobGroup {
                id
            }
            results {
                ServiceJobGroup {
                    id
                }
            }
        }
    }
`;

// ************** ServiceJobNote ***************** //

// ServiceJobNote: gql query
export const mutateServiceJobNoteQuery = gql`
    mutation ServiceJobNoteMutation($input: ServiceJobNoteMutationInput!) {
        ServiceJobNote(input: $input) {
            ServiceJobNote {
                id
            }
            results {
                ServiceJobNote {
                    id
                }
            }
        }
    }
`;

// ************** ServiceJobStage ***************** //

// ServiceJobStage: gql query
export const mutateServiceJobStageQuery = gql`
    mutation ServiceJobStageMutation($input: ServiceJobStageMutationInput!) {
        ServiceJobStage(input: $input) {
            ServiceJobStage {
                id
            }
            results {
                ServiceJobStage {
                    id
                }
            }
        }
    }
`;

// ************** ServiceJobTemplate ***************** //

// ServiceJobTemplate: gql query
export const mutateServiceJobTemplateQuery = gql`
    mutation ServiceJobTemplateMutation($input: ServiceJobTemplateMutationInput!) {
        ServiceJobTemplate(input: $input) {
            ServiceJobTemplate {
                id
            }
            results {
                ServiceJobTemplate {
                    id
                }
            }
        }
    }
`;

// ************** SettingStore ***************** //

// SettingStore: gql query
export const mutateSettingStoreQuery = gql`
    mutation SettingStoreMutation($input: SettingStoreMutationInput!) {
        SettingStore(input: $input) {
            SettingStore {
                id
            }
            results {
                SettingStore {
                    id
                }
            }
        }
    }
`;

// ************** SharedEntity ***************** //

// SharedEntity: gql query
export const mutateSharedEntityQuery = gql`
    mutation SharedEntityMutation($input: SharedEntityMutationInput!) {
        SharedEntity(input: $input) {
            SharedEntity {
                id
            }
            results {
                SharedEntity {
                    id
                }
            }
        }
    }
`;

// ************** Site ***************** //

// Site: gql query
export const mutateSiteQuery = gql`
    mutation SiteMutation($input: SiteMutationInput!) {
        Site(input: $input) {
            Site {
                id
            }
            results {
                Site {
                    id
                }
            }
        }
    }
`;

// ************** SiteNote ***************** //

// SiteNote: gql query
export const mutateSiteNoteQuery = gql`
    mutation SiteNoteMutation($input: SiteNoteMutationInput!) {
        SiteNote(input: $input) {
            SiteNote {
                id
            }
            results {
                SiteNote {
                    id
                }
            }
        }
    }
`;

// ************** Staff ***************** //

// Staff: gql query
export const mutateStaffQuery = gql`
    mutation StaffMutation($input: StaffMutationInput!) {
        Staff(input: $input) {
            Staff {
                id
            }
            results {
                Staff {
                    id
                }
            }
        }
    }
`;

// ************** StaffAccessToken ***************** //

// StaffAccessToken: gql query
export const mutateStaffAccessTokenQuery = gql`
    mutation StaffAccessTokenMutation($input: StaffAccessTokenMutationInput!) {
        StaffAccessToken(input: $input) {
            StaffAccessToken {
                id
            }
            results {
                StaffAccessToken {
                    id
                }
            }
        }
    }
`;

// ************** StaffAccessTokenRegistration ***************** //

// StaffAccessTokenRegistration: gql query
export const mutateStaffAccessTokenRegistrationQuery = gql`
    mutation StaffAccessTokenRegistrationMutation($input: StaffAccessTokenRegistrationMutationInput!) {
        StaffAccessTokenRegistration(input: $input) {
            StaffAccessTokenRegistration {
                id
            }
            results {
                StaffAccessTokenRegistration {
                    id
                }
            }
        }
    }
`;

// ************** StaffRegistration ***************** //

// StaffRegistration: gql query
export const mutateStaffRegistrationQuery = gql`
    mutation StaffRegistrationMutation($input: StaffRegistrationMutationInput!) {
        StaffRegistration(input: $input) {
            StaffRegistration {
                id
            }
            results {
                StaffRegistration {
                    id
                }
            }
        }
    }
`;

// ************** StaffTeam ***************** //

// StaffTeam: gql query
export const mutateStaffTeamQuery = gql`
    mutation StaffTeamMutation($input: StaffTeamMutationInput!) {
        StaffTeam(input: $input) {
            StaffTeam {
                id
            }
            results {
                StaffTeam {
                    id
                }
            }
        }
    }
`;

// ************** StaffTeamLink ***************** //

// StaffTeamLink: gql query
export const mutateStaffTeamLinkQuery = gql`
    mutation StaffTeamLinkMutation($input: StaffTeamLinkMutationInput!) {
        StaffTeamLink(input: $input) {
            StaffTeamLink {
                id
            }
            results {
                StaffTeamLink {
                    id
                }
            }
        }
    }
`;

// ************** StaffValueStore ***************** //

// StaffValueStore: gql query
export const mutateStaffValueStoreQuery = gql`
    mutation StaffValueStoreMutation($input: StaffValueStoreMutationInput!) {
        StaffValueStore(input: $input) {
            StaffValueStore {
                id
            }
            results {
                StaffValueStore {
                    id
                }
            }
        }
    }
`;

// ************** TestCriteria ***************** //

// TestCriteria: gql query
export const mutateTestCriteriaQuery = gql`
    mutation TestCriteriaMutation($input: TestCriteriaMutationInput!) {
        TestCriteria(input: $input) {
            TestCriteria {
                id
            }
            results {
                TestCriteria {
                    id
                }
            }
        }
    }
`;

// ************** TestProblem ***************** //

// TestProblem: gql query
export const mutateTestProblemQuery = gql`
    mutation TestProblemMutation($input: TestProblemMutationInput!) {
        TestProblem(input: $input) {
            TestProblem {
                id
            }
            results {
                TestProblem {
                    id
                }
            }
        }
    }
`;

// ************** TestRecommendation ***************** //

// TestRecommendation: gql query
export const mutateTestRecommendationQuery = gql`
    mutation TestRecommendationMutation($input: TestRecommendationMutationInput!) {
        TestRecommendation(input: $input) {
            TestRecommendation {
                id
            }
            results {
                TestRecommendation {
                    id
                }
            }
        }
    }
`;

// ************** TestReport ***************** //

// TestReport: gql query
export const mutateTestReportQuery = gql`
    mutation TestReportMutation($input: TestReportMutationInput!) {
        TestReport(input: $input) {
            TestReport {
                id
            }
            results {
                TestReport {
                    id
                }
            }
        }
    }
`;

// ************** TestReportAttachment ***************** //

// TestReportAttachment: gql query
export const mutateTestReportAttachmentQuery = gql`
    mutation TestReportAttachmentMutation($input: TestReportAttachmentMutationInput!) {
        TestReportAttachment(input: $input) {
            TestReportAttachment {
                id
            }
            results {
                TestReportAttachment {
                    id
                }
            }
        }
    }
`;

// ************** TestReportSolution ***************** //

// TestReportSolution: gql query
export const mutateTestReportSolutionQuery = gql`
    mutation TestReportSolutionMutation($input: TestReportSolutionMutationInput!) {
        TestReportSolution(input: $input) {
            TestReportSolution {
                id
            }
            results {
                TestReportSolution {
                    id
                }
            }
        }
    }
`;

// ************** TestTreatment ***************** //

// TestTreatment: gql query
export const mutateTestTreatmentQuery = gql`
    mutation TestTreatmentMutation($input: TestTreatmentMutationInput!) {
        TestTreatment(input: $input) {
            TestTreatment {
                id
            }
            results {
                TestTreatment {
                    id
                }
            }
        }
    }
`;

// ************** TestTreatmentFranchiseGroup ***************** //

// TestTreatmentFranchiseGroup: gql query
export const mutateTestTreatmentFranchiseGroupQuery = gql`
    mutation TestTreatmentFranchiseGroupMutation($input: TestTreatmentFranchiseGroupMutationInput!) {
        TestTreatmentFranchiseGroup(input: $input) {
            TestTreatmentFranchiseGroup {
                id
            }
            results {
                TestTreatmentFranchiseGroup {
                    id
                }
            }
        }
    }
`;

// ************** TestTreatmentFranchisePriority ***************** //

// TestTreatmentFranchisePriority: gql query
export const mutateTestTreatmentFranchisePriorityQuery = gql`
    mutation TestTreatmentFranchisePriorityMutation($input: TestTreatmentFranchisePriorityMutationInput!) {
        TestTreatmentFranchisePriority(input: $input) {
            TestTreatmentFranchisePriority {
                id
            }
            results {
                TestTreatmentFranchisePriority {
                    id
                }
            }
        }
    }
`;

// ************** TestTreatmentGroup ***************** //

// TestTreatmentGroup: gql query
export const mutateTestTreatmentGroupQuery = gql`
    mutation TestTreatmentGroupMutation($input: TestTreatmentGroupMutationInput!) {
        TestTreatmentGroup(input: $input) {
            TestTreatmentGroup {
                id
            }
            results {
                TestTreatmentGroup {
                    id
                }
            }
        }
    }
`;

// ************** TreatmentAffect ***************** //

// TreatmentAffect: gql query
export const mutateTreatmentAffectQuery = gql`
    mutation TreatmentAffectMutation($input: TreatmentAffectMutationInput!) {
        TreatmentAffect(input: $input) {
            TreatmentAffect {
                id
            }
            results {
                TreatmentAffect {
                    id
                }
            }
        }
    }
`;

// ************** Unit ***************** //

// Unit: gql query
export const mutateUnitQuery = gql`
    mutation UnitMutation($input: UnitMutationInput!) {
        Unit(input: $input) {
            Unit {
                id
            }
            results {
                Unit {
                    id
                }
            }
        }
    }
`;

// ************** UnitConversion ***************** //

// UnitConversion: gql query
export const mutateUnitConversionQuery = gql`
    mutation UnitConversionMutation($input: UnitConversionMutationInput!) {
        UnitConversion(input: $input) {
            UnitConversion {
                id
            }
            results {
                UnitConversion {
                    id
                }
            }
        }
    }
`;

// ************** UniversalCriteria ***************** //

// UniversalCriteria: gql query
export const mutateUniversalCriteriaQuery = gql`
    mutation UniversalCriteriaMutation($input: UniversalCriteriaMutationInput!) {
        UniversalCriteria(input: $input) {
            UniversalCriteria {
                id
            }
            results {
                UniversalCriteria {
                    id
                }
            }
        }
    }
`;

// ************** VendAPI ***************** //

// VendAPI: gql query
export const mutateVendAPIQuery = gql`
    mutation VendAPIMutation($input: VendAPIMutationInput!) {
        VendAPI(input: $input) {
            VendAPI {
                id
            }
            results {
                VendAPI {
                    id
                }
            }
        }
    }
`;

// ************** VendCustomer ***************** //

// VendCustomer: gql query
export const mutateVendCustomerQuery = gql`
    mutation VendCustomerMutation($input: VendCustomerMutationInput!) {
        VendCustomer(input: $input) {
            VendCustomer {
                id
            }
            results {
                VendCustomer {
                    id
                }
            }
        }
    }
`;

// ************** VendLink ***************** //

// VendLink: gql query
export const mutateVendLinkQuery = gql`
    mutation VendLinkMutation($input: VendLinkMutationInput!) {
        VendLink(input: $input) {
            VendLink {
                id
            }
            results {
                VendLink {
                    id
                }
            }
        }
    }
`;

// ************** VendSale ***************** //

// VendSale: gql query
export const mutateVendSaleQuery = gql`
    mutation VendSaleMutation($input: VendSaleMutationInput!) {
        VendSale(input: $input) {
            VendSale {
                id
            }
            results {
                VendSale {
                    id
                }
            }
        }
    }
`;

// ************** WarrantyClaim ***************** //

// WarrantyClaim: gql query
export const mutateWarrantyClaimQuery = gql`
    mutation WarrantyClaimMutation($input: WarrantyClaimMutationInput!) {
        WarrantyClaim(input: $input) {
            WarrantyClaim {
                id
            }
            results {
                WarrantyClaim {
                    id
                }
            }
        }
    }
`;

// ************** WarrantyClaimCase ***************** //

// WarrantyClaimCase: gql query
export const mutateWarrantyClaimCaseQuery = gql`
    mutation WarrantyClaimCaseMutation($input: WarrantyClaimCaseMutationInput!) {
        WarrantyClaimCase(input: $input) {
            WarrantyClaimCase {
                id
            }
            results {
                WarrantyClaimCase {
                    id
                }
            }
        }
    }
`;

// ************** WarrantyClaimFileAttach ***************** //

// WarrantyClaimFileAttach: gql query
export const mutateWarrantyClaimFileAttachQuery = gql`
    mutation WarrantyClaimFileAttachMutation($input: WarrantyClaimFileAttachMutationInput!) {
        WarrantyClaimFileAttach(input: $input) {
            WarrantyClaimFileAttach {
                id
            }
            results {
                WarrantyClaimFileAttach {
                    id
                }
            }
        }
    }
`;

// ************** WarrantyClaimMessage ***************** //

// WarrantyClaimMessage: gql query
export const mutateWarrantyClaimMessageQuery = gql`
    mutation WarrantyClaimMessageMutation($input: WarrantyClaimMessageMutationInput!) {
        WarrantyClaimMessage(input: $input) {
            WarrantyClaimMessage {
                id
            }
            results {
                WarrantyClaimMessage {
                    id
                }
            }
        }
    }
`;

// ************** WaterTestingRevision ***************** //

// WaterTestingRevision: gql query
export const mutateWaterTestingRevisionQuery = gql`
    mutation WaterTestingRevisionMutation($input: WaterTestingRevisionMutationInput!) {
        WaterTestingRevision(input: $input) {
            WaterTestingRevision {
                id
            }
            results {
                WaterTestingRevision {
                    id
                }
            }
        }
    }
`;

// ************** WorkOrder ***************** //

// WorkOrder: gql query
export const mutateWorkOrderQuery = gql`
    mutation WorkOrderMutation($input: WorkOrderMutationInput!) {
        WorkOrder(input: $input) {
            WorkOrder {
                id
            }
            results {
                WorkOrder {
                    id
                }
            }
        }
    }
`;

// ************** WorkOrderFeedItem ***************** //

// WorkOrderFeedItem: gql query
export const mutateWorkOrderFeedItemQuery = gql`
    mutation WorkOrderFeedItemMutation($input: WorkOrderFeedItemMutationInput!) {
        WorkOrderFeedItem(input: $input) {
            WorkOrderFeedItem {
                id
            }
            results {
                WorkOrderFeedItem {
                    id
                }
            }
        }
    }
`;

// ************** WorkOrderNote ***************** //

// WorkOrderNote: gql query
export const mutateWorkOrderNoteQuery = gql`
    mutation WorkOrderNoteMutation($input: WorkOrderNoteMutationInput!) {
        WorkOrderNote(input: $input) {
            WorkOrderNote {
                id
            }
            results {
                WorkOrderNote {
                    id
                }
            }
        }
    }
`;

// ************** WorkOrderTemplate ***************** //

// WorkOrderTemplate: gql query
export const mutateWorkOrderTemplateQuery = gql`
    mutation WorkOrderTemplateMutation($input: WorkOrderTemplateMutationInput!) {
        WorkOrderTemplate(input: $input) {
            WorkOrderTemplate {
                id
            }
            results {
                WorkOrderTemplate {
                    id
                }
            }
        }
    }
`;

// ************** WorkOrderTemplateAssoc ***************** //

// WorkOrderTemplateAssoc: gql query
export const mutateWorkOrderTemplateAssocQuery = gql`
    mutation WorkOrderTemplateAssocMutation($input: WorkOrderTemplateAssocMutationInput!) {
        WorkOrderTemplateAssoc(input: $input) {
            WorkOrderTemplateAssoc {
                id
            }
            results {
                WorkOrderTemplateAssoc {
                    id
                }
            }
        }
    }
`;

// Mutation Query Lookup table

const __mutations = {
    AbstractDocument: mutateAbstractDocumentQuery,
    Address: mutateAddressQuery,
    Announcement: mutateAnnouncementQuery,
    Appointment: mutateAppointmentQuery,
    AppointmentGroup: mutateAppointmentGroupQuery,
    AppointmentInstance: mutateAppointmentInstanceQuery,
    AppointmentItem: mutateAppointmentItemQuery,
    Brand: mutateBrandQuery,
    ConsumableProduct: mutateConsumableProductQuery,
    Contact: mutateContactQuery,
    ContactType: mutateContactTypeQuery,
    CustomContract: mutateCustomContractQuery,
    Customer: mutateCustomerQuery,
    CustomerNote: mutateCustomerNoteQuery,
    CustomerTag: mutateCustomerTagQuery,
    EZCDeviceLink: mutateEZCDeviceLinkQuery,
    EZCFranchiseLink: mutateEZCFranchiseLinkQuery,
    EmailGlobalSelection: mutateEmailGlobalSelectionQuery,
    EmailMessage: mutateEmailMessageQuery,
    EmailTemplate: mutateEmailTemplateQuery,
    EmailTemplateOrganisationSelection: mutateEmailTemplateOrganisationSelectionQuery,
    EmailTemplateSelection: mutateEmailTemplateSelectionQuery,
    Entity: mutateEntityQuery,
    FeatureFlag: mutateFeatureFlagQuery,
    FeatureFlagVariation: mutateFeatureFlagVariationQuery,
    FileUpload: mutateFileUploadQuery,
    Franchise: mutateFranchiseQuery,
    FranchiseSequenceStore: mutateFranchiseSequenceStoreQuery,
    FranchiseShop: mutateFranchiseShopQuery,
    FranchiseTestTreatmentView: mutateFranchiseTestTreatmentViewQuery,
    FranchiseValueStore: mutateFranchiseValueStoreQuery,
    FranchiseWarranter: mutateFranchiseWarranterQuery,
    HeaterRecommendation: mutateHeaterRecommendationQuery,
    InstallableProduct: mutateInstallableProductQuery,
    Item: mutateItemQuery,
    JobTodo: mutateJobTodoQuery,
    JobTodoItem: mutateJobTodoItemQuery,
    JobTodoTemplate: mutateJobTodoTemplateQuery,
    JobTodoTemplateAssoc: mutateJobTodoTemplateAssocQuery,
    JobTodoTemplateItem: mutateJobTodoTemplateItemQuery,
    ManualProblem: mutateManualProblemQuery,
    Measurement: mutateMeasurementQuery,
    Permission: mutatePermissionQuery,
    Pool: mutatePoolQuery,
    PoolAttachedFlagField: mutatePoolAttachedFlagFieldQuery,
    PoolBottle: mutatePoolBottleQuery,
    PoolBottleLease: mutatePoolBottleLeaseQuery,
    PoolCalibration: mutatePoolCalibrationQuery,
    PoolDeclarationFlagField: mutatePoolDeclarationFlagFieldQuery,
    PoolNote: mutatePoolNoteQuery,
    PoolProductDeclaration: mutatePoolProductDeclarationQuery,
    PoolType: mutatePoolTypeQuery,
    Product: mutateProductQuery,
    ProductCompany: mutateProductCompanyQuery,
    ProductDeclarationFlagField: mutateProductDeclarationFlagFieldQuery,
    ProductDeclarationFloatField: mutateProductDeclarationFloatFieldQuery,
    ProductDeclarationIntegerField: mutateProductDeclarationIntegerFieldQuery,
    ProductDeclarationMeasurementField: mutateProductDeclarationMeasurementFieldQuery,
    ProductDeclarationSelectionChoice: mutateProductDeclarationSelectionChoiceQuery,
    ProductDeclarationSelectionField: mutateProductDeclarationSelectionFieldQuery,
    ProductDeclarationStringField: mutateProductDeclarationStringFieldQuery,
    ProductDeclarationUnitField: mutateProductDeclarationUnitFieldQuery,
    ProductDocument: mutateProductDocumentQuery,
    ProductTrait: mutateProductTraitQuery,
    ProductTraitDeclaration: mutateProductTraitDeclarationQuery,
    ProductTraitFlagValue: mutateProductTraitFlagValueQuery,
    ProductTraitFloatValue: mutateProductTraitFloatValueQuery,
    ProductTraitIntegerValue: mutateProductTraitIntegerValueQuery,
    ProductTraitMeasurementValue: mutateProductTraitMeasurementValueQuery,
    ProductTraitSelectionValue: mutateProductTraitSelectionValueQuery,
    ProductTraitStringValue: mutateProductTraitStringValueQuery,
    ProductTraitUnitValue: mutateProductTraitUnitValueQuery,
    Project: mutateProjectQuery,
    ProjectVersion: mutateProjectVersionQuery,
    RecommendedTreatment: mutateRecommendedTreatmentQuery,
    Role: mutateRoleQuery,
    RoleFlag: mutateRoleFlagQuery,
    Sample: mutateSampleQuery,
    SampleConverter: mutateSampleConverterQuery,
    SampleConverterTemplate: mutateSampleConverterTemplateQuery,
    SampleSet: mutateSampleSetQuery,
    SampleSource: mutateSampleSourceQuery,
    Sanitiser: mutateSanitiserQuery,
    ScheduleEvent: mutateScheduleEventQuery,
    ServiceJob: mutateServiceJobQuery,
    ServiceJobGroup: mutateServiceJobGroupQuery,
    ServiceJobNote: mutateServiceJobNoteQuery,
    ServiceJobStage: mutateServiceJobStageQuery,
    ServiceJobTemplate: mutateServiceJobTemplateQuery,
    SettingStore: mutateSettingStoreQuery,
    SharedEntity: mutateSharedEntityQuery,
    Site: mutateSiteQuery,
    SiteNote: mutateSiteNoteQuery,
    Staff: mutateStaffQuery,
    StaffAccessToken: mutateStaffAccessTokenQuery,
    StaffAccessTokenRegistration: mutateStaffAccessTokenRegistrationQuery,
    StaffRegistration: mutateStaffRegistrationQuery,
    StaffTeam: mutateStaffTeamQuery,
    StaffTeamLink: mutateStaffTeamLinkQuery,
    StaffValueStore: mutateStaffValueStoreQuery,
    TestCriteria: mutateTestCriteriaQuery,
    TestProblem: mutateTestProblemQuery,
    TestRecommendation: mutateTestRecommendationQuery,
    TestReport: mutateTestReportQuery,
    TestReportAttachment: mutateTestReportAttachmentQuery,
    TestReportSolution: mutateTestReportSolutionQuery,
    TestTreatment: mutateTestTreatmentQuery,
    TestTreatmentFranchiseGroup: mutateTestTreatmentFranchiseGroupQuery,
    TestTreatmentFranchisePriority: mutateTestTreatmentFranchisePriorityQuery,
    TestTreatmentGroup: mutateTestTreatmentGroupQuery,
    TreatmentAffect: mutateTreatmentAffectQuery,
    Unit: mutateUnitQuery,
    UnitConversion: mutateUnitConversionQuery,
    UniversalCriteria: mutateUniversalCriteriaQuery,
    VendAPI: mutateVendAPIQuery,
    VendCustomer: mutateVendCustomerQuery,
    VendLink: mutateVendLinkQuery,
    VendSale: mutateVendSaleQuery,
    WarrantyClaim: mutateWarrantyClaimQuery,
    WarrantyClaimCase: mutateWarrantyClaimCaseQuery,
    WarrantyClaimFileAttach: mutateWarrantyClaimFileAttachQuery,
    WarrantyClaimMessage: mutateWarrantyClaimMessageQuery,
    WaterTestingRevision: mutateWaterTestingRevisionQuery,
    WorkOrder: mutateWorkOrderQuery,
    WorkOrderFeedItem: mutateWorkOrderFeedItemQuery,
    WorkOrderNote: mutateWorkOrderNoteQuery,
    WorkOrderTemplate: mutateWorkOrderTemplateQuery,
    WorkOrderTemplateAssoc: mutateWorkOrderTemplateAssocQuery,
    loginStaff: mutateloginStaffQuery,
    pretend: mutatepretendQuery,
    unpretend: mutateunpretendQuery,
};

export const getMutationQueryByName = (mutationName: string) => {
    return __mutations[mutationName];
};
