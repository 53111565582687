import styled from 'styled-components';

const eventTextColor = '#202020';
const eventTextColorInverted = '#f9ffde';

export const CalendarStyledContainer = styled.div`
    @keyframes highlight {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.3);
        }
    }

    user-select: none;
    //background-color: var(--ez-main-background-color-tertiary, inherit);

    --rbc-grid-cell-border-color: var(--ez-calendar-grid-border-color, #f3f3f3);
    //--rbc-grid-cell-border-color: hsl(0 0% 99% / 0.6);

    .rbc-timeslot-group {
        //border: 1px solid var(--ez-calendar-grid-non-working-hours-border-color, #f3f3f3);
        //border: 0px solid #303030;
        //border-right: none;

        //&:last-child {
        //    border-right: 0.5px solid #f3f3f3;
        //}
    }

    .rbc-day-slot {
        .rbc-event {
            background-color: white;
            border-radius: 4px;
            border: 1px solid rgb(0 0 0 / 25%);
            box-shadow: 0 0 16px 0px rgb(0 0 0 / 15%);
        }

        .rbc-time-slot {
            &.workingHours {
                background-color: var(--ez-calendar-grid-working-hours-background-color, #ffffff);
                //&:not(:first-child) {
                border-top: 1px solid var(--ez-calendar-grid-working-hours-border-color, #f3f3f3);
                //border-top: 1px solid var(--ez-calendar-grid-working-hours-background-color, #f3f3f3);
                //}
            }

            &.nonWorkingHours {
                background-color: var(--ez-calendar-grid-non-working-hours-background-color, #ececec);
                border-top: 1px solid var(--ez-calendar-grid-non-working-hours-border-color, #fafafa);
                //border-top: 1px solid var(--ez-calendar-grid-non-working-hours-background-color, #fafafa);
            }
        }
    }

    .rbc-event {
        &.selected {
            border: 3px solid #ff191e;
            box-shadow: 0 0 4px 1px #ff191e;
        }

        &.highlighted-pop,
        &.highlighted {
            //border: 4px solid #ff191e;
            z-index: 1;
        }

        &.highlighted-pop {
            animation: highlight 250ms ease-out 2 alternate;
        }
    }

    .rbc-addons-dnd {
        &.rbc-addons-dnd-is-dragging {
            .rbc-event.rbc-addons-dnd-drag-preview {
                //border: 2px solid #ff191e;
                box-shadow: 0 0 4px 2px #ff191e;
            }

            /// "rbc-addons-dnd-dragged-event" class is set in dragAndDrop/EventWrapper.tsx
            /// "rbc-addons-dnd-dragged-event" class is set in dragAndDrop/EventContainerWrapper.tsx

            .rbc-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview) {
                /// "density-xxx" class is set in CalendarEventAppItem.tsx
                .density-normal {
                    .pw-item-extra {
                        display: none;
                    }
                }

                .density-reduced {
                    .pw-item {
                        display: none;
                    }
                }

                .density-low {
                    display: none;
                }
            }
        }
    }

    .rbc-current-time-indicator {
        background-color: red !important;
        right: 2px !important;
        height: 2px;

        &::before {
            display: block;

            position: absolute;
            left: -3px;
            top: -3px;

            content: ' ';
            background-color: red;

            border-radius: 50%;
            width: 8px;
            height: 8px;
        }
    }

    .pw-dropdown-padding-fix.ui.labeled.icon.button {
        padding-left: 3em !important;
        white-space: nowrap;
        text-overflow: clip;
    }

    .pw-calendar-event {
        padding: 1px;
        display: flex;
        height: 100%;

        line-height: 1em;

        flex-direction: column;
        position: relative;

        &.popover {
            width: auto;
        }

        &.horizontal {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;
        }

        // default text color
        color: ${eventTextColor};

        * > a {
            color: ${eventTextColor};
        }

        &.dimmed {
            color: #404040;
        }

        &.highlighted {
            color: #000000;
        }

        // text color for black background (e.g. when in popover)

        &.inverted {
            color: ${eventTextColorInverted};

            * > a {
                color: ${eventTextColorInverted};
            }
        }

        .pw-item {
            font-size: 0.8em;
            padding: 1px 2px;
            line-height: 1.2em;

            &.pw-time {
                font-weight: bold;
                color: #3b3b3b;
                //margin-top: -4px;
            }

            &.pw-customer {
                & > a {
                    //color: #202020;
                }

                text-decoration: underline;
            }

            &.pw-job {
                color: #5e0a73;
            }

            &.pw-address {
                margin-top: 4px;
                font-size: 0.7em;
            }

            .pw-note {
                background-color: #e8e8e8;
                border: 1px solid #bfbfbf;
                padding: 2px;
                margin: 0;
                border-radius: 2px;
                color: black;

                p {
                    line-height: 1em;
                    font-size: 0.9em;
                }

                max-height: 140px;
                overflow: auto;

                &.preview {
                    background-color: #e8e8e8;
                    border: unset;
                    margin-top: 2px;
                    padding-left: 4px;
                    padding-right: 4px;
                    //border-left: 1px solid white;
                    //color: ${eventTextColor};
                }
            }

            &-icons {
                color: #2a63a1;
                position: absolute;
                background-color: rgba(255, 255, 255, 0.75);
                border-radius: 3px;
                right: 1px;
                top: 1px;
                padding: 0.125em 0.25em;
                overflow: visible;
                display: flex;
                flex-direction: row;
                gap: 2px;
                text-align: center;
                height: 1.5em;

                & i.icon {
                    text-align: center;
                    margin: 0;
                }
            }
        }
    }
`;
