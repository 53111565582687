import * as React from 'react';
import { compose } from '@ez/tools';
import { AppointmentReportsActionProps, withAppointmentReportsActions } from '../../../redux';
import { Button } from '@ez/components';

const ClearAllFilters: React.FC<AppointmentReportsActionProps> = (props) => {
    const clearAll = () => {
        props.AppointmentReportsAction.clearAllFilters();
    };

    const { hasActiveFilters } = props.AppointmentReportsState.filters;
    return (
        <div className={'p-1'}>
            <Button
                disabled={!hasActiveFilters}
                size={'sm'}
                icon={'close'}
                color={'purple'}
                onClick={clearAll}
                content={'Clear all filters'}
                className={'w-full'}
            />
        </div>
    );
};

export default compose(withAppointmentReportsActions())(ClearAllFilters);
