import * as React from 'react';
import { useMemo } from 'react';
import {
    FormikDatePickerInputField,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    FormSectionBody,
    FormSectionHeader,
    Icon,
    PageLayout,
    toastError,
} from '@ez/components';
import { FormikCustomerAndLocationInputFields, FormikSuggestInputStaff, useViewer } from '@poolware/app-shell';
import { useAppNavigator } from '@poolware/react-app-navigator';
import * as Yup from 'yup';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { useServiceJobActions } from '../../index';
import { FormikServiceJobTemplateSelect } from '../../connected-components/FormikServiceJobTemplateSelect';
import { NodeType } from '@poolware/api';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required').max(250),
    description: Yup.string().notRequired().max(4000),
});

type ServiceJobFormValueType = {
    title: string;
    description: string;
    address?: NodeType.Address;
    pool?: NodeType.Pool;
    customer?: NodeType.Customer;
    staff?: NodeType.Staff;
    template?: NodeType.ServiceJobTemplate;
    template_wos?: NodeType.WorkOrderTemplate[];
    dueDate?: Date;
    staffReadOnly?: boolean;
};

export const ServiceJobSingleNew: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess, viewer } = useViewer();

    const allowSJTemplates = modulesAccess.FieldServices?.caseTemplates;

    const sjActions = useServiceJobActions();
    const { customer, address, pool, returnTo } = sjActions.ServiceJobState.details;

    const { create } = useServiceJobMutators({ refetchQueries: ['QueryServiceJob'] });

    let initialValues = useMemo<ServiceJobFormValueType>(() => {
        const initialValues = {
            title: '',
            description: '',
            address: address,
            pool: pool,
            customer: customer,
            staff: null,
            template: null,
            template_wos: [],

            dueDate: null,
            staffReadOnly: false,
        };
        if (modulesAccess.FieldServices?.calendarSingleStaffMode) {
            // lock field only if `me.staff` is available
            initialValues.staffReadOnly = !!viewer.me?.staff;
            initialValues.staff = viewer.me?.staff;
        }

        return initialValues;
    }, [modulesAccess, viewer]);

    const onSubmit = async (values: ServiceJobFormValueType) => {
        try {
            const wos = values?.template_wos?.map<NodeType.CreateServiceJobListWorkOrderInput>((wo) => ({
                template: wo.id,
                autoInflateTemplate: true,
            }));
            const hasWOS = wos.length > 0;

            const conf: NodeType.CreateServiceJobMutationInput = {
                entity: { create: {} },
                title: values.title,
                description: values.description,
                dueDate: values.dueDate,
                address: values.address?.id,
                pool: values.pool?.id,
                customer: values.customer?.id,
                staff: values.staff?.id,
                template: values.template?.id,
                workOrders: hasWOS ? wos : undefined,
                autoInflateTemplate: !hasWOS,
            };

            const resp = await create(conf);

            const id = resp.data?.ServiceJob?.ServiceJob?.id;
            sjActions.ServiceJobAction.serviceJobCreated({ id: id });
            AppNavigator.replace(`/sj/${id}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to create', description: e.message });
        }
    };

    const onCancel = () => {
        if (sjActions.ServiceJobState.isSagaMode) {
            sjActions.ServiceJobAction.abort();
        } else {
            AppNavigator.navigateToOrigin(returnTo);
        }
    };

    return (
        <PageLayout width={'screen-md'}>
            <FormikDefaultForm
                headerColor={ModuleColorNames.ServiceCall}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                debug={true}
                submitOnEnter={false}
                validationSchema={validationSchema}
                header={
                    <>
                        <Icon name={ModuleIconNames.ServiceCall} /> New Service Case
                    </>
                }
                submitButton={{ content: 'Next' }}
            >
                {({ setFieldValue, values }) => {
                    const onSelectTemplate = (template: NodeType.ServiceJobTemplate) => {
                        setFieldValue('title', template?.templateTitle, true);
                    };
                    const selectedWos = (values as ServiceJobFormValueType).template_wos?.map((w) => w.id);
                    return (
                        <>
                            {allowSJTemplates && (
                                <FormikServiceJobTemplateSelect
                                    name={'template'}
                                    selectWorkOrder={'multiple'}
                                    defaultSelectAllWorkOrders={true}
                                    onDidSelect={onSelectTemplate}
                                    previewWorkOrderIds={selectedWos}
                                />
                            )}
                            <FormikInputField autoFocus name={'title'} label={'Subject'} required={true} />
                            <FormikTextareaField
                                name={'description'}
                                label={'Case Description'}
                                required={false}
                                rows={8}
                            />
                            {/*<FormSectionHeader>Staff</FormSectionHeader>*/}
                            <FormSectionBody>
                                <FormikSuggestInputStaff
                                    name={'staff'}
                                    label={'Assigned To'}
                                    readOnly={values.staffReadOnly}
                                />
                            </FormSectionBody>

                            <FormSectionHeader>Customer / Location</FormSectionHeader>
                            <FormSectionBody>
                                <FormikCustomerAndLocationInputFields />
                            </FormSectionBody>
                            <FormikDatePickerInputField name={'dueDate'} label={'Due date'} />
                        </>
                    );
                }}
            </FormikDefaultForm>
        </PageLayout>
    );
};
