import * as React from 'react';
import { useEffect } from 'react';
import { AuthConnectProps } from '@poolware/api';
import { Icon, AppGlobalStyle, SemanticToastContainer } from '@ez/components';
import { getThemeByName } from '@poolware/themes';
import { getAppConfig } from './app-config';
import { useViewerContext } from './Viewer';
import { AppErrorBoundary, AppHelmet, removeDimmer } from './components';
import styled from 'styled-components';
import { DebugPanelCtrl } from './components/DebugPanelCtrl';
import { AppThemeHelmet, useTheme } from './app-theme';
import { setTrackId, withTracker } from './withTracker';
import { UnsupportedBrowserWarning } from './UnsupportedBrowserWarning';

const config = getAppConfig();

interface AppProps extends AuthConnectProps {}

const StyledSpinnerDiv = styled.div`
    margin: 0 auto;
    text-align: center;
    padding: 120px 0;
`;

// TODO: fix icon
const AuthenticationSpinner = () => (
    <StyledSpinnerDiv className={'bg-background text-primary'}>
        {/*<Icon.Group size="huge">*/}
        <Icon name="lock" />
        {/*<Icon corner name="setting" loading inverted />*/}
        {/*</Icon.Group>*/}
        <br />
        Authenticating...
    </StyledSpinnerDiv>
);

const AppRootComp: React.FC<AppProps> = (props) => {
    const { ThemeAction, ThemeState } = useTheme();
    const viewerContext = useViewerContext();

    const setTheme = (themeName) => {
        const baseURL = process.env.PUBLIC_URL;
        const theme = getThemeByName(baseURL, themeName);
        ThemeAction.setTheme(theme);
    };

    useEffect(() => {
        // remove dimmer and set theme on initial load
        setTheme(config.themeName);
        removeDimmer();
    }, []);

    useEffect(() => {
        if (!viewerContext.isAuthenticated) {
            return;
        }

        try {
            const themeName = viewerContext.getFeatureFlag('THEME');
            // Update theme
            if (themeName) {
                setTheme(themeName);
            } else if (viewerContext.isAdmin) {
                setTheme(config.themeNameAdmin);
            } else {
                setTheme(config.themeName);
            }
            setTrackId(viewerContext.viewer?.trackId);
        } catch (e) {
            console.error(e);
        }
    }, [viewerContext]);

    const checkingAuthState = viewerContext.checkingAuthState && !viewerContext.allowBackgroundAuthCheck;

    return (
        <AppErrorBoundary root={true}>
            <>
                <AppHelmet />
                <AppGlobalStyle />
                {checkingAuthState ? (
                    <AuthenticationSpinner />
                ) : (
                    <>
                        <AppThemeHelmet theme={ThemeState.theme} appTitle={config.appName} />
                        {props.children}
                    </>
                )}
                <SemanticToastContainer position="top-center" />
                <AppErrorBoundary>
                    <UnsupportedBrowserWarning />
                    <DebugPanelCtrl />
                </AppErrorBoundary>
            </>
        </AppErrorBoundary>
    );
};

export const AppRoot = withTracker()(AppRootComp);
