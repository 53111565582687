import * as React from 'react';
import { ConnectionTableProps, ConnectionTable } from './ConnectionTable';
import { ScrollX, VStack } from '../layout-primitives';
import { ConnectionPaginationButtons } from './ConnectionPaginationButtons';
import { ConnectionBarProgress } from './ConnectionProgress';
import styled from 'styled-components';
import { ConnectionTableDebug } from './ConnectionTableDebug';
import { useMemo } from 'react';
import { useModalCtrl } from '../use-modal-ctrl';
import { IconButton } from '../buttons';
import { DebugJSON } from '../dev-tools';
import { Modal } from '../semantic-ui';

const TableContainer = styled.div`
    padding: 0;
    .ui.table {
        margin-bottom: 0 !important;
    }
`;

export interface DefaultConnectionTableProps extends ConnectionTableProps {
    showProgress?: boolean;
    debug?: boolean;
}

const DebugInfoButton: React.FC<{ item: any }> = ({ item }) => {
    const m = useModalCtrl();
    return (
        <>
            <IconButton
                size={'sm'}
                icon={'bug'}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    m.onOpen();
                }}
            />
            <Modal {...m}>
                <div style={{ maxHeight: '500px', overflow: 'auto' }} onClick={(e) => e.stopPropagation()}>
                    <DebugJSON data={item} />
                </div>
            </Modal>
        </>
    );
};

const debugCol = {
    header: '',
    width: 1,
    cell: (item) => {
        return <DebugInfoButton item={item} />;
    },
};

const isDevelopment = process.env.NODE_ENV === 'development';

export const DefaultConnectionTable: React.FC<DefaultConnectionTableProps> = (props) => {
    const { showProgress = true, tableProps, debug, tableDef, showPagination = true, ...rest } = props;
    const tb: any = showProgress ? { ...tableProps } : tableProps;

    const _tableDef = useMemo(() => (debug && isDevelopment ? [debugCol, ...tableDef] : tableDef), [debug, tableDef]);
    return (
        <VStack>
            <TableContainer>
                <ScrollX>
                    <ConnectionTable tableDef={_tableDef} {...rest} tableProps={tb} />
                    {showProgress && <ConnectionBarProgress {...rest} />}
                </ScrollX>
            </TableContainer>
            {showPagination && (
                <ConnectionPaginationButtons
                    connectionState={props.connectionState}
                    connectionData={props.connectionData}
                />
            )}
            {debug && <ConnectionTableDebug tableDef={_tableDef} hidden={!debug} {...rest} />}
        </VStack>
    );
};
