import * as React from 'react';

import { Segment, SegmentProps } from '../Segment';
import { clsxm } from '../utils';

export interface PanelBodyProps extends SegmentProps {
    // TODO: HACK: temporary added legacy props to support old code
    secondary?: boolean;
    disabled?: boolean;
    textAlign?: any;
}

const PanelBody: React.FC<PanelBodyProps> = (props) => {
    const { className, ...rest } = props;
    return <Segment className={clsxm('bg-panel py-2 ez-panel-body', className)} {...rest} />;
};

export default PanelBody;
