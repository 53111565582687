import * as React from 'react';
import { useState } from 'react';
import { PageTable } from './Page.Table';
import { NodeType } from '@poolware/api';
import { DebouncedInput, MenuBarHeaderItem, MenuBarItem, MenuBarSection, StickyMenuBar, VStack } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { ModuleIconNames } from '../../../constants';
import * as _ from 'lodash';

const Page: React.FC = () => {
    const { AppNavigator } = useAppNavigator();

    const [searchTerm, setSearchTerm] = useState('');

    const onView = (item: NodeType.JobTodoTemplate) => {
        AppNavigator.navigate(`/job-todo-template/${item.id}`, {
            relativeToModule: true,
        });
    };

    const onNew = () => {
        AppNavigator.navigate('/job-todo-template/new', {
            setOrigin: true,
            modal: false,
            relativeToModule: true,
        });
    };

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            setSearchTerm('');
        } else {
            setSearchTerm(newValue);
        }
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={ModuleIconNames.JobTodoTemplate}>Job Task Templates</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem onClick={onNew} color={'green'} icon={'plus'} title={'New'} />
                </MenuBarSection>
            </StickyMenuBar>

            <DebouncedInput
                label={'Search'}
                initialValue={''}
                placeholder={'Search template'}
                onChange={handleChange}
            />

            <PageTable onView={onView} searchTerm={searchTerm} />
        </VStack>
    );
};

export default Page;
