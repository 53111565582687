import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';

import { withApolloLoading } from '@ez/components';
import { addressFragment, IMutateSite, NodeType, siteFragment, withSiteMutator } from '@poolware/api';

import Page from './Page';
import { IWithPageProps, withPageProps } from '../../../../components/withPageProps';

const SiteEditQuery = gql`
    query SiteEditQuery($siteId: ID!) {
        viewer {
            site: resolveNode(id: $siteId) {
                ... on Site {
                    id
                    ...SiteFragment
                }
            }
        }
    }
    ${siteFragment}
    ${addressFragment}
`;

export default compose(
    withPageProps(),
    graphql(SiteEditQuery, {
        options: (props: any) => {
            const siteId = props.params.siteId || props.siteId;
            return {
                variables: { siteId: siteId },
                fetchPolicy: 'cache-and-network',
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.viewer.site.id' }),
    mapProps((props) => {
        const site = props?.data?.viewer?.site;
        return {
            ...props,
            site: site,
        };
    }),
    withSiteMutator(['CustomerSitesList'])
)(Page);

export interface PageControl extends IWithPageProps, IMutateSite {
    site: NodeType.Site;
}
