import * as React from 'react';
import {
    ConnectionTableDef,
    Display,
    FormField,
    FormikFormFieldLabel,
    Icon,
    InputWithAction,
    Modal,
    TablePickerPanel,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import { useQueryEzcDeviceConnection } from './use-query-ezc-device-connection';
import { useField } from 'formik';
import { useModalCtrl } from '@ez/components';

export interface JobTodoTemplatePickerProps {
    onSubmit: (selectedItems: NodeType.EZCDevice[]) => any;
    onCancel?: () => any;
    selectedItems?: NodeType.EZCDevice[];
}

export const EZCDevicePickerPanel: React.FC<JobTodoTemplatePickerProps> = ({
    onSubmit,
    onCancel,
    selectedItems = [],
}) => {
    const { connectionState, connectionData } = useQueryEzcDeviceConnection();
    const tableDef: ConnectionTableDef<NodeType.EZCDevice> = [
        {
            header: 'Title',
            sortKey: 'title',
            cell: (item) => <span>{item.title}</span>,
        },
        {
            header: 'Is Managed',
            cell: (item) => {
                if (!item.link) {
                    return null;
                }
                return (
                    <div>
                        {item.link && <Icon name={'check square'} />}
                        {item.link?.owner && (
                            <>
                                <Icon name={'user'} />
                                <Display.Entity value={item.link.owner} />
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            header: 'Owner',
            cell: (item) => {
                if (!item.link) {
                    return null;
                }
                return <div>{item.link?.owner && <Display.Entity value={item.link.owner} />}</div>;
            },
        },
    ];

    return (
        <TablePickerPanel
            panelHeader={{ content: 'All Available Devices' }}
            onCancel={onCancel}
            onSubmit={onSubmit}
            multiSelect={false}
            // hideItems={selectedItems}
            connectionState={connectionState}
            connectionData={connectionData}
            tableDef={tableDef}
        />
    );
};

export const FormikEZCDevicePicker: React.FC<{ name: string }> = ({ name }) => {
    const [field, meta, helpers] = useField<NodeType.EZCDevice>({ name: name });
    const modalCtrl = useModalCtrl();

    const onProductSelect = (product: NodeType.EZCDevice[]) => {
        helpers.setValue(product?.[0]);
        modalCtrl.onClose();
    };

    return (
        <FormField>
            <FormikFormFieldLabel label={'Device'} name={name} required={true} />
            <InputWithAction
                actionLabel={'Select'}
                value={field.value?.title || '--'}
                isClearable={!!field.value}
                onClear={() => helpers.setValue(null)}
                onClick={modalCtrl.onOpen}
                required={true}
            />
            <Modal {...modalCtrl}>
                <EZCDevicePickerPanel onCancel={modalCtrl.onClose} onSubmit={onProductSelect} />
            </Modal>
        </FormField>
    );
};
