import * as React from 'react';
import { useMemo } from 'react';
import {
    ConnectionErrorMessage,
    FormikDatePickerInputField,
    FormikDefaultForm,
    FormikInputField,
    PageSkeletonLoader,
    toastError,
} from '@ez/components';
import {
    useQueryInvoiceDefaultPayment,
    useQueryInvoiceDefaultSender,
} from '../queries/use-query-invoice-default-settings';
import { InvoiceDoc, InvoiceStatus } from '../constants';
import { FormikInvoicePaymentMethod, FormikInvoiceSender } from './form-field-components';
import {
    formatInvSequence,
    getInvoicePrefixKey,
    useMutateInvoiceIncrementSequence,
    useQueryResolveInvoiceSequence,
} from '../queries/use-query-invoice-sequence';
import { NodeType, useMutationAbstractDocument } from '@poolware/api';

export interface NewInvoiceFormProps {
    refetchQuery?: any;
    onCancel: () => any;
    onDidCreate: (doc: NodeType.AbstractDocument) => any;
    initialValues?: {
        serviceJobId?: NodeType.ID;
        customerId?: NodeType.ID;
        workOrderId?: NodeType.ID;
    };
}

export const FormNewInvoiceDoc: React.FC<NewInvoiceFormProps> = ({
    initialValues,
    refetchQuery,
    onDidCreate,
    ...rest
}) => {
    const todaysInvPrefixKey = useMemo(() => {
        return getInvoicePrefixKey();
    }, []);
    const defaultSenderRes = useQueryInvoiceDefaultSender();
    const defaultPaymentRes = useQueryInvoiceDefaultPayment();
    const seqRes = useQueryResolveInvoiceSequence({ key: todaysInvPrefixKey });
    const { create } = useMutationAbstractDocument({ refetchQueries: refetchQuery ? [refetchQuery] : undefined });
    const incrementSeq = useMutateInvoiceIncrementSequence();

    const loading = defaultSenderRes.loading || defaultPaymentRes.loading || seqRes.loading;
    const error = defaultSenderRes.error || defaultPaymentRes.error || seqRes.error;

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    }

    const _initialValue: Partial<InvoiceDoc.DocContent> = {
        issueDate: new Date().toISOString(),
        invoiceNumber: formatInvSequence(todaysInvPrefixKey, seqRes.data?.sequence),
        sender: defaultSenderRes.data,
        paymentOption: defaultPaymentRes.data,
    };

    const handleOnSubmit = async (values: InvoiceDoc.DocContent) => {
        try {
            const { data } = await create({
                typeTag: 'INVOICE',
                statusTag: InvoiceStatus.Draft,
                issuedAt: values.issueDate,
                refNumber: values.invoiceNumber,
                jsonBody: values,
                serviceJob: initialValues?.serviceJobId,
                workOrder: initialValues?.workOrderId,
                customer: initialValues?.customerId,
            });
            await incrementSeq({ key: todaysInvPrefixKey });
            await onDidCreate(data.AbstractDocument?.AbstractDocument);
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            initialValues={_initialValue}
            header={'New Invoice'}
            submitButton={{ content: 'Create...' }}
            onSubmit={handleOnSubmit}
            {...rest}
        >
            <FormikInputField label={'Ref Number'} name={'invoiceNumber'} />
            <FormikDatePickerInputField label={'Issue Date'} name={'issueDate'} />
            <FormikInvoiceSender />
            <FormikInvoicePaymentMethod />
        </FormikDefaultForm>
    );
};
