import * as React from 'react';
import { fromEdges } from '@poolware/api';
import { useViewer } from '@poolware/app-shell';
import { PageControlProps } from './Page.Control';
import { DataGraph } from './DataGraph';
import { Icon, Panel } from '@ez/components';
import { DataPoint, samplesToDataPoints } from '../components/graph-helpers';
import { SectionHeader, Segment, SemanticICONS } from '@ez/components';

export interface InputProps extends PageControlProps {}

interface GraphPanelProps {
    header: string;
    canSeeReportStats?: boolean;
    onDetails: () => any;
    dataPoints: DataPoint[];
}

const GraphPanel: React.FC<GraphPanelProps> = ({ canSeeReportStats, onDetails, dataPoints, header }) => {
    return (
        <Panel>
            <Panel.Header
                button={
                    canSeeReportStats && {
                        content: 'View report',
                        color: 'blue',
                        onClick: onDetails,
                    }
                }
            >
                {header}
            </Panel.Header>
            <Panel.Body>
                <DataGraph dataPoints={dataPoints} />
            </Panel.Body>
        </Panel>
    );
};

const StatsPanel: React.FC<{ icon: SemanticICONS; header: string }> = ({ header, icon, children }) => {
    return (
        <Segment className={'flex flex-row flex-nowrap'}>
            <div className={'w-16'}>
                <Icon name={icon} size={'big'} />
            </div>
            <div>
                <div className={'font-bold text-lg'}>{header}</div>
                <div>{children}</div>
            </div>
        </Segment>
    );
};

export const DashboardPage: React.FC<InputProps> = (props) => {
    const { modulesAccess } = useViewer();
    const { numOfDays, data } = props;
    if (!data) {
        return <div>Could not load data</div>;
    }
    const { newCustomers, newReports, newAppointments, franchise } = data;
    const canSeeReportStats = modulesAccess.Reports?.enabled;

    const nav = (path: string) => () => {
        props.AppNavigator.navigate(path, { relativeToModule: true });
    };

    return (
        <div>
            <SectionHeader>
                <Icon name="dashboard" />
                Overview for {franchise ? franchise.name : 'N/A'} (Last {numOfDays} days)
            </SectionHeader>
            {franchise && (
                <div className={'flex flex-row flex-wrap w-full gap-2 my-4'}>
                    {modulesAccess.Pools?.enabled && (
                        <div className={'flex-auto w-full md:w-1/3'}>
                            <StatsPanel icon="life ring" header="Total Pools Registered">
                                {franchise.poolCount ? franchise.poolCount.toString() : 'N/A'}
                            </StatsPanel>
                        </div>
                    )}
                    {modulesAccess.Customers?.enabled && (
                        <div className={'flex-auto w-full md:w-1/3'}>
                            <StatsPanel icon="user" header="Total Customers Registered">
                                {franchise.customerCount ? franchise.customerCount.toString() : 'N/A'}
                            </StatsPanel>
                        </div>
                    )}
                </div>
            )}
            <div className={'flex flex-row flex-wrap w-full gap-2'}>
                {modulesAccess.WaterTest?.enabled && (
                    <div className={'flex-auto w-full md:w-1/3'}>
                        <GraphPanel
                            header={'Water Tests'}
                            canSeeReportStats={canSeeReportStats}
                            dataPoints={samplesToDataPoints(fromEdges(newReports))}
                            onDetails={nav('/water-tests')}
                        />
                    </div>
                )}
                {modulesAccess.Customers?.enabled && (
                    <div className={'flex-auto w-full md:w-1/3'}>
                        <GraphPanel
                            header={'New Customers'}
                            canSeeReportStats={canSeeReportStats}
                            dataPoints={samplesToDataPoints(fromEdges(newCustomers))}
                            onDetails={nav('/new-customers')}
                        />
                    </div>
                )}
                {modulesAccess.FieldServices?.appointments && (
                    <div className={'flex-auto w-full md:w-1/3'}>
                        <GraphPanel
                            header={'Appointments Booked'}
                            canSeeReportStats={canSeeReportStats}
                            dataPoints={samplesToDataPoints(fromEdges(newAppointments))}
                            onDetails={nav('/appointments')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
