import * as React from 'react';
import { useMemo, useState } from 'react';
import { useQueryServiceJobConnection } from '../../../queries/use-query-service-job-connection';
import { NodeType } from '@poolware/api';
import { useViewerContext } from '@poolware/app-shell';
import { Icon, ConnectionTableDef, DefaultConnectionTable, Display, Panel, VStack } from '@ez/components';
import * as _ from 'lodash';
import { Checkbox } from '@ez/components';
import { ModuleColorNames, ModuleIconNames, ServiceJobModuleRouterID } from '../../../constants';
import { useAppNavigator } from '@poolware/react-app-navigator';

export interface UsedByServiceCasesListProps {
    serviceJobTemplate: NodeType.ServiceJobTemplate;
}

export const TemplateUsageReport: React.FC<UsedByServiceCasesListProps> = ({ serviceJobTemplate }) => {
    const [showOnlyRecur, setShowOnlyRecur] = useState(false);
    const { connectionData, connectionState } = useQueryServiceJobConnection({
        usedTemplate: serviceJobTemplate?.id,
        isRecurring: showOnlyRecur || null,
    });
    const { AppNavigator } = useAppNavigator();

    const onView = (serviceJob: NodeType.ServiceJob) => {
        AppNavigator.navigate(`/sj/${serviceJob.id}`, {
            relativeToModule: true,
            moduleId: ServiceJobModuleRouterID,
        });
    };

    const { isAdmin } = useViewerContext();

    const tableDef: ConnectionTableDef<NodeType.ServiceJob> = useMemo(
        () => [
            {
                header: 'ID',
                width: 1,
                cell: (serviceJob) => {
                    return <div>{serviceJob.serviceJobNumber}</div>;
                },
            },
            {
                header: 'Case',
                width: 5,
                cell: (serviceJob) => {
                    const isRecurring = serviceJob.isRecurring;
                    const title = _.truncate(serviceJob.title || '--', { length: 60 });
                    return (
                        <div>
                            <div
                                style={{
                                    marginBottom: '0.5em',
                                }}
                            >
                                {isRecurring && <Icon name={ModuleIconNames.RecurringService} />} {title}
                            </div>
                        </div>
                    );
                },
            },
            {
                header: 'Customer',
                width: 5,
                cell: (value) => {
                    const { customer, address } = value;
                    return (
                        <div>
                            {customer && (
                                <div>
                                    <Icon name={'user'} />
                                    <code style={{ color: '#2185d0' }}>
                                        <b>{customer.crn}</b>
                                    </code>{' '}
                                    - <Display.Entity value={customer} />
                                </div>
                            )}
                            {customer?.companyName && (
                                <div>
                                    <Icon name={'building'} />
                                    {customer.companyName}
                                </div>
                            )}
                        </div>
                    );
                },
            },
        ],
        [connectionData]
    );

    if (isAdmin) {
        tableDef.push({
            header: 'Franchise',
            cell: (sj) => {
                return sj?.entity?.franchise?.name;
            },
        });
    }

    const relatedServiceCasesCount = connectionState?.totalCount;

    return (
        <VStack>
            <Panel>
                <Panel.Body>
                    <Panel.Item label={'Used by '}>{relatedServiceCasesCount || '0'} Service Cases</Panel.Item>
                </Panel.Body>
            </Panel>
            <Checkbox
                checked={showOnlyRecur}
                onClick={(e, { checked }) => setShowOnlyRecur(checked)}
                label={'Show only recurring services'}
            />
            <DefaultConnectionTable
                onRowClick={onView}
                tableProps={{
                    color: ModuleColorNames.ServiceCall,
                }}
                tableCellProps={{
                    verticalAlign: 'top',
                    style: {
                        borderTop: '1px solid hsl(24, 50%, 85%)',
                    },
                }}
                // tableRowDef={tableRowProps}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </VStack>
    );
};
