import gql from 'graphql-tag';
import { DashboardPage } from './Page';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@ez/components';
import { NodeType } from '@poolware/api';
import { IWithPageProps, withPageProps } from '../../../components/withPageProps';

const QL = gql`
    query GetFranchiseActivity($last: Int!) {
        viewer {
            recentActivities {
                franchise {
                    id
                    name
                    customerCount
                    poolCount
                }
                newAppointments(last: $last) {
                    edges {
                        node {
                            count
                            date
                        }
                    }
                }
                newCustomers(last: $last) {
                    edges {
                        node {
                            count
                            date
                        }
                    }
                }
                newReports(last: $last) {
                    edges {
                        node {
                            count
                            date
                        }
                    }
                }
                newServiceJobs(last: $last) {
                    edges {
                        node {
                            count
                            date
                        }
                    }
                }
            }
        }
    }
`;

export default compose(
    withPageProps(),
    graphql(QL, {
        options: {
            variables: { last: 30 },
        },
    }),
    withApolloLoading(),
    mapProps((props) => {
        return {
            ...props,
            numOfDays: props.data.variables.last,
            data: props?.data?.viewer?.recentActivities,
        };
    })
)(DashboardPage);

export interface PageControlProps extends IWithPageProps {
    numOfDays: number;
    data: {
        franchise: {
            name: string;
            customerCount: number;
            poolCount: number;
        };
        newAppointments: NodeType.Edge<NodeType.ActivitySample>;
        newReports: NodeType.Edge<NodeType.ActivitySample>;
        newCustomers: NodeType.Edge<NodeType.ActivitySample>;
        newServiceJobs: NodeType.Edge<NodeType.ActivitySample>;
    };
}
