import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { FormikInputField } from '@ez/components';
import { FieldArray, getIn } from 'formik';
import { useQueryContactTypes } from './use-query-contact-types';
import { Button, ButtonWithDropdownMenu } from '@ez/components';

const ContactItemField: React.FC<{ onRemove; name; contact }> = (props) => {
    const { name, contact } = props;
    const n = (fieldName: string) => `${name}.${fieldName}`;
    return (
        <div className={'p-4 pb-0 rounded border bg-panel'}>
            <div className={'w-full flex flex-row gap-2 items-center'}>
                <FormikInputField name={n('data')} label={getIn(contact, 'type.name')} required={true} />
                <FormikInputField
                    label={'Label'}
                    name={n('label')}
                    value={contact.name}
                    placeholder={'e.g. Owner, Tenant'}
                />
                <Button
                    className={'px-2 flex-grow-0'}
                    onClick={props.onRemove}
                    variant={'tertiary'}
                    color={'red'}
                    size={'md'}
                    icon={'trash'}
                />
            </div>
        </div>
    );
};

export const FormikContactsInputFields: React.FC<{ name: string }> = (props) => {
    const { name } = props;

    const { data } = useQueryContactTypes();

    const types: NodeType.ContactType[] = fromEdges(data?.viewer?.contactTypes);

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                // const errors = form.errors[name];
                const contacts = getIn(form.values, name);

                const contactOptions = types.map((contactType) => ({
                    key: contactType.id,
                    content: contactType.name,
                    onClick: () => arrayHelpers.push({ type: contactType, data: '', label: '' }),
                }));

                return (
                    <div className={'grid gap-2'}>
                        {contacts &&
                            contacts.map((contact, index) => {
                                const n = (index) => `${name}[${index}]`;
                                return (
                                    <ContactItemField
                                        contact={contact}
                                        name={n(index)}
                                        key={index}
                                        onRemove={() => arrayHelpers.remove(index)}
                                    />
                                );
                            })}
                        <div>
                            <ButtonWithDropdownMenu
                                icon={'address book'}
                                content={'Add Contact'}
                                options={contactOptions}
                            />
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default FormikContactsInputFields;
