import { createThemeByHue, hsl } from '@ez/components';

export const getTheme = (publicPrefix) => {
    // Dracula >:-[
    // https://github.com/dracula

    const huePrim = 210;
    const hueSec = 60;
    const saturation = 14;
    const lum = 18;
    const theme = createThemeByHue({
        publicPrefix: publicPrefix,
        huePrim: huePrim,
        hueSec: 28,
        primSat: saturation,
        primLum: lum,
        isDark: true,
        customisation: {
            logoPath: `${publicPrefix}/logo.png`,
            themeName: 'poolware-admin-dark',
            color: {
                // primary: hsl(huePrim, saturation + 10, lum + 20),
            },
            border: {
                color: hsl(huePrim, saturation, lum - 3),
                colorTertiary: hsl(huePrim, saturation, 35),
            },
            panel: {
                borderColor: hsl(huePrim, saturation, lum - 1),
                item: {
                    label: {
                        color: hsl(huePrim, saturation, 95),
                    },
                },
                header: {
                    backgroundColor: hsl(huePrim, saturation, lum + 2),
                    color: hsl(huePrim, saturation, 95),
                },
                body: {
                    backgroundColor: hsl(huePrim, saturation, lum),
                    // color: hsl(huePrim, saturation, 90),
                },
                section: {
                    backgroundColor: hsl(huePrim, saturation, lum + 2),
                },
            },
            main: {
                // backgroundColor: hsl(huePrim, saturation, lum - 10),
                backgroundColorTertiary: hsl(huePrim, saturation, lum - 5),
                // backgroundColorAccent: hsl(huePrim, saturation, lum + 10),
                // backgroundColorNotes: hsl(huePrim, saturation + 10, lum + 4),
            },
            text: {
                size: {
                    base: '15px',
                },
                color: {
                    // base: hsl(huePrim, saturation, 70),
                    // primary: hsl(huePrim, saturation, 80),
                    // tertiary: hsl(huePrim, saturation - 10, 70),
                    // secondary: hsl(hueSec, saturation, 90),
                    // accentForeground: hsl(huePrim, 50, 90),
                    link: hsl(210, 100, 70),
                },
            },
            popover: {
                backgroundColor: hsl(huePrim, saturation, lum - 5),
                foregroundColor: hsl(huePrim, saturation, 90),
            },
            navbar: {
                backgroundColor: hsl(huePrim, saturation, lum),
                menuItem: {
                    backgroundColor: hsl(huePrim, saturation, lum + 1),
                    backgroundColorHover: hsl(huePrim, saturation, lum + 20),
                },
                logo: {
                    backgroundColor: hsl(huePrim, saturation, lum),
                },
            },
            menubar: {
                backgroundColor: hsl(huePrim, saturation, lum),
                menuItem: {
                    backgroundColor: hsl(huePrim, saturation, lum),
                    backgroundColorHover: hsl(huePrim, saturation, lum + 10),
                    backgroundColorActive: hsl(huePrim, saturation, lum - 20),
                },
            },
            sidebar: {
                backgroundColor: hsl(huePrim, saturation, lum),
                menuItem: {
                    backgroundColor: hsl(huePrim, saturation, lum),
                    backgroundColorHover: hsl(huePrim, saturation, lum + 35),
                    backgroundColorActive: hsl(huePrim, saturation, lum + 10),
                    textColor: hsl(huePrim, saturation, 90),
                    ascentColorActive: hsl(hueSec, saturation, 62),
                },
            },
            input: {
                color: hsl(huePrim, saturation, 90),
                backgroundColor: hsl(huePrim, saturation, lum + 20),
                border: hsl(huePrim, saturation, 60),
                focus: {
                    border: hsl(huePrim, saturation, 60),
                },
            },
            calendar: {
                grid: {
                    borderColor: hsl(huePrim, saturation, 30),
                    workingHours: {
                        backgroundColor: hsl(huePrim, saturation, lum - 5),
                        borderColor: hsl(huePrim, saturation, lum),
                    },
                    nonWorkingHours: {
                        backgroundColor: hsl(huePrim, saturation, lum - 10),
                        borderColor: hsl(huePrim, saturation, lum),
                    },
                },
            },
        },
    });
    return theme;
};
