import * as React from 'react';
import styled from 'styled-components';
import { QueryConnectionResult } from './types';
import { Button } from '../buttons';

export interface PaginationButtonsProps<T extends any = any> extends QueryConnectionResult<T> {}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const ConnectionPaginationButtons: React.FC<PaginationButtonsProps> = (props) => {
    const { connectionState } = props;
    if (!connectionState) {
        return null;
    }
    const { pageCount, pageIndex, loading, pageInfo, pageMeta } = connectionState;
    if (pageCount == 0 || pageCount == 1) {
        return null;
    }

    const showPageCount = pageCount !== undefined;
    const isCursorBased =
        pageMeta == undefined && pageInfo !== undefined && (pageInfo.startCursor || pageInfo.endCursor);

    let hasNextPage: boolean;
    let hasPrevPage: boolean;

    if (isCursorBased) {
        // Cursor based pagination
        hasNextPage = Boolean(pageInfo?.hasNextPage);
        hasPrevPage = Boolean(pageInfo?.hasPreviousPage);
    } else {
        // Page Index (offset) based pagination
        hasNextPage = pageInfo?.hasNextPage || pageIndex + 1 < pageCount;
        hasPrevPage = pageInfo?.hasPreviousPage || pageIndex > 0;
    }

    if (pageIndex === 0 && !hasNextPage) {
        return null;
    }

    const handleNext = () => {
        if (!isCursorBased) {
            // Use page index method
            connectionState?.setPageIndex(connectionState?.pageIndex + 1);
            connectionState?.setAfter(null);
            connectionState?.setBefore(null);
        } else {
            // Use Cursor method
            connectionState?.setPageIndex(null);
            connectionState?.setAfter(connectionState?.pageInfo?.endCursor);
            connectionState?.setBefore(null);
        }
    };

    const handlePrev = () => {
        if (!isCursorBased) {
            // Use page index (offset) method
            connectionState?.setPageIndex(connectionState?.pageIndex - 1);
            connectionState?.setAfter(null);
            connectionState?.setBefore(null);
        } else {
            // Use cursor based pagination
            connectionState?.setPageIndex(null);
            connectionState?.setAfter(null);
            connectionState?.setBefore(connectionState?.pageInfo?.startCursor);
        }
    };

    return (
        <Container>
            <Button
                variant={'secondary'}
                // size="tiny"
                content="Prev."
                onClick={handlePrev}
                iconLeft="chevron left"
                disabled={!hasPrevPage || loading}
                loading={loading}
            />
            {!isCursorBased && (
                <div>
                    {pageIndex + 1} {showPageCount && <span> / {pageCount}</span>}
                </div>
            )}
            <div>
                <Button
                    variant={'secondary'}
                    floated={'right'}
                    content="Next"
                    iconRight="right chevron"
                    onClick={handleNext}
                    disabled={!hasNextPage || loading}
                    loading={loading}
                />
            </div>
        </Container>
    );
};
