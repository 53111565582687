import * as React from 'react';
import { useContext } from 'react';
import { VStack } from '../Stack';
import tw, { styled } from 'twin.macro';
import { FullScreen } from '../FullScreen';
import { PageHeader, PageHeaderSub } from '../PageHeader';
import { ThreeColumnManaged } from './ThreeColumnManaged';
import { SideBarLayout } from './SideBarLayout';
import { cva, VariantProps } from 'class-variance-authority';
import { clsxm } from '../../utils';

export type PageLayoutCtxType = {
    width?: PageLayoutWidthType;
};
const PageLayoutCtx = React.createContext<PageLayoutCtxType>({ width: 'screen-wide' });

export const usePageLayoutContext = () => useContext(PageLayoutCtx);

export const PageLayoutTwoColumns = styled.div<{ mobileColReverse?: boolean }>(({ mobileColReverse }) => [
    tw`flex flex-col gap-4 pb-4 lg:grid lg:grid-cols-2`,
    mobileColReverse && tw`flex-col-reverse`,
]);

export type PageLayoutWidthType =
    | 'full'
    | 'screen-sm'
    | 'screen-md'
    | 'screen-lg'
    | 'screen-xl'
    | 'screen-2xl'
    | 'screen-wide';

const widthVariants = {
    full: 'max-w-screen-wide',
    'screen-sm': 'max-w-screen-sm',
    'screen-md': 'max-w-screen-md',
    'screen-lg': 'max-w-screen-lg',
    'screen-xl': 'max-w-screen-xl',
    'screen-2xl': 'max-w-screen-2xl',
    'screen-wide': 'max-w-screen-wide',
};

const pageWrapperVariants = cva('w-full mx-auto mb-10 max-w-[140rem]', {
    variants: {
        width: widthVariants,
    },
});

export interface PageWrapperProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof pageWrapperVariants> {}

export const PageWrapper: React.FC<PageWrapperProps> = ({ width, children, className, ...rest }) => {
    return (
        <div className={clsxm(pageWrapperVariants({ width: width }), className)} {...rest}>
            {children}
        </div>
    );
};

const bodySectionVariants = cva('w-full mx-auto px-2', {
    variants: {
        width: widthVariants,
        vStack: {
            true: 'flex flex-col gap-4',
        },
    },
});

export interface BodySectionProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof bodySectionVariants> {}

export const BodySection: React.FC<BodySectionProps> = ({ width, vStack, children, className, ...rest }) => {
    const ctx = usePageLayoutContext();
    const w = width || ctx?.width;
    return (
        <div className={clsxm(bodySectionVariants({ width: w, vStack: vStack }), className)} {...rest}>
            {children}
        </div>
    );
};

export interface PageLayoutProps extends PageWrapperProps {
    sectionWidth?: PageLayoutWidthType;
}

export class PageLayout extends React.Component<PageLayoutProps> {
    public static FullScreen = FullScreen;
    public static SideBarLayout = SideBarLayout;
    public static ThreeColumnManaged = ThreeColumnManaged;
    public static TwoColumns = PageLayoutTwoColumns;
    public static PageHeader = PageHeader;
    public static PageHeaderSub = PageHeaderSub;
    public static BodySection = BodySection;

    private static defaultProps = {
        width: 'screen-wide',
        sectionWidth: 'full',
    };

    constructor(props: PageLayoutProps) {
        super(props);
    }

    render() {
        const ctxValue = { width: this.props.sectionWidth || this.props.width };

        return (
            <PageLayoutCtx.Provider value={ctxValue}>
                <PageWrapper
                    data-testid={'PageLayout.PageWrapper'}
                    className={this.props.className}
                    width={this.props.width}
                >
                    <VStack>{this.props.children}</VStack>
                </PageWrapper>
            </PageLayoutCtx.Provider>
        );
    }
}
