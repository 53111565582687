import * as React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { Menu, MenuItemProps, Popup, PopupProps, Portal } from 'semantic-ui-react';

import { ConfirmModal, ConfirmProps } from '../ConfirmModal';
import styled from 'styled-components';
import { ButtonWithPopup, ButtonWithPopupProps } from './ButtonWithPopup';
import { Icon, SemanticICONS } from '../Icon';

export interface MenuItemWithConfirmProps extends MenuItemProps {
    confirm?: ConfirmProps;
    onCancel?: () => any;
    // Default lock reason is "No Permission"
    // Provide string value to set custom lock reason.
    locked?: boolean | string;
}

export interface MenuButtonItemConf extends MenuItemWithConfirmProps {}

export const MenuItemWithConfirm: React.FC<MenuItemWithConfirmProps> = (props) => {
    const { confirm, onClick, onCancel, ...rest } = props;

    if (!confirm) {
        return <Menu.Item {...rest} onClick={onClick} />;
    }

    const trigger = <Menu.Item {...rest} />;
    return <ConfirmModal onCancel={onCancel} onClick={onClick} confirm={confirm} trigger={trigger} />;
};

interface MenuItemsRendererProps {
    menuItems: MenuButtonItemConf[];
    onClick: (item: MenuItemProps) => any;
}

class MenuItemsRenderer extends React.Component<MenuItemsRendererProps> {
    render() {
        const { menuItems } = this.props;

        return (
            <Menu vertical secondary>
                {menuItems.map((item, index) => {
                    if (!item) {
                        return null;
                    }
                    const { locked, disabled } = item;
                    let _icon = item.icon;
                    if (!!locked) {
                        _icon = <Icon name={'lock'} />;
                    }
                    return (
                        <MenuItemWithConfirm
                            key={index}
                            confirm={item.confirm}
                            tabIndex={0}
                            role="button"
                            name={item.name}
                            icon={_icon}
                            disabled={disabled || !!locked}
                            onClick={async () => {
                                await this.props.onClick?.(item);
                            }}
                        />
                    );
                })}
            </Menu>
        );
    }
}

export interface MenuButtonProps extends ButtonWithPopupProps {
    onMenuClick?: (menuItem) => any;
    menu?: any;
    menuItems?: MenuButtonItemConf[];
    menuPosition?: PopupProps['position'];
    // icon?: SemanticICONS;
    as?: React.ReactNode;
}

export const PopperBox = styled.div`
    width: 15rem;
    background-color: #ffffff;
    //border: 1px solid #7a7a7a;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin: 1em;
    padding: 0.5em;
    z-index: 1900;
    top: 10px;
`;

export class MenuButton extends React.Component<MenuButtonProps> {
    public static defaultProps = {
        content: null,
        icon: 'ellipsis horizontal',
        color: null,
        menuPosition: 'bottom right',
    };

    state = { isOpen: false };

    handleOpen = () => {
        this.setState({ isOpen: true });
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    handleClick = async (menuItem) => {
        this.handleClose();
        // console.log(menuItem);
        if (menuItem.onClick) {
            await menuItem.onClick();
        } else {
            await this.props.onMenuClick?.(menuItem);
        }
    };

    render() {
        let {
            // useCustomerPopper,
            menu: M,
            menuItems,
            menuPosition,
            color,
            icon,
            content,
            loading,
            onMenuClick,
            as,
            ...rest
        } = this.props;

        const MenuComp = menuItems ? (
            <MenuItemsRenderer menuItems={menuItems} onClick={this.handleClick} />
        ) : (
            <M onClick={this.handleClick} />
        );

        let _icon;
        if (typeof icon === 'string') {
            _icon = <Icon loading={loading} name={icon as SemanticICONS} />;
        } else {
            _icon = icon;
        }

        const button = as || (
            <ButtonWithPopup variant={'secondary'} size="sm" color={color} onClick={this.handleOpen} {...rest}>
                {_icon}
                {content}
            </ButtonWithPopup>
        );

        // Check whether any of the menu items has `confirm` filed.
        const hasConfirm = false; // menuItems.reduce((acc, i) => acc || !!i.confirm, false);

        if (!hasConfirm) {
            return (
                <Popup
                    className={'p-1!'}
                    on="click"
                    open={this.state.isOpen}
                    position={menuPosition}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    trigger={button}
                    flowing
                >
                    {MenuComp}
                </Popup>
            );
        } else {
            // This is a bug workaround.
            // Semantics `Popup` component does some magic and the `MenuItemWithConfirm` stops working.
            return (
                <Manager>
                    <Reference>{({ ref }) => <div ref={ref}>{button}</div>}</Reference>
                    <Portal onClose={this.handleClose} open={this.state.isOpen}>
                        {/*{this.state.isOpen &&*/}
                        <Popper placement="bottom">
                            {({ ref, style, placement, arrowProps }) => (
                                <PopperBox ref={ref} style={style as any} data-placement={placement}>
                                    {MenuComp}
                                </PopperBox>
                            )}
                        </Popper>
                        {/*}*/}
                    </Portal>
                </Manager>
            );
        }
    }
}
