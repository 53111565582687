import * as React from 'react';
import {
    ModuleNavLink,
    ModuleRedirect,
    ModuleRoot,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import { MenuBar, MenuBarHeaderItem, PageLayout, Segment, VStack } from '@ez/components';
import { InvoiceSettingsDefaultSender } from './InvoiceSettingsDefaultSender';
import { InvoiceSettingsDefaultPayment } from './InvoiceSettingsDefaultPayment';

export interface InvoiceSettingsProps {}

export const InvoiceSettingsPage: React.FC<InvoiceSettingsProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    return (
        <ModuleRoot>
            <VStack>
                <MenuBar.Root>
                    <MenuBar.Section>
                        <MenuBar.Item
                            icon={'chevron left'}
                            title={'Back'}
                            onClick={() => AppNavigator.navigateToOrigin('/', { relativeToModule: true })}
                        />
                        <MenuBarHeaderItem icon={'puzzle'}>Invoice Setting</MenuBarHeaderItem>
                    </MenuBar.Section>
                </MenuBar.Root>
                <PageLayout.ThreeColumnManaged
                    layoutColumns={3}
                    main={
                        <ModuleSwitch notFound={() => <div>Not found</div>}>
                            <ModuleRedirect path={'/'} exact to={'/payment'} />
                            <ModuleRoute path={'/payment'} component={InvoiceSettingsDefaultPayment} />
                            <ModuleRoute path={'/sender'} component={InvoiceSettingsDefaultSender} />
                        </ModuleSwitch>
                    }
                    leftSidebar={
                        <Segment className={'w-full flex flex-col gap-2'} color={'blue'}>
                            {/*TODO: style nav links*/}
                            <ModuleNavLink to={'/payment'}>Payment</ModuleNavLink>
                            <ModuleNavLink to={'/sender'}>Sender</ModuleNavLink>
                        </Segment>
                    }
                />
            </VStack>
        </ModuleRoot>
    );
};
