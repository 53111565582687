import * as React from 'react';
import * as URLBuilder from '../../url-builder';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { useCalendarActions } from '../../../redux';
import { useQueryAppointmentGroupsConnection } from '../../Queries/QueryAppointmentGroupsConnection';
import { AppointmentGroupFilter } from './components/AppointmentGroupFilter';
import { useViewer } from '@poolware/app-shell';
import { Modal, useModalCtrl } from '@ez/components';
import { CalendarGroupNewForm } from '../../CalendarGroupEditor';

const FilterByAppointmentGroup: React.FC = () => {
    const modalCtrl = useModalCtrl();
    const { viewer } = useViewer();
    const { AppNavigator } = useAppNavigator();
    const { CalendarAction, CalendarState } = useCalendarActions();
    const canEdit = viewer.Permissions?.AppointmentGroup?.update;
    const { excludeAppointmentGroupsIds = [], franchise } = CalendarState.filters;
    const { connectionData, refetchQuery } = useQueryAppointmentGroupsConnection({ franchiseId: franchise?.id });

    const onNewAppointmentGroup = () => {
        AppNavigator.navigate(URLBuilder.Scheduler.newAppointmentGroup, { setOrigin: true, modal: true });
    };

    const onEditAppointmentGroup = (ag: NodeType.AppointmentGroup) => {
        AppNavigator.navigate(URLBuilder.Scheduler.editAppointmentGroup(ag.id), {
            setOrigin: true,
            modal: false,
        });
    };

    const onNewAppointmentGroupDidCreate = (id: NodeType.ID) => {
        modalCtrl.onClose();
        AppNavigator.replace(URLBuilder.Scheduler.editAppointmentGroup(id));
    };

    return (
        <>
            <AppointmentGroupFilter
                canEdit={canEdit}
                onEditAppointmentGroup={onEditAppointmentGroup}
                onNewAppointmentGroup={modalCtrl.onOpen}
                appointmentGroups={connectionData}
                excludeIds={excludeAppointmentGroupsIds}
                onFilterChange={CalendarAction.setAppointmentGroupFilter}
            />
            {modalCtrl.open && (
                <Modal {...modalCtrl}>
                    <CalendarGroupNewForm
                        onCancel={modalCtrl.onClose}
                        onFinish={onNewAppointmentGroupDidCreate}
                        refetchQueries={[refetchQuery]}
                    />
                </Modal>
            )}
        </>
    );
};

export default FilterByAppointmentGroup;
