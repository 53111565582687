import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, clsxm, Display, Icon } from '@ez/components';
import { NodeType } from '@poolware/api';
import { Frame, SectionHeader } from './components';
import Lightbox from 'yet-another-react-lightbox';
import { FileAttachmentUploader } from '@poolware/app-shell';
import styled from 'styled-components';

export enum PhotoAttachmentType {
    BeforeService = 'BeforeService',
    AfterService = 'AfterService',
    Other = 'Other',
}

export const photoGroupTypeToLabel = {
    [PhotoAttachmentType.BeforeService]: 'Before service',
    [PhotoAttachmentType.AfterService]: 'After service',
    [PhotoAttachmentType.Other]: 'Photos',
};

const fileToPhoto = (file: NodeType.FileUpload) => {
    if (!file) return null;
    return {
        src: file.url,
        isImage: file.isImage,
        urlThumbnail: file.imageUrl + '?size=sm',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        file: file,
    };
};

export const PhotoItem: React.FC<{
    isSelected?: boolean;
    file?: NodeType.FileUpload;
    onClick?: () => void;
    mode?: 'view' | 'select';
}> = ({ file, onClick, isSelected, mode }) => {
    const image = fileToPhoto(file);
    return (
        <div className={'w-[100px] min-w-fit flex flex-col gap-2 relative'} onClick={onClick}>
            {mode === 'select' && (
                <div className={clsxm('absolute top-0 right-0', isSelected ? 'text-green' : 'text-white')}>
                    <Icon className={'shadow'} name={isSelected ? 'check circle' : 'circle outline'} />
                </div>
            )}
            <button
                className={clsxm(
                    'focusable size-[100px] rounded bg-tertiary grid items-center justify-center overflow-clip',
                    isSelected && 'border-2 p-1 border-green border-solid'
                )}
            >
                {file ? (
                    <img src={image.urlThumbnail} alt={''} className={'object-cover w-full h-full'} />
                ) : (
                    <div className={'text-tertiary text-center'}>No image</div>
                )}
            </button>
            <div className={'text-xs h-[20px]'}>
                <Display.Date value={file?.createdAt} />
            </div>
        </div>
    );
};

export const PhotoAttachmentGroup: React.FC<{
    label: React.ReactNode;
    files?: NodeType.FileUpload[];
    onFileDidUpload: (fileId: NodeType.ID) => Promise<any>;
    onDeleteFiles?: (filesToDelete: NodeType.FileUpload[]) => Promise<any>;
}> = ({ label, files, onFileDidUpload, onDeleteFiles }) => {
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [openIndex, setOpenIndex] = useState(-1);
    const [selectedFiles, setSelectedFiles] = useState<NodeType.FileUpload[]>([]);

    useEffect(() => {
        setSelectedFiles([]);
    }, [isSelecting]);

    const sectionWrapperClass =
        'bg-main p-2 flex flex-row flex-wrap gap-x-2 gap-y-4 w-full pb-4 items-start justify-start';

    const onFileClick = (file: NodeType.FileUpload, index: number) => {
        if (isSelecting) {
            if (selectedFiles.includes(file)) {
                return setSelectedFiles(selectedFiles.filter((f) => f !== file));
            } else {
                return setSelectedFiles([...selectedFiles, file]);
            }
        }
        setOpenIndex(index);
    };

    const handleOnDeleteFiles = async () => {
        if (!onDeleteFiles) {
            return;
        }
        if (!selectedFiles.length) {
            return;
        }
        await onDeleteFiles?.(selectedFiles);
        setSelectedFiles([]);
        setIsSelecting(false);
    };
    const slides = files?.map((file) => ({
        src: file.url,
    }));
    const hasSelection = selectedFiles.length > 0;

    const showEditButton = files?.length > 0;

    return (
        <>
            <Frame bordered={true} tertiary={true}>
                <SectionHeader className={'flex flex-row gap-2 w-full'}>
                    <span className={'flex-grow'}>{label}</span>
                    {showEditButton && (
                        <Button
                            size={'sm'}
                            variant={'tertiary'}
                            icon={'edit'}
                            onClick={() => setIsSelecting(!isSelecting)}
                        />
                    )}
                </SectionHeader>
                <div className={sectionWrapperClass}>
                    {files?.map((file, index) => {
                        const isSelected = selectedFiles.includes(file);
                        return (
                            <PhotoItem
                                key={file.id}
                                file={file}
                                onClick={() => onFileClick(file, index)}
                                isSelected={isSelected}
                                mode={isSelecting ? 'select' : 'view'}
                            />
                        );
                    })}
                    {!showPicker && !isSelecting && (
                        <Button
                            variant={'tertiary'}
                            icon={'plus'}
                            className={'!size-[100px] border rounded border-tertiary border-opacity-100'}
                            onClick={() => setShowPicker(true)}
                        />
                    )}
                </div>

                {isSelecting && (
                    <Button
                        size={'md'}
                        disabled={!hasSelection}
                        icon={'trash'}
                        onClick={handleOnDeleteFiles}
                        color={'orange'}
                    >
                        Delete Files
                    </Button>
                )}

                <Lightbox
                    index={openIndex}
                    open={openIndex >= 0}
                    close={() => {
                        setOpenIndex(-1);
                    }}
                    slides={slides}
                    // plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                />

                {showPicker && (
                    <FileAttachmentUploader
                        onFileUploadComplete={onFileDidUpload}
                        onDone={() => setShowPicker(false)}
                        dzOptions={{
                            multiple: true,
                            accept: 'image/*',
                        }}
                        onCancel={() => setShowPicker(false)}
                    />
                )}
            </Frame>
        </>
    );
};

const PhotoScrollableContainer = styled.div`
    scrollbar-color: var(--ez-color-primary, black) var(--ez-main-background-color-accent, lightblue);

    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--ez-color-primary, black);
    }

    &::-webkit-scrollbar-track {
        background: var(--ez-main-background-color-accent, lightblue);
    }
`;

export const PhotoAttachmentGroupHorizontal: React.FC<{
    files: NodeType.FileUpload[];
    label: string;
    onAddPhoto?: () => void;
}> = ({ files, label, onAddPhoto }) => {
    const slides = files?.map((file) => ({
        src: file.url,
    }));
    const [openIndex, setOpenIndex] = useState(-1);

    const sectionWrapperClass = 'bg-main flex flex-row flex-nowrap overflow-x-auto gap-x-2 w-full pb-2 items-start';
    return (
        <div>
            <div className={'flex flex-row justify-between'}>
                <strong className={'text-sm'}>{label}</strong>
            </div>
            <PhotoScrollableContainer className={sectionWrapperClass}>
                {files?.map((file, index) => (
                    <PhotoItem key={file.id} file={file} onClick={() => setOpenIndex(index)} />
                ))}
            </PhotoScrollableContainer>
            <Lightbox
                index={openIndex}
                open={openIndex >= 0}
                close={() => {
                    setOpenIndex(-1);
                }}
                slides={slides}
                // plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
            />
        </div>
    );
};
