import * as React from 'react';
import styled from 'styled-components';
import { Display, Icon, Popup } from '@ez/components';
import moment from 'moment';

export const DisplayDateStyled = styled(Display.Date)`
    font-size: 0.9em;
    color: ${(props) => props.color};
`;

const WarningSignIcon = () => <Icon name={'warning sign'} color={'red'} />;

const OverdueWarning = () => (
    <React.Fragment>
        <WarningSignIcon /> Overdue
    </React.Fragment>
);

export const DueDate: React.FC<{ dueDate: Date; format?: string; warnIfOverdue?: boolean }> = ({
    dueDate,
    format,
    warnIfOverdue = true,
}) => {
    const isOverdue = moment().isAfter(moment(dueDate).endOf('d')) && warnIfOverdue;

    const C = (
        <span>
            {isOverdue && <WarningSignIcon />}
            <DisplayDateStyled value={dueDate} format={format || 'LL'} color={isOverdue ? 'red' : undefined} />
        </span>
    );

    return isOverdue ? (
        <Popup position={'top center'} trigger={C}>
            <Popup.Content>
                <OverdueWarning />
            </Popup.Content>
        </Popup>
    ) : (
        C
    );
};
