import styled from 'styled-components';
import * as React from 'react';
import { Icon, IconProps, SemanticICONS } from '@ez/components';

const IconButtonContainer = styled.button`
    border: none;
    cursor: pointer;
    outline: none;
    color: #c2c1c1;
    background-color: transparent;

    :disabled {
        color: #8c8c8c;
        opacity: 0.4;
    }
`;

export const IconButton: React.FC<{ onClick; icon?: SemanticICONS; hidden?: boolean } & IconProps> = ({
    onClick,
    icon,
    disabled,
    hidden,
    children,
    style,
    ...props
}) => {
    return (
        <IconButtonContainer disabled={disabled} onClick={onClick} style={style} hidden={hidden}>
            <Icon name={icon} disabled={disabled} {...props} />
            {children}
        </IconButtonContainer>
    );
};
