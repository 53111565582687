import { Display, PrintTable as Table } from '@ez/components';
import * as React from 'react';
import { IconServiceJobStage, mapServiceJobStageTypeToName } from '../../../constants';
import { fromEdges, NodeType } from '@poolware/api';
import { Icon } from '@ez/components';

export const WorkOrdersList: React.FC<{ serviceJob?: NodeType.ServiceJob }> = ({ serviceJob }) => {
    const workOrders = fromEdges(serviceJob?.workOrders);
    const workOrdersCount = workOrders.length;
    return (
        <Table.Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Subject</Table.HeaderCell>
                    <Table.HeaderCell>Assigned To</Table.HeaderCell>
                    <Table.HeaderCell>Appointment</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {workOrders.map((workOrder) => {
                    return (
                        <Table.Row key={workOrder.id}>
                            <Table.Cell>
                                {workOrder.workOrderNumber}
                                <br />
                            </Table.Cell>
                            <Table.Cell>
                                <span style={{ display: 'inline-flex' }}>
                                    <IconServiceJobStage stage={workOrder?.stage} />;
                                    {mapServiceJobStageTypeToName(workOrder.stage?.type)}
                                </span>
                            </Table.Cell>
                            <Table.Cell>{workOrder.title}</Table.Cell>
                            <Table.Cell>
                                <Display.Entity value={workOrder.assignedTo} />
                            </Table.Cell>
                            <Table.Cell>
                                {workOrder.appointmentItem && (
                                    <>
                                        <Icon name={'calendar alternate outline'} />
                                        <Display.Date value={workOrder?.appointmentItem?.startDate} format={'lll'} />
                                    </>
                                )}
                            </Table.Cell>
                            <Table.Cell>{workOrder.description}</Table.Cell>
                        </Table.Row>
                    );
                })}
                {workOrdersCount === 0 && (
                    <Table.Row>
                        <Table.Cell colSpan={6}>--</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table.Table>
    );
};
