import styled from 'styled-components';
import {
    defaultMenuItemActiveBgColor,
    defaultSeparatorColor,
    defaultSidebarBgColor,
    defaultTextOnDarkColor,
    scrollbarBgColor,
    scrollbarFgColor,
    scrollbarWidth,
} from './default-constants';

const SidebarAside = styled.aside`
    display: block;
    background-color: var(--ez-sidebar-background-color, ${defaultSidebarBgColor});
    box-shadow: var(--ez-sidebar-shadow);
    font-size: 16px;
`;

const SidebarMenuContainer = styled.div`
    height: 100%;
    overflow-y: auto;

    // For Google Chrome

    &::-webkit-scrollbar {
        width: ${scrollbarWidth};
        height: ${scrollbarWidth};
    }

    &::-webkit-scrollbar-thumb {
        background: ${scrollbarFgColor};
    }

    &::-webkit-scrollbar-track {
        background: ${scrollbarBgColor};
    }

    // For Internet Explorer

    body {
        scrollbar-face-color: ${scrollbarFgColor};
        scrollbar-track-color: ${scrollbarBgColor};
    }
`;

const SidebarMenu = styled.div`
    display: flex;
    flex-direction: column;
    border: none;
    color: #fdfdfa;
    width: 100%;
    flex: 0 0 auto;
    overflow-x: hidden;
`;

const SidebarMenuSpacer = styled.div`
    //flex: 1 1 2rem;
    //background-color: brown;
    height: 60px;
`;

const SidebarMenuBottom = styled(SidebarMenu)`
    flex: 0 0 auto;
    background-color: pink;
    justify-content: flex-end;
`;

const SidebarLogoContainer = styled.div`
    padding: 0.5rem;
    height: var(--topBar, 46px);
    display: flex;
    flex-direction: column;
    background-color: var(--ez-navbar-logo-background-color, ${defaultSidebarBgColor});
    border-bottom: 2px solid var(--ez-navbar-separator-color, ${defaultSeparatorColor});
    color: var(--ez-navbar-logo-text-color, ${defaultTextOnDarkColor});

    & #nav-logo-icon {
        margin: 0 auto 0.5rem auto;
        max-width: 3rem;
        max-height: 3rem;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    #nav-logo-image {
        padding: 0 1rem;
        margin-top: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
        max-height: var(--topBar, 46px);
        max-width: 170px;
    }
`;

const SidebarMenuGroup = styled.div`
    padding: 0.5rem 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    border-top: 1px solid var(--ez-sidebar-menu-group-separator-color, hsl(200 10 80));

    &:first-child {
        border-top: none !important;
    }
`;

const SidebarMenuGroupContent = styled.div``;

export const createSidebarMenuItem = (C) => {
    return styled(C)`
        height: var(--ez-sidebar-menu-item-height, 40px);
        padding: var(--ez-sidebar-menu-item-padding, 0 calc(0.5em - 2px));
        border-left: var(--ez-sidebar-menu-item-border-left, 0px solid transparent);
        border-right: var(--ez-sidebar-menu-item-border-right, 0px solid transparent);
        box-sizing: content-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;

        &:hover {
            color: var(--ez-sidebar-menu-item-content-text-color, ${defaultTextOnDarkColor});
        }

        &.active {
            border-left-color: var(--ez-sidebar-menu-item-ascent-color-active);

            .menu-item-content {
                border-right-width: 4px;
                border-right-style: solid;
                border-right-color: var(--ez-sidebar-menu-item-content-ascent-color, ${defaultSeparatorColor});
                border-left-color: var(--ez-sidebar-menu-item-content-ascent-color, ${defaultSeparatorColor});
                background-color: var(
                    --ez-sidebar-menu-item-content-background-color-active,
                    ${defaultMenuItemActiveBgColor}
                );
                color: var(--ez-sidebar-menu-item-text-color, ${defaultTextOnDarkColor});
            }

            .menu-item-label {
                font-weight: bold;
            }
        }

        &.minimised {
            .menu-item-content {
                justify-content: center;
            }
        }

        .menu-item-content {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: flex-start;
            align-items: center;
            border-right: var(--ez-sidebar-menu-item-content-border-right);
            border-left: var(--ez-sidebar-menu-item-content-border-left);
            border-radius: var(--ez-sidebar-menu-item-content-border-radius, var(--ez-border-radius-default, 0.25rem));
            cursor: pointer;
            padding: var(--ez-sidebar-menu-item-content-padding, 0.5em);
            font-weight: normal;

            color: var(--ez-sidebar-menu-item-text-color, ${defaultTextOnDarkColor});
            @media (pointer: fine) {
                &:hover {
                    background-color: var(
                        --ez-sidebar-menu-item-content-background-color-hover,
                        ${defaultMenuItemActiveBgColor}
                    );
                }
            }

            @media (pointer: coarse) {
                &:hover {
                    background-color: var(--ez-sidebar-menu-item-content-background-color-hover, hsl(0 10 20));
                }
            }

            // Labels are displayed in one line. Prevent from wrapping.
            white-space: nowrap;

            .menu-item-label {
                font-size: 14px;
            }
        }

        .menu-item-main-icon {
            width: 1em;
            height: 1em;
            line-height: 1em;
            margin: 0.25em;
            //padding: 0.25em;
        }
    `;
};

const SidebarMenuHeader = styled.div`
    color: var(--ez-sidebar-menu-item-text-color, ${defaultTextOnDarkColor});
    margin: 0;
    padding-left: 1rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.1em;
`;

export const Sidebar = {
    Aside: SidebarAside,
    LogoContainer: SidebarLogoContainer,
    MenuContainer: SidebarMenuContainer,
    Menu: SidebarMenu,
    MenuSpacer: SidebarMenuSpacer,
    MenuBottom: SidebarMenuBottom,
    MenuHeader: SidebarMenuHeader,
    MenuGroup: SidebarMenuGroup,
    MenuGroupContent: SidebarMenuGroupContent,
    createMenuItem: createSidebarMenuItem,
};
