import * as React from 'react';

import { Button } from '@ez/components';

interface PaginationButtonsProps {
    isLoadingPage?: boolean;
    setPage: (page: number) => void;
    pageCount: number;
    currentPage: number;
}

export default class PaginationButtons extends React.PureComponent<PaginationButtonsProps> {
    render() {
        const { isLoadingPage, currentPage, pageCount, setPage } = this.props;

        if (pageCount < 2) {
            return null;
        }
        const hasNextPage = currentPage + 1 < pageCount;

        return (
            <div className={'flex flex-row justify-between items-center py-2 w-full'}>
                <Button
                    size="sm"
                    variant={'tertiary'}
                    content="Prev."
                    onClick={() => setPage(currentPage - 1)}
                    iconLeft="chevron left"
                    disabled={currentPage === 0 || isLoadingPage}
                    loading={isLoadingPage}
                />

                <div className={'text-sm'}>
                    Page: {currentPage + 1} / {pageCount}
                </div>

                <Button
                    content="Next"
                    variant={'tertiary'}
                    size={'sm'}
                    iconRight="chevron right"
                    onClick={() => setPage(currentPage + 1)}
                    disabled={!hasNextPage || isLoadingPage}
                    loading={isLoadingPage}
                />
            </div>
        );
    }
}
