import * as React from 'react';
import { ActionBar, IconButton, SemanticICONS } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { usePersistedToggle } from '@ez/tools';

export interface OptionableL1<V1, T = any> {
    text: string;
    value: V1;
    type?: T;
    icon?: React.ReactNode | SemanticICONS;
}

export interface OptionableL2<V1 = any, V2 = any, T = any> extends OptionableL1<V1, T> {
    options?: OptionableL1<V2, T>[];
}

export interface OptionableL3<V1 = any, V2 = any, V3 = any, T = string> extends OptionableL2<V1, V2, T> {
    options?: OptionableL2<V2, V3, T>[];
}

export type OptionableL3OnChangeFn<V1 = any, V2 = V1, V3 = V1, T = string> = (
    optionL1: OptionableL3<V1, V2, T> | null,
    optionL2: OptionableL2<V2, V3, T> | null,
    optionL3: OptionableL1<V3, T> | null
) => any;

export interface OptionableComponentL3<V1, V2, V3, T = any> {
    value: V1 | V2 | V3 | null;
    onChange: OptionableL3OnChangeFn<V1, V2, V3, T>;
    options: OptionableL3<V1, V2, V3, T>[];
    valueComparator?: (value: V1 | V2 | V3, option: OptionableL1<V1 | V2 | V3, T>) => boolean;
}

// ***********

export enum JobOptionType {
    Group = 'group',
    Template = 'template',
}

export function mapServiceJobGroupsToOptions<
    V1 = NodeType.ServiceJobGroup,
    V2 = NodeType.ServiceJobGroup | NodeType.ServiceJobTemplate,
    V3 = NodeType.ServiceJobTemplate,
    T = JobOptionType
>(
    groups?: NodeType.ServiceJobGroup[],
    opts?: {
        hideJobTemplatesL2?: boolean;
        hideJobTemplatesL3?: boolean;
    }
): OptionableL3<V1, V2, V3, T>[] {
    const mapTemplateToOption = (template: NodeType.ServiceJobTemplate) => ({
        type: JobOptionType.Template,
        value: template,
        text: template.templateTitle,
        icon: 'suitcase',
    });

    return groups?.map<OptionableL3<V1, V2, V3, T>>((group) => {
        const o2subGroups = fromEdges(group.children).map((group) => {
            let o3 = !opts?.hideJobTemplatesL3 ? fromEdges(group.templates).map(mapTemplateToOption) : [];
            return {
                type: JobOptionType.Group,
                value: group,
                text: group.title,
                icon: 'folder outline',
                options: o3,
            };
        });

        const o2templates = !opts?.hideJobTemplatesL2 ? fromEdges(group.templates).map(mapTemplateToOption) : [];

        const o1 = {
            type: JobOptionType.Group,
            value: group,
            icon: 'folder outline',
            text: group.title,
            options: [...o2subGroups, ...o2templates],
        };

        // todo fix types
        return o1 as any;
    });
}

export interface ServiceJobGroupsMenuProps<V1 = any, V2 = any, V3 = any>
    extends OptionableComponentL3<V1, V2, V3, JobOptionType> {
    headerTitle: string | React.ReactNode;
    maxHeight?: number;
    onEditGroups?: () => void;
    collapsable?: boolean;
    persistStorageKey?: string;
}

export const ActionBarServiceGroups: React.FC<ServiceJobGroupsMenuProps> = ({
    onChange,
    value = null,
    options,
    headerTitle,
    maxHeight = 500,
    onEditGroups,
    collapsable = true,
    persistStorageKey = 'service-group.filter.toggle',
    valueComparator = (v, o) => {
        return v === o?.value?.id;
    },
}) => {
    const singleSelect = true;
    const [expanded, setExpanded] = usePersistedToggle(persistStorageKey, true);

    const handleOnChangeL1 = (optionL1: OptionableL3 | null, checked: boolean) => {
        if (checked) {
            onChange(optionL1, null, null);
        } else {
            onChange(null, null, null);
        }
    };

    const handleOnChangeL2 = (optionL1: OptionableL3 | null, optionL2: OptionableL2, checked: boolean) => {
        if (checked) {
            onChange(optionL1, optionL2, null);
        } else {
            onChange(optionL1, null, null);
        }
    };

    const handleOnChangeL3 = (
        optionL1: OptionableL3 | null,
        optionL2: OptionableL2 | null,
        optionL3: OptionableL2 | null,
        checked: boolean
    ) => {
        if (checked) {
            onChange(optionL1, optionL2, optionL3);
        } else {
            onChange(optionL1, null, null);
        }
    };

    const onShowAll = (checked: boolean) => {
        if (checked) {
            onChange(null, null, null);
        }
    };

    const actionButtons = (
        <>
            {!!onEditGroups && <IconButton icon={'cog'} color={'grey'} onClick={() => onEditGroups?.()} />}
            {collapsable && (
                <IconButton
                    icon={expanded ? 'chevron down' : 'chevron right'}
                    color={'grey'}
                    size={'xs'}
                    onClick={() => setExpanded(!expanded)}
                />
            )}
        </>
    );

    const isAllChecked = !value;
    const isExpanded = collapsable ? expanded : true;
    return (
        <ActionBar active={!isAllChecked}>
            <ActionBar.Header content={headerTitle} icon={'filter'} actionComponent={actionButtons} />
            {isExpanded && (
                <ActionBar.Body>
                    <ActionBar.ItemCheckbox
                        dividing={true}
                        checked={isAllChecked}
                        onChange={(checked) => onShowAll(checked)}
                        content={'All'}
                        highlightChecked={false}
                    />
                    <ActionBar.Scroll maxHeight={maxHeight}>
                        {options.map((o1, i) => {
                            const subOptions = o1.options;
                            const isO1Selected = valueComparator(value, o1); // value === o1?.value?.id;
                            return (
                                <div className={'text-sm'} key={i}>
                                    <ActionBar.ItemCheckbox
                                        highlightChecked={true}
                                        radio={singleSelect}
                                        checked={isO1Selected}
                                        onChange={(checked) => handleOnChangeL1(o1, checked)}
                                        icon={o1.icon}
                                        content={o1.text}
                                    />
                                    {subOptions?.map((o2, n) => {
                                        const subSubOptions = o2.options;
                                        const isO2Selected = valueComparator(value, o2); // value === o2?.value?.id;
                                        const isO3ChildSelected =
                                            subSubOptions?.findIndex((sso) => {
                                                return valueComparator(value, sso);
                                            }) !== -1;
                                        const showL3 = isO2Selected || isO3ChildSelected;
                                        // console.log({subSubOptions, value, isO3ChildSelected});
                                        return (
                                            <React.Fragment key={n}>
                                                <ActionBar.ItemCheckbox
                                                    highlightChecked={true}
                                                    level={'second'}
                                                    radio={singleSelect}
                                                    checked={isO2Selected}
                                                    onChange={(checked) => handleOnChangeL2(o1, o2, checked)}
                                                    key={n}
                                                    icon={o2.icon}
                                                    content={o2.text}
                                                />
                                                {showL3 &&
                                                    subSubOptions?.map((o3, m) => {
                                                        const isO3Selected = valueComparator(value, o3);
                                                        return (
                                                            <div className={'text-sm pl-4'} key={m}>
                                                                <ActionBar.ItemCheckbox
                                                                    highlightChecked={true}
                                                                    level={'second'}
                                                                    radio={singleSelect}
                                                                    checked={isO3Selected}
                                                                    onChange={(checked) =>
                                                                        handleOnChangeL3(o1, o2, o3, checked)
                                                                    }
                                                                    icon={o3.icon}
                                                                    content={o3.text}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </ActionBar.Scroll>
                </ActionBar.Body>
            )}
        </ActionBar>
    );
};
