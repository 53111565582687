import { registerLocale, setDefaultLocale } from '@ez/react-datepicker';

import { Locale } from 'date-fns';
import { enAU } from 'date-fns/locale';

// Force Monday as first day for AU locale
const localeAU: Locale = { ...enAU, options: { ...enAU.options, weekStartsOn: 1 } };
const localeName = 'en-AU';
registerLocale(localeName, localeAU);
setDefaultLocale(localeName);
