import * as React from 'react';
import { ConnectionPaginationButtons, PageLayout } from '@ez/components';
import { useViewer } from '@poolware/app-shell';
import { DeviceCard } from './DeviceCard';
import { DebugPageBreakTester } from './debug-page-break-tester';
import { LayoutViewProps } from '../types';

export interface PageListProps extends LayoutViewProps {}

export const LayoutCards: React.FC<PageListProps> = ({ onView, connectionData, connectionState, isLoadingShadow }) => {
    const { isAdmin } = useViewer();

    return (
        <PageLayout.BodySection>
            {false && <DebugPageBreakTester />}

            <div
                className={
                    'p-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-8 gap-4'
                }
            >
                {connectionData.map((device) => {
                    return (
                        <DeviceCard
                            key={device.id}
                            onView={() => onView(device)}
                            device={device}
                            admin={isAdmin}
                            isLoadingShadow={isLoadingShadow}
                        />
                    );
                })}
            </div>
            <div className={'p-1 mt-4'}>
                <ConnectionPaginationButtons connectionState={connectionState} connectionData={connectionData} />
            </div>
            {/*<DebugJSON data={{ loading, deviceStates, error }} />*/}
        </PageLayout.BodySection>
    );
};
