import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Manager, Reference, Popper } from "react-popper";

// export const popperPlacementPositions = placements;

export default class PopperComponent extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        wrapperClassName: PropTypes.string,
        hidePopper: PropTypes.bool,
        popperComponent: PropTypes.element,
        popperModifiers: PropTypes.array, // <datepicker/> props
        popperPlacement: PropTypes.any,
        popperContainer: PropTypes.func,
        popperProps: PropTypes.object,
        targetComponent: PropTypes.element
    };

    static get defaultProps() {
        return {
            hidePopper: true,
            popperModifiers: [
            //     {
            //     name: 'preventOverflow',
            //     options: {
            //         padding: 8,
            //     },
            // }
            ],
            popperProps: {},
            popperPlacement: "bottom-start"
        }
    }

    render() {
        const {
            className,
            wrapperClassName,
            hidePopper,
            popperComponent,
            popperModifiers,
            popperPlacement,
            popperProps,
            targetComponent
        } = this.props;

        let popper;

        if (!hidePopper) {
            const classes = classnames("react-datepicker-popper", className);
            popper = (
                <Popper
                    modifiers={popperModifiers}
                    placement={popperPlacement}
                    {...popperProps}
                >
                    {({ref, style, placement, arrowProps}) => (
                        <div
                            {...{ref, style}}
                            className={classes}
                            data-placement={placement}
                        >
                            {React.cloneElement(popperComponent, {arrowProps})}
                        </div>
                    )}
                </Popper>
            );
        }

        if (this.props.popperContainer) {
            popper = React.createElement(this.props.popperContainer, {}, popper);
        }

        const wrapperClasses = classnames(
            "react-datepicker-wrapper",
            wrapperClassName
        );

        return (
            <Manager>
                <Reference>
                    {({ref}) => (
                        <div ref={ref} className={wrapperClasses}>
                            {targetComponent}
                        </div>
                    )}
                </Reference>
                {popper}
            </Manager>
        );
    }
}
