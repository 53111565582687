import * as React from 'react';
import { CalendarEventMode, CalendarEventType } from '../types';
import { Display, Icon, stringFormatters } from '@ez/components';
import { Link } from 'react-router-dom';
import * as URLBuilder from '../../url-builder';
import * as _ from 'lodash';
import { ModuleIconNames } from '../../../constants';
import { css, styled } from 'twin.macro';

const eventTextColor = '#202020';
const eventTextColorInverted = '#f9ffde';

const CalendarEventStyled = styled.div(() => [
    css`
        padding: 1px;
        display: flex;
        height: 100%;

        line-height: 1em;

        flex-direction: column;
        position: relative;

        &.popover {
            width: auto;
        }

        &.horizontal {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;
        }

        // default text color
        color: ${eventTextColor};

        * > a {
            color: ${eventTextColor};
        }

        &.dimmed {
            color: #404040;
        }

        &.highlighted {
            color: #000000;
        }

        // text color for black background (e.g. when in popover)

        &.inverted {
            color: ${eventTextColorInverted};

            * > a {
                color: ${eventTextColorInverted};
            }
        }

        .pw-item {
            font-size: 0.7em;
            padding: 2px;

            &.pw-time {
                //margin-top: -4px;
            }

            &.pw-customer {
                & > a {
                    //color: #202020;
                }

                text-decoration: underline;
            }

            &.pw-staff {
            }

            &.pw-address {
            }

            &.pw-pool {
                .pw-pool-name {
                }
            }

            .pw-note {
                background-color: ivory;
                border: 1px solid #bfbfbf;
                padding: 2px;
                margin: 0;
                border-radius: 2px;
                color: black;

                p {
                    line-height: 1em;
                    font-size: 0.9em;
                }

                max-height: 140px;
                overflow: auto;

                &.preview {
                    background-color: rgba(0, 0, 0, 0.1);
                    border: unset;
                    margin-top: 2px;
                    padding-left: 4px;
                    padding-right: 4px;
                    //border-left: 1px solid white;
                    color: ${eventTextColor};
                }
            }

            &-icons {
                color: #2a63a1;
                position: absolute;
                background-color: rgba(255, 255, 255, 0.25);
                border-radius: 3px;
                right: 2px;
                top: 2px;
                padding: 0.125em 0.25em 0.5em 0.5em;
                overflow: visible;
                display: flex;
                flex-direction: row;
                gap: 4px;
                text-align: center;

                & i.icon {
                    text-align: center;
                    width: 0.75em;
                }
            }
        }
    `,
]);

export const CalendarEventPopoverContent: React.FC<{ event: CalendarEventType }> = React.memo(({ event }) => {
    const { item, mode } = event;
    if (mode !== CalendarEventMode.APPOINTMENT_ITEM) return null;

    const { startDate, customer, isRecurring, staff, address, pool, duration, note, serviceJob, workOrder } = item;

    const hasServiceCase = (!!serviceJob && !serviceJob?.isImplicit) || !!workOrder;
    const groupTitle = serviceJob?.group?.title;
    const serviceCaseTitle = serviceJob?.title;
    const woTitle = workOrder?.title;

    return (
        <CalendarEventStyled className={'inverted'}>
            <div className={'pw-item'}>
                <Icon name={isRecurring ? 'refresh' : 'clock'} />
                <Display.DateRange startDate={startDate} duration={duration} />
            </div>

            {customer && (
                <div className={'pw-item pw-customer'}>
                    <Icon name={'user'} />
                    <Link to={URLBuilder.Customer(customer.id).view}>
                        {stringFormatters.formatEntityName(customer, '')}
                    </Link>
                </div>
            )}

            {staff && (
                <div className={'pw-item pw-staff'}>
                    <Icon name={'spy'} />
                    {stringFormatters.formatEntityName(staff, '')}
                </div>
            )}

            {address && (
                <div className={'pw-item pw-address'}>
                    <Icon name={'marker'} />
                    {stringFormatters.formatAddress(address)}
                </div>
            )}

            {pool && (
                <>
                    {!address && (
                        <div className={'pw-item pw-pool'}>
                            <Icon name={'marker'} /> {stringFormatters.formatAddress(pool.address)}
                        </div>
                    )}
                    <div className={'pw-item pw-pool'}>
                        <Icon name={'life ring'} />{' '}
                        <span className={'pw-pool-name'}>
                            {_.truncate(pool.name, { length: 50 })} - {pool.volume && `${pool.volume} L`}
                        </span>
                    </div>
                </>
            )}

            {hasServiceCase && (
                <>
                    <div className={'my-2'} />
                    {groupTitle && (
                        <div className={'pw-item'}>
                            <Icon name={ModuleIconNames.ServiceGroup} />
                            <span className={'pl-1'}>{groupTitle}</span>
                        </div>
                    )}
                    {serviceCaseTitle && (
                        <div className={'pw-item'}>
                            <Icon name={ModuleIconNames.ServiceCase} />
                            <span className={'pl-1 '}>{serviceCaseTitle}</span>
                        </div>
                    )}
                    {woTitle && (
                        <div className={'pw-item'}>
                            <Icon name={ModuleIconNames.WorkOrder} />
                            <span className={'pl-1 '}>{woTitle}</span>
                        </div>
                    )}
                </>
            )}

            {note && (
                <div className={'pw-item'}>
                    <Display.Text value={note} className={'pw-note'} />
                </div>
            )}
        </CalendarEventStyled>
    );
});
