import React from 'react';
import clsx from 'clsx';
import { CalNavigate, CalViewMode } from './types';
import { RBCContext } from './CalendarContext';

export interface CalToolbarProps {
    date: Date;
    localizer: any;
    view: CalViewMode;
    onNavigate: (action: string, newDate?: Date) => void;
    onView: (view: CalViewMode) => void;
    label?: React.ReactNode;
    views: (CalViewMode | string)[];
}

class Toolbar extends React.Component<CalToolbarProps> {
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    render() {
        let { label } = this.props;
        const {
            localizer: { messages },
        } = this.context;

        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    <button type="button" onClick={this.navigate.bind(null, CalNavigate.TODAY)}>
                        {messages.today}
                    </button>
                    <button type="button" onClick={this.navigate.bind(null, CalNavigate.PREVIOUS)}>
                        {messages.previous}
                    </button>
                    <button type="button" onClick={this.navigate.bind(null, CalNavigate.NEXT)}>
                        {messages.next}
                    </button>
                </span>

                <span className="rbc-toolbar-label">{label}</span>

                <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
            </div>
        );
    }

    navigate = (action) => {
        this.props.onNavigate(action);
    };

    view = (view) => {
        this.props.onView(view);
    };

    viewNamesGroup(messages) {
        let viewNames = this.props.views;
        const view = this.props.view;

        if (viewNames.length > 1) {
            return viewNames.map((name) => (
                <button
                    type="button"
                    key={name}
                    className={clsx({ 'rbc-active': view === name })}
                    onClick={this.view.bind(null, name)}
                >
                    {messages[name]}
                </button>
            ));
        }
    }
}

export default Toolbar;
