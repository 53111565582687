import { useAppNavigator } from '@poolware/react-app-navigator';
import { default as React, useMemo } from 'react';
import {
    ConnectionErrorMessage,
    Display,
    FormikDefaultForm,
    FormSectionBody,
    FormSectionHeader,
    PageSkeletonLoader,
    Panel,
    toastError,
} from '@ez/components';
import { useWorkOrderMutators } from '../../queries/mutators-work-order';
import { useQueryServiceJob } from '../../queries/use-query-service-job';
import { Icon, PageLayout } from '@ez/components';
import { getIn } from 'formik';
import { CustomerLink, ServiceJobLink } from '../../Links';
import { NodeType } from '@poolware/api';
import { ModuleIconNames } from '../../constants';
import {
    FormikCustomerAndLocationInputFields,
    FormikSuggestInputStaff,
    NotFoundPage,
    useViewer,
} from '@poolware/app-shell';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { validationSchemaWorkOrderItem } from './WorkOrderItemFormFields/FormikInputJobTodo';
import {
    FormikFragmentInitialStateWorkOrderItem,
    FormikFragmentWorkOrderJobPayload,
    NewOrderType,
} from './FormikFragmentWorkOrderJobPayload';

const validationSchema = Yup.object({
    assignedTo: Yup.object().notRequired().nullable(),
    jobPayload: Yup.object({
        type: Yup.string().required('Required').nullable(),
        adHocWorkOrder: Yup.object().when('type', {
            is: NewOrderType.NEW,
            then: validationSchemaWorkOrderItem,
            otherwise: Yup.object().nullable().notRequired(),
        }),
    }),
});

const defaultItem = {
    orderIndex: 0,
    title: 'Job Complete',
};

export const WorkOrderNew: React.FC = () => {
    const { AppNavigator, query } = useAppNavigator();
    const { modulesAccess } = useViewer();
    const workOrderMutators = useWorkOrderMutators({ refetchQueries: ['QueryServiceJob'] });

    const useServiceCaseTemplates = modulesAccess.FieldServices?.caseTemplates;

    const sjId = query.sjId;
    if (!sjId) {
        throw new Error('Expected sjId query');
    }

    const { loading, node: serviceJob, error } = useQueryServiceJob(sjId);

    const options = useMemo(() => {
        const options = [];
        if (useServiceCaseTemplates) {
            options.push({
                key: NewOrderType.SERVICE_JOB_TEMPLATE,
                value: NewOrderType.SERVICE_JOB_TEMPLATE,
                text: 'From Template',
            });
        }
        options.push({
            key: NewOrderType.NEW,
            value: NewOrderType.NEW,
            text: 'Ad-Hoc',
        });

        return options;
    }, [useServiceCaseTemplates]);

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !serviceJob) {
        return <NotFoundPage />;
    }

    const defaultType = useServiceCaseTemplates ? NewOrderType.SERVICE_JOB_TEMPLATE : NewOrderType.NEW;

    let initialValues = {
        customer: serviceJob.customer,
        pool: serviceJob.pool,
        address: serviceJob.address,
        showCustomerAsPreview: true,
        assignedTo: undefined,
        jobPayload: {
            type: defaultType as NewOrderType,
            jobTodoTemplate: {
                template: null as NodeType.JobTodoTemplate,
            },
            serviceJobTemplate: {
                sjTemplate: null as NodeType.ServiceJobTemplate,
                woTemplates: null as NodeType.WorkOrderTemplate[],
            },
            adHocWorkOrder: {
                ...FormikFragmentInitialStateWorkOrderItem,
                title: serviceJob?.title,
                description: serviceJob?.description,
            },
        },
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };

    const prepareWoConfig = (values: typeof initialValues, wot?: NodeType.WorkOrderTemplate) => {
        // Single work order template exist in ServiceJob template
        const woConfig: NodeType.CreateWorkOrderMutationInput = {
            serviceJob: serviceJob.id,
            workFor: values?.customer?.id,
            address: values?.address?.id,
            pool: values?.pool?.id,
            assignedTo: values?.assignedTo?.id,
        };
        if (wot) {
            woConfig.template = wot?.id;
            woConfig.autoInflateTemplate = true;
        }
        return woConfig;
    };

    const onCreateFromServiceJobTemplate = async (values: typeof initialValues) => {
        const orderType = values?.jobPayload?.type;
        if (orderType !== NewOrderType.SERVICE_JOB_TEMPLATE) {
            throw new Error('Wrong order type, expected TEMPLATE');
        }
        const serviceJobTemplate = values.jobPayload?.serviceJobTemplate?.sjTemplate;
        const woTemplates = values.jobPayload?.serviceJobTemplate?.woTemplates;

        if (!serviceJobTemplate && !woTemplates) {
            // Template is not provided. Create an empty work order
            let woConfig = prepareWoConfig(values, null);
            woConfig.title = serviceJob?.title; // copy service case's title
            const res = await workOrderMutators.create(woConfig);
            const id = res?.data?.WorkOrder?.WorkOrder?.id;
            AppNavigator.replace(`/wo/${id}`, { relativeToModule: true });
            return;
        }

        if (woTemplates.length > 1) {
            // Multiple work orders exist in template
            const woConfigs = woTemplates.map((wot) => {
                return prepareWoConfig(values, wot);
            });
            await workOrderMutators.create(woConfigs);
            // Navigate back to Service case page
            AppNavigator.replaceToOrigin(`/sj/${serviceJob.id}`, { relativeToModule: true });
        } else if (woTemplates.length === 1) {
            // Single work order template exist in ServiceJob template
            // Special case.
            let woConfig = prepareWoConfig(values, woTemplates[0]);
            const res = await workOrderMutators.create(woConfig);
            const id = res?.data?.WorkOrder?.WorkOrder?.id;
            AppNavigator.replace(`/wo/${id}`, { relativeToModule: true });
        } else {
            // No work order templates available.
            let woConfig = prepareWoConfig(values, null);
            woConfig.title = serviceJob?.title; // copy service case's title
            const res = await workOrderMutators.create(woConfig);
            const id = res?.data?.WorkOrder?.WorkOrder?.id;
            AppNavigator.replace(`/wo/${id}`, { relativeToModule: true });
        }
    };

    const onCreateAdHoc = async (values: typeof initialValues) => {
        const orderType = values?.jobPayload?.type;
        if (orderType !== NewOrderType.NEW) {
            throw new Error('Wrong order type, expected NEW');
        }

        let conf = prepareWoConfig(values);
        conf.title = serviceJob?.title; // copy default title

        const { adHocWorkOrder } = values.jobPayload;
        if (!adHocWorkOrder) {
            console.error('workOrderItems is not defined');
            return;
        }

        // DUPE: See WorkOrderItemNew.tsx file.
        const items: NodeType.CreateJobTodoListJobTodoItemInput[] = (adHocWorkOrder?.todoItems || [])
            .map((item, index) => ({
                title: item.title,
                orderIndex: index,
            }))
            .filter((i) => !!i.title); // remove items with empty titles

        if (items.length === 0) {
            items.push(defaultItem);
        }

        conf.title = _.trim(adHocWorkOrder.title);
        conf.jobs = [
            {
                title: adHocWorkOrder.title,
                description: adHocWorkOrder.description,
                items: items,
            },
        ];

        const res = await workOrderMutators.create(conf);
        const woId = res?.data?.WorkOrder?.WorkOrder?.id;
        AppNavigator.replace(`/wo/${woId}`, { relativeToModule: true });
    };

    const onCreate = async (values: typeof initialValues) => {
        const orderType = values?.jobPayload?.type;

        try {
            switch (orderType) {
                case NewOrderType.NEW:
                    return onCreateAdHoc(values);
                case NewOrderType.SERVICE_JOB_TEMPLATE:
                    return onCreateFromServiceJobTemplate(values);
                default:
                    throw new Error('orderType is unknown');
            }
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <PageLayout width={'screen-md'}>
            <FormikDefaultForm
                header={
                    <>
                        <Icon name={ModuleIconNames.WorkOrder} />
                        New Work Order
                    </>
                }
                submitOnEnter={false}
                debug={true}
                initialValues={initialValues}
                onSubmit={onCreate}
                onCancel={onCancel}
                submitButton={{ content: 'Next' }}
                validationSchema={validationSchema}
            >
                {(formikBag) => {
                    const customer = getIn(formikBag.values, 'customer');
                    const pool = getIn(formikBag.values, 'pool');
                    const address = getIn(formikBag.values, 'address');
                    const showCustomerAsPreview = getIn(formikBag.values, 'showCustomerAsPreview');
                    const customerAndLocationPresent = (customer || pool || address) && showCustomerAsPreview;

                    const onClearCustomer = () => {
                        formikBag.setFieldValue('showCustomerAsPreview', false);
                        formikBag.setFieldValue('customer', null);
                        formikBag.setFieldValue('pool', null);
                        formikBag.setFieldValue('address', null);
                    };

                    return (
                        <>
                            <FormSectionHeader>Parent Service Case</FormSectionHeader>
                            <FormSectionBody>
                                <Panel.Item label={'Service Case ID'}>
                                    <ServiceJobLink id={serviceJob.id}>
                                        {serviceJob.serviceJobNumber} <Icon name={'external'} />
                                    </ServiceJobLink>
                                </Panel.Item>
                                <Panel.Item label={'Subject'} content={serviceJob.title} />
                                <Panel.ItemDate label={'Opened'} content={serviceJob.createdAt} />
                                {serviceJob.dueDate && (
                                    <Panel.ItemDate label={'Due Date'} content={serviceJob.dueDate} />
                                )}

                                {serviceJob.description && false && (
                                    <Panel.ItemText
                                        label={'Case Description'}
                                        content={_.truncate(serviceJob.description, { length: 200 })}
                                        maxHeightPx={320}
                                    />
                                )}
                            </FormSectionBody>

                            <FormSectionHeader
                                button={
                                    customerAndLocationPresent && {
                                        icon: 'close',
                                        content: 'Change',
                                        onClick: onClearCustomer,
                                    }
                                }
                            >
                                Customer / Location
                            </FormSectionHeader>
                            <FormSectionBody>
                                {customerAndLocationPresent ? (
                                    <>
                                        {customer && (
                                            <Panel.Item label={'Customer'}>
                                                <CustomerLink id={customer.id}>
                                                    <Display.Entity value={customer} />
                                                </CustomerLink>
                                            </Panel.Item>
                                        )}
                                        {pool && <Panel.Item label={'Pool'}>{pool.name}</Panel.Item>}
                                        {address && (
                                            <Panel.Item label={'Address'}>
                                                <Display.Address value={address} showMapLink={true} />
                                            </Panel.Item>
                                        )}
                                    </>
                                ) : (
                                    <FormikCustomerAndLocationInputFields />
                                )}
                            </FormSectionBody>

                            <FormSectionHeader>Staff</FormSectionHeader>
                            <FormSectionBody>
                                <FormikSuggestInputStaff label={'Assigned To'} name={'assignedTo'} />
                            </FormSectionBody>

                            <FormSectionHeader>Work Order Job</FormSectionHeader>
                            <FormSectionBody>
                                <FormikFragmentWorkOrderJobPayload name={'jobPayload'} options={options} />
                            </FormSectionBody>
                        </>
                    );
                }}
            </FormikDefaultForm>
        </PageLayout>
    );
};
