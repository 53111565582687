import * as React from 'react';
import { Button } from '@ez/components';
import { useCalendarActions } from '../../../redux';

const ClearAllSection: React.FC = () => {
    const { CalendarState, CalendarAction } = useCalendarActions();

    const clearAll = () => {
        CalendarAction.clearAllFilters();
    };

    const { hasActiveFilters } = CalendarState.filters;
    return (
        <Button
            className={'w-full'}
            disabled={!hasActiveFilters}
            icon={'close'}
            color={hasActiveFilters ? 'purple' : 'grey'}
            onClick={clearAll}
            content={'Clear all filters'}
        />
    );
};

export default ClearAllSection;
