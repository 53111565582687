import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Display, Icon, TableLineLoader } from '@ez/components';
import { OnlineIcon } from '../common';
import { EZCDevicesLinkWithState } from '../use-query-ezc-device-states';
import { DeviceStateType, LifeGuardShadowType } from '../../View/TabDeviceState/types';

const CardHeader: React.FC<{
    deviceLink: NodeType.EZCDeviceLink;
    deviceState: NodeType.EZCDeviceState;
    isLoadingShadow?: boolean;
}> = ({ deviceLink, deviceState, isLoadingShadow }) => {
    const state: DeviceStateType<LifeGuardShadowType> = deviceState?.state?.lifeguard || {};
    return (
        <div className={'bg-accent p-2 flex flex-row justify-between mb-2 rounded-t flex-nowrap gap-2'}>
            <div className={'text-ellipsis text-nowrap overflow-hidden'}>{deviceLink.device?.title}</div>
            <OnlineIcon loading={isLoadingShadow} state={state} />
        </div>
    );
};

const DeviceStateValueItem: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => {
    return (
        <div className={'flex flex-row gap-1 items-center flex-nowrap'}>
            <div className={'w-2/3 text-right text-secondary'}>{label}</div>
            <div className={'font-bold w-2/3 text-center bg-tertiary rounded px-1'}>{value}</div>
        </div>
    );
};

export const DeviceState: React.FC<{ deviceState?: NodeType.EZCDeviceState; stateLoading?: boolean }> = ({
    deviceState,
    stateLoading,
}) => {
    const state: DeviceStateType<LifeGuardShadowType> = deviceState?.state?.lifeguard || {};

    if (stateLoading) {
        return (
            <div className={'h-full bg-panel-section p-2 rounded flex flex-col gap-1'}>
                <TableLineLoader />
                <TableLineLoader />
                <TableLineLoader />
            </div>
        );
    }

    const stateReported = state.reported;
    if (!stateReported) return null;

    return (
        <div className={'h-full bg-panel-section p-1 gap-x-2 gap-y-1 rounded grid grid-cols-2'}>
            <DeviceStateValueItem label={'pH'} value={stateReported?.phSensorValue || '--'} />
            <DeviceStateValueItem label={'ORP'} value={stateReported?.orpSensorValue || '--'} />
            <DeviceStateValueItem label={'Temp'} value={stateReported?.temperatureSensorValue || '--'} />
        </div>
    );
};

export const DeviceCard: React.FC<{
    device: EZCDevicesLinkWithState;
    onView?: () => void;
    admin?: boolean;
    isLoadingShadow?: boolean;
}> = ({ device, isLoadingShadow, admin, onView }) => {
    const address = device.pool?.address || device.owner?.primaryAddress;
    const deviceState = device.deviceState;
    return (
        <div
            className={'h-64 w-full rounded bg-panel hover:cursor-pointer hover:shadow-lg flex flex-col'}
            onClick={onView}
        >
            <CardHeader deviceLink={device} deviceState={deviceState} isLoadingShadow={isLoadingShadow} />
            <div className={'flex-1 text-sm flex-col flex gap-1 p-2'}>
                <div className={'text-ellipsis text-nowrap overflow-hidden'}>
                    <Icon name={'user'} /> {device.owner ? <Display.Entity value={device.owner} /> : '--'}
                </div>

                <div className={'text-ellipsis text-nowrap overflow-hidden'}>
                    <Icon name={'map marker'} />
                    {address ? <Display.Address value={address} /> : '--'}
                </div>

                {device.pool && (
                    <div className={'text-ellipsis text-nowrap overflow-hidden'}>
                        <Icon name={'life ring'} /> {device.pool?.name} ({device.pool?.volume} L)
                    </div>
                )}

                {admin && <div>Franchise: {device.franchise?.name}</div>}
            </div>
            <div className={'flex-1 flex-grow p-2'}>
                <DeviceState deviceState={deviceState} stateLoading={isLoadingShadow} />
            </div>
        </div>
    );
};
