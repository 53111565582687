import * as React from 'react';
import { Form, MenuBar, MenuBarHeaderItem, Segment, VStack } from '@ez/components';
import {
    FilterByBrand,
    FilterByProductCompany,
    FilterByProductName,
    FilterBySku,
    FilterByTraitDeclaration_Tree,
} from '../../../components-redux-connected';

const SearchSideBar: React.FC = () => {
    return (
        <Form>
            <VStack>
                <div>
                    <MenuBar.Root className={'rounded-b-none'}>
                        <MenuBar.HeaderItem icon={'search'}>Search</MenuBar.HeaderItem>
                    </MenuBar.Root>
                    <div className={'rounded rounded-t-none bg-panel p-2 border'}>
                        <FilterByProductName />
                        <FilterBySku />
                        <FilterByBrand label={'Brand'} />
                        <FilterByProductCompany label={'Product Company'} />
                    </div>
                </div>

                <FilterByTraitDeclaration_Tree header={'Search By Category'} />
            </VStack>
        </Form>
    );
};

export default SearchSideBar;
