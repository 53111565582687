import * as React from 'react';
import { ButtonWithPopup, ButtonWithPopupProps } from './ButtonWithPopup';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../radix';
import { Icon } from '../Icon';

export interface DropdownMenuOptionType {
    key?: number | string;
    icon?: string;
    content?: React.ReactNode;
    value?: boolean | number | string;
    locked?: boolean | string;
    disabled?: boolean;
    onClick?:
        | ((option: Omit<DropdownMenuOptionType, 'onClick'>) => void | Promise<void> | undefined)
        | undefined
        | null;
}

export interface ButtonWithDropdownMenuProps extends ButtonWithPopupProps {
    options: DropdownMenuOptionType[];
}

export const ButtonWithDropdownMenu: React.FC<ButtonWithDropdownMenuProps> = ({ options, ...props }) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <ButtonWithPopup icon={'chevron down'} {...props} />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                {options.map((option, index) => {
                    return (
                        <DropdownMenuItem
                            key={option.key ?? index}
                            onClick={(e) => option.onClick?.(option)}
                            disabled={!!option.locked || option.disabled}
                            className={'gap-2'}
                        >
                            {option.locked && <Icon name={'lock'} />}
                            {option.icon && <Icon name={option.icon} />}
                            {option.content}
                        </DropdownMenuItem>
                    );
                })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
