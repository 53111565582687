import { graphql } from 'react-apollo';
import { compose, withState } from '@ez/tools';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { getMutationQueryByName } from './mutations-queries-codegen';

const isDevelopment = process.env.NODE_ENV === 'development';
const debug = isDevelopment ? console.log : noop;

export const createMutatorActions =
    ({ propName, actions }) =>
    ({ mutate }) => {
        actions = actions || [];

        return actions
            .map((action) => {
                return {
                    action: action,
                    function: (values) => {
                        debug(`Mutation: ${propName}.${action}`, values);
                        return mutate({
                            variables: { input: { [action]: values } },
                        });
                    },
                };
            })
            .reduce((acc, current) => {
                acc[current.action] = current.function;
                return acc;
            }, {});
    };

export const withMutator =
    ({ mutationQuery, propName, actions, refetchQueries, update }) =>
    (Page) => {
        let refetchQueriesCombined = undefined;

        if (refetchQueries) {
            if (isArray(refetchQueries)) {
                refetchQueriesCombined = refetchQueries;
            } else {
                refetchQueriesCombined = [refetchQueries];
            }
        } else {
            refetchQueriesCombined = [];
        }

        return compose(
            withState('isMutating', 'setMutating', false),
            graphql(mutationQuery, {
                props: (props: any) => {
                    const { mutate, ownProps } = props;
                    const { setMutating } = ownProps;
                    const m = async (...args) => {
                        try {
                            setMutating(true);
                            const res = await mutate(...args);
                            debug('Mutation Result:', res);
                            setMutating(false);
                            return res;
                        } catch (e) {
                            setMutating(false);
                            throw e;
                        }
                    };
                    return {
                        [propName]: createMutatorActions({ propName, actions })({ mutate: m }),
                    };
                },
                options: (props) => {
                    const rf = refetchQueriesCombined.map((rq) => {
                        if (isFunction(rq)) {
                            return rq(props);
                        } else {
                            return rq;
                        }
                    });
                    return {
                        refetchQueries: rf,
                        update: update,
                    };
                },
            })
        )(Page);
    };

export const withObjectMutator = (input: { objectName: string; actions: string[]; update? }) => {
    const { objectName, actions, update } = input;
    const defaultMutationQuery = getMutationQueryByName(objectName);
    const propName = `mutate${objectName}`;
    return (refetchQueries?, mutationQuery?) => {
        return withMutator({
            mutationQuery: mutationQuery || defaultMutationQuery,
            propName,
            actions,
            refetchQueries,
            update,
        });
    };
};
