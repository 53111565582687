import * as React from 'react';
import { NodeType, useMutationCustomer } from '@poolware/api';
import {
    Display,
    FormikDatePickerInputField,
    FormikDefaultForm,
    IconButton,
    Modal,
    Panel,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { differenceInYears, endOfDay, parseISO } from 'date-fns';

interface PanelCustomerNotesProps {
    customer: NodeType.Customer;
}

export interface PanelFormEditCustomerOfficerProps {
    customer: NodeType.Customer;
    onDone: () => any;
}

const today = endOfDay(new Date());

const FormEditDate: React.FC<PanelFormEditCustomerOfficerProps> = ({ customer, onDone }) => {
    const initialValues = {
        ...customer,
    };

    const { update } = useMutationCustomer({ refetchQueries: ['CustomerQuery'] });

    const onSubmit = async (values: typeof initialValues) => {
        try {
            await update({
                id: customer.id,
                registeredAt: values.registeredAt || null,
            });
            onDone();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            debug={false}
            header={'Registration Date'}
            initialValues={initialValues}
            submitButton={{ content: 'Submit' }}
            onSubmit={onSubmit}
            onCancel={onDone}
        >
            <FormikDatePickerInputField label={'Date'} name={'registeredAt'} maxDate={today} />
        </FormikDefaultForm>
    );
};

interface PanelCustomerRegoDateProps {
    registrationDate?: string;
}

type TierType = { min: number; max: number; name: string };
const tiers: TierType[] = [
    { min: 0, max: 5, name: 'Silver' },
    { min: 6, max: 10, name: 'Gold' },
    { min: 11, max: 20, name: 'Platinum' },
    { min: 21, max: 1000, name: 'Diamond' },
];

const Info = () => (
    <ul>
        <li>0-5 years - Silver Tier</li>
        <li>6-10 years - Gold Tier</li>
        <li>11 - 20 years - Platinum Tier</li>
        <li>20+ years - Diamond Tier</li>
    </ul>
);
const TierFormatter: React.FC<PanelCustomerRegoDateProps> = ({ registrationDate }) => {
    if (!registrationDate) return null;
    /*
        0-5 years = Silver Tier
        6-10 years = Gold Tier
        11 - 20 years = Platinum Tier
        20+ years = Diamond Tier
     */

    const left = endOfDay(new Date());
    const right = parseISO(registrationDate);
    const age = differenceInYears(left, right);
    const tier = tiers.find((t) => age >= t.min && age <= t.max);

    return (
        <div>
            {tier?.name || '--'} ({age} yrs)
        </div>
    );
};

export const PanelCustomerRegDate: React.FC<PanelCustomerNotesProps> = ({ customer }) => {
    const modalCtrl = useModalCtrl();
    const registeredAt = customer?.registeredAt;

    return (
        <>
            <Panel>
                <Panel.Body>
                    <Panel.Item label={'Date Joined'}>
                        <Display.Date value={registeredAt} format={'LL'} defaultString={'--'} />
                        <IconButton icon={'edit'} onClick={modalCtrl.onOpen} />
                    </Panel.Item>
                    {registeredAt && (
                        <Panel.Item label={'Tier'} labelInfoIcon={'info circle'} labelInfo={Info}>
                            <TierFormatter registrationDate={registeredAt} />
                        </Panel.Item>
                    )}
                </Panel.Body>
            </Panel>
            <Modal {...modalCtrl}>
                <FormEditDate customer={customer} onDone={modalCtrl.onClose} />
            </Modal>
        </>
    );
};
