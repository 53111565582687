import gql from 'graphql-tag';
import ApolloClient from 'apollo-client';
import * as _ from 'lodash';
import { toastError } from '@ez/components';

const vendSaleQuery = gql`
    query AppointmentItemVendDetailQuery($id: ID!) {
        appointmentItem: node(id: $id) {
            ... on AppointmentItem {
                startDate
                paymentUrl
                vendSale {
                    id
                    historyUrl
                    webRegistryUrl
                }
            }
        }
    }
`;

export const querySaleDetails = async (client: ApolloClient<any>, appointmentId): Promise<string | null> => {
    try {
        const res = await client.query({
            query: vendSaleQuery,
            fetchPolicy: 'no-cache',
            variables: { id: appointmentId },
        });
        return _.get(res, 'data.appointmentItem.vendSale.webRegistryUrl', null);
    } catch (e) {
        console.error(e);
        toastError({ title: 'Unable to create sale on VEND' });
    }
    return null;
};
