import * as React from 'react';
import { useAppConfig } from '@poolware/app-shell';
import { APIProvider } from '@vis.gl/react-google-maps';

export const MapProvider: React.FC = ({ children }) => {
    const config = useAppConfig();
    if (config.google_maps?.apiToken && config.google_maps?.enabled) {
        return <APIProvider apiKey={config.google_maps?.apiToken}>{children}</APIProvider>;
    } else {
        return <>{children}</>;
    }
};
