import * as React from 'react';
import { compose } from '@ez/tools';
import { withPageProps } from '../../../../components/withPageProps';
import { DeviceStatusPanel, WaterlinkConfigForm } from '@poolware/waterlink';
import { VStack } from '@ez/components';
import { Alert } from '@ez/components';

export const Page: React.FC<{}> = () => {
    return (
        <VStack>
            <WaterlinkConfigForm />
            <DeviceStatusPanel />
            <Alert type={'info'}>
                <p>
                    Waterlink integration requires <b>Waterlink Connect 2 (!)</b> software installed on your Windows
                    computer.
                </p>
                <p>
                    If you don't have it installed yet, please download{' '}
                    <a
                        href={'http://softwarecenter.lamotte.com/waterlinkconnect2.php'}
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        Waterlink Connect 2
                    </a>{' '}
                    from LaMotte Software Center (
                    <a href={'http://softwarecenter.lamotte.com/'} target={'_blank'} rel="noopener noreferrer">
                        http://softwarecenter.lamotte.com/
                    </a>
                    )
                </p>
            </Alert>
        </VStack>
    );
};

export default compose(withPageProps())(Page);
