import * as React from 'react';
import { StickyMenuContainer } from './StickyMenuContainer';
import { MenuBar } from './MenuBar';

export const StickyMenuBar: React.FC<{ topOffset?: number }> = ({ children, topOffset }) => {
    return (
        <StickyMenuContainer topOffset={topOffset}>
            <MenuBar.Root>{children}</MenuBar.Root>
        </StickyMenuContainer>
    );
};
