import * as React from 'react';
import Gallery from './Gallery';
import { Dimmer, Loader } from 'semantic-ui-react';

import { ButtonWithConfirm } from '../buttons';
import { HeaderButtonType, Panel } from '../panel';
import { Alert } from '../alert';

export type PhotoFileType = {
    id: string;
    note?: string;
    fileName?: string;
    mimeType?: string;
    location?: string;
    createdAt?: Date;
    isImage?: boolean;
    url?: string;
    urlThumbnail?: string;
};

interface InputProps {
    files: PhotoFileType[];
    onDeleteFiles: (files: PhotoFileType[]) => Promise<any>;
}

const FileDeleteWarningMessage = ({ images }) => {
    const fileCount = images.length;
    return <Alert type={'error'}>{`Delete ${fileCount} file${fileCount > 1 ? 's' : ''}?`}</Alert>;
};

export class PhotoFileGalleryPanel extends React.Component<InputProps> {
    state = {
        files: null,
        images: [],
        selectMode: false,
        isDeleting: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            files: props.files,
            images: this.preparePhotos(props.files),
            selectMode: false,
            isDeleting: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.files === nextProps.files) {
            return;
        }

        this.setState({ images: this.preparePhotos(nextProps.files) });
    }

    preparePhotos = (files: PhotoFileType[] = []) => {
        const images: any[] =
            files
                .filter?.((file) => file && file.isImage)
                .map((file) => {
                    return {
                        src: file.url,
                        thumbnail: file.urlThumbnail || file.url,
                        thumbnailWidth: 64,
                        thumbnailHeight: 48,
                        file: file,
                    };
                }) || [];

        return images;
    };

    onDeleteImages = async (images) => {
        this.setState({ isDeleting: true });
        const files = images.map((i) => i.file);
        await this.props.onDeleteFiles(files);
        this.setState({ isDeleting: false });
        this.onCancelSelection();
    };

    onSelectImage = (index, image) => {
        const images = this.state.images.slice();
        const img = images[index];
        if (img.hasOwnProperty('isSelected')) img.isSelected = !img.isSelected;
        else img.isSelected = true;

        this.setState({
            images: images,
        });
    };

    getSelectedImages() {
        return this.state.images.filter((i) => {
            return i.isSelected === true;
        });
    }

    onCancelSelection = () => {
        this.setState({ selectMode: false, images: this.preparePhotos(this.props.files) });
    };

    imageGalleryButtons = (): HeaderButtonType[] => {
        const { isDeleting } = this.state;
        const selectedImages = this.getSelectedImages();

        return [
            selectedImages.length > 0 && (
                <ButtonWithConfirm
                    loading={isDeleting}
                    content={'Delete Selected'}
                    color={'red'}
                    size={'sm'}
                    icon={'trash'}
                    onClick={() => this.onDeleteImages(selectedImages)}
                    confirm={{
                        confirmMessage: {
                            header: 'Delete Files?',
                            content: <FileDeleteWarningMessage images={selectedImages} />,
                        },
                        confirmButton: { content: 'Delete Files', negative: true, icon: 'trash' },
                        cancelButton: { content: 'Keep' },
                    }}
                />
            ),
            this.state.selectMode
                ? {
                      content: 'cancel',
                      icon: 'cancel',
                      onClick: () => this.onCancelSelection(),
                  }
                : {
                      icon: 'edit',
                      onClick: () => this.setState({ selectMode: true }),
                  },
        ];
    };

    render() {
        const { images, isDeleting } = this.state;

        if (images.length === 0) return null;

        return (
            <Dimmer.Dimmable dimmed={isDeleting}>
                <Dimmer active={isDeleting} inverted>
                    <Loader> Deleting </Loader>
                </Dimmer>
                <Panel>
                    <Panel.Header content={'Photos'} basic={true} button={this.imageGalleryButtons()} />
                    <Panel.Body>
                        <Gallery
                            selectMode={this.state.selectMode}
                            // enableLightbox={!this.state.selectMode}
                            images={images}
                            enableImageSelection={this.state.selectMode}
                            onSelectImage={this.onSelectImage}
                            rowHeight={100}
                            backdropClosesModal={true}
                            showLightboxThumbnails={true}
                        />
                    </Panel.Body>
                </Panel>
            </Dimmer.Dimmable>
        );
    }
}
