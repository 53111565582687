import * as React from 'react';
import { Panel } from '@ez/components';
import { getRecommendationSummary } from './compute-recommendation-summary';
import { CalcDocType, FormikHeatCalcValueType } from './types';
import * as _ from 'lodash';
import { useFormikContext } from 'formik';

export interface RecommendationPanelProps {}

export const PanelHeaterRecommendation: React.FC<RecommendationPanelProps> = ({}) => {
    const { values } = useFormikContext<FormikHeatCalcValueType>();
    const type = values.docType;
    const costEstimation = values.costEstimation;
    let summary = getRecommendationSummary({ type, costEstimation });

    if (!summary) {
        return (
            <div>
                <Panel>
                    <Panel.Header>Recommended Heater</Panel.Header>
                    <Panel.Body>
                        <>No recommendations</>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }

    if (type === CalcDocType.HeatPump || type === CalcDocType.HeatPumpAutopool) {
        return (
            <>
                <Panel>
                    <Panel.Header color={'green'}>Selected Heat Pump</Panel.Header>
                    <Panel.Body>
                        <Panel.Item labelWidth={200} label={'Minimum Heat Output'}>
                            {summary.minimumOutput} KW/Hour
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Operating time'}>
                            {summary.operatingHours} hrs/day
                        </Panel.Item>
                        <Panel.Divider />

                        <Panel.Item labelWidth={200} label={'Heater Model'}>
                            <span className={'text-blue-500 font-bold'}>{summary.model?.name}</span>
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Part No'}>
                            {summary.model?.sku}
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Heating Capacity'}>
                            {summary.model?.capacity} kW
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Input Power'}>
                            {summary.model.input} kW
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Number of units'}>
                            {summary.numberOfUnits || '--'}
                        </Panel.Item>
                        <Panel.Divider />
                        <Panel.Item labelWidth={200} label={'Estimated Cost (Yearly)'}>
                            <span className={'text-red-800 font-bold'}>${summary.costYearly}</span>
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Electricity Cost'}>
                            {summary.resourcePrice}
                        </Panel.Item>
                    </Panel.Body>
                </Panel>
            </>
        );
    }
    if (type === CalcDocType.GasHeater) {
        const coldestMonthCost = _.maxBy(costEstimation?.gas?.costByMonth, (c) => c.costRunningMonthly);

        return (
            <>
                <Panel>
                    <Panel.Header color={'green'}>Selected Gas Heater</Panel.Header>
                    <Panel.Body>
                        <Panel.Item labelWidth={200} label={'Heat Output Required'}>
                            {summary.minimumOutput} MJ
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Time to heat up (max)'}>
                            {summary.operatingHours || '0'} hr
                        </Panel.Item>
                        <Panel.Divider />
                        <Panel.Item labelWidth={200} label={'Heater Model'}>
                            <span className={'text-blue-500 font-bold'}>{summary.model?.name}</span>
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Part No'}>
                            {summary.model?.sku}
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Heating Capacity'}>
                            {summary.model?.capacity} MJ
                        </Panel.Item>
                        <Panel.Item labelWidth={200} label={'Number of units'}>
                            {summary.numberOfUnits || '--'}
                        </Panel.Item>
                        <Panel.Divider />
                        <Panel.Item labelWidth={200} label={'Est. Cost (yearly)'}>
                            <span className={'text-red-800 font-bold'}>${summary.costYearly}</span>
                        </Panel.Item>
                        {coldestMonthCost && (
                            <Panel.Item labelWidth={200} label={'Est. Cost (coldest month)'}>
                                <span className={'text-red-700 font-bold'}>
                                    ${coldestMonthCost.costRunningMonthly?.toFixed(2)}
                                </span>
                            </Panel.Item>
                        )}
                        <Panel.Item labelWidth={200} label={'Cost of Gas'}>
                            {summary.resourcePrice}
                        </Panel.Item>
                    </Panel.Body>
                </Panel>
            </>
        );
    }
};

const RecommendationPanelGas: React.FC<RecommendationPanelProps> = ({}) => {
    // return (
    //     <Panel>
    //         <Panel.Header color={'green'}>Recommended Heater</Panel.Header>
    //         <Panel.Body style={{ backgroundColor: '#fbfffa' }}>
    //             <Panel.Item labelWidth={260} label={'Heater Model'}>
    //                 <b>{recommendedModel.Description}</b>
    //             </Panel.Item>
    //             <Panel.Item labelWidth={260} label={'Heat Output'}>
    //                 {recommendedModel.Capacity} MJ
    //             </Panel.Item>
    //             <Panel.SectionHeader>Coldest Month ({profile.monthProfile.name})</Panel.SectionHeader>
    //             <Panel.Item labelWidth={260} label={'Initial Heating Cost (from ambient)'}>
    //                 $AUD {profile.costToHeatUp?.toFixed?.(2)}
    //             </Panel.Item>
    //             <Panel.Item labelWidth={260} label={'Daily Running Cost'}>
    //                 $AUD {profile.dailyRunningCost?.toFixed?.(2)}
    //             </Panel.Item>
    //             <Panel.Item labelWidth={260} label={'Weekly Running Cost'}>
    //                 $AUD {(profile.dailyRunningCost * 7)?.toFixed?.(2)}
    //             </Panel.Item>
    //             <Panel.Item labelWidth={260} label={'Monthly Running Cost'}>
    //                 $AUD {(profile.dailyRunningCost * profile.monthProfile.days)?.toFixed?.(2)}
    //             </Panel.Item>
    //             <Panel.Item labelWidth={260} label={'Gas Cost'}>
    //                 {profile.gasCost?.toFixed?.(2)} Cent per MJ
    //             </Panel.Item>
    //         </Panel.Body>
    //     </Panel>
    // );
    return null;
};
