import * as React from 'react';
import { PageControlProps } from './EquipmentList.Control';
import { groupItemsByProductDeclaration } from '../../../Equipment/utils';

interface EquipmentListProps extends PageControlProps {
    poolId: string;
    onClick: (any) => any;
}

const EquipmentList: React.FC<EquipmentListProps> = (props) => {
    let { items, poolProductDeclarations } = props;

    if (!items?.length) {
        return (
            <small>
                <i>No equipment registered</i>
            </small>
        );
    }

    const groups = groupItemsByProductDeclaration(poolProductDeclarations, items);

    return (
        <div className={'grid gap-2'}>
            {groups.map((g, i) => {
                const count = g.items.length;
                if (count === 0) {
                    return null;
                }
                return (
                    <div key={i}>
                        <div className={'font-bold'}>{g.name}</div>
                        <div className={'grid gap-1'}>
                            {g.items.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <span className={'pl-2'}>- {item?.product?.name || '--'}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default EquipmentList;
