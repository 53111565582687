import * as React from 'react';
import { ModuleLink, useAppNavigator } from '@poolware/react-app-navigator';
import { Display, HeaderButtonType, Icon, Panel } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';

interface WorkOrderReportPanelProps {
    workOrder: NodeType.WorkOrder;
}

export const WorkOrderWaterTestReportsPanel: React.FC<WorkOrderReportPanelProps> = ({ workOrder }) => {
    const { AppNavigator } = useAppNavigator();

    const onNewWaterTest = () => {
        AppNavigator.navigate('/new', {
            moduleId: 'WATER_TEST',
            setOrigin: true,
            query: {
                poolId: workOrder?.pool?.id,
                woId: workOrder?.id,
            },
            state: {
                workOrder: workOrder,
            },
        });
    };
    const reports = fromEdges(workOrder.testReports);

    const button: HeaderButtonType = {
        content: 'Add',
        icon: 'plus',
        onClick: onNewWaterTest,
        locked: !workOrder?.pool?.id ? 'Pool is not linked to work order' : false,
    };

    return (
        <Panel>
            <Panel.Header color={'grey'} icon={'lab'} size={'small'} content={'Water Test'} button={button} />
            <Panel.Body>
                {reports.length > 0 && (
                    <div className={'grid gap-2'}>
                        {reports.map((testReport) => {
                            return (
                                <div key={testReport.id} className={'flex flex-row gap-4'}>
                                    <Icon name={'lab'} />
                                    <Display.Date value={testReport.createdAt} format={'LL'} />{' '}
                                    <div className={'flex flex-row gap-2'}>
                                        <ModuleLink
                                            relativeToModule={true}
                                            moduleId={'WATER_TEST'}
                                            to={`${testReport.id}`}
                                        >
                                            Open Report <Icon name={'external'} />
                                        </ModuleLink>
                                        <span className={'w-2'}>|</span>
                                        <a target={'_blank'} href={testReport.pdfUrl} rel="noopener noreferrer">
                                            Download PDF <Icon name={'file pdf'} color={'red'} />
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </Panel.Body>
        </Panel>
    );
};
