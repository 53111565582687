import * as React from 'react';
import { ButtonWithDropdownMenu, confirmModalImperative, Display, DropdownMenuOptionType, Icon } from '@ez/components';
import styled from 'styled-components';

const EventContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #d2d2d2;
    margin: 0 0 0.75em 0.5em;
    padding: 0.5em;
    border-radius: 5px;
    background-color: white;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        border-style: solid;
        display: block;
        width: 0;
        top: 10px;
        bottom: auto;
        left: -6px;
        border-width: 6px 6px 6px 0;
        border-color: transparent #d0d0d0;
    }

    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        display: block;
        width: 0;
        top: 10px;
        bottom: auto;
        left: -5px;
        border-width: 6px 6px 6px 0;
        border-color: transparent #fff;
    }
`;

const EventHeader = styled.div`
    display: grid;
    grid-template-columns: 36px 1fr 50px;
    grid-template-areas: 'avatar content action';
    align-items: center;
    margin-bottom: 0.5em;
`;

const HeaderAvatar = styled.div`
    grid-area: avatar;
    align-self: center;
    justify-self: start;
`;

const HeaderContent = styled.div`
    grid-area: content;
    align-self: center;
`;
const HeaderAction = styled.div`
    grid-area: action;
    justify-self: end;
`;

const EventContent = styled.div`
    //font-size: 0.9em;
    //display: inline-block;
    //color: #818181;
    font-size: 0.9em;
    border-top: 1px solid #d2d2d2;
    padding: 0.25em 0.5em;
`;

const EventUser = styled.div`
    font-size: 0.9em;
    display: inline-block;

    //color: #818181;
`;

const EventDate = styled.div`
    font-size: 0.9em;
    display: inline-block;
    padding-left: 1em;
    color: #818181;
`;

export const FeedEvent = ({ note, onEdit, onDelete }) => {
    const handleDelete = async (v) => {
        const answer = await confirmModalImperative({
            negative: true,
            confirmMessage: {
                header: `Delete Note?`,
                content: 'This cannot be undone!',
            },
            confirmButton: {
                content: 'Delete',
                icon: 'trash',
                negative: true,
            },
        });

        if (answer) {
            await onDelete(v);
        }
    };

    const menuItems: DropdownMenuOptionType[] = [
        onEdit && {
            content: 'Edit',
            icon: 'edit',
            onClick: onEdit,
        },
        onDelete && {
            content: 'Delete',
            icon: 'trash',
            onClick: handleDelete,
        },
    ].filter(Boolean);

    const hasMenus = menuItems?.length > 0;

    return (
        <div className={'bg-tertiary flex flex-col gap-2 p-2 rounded'}>
            <div className={'text-tertiary flex flex-row text-sm  font-bold'}>
                <div className={'w-8'}>
                    <Icon name={'spy'} />
                </div>
                <strong className={'flex-grow'}>
                    <Display.Entity value={note?.addedBy} />
                </strong>
                {hasMenus && (
                    <ButtonWithDropdownMenu
                        variant={'tertiary'}
                        size={'xs'}
                        icon={'ellipsis vertical'}
                        options={menuItems}
                    />
                )}
            </div>
            <div className={'pl-8'}>
                <Display.TextShortened
                    showLines={8}
                    value={note?.note}
                    expandOnly={true}
                    // style={{ backgroundColor: '#fffff0', padding: '0.5em' }}
                />
            </div>
            <div className={'pl-8 text-sm text-tertiary'}>
                <Display.Date format={'lll'} value={note?.createdAt} />
            </div>
        </div>
    );
};
