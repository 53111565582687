import * as React from 'react';
import { AdminOnly, ThemeStateProps, useViewer } from '@poolware/app-shell';
import {
    Alert,
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    SectionHeader,
    Segment,
    StickyMenuBar,
    ViewJSON,
} from '@ez/components';
import { getConfig, getRollbar } from '../../config';

interface PageProps extends ThemeStateProps {}

const Page: React.FC<PageProps> = () => {
    const { isAdmin } = useViewer();
    const rollbar = getRollbar() || {};

    if (!isAdmin) {
        return <Alert type={'info'} content={'Not enough permissions to see this page'} />;
    }

    return (
        <AdminOnly>
            <PageLayout>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarHeaderItem content={'System Config'} />
                    </MenuBarSection>
                </StickyMenuBar>
                <PageLayout.BodySection width={'screen-lg'} className={'gap-2 flex flex-col'}>
                    <Segment>
                        <SectionHeader content={'System config'} />
                        <ViewJSON data={getConfig()} />
                    </Segment>
                    <Segment>
                        <SectionHeader content={'Rollbar'} />
                        <ViewJSON data={rollbar.options || {}} />
                    </Segment>

                    <Segment>
                        <SectionHeader content={'Server manifest'} />
                        <ViewJSON data={window['serverManifest']} />
                    </Segment>

                    <Segment>
                        <SectionHeader content={'process.env'} />
                        <ViewJSON data={process.env} />
                    </Segment>
                </PageLayout.BodySection>
            </PageLayout>
        </AdminOnly>
    );
};

export default Page;
