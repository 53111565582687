import * as React from 'react';
import { Icon } from '@ez/components';
import { LifeGuardShadowType } from '../View/TabDeviceState/types';
import { DeviceStateType } from '../View/TabDeviceState/types';

export const OnlineIcon: React.FC<{
    state?: DeviceStateType<LifeGuardShadowType>;
    loading?: boolean;
}> = ({ state, loading }) => {
    if (loading) {
        return <Icon name={'spinner'} color={'gray'} loading={true} />;
    }
    return <Icon name={'wifi'} color={state?.isAlive ? 'green' : 'grey'} />;
};
