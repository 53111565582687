import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import * as dates from './utils/dates';
import { RBCContext } from './CalendarContext';

const propTypes = {
    event: PropTypes.object.isRequired,
    slotStart: PropTypes.instanceOf(Date),
    slotEnd: PropTypes.instanceOf(Date),

    selected: PropTypes.bool,
    isAllDay: PropTypes.bool,
    continuesPrior: PropTypes.bool,
    continuesAfter: PropTypes.bool,

    onSelect: PropTypes.func,
    onDoubleClick: PropTypes.func,
};

interface EventCellProps extends InferProps<typeof propTypes> {
    style?: any;
    className?: string;
}

class EventCell extends React.Component<EventCellProps> {
    public static propTypes = propTypes;
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    render() {
        let {
            style,
            className,
            event,
            selected,
            isAllDay,
            onSelect,
            onDoubleClick,
            continuesPrior,
            continuesAfter,
            children,
            slotStart,
            slotEnd,
            ...props
        } = this.props;

        const {
            localizer,
            accessors,
            getters,
            components: { event: Event, eventWrapper: EventWrapper },
        } = this.context;

        let title = accessors.title(event);
        let end = accessors.end(event);
        let start = accessors.start(event);
        let allDay = accessors.allDay(event);

        let showAsAllDay = isAllDay || allDay || dates.diff(start, dates.ceil(end, 'day'), 'day') > 1;

        let userProps = getters.eventProp(event, start, end, selected);

        const content = (
            <div className="rbc-event-content">
                {Event ? (
                    <Event
                        event={event}
                        continuesPrior={continuesPrior}
                        continuesAfter={continuesAfter}
                        title={title}
                        isAllDay={allDay}
                        localizer={localizer}
                        slotStart={slotStart}
                        slotEnd={slotEnd}
                    />
                ) : (
                    title
                )}
            </div>
        );

        return (
            <EventWrapper {...this.props} type="date">
                <div
                    {...props}
                    tabIndex={0}
                    style={{ ...userProps.style, ...style }}
                    className={clsx('rbc-event', className, userProps.className, {
                        'rbc-selected': selected,
                        'rbc-event-allday': showAsAllDay,
                        'rbc-event-continues-prior': continuesPrior,
                        'rbc-event-continues-after': continuesAfter,
                    })}
                    onClick={(e) => onSelect && onSelect(event, e)}
                    onDoubleClick={(e) => onDoubleClick && onDoubleClick(event, e)}
                >
                    {typeof children === 'function' ? children(content) : content}
                </div>
            </EventWrapper>
        );
    }
}

export default EventCell;
