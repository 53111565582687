import Main from './Pages/Page';
import NewAppointment from './AppointmentForm/Page.New';
import EditAppointment from './AppointmentForm/Page.Edit';
import NewWorkOrderAppointment from './AppointmentForm/Page.NewWorkOrder';
import WhereToGo from './Pages/Page.WhereToGo';
import { PageRescheduleConfirm } from './AppointmentForm/Page.RescheduleConfirm';
import * as CalendarGroup from './CalendarGroupEditor';

export const SchedulerPages = {
    Main,
    NewAppointment,
    NewWorkOrderAppointment,
    WhereToGo,
    PageRescheduleConfirm,
    EditAppointment,
    CalendarGroup,
};

export * from './AppointmentDock/';
export * from './AppointmentPreview/';
export * from './utils';
export * from './Queries';
export * from './types';
