import * as React from 'react';
import { useEffect, useMemo } from 'react';
import CalendarView from '../CalendarView';
import { Modal, SlideInDock, stringFormatters, useModalCtrl } from '@ez/components';
import { NodeType } from '@poolware/api';
import { BookingActionProps, CalendarActionProps, useBookingActions, useCalendarActions } from '../../redux';
import { AppointmentPreviewModal } from '../AppointmentPreview';
import { SelectSlotTopMessageBar } from './TopMessageBar';
import { CalendarPageLayoutManager } from './CalendarPageLayoutManager';
import { IAppNavigatorProps, useAppNavigator } from '@poolware/react-app-navigator';
import { CalendarPageContextProvider } from '../utils/CalendarPageContext';
import { ACTION_NOTIFICATION, DnDInteractionInfoType } from '@poolware/react-big-calendar';
import { UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import NewWorkOrderAppointment from '../AppointmentForm/Page.NewWorkOrder';
import { SidebarLeft, SidebarRight } from '../CalendarSidebar';
import { AppointmentViewContextProvider, AppointmentViewDock, useAppointmentViewCtx } from '../AppointmentDock';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';
import { SlotInfoType } from '../CalendarView/CalendarViewControl';
import { CalendarEventType } from '../CalendarView/types';
import moment from 'moment';

export interface PageProps extends IAppNavigatorProps, BookingActionProps, CalendarActionProps {}

const Page: React.FC<PageProps> = () => {
    const showForm = useModalCtrl();
    const modalPreview = useModalCtrl();

    const { CalendarAction, CalendarState } = useCalendarActions();
    const { BookingState, BookingAction } = useBookingActions();
    const { AppNavigator } = useAppNavigator();
    const CalLayoutState = useCalendarLayoutState();
    const previewItemCtx = useAppointmentViewCtx();

    useEffect(() => {
        const refetchQueries = ['SchedulerAppointmentsList'];
        previewItemCtx.addRefetchQueries(refetchQueries);
        return () => {
            previewItemCtx.removeRefetchQueries(refetchQueries);
        };
    }, [previewItemCtx.appointmentItemId]);

    const closeAppointmentPreview = () => {
        CalendarAction.setPreviewAppt(null);
        previewItemCtx.setAppointmentItemId(null);
        modalPreview.onClose();
    };

    const onClickPoint = (slot) => {
        if (!BookingState.isSagaMode) {
            // TODO: HACK: TO FIX:
            //  single clickPoint is called on double click as well. need to fix.
            //  Ignore single clickpoint when in saga mode
            closeAppointmentPreview();
        }
    };

    const showAppointmentPreview = (event: CalendarEventType | null) => {
        if (!event?.item) {
            CalendarAction.setPreviewAppt(null);
            previewItemCtx.setAppointmentItemId(null);
            modalPreview.onClose();
            return;
        }

        // TODO: clean up this logic. This is very messy.
        if (false) {
            // HACK: TEMPORARY CODE. DON"T CHECKIN TRUE CONDITION TO GIT
            AppNavigator.navigateRelative(`/mobile/${event.item?.id}`, { setOrigin: true });
        } else if (CalLayoutState.appLayoutMode.isMobile) {
            // Small and touch screens. Show as full page.
            CalendarAction.setPreviewAppt(null);
            AppNavigator.navigateRelative(`/${event.item?.id}/preview`, { setOrigin: true });
        } else {
            if (CalendarState.previewApptId !== event.item?.id) {
                CalendarAction.setPreviewAppt(event.item?.id);
            }
            previewItemCtx.setAppointmentItemId(event.item?.id);
            modalPreview.onOpen();
        }
    };

    const onSwitchToDockMode = () => {
        CalendarAction.setPreviewAppt(previewItemCtx.appointmentItemId);
        CalLayoutState.setDockState(true);
        modalPreview.onClose();
    };

    const onBookingAbort = () => {
        if (BookingState.isSagaMode) {
            BookingAction.abort();
        } else {
            AppNavigator.navigateToOrigin();
        }
    };

    // Handle new appointment event.
    // NOTE: new appointments is created via saga flow.
    // see `appointment-booking-from-calendar` saga.
    const onSelectSlot = async (slotInfo: SlotInfoType) => {
        const { startDate, endDate, resourceId, action } = slotInfo;
        if (resourceId) {
            BookingAction.setDetails({ staff: { id: resourceId } });
        }

        let _endDate = endDate;
        if (action === ACTION_NOTIFICATION.doubleClick) {
            const duration = BookingState.details?.appointmentItem?.duration || 60;
            _endDate = moment(startDate).add(duration, 'minutes').toDate();
        }
        BookingAction.timeSlotSelected({ startDate, endDate: _endDate });

        // if not in saga mode yet...
        if (!BookingState.isSagaMode) {
            // Start new saga
            closeAppointmentPreview();
            BookingAction.startFromCalendar();
        } else {
        }
    };

    const onDropFromOutsideEvent = (slotInfo: DnDInteractionInfoType) => {
        // console.log('onDropFromOutsideEvent', slotInfo);
        // @ts-ignore
        const workOrder = slotInfo.event as NodeType.WorkOrder;
        const customer = workOrder?.customer;
        const address = workOrder?.address;
        let staff = workOrder?.assignedTo;
        if (slotInfo.resourceId && slotInfo.resourceId !== UNASSIGNED_APPOINTMENT_STAFF_ID) {
            // override WorkOrder's staff id
            staff = { id: slotInfo.resourceId };
        }
        const pool = workOrder?.pool;

        // HACK: override end date to +1h from start date.
        // Make it configurable via calendar's drag-and-drop API.
        const endDate = moment(slotInfo.start).add(1, 'h').toDate();
        BookingAction.timeSlotSelected({ startDate: slotInfo.start, endDate: endDate });
        // BookingAction.setDetails({ workOrder, customer, pool, address, staff });
        // showForm.onOpen();

        // // if not in saga mode yet...
        if (!BookingState.isSagaMode) {
            // Start new saga
            BookingAction.startFromCalendarDropFromOutside({
                details: {
                    workOrder,
                    customer,
                    pool,
                    address,
                    staff,
                },
            });
        }
    };

    // Handle edit appointment event.
    const onAppointmentItemEdit = (item: NodeType.AppointmentItem) => {
        if (!CalLayoutState.isDockOpen) {
            closeAppointmentPreview();
        }
        AppNavigator.navigate(`/scheduler/${item.id}/edit`, {
            modal: true,
            setOrigin: true,
            state: {
                appointmentItem: item,
            },
        });
    };

    const customer = BookingState.details?.customer;
    const leftLabel = customer ? stringFormatters.formatEntityName(customer) : undefined;

    const LB = useMemo(() => <SidebarLeft />, []);

    const RB = useMemo(() => {
        if (!CalLayoutState.canOpenDock) {
            return null;
        }
        return <SidebarRight />;
    }, [CalLayoutState.canOpenDock]);

    const MB = (
        <CalendarView
            lockEvents={!BookingState.isSelectingSlot}
            onSelectSlot={onSelectSlot}
            onClickPoint={onClickPoint}
            onSelectEvent={showAppointmentPreview}
            onDropFromOutsideEvent={onDropFromOutsideEvent}
            onAppointmentItemEdit={onAppointmentItemEdit}
        />
    );

    const showSlideInDock =
        CalLayoutState.useSlideInDockForPreview &&
        previewItemCtx.appointmentItemId &&
        !CalLayoutState.isDockOpen &&
        !BookingState.isSelectingSlot;

    return (
        <React.Suspense fallback={'loading'}>
            {BookingState.isSelectingSlot && (
                <SelectSlotTopMessageBar
                    centerLabel={'Select Time Slot'}
                    leftLabel={leftLabel}
                    onCancel={onBookingAbort}
                />
            )}

            <CalendarPageLayoutManager leftSidebar={LB} main={MB} rightSidebar={RB} />

            {showSlideInDock && (
                <SlideInDock onClose={closeAppointmentPreview} animateSlide={false}>
                    <AppointmentViewDock
                        onClose={closeAppointmentPreview}
                        appointmentItemId={previewItemCtx.appointmentItemId}
                        // onDidDelete={onDidDelete}
                        canShowDockModePin={true}
                    />
                </SlideInDock>
            )}

            {!CalLayoutState.useSlideInDockForPreview &&
                previewItemCtx.appointmentItemId &&
                !CalLayoutState.isDockOpen &&
                modalPreview.open && (
                    <AppointmentPreviewModal
                        onClose={closeAppointmentPreview}
                        onEdit={onAppointmentItemEdit}
                        open={true}
                        onShowInCalendar={() => modalPreview.onClose()}
                        appointmentItemId={previewItemCtx.appointmentItemId}
                        onSwitchToDockMode={onSwitchToDockMode}
                        allowDockModeOption={true}
                    />
                )}
            {showForm.open && (
                <Modal {...showForm}>
                    <NewWorkOrderAppointment />
                </Modal>
            )}
        </React.Suspense>
    );
};

export default (props) => (
    <CalendarPageContextProvider>
        <AppointmentViewContextProvider>
            <Page {...props} />
        </AppointmentViewContextProvider>
    </CalendarPageContextProvider>
);
