import { fromEdges, JobTodoItemStatus, NodeType } from '@poolware/api';

export const calcChecklistCount = (workOrder: NodeType.WorkOrder) => {
    const jobTodos = fromEdges(workOrder?.jobs) || [];
    const result = jobTodos.reduce(
        (acc, job) => {
            const items = fromEdges(job?.items);
            if (items.length === 0) {
                // job has no items, so it is a single job
                const isJobCompleted = job?.status !== JobTodoItemStatus.Open;
                return {
                    total: acc.total + 1,
                    completed: acc.completed + (isJobCompleted ? 1 : 0),
                };
            } else {
                // job has items
                const completed = items.filter((item) => item?.status !== JobTodoItemStatus.Open).length;
                return {
                    total: acc.total + items.length,
                    completed: acc.completed + completed,
                };
            }
        },
        { total: 0, completed: 0 }
    );

    return result;
};
