import * as React from 'react';

import { Panel } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { GroupItem } from '../utils';
import { BrandLabel } from '../RegisterNew/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { SectionHeader } from '@ez/components';

interface EquipmentItemPanelProps {
    item: NodeType.Item;
    onEdit: (item: NodeType.Item) => any;
}

const EquipmentItemPanel: React.FC<EquipmentItemPanelProps> = (props) => {
    const { item } = props;

    const installedAt = item?.installedAt;
    const productName = item?.product?.name;
    const brand = item?.product?.brand;
    const traits = fromEdges(item?.product?.traits);
    const declarationsString = traits.map((t) => t?.declaration?.name || '').join(', ');

    return (
        <Panel>
            <Panel.Header
                color={'brown'}
                content={productName}
                button={{ icon: 'edit', content: 'Details', onClick: () => props.onEdit(item) }}
            />
            <Panel.Body>
                <Panel.Item label={'Name'}>{productName}</Panel.Item>
                <Panel.Item label={'Label'}>{item.label}</Panel.Item>
                <Panel.Item label={'Brand'}>
                    <BrandLabel brand={brand} />
                </Panel.Item>
                <Panel.ItemDate format={'LL'} label={'Installation Date'} content={installedAt} />
                <Panel.Item label={'Categories'}>{declarationsString}</Panel.Item>
                <Panel.ItemText label={'Equipment Notes'} content={item.note}>
                    {/*<Display.Description value={item.note} />*/}
                </Panel.ItemText>
            </Panel.Body>
        </Panel>
    );
};

interface EquipmentTableProps {
    groups: GroupItem[];
    onRemoveProductItem: (item: NodeType.Node) => any;
}

const EquipmentList: React.FC<EquipmentTableProps> = (props) => {
    const { AppNavigator } = useAppNavigator();
    const { groups } = props;

    const onEditItem = (item: NodeType.Item) => {
        AppNavigator.navigate(`/${item.id}`, { relativeToModule: true, setOrigin: true });
    };

    return (
        <div>
            {groups.map((group, i) => {
                if (group.items.length === 0) {
                    return null;
                }
                return (
                    <React.Fragment key={i}>
                        <SectionHeader size={'small'} dividing={true}>
                            {group.name}
                        </SectionHeader>
                        {group.items.length === 0 && <div>--</div>}
                        <div className={'grid gap-4'}>
                            {group.items.map((item) => {
                                return <EquipmentItemPanel item={item} onEdit={onEditItem} />;
                            })}
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default EquipmentList as React.ComponentType<EquipmentTableProps>;
