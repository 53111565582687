import * as React from 'react';
import * as URLBuilder from '../../../../../routes/url-builder';

import { NodeType } from '@poolware/api';
import { compose } from '@ez/tools';

import { AppointmentPreviewModal, CalendarActionProps, withCalendarActions } from '@poolware/app-service-jobs';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { Button } from '@ez/components';

export interface InputProps {
    appointment: NodeType.AppointmentItem;
    refetch?: () => Promise<any>;
}

interface Props extends InputProps, IAppNavigatorProps, CalendarActionProps {}

class AppointmentPreviewButton extends React.Component<Props> {
    state = {
        detailsPopupOpen: false,
        selectedAppointmentItem: null,
    };

    onAppointmentView = (appointment: NodeType.AppointmentItem) => {
        this.showEventPopup(appointment);
    };

    showEventPopup = (appointment: NodeType.AppointmentItem, target?: any) => {
        this.setState({ detailsPopupOpen: true, selectedAppointmentItem: appointment, target: target });
    };

    closeEventPopup = async (cb?: any) => {
        this.setState({ detailsPopupOpen: false, selectedAppointmentItem: null, target: null }, cb);
    };

    onAppointmentItemEdit = async (item: NodeType.AppointmentItem) => {
        const AppNavigator = this.props.AppNavigator;
        await this.closeEventPopup(() => {
            const id = this.props.appointment.id;
            AppNavigator.navigate(URLBuilder.Scheduler.edit(id), {
                setOrigin: true,
                modal: true,
                state: {
                    appointmentItem: item,
                },
            });
        });
    };

    onDidDelete = async () => {
        if (this.props.refetch) {
            try {
                await this.props.refetch();
            } catch (e) {
                console.error(e);
            }
        }
    };

    onShowInCalendar = (item: NodeType.AppointmentItem) => {
        this.props.CalendarAction.showAppointmentInCalendar(item);
    };

    render() {
        const { appointment } = this.props;

        if (!appointment) {
            return null;
        }

        return (
            <React.Fragment>
                <Button variant={'tertiary'} icon={'info'} onClick={() => this.onAppointmentView(appointment)} />

                {this.state.selectedAppointmentItem && (
                    <AppointmentPreviewModal
                        onDidDelete={this.onDidDelete}
                        onClose={this.closeEventPopup}
                        onEdit={this.onAppointmentItemEdit}
                        onShowInCalendar={this.onShowInCalendar}
                        open={this.state.detailsPopupOpen}
                        appointmentItemId={this.props.appointment.id}
                        showServiceJobLink={false}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default compose(
    withAppNavigator(),
    withCalendarActions()
)(AppointmentPreviewButton) as React.ComponentType<InputProps>;
