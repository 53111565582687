import * as React from 'react';
import { useState } from 'react';
import { WorkOrdersTable } from './WorkOrdersTable';
import { NodeType, useMutationWorkOrderFeedItem } from '@poolware/api';
import {
    confirmModalImperative,
    MenuBar,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    ScrollableLayout,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { FilterBar } from './FilterBar';
import { ToolbarQuickFilters } from './ToolbarQuickFilters';
import { customWorkOrderFeedItemMutation, useConnectedQueryWorkOrdersFeed } from './useConnectedQueryWorkOrdersFeed';
import { Modal } from '@ez/components';
import { PromptWorkOrderCreate } from './PromptWorkOrderCreate';
import { useStateSidebar, PageLayoutManager } from '@poolware/app-shell';

export const WorkOrderFeed: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { showSidebar, setShowSidebar } = useStateSidebar('service-jobs.wo-list.sidebar');

    const { startWorkOrder } = useMutationWorkOrderFeedItem({ mutation: customWorkOrderFeedItemMutation });
    const { connectionState, connectionData, refetchQuery } = useConnectedQueryWorkOrdersFeed();
    const modalCtrl = useModalCtrl();
    const [selectedWO, setSelectedWO] = useState<NodeType.WorkOrderFeedItem>();

    const navigateToWo = (workOrder: NodeType.WorkOrder) => {
        AppNavigator.navigate(`/wo/${workOrder?.id}`, {
            relativeToModule: true,
            setOrigin: true,
        });
    };
    const onView = async (item: NodeType.WorkOrderFeedItem, refetchQuery?: any) => {
        const { workOrder } = item;
        if (workOrder) {
            return navigateToWo(workOrder);
        }

        setSelectedWO(item);
        modalCtrl.onOpen();
        return;

        const confirmMsg = {};
        const isYes = await confirmModalImperative(confirmMsg);
        if (!isYes) {
            return;
        }

        try {
            const res = await startWorkOrder({ id: item.id }, refetchQuery && { refetchQueries: [refetchQuery] });
            const wo = res.data?.WorkOrderFeedItem?.WorkOrderFeedItem?.workOrder;
            if (!wo) {
                console.error('failed to fetch work order after `startWorkOrder` mutation');
                toastError({ title: 'Error', description: 'Failed to fetch work order details' });
                return;
            }
            // return navigateToWo(wo);
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onNew = () => {
        AppNavigator.navigateRelative('/sj/single/new', {
            setOrigin: true,
            modal: false,
        });
    };

    const LS = (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar>
                <MenuBar.Section position={'left'}>
                    <MenuBar.HeaderItem color={'grey'} icon={'filter'}>
                        Filters
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item onClick={() => setShowSidebar(false)} icon={'angle double left'} color={'grey'} />
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>
            <ScrollableLayout.BodyScroll>
                <div className={'px-2 py-4'}>
                    <FilterBar />
                </div>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );

    const Main = (
        <ScrollableLayout block={false}>
            <div className={'z-10'}>
                <MenuBar.Root>
                    <MenuBarSection>
                        {!showSidebar && <MenuBar.Item icon={'filter'} onClick={() => setShowSidebar(true)} />}
                        <MenuBarHeaderItem color={ModuleColorNames.WorkOrder} icon={ModuleIconNames.WorkOrder}>
                            Work Orders
                        </MenuBarHeaderItem>
                    </MenuBarSection>
                    <MenuBarSection position={'right'}>
                        <MenuBarItem onClick={onNew} color={'green'} icon={'plus'} title={'New Service Case'} />
                    </MenuBarSection>
                </MenuBar.Root>
            </div>

            <ToolbarQuickFilters setShowSidebar={setShowSidebar} showSidebar={showSidebar} />

            <ScrollableLayout.BodyScroll>
                <WorkOrdersTable connectionState={connectionState} connectionData={connectionData} onView={onView} />
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );

    return (
        <>
            <PageLayoutManager
                leftSidebar={LS}
                main={Main}
                showLeft={showSidebar}
                onCloseLeft={() => setShowSidebar(false)}
            />
            <Modal size={'small'} {...modalCtrl}>
                {selectedWO && (
                    <PromptWorkOrderCreate
                        woFeedItem={selectedWO}
                        onDone={modalCtrl.onClose}
                        refetchQuery={refetchQuery}
                    />
                )}
            </Modal>
        </>
    );
};
