import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { sanitizeRecurrence, useAppointmentMutators } from '../../queries/use-appointment-mutators';
import { AppointmentFormValuesType } from './AppointmentForm';
import { NodeType } from '@poolware/api';
import invariant from 'invariant';
import { toastError } from '@ez/components';
import { useViewer } from '@poolware/app-shell';

export const useApptCreationMutations = () => {
    const ServiceJobMutator = useServiceJobMutators({ refetchQueries: ['SchedulerAppointmentsList'] });
    const { AppointmentMutator } = useAppointmentMutators(['SchedulerAppointmentsList', 'QueryWorkOrderFeed']);
    const { modulesAccess, viewer } = useViewer();

    const createSingleAppointmentForWorkOrder = async (values: AppointmentFormValuesType) => {
        invariant(values.workOrder, 'Expected work order');
        invariant(!values.recurrence, 'Expected non recurrent appointment');
        return await AppointmentMutator.createAppointment(values);
    };

    const createSingleAppointmentForServiceJob = async (values: AppointmentFormValuesType) => {
        invariant(values.serviceJob, 'Expected service Job');
        invariant(!values.workOrder, 'Expected work order to not present');
        const isFollowup = Boolean(values.followUpFromWorkOrder?.id);
        const sjCurrentTitle = values.serviceJob?.title || 'Service';
        let title = values.woTemplate?.templateTitle || (isFollowup ? `${sjCurrentTitle} (follow up)` : sjCurrentTitle);
        const conf: NodeType.UpdateServiceJobMutationInput = {
            id: values.serviceJob.id,
            workOrders: {
                create: [
                    {
                        assignedTo: values.staff?.id,
                        title: title,
                        description: values.note,
                        template: values.woTemplate?.id,
                        useDefaultTemplate: !values.woTemplate?.id,
                        autoInflateTemplate: true,
                        address: values.address?.id,
                        pool: values.pool?.id,
                        workFor: values.customer?.id,
                        followUpFrom: values.followUpFromWorkOrder?.id,
                        appointment: {
                            create: {
                                color: values.color,
                                group: values.group?.id,
                                startDate: values.startDate,
                                duration: values.duration,
                            },
                        },
                    },
                ],
            },
        };
        return ServiceJobMutator.update(conf);
    };

    const createAppointmentWithJobTemplate = async (values: AppointmentFormValuesType) => {
        const isFollowup = Boolean(values.followUpFromWorkOrder?.id);
        let title = isFollowup ? 'Service (follow up)' : 'Service';
        // TODO: notes, implicit flag
        const conf: NodeType.CreateServiceJobMutationInput = {
            entity: { create: {} },
            address: values.address?.id,
            pool: values.pool?.id,
            customer: values.customer?.id,
            title: values.sjTemplate?.templateTitle || title,
            staff: values.staff?.id,
            description: values.note,
            template: values.sjTemplate?.id,
            autoInflateTemplate: false,
            implicitType: NodeType.ServiceJobImplicitTypeEnum.Appointment,
        };

        if (values.recurrence && values.isRecurring) {
            // Create Recurring Service Case
            conf.useDefaultTemplate = !values.sjTemplate;
            conf.recurrentAppointment = {
                startDate: values.startDate,
                duration: values.duration,
                recurrence: sanitizeRecurrence(values.recurrence),
                workOrderTemplate: values.woTemplate?.id,
                useDefaultTemplate: !values.woTemplate?.id,
                group: values.group?.id,
                selectedColor: values.color,
                note: values.note,
            };
        } else {
            // Create Single Service Case
            conf.workOrders = [
                {
                    title: values.woTemplate?.templateTitle || title,
                    description: values.note,
                    template: values.woTemplate?.id,
                    useDefaultTemplate: !values.woTemplate?.id,
                    followUpFrom: values.followUpFromWorkOrder?.id,
                    autoInflateTemplate: true,
                    appointment: {
                        create: {
                            color: values.color,
                            group: values.group?.id,
                            startDate: values.startDate,
                            duration: values.duration,
                        },
                    },
                },
            ];
        }
        return await ServiceJobMutator.create(conf);
    };

    /**
     * Create appointment and automatically creat work order
     * @param values
     */
    const onSubmitWithAutoWO = async (values: AppointmentFormValuesType) => {
        if (values.serviceJob && !values.workOrder) {
            return await createSingleAppointmentForServiceJob(values);
        } else if (values.workOrder) {
            return await createSingleAppointmentForWorkOrder(values);
        } else if (values.recurrence && values.isRecurring && !values.customer?.id) {
            // Only create recurring cases if customer is provided
            // TODO: remove this limitation in the future.
            return await onSubmitNoAutoWO(values);
        } else {
            return await createAppointmentWithJobTemplate(values);
        }
    };

    /**
     * Create appointment without automatic creation of work order
     * @param values
     */
    const onSubmitNoAutoWO = async (values: AppointmentFormValuesType) => {
        if (values.sjTemplate && values.woTemplate) {
            // TODO: notes, implicit flag
            const conf: NodeType.CreateServiceJobMutationInput = {
                entity: { create: {} },
                address: values.address?.id,
                pool: values.pool?.id,
                customer: values.customer?.id,
                title: values.sjTemplate.templateTitle,
                staff: values.staff?.id,
                description: values.note,
                template: values.sjTemplate.id,
                autoInflateTemplate: false,
                implicitType: NodeType.ServiceJobImplicitTypeEnum.Appointment,
            };
            if (values.recurrence && values.isRecurring) {
                // Create Recurring Service Case
                conf.recurrentAppointment = {
                    startDate: values.startDate,
                    duration: values.duration,
                    recurrence: sanitizeRecurrence(values.recurrence),
                    workOrderTemplate: values.woTemplate.id,
                    group: values.group?.id,
                    selectedColor: values.color,
                    note: values.note,
                };
            } else {
                // Create Single Service Case
                conf.workOrders = [
                    {
                        template: values.woTemplate?.id,
                        description: values.note,
                        autoInflateTemplate: true,
                        appointment: {
                            create: {
                                group: values.group?.id,
                                startDate: values.startDate,
                                duration: values.duration,
                            },
                        },
                    },
                ];
            }
            return await ServiceJobMutator.create(conf);
        } else {
            // Create Appointment
            return await AppointmentMutator.createAppointment(values);
        }
    };

    const onSubmit = async (values: AppointmentFormValuesType) => {
        if (values.serviceJob || modulesAccess.FieldServices?.calendarAutoCreateWo) {
            return await onSubmitWithAutoWO(values);
        } else {
            return await onSubmitNoAutoWO(values);
        }
    };

    return {
        createAppointmentWithJobTemplate,
        createSingleAppointmentForServiceJob,
        onSubmitWithAutoWO,
        onSubmitNoAutoWO,
        onSubmit,
    };
};
