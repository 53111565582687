import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Display, Panel } from '@ez/components';
import ContactItem from './ContactItem';

import _sortBy from 'lodash/sortBy';

export const lookupSpanFormatter = (contact: NodeType.Contact) => {
    if (!contact.type) return null;

    const { validatorTag = 'default' } = contact.type;
    const lookup = {
        PHONE: Display.Phone,
        EMAIL: Display.Email,
        default: Display.Span,
    };

    const displayFormatter = lookup[validatorTag] || lookup['default'];

    const display = (Tag) => () =>
        (
            <>
                <Tag value={contact.data} />
                {contact.label && <i className={'pl-1 text-tertiary'}>({contact.label})</i>}
            </>
        );

    return display(displayFormatter);
};

export const mapEntityContactsToContactItems = (entityContacts) => {
    return _sortBy(entityContacts, (c) => c?.type?.name).map((contact: NodeType.Contact) => {
        const typeName = contact?.type?.name;
        return new ContactItem({
            contact: contact,
            label: typeName,
            view: lookupSpanFormatter(contact),
        });
    });
};

export const prepareCustomerContactItems = (customer: NodeType.Customer, onlyContacts = false): ContactItem[][] => {
    // Contact items extracted from "Customer" and "User" objects

    let groups = [];
    if (!onlyContacts) {
        const group1 = [
            new ContactItem({
                label: 'CRN',
                view: () => <Display.Span value={customer.crn} />,
            }),

            new ContactItem({
                label: 'Name',
                view: () => <Display.Entity value={customer} />,
            }),
        ];

        if (customer.contactName) {
            group1.push(
                new ContactItem({
                    label: 'Contact Name',
                    view: () => <Display.Span value={customer.contactName} />,
                })
            );
        }
        if (customer.companyName) {
            group1.push(
                new ContactItem({
                    label: 'Company Name',
                    view: () => <Display.Span value={customer.companyName} />,
                })
            );
        }

        const group2 = [
            new ContactItem({
                label: 'Primary Address',
                view: () => <Display.Address value={customer.primaryAddress} showMapLink={true} />,
            }),
        ];

        if (customer.billAddress) {
            group2.push(
                new ContactItem({
                    label: 'Bill Address',
                    view: () => <Display.Address value={customer.billAddress} showMapLink={true} />,
                })
            );
        }
        groups.push(group1);
        groups.push(group2);
    }

    // Contact items extracted from "Entity" object
    const entityContacts = fromEdges(customer?.user?.entity?.contacts);
    const group3 = mapEntityContactsToContactItems(entityContacts);

    if (group3.length > 0) {
        groups.push(group3);
    }
    return groups;
};

export const prepareHeadOfficeContactItems = (headOffice: any): ContactItem[][] => {
    // Contact items extracted from "Customer" and "User" objects

    let groups = [];

    const group2 = [
        new ContactItem({
            label: 'Address',
            view: () => <Display.Address value={headOffice.address} />,
        }),
    ];

    // Contact items extracted from "Entity" object

    let entityContacts = fromEdges<NodeType.Contact>(headOffice?.entity?.contacts);
    entityContacts = _sortBy(entityContacts, (c) => {
        return c?.type?.name;
    });

    const group3 = mapEntityContactsToContactItems(entityContacts);

    groups.push(group2);
    if (group3.length > 0) {
        groups.push(group3);
    }
    return groups;
};

const Contacts: React.FC<{
    as?: any;
    contactItems: ContactItem[][];
}> = ({ as: ItemComponent = Panel.Item, contactItems, ...rest }) => {
    if (!contactItems) return null;

    let lastIndex = contactItems.length - 1;
    return (
        <>
            {contactItems.map((contactItemGroup, groupIndex) => {
                if (!contactItemGroup) return null;
                return (
                    <React.Fragment key={groupIndex}>
                        {contactItemGroup.map((contactItem, index) => {
                            const isPrimary = contactItem?.contact?.isPrimary;
                            return (
                                <ItemComponent
                                    key={index}
                                    {...rest}
                                    label={contactItem.label}
                                    labelInfoIcon={isPrimary ? 'star' : undefined}
                                    labelInfo={isPrimary ? `Primary ${contactItem.label}` : undefined}
                                >
                                    <contactItem.view />
                                </ItemComponent>
                            );
                        })}
                        {lastIndex !== groupIndex && <Panel.Divider />}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default Contacts;
