import * as React from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Icon,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { fromEdges, NodeType } from '@poolware/api';
import { useQueryWorkOrderTemplateConnection } from './use-query-work-order-template-connection';
import { ModuleIconNames } from '../../constants';

export interface ServiceJobTemplateNewProps {}

export const WorkOrderTemplateList: React.FC<ServiceJobTemplateNewProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const { connectionState, connectionData } = useQueryWorkOrderTemplateConnection({ showOwnedTemplates: true });

    const onNew = () => {
        AppNavigator.navigateRelative('/wo-template/new');
    };

    const onViewTemplate = (template: NodeType.ServiceJobTemplate) => {
        AppNavigator.navigateRelative(`/wo-template/${template.id}`);
    };

    const tableDef: ConnectionTableDef<NodeType.WorkOrderTemplate> = [
        {
            header: 'Template',
            cell: (item) => {
                const subItems = fromEdges(item.jobTodoTemplateAssocs).sort((a, b) =>
                    a.priority > b.priority ? 1 : -1
                );
                return (
                    <span>
                        <Icon name={ModuleIconNames.WorkOrder} /> {item.templateTitle}
                        <div style={{ margin: '0.5em' }}>
                            <div className={'grid grid-2 text-sm'}>
                                {subItems.map((assoc) => {
                                    return (
                                        <div className={'flex flex-row'} key={assoc.id}>
                                            <div style={{ paddingLeft: '2em' }}>
                                                <Icon name={ModuleIconNames.WorkOrderItem} /> W/O: {assoc.priority} -{' '}
                                                {assoc.jobTodoTemplate.title}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </span>
                );
            },
        },
        {
            header: 'Owned',
            width: 1,
            cell: (item) => {
                const isOwned = Boolean(item.ownedByServiceJobTemplate?.id);
                if (isOwned) return <Icon name={'linkify'} />;
                else return <>--</>;
            },
        },
    ];

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem>Work Order Templates</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem icon={'plus'} color={'green'} onClick={onNew}>
                        New
                    </MenuBarItem>
                </MenuBarSection>
            </StickyMenuBar>

            <DefaultConnectionTable
                onRowClick={onViewTemplate}
                tableProps={{ color: 'grey' }}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </VStack>
    );
};
