//
// DO NOT EDIT!!!
// THIS IS AN AUTOMATICALLY GENERATED FILE.
// Date:  Fri Jul 15 2022 12:26:35 GMT+1000 (Australian Eastern Standard Time)
//

import { withObjectMutator } from './with-mutator';
import { NodeType } from './graphql-types';

export interface MutationResultFn<T> {
    data?: T;
}

// ************** AbstractDocument ***************** //

// AbstractDocument: Mutator
export const withAbstractDocumentMutator = withObjectMutator({
    objectName: 'AbstractDocument',
    actions: ['delete', 'create', 'update'],
});

// AbstractDocument: Mutator Interface
export interface IMutateAbstractDocument {
    mutateAbstractDocument: {
        delete: (
            input: NodeType.DeleteAbstractDocumentMutationInput[] | NodeType.DeleteAbstractDocumentMutationInput
        ) => Promise<MutationResultFn<{ AbstractDocument: NodeType.AbstractDocumentMutationPayload }>>;
        create: (
            input: NodeType.CreateAbstractDocumentMutationInput[] | NodeType.CreateAbstractDocumentMutationInput
        ) => Promise<MutationResultFn<{ AbstractDocument: NodeType.AbstractDocumentMutationPayload }>>;
        update: (
            input: NodeType.UpdateAbstractDocumentMutationInput[] | NodeType.UpdateAbstractDocumentMutationInput
        ) => Promise<MutationResultFn<{ AbstractDocument: NodeType.AbstractDocumentMutationPayload }>>;
    };
}

// ************** Address ***************** //

// Address: Mutator
export const withAddressMutator = withObjectMutator({
    objectName: 'Address',
    actions: ['create', 'update'],
});

// Address: Mutator Interface
export interface IMutateAddress {
    mutateAddress: {
        create: (
            input: NodeType.CreateAddressMutationInput[] | NodeType.CreateAddressMutationInput
        ) => Promise<MutationResultFn<{ Address: NodeType.AddressMutationPayload }>>;
        update: (
            input: NodeType.UpdateAddressMutationInput[] | NodeType.UpdateAddressMutationInput
        ) => Promise<MutationResultFn<{ Address: NodeType.AddressMutationPayload }>>;
    };
}

// ************** Announcement ***************** //

// Announcement: Mutator
export const withAnnouncementMutator = withObjectMutator({
    objectName: 'Announcement',
    actions: ['delete', 'create', 'update', 'publish', 'unpublish'],
});

// Announcement: Mutator Interface
export interface IMutateAnnouncement {
    mutateAnnouncement: {
        delete: (
            input: NodeType.DeleteAnnouncementMutationInput[] | NodeType.DeleteAnnouncementMutationInput
        ) => Promise<MutationResultFn<{ Announcement: NodeType.AnnouncementMutationPayload }>>;
        create: (
            input: NodeType.CreateAnnouncementMutationInput[] | NodeType.CreateAnnouncementMutationInput
        ) => Promise<MutationResultFn<{ Announcement: NodeType.AnnouncementMutationPayload }>>;
        update: (
            input: NodeType.UpdateAnnouncementMutationInput[] | NodeType.UpdateAnnouncementMutationInput
        ) => Promise<MutationResultFn<{ Announcement: NodeType.AnnouncementMutationPayload }>>;
        publish: (
            input: NodeType.AnnouncementPublishMutationInput[] | NodeType.AnnouncementPublishMutationInput
        ) => Promise<MutationResultFn<{ Announcement: NodeType.AnnouncementMutationPayload }>>;
        unpublish: (
            input: NodeType.AnnouncementUnpublishMutationInput[] | NodeType.AnnouncementUnpublishMutationInput
        ) => Promise<MutationResultFn<{ Announcement: NodeType.AnnouncementMutationPayload }>>;
    };
}

// ************** Appointment ***************** //

// Appointment: Mutator
export const withAppointmentMutator = withObjectMutator({
    objectName: 'Appointment',
    actions: ['delete', 'create', 'update', 'split', 'changeEndDate', 'changeBlockEndDate', 'deleteBlock'],
});

// Appointment: Mutator Interface
export interface IMutateAppointment {
    mutateAppointment: {
        delete: (
            input: NodeType.DeleteAppointmentMutationInput[] | NodeType.DeleteAppointmentMutationInput
        ) => Promise<MutationResultFn<{ Appointment: NodeType.AppointmentMutationPayload }>>;
        create: (
            input: NodeType.CreateAppointmentMutationInput[] | NodeType.CreateAppointmentMutationInput
        ) => Promise<MutationResultFn<{ Appointment: NodeType.AppointmentMutationPayload }>>;
        update: (
            input: NodeType.UpdateAppointmentMutationInput[] | NodeType.UpdateAppointmentMutationInput
        ) => Promise<MutationResultFn<{ Appointment: NodeType.AppointmentMutationPayload }>>;
        split: (
            input: NodeType.AppointmentSplitMutationInput[] | NodeType.AppointmentSplitMutationInput
        ) => Promise<MutationResultFn<{ Appointment: NodeType.AppointmentMutationPayload }>>;
        changeEndDate: (
            input: NodeType.AppointmentChangeEndDateMutationInput[] | NodeType.AppointmentChangeEndDateMutationInput
        ) => Promise<MutationResultFn<{ Appointment: NodeType.AppointmentMutationPayload }>>;
        changeBlockEndDate: (
            input:
                | NodeType.AppointmentChangeBlockEndDateMutationInput[]
                | NodeType.AppointmentChangeBlockEndDateMutationInput
        ) => Promise<MutationResultFn<{ Appointment: NodeType.AppointmentMutationPayload }>>;
        deleteBlock: (
            input: NodeType.AppointmentDeleteBlockMutationInput[] | NodeType.AppointmentDeleteBlockMutationInput
        ) => Promise<MutationResultFn<{ Appointment: NodeType.AppointmentMutationPayload }>>;
    };
}

// ************** AppointmentGroup ***************** //

// AppointmentGroup: Mutator
export const withAppointmentGroupMutator = withObjectMutator({
    objectName: 'AppointmentGroup',
    actions: ['delete', 'create', 'update'],
});

// AppointmentGroup: Mutator Interface
export interface IMutateAppointmentGroup {
    mutateAppointmentGroup: {
        delete: (
            input: NodeType.DeleteAppointmentGroupMutationInput[] | NodeType.DeleteAppointmentGroupMutationInput
        ) => Promise<MutationResultFn<{ AppointmentGroup: NodeType.AppointmentGroupMutationPayload }>>;
        create: (
            input: NodeType.CreateAppointmentGroupMutationInput[] | NodeType.CreateAppointmentGroupMutationInput
        ) => Promise<MutationResultFn<{ AppointmentGroup: NodeType.AppointmentGroupMutationPayload }>>;
        update: (
            input: NodeType.UpdateAppointmentGroupMutationInput[] | NodeType.UpdateAppointmentGroupMutationInput
        ) => Promise<MutationResultFn<{ AppointmentGroup: NodeType.AppointmentGroupMutationPayload }>>;
    };
}

// ************** AppointmentInstance ***************** //

// AppointmentInstance: Mutator
export const withAppointmentInstanceMutator = withObjectMutator({
    objectName: 'AppointmentInstance',
    actions: ['delete', 'update', 'startWorkOrder', 'addSale'],
});

// AppointmentInstance: Mutator Interface
export interface IMutateAppointmentInstance {
    mutateAppointmentInstance: {
        delete: (
            input: NodeType.DeleteAppointmentInstanceMutationInput[] | NodeType.DeleteAppointmentInstanceMutationInput
        ) => Promise<MutationResultFn<{ AppointmentInstance: NodeType.AppointmentInstanceMutationPayload }>>;
        update: (
            input: NodeType.UpdateAppointmentInstanceMutationInput[] | NodeType.UpdateAppointmentInstanceMutationInput
        ) => Promise<MutationResultFn<{ AppointmentInstance: NodeType.AppointmentInstanceMutationPayload }>>;
        startWorkOrder: (
            input:
                | NodeType.AppointmentInstanceStartWorkOrderMutationInput[]
                | NodeType.AppointmentInstanceStartWorkOrderMutationInput
        ) => Promise<MutationResultFn<{ AppointmentInstance: NodeType.AppointmentInstanceMutationPayload }>>;
        addSale: (
            input: NodeType.AppointmentInstanceAddSaleMutationInput[] | NodeType.AppointmentInstanceAddSaleMutationInput
        ) => Promise<MutationResultFn<{ AppointmentInstance: NodeType.AppointmentInstanceMutationPayload }>>;
    };
}

// ************** AppointmentItem ***************** //

// AppointmentItem: Mutator
export const withAppointmentItemMutator = withObjectMutator({
    objectName: 'AppointmentItem',
    actions: [
        'changeRecurrenceFrom',
        'startWorkOrder',
        'updateSingle',
        'updateFuture',
        'changeRecurrence',
        'update',
        'addSale',
        'delete',
    ],
});

// AppointmentItem: Mutator Interface
export interface IMutateAppointmentItem {
    mutateAppointmentItem: {
        changeRecurrenceFrom: (
            input:
                | NodeType.AppointmentItemChangeRecurrenceFromMutationInput[]
                | NodeType.AppointmentItemChangeRecurrenceFromMutationInput
        ) => Promise<MutationResultFn<{ AppointmentItem: NodeType.AppointmentItemMutationPayload }>>;
        startWorkOrder: (
            input:
                | NodeType.AppointmentItemStartWorkOrderMutationInput[]
                | NodeType.AppointmentItemStartWorkOrderMutationInput
        ) => Promise<MutationResultFn<{ AppointmentItem: NodeType.AppointmentItemMutationPayload }>>;
        updateSingle: (
            input:
                | NodeType.AppointmentItemUpdateSingleMutationInput[]
                | NodeType.AppointmentItemUpdateSingleMutationInput
        ) => Promise<MutationResultFn<{ AppointmentItem: NodeType.AppointmentItemMutationPayload }>>;
        updateFuture: (
            input:
                | NodeType.AppointmentItemUpdateFutureMutationInput[]
                | NodeType.AppointmentItemUpdateFutureMutationInput
        ) => Promise<MutationResultFn<{ AppointmentItem: NodeType.AppointmentItemMutationPayload }>>;
        changeRecurrence: (
            input:
                | NodeType.AppointmentItemChangeRecurrenceMutationInput[]
                | NodeType.AppointmentItemChangeRecurrenceMutationInput
        ) => Promise<MutationResultFn<{ AppointmentItem: NodeType.AppointmentItemMutationPayload }>>;
        update: (
            input: NodeType.AppointmentItemUpdateMutationInput[] | NodeType.AppointmentItemUpdateMutationInput
        ) => Promise<MutationResultFn<{ AppointmentItem: NodeType.AppointmentItemMutationPayload }>>;
        addSale: (
            input: NodeType.AppointmentItemAddSaleMutationInput[] | NodeType.AppointmentItemAddSaleMutationInput
        ) => Promise<MutationResultFn<{ AppointmentItem: NodeType.AppointmentItemMutationPayload }>>;
        delete: (
            input: NodeType.AppointmentItemDeleteMutationInput[] | NodeType.AppointmentItemDeleteMutationInput
        ) => Promise<MutationResultFn<{ AppointmentItem: NodeType.AppointmentItemMutationPayload }>>;
    };
}

// ************** Brand ***************** //

// Brand: Mutator
export const withBrandMutator = withObjectMutator({
    objectName: 'Brand',
    actions: ['delete', 'create', 'update'],
});

// Brand: Mutator Interface
export interface IMutateBrand {
    mutateBrand: {
        delete: (
            input: NodeType.DeleteBrandMutationInput[] | NodeType.DeleteBrandMutationInput
        ) => Promise<MutationResultFn<{ Brand: NodeType.BrandMutationPayload }>>;
        create: (
            input: NodeType.CreateBrandMutationInput[] | NodeType.CreateBrandMutationInput
        ) => Promise<MutationResultFn<{ Brand: NodeType.BrandMutationPayload }>>;
        update: (
            input: NodeType.UpdateBrandMutationInput[] | NodeType.UpdateBrandMutationInput
        ) => Promise<MutationResultFn<{ Brand: NodeType.BrandMutationPayload }>>;
    };
}

// ************** ConsumableProduct ***************** //

// ConsumableProduct: Mutator
export const withConsumableProductMutator = withObjectMutator({
    objectName: 'ConsumableProduct',
    actions: ['delete', 'create', 'update'],
});

// ConsumableProduct: Mutator Interface
export interface IMutateConsumableProduct {
    mutateConsumableProduct: {
        delete: (
            input: NodeType.DeleteConsumableProductMutationInput[] | NodeType.DeleteConsumableProductMutationInput
        ) => Promise<MutationResultFn<{ ConsumableProduct: NodeType.ConsumableProductMutationPayload }>>;
        create: (
            input: NodeType.CreateConsumableProductMutationInput[] | NodeType.CreateConsumableProductMutationInput
        ) => Promise<MutationResultFn<{ ConsumableProduct: NodeType.ConsumableProductMutationPayload }>>;
        update: (
            input: NodeType.UpdateConsumableProductMutationInput[] | NodeType.UpdateConsumableProductMutationInput
        ) => Promise<MutationResultFn<{ ConsumableProduct: NodeType.ConsumableProductMutationPayload }>>;
    };
}

// ************** Contact ***************** //

// Contact: Mutator
export const withContactMutator = withObjectMutator({
    objectName: 'Contact',
    actions: ['delete', 'create', 'update', 'makePrimary'],
});

// Contact: Mutator Interface
export interface IMutateContact {
    mutateContact: {
        delete: (
            input: NodeType.DeleteContactMutationInput[] | NodeType.DeleteContactMutationInput
        ) => Promise<MutationResultFn<{ Contact: NodeType.ContactMutationPayload }>>;
        create: (
            input: NodeType.CreateContactMutationInput[] | NodeType.CreateContactMutationInput
        ) => Promise<MutationResultFn<{ Contact: NodeType.ContactMutationPayload }>>;
        update: (
            input: NodeType.UpdateContactMutationInput[] | NodeType.UpdateContactMutationInput
        ) => Promise<MutationResultFn<{ Contact: NodeType.ContactMutationPayload }>>;
        makePrimary: (
            input: NodeType.ContactMakePrimaryMutationInput[] | NodeType.ContactMakePrimaryMutationInput
        ) => Promise<MutationResultFn<{ Contact: NodeType.ContactMutationPayload }>>;
    };
}

// ************** ContactType ***************** //

// ContactType: Mutator
export const withContactTypeMutator = withObjectMutator({
    objectName: 'ContactType',
    actions: ['create', 'update'],
});

// ContactType: Mutator Interface
export interface IMutateContactType {
    mutateContactType: {
        create: (
            input: NodeType.CreateContactTypeMutationInput[] | NodeType.CreateContactTypeMutationInput
        ) => Promise<MutationResultFn<{ ContactType: NodeType.ContactTypeMutationPayload }>>;
        update: (
            input: NodeType.UpdateContactTypeMutationInput[] | NodeType.UpdateContactTypeMutationInput
        ) => Promise<MutationResultFn<{ ContactType: NodeType.ContactTypeMutationPayload }>>;
    };
}

// ************** CustomContract ***************** //

// CustomContract: Mutator
export const withCustomContractMutator = withObjectMutator({
    objectName: 'CustomContract',
    actions: ['delete', 'create', 'update'],
});

// CustomContract: Mutator Interface
export interface IMutateCustomContract {
    mutateCustomContract: {
        delete: (
            input: NodeType.DeleteCustomContractMutationInput[] | NodeType.DeleteCustomContractMutationInput
        ) => Promise<MutationResultFn<{ CustomContract: NodeType.CustomContractMutationPayload }>>;
        create: (
            input: NodeType.CreateCustomContractMutationInput[] | NodeType.CreateCustomContractMutationInput
        ) => Promise<MutationResultFn<{ CustomContract: NodeType.CustomContractMutationPayload }>>;
        update: (
            input: NodeType.UpdateCustomContractMutationInput[] | NodeType.UpdateCustomContractMutationInput
        ) => Promise<MutationResultFn<{ CustomContract: NodeType.CustomContractMutationPayload }>>;
    };
}

// ************** Customer ***************** //

// Customer: Mutator
export const withCustomerMutator = withObjectMutator({
    objectName: 'Customer',
    actions: ['delete', 'create', 'update'],
});

// Customer: Mutator Interface
export interface IMutateCustomer {
    mutateCustomer: {
        delete: (
            input: NodeType.DeleteCustomerMutationInput[] | NodeType.DeleteCustomerMutationInput
        ) => Promise<MutationResultFn<{ Customer: NodeType.CustomerMutationPayload }>>;
        create: (
            input: NodeType.CreateCustomerMutationInput[] | NodeType.CreateCustomerMutationInput
        ) => Promise<MutationResultFn<{ Customer: NodeType.CustomerMutationPayload }>>;
        update: (
            input: NodeType.UpdateCustomerMutationInput[] | NodeType.UpdateCustomerMutationInput
        ) => Promise<MutationResultFn<{ Customer: NodeType.CustomerMutationPayload }>>;
    };
}

// ************** CustomerNote ***************** //

// CustomerNote: Mutator
export const withCustomerNoteMutator = withObjectMutator({
    objectName: 'CustomerNote',
    actions: ['delete', 'create', 'update'],
});

// CustomerNote: Mutator Interface
export interface IMutateCustomerNote {
    mutateCustomerNote: {
        delete: (
            input: NodeType.DeleteCustomerNoteMutationInput[] | NodeType.DeleteCustomerNoteMutationInput
        ) => Promise<MutationResultFn<{ CustomerNote: NodeType.CustomerNoteMutationPayload }>>;
        create: (
            input: NodeType.CreateCustomerNoteMutationInput[] | NodeType.CreateCustomerNoteMutationInput
        ) => Promise<MutationResultFn<{ CustomerNote: NodeType.CustomerNoteMutationPayload }>>;
        update: (
            input: NodeType.UpdateCustomerNoteMutationInput[] | NodeType.UpdateCustomerNoteMutationInput
        ) => Promise<MutationResultFn<{ CustomerNote: NodeType.CustomerNoteMutationPayload }>>;
    };
}

// ************** CustomerTag ***************** //

// CustomerTag: Mutator
export const withCustomerTagMutator = withObjectMutator({
    objectName: 'CustomerTag',
    actions: ['delete', 'create', 'update'],
});

// CustomerTag: Mutator Interface
export interface IMutateCustomerTag {
    mutateCustomerTag: {
        delete: (
            input: NodeType.DeleteCustomerTagMutationInput[] | NodeType.DeleteCustomerTagMutationInput
        ) => Promise<MutationResultFn<{ CustomerTag: NodeType.CustomerTagMutationPayload }>>;
        create: (
            input: NodeType.CreateCustomerTagMutationInput[] | NodeType.CreateCustomerTagMutationInput
        ) => Promise<MutationResultFn<{ CustomerTag: NodeType.CustomerTagMutationPayload }>>;
        update: (
            input: NodeType.UpdateCustomerTagMutationInput[] | NodeType.UpdateCustomerTagMutationInput
        ) => Promise<MutationResultFn<{ CustomerTag: NodeType.CustomerTagMutationPayload }>>;
    };
}

// ************** EmailGlobalSelection ***************** //

// EmailGlobalSelection: Mutator
export const withEmailGlobalSelectionMutator = withObjectMutator({
    objectName: 'EmailGlobalSelection',
    actions: ['delete'],
});

// EmailGlobalSelection: Mutator Interface
export interface IMutateEmailGlobalSelection {
    mutateEmailGlobalSelection: {
        delete: (
            input: NodeType.DeleteEmailGlobalSelectionMutationInput[] | NodeType.DeleteEmailGlobalSelectionMutationInput
        ) => Promise<MutationResultFn<{ EmailGlobalSelection: NodeType.EmailGlobalSelectionMutationPayload }>>;
    };
}

// ************** EmailMessage ***************** //

// EmailMessage: Mutator
export const withEmailMessageMutator = withObjectMutator({
    objectName: 'EmailMessage',
    actions: ['createForReportAndSend'],
});

// EmailMessage: Mutator Interface
export interface IMutateEmailMessage {
    mutateEmailMessage: {
        createForReportAndSend: (
            input:
                | NodeType.EmailMessageCreateForReportAndSendMutationInput[]
                | NodeType.EmailMessageCreateForReportAndSendMutationInput
        ) => Promise<MutationResultFn<{ EmailMessage: NodeType.EmailMessageMutationPayload }>>;
    };
}

// ************** EmailTemplate ***************** //

// EmailTemplate: Mutator
export const withEmailTemplateMutator = withObjectMutator({
    objectName: 'EmailTemplate',
    actions: [
        'delete',
        'create',
        'update',
        'createForSendgrid',
        'selectForFranchise',
        'unselectForFranchise',
        'selectGlobally',
        'unselectGlobally',
    ],
});

// EmailTemplate: Mutator Interface
export interface IMutateEmailTemplate {
    mutateEmailTemplate: {
        delete: (
            input: NodeType.DeleteEmailTemplateMutationInput[] | NodeType.DeleteEmailTemplateMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplate: NodeType.EmailTemplateMutationPayload }>>;
        create: (
            input: NodeType.CreateEmailTemplateMutationInput[] | NodeType.CreateEmailTemplateMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplate: NodeType.EmailTemplateMutationPayload }>>;
        update: (
            input: NodeType.UpdateEmailTemplateMutationInput[] | NodeType.UpdateEmailTemplateMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplate: NodeType.EmailTemplateMutationPayload }>>;
        selectForFranchise: (
            input:
                | NodeType.EmailTemplateSelectForFranchiseMutationInput[]
                | NodeType.EmailTemplateSelectForFranchiseMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplate: NodeType.EmailTemplateMutationPayload }>>;
        unselectForFranchise: (
            input:
                | NodeType.EmailTemplateUnselectForFranchiseMutationInput[]
                | NodeType.EmailTemplateUnselectForFranchiseMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplate: NodeType.EmailTemplateMutationPayload }>>;
        selectGlobally: (
            input:
                | NodeType.EmailTemplateSelectGloballyMutationInput[]
                | NodeType.EmailTemplateSelectGloballyMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplate: NodeType.EmailTemplateMutationPayload }>>;
        unselectGlobally: (
            input:
                | NodeType.EmailTemplateUnselectGloballyMutationInput[]
                | NodeType.EmailTemplateUnselectGloballyMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplate: NodeType.EmailTemplateMutationPayload }>>;
    };
}

// ************** EmailTemplateSelection ***************** //

// EmailTemplateSelection: Mutator
export const withEmailTemplateSelectionMutator = withObjectMutator({
    objectName: 'EmailTemplateSelection',
    actions: ['delete', 'select'],
});

// EmailTemplateSelection: Mutator Interface
export interface IMutateEmailTemplateSelection {
    mutateEmailTemplateSelection: {
        delete: (
            input:
                | NodeType.DeleteEmailTemplateSelectionMutationInput[]
                | NodeType.DeleteEmailTemplateSelectionMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplateSelection: NodeType.EmailTemplateSelectionMutationPayload }>>;
        select: (
            input:
                | NodeType.EmailTemplateSelectionSelectMutationInput[]
                | NodeType.EmailTemplateSelectionSelectMutationInput
        ) => Promise<MutationResultFn<{ EmailTemplateSelection: NodeType.EmailTemplateSelectionMutationPayload }>>;
    };
}

// ************** Entity ***************** //

// Entity: Mutator
export const withEntityMutator = withObjectMutator({
    objectName: 'Entity',
    actions: ['superDelete'],
});

// Entity: Mutator Interface
export interface IMutateEntity {
    mutateEntity: {
        superDelete: (
            input: NodeType.EntitySuperDeleteMutationInput[] | NodeType.EntitySuperDeleteMutationInput
        ) => Promise<MutationResultFn<{ Entity: NodeType.EntityMutationPayload }>>;
    };
}

// ************** FeatureFlag ***************** //

// FeatureFlag: Mutator
export const withFeatureFlagMutator = withObjectMutator({
    objectName: 'FeatureFlag',
    actions: ['delete', 'update'],
});

// FeatureFlag: Mutator Interface
export interface IMutateFeatureFlag {
    mutateFeatureFlag: {
        delete: (
            input: NodeType.DeleteFeatureFlagMutationInput[] | NodeType.DeleteFeatureFlagMutationInput
        ) => Promise<MutationResultFn<{ FeatureFlag: NodeType.FeatureFlagMutationPayload }>>;
        update: (
            input: NodeType.FeatureFlagUpdateMutationInput[] | NodeType.FeatureFlagUpdateMutationInput
        ) => Promise<MutationResultFn<{ FeatureFlag: NodeType.FeatureFlagMutationPayload }>>;
    };
}

// ************** FeatureFlagVariation ***************** //

// FeatureFlagVariation: Mutator
export const withFeatureFlagVariationMutator = withObjectMutator({
    objectName: 'FeatureFlagVariation',
    actions: ['delete', 'create', 'update'],
});

// FeatureFlagVariation: Mutator Interface
export interface IMutateFeatureFlagVariation {
    mutateFeatureFlagVariation: {
        delete: (
            input: NodeType.DeleteFeatureFlagVariationMutationInput[] | NodeType.DeleteFeatureFlagVariationMutationInput
        ) => Promise<MutationResultFn<{ FeatureFlagVariation: NodeType.FeatureFlagVariationMutationPayload }>>;
        create: (
            input: NodeType.CreateFeatureFlagVariationMutationInput[] | NodeType.CreateFeatureFlagVariationMutationInput
        ) => Promise<MutationResultFn<{ FeatureFlagVariation: NodeType.FeatureFlagVariationMutationPayload }>>;
        update: (
            input: NodeType.UpdateFeatureFlagVariationMutationInput[] | NodeType.UpdateFeatureFlagVariationMutationInput
        ) => Promise<MutationResultFn<{ FeatureFlagVariation: NodeType.FeatureFlagVariationMutationPayload }>>;
    };
}

// ************** FileUpload ***************** //

// FileUpload: Mutator
export const withFileUploadMutator = withObjectMutator({
    objectName: 'FileUpload',
    actions: ['delete', 'update'],
});

// FileUpload: Mutator Interface
export interface IMutateFileUpload {
    mutateFileUpload: {
        delete: (
            input: NodeType.DeleteFileUploadMutationInput[] | NodeType.DeleteFileUploadMutationInput
        ) => Promise<MutationResultFn<{ FileUpload: NodeType.FileUploadMutationPayload }>>;
        update: (
            input: NodeType.UpdateFileUploadMutationInput[] | NodeType.UpdateFileUploadMutationInput
        ) => Promise<MutationResultFn<{ FileUpload: NodeType.FileUploadMutationPayload }>>;
    };
}

// ************** Franchise ***************** //

// Franchise: Mutator
export const withFranchiseMutator = withObjectMutator({
    objectName: 'Franchise',
    actions: ['delete', 'create', 'update'],
});

// Franchise: Mutator Interface
export interface IMutateFranchise {
    mutateFranchise: {
        delete: (
            input: NodeType.DeleteFranchiseMutationInput[] | NodeType.DeleteFranchiseMutationInput
        ) => Promise<MutationResultFn<{ Franchise: NodeType.FranchiseMutationPayload }>>;
        create: (
            input: NodeType.CreateFranchiseMutationInput[] | NodeType.CreateFranchiseMutationInput
        ) => Promise<MutationResultFn<{ Franchise: NodeType.FranchiseMutationPayload }>>;
        update: (
            input: NodeType.UpdateFranchiseMutationInput[] | NodeType.UpdateFranchiseMutationInput
        ) => Promise<MutationResultFn<{ Franchise: NodeType.FranchiseMutationPayload }>>;
    };
}

// ************** FranchiseSequenceStore ***************** //

// FranchiseSequenceStore: Mutator
export const withFranchiseSequenceStoreMutator = withObjectMutator({
    objectName: 'FranchiseSequenceStore',
    actions: ['update', 'increment', 'incrementOwn'],
});

// FranchiseSequenceStore: Mutator Interface
export interface IMutateFranchiseSequenceStore {
    mutateFranchiseSequenceStore: {
        update: (
            input:
                | NodeType.UpdateFranchiseSequenceStoreMutationInput[]
                | NodeType.UpdateFranchiseSequenceStoreMutationInput
        ) => Promise<MutationResultFn<{ FranchiseSequenceStore: NodeType.FranchiseSequenceStoreMutationPayload }>>;
        increment: (
            input:
                | NodeType.FranchiseSequenceStoreIncrementMutationInput[]
                | NodeType.FranchiseSequenceStoreIncrementMutationInput
        ) => Promise<MutationResultFn<{ FranchiseSequenceStore: NodeType.FranchiseSequenceStoreMutationPayload }>>;
        incrementOwn: (
            input:
                | NodeType.FranchiseSequenceStoreIncrementOwnMutationInput[]
                | NodeType.FranchiseSequenceStoreIncrementOwnMutationInput
        ) => Promise<MutationResultFn<{ FranchiseSequenceStore: NodeType.FranchiseSequenceStoreMutationPayload }>>;
    };
}

// ************** FranchiseShop ***************** //

// FranchiseShop: Mutator
export const withFranchiseShopMutator = withObjectMutator({
    objectName: 'FranchiseShop',
    actions: ['delete', 'create', 'update'],
});

// FranchiseShop: Mutator Interface
export interface IMutateFranchiseShop {
    mutateFranchiseShop: {
        delete: (
            input: NodeType.DeleteFranchiseShopMutationInput[] | NodeType.DeleteFranchiseShopMutationInput
        ) => Promise<MutationResultFn<{ FranchiseShop: NodeType.FranchiseShopMutationPayload }>>;
        create: (
            input: NodeType.CreateFranchiseShopMutationInput[] | NodeType.CreateFranchiseShopMutationInput
        ) => Promise<MutationResultFn<{ FranchiseShop: NodeType.FranchiseShopMutationPayload }>>;
        update: (
            input: NodeType.UpdateFranchiseShopMutationInput[] | NodeType.UpdateFranchiseShopMutationInput
        ) => Promise<MutationResultFn<{ FranchiseShop: NodeType.FranchiseShopMutationPayload }>>;
    };
}

// ************** FranchiseTestTreatmentView ***************** //

// FranchiseTestTreatmentView: Mutator
export const withFranchiseTestTreatmentViewMutator = withObjectMutator({
    objectName: 'FranchiseTestTreatmentView',
    actions: ['setBlacklist', 'setLocalPriority'],
});

// FranchiseTestTreatmentView: Mutator Interface
export interface IMutateFranchiseTestTreatmentView {
    mutateFranchiseTestTreatmentView: {
        setBlacklist: (
            input:
                | NodeType.FranchiseTestTreatmentViewSetBlacklistMutationInput[]
                | NodeType.FranchiseTestTreatmentViewSetBlacklistMutationInput
        ) => Promise<
            MutationResultFn<{ FranchiseTestTreatmentView: NodeType.FranchiseTestTreatmentViewMutationPayload }>
        >;
        setLocalPriority: (
            input:
                | NodeType.FranchiseTestTreatmentViewSetLocalPriorityMutationInput[]
                | NodeType.FranchiseTestTreatmentViewSetLocalPriorityMutationInput
        ) => Promise<
            MutationResultFn<{ FranchiseTestTreatmentView: NodeType.FranchiseTestTreatmentViewMutationPayload }>
        >;
    };
}

// ************** FranchiseValueStore ***************** //

// FranchiseValueStore: Mutator
export const withFranchiseValueStoreMutator = withObjectMutator({
    objectName: 'FranchiseValueStore',
    actions: ['delete', 'update', 'set', 'setOwn'],
});

// FranchiseValueStore: Mutator Interface
export interface IMutateFranchiseValueStore {
    mutateFranchiseValueStore: {
        delete: (
            input: NodeType.DeleteFranchiseValueStoreMutationInput[] | NodeType.DeleteFranchiseValueStoreMutationInput
        ) => Promise<MutationResultFn<{ FranchiseValueStore: NodeType.FranchiseValueStoreMutationPayload }>>;
        update: (
            input: NodeType.UpdateFranchiseValueStoreMutationInput[] | NodeType.UpdateFranchiseValueStoreMutationInput
        ) => Promise<MutationResultFn<{ FranchiseValueStore: NodeType.FranchiseValueStoreMutationPayload }>>;
        set: (
            input: NodeType.FranchiseValueStoreSetMutationInput[] | NodeType.FranchiseValueStoreSetMutationInput
        ) => Promise<MutationResultFn<{ FranchiseValueStore: NodeType.FranchiseValueStoreMutationPayload }>>;
        setOwn: (
            input: NodeType.FranchiseValueStoreSetOwnMutationInput[] | NodeType.FranchiseValueStoreSetOwnMutationInput
        ) => Promise<MutationResultFn<{ FranchiseValueStore: NodeType.FranchiseValueStoreMutationPayload }>>;
    };
}

// ************** FranchiseWarranter ***************** //

// FranchiseWarranter: Mutator
export const withFranchiseWarranterMutator = withObjectMutator({
    objectName: 'FranchiseWarranter',
    actions: ['delete', 'create', 'update'],
});

// FranchiseWarranter: Mutator Interface
export interface IMutateFranchiseWarranter {
    mutateFranchiseWarranter: {
        delete: (
            input: NodeType.DeleteFranchiseWarranterMutationInput[] | NodeType.DeleteFranchiseWarranterMutationInput
        ) => Promise<MutationResultFn<{ FranchiseWarranter: NodeType.FranchiseWarranterMutationPayload }>>;
        create: (
            input: NodeType.CreateFranchiseWarranterMutationInput[] | NodeType.CreateFranchiseWarranterMutationInput
        ) => Promise<MutationResultFn<{ FranchiseWarranter: NodeType.FranchiseWarranterMutationPayload }>>;
        update: (
            input: NodeType.UpdateFranchiseWarranterMutationInput[] | NodeType.UpdateFranchiseWarranterMutationInput
        ) => Promise<MutationResultFn<{ FranchiseWarranter: NodeType.FranchiseWarranterMutationPayload }>>;
    };
}

// ************** HeaterRecommendation ***************** //

// HeaterRecommendation: Mutator
export const withHeaterRecommendationMutator = withObjectMutator({
    objectName: 'HeaterRecommendation',
    actions: ['delete', 'create', 'update'],
});

// HeaterRecommendation: Mutator Interface
export interface IMutateHeaterRecommendation {
    mutateHeaterRecommendation: {
        delete: (
            input: NodeType.DeleteHeaterRecommendationMutationInput[] | NodeType.DeleteHeaterRecommendationMutationInput
        ) => Promise<MutationResultFn<{ HeaterRecommendation: NodeType.HeaterRecommendationMutationPayload }>>;
        create: (
            input: NodeType.CreateHeaterRecommendationMutationInput[] | NodeType.CreateHeaterRecommendationMutationInput
        ) => Promise<MutationResultFn<{ HeaterRecommendation: NodeType.HeaterRecommendationMutationPayload }>>;
        update: (
            input: NodeType.UpdateHeaterRecommendationMutationInput[] | NodeType.UpdateHeaterRecommendationMutationInput
        ) => Promise<MutationResultFn<{ HeaterRecommendation: NodeType.HeaterRecommendationMutationPayload }>>;
    };
}

// ************** InstallableProduct ***************** //

// InstallableProduct: Mutator
export const withInstallableProductMutator = withObjectMutator({
    objectName: 'InstallableProduct',
    actions: ['delete', 'create', 'update'],
});

// InstallableProduct: Mutator Interface
export interface IMutateInstallableProduct {
    mutateInstallableProduct: {
        delete: (
            input: NodeType.DeleteInstallableProductMutationInput[] | NodeType.DeleteInstallableProductMutationInput
        ) => Promise<MutationResultFn<{ InstallableProduct: NodeType.InstallableProductMutationPayload }>>;
        create: (
            input: NodeType.CreateInstallableProductMutationInput[] | NodeType.CreateInstallableProductMutationInput
        ) => Promise<MutationResultFn<{ InstallableProduct: NodeType.InstallableProductMutationPayload }>>;
        update: (
            input: NodeType.UpdateInstallableProductMutationInput[] | NodeType.UpdateInstallableProductMutationInput
        ) => Promise<MutationResultFn<{ InstallableProduct: NodeType.InstallableProductMutationPayload }>>;
    };
}

// ************** Item ***************** //

// Item: Mutator
export const withItemMutator = withObjectMutator({
    objectName: 'Item',
    actions: ['delete', 'create', 'update'],
});

// Item: Mutator Interface
export interface IMutateItem {
    mutateItem: {
        delete: (
            input: NodeType.DeleteItemMutationInput[] | NodeType.DeleteItemMutationInput
        ) => Promise<MutationResultFn<{ Item: NodeType.ItemMutationPayload }>>;
        create: (
            input: NodeType.CreateItemMutationInput[] | NodeType.CreateItemMutationInput
        ) => Promise<MutationResultFn<{ Item: NodeType.ItemMutationPayload }>>;
        update: (
            input: NodeType.UpdateItemMutationInput[] | NodeType.UpdateItemMutationInput
        ) => Promise<MutationResultFn<{ Item: NodeType.ItemMutationPayload }>>;
    };
}

// ************** JobTodo ***************** //

// JobTodo: Mutator
export const withJobTodoMutator = withObjectMutator({
    objectName: 'JobTodo',
    actions: ['delete', 'create', 'update', 'createFromTemplate'],
});

// JobTodo: Mutator Interface
export interface IMutateJobTodo {
    mutateJobTodo: {
        delete: (
            input: NodeType.DeleteJobTodoMutationInput[] | NodeType.DeleteJobTodoMutationInput
        ) => Promise<MutationResultFn<{ JobTodo: NodeType.JobTodoMutationPayload }>>;
        create: (
            input: NodeType.CreateJobTodoMutationInput[] | NodeType.CreateJobTodoMutationInput
        ) => Promise<MutationResultFn<{ JobTodo: NodeType.JobTodoMutationPayload }>>;
        update: (
            input: NodeType.UpdateJobTodoMutationInput[] | NodeType.UpdateJobTodoMutationInput
        ) => Promise<MutationResultFn<{ JobTodo: NodeType.JobTodoMutationPayload }>>;
        createFromTemplate: (
            input: NodeType.JobTodoCreateFromTemplateMutationInput[] | NodeType.JobTodoCreateFromTemplateMutationInput
        ) => Promise<MutationResultFn<{ JobTodo: NodeType.JobTodoMutationPayload }>>;
    };
}

// ************** JobTodoItem ***************** //

// JobTodoItem: Mutator
export const withJobTodoItemMutator = withObjectMutator({
    objectName: 'JobTodoItem',
    actions: ['delete', 'create', 'update'],
});

// JobTodoItem: Mutator Interface
export interface IMutateJobTodoItem {
    mutateJobTodoItem: {
        delete: (
            input: NodeType.DeleteJobTodoItemMutationInput[] | NodeType.DeleteJobTodoItemMutationInput
        ) => Promise<MutationResultFn<{ JobTodoItem: NodeType.JobTodoItemMutationPayload }>>;
        create: (
            input: NodeType.CreateJobTodoItemMutationInput[] | NodeType.CreateJobTodoItemMutationInput
        ) => Promise<MutationResultFn<{ JobTodoItem: NodeType.JobTodoItemMutationPayload }>>;
        update: (
            input: NodeType.UpdateJobTodoItemMutationInput[] | NodeType.UpdateJobTodoItemMutationInput
        ) => Promise<MutationResultFn<{ JobTodoItem: NodeType.JobTodoItemMutationPayload }>>;
    };
}

// ************** JobTodoTemplate ***************** //

// JobTodoTemplate: Mutator
export const withJobTodoTemplateMutator = withObjectMutator({
    objectName: 'JobTodoTemplate',
    actions: ['delete', 'create', 'update', 'archive'],
});

// JobTodoTemplate: Mutator Interface
export interface IMutateJobTodoTemplate {
    mutateJobTodoTemplate: {
        delete: (
            input: NodeType.DeleteJobTodoTemplateMutationInput[] | NodeType.DeleteJobTodoTemplateMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplate: NodeType.JobTodoTemplateMutationPayload }>>;
        create: (
            input: NodeType.CreateJobTodoTemplateMutationInput[] | NodeType.CreateJobTodoTemplateMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplate: NodeType.JobTodoTemplateMutationPayload }>>;
        update: (
            input: NodeType.UpdateJobTodoTemplateMutationInput[] | NodeType.UpdateJobTodoTemplateMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplate: NodeType.JobTodoTemplateMutationPayload }>>;
        archive: (
            input: NodeType.JobTodoTemplateArchiveMutationInput[] | NodeType.JobTodoTemplateArchiveMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplate: NodeType.JobTodoTemplateMutationPayload }>>;
    };
}

// ************** JobTodoTemplateAssoc ***************** //

// JobTodoTemplateAssoc: Mutator
export const withJobTodoTemplateAssocMutator = withObjectMutator({
    objectName: 'JobTodoTemplateAssoc',
    actions: ['delete', 'create', 'update'],
});

// JobTodoTemplateAssoc: Mutator Interface
export interface IMutateJobTodoTemplateAssoc {
    mutateJobTodoTemplateAssoc: {
        delete: (
            input: NodeType.DeleteJobTodoTemplateAssocMutationInput[] | NodeType.DeleteJobTodoTemplateAssocMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplateAssoc: NodeType.JobTodoTemplateAssocMutationPayload }>>;
        create: (
            input: NodeType.CreateJobTodoTemplateAssocMutationInput[] | NodeType.CreateJobTodoTemplateAssocMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplateAssoc: NodeType.JobTodoTemplateAssocMutationPayload }>>;
        update: (
            input: NodeType.UpdateJobTodoTemplateAssocMutationInput[] | NodeType.UpdateJobTodoTemplateAssocMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplateAssoc: NodeType.JobTodoTemplateAssocMutationPayload }>>;
    };
}

// ************** JobTodoTemplateItem ***************** //

// JobTodoTemplateItem: Mutator
export const withJobTodoTemplateItemMutator = withObjectMutator({
    objectName: 'JobTodoTemplateItem',
    actions: ['delete', 'create', 'update'],
});

// JobTodoTemplateItem: Mutator Interface
export interface IMutateJobTodoTemplateItem {
    mutateJobTodoTemplateItem: {
        delete: (
            input: NodeType.DeleteJobTodoTemplateItemMutationInput[] | NodeType.DeleteJobTodoTemplateItemMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplateItem: NodeType.JobTodoTemplateItemMutationPayload }>>;
        create: (
            input: NodeType.CreateJobTodoTemplateItemMutationInput[] | NodeType.CreateJobTodoTemplateItemMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplateItem: NodeType.JobTodoTemplateItemMutationPayload }>>;
        update: (
            input: NodeType.UpdateJobTodoTemplateItemMutationInput[] | NodeType.UpdateJobTodoTemplateItemMutationInput
        ) => Promise<MutationResultFn<{ JobTodoTemplateItem: NodeType.JobTodoTemplateItemMutationPayload }>>;
    };
}

// ************** ManualProblem ***************** //

// ManualProblem: Mutator
export const withManualProblemMutator = withObjectMutator({
    objectName: 'ManualProblem',
    actions: ['delete', 'create', 'update'],
});

// ManualProblem: Mutator Interface
export interface IMutateManualProblem {
    mutateManualProblem: {
        delete: (
            input: NodeType.DeleteManualProblemMutationInput[] | NodeType.DeleteManualProblemMutationInput
        ) => Promise<MutationResultFn<{ ManualProblem: NodeType.ManualProblemMutationPayload }>>;
        create: (
            input: NodeType.CreateManualProblemMutationInput[] | NodeType.CreateManualProblemMutationInput
        ) => Promise<MutationResultFn<{ ManualProblem: NodeType.ManualProblemMutationPayload }>>;
        update: (
            input: NodeType.UpdateManualProblemMutationInput[] | NodeType.UpdateManualProblemMutationInput
        ) => Promise<MutationResultFn<{ ManualProblem: NodeType.ManualProblemMutationPayload }>>;
    };
}

// ************** Measurement ***************** //

// Measurement: Mutator
export const withMeasurementMutator = withObjectMutator({
    objectName: 'Measurement',
    actions: ['delete', 'create', 'update'],
});

// Measurement: Mutator Interface
export interface IMutateMeasurement {
    mutateMeasurement: {
        delete: (
            input: NodeType.DeleteMeasurementMutationInput[] | NodeType.DeleteMeasurementMutationInput
        ) => Promise<MutationResultFn<{ Measurement: NodeType.MeasurementMutationPayload }>>;
        create: (
            input: NodeType.CreateMeasurementMutationInput[] | NodeType.CreateMeasurementMutationInput
        ) => Promise<MutationResultFn<{ Measurement: NodeType.MeasurementMutationPayload }>>;
        update: (
            input: NodeType.UpdateMeasurementMutationInput[] | NodeType.UpdateMeasurementMutationInput
        ) => Promise<MutationResultFn<{ Measurement: NodeType.MeasurementMutationPayload }>>;
    };
}

// ************** Permission ***************** //

// Permission: Mutator
export const withPermissionMutator = withObjectMutator({
    objectName: 'Permission',
    actions: ['create', 'update'],
});

// Permission: Mutator Interface
export interface IMutatePermission {
    mutatePermission: {
        create: (
            input: NodeType.CreatePermissionMutationInput[] | NodeType.CreatePermissionMutationInput
        ) => Promise<MutationResultFn<{ Permission: NodeType.PermissionMutationPayload }>>;
        update: (
            input: NodeType.UpdatePermissionMutationInput[] | NodeType.UpdatePermissionMutationInput
        ) => Promise<MutationResultFn<{ Permission: NodeType.PermissionMutationPayload }>>;
    };
}

// ************** Pool ***************** //

// Pool: Mutator
export const withPoolMutator = withObjectMutator({
    objectName: 'Pool',
    actions: ['delete', 'create', 'update'],
});

// Pool: Mutator Interface
export interface IMutatePool {
    mutatePool: {
        delete: (
            input: NodeType.DeletePoolMutationInput[] | NodeType.DeletePoolMutationInput
        ) => Promise<MutationResultFn<{ Pool: NodeType.PoolMutationPayload }>>;
        create: (
            input: NodeType.CreatePoolMutationInput[] | NodeType.CreatePoolMutationInput
        ) => Promise<MutationResultFn<{ Pool: NodeType.PoolMutationPayload }>>;
        update: (
            input: NodeType.UpdatePoolMutationInput[] | NodeType.UpdatePoolMutationInput
        ) => Promise<MutationResultFn<{ Pool: NodeType.PoolMutationPayload }>>;
    };
}

// ************** PoolBottle ***************** //

// PoolBottle: Mutator
export const withPoolBottleMutator = withObjectMutator({
    objectName: 'PoolBottle',
    actions: ['create', 'processReturn'],
});

// PoolBottle: Mutator Interface
export interface IMutatePoolBottle {
    mutatePoolBottle: {
        create: (
            input: NodeType.CreatePoolBottleMutationInput[] | NodeType.CreatePoolBottleMutationInput
        ) => Promise<MutationResultFn<{ PoolBottle: NodeType.PoolBottleMutationPayload }>>;
        processReturn: (
            input: NodeType.PoolBottleProcessReturnMutationInput[] | NodeType.PoolBottleProcessReturnMutationInput
        ) => Promise<MutationResultFn<{ PoolBottle: NodeType.PoolBottleMutationPayload }>>;
    };
}

// ************** PoolBottleLease ***************** //

// PoolBottleLease: Mutator
export const withPoolBottleLeaseMutator = withObjectMutator({
    objectName: 'PoolBottleLease',
    actions: ['issueLease', 'processReturn'],
});

// PoolBottleLease: Mutator Interface
export interface IMutatePoolBottleLease {
    mutatePoolBottleLease: {
        issueLease: (
            input: NodeType.PoolBottleLeaseIssueLeaseMutationInput[] | NodeType.PoolBottleLeaseIssueLeaseMutationInput
        ) => Promise<MutationResultFn<{ PoolBottleLease: NodeType.PoolBottleLeaseMutationPayload }>>;
        processReturn: (
            input:
                | NodeType.PoolBottleLeaseProcessReturnMutationInput[]
                | NodeType.PoolBottleLeaseProcessReturnMutationInput
        ) => Promise<MutationResultFn<{ PoolBottleLease: NodeType.PoolBottleLeaseMutationPayload }>>;
    };
}

// ************** PoolCalibration ***************** //

// PoolCalibration: Mutator
export const withPoolCalibrationMutator = withObjectMutator({
    objectName: 'PoolCalibration',
    actions: ['delete', 'create', 'update'],
});

// PoolCalibration: Mutator Interface
export interface IMutatePoolCalibration {
    mutatePoolCalibration: {
        delete: (
            input: NodeType.DeletePoolCalibrationMutationInput[] | NodeType.DeletePoolCalibrationMutationInput
        ) => Promise<MutationResultFn<{ PoolCalibration: NodeType.PoolCalibrationMutationPayload }>>;
        create: (
            input: NodeType.CreatePoolCalibrationMutationInput[] | NodeType.CreatePoolCalibrationMutationInput
        ) => Promise<MutationResultFn<{ PoolCalibration: NodeType.PoolCalibrationMutationPayload }>>;
        update: (
            input: NodeType.UpdatePoolCalibrationMutationInput[] | NodeType.UpdatePoolCalibrationMutationInput
        ) => Promise<MutationResultFn<{ PoolCalibration: NodeType.PoolCalibrationMutationPayload }>>;
    };
}

// ************** PoolNote ***************** //

// PoolNote: Mutator
export const withPoolNoteMutator = withObjectMutator({
    objectName: 'PoolNote',
    actions: ['delete', 'create', 'update'],
});

// PoolNote: Mutator Interface
export interface IMutatePoolNote {
    mutatePoolNote: {
        delete: (
            input: NodeType.DeletePoolNoteMutationInput[] | NodeType.DeletePoolNoteMutationInput
        ) => Promise<MutationResultFn<{ PoolNote: NodeType.PoolNoteMutationPayload }>>;
        create: (
            input: NodeType.CreatePoolNoteMutationInput[] | NodeType.CreatePoolNoteMutationInput
        ) => Promise<MutationResultFn<{ PoolNote: NodeType.PoolNoteMutationPayload }>>;
        update: (
            input: NodeType.UpdatePoolNoteMutationInput[] | NodeType.UpdatePoolNoteMutationInput
        ) => Promise<MutationResultFn<{ PoolNote: NodeType.PoolNoteMutationPayload }>>;
    };
}

// ************** PoolProductDeclaration ***************** //

// PoolProductDeclaration: Mutator
export const withPoolProductDeclarationMutator = withObjectMutator({
    objectName: 'PoolProductDeclaration',
    actions: ['delete', 'create', 'update'],
});

// PoolProductDeclaration: Mutator Interface
export interface IMutatePoolProductDeclaration {
    mutatePoolProductDeclaration: {
        delete: (
            input:
                | NodeType.DeletePoolProductDeclarationMutationInput[]
                | NodeType.DeletePoolProductDeclarationMutationInput
        ) => Promise<MutationResultFn<{ PoolProductDeclaration: NodeType.PoolProductDeclarationMutationPayload }>>;
        create: (
            input:
                | NodeType.CreatePoolProductDeclarationMutationInput[]
                | NodeType.CreatePoolProductDeclarationMutationInput
        ) => Promise<MutationResultFn<{ PoolProductDeclaration: NodeType.PoolProductDeclarationMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdatePoolProductDeclarationMutationInput[]
                | NodeType.UpdatePoolProductDeclarationMutationInput
        ) => Promise<MutationResultFn<{ PoolProductDeclaration: NodeType.PoolProductDeclarationMutationPayload }>>;
    };
}

// ************** PoolType ***************** //

// PoolType: Mutator
export const withPoolTypeMutator = withObjectMutator({
    objectName: 'PoolType',
    actions: ['delete', 'create', 'update'],
});

// PoolType: Mutator Interface
export interface IMutatePoolType {
    mutatePoolType: {
        delete: (
            input: NodeType.DeletePoolTypeMutationInput[] | NodeType.DeletePoolTypeMutationInput
        ) => Promise<MutationResultFn<{ PoolType: NodeType.PoolTypeMutationPayload }>>;
        create: (
            input: NodeType.CreatePoolTypeMutationInput[] | NodeType.CreatePoolTypeMutationInput
        ) => Promise<MutationResultFn<{ PoolType: NodeType.PoolTypeMutationPayload }>>;
        update: (
            input: NodeType.UpdatePoolTypeMutationInput[] | NodeType.UpdatePoolTypeMutationInput
        ) => Promise<MutationResultFn<{ PoolType: NodeType.PoolTypeMutationPayload }>>;
    };
}

// ************** Product ***************** //

// Product: Mutator
export const withProductMutator = withObjectMutator({
    objectName: 'Product',
    actions: ['delete', 'create', 'update', 'retire', 'unretire'],
});

// Product: Mutator Interface
export interface IMutateProduct {
    mutateProduct: {
        delete: (
            input: NodeType.DeleteProductMutationInput[] | NodeType.DeleteProductMutationInput
        ) => Promise<MutationResultFn<{ Product: NodeType.ProductMutationPayload }>>;
        create: (
            input: NodeType.CreateProductMutationInput[] | NodeType.CreateProductMutationInput
        ) => Promise<MutationResultFn<{ Product: NodeType.ProductMutationPayload }>>;
        update: (
            input: NodeType.UpdateProductMutationInput[] | NodeType.UpdateProductMutationInput
        ) => Promise<MutationResultFn<{ Product: NodeType.ProductMutationPayload }>>;
        retire: (
            input: NodeType.ProductRetireMutationInput[] | NodeType.ProductRetireMutationInput
        ) => Promise<MutationResultFn<{ Product: NodeType.ProductMutationPayload }>>;
        unretire: (
            input: NodeType.ProductUnretireMutationInput[] | NodeType.ProductUnretireMutationInput
        ) => Promise<MutationResultFn<{ Product: NodeType.ProductMutationPayload }>>;
    };
}

// ************** ProductCompany ***************** //

// ProductCompany: Mutator
export const withProductCompanyMutator = withObjectMutator({
    objectName: 'ProductCompany',
    actions: ['delete', 'create', 'update'],
});

// ProductCompany: Mutator Interface
export interface IMutateProductCompany {
    mutateProductCompany: {
        delete: (
            input: NodeType.DeleteProductCompanyMutationInput[] | NodeType.DeleteProductCompanyMutationInput
        ) => Promise<MutationResultFn<{ ProductCompany: NodeType.ProductCompanyMutationPayload }>>;
        create: (
            input: NodeType.CreateProductCompanyMutationInput[] | NodeType.CreateProductCompanyMutationInput
        ) => Promise<MutationResultFn<{ ProductCompany: NodeType.ProductCompanyMutationPayload }>>;
        update: (
            input: NodeType.UpdateProductCompanyMutationInput[] | NodeType.UpdateProductCompanyMutationInput
        ) => Promise<MutationResultFn<{ ProductCompany: NodeType.ProductCompanyMutationPayload }>>;
    };
}

// ************** ProductDeclarationFlagField ***************** //

// ProductDeclarationFlagField: Mutator
export const withProductDeclarationFlagFieldMutator = withObjectMutator({
    objectName: 'ProductDeclarationFlagField',
    actions: ['delete', 'create', 'update'],
});

// ProductDeclarationFlagField: Mutator Interface
export interface IMutateProductDeclarationFlagField {
    mutateProductDeclarationFlagField: {
        delete: (
            input:
                | NodeType.DeleteProductDeclarationFlagFieldMutationInput[]
                | NodeType.DeleteProductDeclarationFlagFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationFlagField: NodeType.ProductDeclarationFlagFieldMutationPayload }>
        >;
        create: (
            input:
                | NodeType.CreateProductDeclarationFlagFieldMutationInput[]
                | NodeType.CreateProductDeclarationFlagFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationFlagField: NodeType.ProductDeclarationFlagFieldMutationPayload }>
        >;
        update: (
            input:
                | NodeType.UpdateProductDeclarationFlagFieldMutationInput[]
                | NodeType.UpdateProductDeclarationFlagFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationFlagField: NodeType.ProductDeclarationFlagFieldMutationPayload }>
        >;
    };
}

// ************** ProductDeclarationFloatField ***************** //

// ProductDeclarationFloatField: Mutator
export const withProductDeclarationFloatFieldMutator = withObjectMutator({
    objectName: 'ProductDeclarationFloatField',
    actions: ['delete', 'create', 'update'],
});

// ProductDeclarationFloatField: Mutator Interface
export interface IMutateProductDeclarationFloatField {
    mutateProductDeclarationFloatField: {
        delete: (
            input:
                | NodeType.DeleteProductDeclarationFloatFieldMutationInput[]
                | NodeType.DeleteProductDeclarationFloatFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationFloatField: NodeType.ProductDeclarationFloatFieldMutationPayload }>
        >;
        create: (
            input:
                | NodeType.CreateProductDeclarationFloatFieldMutationInput[]
                | NodeType.CreateProductDeclarationFloatFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationFloatField: NodeType.ProductDeclarationFloatFieldMutationPayload }>
        >;
        update: (
            input:
                | NodeType.UpdateProductDeclarationFloatFieldMutationInput[]
                | NodeType.UpdateProductDeclarationFloatFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationFloatField: NodeType.ProductDeclarationFloatFieldMutationPayload }>
        >;
    };
}

// ************** ProductDeclarationIntegerField ***************** //

// ProductDeclarationIntegerField: Mutator
export const withProductDeclarationIntegerFieldMutator = withObjectMutator({
    objectName: 'ProductDeclarationIntegerField',
    actions: ['delete', 'create', 'update'],
});

// ProductDeclarationIntegerField: Mutator Interface
export interface IMutateProductDeclarationIntegerField {
    mutateProductDeclarationIntegerField: {
        delete: (
            input:
                | NodeType.DeleteProductDeclarationIntegerFieldMutationInput[]
                | NodeType.DeleteProductDeclarationIntegerFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationIntegerField: NodeType.ProductDeclarationIntegerFieldMutationPayload }>
        >;
        create: (
            input:
                | NodeType.CreateProductDeclarationIntegerFieldMutationInput[]
                | NodeType.CreateProductDeclarationIntegerFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationIntegerField: NodeType.ProductDeclarationIntegerFieldMutationPayload }>
        >;
        update: (
            input:
                | NodeType.UpdateProductDeclarationIntegerFieldMutationInput[]
                | NodeType.UpdateProductDeclarationIntegerFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationIntegerField: NodeType.ProductDeclarationIntegerFieldMutationPayload }>
        >;
    };
}

// ************** ProductDeclarationMeasurementField ***************** //

// ProductDeclarationMeasurementField: Mutator
export const withProductDeclarationMeasurementFieldMutator = withObjectMutator({
    objectName: 'ProductDeclarationMeasurementField',
    actions: ['delete', 'create', 'update'],
});

// ProductDeclarationMeasurementField: Mutator Interface
export interface IMutateProductDeclarationMeasurementField {
    mutateProductDeclarationMeasurementField: {
        delete: (
            input:
                | NodeType.DeleteProductDeclarationMeasurementFieldMutationInput[]
                | NodeType.DeleteProductDeclarationMeasurementFieldMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationMeasurementField: NodeType.ProductDeclarationMeasurementFieldMutationPayload;
            }>
        >;
        create: (
            input:
                | NodeType.CreateProductDeclarationMeasurementFieldMutationInput[]
                | NodeType.CreateProductDeclarationMeasurementFieldMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationMeasurementField: NodeType.ProductDeclarationMeasurementFieldMutationPayload;
            }>
        >;
        update: (
            input:
                | NodeType.UpdateProductDeclarationMeasurementFieldMutationInput[]
                | NodeType.UpdateProductDeclarationMeasurementFieldMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationMeasurementField: NodeType.ProductDeclarationMeasurementFieldMutationPayload;
            }>
        >;
    };
}

// ************** ProductDeclarationSelectionChoice ***************** //

// ProductDeclarationSelectionChoice: Mutator
export const withProductDeclarationSelectionChoiceMutator = withObjectMutator({
    objectName: 'ProductDeclarationSelectionChoice',
    actions: ['delete', 'create', 'update'],
});

// ProductDeclarationSelectionChoice: Mutator Interface
export interface IMutateProductDeclarationSelectionChoice {
    mutateProductDeclarationSelectionChoice: {
        delete: (
            input:
                | NodeType.DeleteProductDeclarationSelectionChoiceMutationInput[]
                | NodeType.DeleteProductDeclarationSelectionChoiceMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationSelectionChoice: NodeType.ProductDeclarationSelectionChoiceMutationPayload;
            }>
        >;
        create: (
            input:
                | NodeType.CreateProductDeclarationSelectionChoiceMutationInput[]
                | NodeType.CreateProductDeclarationSelectionChoiceMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationSelectionChoice: NodeType.ProductDeclarationSelectionChoiceMutationPayload;
            }>
        >;
        update: (
            input:
                | NodeType.UpdateProductDeclarationSelectionChoiceMutationInput[]
                | NodeType.UpdateProductDeclarationSelectionChoiceMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationSelectionChoice: NodeType.ProductDeclarationSelectionChoiceMutationPayload;
            }>
        >;
    };
}

// ************** ProductDeclarationSelectionField ***************** //

// ProductDeclarationSelectionField: Mutator
export const withProductDeclarationSelectionFieldMutator = withObjectMutator({
    objectName: 'ProductDeclarationSelectionField',
    actions: ['delete', 'create', 'update'],
});

// ProductDeclarationSelectionField: Mutator Interface
export interface IMutateProductDeclarationSelectionField {
    mutateProductDeclarationSelectionField: {
        delete: (
            input:
                | NodeType.DeleteProductDeclarationSelectionFieldMutationInput[]
                | NodeType.DeleteProductDeclarationSelectionFieldMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationSelectionField: NodeType.ProductDeclarationSelectionFieldMutationPayload;
            }>
        >;
        create: (
            input:
                | NodeType.CreateProductDeclarationSelectionFieldMutationInput[]
                | NodeType.CreateProductDeclarationSelectionFieldMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationSelectionField: NodeType.ProductDeclarationSelectionFieldMutationPayload;
            }>
        >;
        update: (
            input:
                | NodeType.UpdateProductDeclarationSelectionFieldMutationInput[]
                | NodeType.UpdateProductDeclarationSelectionFieldMutationInput
        ) => Promise<
            MutationResultFn<{
                ProductDeclarationSelectionField: NodeType.ProductDeclarationSelectionFieldMutationPayload;
            }>
        >;
    };
}

// ************** ProductDeclarationStringField ***************** //

// ProductDeclarationStringField: Mutator
export const withProductDeclarationStringFieldMutator = withObjectMutator({
    objectName: 'ProductDeclarationStringField',
    actions: ['delete', 'create', 'update'],
});

// ProductDeclarationStringField: Mutator Interface
export interface IMutateProductDeclarationStringField {
    mutateProductDeclarationStringField: {
        delete: (
            input:
                | NodeType.DeleteProductDeclarationStringFieldMutationInput[]
                | NodeType.DeleteProductDeclarationStringFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationStringField: NodeType.ProductDeclarationStringFieldMutationPayload }>
        >;
        create: (
            input:
                | NodeType.CreateProductDeclarationStringFieldMutationInput[]
                | NodeType.CreateProductDeclarationStringFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationStringField: NodeType.ProductDeclarationStringFieldMutationPayload }>
        >;
        update: (
            input:
                | NodeType.UpdateProductDeclarationStringFieldMutationInput[]
                | NodeType.UpdateProductDeclarationStringFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationStringField: NodeType.ProductDeclarationStringFieldMutationPayload }>
        >;
    };
}

// ************** ProductDeclarationUnitField ***************** //

// ProductDeclarationUnitField: Mutator
export const withProductDeclarationUnitFieldMutator = withObjectMutator({
    objectName: 'ProductDeclarationUnitField',
    actions: ['delete', 'create', 'update'],
});

// ProductDeclarationUnitField: Mutator Interface
export interface IMutateProductDeclarationUnitField {
    mutateProductDeclarationUnitField: {
        delete: (
            input:
                | NodeType.DeleteProductDeclarationUnitFieldMutationInput[]
                | NodeType.DeleteProductDeclarationUnitFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationUnitField: NodeType.ProductDeclarationUnitFieldMutationPayload }>
        >;
        create: (
            input:
                | NodeType.CreateProductDeclarationUnitFieldMutationInput[]
                | NodeType.CreateProductDeclarationUnitFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationUnitField: NodeType.ProductDeclarationUnitFieldMutationPayload }>
        >;
        update: (
            input:
                | NodeType.UpdateProductDeclarationUnitFieldMutationInput[]
                | NodeType.UpdateProductDeclarationUnitFieldMutationInput
        ) => Promise<
            MutationResultFn<{ ProductDeclarationUnitField: NodeType.ProductDeclarationUnitFieldMutationPayload }>
        >;
    };
}

// ************** ProductDocument ***************** //

// ProductDocument: Mutator
export const withProductDocumentMutator = withObjectMutator({
    objectName: 'ProductDocument',
    actions: ['delete', 'update'],
});

// ProductDocument: Mutator Interface
export interface IMutateProductDocument {
    mutateProductDocument: {
        delete: (
            input: NodeType.DeleteProductDocumentMutationInput[] | NodeType.DeleteProductDocumentMutationInput
        ) => Promise<MutationResultFn<{ ProductDocument: NodeType.ProductDocumentMutationPayload }>>;
        update: (
            input: NodeType.UpdateProductDocumentMutationInput[] | NodeType.UpdateProductDocumentMutationInput
        ) => Promise<MutationResultFn<{ ProductDocument: NodeType.ProductDocumentMutationPayload }>>;
    };
}

// ************** ProductTrait ***************** //

// ProductTrait: Mutator
export const withProductTraitMutator = withObjectMutator({
    objectName: 'ProductTrait',
    actions: ['delete', 'create', 'update'],
});

// ProductTrait: Mutator Interface
export interface IMutateProductTrait {
    mutateProductTrait: {
        delete: (
            input: NodeType.DeleteProductTraitMutationInput[] | NodeType.DeleteProductTraitMutationInput
        ) => Promise<MutationResultFn<{ ProductTrait: NodeType.ProductTraitMutationPayload }>>;
        create: (
            input: NodeType.CreateProductTraitMutationInput[] | NodeType.CreateProductTraitMutationInput
        ) => Promise<MutationResultFn<{ ProductTrait: NodeType.ProductTraitMutationPayload }>>;
        update: (
            input: NodeType.UpdateProductTraitMutationInput[] | NodeType.UpdateProductTraitMutationInput
        ) => Promise<MutationResultFn<{ ProductTrait: NodeType.ProductTraitMutationPayload }>>;
    };
}

// ************** ProductTraitDeclaration ***************** //

// ProductTraitDeclaration: Mutator
export const withProductTraitDeclarationMutator = withObjectMutator({
    objectName: 'ProductTraitDeclaration',
    actions: ['delete', 'create', 'update'],
});

// ProductTraitDeclaration: Mutator Interface
export interface IMutateProductTraitDeclaration {
    mutateProductTraitDeclaration: {
        delete: (
            input:
                | NodeType.DeleteProductTraitDeclarationMutationInput[]
                | NodeType.DeleteProductTraitDeclarationMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitDeclaration: NodeType.ProductTraitDeclarationMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateProductTraitDeclarationMutationInput[]
                | NodeType.CreateProductTraitDeclarationMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitDeclaration: NodeType.ProductTraitDeclarationMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateProductTraitDeclarationMutationInput[]
                | NodeType.UpdateProductTraitDeclarationMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitDeclaration: NodeType.ProductTraitDeclarationMutationPayload }>>;
    };
}

// ************** ProductTraitFlagValue ***************** //

// ProductTraitFlagValue: Mutator
export const withProductTraitFlagValueMutator = withObjectMutator({
    objectName: 'ProductTraitFlagValue',
    actions: ['delete', 'create', 'update', 'assign'],
});

// ProductTraitFlagValue: Mutator Interface
export interface IMutateProductTraitFlagValue {
    mutateProductTraitFlagValue: {
        delete: (
            input:
                | NodeType.DeleteProductTraitFlagValueMutationInput[]
                | NodeType.DeleteProductTraitFlagValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitFlagValue: NodeType.ProductTraitFlagValueMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateProductTraitFlagValueMutationInput[]
                | NodeType.CreateProductTraitFlagValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitFlagValue: NodeType.ProductTraitFlagValueMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateProductTraitFlagValueMutationInput[]
                | NodeType.UpdateProductTraitFlagValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitFlagValue: NodeType.ProductTraitFlagValueMutationPayload }>>;
        assign: (
            input:
                | NodeType.ProductTraitFlagValueAssignMutationInput[]
                | NodeType.ProductTraitFlagValueAssignMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitFlagValue: NodeType.ProductTraitFlagValueMutationPayload }>>;
    };
}

// ************** ProductTraitFloatValue ***************** //

// ProductTraitFloatValue: Mutator
export const withProductTraitFloatValueMutator = withObjectMutator({
    objectName: 'ProductTraitFloatValue',
    actions: ['delete', 'create', 'update'],
});

// ProductTraitFloatValue: Mutator Interface
export interface IMutateProductTraitFloatValue {
    mutateProductTraitFloatValue: {
        delete: (
            input:
                | NodeType.DeleteProductTraitFloatValueMutationInput[]
                | NodeType.DeleteProductTraitFloatValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitFloatValue: NodeType.ProductTraitFloatValueMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateProductTraitFloatValueMutationInput[]
                | NodeType.CreateProductTraitFloatValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitFloatValue: NodeType.ProductTraitFloatValueMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateProductTraitFloatValueMutationInput[]
                | NodeType.UpdateProductTraitFloatValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitFloatValue: NodeType.ProductTraitFloatValueMutationPayload }>>;
    };
}

// ************** ProductTraitIntegerValue ***************** //

// ProductTraitIntegerValue: Mutator
export const withProductTraitIntegerValueMutator = withObjectMutator({
    objectName: 'ProductTraitIntegerValue',
    actions: ['delete', 'create', 'update'],
});

// ProductTraitIntegerValue: Mutator Interface
export interface IMutateProductTraitIntegerValue {
    mutateProductTraitIntegerValue: {
        delete: (
            input:
                | NodeType.DeleteProductTraitIntegerValueMutationInput[]
                | NodeType.DeleteProductTraitIntegerValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitIntegerValue: NodeType.ProductTraitIntegerValueMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateProductTraitIntegerValueMutationInput[]
                | NodeType.CreateProductTraitIntegerValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitIntegerValue: NodeType.ProductTraitIntegerValueMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateProductTraitIntegerValueMutationInput[]
                | NodeType.UpdateProductTraitIntegerValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitIntegerValue: NodeType.ProductTraitIntegerValueMutationPayload }>>;
    };
}

// ************** ProductTraitMeasurementValue ***************** //

// ProductTraitMeasurementValue: Mutator
export const withProductTraitMeasurementValueMutator = withObjectMutator({
    objectName: 'ProductTraitMeasurementValue',
    actions: ['delete', 'create', 'update'],
});

// ProductTraitMeasurementValue: Mutator Interface
export interface IMutateProductTraitMeasurementValue {
    mutateProductTraitMeasurementValue: {
        delete: (
            input:
                | NodeType.DeleteProductTraitMeasurementValueMutationInput[]
                | NodeType.DeleteProductTraitMeasurementValueMutationInput
        ) => Promise<
            MutationResultFn<{ ProductTraitMeasurementValue: NodeType.ProductTraitMeasurementValueMutationPayload }>
        >;
        create: (
            input:
                | NodeType.CreateProductTraitMeasurementValueMutationInput[]
                | NodeType.CreateProductTraitMeasurementValueMutationInput
        ) => Promise<
            MutationResultFn<{ ProductTraitMeasurementValue: NodeType.ProductTraitMeasurementValueMutationPayload }>
        >;
        update: (
            input:
                | NodeType.UpdateProductTraitMeasurementValueMutationInput[]
                | NodeType.UpdateProductTraitMeasurementValueMutationInput
        ) => Promise<
            MutationResultFn<{ ProductTraitMeasurementValue: NodeType.ProductTraitMeasurementValueMutationPayload }>
        >;
    };
}

// ************** ProductTraitSelectionValue ***************** //

// ProductTraitSelectionValue: Mutator
export const withProductTraitSelectionValueMutator = withObjectMutator({
    objectName: 'ProductTraitSelectionValue',
    actions: ['delete', 'create', 'update'],
});

// ProductTraitSelectionValue: Mutator Interface
export interface IMutateProductTraitSelectionValue {
    mutateProductTraitSelectionValue: {
        delete: (
            input:
                | NodeType.DeleteProductTraitSelectionValueMutationInput[]
                | NodeType.DeleteProductTraitSelectionValueMutationInput
        ) => Promise<
            MutationResultFn<{ ProductTraitSelectionValue: NodeType.ProductTraitSelectionValueMutationPayload }>
        >;
        create: (
            input:
                | NodeType.CreateProductTraitSelectionValueMutationInput[]
                | NodeType.CreateProductTraitSelectionValueMutationInput
        ) => Promise<
            MutationResultFn<{ ProductTraitSelectionValue: NodeType.ProductTraitSelectionValueMutationPayload }>
        >;
        update: (
            input:
                | NodeType.UpdateProductTraitSelectionValueMutationInput[]
                | NodeType.UpdateProductTraitSelectionValueMutationInput
        ) => Promise<
            MutationResultFn<{ ProductTraitSelectionValue: NodeType.ProductTraitSelectionValueMutationPayload }>
        >;
    };
}

// ************** ProductTraitStringValue ***************** //

// ProductTraitStringValue: Mutator
export const withProductTraitStringValueMutator = withObjectMutator({
    objectName: 'ProductTraitStringValue',
    actions: ['delete', 'create', 'update'],
});

// ProductTraitStringValue: Mutator Interface
export interface IMutateProductTraitStringValue {
    mutateProductTraitStringValue: {
        delete: (
            input:
                | NodeType.DeleteProductTraitStringValueMutationInput[]
                | NodeType.DeleteProductTraitStringValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitStringValue: NodeType.ProductTraitStringValueMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateProductTraitStringValueMutationInput[]
                | NodeType.CreateProductTraitStringValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitStringValue: NodeType.ProductTraitStringValueMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateProductTraitStringValueMutationInput[]
                | NodeType.UpdateProductTraitStringValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitStringValue: NodeType.ProductTraitStringValueMutationPayload }>>;
    };
}

// ************** ProductTraitUnitValue ***************** //

// ProductTraitUnitValue: Mutator
export const withProductTraitUnitValueMutator = withObjectMutator({
    objectName: 'ProductTraitUnitValue',
    actions: ['delete', 'create', 'update'],
});

// ProductTraitUnitValue: Mutator Interface
export interface IMutateProductTraitUnitValue {
    mutateProductTraitUnitValue: {
        delete: (
            input:
                | NodeType.DeleteProductTraitUnitValueMutationInput[]
                | NodeType.DeleteProductTraitUnitValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitUnitValue: NodeType.ProductTraitUnitValueMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateProductTraitUnitValueMutationInput[]
                | NodeType.CreateProductTraitUnitValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitUnitValue: NodeType.ProductTraitUnitValueMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateProductTraitUnitValueMutationInput[]
                | NodeType.UpdateProductTraitUnitValueMutationInput
        ) => Promise<MutationResultFn<{ ProductTraitUnitValue: NodeType.ProductTraitUnitValueMutationPayload }>>;
    };
}

// ************** Project ***************** //

// Project: Mutator
export const withProjectMutator = withObjectMutator({
    objectName: 'Project',
    actions: ['delete', 'create', 'update'],
});

// Project: Mutator Interface
export interface IMutateProject {
    mutateProject: {
        delete: (
            input: NodeType.DeleteProjectMutationInput[] | NodeType.DeleteProjectMutationInput
        ) => Promise<MutationResultFn<{ Project: NodeType.ProjectMutationPayload }>>;
        create: (
            input: NodeType.CreateProjectMutationInput[] | NodeType.CreateProjectMutationInput
        ) => Promise<MutationResultFn<{ Project: NodeType.ProjectMutationPayload }>>;
        update: (
            input: NodeType.UpdateProjectMutationInput[] | NodeType.UpdateProjectMutationInput
        ) => Promise<MutationResultFn<{ Project: NodeType.ProjectMutationPayload }>>;
    };
}

// ************** ProjectVersion ***************** //

// ProjectVersion: Mutator
export const withProjectVersionMutator = withObjectMutator({
    objectName: 'ProjectVersion',
    actions: ['delete', 'create', 'update'],
});

// ProjectVersion: Mutator Interface
export interface IMutateProjectVersion {
    mutateProjectVersion: {
        delete: (
            input: NodeType.DeleteProjectVersionMutationInput[] | NodeType.DeleteProjectVersionMutationInput
        ) => Promise<MutationResultFn<{ ProjectVersion: NodeType.ProjectVersionMutationPayload }>>;
        create: (
            input: NodeType.CreateProjectVersionMutationInput[] | NodeType.CreateProjectVersionMutationInput
        ) => Promise<MutationResultFn<{ ProjectVersion: NodeType.ProjectVersionMutationPayload }>>;
        update: (
            input: NodeType.UpdateProjectVersionMutationInput[] | NodeType.UpdateProjectVersionMutationInput
        ) => Promise<MutationResultFn<{ ProjectVersion: NodeType.ProjectVersionMutationPayload }>>;
    };
}

// ************** RecommendedTreatment ***************** //

// RecommendedTreatment: Mutator
export const withRecommendedTreatmentMutator = withObjectMutator({
    objectName: 'RecommendedTreatment',
    actions: ['override', 'reset'],
});

// RecommendedTreatment: Mutator Interface
export interface IMutateRecommendedTreatment {
    mutateRecommendedTreatment: {
        override: (
            input:
                | NodeType.RecommendedTreatmentOverrideMutationInput[]
                | NodeType.RecommendedTreatmentOverrideMutationInput
        ) => Promise<MutationResultFn<{ RecommendedTreatment: NodeType.RecommendedTreatmentMutationPayload }>>;
        reset: (
            input: NodeType.RecommendedTreatmentResetMutationInput[] | NodeType.RecommendedTreatmentResetMutationInput
        ) => Promise<MutationResultFn<{ RecommendedTreatment: NodeType.RecommendedTreatmentMutationPayload }>>;
    };
}

// ************** Role ***************** //

// Role: Mutator
export const withRoleMutator = withObjectMutator({
    objectName: 'Role',
    actions: ['create', 'update'],
});

// Role: Mutator Interface
export interface IMutateRole {
    mutateRole: {
        create: (
            input: NodeType.CreateRoleMutationInput[] | NodeType.CreateRoleMutationInput
        ) => Promise<MutationResultFn<{ Role: NodeType.RoleMutationPayload }>>;
        update: (
            input: NodeType.UpdateRoleMutationInput[] | NodeType.UpdateRoleMutationInput
        ) => Promise<MutationResultFn<{ Role: NodeType.RoleMutationPayload }>>;
    };
}

// ************** RoleFlag ***************** //

// RoleFlag: Mutator
export const withRoleFlagMutator = withObjectMutator({
    objectName: 'RoleFlag',
    actions: ['delete', 'create', 'update'],
});

// RoleFlag: Mutator Interface
export interface IMutateRoleFlag {
    mutateRoleFlag: {
        delete: (
            input: NodeType.DeleteRoleFlagMutationInput[] | NodeType.DeleteRoleFlagMutationInput
        ) => Promise<MutationResultFn<{ RoleFlag: NodeType.RoleFlagMutationPayload }>>;
        create: (
            input: NodeType.CreateRoleFlagMutationInput[] | NodeType.CreateRoleFlagMutationInput
        ) => Promise<MutationResultFn<{ RoleFlag: NodeType.RoleFlagMutationPayload }>>;
        update: (
            input: NodeType.UpdateRoleFlagMutationInput[] | NodeType.UpdateRoleFlagMutationInput
        ) => Promise<MutationResultFn<{ RoleFlag: NodeType.RoleFlagMutationPayload }>>;
    };
}

// ************** Sample ***************** //

// Sample: Mutator
export const withSampleMutator = withObjectMutator({
    objectName: 'Sample',
    actions: ['delete', 'create', 'update'],
});

// Sample: Mutator Interface
export interface IMutateSample {
    mutateSample: {
        delete: (
            input: NodeType.DeleteSampleMutationInput[] | NodeType.DeleteSampleMutationInput
        ) => Promise<MutationResultFn<{ Sample: NodeType.SampleMutationPayload }>>;
        create: (
            input: NodeType.CreateSampleMutationInput[] | NodeType.CreateSampleMutationInput
        ) => Promise<MutationResultFn<{ Sample: NodeType.SampleMutationPayload }>>;
        update: (
            input: NodeType.UpdateSampleMutationInput[] | NodeType.UpdateSampleMutationInput
        ) => Promise<MutationResultFn<{ Sample: NodeType.SampleMutationPayload }>>;
    };
}

// ************** SampleConverter ***************** //

// SampleConverter: Mutator
export const withSampleConverterMutator = withObjectMutator({
    objectName: 'SampleConverter',
    actions: ['delete', 'create', 'update'],
});

// SampleConverter: Mutator Interface
export interface IMutateSampleConverter {
    mutateSampleConverter: {
        delete: (
            input: NodeType.DeleteSampleConverterMutationInput[] | NodeType.DeleteSampleConverterMutationInput
        ) => Promise<MutationResultFn<{ SampleConverter: NodeType.SampleConverterMutationPayload }>>;
        create: (
            input: NodeType.CreateSampleConverterMutationInput[] | NodeType.CreateSampleConverterMutationInput
        ) => Promise<MutationResultFn<{ SampleConverter: NodeType.SampleConverterMutationPayload }>>;
        update: (
            input: NodeType.UpdateSampleConverterMutationInput[] | NodeType.UpdateSampleConverterMutationInput
        ) => Promise<MutationResultFn<{ SampleConverter: NodeType.SampleConverterMutationPayload }>>;
    };
}

// ************** SampleConverterTemplate ***************** //

// SampleConverterTemplate: Mutator
export const withSampleConverterTemplateMutator = withObjectMutator({
    objectName: 'SampleConverterTemplate',
    actions: ['delete', 'create', 'update'],
});

// SampleConverterTemplate: Mutator Interface
export interface IMutateSampleConverterTemplate {
    mutateSampleConverterTemplate: {
        delete: (
            input:
                | NodeType.DeleteSampleConverterTemplateMutationInput[]
                | NodeType.DeleteSampleConverterTemplateMutationInput
        ) => Promise<MutationResultFn<{ SampleConverterTemplate: NodeType.SampleConverterTemplateMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateSampleConverterTemplateMutationInput[]
                | NodeType.CreateSampleConverterTemplateMutationInput
        ) => Promise<MutationResultFn<{ SampleConverterTemplate: NodeType.SampleConverterTemplateMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateSampleConverterTemplateMutationInput[]
                | NodeType.UpdateSampleConverterTemplateMutationInput
        ) => Promise<MutationResultFn<{ SampleConverterTemplate: NodeType.SampleConverterTemplateMutationPayload }>>;
    };
}

// ************** SampleSet ***************** //

// SampleSet: Mutator
export const withSampleSetMutator = withObjectMutator({
    objectName: 'SampleSet',
    actions: ['create', 'update'],
});

// SampleSet: Mutator Interface
export interface IMutateSampleSet {
    mutateSampleSet: {
        create: (
            input: NodeType.CreateSampleSetMutationInput[] | NodeType.CreateSampleSetMutationInput
        ) => Promise<MutationResultFn<{ SampleSet: NodeType.SampleSetMutationPayload }>>;
        update: (
            input: NodeType.UpdateSampleSetMutationInput[] | NodeType.UpdateSampleSetMutationInput
        ) => Promise<MutationResultFn<{ SampleSet: NodeType.SampleSetMutationPayload }>>;
    };
}

// ************** SampleSource ***************** //

// SampleSource: Mutator
export const withSampleSourceMutator = withObjectMutator({
    objectName: 'SampleSource',
    actions: ['create', 'update'],
});

// SampleSource: Mutator Interface
export interface IMutateSampleSource {
    mutateSampleSource: {
        create: (
            input: NodeType.CreateSampleSourceMutationInput[] | NodeType.CreateSampleSourceMutationInput
        ) => Promise<MutationResultFn<{ SampleSource: NodeType.SampleSourceMutationPayload }>>;
        update: (
            input: NodeType.UpdateSampleSourceMutationInput[] | NodeType.UpdateSampleSourceMutationInput
        ) => Promise<MutationResultFn<{ SampleSource: NodeType.SampleSourceMutationPayload }>>;
    };
}

// ************** Sanitiser ***************** //

// Sanitiser: Mutator
export const withSanitiserMutator = withObjectMutator({
    objectName: 'Sanitiser',
    actions: ['delete', 'create', 'update'],
});

// Sanitiser: Mutator Interface
export interface IMutateSanitiser {
    mutateSanitiser: {
        delete: (
            input: NodeType.DeleteSanitiserMutationInput[] | NodeType.DeleteSanitiserMutationInput
        ) => Promise<MutationResultFn<{ Sanitiser: NodeType.SanitiserMutationPayload }>>;
        create: (
            input: NodeType.CreateSanitiserMutationInput[] | NodeType.CreateSanitiserMutationInput
        ) => Promise<MutationResultFn<{ Sanitiser: NodeType.SanitiserMutationPayload }>>;
        update: (
            input: NodeType.UpdateSanitiserMutationInput[] | NodeType.UpdateSanitiserMutationInput
        ) => Promise<MutationResultFn<{ Sanitiser: NodeType.SanitiserMutationPayload }>>;
    };
}

// ************** ScheduleEvent ***************** //

// ScheduleEvent: Mutator
export const withScheduleEventMutator = withObjectMutator({
    objectName: 'ScheduleEvent',
    actions: ['delete'],
});

// ScheduleEvent: Mutator Interface
export interface IMutateScheduleEvent {
    mutateScheduleEvent: {
        delete: (
            input: NodeType.DeleteScheduleEventMutationInput[] | NodeType.DeleteScheduleEventMutationInput
        ) => Promise<MutationResultFn<{ ScheduleEvent: NodeType.ScheduleEventMutationPayload }>>;
    };
}

// ************** ServiceJob ***************** //

// ServiceJob: Mutator
export const withServiceJobMutator = withObjectMutator({
    objectName: 'ServiceJob',
    actions: [
        'delete',
        'create',
        'update',
        'createForSingleAppointment',
        'createForRecurrentAppointment',
        'splitRecurrentJob',
        'updateRecurringJob',
        'setStopDate',
        'archive',
        'unArchive',
    ],
});

// ServiceJob: Mutator Interface
export interface IMutateServiceJob {
    mutateServiceJob: {
        delete: (
            input: NodeType.DeleteServiceJobMutationInput[] | NodeType.DeleteServiceJobMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        create: (
            input: NodeType.CreateServiceJobMutationInput[] | NodeType.CreateServiceJobMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        update: (
            input: NodeType.UpdateServiceJobMutationInput[] | NodeType.UpdateServiceJobMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        createForSingleAppointment: (
            input:
                | NodeType.ServiceJobCreateForSingleAppointmentMutationInput[]
                | NodeType.ServiceJobCreateForSingleAppointmentMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        createForRecurrentAppointment: (
            input:
                | NodeType.ServiceJobCreateForRecurrentAppointmentMutationInput[]
                | NodeType.ServiceJobCreateForRecurrentAppointmentMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        splitRecurrentJob: (
            input:
                | NodeType.ServiceJobSplitRecurrentJobMutationInput[]
                | NodeType.ServiceJobSplitRecurrentJobMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        updateRecurringJob: (
            input:
                | NodeType.ServiceJobUpdateRecurringJobMutationInput[]
                | NodeType.ServiceJobUpdateRecurringJobMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        setStopDate: (
            input: NodeType.ServiceJobSetStopDateMutationInput[] | NodeType.ServiceJobSetStopDateMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        archive: (
            input: NodeType.ServiceJobArchiveMutationInput[] | NodeType.ServiceJobArchiveMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
        unArchive: (
            input: NodeType.ServiceJobUnArchiveMutationInput[] | NodeType.ServiceJobUnArchiveMutationInput
        ) => Promise<MutationResultFn<{ ServiceJob: NodeType.ServiceJobMutationPayload }>>;
    };
}

// ************** ServiceJobGroup ***************** //

// ServiceJobGroup: Mutator
export const withServiceJobGroupMutator = withObjectMutator({
    objectName: 'ServiceJobGroup',
    actions: ['delete', 'create', 'update'],
});

// ServiceJobGroup: Mutator Interface
export interface IMutateServiceJobGroup {
    mutateServiceJobGroup: {
        delete: (
            input: NodeType.DeleteServiceJobGroupMutationInput[] | NodeType.DeleteServiceJobGroupMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobGroup: NodeType.ServiceJobGroupMutationPayload }>>;
        create: (
            input: NodeType.CreateServiceJobGroupMutationInput[] | NodeType.CreateServiceJobGroupMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobGroup: NodeType.ServiceJobGroupMutationPayload }>>;
        update: (
            input: NodeType.UpdateServiceJobGroupMutationInput[] | NodeType.UpdateServiceJobGroupMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobGroup: NodeType.ServiceJobGroupMutationPayload }>>;
    };
}

// ************** ServiceJobNote ***************** //

// ServiceJobNote: Mutator
export const withServiceJobNoteMutator = withObjectMutator({
    objectName: 'ServiceJobNote',
    actions: ['delete', 'create', 'update'],
});

// ServiceJobNote: Mutator Interface
export interface IMutateServiceJobNote {
    mutateServiceJobNote: {
        delete: (
            input: NodeType.DeleteServiceJobNoteMutationInput[] | NodeType.DeleteServiceJobNoteMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobNote: NodeType.ServiceJobNoteMutationPayload }>>;
        create: (
            input: NodeType.CreateServiceJobNoteMutationInput[] | NodeType.CreateServiceJobNoteMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobNote: NodeType.ServiceJobNoteMutationPayload }>>;
        update: (
            input: NodeType.UpdateServiceJobNoteMutationInput[] | NodeType.UpdateServiceJobNoteMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobNote: NodeType.ServiceJobNoteMutationPayload }>>;
    };
}

// ************** ServiceJobStage ***************** //

// ServiceJobStage: Mutator
export const withServiceJobStageMutator = withObjectMutator({
    objectName: 'ServiceJobStage',
    actions: ['delete', 'create', 'update', 'archive'],
});

// ServiceJobStage: Mutator Interface
export interface IMutateServiceJobStage {
    mutateServiceJobStage: {
        delete: (
            input: NodeType.DeleteServiceJobStageMutationInput[] | NodeType.DeleteServiceJobStageMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobStage: NodeType.ServiceJobStageMutationPayload }>>;
        create: (
            input: NodeType.CreateServiceJobStageMutationInput[] | NodeType.CreateServiceJobStageMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobStage: NodeType.ServiceJobStageMutationPayload }>>;
        update: (
            input: NodeType.UpdateServiceJobStageMutationInput[] | NodeType.UpdateServiceJobStageMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobStage: NodeType.ServiceJobStageMutationPayload }>>;
        archive: (
            input: NodeType.ServiceJobStageArchiveMutationInput[] | NodeType.ServiceJobStageArchiveMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobStage: NodeType.ServiceJobStageMutationPayload }>>;
    };
}

// ************** ServiceJobTemplate ***************** //

// ServiceJobTemplate: Mutator
export const withServiceJobTemplateMutator = withObjectMutator({
    objectName: 'ServiceJobTemplate',
    actions: ['delete', 'create', 'update', 'setAsDefault', 'archive'],
});

// ServiceJobTemplate: Mutator Interface
export interface IMutateServiceJobTemplate {
    mutateServiceJobTemplate: {
        delete: (
            input: NodeType.DeleteServiceJobTemplateMutationInput[] | NodeType.DeleteServiceJobTemplateMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobTemplate: NodeType.ServiceJobTemplateMutationPayload }>>;
        create: (
            input: NodeType.CreateServiceJobTemplateMutationInput[] | NodeType.CreateServiceJobTemplateMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobTemplate: NodeType.ServiceJobTemplateMutationPayload }>>;
        update: (
            input: NodeType.UpdateServiceJobTemplateMutationInput[] | NodeType.UpdateServiceJobTemplateMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobTemplate: NodeType.ServiceJobTemplateMutationPayload }>>;
        setAsDefault: (
            input:
                | NodeType.ServiceJobTemplateSetAsDefaultMutationInput[]
                | NodeType.ServiceJobTemplateSetAsDefaultMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobTemplate: NodeType.ServiceJobTemplateMutationPayload }>>;
        archive: (
            input: NodeType.ServiceJobTemplateArchiveMutationInput[] | NodeType.ServiceJobTemplateArchiveMutationInput
        ) => Promise<MutationResultFn<{ ServiceJobTemplate: NodeType.ServiceJobTemplateMutationPayload }>>;
    };
}

// ************** SettingStore ***************** //

// SettingStore: Mutator
export const withSettingStoreMutator = withObjectMutator({
    objectName: 'SettingStore',
    actions: ['delete', 'create', 'update'],
});

// SettingStore: Mutator Interface
export interface IMutateSettingStore {
    mutateSettingStore: {
        delete: (
            input: NodeType.DeleteSettingStoreMutationInput[] | NodeType.DeleteSettingStoreMutationInput
        ) => Promise<MutationResultFn<{ SettingStore: NodeType.SettingStoreMutationPayload }>>;
        create: (
            input: NodeType.CreateSettingStoreMutationInput[] | NodeType.CreateSettingStoreMutationInput
        ) => Promise<MutationResultFn<{ SettingStore: NodeType.SettingStoreMutationPayload }>>;
        update: (
            input: NodeType.UpdateSettingStoreMutationInput[] | NodeType.UpdateSettingStoreMutationInput
        ) => Promise<MutationResultFn<{ SettingStore: NodeType.SettingStoreMutationPayload }>>;
    };
}

// ************** SharedEntity ***************** //

// SharedEntity: Mutator
export const withSharedEntityMutator = withObjectMutator({
    objectName: 'SharedEntity',
    actions: ['create'],
});

// SharedEntity: Mutator Interface
export interface IMutateSharedEntity {
    mutateSharedEntity: {
        create: (
            input: NodeType.CreateSharedEntityMutationInput[] | NodeType.CreateSharedEntityMutationInput
        ) => Promise<MutationResultFn<{ SharedEntity: NodeType.SharedEntityMutationPayload }>>;
    };
}

// ************** Site ***************** //

// Site: Mutator
export const withSiteMutator = withObjectMutator({
    objectName: 'Site',
    actions: ['delete', 'create', 'update', 'transferTo'],
});

// Site: Mutator Interface
export interface IMutateSite {
    mutateSite: {
        delete: (
            input: NodeType.DeleteSiteMutationInput[] | NodeType.DeleteSiteMutationInput
        ) => Promise<MutationResultFn<{ Site: NodeType.SiteMutationPayload }>>;
        create: (
            input: NodeType.CreateSiteMutationInput[] | NodeType.CreateSiteMutationInput
        ) => Promise<MutationResultFn<{ Site: NodeType.SiteMutationPayload }>>;
        update: (
            input: NodeType.UpdateSiteMutationInput[] | NodeType.UpdateSiteMutationInput
        ) => Promise<MutationResultFn<{ Site: NodeType.SiteMutationPayload }>>;
        transferTo: (
            input: NodeType.SiteTransferToMutationInput[] | NodeType.SiteTransferToMutationInput
        ) => Promise<MutationResultFn<{ Site: NodeType.SiteMutationPayload }>>;
    };
}

// ************** SiteNote ***************** //

// SiteNote: Mutator
export const withSiteNoteMutator = withObjectMutator({
    objectName: 'SiteNote',
    actions: ['delete', 'create', 'update'],
});

// SiteNote: Mutator Interface
export interface IMutateSiteNote {
    mutateSiteNote: {
        delete: (
            input: NodeType.DeleteSiteNoteMutationInput[] | NodeType.DeleteSiteNoteMutationInput
        ) => Promise<MutationResultFn<{ SiteNote: NodeType.SiteNoteMutationPayload }>>;
        create: (
            input: NodeType.CreateSiteNoteMutationInput[] | NodeType.CreateSiteNoteMutationInput
        ) => Promise<MutationResultFn<{ SiteNote: NodeType.SiteNoteMutationPayload }>>;
        update: (
            input: NodeType.UpdateSiteNoteMutationInput[] | NodeType.UpdateSiteNoteMutationInput
        ) => Promise<MutationResultFn<{ SiteNote: NodeType.SiteNoteMutationPayload }>>;
    };
}

// ************** Staff ***************** //

// Staff: Mutator
export const withStaffMutator = withObjectMutator({
    objectName: 'Staff',
    actions: ['create', 'update', 'archive', 'unarchive'],
});

// Staff: Mutator Interface
export interface IMutateStaff {
    mutateStaff: {
        create: (
            input: NodeType.CreateStaffMutationInput[] | NodeType.CreateStaffMutationInput
        ) => Promise<MutationResultFn<{ Staff: NodeType.StaffMutationPayload }>>;
        update: (
            input: NodeType.UpdateStaffMutationInput[] | NodeType.UpdateStaffMutationInput
        ) => Promise<MutationResultFn<{ Staff: NodeType.StaffMutationPayload }>>;
        archive: (
            input: NodeType.StaffArchiveMutationInput[] | NodeType.StaffArchiveMutationInput
        ) => Promise<MutationResultFn<{ Staff: NodeType.StaffMutationPayload }>>;
        unarchive: (
            input: NodeType.StaffUnarchiveMutationInput[] | NodeType.StaffUnarchiveMutationInput
        ) => Promise<MutationResultFn<{ Staff: NodeType.StaffMutationPayload }>>;
    };
}

// ************** StaffRegistration ***************** //

// StaffRegistration: Mutator
export const withStaffRegistrationMutator = withObjectMutator({
    objectName: 'StaffRegistration',
    actions: ['delete', 'create', 'update', 'forcePassword', 'disable', 'invalidatePassword', 'enable'],
});

// StaffRegistration: Mutator Interface
export interface IMutateStaffRegistration {
    mutateStaffRegistration: {
        delete: (
            input: NodeType.DeleteStaffRegistrationMutationInput[] | NodeType.DeleteStaffRegistrationMutationInput
        ) => Promise<MutationResultFn<{ StaffRegistration: NodeType.StaffRegistrationMutationPayload }>>;
        create: (
            input: NodeType.CreateStaffRegistrationMutationInput[] | NodeType.CreateStaffRegistrationMutationInput
        ) => Promise<MutationResultFn<{ StaffRegistration: NodeType.StaffRegistrationMutationPayload }>>;
        update: (
            input: NodeType.UpdateStaffRegistrationMutationInput[] | NodeType.UpdateStaffRegistrationMutationInput
        ) => Promise<MutationResultFn<{ StaffRegistration: NodeType.StaffRegistrationMutationPayload }>>;
        forcePassword: (
            input:
                | NodeType.StaffRegistrationForcePasswordMutationInput[]
                | NodeType.StaffRegistrationForcePasswordMutationInput
        ) => Promise<MutationResultFn<{ StaffRegistration: NodeType.StaffRegistrationMutationPayload }>>;
        disable: (
            input: NodeType.StaffRegistrationDisableMutationInput[] | NodeType.StaffRegistrationDisableMutationInput
        ) => Promise<MutationResultFn<{ StaffRegistration: NodeType.StaffRegistrationMutationPayload }>>;
        invalidatePassword: (
            input:
                | NodeType.StaffRegistrationInvalidatePasswordMutationInput[]
                | NodeType.StaffRegistrationInvalidatePasswordMutationInput
        ) => Promise<MutationResultFn<{ StaffRegistration: NodeType.StaffRegistrationMutationPayload }>>;
        enable: (
            input: NodeType.StaffRegistrationEnableMutationInput[] | NodeType.StaffRegistrationEnableMutationInput
        ) => Promise<MutationResultFn<{ StaffRegistration: NodeType.StaffRegistrationMutationPayload }>>;
    };
}

// ************** StaffTeam ***************** //

// StaffTeam: Mutator
export const withStaffTeamMutator = withObjectMutator({
    objectName: 'StaffTeam',
    actions: ['delete', 'create', 'update', 'addStaff'],
});

// StaffTeam: Mutator Interface
export interface IMutateStaffTeam {
    mutateStaffTeam: {
        delete: (
            input: NodeType.DeleteStaffTeamMutationInput[] | NodeType.DeleteStaffTeamMutationInput
        ) => Promise<MutationResultFn<{ StaffTeam: NodeType.StaffTeamMutationPayload }>>;
        create: (
            input: NodeType.CreateStaffTeamMutationInput[] | NodeType.CreateStaffTeamMutationInput
        ) => Promise<MutationResultFn<{ StaffTeam: NodeType.StaffTeamMutationPayload }>>;
        update: (
            input: NodeType.UpdateStaffTeamMutationInput[] | NodeType.UpdateStaffTeamMutationInput
        ) => Promise<MutationResultFn<{ StaffTeam: NodeType.StaffTeamMutationPayload }>>;
        addStaff: (
            input: NodeType.StaffTeamAddStaffMutationInput[] | NodeType.StaffTeamAddStaffMutationInput
        ) => Promise<MutationResultFn<{ StaffTeam: NodeType.StaffTeamMutationPayload }>>;
    };
}

// ************** StaffTeamLink ***************** //

// StaffTeamLink: Mutator
export const withStaffTeamLinkMutator = withObjectMutator({
    objectName: 'StaffTeamLink',
    actions: ['delete'],
});

// StaffTeamLink: Mutator Interface
export interface IMutateStaffTeamLink {
    mutateStaffTeamLink: {
        delete: (
            input: NodeType.DeleteStaffTeamLinkMutationInput[] | NodeType.DeleteStaffTeamLinkMutationInput
        ) => Promise<MutationResultFn<{ StaffTeamLink: NodeType.StaffTeamLinkMutationPayload }>>;
    };
}

// ************** StaffValueStore ***************** //

// StaffValueStore: Mutator
export const withStaffValueStoreMutator = withObjectMutator({
    objectName: 'StaffValueStore',
    actions: ['delete', 'update', 'set', 'setOwn'],
});

// StaffValueStore: Mutator Interface
export interface IMutateStaffValueStore {
    mutateStaffValueStore: {
        delete: (
            input: NodeType.DeleteStaffValueStoreMutationInput[] | NodeType.DeleteStaffValueStoreMutationInput
        ) => Promise<MutationResultFn<{ StaffValueStore: NodeType.StaffValueStoreMutationPayload }>>;
        update: (
            input: NodeType.UpdateStaffValueStoreMutationInput[] | NodeType.UpdateStaffValueStoreMutationInput
        ) => Promise<MutationResultFn<{ StaffValueStore: NodeType.StaffValueStoreMutationPayload }>>;
        set: (
            input: NodeType.StaffValueStoreSetMutationInput[] | NodeType.StaffValueStoreSetMutationInput
        ) => Promise<MutationResultFn<{ StaffValueStore: NodeType.StaffValueStoreMutationPayload }>>;
        setOwn: (
            input: NodeType.StaffValueStoreSetOwnMutationInput[] | NodeType.StaffValueStoreSetOwnMutationInput
        ) => Promise<MutationResultFn<{ StaffValueStore: NodeType.StaffValueStoreMutationPayload }>>;
    };
}

// ************** TestCriteria ***************** //

// TestCriteria: Mutator
export const withTestCriteriaMutator = withObjectMutator({
    objectName: 'TestCriteria',
    actions: ['delete', 'create', 'update'],
});

// TestCriteria: Mutator Interface
export interface IMutateTestCriteria {
    mutateTestCriteria: {
        delete: (
            input: NodeType.DeleteTestCriteriaMutationInput[] | NodeType.DeleteTestCriteriaMutationInput
        ) => Promise<MutationResultFn<{ TestCriteria: NodeType.TestCriteriaMutationPayload }>>;
        create: (
            input: NodeType.CreateTestCriteriaMutationInput[] | NodeType.CreateTestCriteriaMutationInput
        ) => Promise<MutationResultFn<{ TestCriteria: NodeType.TestCriteriaMutationPayload }>>;
        update: (
            input: NodeType.UpdateTestCriteriaMutationInput[] | NodeType.UpdateTestCriteriaMutationInput
        ) => Promise<MutationResultFn<{ TestCriteria: NodeType.TestCriteriaMutationPayload }>>;
    };
}

// ************** TestProblem ***************** //

// TestProblem: Mutator
export const withTestProblemMutator = withObjectMutator({
    objectName: 'TestProblem',
    actions: ['delete', 'create', 'update'],
});

// TestProblem: Mutator Interface
export interface IMutateTestProblem {
    mutateTestProblem: {
        delete: (
            input: NodeType.DeleteTestProblemMutationInput[] | NodeType.DeleteTestProblemMutationInput
        ) => Promise<MutationResultFn<{ TestProblem: NodeType.TestProblemMutationPayload }>>;
        create: (
            input: NodeType.CreateTestProblemMutationInput[] | NodeType.CreateTestProblemMutationInput
        ) => Promise<MutationResultFn<{ TestProblem: NodeType.TestProblemMutationPayload }>>;
        update: (
            input: NodeType.UpdateTestProblemMutationInput[] | NodeType.UpdateTestProblemMutationInput
        ) => Promise<MutationResultFn<{ TestProblem: NodeType.TestProblemMutationPayload }>>;
    };
}

// ************** TestRecommendation ***************** //

// TestRecommendation: Mutator
export const withTestRecommendationMutator = withObjectMutator({
    objectName: 'TestRecommendation',
    actions: ['selectTreatment'],
});

// TestRecommendation: Mutator Interface
export interface IMutateTestRecommendation {
    mutateTestRecommendation: {
        selectTreatment: (
            input:
                | NodeType.TestRecommendationSelectTreatmentMutationInput[]
                | NodeType.TestRecommendationSelectTreatmentMutationInput
        ) => Promise<MutationResultFn<{ TestRecommendation: NodeType.TestRecommendationMutationPayload }>>;
    };
}

// ************** TestReport ***************** //

// TestReport: Mutator
export const withTestReportMutator = withObjectMutator({
    objectName: 'TestReport',
    actions: ['delete', 'create', 'update', 'finaliseReport', 'archiveReport'],
});

// TestReport: Mutator Interface
export interface IMutateTestReport {
    mutateTestReport: {
        delete: (
            input: NodeType.DeleteTestReportMutationInput[] | NodeType.DeleteTestReportMutationInput
        ) => Promise<MutationResultFn<{ TestReport: NodeType.TestReportMutationPayload }>>;
        create: (
            input: NodeType.CreateTestReportMutationInput[] | NodeType.CreateTestReportMutationInput
        ) => Promise<MutationResultFn<{ TestReport: NodeType.TestReportMutationPayload }>>;
        update: (
            input: NodeType.UpdateTestReportMutationInput[] | NodeType.UpdateTestReportMutationInput
        ) => Promise<MutationResultFn<{ TestReport: NodeType.TestReportMutationPayload }>>;
        finaliseReport: (
            input: NodeType.TestReportFinaliseReportMutationInput[] | NodeType.TestReportFinaliseReportMutationInput
        ) => Promise<MutationResultFn<{ TestReport: NodeType.TestReportMutationPayload }>>;
        archiveReport: (
            input: NodeType.TestReportArchiveReportMutationInput[] | NodeType.TestReportArchiveReportMutationInput
        ) => Promise<MutationResultFn<{ TestReport: NodeType.TestReportMutationPayload }>>;
    };
}

// ************** TestReportAttachment ***************** //

// TestReportAttachment: Mutator
export const withTestReportAttachmentMutator = withObjectMutator({
    objectName: 'TestReportAttachment',
    actions: ['delete', 'create', 'update'],
});

// TestReportAttachment: Mutator Interface
export interface IMutateTestReportAttachment {
    mutateTestReportAttachment: {
        delete: (
            input: NodeType.DeleteTestReportAttachmentMutationInput[] | NodeType.DeleteTestReportAttachmentMutationInput
        ) => Promise<MutationResultFn<{ TestReportAttachment: NodeType.TestReportAttachmentMutationPayload }>>;
        create: (
            input: NodeType.CreateTestReportAttachmentMutationInput[] | NodeType.CreateTestReportAttachmentMutationInput
        ) => Promise<MutationResultFn<{ TestReportAttachment: NodeType.TestReportAttachmentMutationPayload }>>;
        update: (
            input: NodeType.UpdateTestReportAttachmentMutationInput[] | NodeType.UpdateTestReportAttachmentMutationInput
        ) => Promise<MutationResultFn<{ TestReportAttachment: NodeType.TestReportAttachmentMutationPayload }>>;
    };
}

// ************** TestReportSolution ***************** //

// TestReportSolution: Mutator
export const withTestReportSolutionMutator = withObjectMutator({
    objectName: 'TestReportSolution',
    actions: ['delete', 'create', 'update', 'override'],
});

// TestReportSolution: Mutator Interface
export interface IMutateTestReportSolution {
    mutateTestReportSolution: {
        delete: (
            input: NodeType.DeleteTestReportSolutionMutationInput[] | NodeType.DeleteTestReportSolutionMutationInput
        ) => Promise<MutationResultFn<{ TestReportSolution: NodeType.TestReportSolutionMutationPayload }>>;
        create: (
            input: NodeType.CreateTestReportSolutionMutationInput[] | NodeType.CreateTestReportSolutionMutationInput
        ) => Promise<MutationResultFn<{ TestReportSolution: NodeType.TestReportSolutionMutationPayload }>>;
        update: (
            input: NodeType.UpdateTestReportSolutionMutationInput[] | NodeType.UpdateTestReportSolutionMutationInput
        ) => Promise<MutationResultFn<{ TestReportSolution: NodeType.TestReportSolutionMutationPayload }>>;
        override: (
            input: NodeType.TestReportSolutionOverrideMutationInput[] | NodeType.TestReportSolutionOverrideMutationInput
        ) => Promise<MutationResultFn<{ TestReportSolution: NodeType.TestReportSolutionMutationPayload }>>;
    };
}

// ************** TestTreatment ***************** //

// TestTreatment: Mutator
export const withTestTreatmentMutator = withObjectMutator({
    objectName: 'TestTreatment',
    actions: ['delete', 'create', 'update', 'setPriorityForFranchise', 'setBlacklistForFranchise'],
});

// TestTreatment: Mutator Interface
export interface IMutateTestTreatment {
    mutateTestTreatment: {
        delete: (
            input: NodeType.DeleteTestTreatmentMutationInput[] | NodeType.DeleteTestTreatmentMutationInput
        ) => Promise<MutationResultFn<{ TestTreatment: NodeType.TestTreatmentMutationPayload }>>;
        create: (
            input: NodeType.CreateTestTreatmentMutationInput[] | NodeType.CreateTestTreatmentMutationInput
        ) => Promise<MutationResultFn<{ TestTreatment: NodeType.TestTreatmentMutationPayload }>>;
        update: (
            input: NodeType.UpdateTestTreatmentMutationInput[] | NodeType.UpdateTestTreatmentMutationInput
        ) => Promise<MutationResultFn<{ TestTreatment: NodeType.TestTreatmentMutationPayload }>>;
        setPriorityForFranchise: (
            input:
                | NodeType.TestTreatmentSetPriorityForFranchiseMutationInput[]
                | NodeType.TestTreatmentSetPriorityForFranchiseMutationInput
        ) => Promise<MutationResultFn<{ TestTreatment: NodeType.TestTreatmentMutationPayload }>>;
        setBlacklistForFranchise: (
            input:
                | NodeType.TestTreatmentSetBlacklistForFranchiseMutationInput[]
                | NodeType.TestTreatmentSetBlacklistForFranchiseMutationInput
        ) => Promise<MutationResultFn<{ TestTreatment: NodeType.TestTreatmentMutationPayload }>>;
    };
}

// ************** TestTreatmentFranchisePriority ***************** //

// TestTreatmentFranchisePriority: Mutator
export const withTestTreatmentFranchisePriorityMutator = withObjectMutator({
    objectName: 'TestTreatmentFranchisePriority',
    actions: ['delete', 'update'],
});

// TestTreatmentFranchisePriority: Mutator Interface
export interface IMutateTestTreatmentFranchisePriority {
    mutateTestTreatmentFranchisePriority: {
        delete: (
            input:
                | NodeType.DeleteTestTreatmentFranchisePriorityMutationInput[]
                | NodeType.DeleteTestTreatmentFranchisePriorityMutationInput
        ) => Promise<
            MutationResultFn<{ TestTreatmentFranchisePriority: NodeType.TestTreatmentFranchisePriorityMutationPayload }>
        >;
        update: (
            input:
                | NodeType.UpdateTestTreatmentFranchisePriorityMutationInput[]
                | NodeType.UpdateTestTreatmentFranchisePriorityMutationInput
        ) => Promise<
            MutationResultFn<{ TestTreatmentFranchisePriority: NodeType.TestTreatmentFranchisePriorityMutationPayload }>
        >;
    };
}

// ************** TreatmentAffect ***************** //

// TreatmentAffect: Mutator
export const withTreatmentAffectMutator = withObjectMutator({
    objectName: 'TreatmentAffect',
    actions: ['delete', 'create', 'update'],
});

// TreatmentAffect: Mutator Interface
export interface IMutateTreatmentAffect {
    mutateTreatmentAffect: {
        delete: (
            input: NodeType.DeleteTreatmentAffectMutationInput[] | NodeType.DeleteTreatmentAffectMutationInput
        ) => Promise<MutationResultFn<{ TreatmentAffect: NodeType.TreatmentAffectMutationPayload }>>;
        create: (
            input: NodeType.CreateTreatmentAffectMutationInput[] | NodeType.CreateTreatmentAffectMutationInput
        ) => Promise<MutationResultFn<{ TreatmentAffect: NodeType.TreatmentAffectMutationPayload }>>;
        update: (
            input: NodeType.UpdateTreatmentAffectMutationInput[] | NodeType.UpdateTreatmentAffectMutationInput
        ) => Promise<MutationResultFn<{ TreatmentAffect: NodeType.TreatmentAffectMutationPayload }>>;
    };
}

// ************** Unit ***************** //

// Unit: Mutator
export const withUnitMutator = withObjectMutator({
    objectName: 'Unit',
    actions: ['delete', 'create', 'update'],
});

// Unit: Mutator Interface
export interface IMutateUnit {
    mutateUnit: {
        delete: (
            input: NodeType.DeleteUnitMutationInput[] | NodeType.DeleteUnitMutationInput
        ) => Promise<MutationResultFn<{ Unit: NodeType.UnitMutationPayload }>>;
        create: (
            input: NodeType.CreateUnitMutationInput[] | NodeType.CreateUnitMutationInput
        ) => Promise<MutationResultFn<{ Unit: NodeType.UnitMutationPayload }>>;
        update: (
            input: NodeType.UpdateUnitMutationInput[] | NodeType.UpdateUnitMutationInput
        ) => Promise<MutationResultFn<{ Unit: NodeType.UnitMutationPayload }>>;
    };
}

// ************** UnitConversion ***************** //

// UnitConversion: Mutator
export const withUnitConversionMutator = withObjectMutator({
    objectName: 'UnitConversion',
    actions: ['delete', 'create', 'update'],
});

// UnitConversion: Mutator Interface
export interface IMutateUnitConversion {
    mutateUnitConversion: {
        delete: (
            input: NodeType.DeleteUnitConversionMutationInput[] | NodeType.DeleteUnitConversionMutationInput
        ) => Promise<MutationResultFn<{ UnitConversion: NodeType.UnitConversionMutationPayload }>>;
        create: (
            input: NodeType.CreateUnitConversionMutationInput[] | NodeType.CreateUnitConversionMutationInput
        ) => Promise<MutationResultFn<{ UnitConversion: NodeType.UnitConversionMutationPayload }>>;
        update: (
            input: NodeType.UpdateUnitConversionMutationInput[] | NodeType.UpdateUnitConversionMutationInput
        ) => Promise<MutationResultFn<{ UnitConversion: NodeType.UnitConversionMutationPayload }>>;
    };
}

// ************** UniversalCriteria ***************** //

// UniversalCriteria: Mutator
export const withUniversalCriteriaMutator = withObjectMutator({
    objectName: 'UniversalCriteria',
    actions: ['delete', 'create'],
});

// UniversalCriteria: Mutator Interface
export interface IMutateUniversalCriteria {
    mutateUniversalCriteria: {
        delete: (
            input: NodeType.DeleteUniversalCriteriaMutationInput[] | NodeType.DeleteUniversalCriteriaMutationInput
        ) => Promise<MutationResultFn<{ UniversalCriteria: NodeType.UniversalCriteriaMutationPayload }>>;
        create: (
            input: NodeType.CreateUniversalCriteriaMutationInput[] | NodeType.CreateUniversalCriteriaMutationInput
        ) => Promise<MutationResultFn<{ UniversalCriteria: NodeType.UniversalCriteriaMutationPayload }>>;
    };
}

// ************** VendAPI ***************** //

// VendAPI: Mutator
export const withVendAPIMutator = withObjectMutator({
    objectName: 'VendAPI',
    actions: ['delete', 'create', 'update', 'importFromVend', 'addCustomer', 'legacyReconnect', 'exportToVend'],
});

// VendAPI: Mutator Interface
export interface IMutateVendAPI {
    mutateVendAPI: {
        delete: (
            input: NodeType.DeleteVendAPIMutationInput[] | NodeType.DeleteVendAPIMutationInput
        ) => Promise<MutationResultFn<{ VendAPI: NodeType.VendAPIMutationPayload }>>;
        create: (
            input: NodeType.CreateVendAPIMutationInput[] | NodeType.CreateVendAPIMutationInput
        ) => Promise<MutationResultFn<{ VendAPI: NodeType.VendAPIMutationPayload }>>;
        update: (
            input: NodeType.UpdateVendAPIMutationInput[] | NodeType.UpdateVendAPIMutationInput
        ) => Promise<MutationResultFn<{ VendAPI: NodeType.VendAPIMutationPayload }>>;
        importFromVend: (
            input: NodeType.VendAPIImportFromVendMutationInput[] | NodeType.VendAPIImportFromVendMutationInput
        ) => Promise<MutationResultFn<{ VendAPI: NodeType.VendAPIMutationPayload }>>;
        addCustomer: (
            input: NodeType.VendAPIAddCustomerMutationInput[] | NodeType.VendAPIAddCustomerMutationInput
        ) => Promise<MutationResultFn<{ VendAPI: NodeType.VendAPIMutationPayload }>>;
        legacyReconnect: (
            input: NodeType.VendAPILegacyReconnectMutationInput[] | NodeType.VendAPILegacyReconnectMutationInput
        ) => Promise<MutationResultFn<{ VendAPI: NodeType.VendAPIMutationPayload }>>;
        exportToVend: (
            input: NodeType.VendAPIExportToVendMutationInput[] | NodeType.VendAPIExportToVendMutationInput
        ) => Promise<MutationResultFn<{ VendAPI: NodeType.VendAPIMutationPayload }>>;
    };
}

// ************** VendCustomer ***************** //

// VendCustomer: Mutator
export const withVendCustomerMutator = withObjectMutator({
    objectName: 'VendCustomer',
    actions: ['create'],
});

// VendCustomer: Mutator Interface
export interface IMutateVendCustomer {
    mutateVendCustomer: {
        create: (
            input: NodeType.VendCustomerCreateMutationInput[] | NodeType.VendCustomerCreateMutationInput
        ) => Promise<MutationResultFn<{ VendCustomer: NodeType.VendCustomerMutationPayload }>>;
    };
}

// ************** VendLink ***************** //

// VendLink: Mutator
export const withVendLinkMutator = withObjectMutator({
    objectName: 'VendLink',
    actions: ['sync'],
});

// VendLink: Mutator Interface
export interface IMutateVendLink {
    mutateVendLink: {
        sync: (
            input: NodeType.VendLinkSyncMutationInput[] | NodeType.VendLinkSyncMutationInput
        ) => Promise<MutationResultFn<{ VendLink: NodeType.VendLinkMutationPayload }>>;
    };
}

// ************** VendSale ***************** //

// VendSale: Mutator
export const withVendSaleMutator = withObjectMutator({
    objectName: 'VendSale',
    actions: ['create'],
});

// VendSale: Mutator Interface
export interface IMutateVendSale {
    mutateVendSale: {
        create: (
            input: NodeType.CreateVendSaleMutationInput[] | NodeType.CreateVendSaleMutationInput
        ) => Promise<MutationResultFn<{ VendSale: NodeType.VendSaleMutationPayload }>>;
    };
}

// ************** WarrantyClaim ***************** //

// WarrantyClaim: Mutator
export const withWarrantyClaimMutator = withObjectMutator({
    objectName: 'WarrantyClaim',
    actions: ['delete', 'create', 'update', 'executeWarrantyClaimRequest', 'forceClaimCase', 'fixUnsharedClaims'],
});

// WarrantyClaim: Mutator Interface
export interface IMutateWarrantyClaim {
    mutateWarrantyClaim: {
        delete: (
            input: NodeType.DeleteWarrantyClaimMutationInput[] | NodeType.DeleteWarrantyClaimMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaim: NodeType.WarrantyClaimMutationPayload }>>;
        create: (
            input: NodeType.CreateWarrantyClaimMutationInput[] | NodeType.CreateWarrantyClaimMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaim: NodeType.WarrantyClaimMutationPayload }>>;
        update: (
            input: NodeType.UpdateWarrantyClaimMutationInput[] | NodeType.UpdateWarrantyClaimMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaim: NodeType.WarrantyClaimMutationPayload }>>;
        executeWarrantyClaimRequest: (
            input:
                | NodeType.WarrantyClaimExecuteWarrantyClaimRequestMutationInput[]
                | NodeType.WarrantyClaimExecuteWarrantyClaimRequestMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaim: NodeType.WarrantyClaimMutationPayload }>>;
        forceClaimCase: (
            input:
                | NodeType.WarrantyClaimForceClaimCaseMutationInput[]
                | NodeType.WarrantyClaimForceClaimCaseMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaim: NodeType.WarrantyClaimMutationPayload }>>;
        fixUnsharedClaims: (
            input:
                | NodeType.WarrantyClaimFixUnsharedClaimsMutationInput[]
                | NodeType.WarrantyClaimFixUnsharedClaimsMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaim: NodeType.WarrantyClaimMutationPayload }>>;
    };
}

// ************** WarrantyClaimCase ***************** //

// WarrantyClaimCase: Mutator
export const withWarrantyClaimCaseMutator = withObjectMutator({
    objectName: 'WarrantyClaimCase',
    actions: ['delete', 'create', 'update', 'markMessagesAsRead', 'updateStatus'],
});

// WarrantyClaimCase: Mutator Interface
export interface IMutateWarrantyClaimCase {
    mutateWarrantyClaimCase: {
        delete: (
            input: NodeType.DeleteWarrantyClaimCaseMutationInput[] | NodeType.DeleteWarrantyClaimCaseMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimCase: NodeType.WarrantyClaimCaseMutationPayload }>>;
        create: (
            input: NodeType.CreateWarrantyClaimCaseMutationInput[] | NodeType.CreateWarrantyClaimCaseMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimCase: NodeType.WarrantyClaimCaseMutationPayload }>>;
        update: (
            input: NodeType.UpdateWarrantyClaimCaseMutationInput[] | NodeType.UpdateWarrantyClaimCaseMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimCase: NodeType.WarrantyClaimCaseMutationPayload }>>;
        markMessagesAsRead: (
            input:
                | NodeType.WarrantyClaimCaseMarkMessagesAsReadMutationInput[]
                | NodeType.WarrantyClaimCaseMarkMessagesAsReadMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimCase: NodeType.WarrantyClaimCaseMutationPayload }>>;
        updateStatus: (
            input:
                | NodeType.WarrantyClaimCaseUpdateStatusMutationInput[]
                | NodeType.WarrantyClaimCaseUpdateStatusMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimCase: NodeType.WarrantyClaimCaseMutationPayload }>>;
    };
}

// ************** WarrantyClaimFileAttach ***************** //

// WarrantyClaimFileAttach: Mutator
export const withWarrantyClaimFileAttachMutator = withObjectMutator({
    objectName: 'WarrantyClaimFileAttach',
    actions: ['delete', 'create', 'update'],
});

// WarrantyClaimFileAttach: Mutator Interface
export interface IMutateWarrantyClaimFileAttach {
    mutateWarrantyClaimFileAttach: {
        delete: (
            input:
                | NodeType.DeleteWarrantyClaimFileAttachMutationInput[]
                | NodeType.DeleteWarrantyClaimFileAttachMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimFileAttach: NodeType.WarrantyClaimFileAttachMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateWarrantyClaimFileAttachMutationInput[]
                | NodeType.CreateWarrantyClaimFileAttachMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimFileAttach: NodeType.WarrantyClaimFileAttachMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateWarrantyClaimFileAttachMutationInput[]
                | NodeType.UpdateWarrantyClaimFileAttachMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimFileAttach: NodeType.WarrantyClaimFileAttachMutationPayload }>>;
    };
}

// ************** WarrantyClaimMessage ***************** //

// WarrantyClaimMessage: Mutator
export const withWarrantyClaimMessageMutator = withObjectMutator({
    objectName: 'WarrantyClaimMessage',
    actions: ['delete', 'update', 'postMessage'],
});

// WarrantyClaimMessage: Mutator Interface
export interface IMutateWarrantyClaimMessage {
    mutateWarrantyClaimMessage: {
        delete: (
            input: NodeType.DeleteWarrantyClaimMessageMutationInput[] | NodeType.DeleteWarrantyClaimMessageMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimMessage: NodeType.WarrantyClaimMessageMutationPayload }>>;
        update: (
            input: NodeType.UpdateWarrantyClaimMessageMutationInput[] | NodeType.UpdateWarrantyClaimMessageMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimMessage: NodeType.WarrantyClaimMessageMutationPayload }>>;
        postMessage: (
            input:
                | NodeType.WarrantyClaimMessagePostMessageMutationInput[]
                | NodeType.WarrantyClaimMessagePostMessageMutationInput
        ) => Promise<MutationResultFn<{ WarrantyClaimMessage: NodeType.WarrantyClaimMessageMutationPayload }>>;
    };
}

// ************** WaterTestingRevision ***************** //

// WaterTestingRevision: Mutator
export const withWaterTestingRevisionMutator = withObjectMutator({
    objectName: 'WaterTestingRevision',
    actions: ['create'],
});

// WaterTestingRevision: Mutator Interface
export interface IMutateWaterTestingRevision {
    mutateWaterTestingRevision: {
        create: (
            input: NodeType.CreateWaterTestingRevisionMutationInput[] | NodeType.CreateWaterTestingRevisionMutationInput
        ) => Promise<MutationResultFn<{ WaterTestingRevision: NodeType.WaterTestingRevisionMutationPayload }>>;
    };
}

// ************** WorkOrder ***************** //

// WorkOrder: Mutator
export const withWorkOrderMutator = withObjectMutator({
    objectName: 'WorkOrder',
    actions: [
        'delete',
        'create',
        'update',
        'assignAppointmentItem',
        'addJobTodoFromTemplate',
        'appendWorkOrderTemplate',
        'replaceWorkOrderTemplate',
        'addVendSale',
    ],
});

// WorkOrder: Mutator Interface
export interface IMutateWorkOrder {
    mutateWorkOrder: {
        delete: (
            input: NodeType.DeleteWorkOrderMutationInput[] | NodeType.DeleteWorkOrderMutationInput
        ) => Promise<MutationResultFn<{ WorkOrder: NodeType.WorkOrderMutationPayload }>>;
        create: (
            input: NodeType.CreateWorkOrderMutationInput[] | NodeType.CreateWorkOrderMutationInput
        ) => Promise<MutationResultFn<{ WorkOrder: NodeType.WorkOrderMutationPayload }>>;
        update: (
            input: NodeType.UpdateWorkOrderMutationInput[] | NodeType.UpdateWorkOrderMutationInput
        ) => Promise<MutationResultFn<{ WorkOrder: NodeType.WorkOrderMutationPayload }>>;
        assignAppointmentItem: (
            input:
                | NodeType.WorkOrderAssignAppointmentItemMutationInput[]
                | NodeType.WorkOrderAssignAppointmentItemMutationInput
        ) => Promise<MutationResultFn<{ WorkOrder: NodeType.WorkOrderMutationPayload }>>;
        addJobTodoFromTemplate: (
            input:
                | NodeType.WorkOrderAddJobTodoFromTemplateMutationInput[]
                | NodeType.WorkOrderAddJobTodoFromTemplateMutationInput
        ) => Promise<MutationResultFn<{ WorkOrder: NodeType.WorkOrderMutationPayload }>>;
        appendWorkOrderTemplate: (
            input:
                | NodeType.WorkOrderAppendWorkOrderTemplateMutationInput[]
                | NodeType.WorkOrderAppendWorkOrderTemplateMutationInput
        ) => Promise<MutationResultFn<{ WorkOrder: NodeType.WorkOrderMutationPayload }>>;
        replaceWorkOrderTemplate: (
            input:
                | NodeType.WorkOrderReplaceWorkOrderTemplateMutationInput[]
                | NodeType.WorkOrderReplaceWorkOrderTemplateMutationInput
        ) => Promise<MutationResultFn<{ WorkOrder: NodeType.WorkOrderMutationPayload }>>;
        addVendSale: (
            input: NodeType.WorkOrderAddVendSaleMutationInput[] | NodeType.WorkOrderAddVendSaleMutationInput
        ) => Promise<MutationResultFn<{ WorkOrder: NodeType.WorkOrderMutationPayload }>>;
    };
}

// ************** WorkOrderFeedItem ***************** //

// WorkOrderFeedItem: Mutator
export const withWorkOrderFeedItemMutator = withObjectMutator({
    objectName: 'WorkOrderFeedItem',
    actions: ['startWorkOrder'],
});

// WorkOrderFeedItem: Mutator Interface
export interface IMutateWorkOrderFeedItem {
    mutateWorkOrderFeedItem: {
        startWorkOrder: (
            input:
                | NodeType.WorkOrderFeedItemStartWorkOrderMutationInput[]
                | NodeType.WorkOrderFeedItemStartWorkOrderMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderFeedItem: NodeType.WorkOrderFeedItemMutationPayload }>>;
    };
}

// ************** WorkOrderNote ***************** //

// WorkOrderNote: Mutator
export const withWorkOrderNoteMutator = withObjectMutator({
    objectName: 'WorkOrderNote',
    actions: ['delete', 'create', 'update'],
});

// WorkOrderNote: Mutator Interface
export interface IMutateWorkOrderNote {
    mutateWorkOrderNote: {
        delete: (
            input: NodeType.DeleteWorkOrderNoteMutationInput[] | NodeType.DeleteWorkOrderNoteMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderNote: NodeType.WorkOrderNoteMutationPayload }>>;
        create: (
            input: NodeType.CreateWorkOrderNoteMutationInput[] | NodeType.CreateWorkOrderNoteMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderNote: NodeType.WorkOrderNoteMutationPayload }>>;
        update: (
            input: NodeType.UpdateWorkOrderNoteMutationInput[] | NodeType.UpdateWorkOrderNoteMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderNote: NodeType.WorkOrderNoteMutationPayload }>>;
    };
}

// ************** WorkOrderTemplate ***************** //

// WorkOrderTemplate: Mutator
export const withWorkOrderTemplateMutator = withObjectMutator({
    objectName: 'WorkOrderTemplate',
    actions: ['delete', 'create', 'update', 'archive'],
});

// WorkOrderTemplate: Mutator Interface
export interface IMutateWorkOrderTemplate {
    mutateWorkOrderTemplate: {
        delete: (
            input: NodeType.DeleteWorkOrderTemplateMutationInput[] | NodeType.DeleteWorkOrderTemplateMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderTemplate: NodeType.WorkOrderTemplateMutationPayload }>>;
        create: (
            input: NodeType.CreateWorkOrderTemplateMutationInput[] | NodeType.CreateWorkOrderTemplateMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderTemplate: NodeType.WorkOrderTemplateMutationPayload }>>;
        update: (
            input: NodeType.UpdateWorkOrderTemplateMutationInput[] | NodeType.UpdateWorkOrderTemplateMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderTemplate: NodeType.WorkOrderTemplateMutationPayload }>>;
        archive: (
            input: NodeType.WorkOrderTemplateArchiveMutationInput[] | NodeType.WorkOrderTemplateArchiveMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderTemplate: NodeType.WorkOrderTemplateMutationPayload }>>;
    };
}

// ************** WorkOrderTemplateAssoc ***************** //

// WorkOrderTemplateAssoc: Mutator
export const withWorkOrderTemplateAssocMutator = withObjectMutator({
    objectName: 'WorkOrderTemplateAssoc',
    actions: ['delete', 'create', 'update', 'setDefault'],
});

// WorkOrderTemplateAssoc: Mutator Interface
export interface IMutateWorkOrderTemplateAssoc {
    mutateWorkOrderTemplateAssoc: {
        delete: (
            input:
                | NodeType.DeleteWorkOrderTemplateAssocMutationInput[]
                | NodeType.DeleteWorkOrderTemplateAssocMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderTemplateAssoc: NodeType.WorkOrderTemplateAssocMutationPayload }>>;
        create: (
            input:
                | NodeType.CreateWorkOrderTemplateAssocMutationInput[]
                | NodeType.CreateWorkOrderTemplateAssocMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderTemplateAssoc: NodeType.WorkOrderTemplateAssocMutationPayload }>>;
        update: (
            input:
                | NodeType.UpdateWorkOrderTemplateAssocMutationInput[]
                | NodeType.UpdateWorkOrderTemplateAssocMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderTemplateAssoc: NodeType.WorkOrderTemplateAssocMutationPayload }>>;
        setDefault: (
            input:
                | NodeType.WorkOrderTemplateAssocSetDefaultMutationInput[]
                | NodeType.WorkOrderTemplateAssocSetDefaultMutationInput
        ) => Promise<MutationResultFn<{ WorkOrderTemplateAssoc: NodeType.WorkOrderTemplateAssocMutationPayload }>>;
    };
}
