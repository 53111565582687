import * as React from 'react';
import NewCustomerForm from './NewCustomerForm';
import { PageControlProps } from './Page.Control';
import { PageLayout, PageSkeletonLoader, toastError } from '@ez/components';
import { usePersistedToggle } from '@ez/tools';
import { useViewer } from '@poolware/app-shell';
import { useAppNavigator } from '@poolware/react-app-navigator';
import * as URLBuilder from '../../../../routes/url-builder';
import { useDefaultCountry } from '../../../CommonComponents/Address/hooks';
import { fromEdges } from '@poolware/api';
import { useQueryContactTypes } from './use-query-contact-types';

interface PageProps extends PageControlProps {}

const SAME_ADDR_KEY = 'newcustomer.isCreateSiteWithSameAddress';

const usePrepareDefaultContacts = () => {
    const { modulesAccess } = useViewer();
    const { data: contactTypes, loading, error } = useQueryContactTypes();

    if (!modulesAccess.Customers?.forceContactsOnNewForm)
        return {
            loading: false,
            defaultContacts: [],
        };

    let defaultContacts = [];
    const defaultIdentificationTag = ['phone_mobile', 'email'];

    if (loading) {
        return { defaultContacts: [], loading };
    } else if (error) {
        console.error(error);
        return { defaultContacts: [], loading: false };
    } else if (!loading && contactTypes) {
        defaultContacts = fromEdges(contactTypes?.viewer?.contactTypes)
            .filter((t) => defaultIdentificationTag.includes(t.identificationTag))
            .map((t) => ({ type: t, data: '', label: '' }));
    }
    return { defaultContacts, loading };
};

export const Page: React.FC<PageProps> = (props) => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    const { defaultCountryCode, setDefaultCountryCode } = useDefaultCountry();
    const [isCreateSiteWithSameAddress, setIsCreateSiteWithSameAddress] = usePersistedToggle(SAME_ADDR_KEY, true);
    const { loading, defaultContacts } = usePrepareDefaultContacts();

    if (loading) {
        return <PageSkeletonLoader />;
    }

    const initialValues = {
        isBillingAddressSame: true,
        isCreateSiteWithSameAddress: modulesAccess.Pools?.enabled && isCreateSiteWithSameAddress,
        user: {
            title: '',
            firstName: '',
            lastName: '',
            contactName: null,
            companyName: null,
        },
        primaryAddress: {
            city: '',
            state: '',
            postCode: '',
            country: defaultCountryCode,
        },
        billAddress: {
            city: '',
            state: '',
            postCode: '',
            country: defaultCountryCode,
        },
        site: {
            name: 'Main Site',
        },
        contacts: defaultContacts,
    };

    const onCancel = async () => {
        AppNavigator.navigateToOrigin();
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            setIsCreateSiteWithSameAddress(values.isCreateSiteWithSameAddress);
            setDefaultCountryCode(values.primaryAddress?.country);
            const newCustomerId = await props.CustomerMutator.createCustomer(values);
            const link = URLBuilder.Customer(newCustomerId).view;
            AppNavigator.replace(link);
        } catch (error) {
            toastError({
                icon: 'user',
                title: 'Failed to create customer',
                description: error.message,
                time: 4000,
            });
        }
    };

    return (
        <PageLayout width={'screen-lg'}>
            <NewCustomerForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                hideNewSite={!modulesAccess.Pools?.enabled}
            />
        </PageLayout>
    );
};

export default Page;
