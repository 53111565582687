import * as React from 'react';
import { resolveIconComponent } from '../Icon/Icon';

export interface MenuBarHeaderItemProps {
    icon?: React.ReactNode;
    content?: React.ReactNode;

    // TODO: deprecated
    color?: any;
}

export const MenuBarHeaderItem: React.FC<MenuBarHeaderItemProps> = ({ content, icon, children }) => {
    const IconComp = resolveIconComponent(icon);
    return (
        <div className={'font-bold text-md md:text-lg inline-flex items-center px-2 whitespace-nowrap'}>
            <span className={'mr-1'}>{IconComp}</span>
            {content}
            {children}
        </div>
    );
};
