import { createThemeByHue } from '@ez/components';

let _t = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }
    const primHue = Math.floor(Math.random() * 360);
    const secHue = Math.floor(Math.random() * 360);
    _t = createThemeByHue({ publicPrefix, huePrim: primHue, hueSec: secHue });
    _t.themeName = 'random';
    return _t;
};
// 195, 184
