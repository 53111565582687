import { Icon, Input } from '@ez/components';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from '@ez/tools';

const SCANNER_SPEED_THRESHOLD = 100;

export interface BarcodeScannerProps {
    onChange?: (value: string) => any;
    loading?: boolean;
    onBlur?: any;
    error?: boolean;
    value?: string;
}

export const BarcodeScannerInput: React.FC<BarcodeScannerProps> = ({ value, onChange, loading, onBlur, error }) => {
    const [screenValue, setScreenValue] = useState('');
    const [burstValue, setBurstValue] = useState('');
    const ref = useRef();

    const [burstDetector] = useDebouncedCallback((value: string) => {
        const sizeDiff = value.length - burstValue?.length;
        // console.log('sizeDiff', sizeDiff);
        // console.log('burstValue', burstValue);
        // console.log('newValue', value);
        if (sizeDiff >= 4) {
            const burst = value.slice(-sizeDiff);
            // console.log('** BURST =>> ', burst);
            setBarcode(burst);
            setBurstValue(burst);
        } else {
            setBurstValue(value);
        }
    }, SCANNER_SPEED_THRESHOLD);

    const setBarcode = (value) => {
        setScreenValue(value);
        burstDetector(value);
        onChange?.(value);
    };

    useEffect(() => {
        if (!ref?.current) {
            return;
        } else {
            // @ts-ignore
            ref.current?.focus?.();
        }
    }, [ref]);

    const handleChange = (e, { value }) => {
        setBarcode(value);
    };

    return (
        <Input
            iconRight={loading && <Icon size={'big'} name={'spinner'} loading={true} />}
            ref={ref}
            placeholder={'Scan or enter barcode...'}
            id="bottleBarcodeNumber"
            autoComplete="off"
            value={value || screenValue}
            onChange={(event) => setBarcode(event.target?.value)}
            onBlur={onBlur}
            className={'my-2 !h-16 text-2xl'}
            // loading={loading}
            // error={error}
        />
    );
};
