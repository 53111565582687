import * as React from 'react';
import { NotFoundPage } from '@poolware/app-shell';
import { PageLayout } from '@ez/components';

export const NotFoundPageInContainer = () => {
    return (
        <PageLayout width={'screen-md'}>
            <NotFoundPage />
        </PageLayout>
    );
};
