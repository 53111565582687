import * as React from 'react';
import { CalendarActionProps, withCalendarActions } from '../../redux';
import { TopFilterBar } from '../../components/TopFilterBar';
import { BarProgress, DevOnly, Icon, Popup } from '@ez/components';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';
import { clsxm } from '@ez/components';

const DebugAppLayoutMode = () => {
    const layout = useCalendarLayoutState();
    return (
        <DevOnly>
            {layout.appLayoutMode.mode} {layout.layoutColumns}
        </DevOnly>
    );
};

const getTimezoneName = () => {
    try {
        if (!Intl) {
            return null;
        }
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
        // console.error(e);
        return null;
    }
};

const ContainerDiv: React.FC = (props) => {
    return (
        <div
            className={clsxm([
                'text-sm rounded print:hidden shadow h-8 pt-0 mt-2 overflow-hidden',
                'bg-popover text-popover-foreground border border-solid',
            ])}
            {...props}
        />
    );
};

const CalendarInfo: React.FC<CalendarActionProps & { isLoading?: boolean }> = ({
    CalendarState,
    CalendarAction,
    isLoading,
}) => {
    if (!CalendarState) {
        return null;
    }
    const filters = CalendarState.filters;

    const { hasActiveFilters } = filters;

    const timeZone = getTimezoneName();

    return (
        <ContainerDiv>
            <BarProgress style={{ marginTop: '0px' }} isLoading={isLoading} />

            <div className={'flex justify-between items-center pb-1 px-2'}>
                {hasActiveFilters && (
                    <div className={'flex flex-row flex-wrap gap-4 text-sm h-6 items-center'}>
                        <TopFilterBar.SummaryFilterIcon />

                        <Popup
                            size={'small'}
                            trigger={
                                <div className={'text-purple-500 text-sm'}>
                                    <Icon name={'warning sign'} /> Active Filters
                                </div>
                            }
                        >
                            <Popup.Content>
                                You have active filters.
                                <br />
                                Not all appointments may be visible.
                            </Popup.Content>
                        </Popup>

                        <>
                            {filters.excludeAppointmentGroupsIds?.length > 0 && (
                                <TopFilterBar.SummaryItem>Group</TopFilterBar.SummaryItem>
                            )}
                        </>
                        <>{!!filters.isRecurring && <TopFilterBar.SummaryItem>Recur</TopFilterBar.SummaryItem>}</>
                        <>{!!filters.teamId && <TopFilterBar.SummaryItem>Team</TopFilterBar.SummaryItem>}</>
                        <>
                            {!!filters.showWithAddressOnly && (
                                <TopFilterBar.SummaryItem>Address</TopFilterBar.SummaryItem>
                            )}
                        </>

                        <>
                            {filters.staffIds?.length > 0 && <TopFilterBar.SummaryItem>Staff</TopFilterBar.SummaryItem>}
                        </>
                        <>
                            {filters.includeStatuses.length > 0 && (
                                <TopFilterBar.SummaryItem>Status</TopFilterBar.SummaryItem>
                            )}
                        </>
                        <>
                            {(!!filters.serviceGroupId || !!filters.serviceJobTemplateId) && (
                                <TopFilterBar.SummaryItem>Service Type</TopFilterBar.SummaryItem>
                            )}
                        </>
                        <TopFilterBar.SummaryClearButton onClick={CalendarAction.clearAllFilters} />
                    </div>
                )}
                <div className={'flex-grow'} />
                <div className={'pr-2'}>
                    {!!filters.autoHideEmptyResources && (
                        <span className={'text-sm text-purple-500'}>Empty columns hidden</span>
                    )}
                </div>

                {/*<DebugAppLayoutMode />*/}
                {timeZone && <div className={'text-secondary text-xs text-right'}>Timezone: {timeZone}</div>}
            </div>
        </ContainerDiv>
    );
};
export default withCalendarActions()(CalendarInfo);
