import * as React from 'react';
import { ModuleRedirect, ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { NotFoundPage } from '@poolware/app-shell';
import { Page as HeatPumpPage } from './heat-pump-calculators';
import { Page as GasHeaterPage } from './gas-heater-calculators';
import { CombinedCalcPageDefault, CombinedCalcPageNew, CombinedCalcPageOpen } from './combined-calculators/Page';

export const Router = () => {
    return (
        <ModuleRootSwitch notFound={NotFoundPage}>
            <ModuleRedirect path={'/'} to={'/combined'} exact />
            <ModuleRoute path={`/heat-pump`} component={HeatPumpPage} />
            <ModuleRoute path={`/gas-heater`} component={GasHeaterPage} />
            <ModuleRoute path={`/combined/new`} component={CombinedCalcPageNew} />
            <ModuleRoute path={`/combined/rc/:id`} component={CombinedCalcPageOpen} />
            <ModuleRoute path={`/combined`} exact={true} component={CombinedCalcPageDefault} />
        </ModuleRootSwitch>
    );
};
