import { ConnectionTableDef, Icon, Modal, useModalCtrl } from '@ez/components';
import * as React from 'react';
import { useQueryJobTemplatesConnection } from '../../queries/use-query-job-templates-connection';
import { fromEdges, NodeType } from '@poolware/api';
import { ModuleIconNames } from '../../constants';
import { TablePickerPanel } from '../../components/TablePickerPanel';

export interface JobTodoTemplatePickerProps {
    onSubmit: (selectedItems: NodeType.JobTodoTemplate[]) => any;
    onCancel?: () => any;
    selectedItems?: NodeType.JobTodoTemplate[];
}

export const JobTodoTemplatePicker: React.FC<JobTodoTemplatePickerProps> = ({
    onSubmit,
    onCancel,
    selectedItems = [],
}) => {
    const { connectionState, connectionData } = useQueryJobTemplatesConnection({ searchAny: undefined });
    const tableDef: ConnectionTableDef<NodeType.JobTodoTemplate> = [
        {
            header: 'Title',
            sortKey: 'title',
            cell: (item) => (
                <span>
                    <Icon name={ModuleIconNames.WorkOrderItem} />
                    {item.title}
                </span>
            ),
        },
        {
            header: 'Recommended Price',
            cell: (item) => {
                if (item.recommendedPrice) {
                    return <span>$ {item.recommendedPrice}</span>;
                } else {
                    return null;
                }
            },
        },
        {
            header: 'Duration',
            cell: (item) => {
                if (!item.timeEstimate) return null;
                return <span>{item.timeEstimate} min</span>;
            },
        },
        {
            header: 'Job Items',
            cell: (item) => <span>{fromEdges(item.items).length}</span>,
        },
        {
            header: 'Owner',
            width: 3,
            cell: (item) =>
                item.franchise ? (
                    item.franchise.name
                ) : (
                    <>
                        System <Icon name={'globe'} />
                    </>
                ),
        },
    ];

    return (
        <TablePickerPanel
            panelHeader={{ content: 'Job Tasks Templates' }}
            onCancel={onCancel}
            onSubmit={onSubmit}
            hideItems={selectedItems}
            connectionState={connectionState}
            connectionData={connectionData}
            tableDef={tableDef}
        />
    );
};

export const JobTodoTemplatePickerModal: React.FC<JobTodoTemplatePickerProps & { trigger: React.ReactNode }> = ({
    onSubmit,
    trigger,
    ...props
}) => {
    const modalCtrl = useModalCtrl();

    const handleOnSubmit = async (v) => {
        await onSubmit?.(v);
        modalCtrl.onClose();
    };

    return (
        <Modal trigger={trigger} {...modalCtrl}>
            <JobTodoTemplatePicker onCancel={modalCtrl.onClose} onSubmit={handleOnSubmit} {...props} />
        </Modal>
    );
};
