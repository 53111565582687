import Page from './Page';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@ez/components';
import { NodeType, staffFragment, StaffMutatorProps, withStaffMutators, withStaffPretend } from '@poolware/api';
import { withAppNavigator } from '@poolware/react-app-navigator';
import { withViewer, WithViewerProps } from '@poolware/app-shell';
import { IAppNavigatorProps } from '@poolware/react-app-navigator';

export const StaffQuery = gql`
    query StaffQuery($staffId: ID!) {
        staff: node(id: $staffId) {
            id
            ...StaffFragment
            ... on Staff {
                archivedAt
                isSystemHidden
                archivedBy {
                    id
                }
                registration {
                    id
                    disabledAt
                }
                user {
                    id
                    entity {
                        id
                        franchise {
                            id
                            name
                            organisationType {
                                id
                                name
                            }
                        }
                    }
                }
                teams {
                    edges {
                        node {
                            id
                            title
                        }
                    }
                }
            }
        }
    }
    ${staffFragment}
`;

export default compose(
    withAppNavigator(),
    withViewer(),
    graphql(StaffQuery, {
        options: (props: any) => ({
            variables: { staffId: props?.params?.staffId },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.staff.id' }),
    mapProps((props) => {
        let staff = props?.data?.staff;
        return {
            ...props,
            staff,
        };
    }),
    withStaffPretend(),
    withStaffMutators(['StaffQuery', 'StaffListQuery'])
)(Page);

export interface PageControlProps extends StaffMutatorProps, IAppNavigatorProps, WithViewerProps {
    staff: NodeType.Staff;
    pretend: (staffId: string) => any;
}
