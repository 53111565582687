import * as React from 'react';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import AppLayout from '../Layout/AppLayout';
import SDSPage from '../Pages/SDS-Search';
import { Router as StaffRouter } from '../Pages/Staff';
import { SchedulerModuleRouterID, SchedulerRouter, ServiceJobModuleRouterID } from '@poolware/app-service-jobs';
import { NewCustomerRouter, Router as CustomerRouter } from '../Pages/CustomerManagement/Router';
import { Router as PrintRoutes } from '../Pages/Print';
import { Router as HQAdminRouter } from '../Pages/HQAdmin';
import { FranchiseDetailsRoute, FranchiseSettingsRouter } from '../Pages/FranchiseAdmin/router';
import { Router as DevRouter } from '../Pages/Dev';
import { Router as DownloadsRouter } from '../Pages/Downloads';
import { Router as RecentActivityRouter } from '../Pages/RecentActivity';
import { Router as SearchRouter } from '../Pages/Search';
import { Router as ReportsRouter } from '../Pages/Reports';
import { Router as CalculatorsRouter } from '@poolware/app-calculators';
import { WarrantyClaimsRouter } from '@poolware/app-warranty-claims';
import { Router as WaterlinkRouter } from '../Pages/Waterlink';
import { NotFoundPageInContainer } from './NotFoundPageInContainer';
import { ProductCatalogViewer } from '@poolware/app-catalog';
import { AuthenticatedSwitch } from '@poolware/app-shell';
import { ServiceJobs } from '../Pages/ServiceJobs';
import { Router as DevicesRouter } from '../Pages/Devices/Router';
import { Redirector } from '../Pages/Redirector';
import { useAppNavContext } from '@poolware/react-app-navigator';
import * as Pool from '../Pages/CustomerManagement/Pool';
import { Page as TrainingFormsPage } from '../Pages/TrainingRequests';
import { Router as WaterTestRouter } from '../Pages/CustomerManagement/WaterTest/Router';

export interface IRouteConfig {
    path: string;
    exact?: boolean;
    component: any;
    moduleID?: string;
}

const routes: IRouteConfig[] = [
    { path: '/', exact: true, component: () => <Redirect to={'/search'} /> },
    { path: '/search', component: SearchRouter },
    { path: '/dev', component: DevRouter },
    { path: '/settings', component: FranchiseSettingsRouter },
    { path: '/customer-new', component: NewCustomerRouter },
    {
        path: '/customers',
        moduleID: 'CUSTOMERS',
        component: CustomerRouter,
    },
    {
        path: '/water-test',
        moduleID: 'WATER_TEST',
        component: WaterTestRouter,
    },
    {
        path: '/devices',
        moduleID: 'DEVICES',
        component: DevicesRouter,
    },
    {
        path: '/pools',
        moduleID: 'POOLS',
        component: Pool.List,
    },
    { path: '/downloads', component: DownloadsRouter },
    { path: '/reports', component: ReportsRouter },
    {
        path: '/staff',
        moduleID: 'STAFF',
        component: StaffRouter,
    },
    { path: '/franchise-admin/my-franchise', component: FranchiseDetailsRoute },
    { path: '/admin', component: HQAdminRouter },
    { path: '/calcs', component: CalculatorsRouter },
    {
        path: '/catalog',
        moduleID: 'PRODUCT_CATALOG',
        component: ProductCatalogViewer,
    },
    { path: '/recent-activity', component: RecentActivityRouter },
    { path: '/print', component: PrintRoutes },
    { path: '/sds', component: SDSPage },
    { path: '/waterlink', component: WaterlinkRouter },
    {
        path: '/scheduler',
        moduleID: SchedulerModuleRouterID,
        component: SchedulerRouter,
    },
    {
        path: '/service-jobs',
        moduleID: ServiceJobModuleRouterID,
        component: ServiceJobs.Router,
    },
    {
        path: '/service-jobs-templates',
        moduleID: 'SERVICE_JOB_TEMPLATES',
        component: ServiceJobs.TemplatesManagerRouter,
    },
    {
        path: '/warranty-claims',
        moduleID: 'WARRANTY_CLAIMS',
        component: WarrantyClaimsRouter,
    },
    {
        path: '/training-forms',
        component: TrainingFormsPage,
    },
    { path: '/r', component: Redirector },
];

const modules = routes
    .filter((r) => !!r.moduleID)
    .reduce((prev, curr) => {
        prev[curr.moduleID] = curr.path;
        return prev;
    }, {});

export const AppRoutes: React.FC = () => {
    const appNavContext = useAppNavContext();
    useEffect(() => {
        appNavContext.registerModules(modules);
    }, []);

    return (
        <AuthenticatedSwitch>
            <Route path={'/print'} component={PrintRoutes} />
            <AppLayout>
                {({ location }) => {
                    return (
                        <Switch location={location}>
                            {routes.map((r, index) => (
                                <Route exact={r.exact} key={index} path={r.path} component={r.component} />
                            ))}
                            <Route path={'**'} component={NotFoundPageInContainer} />
                        </Switch>
                    );
                }}
            </AppLayout>
        </AuthenticatedSwitch>
    );
};
