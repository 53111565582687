import * as React from 'react';
import {
    ConnectionTableDef,
    DebugJSON,
    DefaultConnectionTable,
    Icon,
    MenuBarHeaderItem,
    MenuBarSection,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { useQueryPermissions } from './queries';

export interface PageProps {}

export const Page: React.FC<PageProps> = ({}) => {
    const { viewerPermissions, data } = useQueryPermissions();
    const myRoleName = data.viewer?.me?.staff?.role?.name;

    const tableData = Object.keys(viewerPermissions)
        .filter((i) => !i.startsWith('_'))
        .flatMap((permKey) => {
            const crud = viewerPermissions[permKey];
            return Object.keys(crud)
                .map((crudKey) => ({
                    permKey: permKey,
                    crudKey: crudKey,
                    data: crud[crudKey],
                }))
                .filter((i) => !i.crudKey?.startsWith('_'));
        });

    const tableDef: ConnectionTableDef<(typeof tableData)[0]> = [
        {
            header: 'Permission',
            cell: (r) => <>{r.permKey}</>,
        },
        {
            header: 'Action',
            cell: (r) => <>{r.crudKey}</>,
        },
        {
            header: myRoleName,
            cell: (r) => {
                return <span>{r.data ? <Icon name={'checkmark'} color={'green'} /> : <Icon name={'cancel'} />}</span>;
            },
        },
        false && {
            header: 'Dispatcher',
            cell: (r) => {
                return <span></span>;
            },
        },
        false && {
            header: 'Technician',
            cell: (r) => {
                return <span></span>;
            },
        },
    ];

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'tag'}>Permissions</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <DefaultConnectionTable tableDef={tableDef} connectionData={tableData} />
            <DebugJSON data={tableData} />
        </VStack>
    );
};
