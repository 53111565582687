import * as React from 'react';
import { fromEdges, NodeType, useQueryStaffConnection, useQueryStaffTeamsConnection } from '@poolware/api';
import { UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import { css, styled } from 'twin.macro';
import { useViewer } from '@poolware/app-shell';
import { usePersistedToggle } from '@ez/tools';
import { ActionBar, Checkbox, Dropdown, Icon, IconButton, Optionable, stringFormatters } from '@ez/components';

const { formatEntityName } = stringFormatters;

export interface ActionBarSelectProps<TeamType extends NodeType.StaffTeam = NodeType.StaffTeam> {
    options?: Optionable<TeamType>[];
    selectedTeam: TeamType;
    onChangeTeam: (option: Optionable<TeamType>) => any;
    onChange: () => any;
    checked?: boolean;
}

const TriggerDiv = styled.div<{ $highlightChecked?: boolean }>(({ $highlightChecked, theme }) => [
    $highlightChecked &&
        css`
            color: ${theme?.components?.activeFilterColor || '#a333c8'};
        `,
    css`
        text-decoration: underline;
        width: calc(100% - 2rem);
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    `,
]);

export const ActionBarSelectTeam: React.FC<ActionBarSelectProps> = ({
    options,
    selectedTeam,
    onChangeTeam,
    onChange,
    checked,
}) => {
    const handleChangeSelect = (value) => {
        onChangeTeam(value);
    };

    const selectedTeamOption = options.find((o) => o.value?.id === selectedTeam?.id);

    const trigger = selectedTeamOption?.text ? (
        <TriggerDiv $highlightChecked={true}>
            <Icon name={'users'} /> {selectedTeamOption?.text}
        </TriggerDiv>
    ) : (
        <TriggerDiv>All</TriggerDiv>
    );

    return (
        <ActionBar.Item dividing={true}>
            <div className={'p-1 flex-initial'}>
                <Checkbox onClick={onChange} checked={checked} />
            </div>
            <div className={'p-1 flex-auto pr-4 min-w-0'}>
                <Dropdown clearable={true} trigger={trigger} fluid>
                    <Dropdown.Menu style={{ width: '100%', maxWidth: '100%' }}>
                        <Dropdown.Header icon="users" content="Teams" />
                        <Dropdown.Item
                            text={<i>All Teams</i>}
                            selected={!selectedTeam}
                            onClick={() => handleChangeSelect(null)}
                        />
                        <Dropdown.Menu scrolling>
                            {options.map((option, i) => {
                                const isSelected = selectedTeamOption?.value === option.value;
                                return (
                                    <Dropdown.Item
                                        key={i}
                                        text={option.text}
                                        selected={isSelected}
                                        onClick={() => handleChangeSelect(option)}
                                    />
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </ActionBar.Item>
    );
};

interface StaffFilterProps {
    franchiseId?: NodeType.ID;
    staffIds: NodeType.ID[];
    setStaffFilter: (i: { includeIds: string[] }) => any;
    teamId?: NodeType.ID | null;
    setStaffTeam?: (teamId: NodeType.ID | null) => any;
    singleSelect: boolean;
    useUnassignedFilter?: boolean;
    forceMeFilter?: boolean;
    enableTeams?: boolean;
}

export const ActionBarFilterByStaff: React.FC<StaffFilterProps> = ({
    setStaffFilter,
    staffIds,
    teamId,
    setStaffTeam,
    singleSelect = true,
    useUnassignedFilter = true,
    forceMeFilter = false,
    enableTeams = false,
    franchiseId,
}) => {
    const { modulesAccess } = useViewer();
    const [expanded, setExpanded] = usePersistedToggle('sj.staff.filter.toggle', true);
    const { connectionData: teams, connectionState: teamConState } = useQueryStaffTeamsConnection({
        skip: !enableTeams || forceMeFilter,
        franchiseId: franchiseId,
    });
    const { connectionData: staffListFull, connectionState: staffConState } = useQueryStaffConnection({
        skip: forceMeFilter,
        franchiseId: franchiseId,
    });

    const isLoading = teamConState.loading || staffConState.loading;

    const teamOptions = teams.map<Optionable<NodeType.StaffTeam>>((t) => ({
        text: t.title,
        value: t,
    }));
    const showTeamSelector = enableTeams && modulesAccess.Staff?.teams && teamOptions.length > 0;

    const selectedTeam = teams?.find((t) => t.id === teamId);
    let staffList = staffListFull;

    if (showTeamSelector && selectedTeam) {
        if (selectedTeam) {
            const staffInTeam = fromEdges(selectedTeam.staff).map((s) => s.id);
            staffList = staffListFull.filter((staff) => {
                return staffInTeam.includes(staff.id);
            });
        }
    }

    const onChangeTeam = (option: Optionable<NodeType.StaffTeam>) => {
        const newId = option?.value?.id || null;
        if (selectedTeam?.id === newId) {
            // team hasn't changed, noop
            return;
        }
        setStaffTeam?.(newId);

        // reset staff filter when team id changes.
        if (staffIds?.length != 0) {
            setStaffFilter({ includeIds: [] });
        }
    };

    const onShowAll = () => {
        setStaffFilter({ includeIds: [] });
    };

    const onFilterChange = (staffId: NodeType.ID, checked: boolean) => {
        if (singleSelect) {
            if (checked) {
                setStaffFilter({ includeIds: [staffId] });
            } else {
                setStaffFilter({ includeIds: [] });
            }
            return;
        }

        // Check whether the staff is in the inclusion list
        const isInFilterArray = staffIds.findIndex((id) => id === staffId);

        if (checked) {
            // if staff is checked, then add it to exclusion list.
            if (isInFilterArray < 0) {
                // it is not in the list, adding it.
                const newFilters = [...staffIds, staffId];
                setStaffFilter({ includeIds: newFilters });
            }
        } else {
            // if staff is unchecked, then remove it from inclusion list.
            if (isInFilterArray >= 0) {
                // it is already in the list, removing it
                let a = [...staffIds];
                a.splice(isInFilterArray, 1);
                setStaffFilter({ includeIds: a });
            }
        }
    };

    if (forceMeFilter) {
        return (
            <ActionBar>
                <ActionBar.Header content={'Staff'} icon={'filter'} />
                <ActionBar.ItemCheckbox
                    radio={false}
                    checked={true}
                    disabled={true}
                    onChange={undefined}
                    content={'Me'}
                    icon="user outline"
                    labelStyle={{ fontStyle: 'italic' }}
                />
            </ActionBar>
        );
    }

    const hasFilter = staffIds.length > 0;
    return (
        <ActionBar active={hasFilter}>
            <ActionBar.Header
                content={'Staff'}
                icon={'filter'}
                actionComponent={
                    <IconButton
                        size={'xs'}
                        icon={expanded ? 'chevron down' : 'chevron right'}
                        color={'grey'}
                        onClick={() => setExpanded(!expanded)}
                    />
                }
            />
            {expanded && (
                <ActionBar.Body>
                    {showTeamSelector ? (
                        <ActionBarSelectTeam
                            options={teamOptions}
                            selectedTeam={selectedTeam}
                            onChangeTeam={onChangeTeam}
                            checked={staffIds.length === 0}
                            onChange={onShowAll}
                        />
                    ) : (
                        <ActionBar.ItemCheckbox
                            dividing={true}
                            highlightChecked={false}
                            checked={staffIds.length === 0}
                            onChange={onShowAll}
                            content={'All'}
                        />
                    )}
                    {isLoading ? (
                        <ActionBar.Item>
                            <div className={'w-full'}>
                                {/*<Placeholder fluid={true}>*/}
                                {/*    <Placeholder.Line length="full" />*/}
                                {/*    <Placeholder.Line length="full" />*/}
                                {/*</Placeholder>*/}
                                loading...
                            </div>
                        </ActionBar.Item>
                    ) : (
                        <ActionBar.Scroll>
                            {useUnassignedFilter && (
                                <ActionBar.ItemCheckbox
                                    radio={singleSelect}
                                    checked={staffIds.includes(UNASSIGNED_APPOINTMENT_STAFF_ID)}
                                    onChange={(checked) => onFilterChange(UNASSIGNED_APPOINTMENT_STAFF_ID, checked)}
                                    content={'Unassigned'}
                                    icon="user outline"
                                    labelStyle={{ fontStyle: 'italic' }}
                                />
                            )}

                            {staffList.map((staff) => {
                                return (
                                    <ActionBar.ItemCheckbox
                                        highlightChecked={true}
                                        radio={singleSelect}
                                        key={staff.id}
                                        checked={staffIds.includes(staff.id)}
                                        onChange={(checked) => onFilterChange(staff.id, checked)}
                                        icon={'user'}
                                        content={formatEntityName(staff)}
                                    />
                                );
                            })}
                        </ActionBar.Scroll>
                    )}
                </ActionBar.Body>
            )}
        </ActionBar>
    );
};
