import * as React from 'react';
import {
    Button,
    MenuBar,
    Modal,
    SectionHeader,
    Segment,
    SemanticToastType,
    toast,
    ViewJSON,
    VStack,
} from '@ez/components';
import { compose } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { getRollbar, withAppConfig, WithAppConfigProps } from '../../app-config';
import { connectWithTheme, ThemeStateProps } from '../../app-theme';

interface PageProps extends ThemeStateProps, WithAppConfigProps, IAppNavigatorProps {}

class Page extends React.Component<PageProps, any> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            throwTest: false,
            showModal: false,
        };
    }

    goBack = () => {
        this.props.AppNavigator.navigate('/');
    };

    showToast = (toastType: SemanticToastType) => () => {
        toast({
            type: toastType,
            title: 'Alert ' + toastType,
            description: 'In Icon (created by Message), SemanticToastContainer.',
        });
    };

    renderSystemConf = () => {
        const rollbar = getRollbar() || {};
        const appConfig = this.props.appConfig;

        return (
            <div className={'grid gap-2'}>
                <Segment>
                    <SectionHeader content={'App Config'} />
                    <ViewJSON data={appConfig} />
                </Segment>
                <Segment>
                    <SectionHeader content={'Rollbar'} />
                    <ViewJSON data={rollbar.options || {}} />
                </Segment>

                <Segment>
                    <SectionHeader content={'Server manifest'} />
                    <ViewJSON data={window['serverManifest']} />
                </Segment>

                <Segment>
                    <SectionHeader content={'process.env'} />
                    <ViewJSON data={process.env} />
                </Segment>
            </div>
        );
    };

    renderToastButtons = () => {
        return (
            <div>
                <Button onClick={this.showToast('error')} content={'Error'} />
                <Button onClick={this.showToast('info')} content={'Info'} />
                <Button onClick={this.showToast('success')} content={'Success'} />
                <Button onClick={this.showToast('warning')} content={'Warning'} />
            </div>
        );
    };

    renderUIComps = () => {
        return (
            <div className={'grid gap-2'}>
                <Segment>
                    <SectionHeader content={'Toaster'} />
                    {this.renderToastButtons()}
                </Segment>

                <Segment>
                    <Modal trigger={<Button content={'Test in Modal'} />} closeIcon>
                        <SectionHeader content="Toasters" />
                        <Modal.Content>{this.renderToastButtons()}</Modal.Content>
                    </Modal>
                </Segment>

                <Segment>
                    <SectionHeader content={'Crash Logger Tests'} />
                    {this.state.throwTest && this.state.throwTestError()}
                    <p>Tests crash loggers</p>
                    <Button onClick={this.crash} content={'Crash'} color={'red'} basic icon={'warning sign'} />
                </Segment>
            </div>
        );
    };

    // panes = [
    //     { menuItem: 'System', render: () => <Tab.Pane>{this.renderSystemConf()}</Tab.Pane> },
    //     { menuItem: 'Other', render: () => <Tab.Pane>{this.renderUIComps()}</Tab.Pane> },
    // ];
    //
    // const tabCtrl = useTabCtrl({ panes, defaultTab: AppNavigator.tab, onTabChange: AppNavigator.setTab });

    render() {
        return (
            <VStack>
                <MenuBar.Root>
                    <MenuBar.Section>
                        <MenuBar.Item onClick={this.goBack} icon={'chevron left'} title={'Back'} />
                    </MenuBar.Section>
                </MenuBar.Root>
                <SectionHeader size={'small'} content={'Dev Tools - Admins ONLY'} />
                {/*<PageTabs {...tabCtrl}>*/}
            </VStack>
        );
    }

    private crash = () => {
        this.setState({ throwTest: true });
    };
}

export default compose(withAppNavigator(), connectWithTheme(), withAppConfig())(Page);
