import { Modal, Panel, PhotoFileGalleryPanel, SectionHeader, toastError, useModalCtrl } from '@ez/components';
import { NodeType } from '@poolware/api';
import * as React from 'react';
import { FileAttachmentUploader } from '@poolware/app-shell';
import { FileListTable } from './FileListTable';

export interface FileAttachmentPanelProps {
    fileAttachments: NodeType.FileUpload[];
    readOnly?: boolean;
    removeFiles: (files: NodeType.FileUpload[]) => Promise<any>;
    addFiles: (fileIDs: NodeType.ID[]) => Promise<any>;
}

export const PanelFileAttachments: React.FC<FileAttachmentPanelProps> = ({
    fileAttachments,
    removeFiles,
    addFiles,
    readOnly,
}) => {
    const modalCtrl = useModalCtrl(false);

    const onFileAttachmentRemove = async (files: NodeType.FileUpload[]) => {
        try {
            await removeFiles(files);
        } catch (e) {
            toastError(e);
        }
    };

    const onFileUploadComplete = async (fileId) => {
        await addFiles([fileId]);
    };

    const photoFiles = fileAttachments
        .filter((f) => f?.isImage && false)
        .map((f) => {
            if (f.imageUrl) {
                return { ...f, urlThumbnail: f.imageUrl + '?size=sm' };
            } else {
                return f;
            }
        });

    // const nonPhotoFiles = fileAttachments.filter((f) => !f.isImage);
    const nonPhotoFiles = fileAttachments;

    return (
        <>
            <SectionHeader button={!readOnly && { content: 'Add', icon: 'plus', onClick: modalCtrl.onOpen }}>
                File Attachments
            </SectionHeader>

            <PhotoFileGalleryPanel files={photoFiles} onDeleteFiles={onFileAttachmentRemove} />

            <>
                {/*<SectionHeader>Documents</SectionHeader>*/}
                <FileListTable
                    fileAttachments={nonPhotoFiles}
                    onFileAttachmentRemove={(f) => onFileAttachmentRemove([f])}
                    readOnly={readOnly}
                />
            </>

            <Modal {...modalCtrl}>
                <Panel>
                    <Panel.Header
                        content={'Upload Files'}
                        button={{ content: 'Close', icon: 'cancel', onClick: modalCtrl.onClose }}
                    />
                    <Panel.Body>
                        <FileAttachmentUploader
                            onFileUploadComplete={onFileUploadComplete}
                            onDone={modalCtrl.onClose}
                        />
                    </Panel.Body>
                </Panel>
            </Modal>
        </>
    );
};
