import * as React from 'react';
import { useCallback } from 'react';
import { useAppointmentItemQuery } from '../AppointmentPreview/use-appointment-item-query';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Alert, AlertHeader, DisplayApolloError, Icon, JSONView, PageSkeletonLoader, toastError } from '@ez/components';
import { NodeType, useMutationWorkOrder } from '@poolware/api';
import { ApolloError } from 'apollo-client';
import { AppErrorBoundary } from '@poolware/app-shell';
import { AppointmentItemDockViewQuery } from '../AppointmentDock/query-appointment-item-dock-view';
import { useAppointmentMutators } from '../../queries/use-appointment-mutators';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { useWorkOrderMutators } from '../../queries/mutators-work-order';
import { MobileStyles } from './Router';

const LoadErrorMessage: React.FC<{ error?: ApolloError }> = ({ error }) => {
    return (
        <div className={'p-2'}>
            <Alert>
                <AlertHeader>
                    <Icon name={'frown'} /> Failed to load appointment details.
                </AlertHeader>
            </Alert>
            {error && <DisplayApolloError error={error} />}
        </div>
    );
};

export interface AppointmentRootContextType {
    appointmentItem?: NodeType.AppointmentItem;
    refetchQueries?: any[];
}

const AppointmentRootContext = React.createContext<AppointmentRootContextType>({
    appointmentItem: null,
});

export const useMobileAppointmentCtx = (refetchQueries?: any[], awaitRefetchQueries?: boolean) => {
    const previewItemCtx = React.useContext(AppointmentRootContext);
    const rq = [...previewItemCtx.refetchQueries, ...(refetchQueries || [])];
    const appointmentItemMutator = useAppointmentMutators(rq);
    const serviceJobMutator = useServiceJobMutators({
        refetchQueries: rq,
    });
    const workOrderMutator = useWorkOrderMutators({
        refetchQueries: rq,
    });
    const isWorkOrderPending = previewItemCtx.appointmentItem?.isWorkOrderPending;

    const startWorkOrder = useCallback(async () => {
        try {
            if (!previewItemCtx.appointmentItem?.id) {
                throw new Error('Appointment item ID is missing');
            }
            await appointmentItemMutator.mutateAppointmentItem.startWorkOrder({
                id: previewItemCtx.appointmentItem.id,
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    }, [appointmentItemMutator, previewItemCtx.appointmentItem?.id]);

    const workOrder = previewItemCtx.appointmentItem?.workOrder;
    const serviceJob = previewItemCtx.appointmentItem?.serviceJob;
    return {
        ...previewItemCtx,
        workOrder,
        serviceJob,
        isWorkOrderPending,
        appointmentItemMutator,
        serviceJobMutator,
        workOrderMutator,
        startWorkOrder,
    };
};

export const MobileAppointmentViewRoot: React.FC = (props) => {
    const { params } = useAppNavigator();
    const id = params.appointmentItem;
    const queryRes = useAppointmentItemQuery(id, {
        query: AppointmentItemDockViewQuery,
    });

    if (queryRes.isLoading && !queryRes.appointmentItem) {
        return (
            <div className={'p-2'}>
                <PageSkeletonLoader lineCount={5} />
            </div>
        );
    } else if (!queryRes.isLoading && !queryRes.appointmentItem) {
        return <LoadErrorMessage />;
    }

    const appointmentItem = queryRes.appointmentItem;
    const ctx = {
        appointmentItem,
        refetchQueries: [queryRes.refetchQuery],
    };

    return (
        <AppErrorBoundary>
            <AppointmentRootContext.Provider value={ctx}>
                {props.children}
                {/*<JSONView data={queryRes} jsonExpandLevel={0} />*/}
            </AppointmentRootContext.Provider>
            <MobileStyles />
        </AppErrorBoundary>
    );
};
