import { useAppNavigator } from '@poolware/react-app-navigator';
import { default as React } from 'react';
import { toastError } from '@ez/components';
import _toNumber from 'lodash/toNumber';
import { Icon, PageLayout } from '@ez/components';
import { ModuleIconNames } from '../../constants';
import { useJobTodoTemplateMutators } from '../../queries/mutators-job-todo-templates';
import { useViewerContext } from '@poolware/app-shell';
import { NodeType } from '@poolware/api';
import { JobTodoTemplateForm } from './JobTodoTemplateForm';

export const JobTodoTemplateNew: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const viewerCtx = useViewerContext();

    const jobTodoTemplateMutators = useJobTodoTemplateMutators({
        refetchQueries: ['QueryJobTotoTemplatesList'],
        awaitRefetchQueries: true,
    });

    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };

    const initialValues = {
        title: '',
        description: '',
        items: [],
        timeEstimate: 0,
        recommendedPrice: 0,
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const prepareItemPayload = (item) => {
                return {
                    title: item.title?.trim() || '',
                    description: item.description?.trim() || '',
                    orderIndex: item.orderIndex,
                };
            };
            const toCreate = values.items
                .filter((i) => !!i.title) // remove items with empty titles
                .map(prepareItemPayload);

            const conf: NodeType.CreateJobTodoTemplateMutationInput = {
                title: values.title?.trim() || '',
                description: values.description?.trim() || '',
                timeEstimate: _toNumber(values.timeEstimate),
                recommendedPrice: values.recommendedPrice ? _toNumber(values.recommendedPrice) : undefined,
                items: toCreate,
            };

            if (viewerCtx.isAdmin) {
                // Need to add org Id to make template global for organisation
                conf.organisation = viewerCtx?.viewer?.organisation?.id;
            }
            const res = await jobTodoTemplateMutators.create(conf);

            const id = res?.data?.JobTodoTemplate?.JobTodoTemplate?.id;
            AppNavigator.replace(`/job-todo-template/${id}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to create', description: e.message });
        }
    };

    // TODO: add validation

    return (
        <PageLayout width={'screen-md'}>
            <JobTodoTemplateForm
                header={
                    <>
                        <Icon name={ModuleIconNames.JobTodoTemplate} /> New Job Task Template
                    </>
                }
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                submitButton={{ content: 'Next' }}
            />
        </PageLayout>
    );
};
