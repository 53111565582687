import * as React from 'react';
import { Button, Segment } from '@ez/components';
import { useWorkOrderListActions } from '../../redux';

export const ClearAllFilters: React.FC = () => {
    const { WorkOrderListAction, WorkOrderListState } = useWorkOrderListActions();
    const clearAll = () => {
        WorkOrderListAction.clearAllFilters();
    };

    const { hasActiveFilters } = WorkOrderListState.filters;
    return (
        <Segment className={'p-1'}>
            <Button
                disabled={!hasActiveFilters}
                size={'sm'}
                icon={'close'}
                color={hasActiveFilters ? 'purple' : 'grey'}
                onClick={clearAll}
                content={'Clear all filters'}
            />
        </Segment>
    );
};
