import * as React from 'react';
import { Display, FormikDefaultForm, FormikDefaultFormProps, Panel } from '@ez/components';
import { NodeType } from '@poolware/api';
import { FormikCustomerAndLocationInputFields, FormikSuggestInputStaff } from '@poolware/app-shell';
import { FormikServiceJobTemplateSelect } from '../../../connected-components/FormikServiceJobTemplateSelect';
import { ModuleIconNames } from '../../../constants';
import { recurrenceToString } from '../../utils';

export interface FormRecurApptToRecurCaseConverterProps extends FormikDefaultFormProps {
    appointmentItem: NodeType.AppointmentItem;
}

export const FormRecurApptToRecurCaseConverter: React.FC<FormRecurApptToRecurCaseConverterProps> = ({
    initialValues,
    appointmentItem,
    ...rest
}) => {
    const hasCustomer = !!initialValues.customer?.id;
    const hasPool = !!initialValues.pool?.id;
    const recurrence = appointmentItem?.appointment?.recurrence;

    return (
        <FormikDefaultForm
            debug={true}
            header={'Convert to Recurring Service'}
            initialValues={initialValues}
            submitButton={{ content: 'Create Recurring Service' }}
            {...rest}
        >
            {({ values, setFieldValue }) => {
                const onDidSelectTemplate = (
                    serviceJobTemplate: NodeType.ServiceJobTemplate,
                    workOrderTemplates: NodeType.WorkOrderTemplate[]
                ) => {
                    setFieldValue('woTemplate', workOrderTemplates?.[0]);
                };

                return (
                    <>
                        <Panel.SectionHeader size={'small'} icon={ModuleIconNames.Appointment}>
                            Appointment
                        </Panel.SectionHeader>
                        <Panel.Item label="Time" labelIcon="clock">
                            <Display.DateRange
                                startDate={appointmentItem.startDate}
                                duration={appointmentItem.duration}
                            />
                        </Panel.Item>
                        <Panel.Item label="Duration" labelIcon="clock">
                            {appointmentItem.duration} min
                        </Panel.Item>
                        {appointmentItem.isRecurring && (
                            <Panel.Item label="Recurring" labelIcon="refresh">
                                <div style={{ maxWidth: '330px', fontSize: '0.95em' }}>
                                    {recurrenceToString(recurrence)}
                                </div>
                            </Panel.Item>
                        )}
                        <Panel.Divider />

                        <FormikCustomerAndLocationInputFields
                            customerRequired={true}
                            readOnlyCustomer={hasCustomer}
                            readOnlyPool={hasPool}
                        />
                        <FormikSuggestInputStaff label={'Default Assigned To'} name={'staff'} />
                        <FormikServiceJobTemplateSelect
                            name={'sjTemplate'}
                            selectWorkOrder={'single'}
                            requiredWorkOrder={true}
                            required={true}
                            onDidSelect={onDidSelectTemplate}
                            previewWorkOrderIds={[values.woTemplate?.id]}
                        />
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};
