import * as React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { NodeType } from '@poolware/api';
import { Checkbox, CheckboxProps, DevOnly, IconButton, Icon } from '@ez/components';
import { Alert, AlertHeader } from '@ez/components';

export const AsyncCheckbox: React.FC<CheckboxProps> = ({ onChange, ...rest }) => {
    const [isChanging, setChanging] = useState(false);

    const handleChange = useCallback(
        async (event, data) => {
            setChanging(true);
            await onChange(event, data);
            setChanging(false);
        },
        [onChange]
    );

    if (isChanging) {
        return <Icon loading={true} name={'spinner'} />;
    }

    return <Checkbox toggle {...rest} onChange={handleChange} />;
};

export const PriorityChanger: React.FC<{
    onPriorityChange: (newValue: number) => any;
    priority: number;
    maxPriority: number;
}> = ({ onPriorityChange, priority }) => {
    const [isChangingUp, setChangingUp] = useState(false);
    const [isChangingDown, setChangingDown] = useState(false);

    const onNewValue = (setChanging) => async (newValue: number) => {
        setChanging(true);
        await onPriorityChange(newValue);
        setChanging(false);
    };

    const onUp = () => {
        return onNewValue(setChangingUp)(priority + 1);
    };
    const onDown = () => {
        return onNewValue(setChangingDown)(priority - 1);
    };

    const canUp = true; ///priority <= maxPriority;
    const canDown = priority > 0;
    const isChanging = isChangingDown || isChangingUp;

    return (
        <span style={{ whiteSpace: 'nowrap' }}>
            <IconButton
                disabled={!canUp || isChanging}
                loading={isChangingUp}
                onClick={onUp}
                icon={isChangingUp ? 'spinner' : 'chevron up'}
                color={'primary'}
            />
            {priority}
            <IconButton
                disabled={!canDown || isChanging}
                loading={isChangingDown}
                onClick={onDown}
                icon={isChangingDown ? 'spinner' : 'chevron down'}
                color={'primary'}
            />
        </span>
    );
};

export const ConsumableProduct: React.FC<{ consumableProduct?: NodeType.ConsumableProduct }> = ({
    consumableProduct,
}) => {
    return <span>{consumableProduct?.product?.name || '--'}</span>;
};

const StyledAffectSpan = styled.span`
    font-size: 0.9em;
    font-stretch: condensed;
    font-family: monospace, serif;
`;

export const AffectsList: React.FC<{ affects: NodeType.TreatmentAffect[] }> = ({ affects }) => {
    return (
        <>
            {affects.map((a) => {
                return <StyledAffectSpan key={a.id}>{a.measurementType && a.measurementType.name}</StyledAffectSpan>;
            })}
        </>
    );
};

export const RequestError: React.FC<{ error?: any }> = ({ error }) => {
    const errorMsg = error?.networkError?.result || error;
    return (
        <Alert type={'error'}>
            <AlertHeader>
                We're sorry but something went wrong <Icon name={'frown'} />
                <DevOnly>{errorMsg}</DevOnly>
            </AlertHeader>
        </Alert>
    );
};
