import * as React from 'react';

import { Icon, ConfirmButton } from '@ez/components';
import { Alert } from '@ez/components';

/// DEACTIVATE

export const AccountName: React.FC<{ email?: string }> = ({ email }) => {
    if (!email) return null;
    return (
        <b>
            Account : <code style={{ color: 'blue' }}>{email}</code>
        </b>
    );
};

export const DeactivationHeader = () => {
    return (
        <span>
            <Icon name={'warning sign'} color={'yellow'} />
            Deactivate Account?
        </span>
    );
};

export const DeactivationWarningMessage: React.FC<{ email?: string }> = ({ email }) => {
    return (
        <Alert type={'warning'}>
            <AccountName email={email} />
            <p>If you deactivate this account, the account owner will not be able to login into the system anymore.</p>
            <p>All the data associated with this account will not be deleted.</p>
            <p>You can reactivate this account later.</p>
        </Alert>
    );
};

export const ConfirmDeactivateButton: React.FC<{ email?: string; onClick: () => any }> = ({ email, onClick }) => {
    return (
        <ConfirmButton
            size={'sm'}
            icon={'user cancel'}
            color="orange"
            modalSize={'small'}
            content={'Deactivate Account'}
            onClick={onClick}
            confirmMessage={{ header: <DeactivationHeader />, content: <DeactivationWarningMessage email={email} /> }}
            confirmButton={{ content: 'Deactivate Account' }}
        />
    );
};

export const PasswordInvalidationWarningMessage: React.FC<{ email?: string }> = ({ email }) => {
    return (
        <Alert type={'warning'}>
            <AccountName email={email} />
            <p>
                If you proceed, the account owner will not be able to login into the system using their current
                password.
            </p>
            <p>The account owner will have to reset password and create a new one.</p>
        </Alert>
    );
};

export const ConfirmInvalidatePasswordButton: React.FC<{ email?: string; onClick: () => any }> = ({
    email,
    onClick,
}) => {
    return (
        <ConfirmButton
            size={'small'}
            icon={'key'}
            color="secondary"
            modalSize={'small'}
            content={'Invalidate Account Password'}
            onClick={onClick}
            popup={{
                content: (
                    <div>
                        Invalidates current password for <i>{email}</i> account. Password reset will be required.
                    </div>
                ),
            }}
            confirmMessage={{
                header: 'Invalidate Password',
                content: <PasswordInvalidationWarningMessage email={email} />,
            }}
            confirmButton={{ content: 'Invalidate Password' }}
        />
    );
};

/// DELETE

export const ArchiveStaffWarningMessage: React.FC<{ email?: string }> = ({ email }) => {
    return (
        <Alert type={'warning'}>
            <AccountName email={email} />
            <p>
                This action deletes <b>{email}</b> account and archives staff. It will not be possible to reactivate
                login for <b>{email}</b> account.
            </p>
            <p>
                Staff account will no longer appear in staff lists. However their activity will be preserved. It will be
                possible to access their activities later.
            </p>
            <p>There is no undo for this action!</p>
        </Alert>
    );
};

const ArchiveStaffMessageHeader = () => {
    return (
        <span>
            <Icon name={'warning sign'} color={'red'} />
            Archive Account?
        </span>
    );
};

export const ConfirmArchiveStaffButton: React.FC<{ email?: string; onClick: () => any }> = ({ email, onClick }) => {
    return (
        <ConfirmButton
            basic
            size={'mini'}
            floated={'right'}
            icon={'trash'}
            color="red"
            modalSize={'small'}
            negative={true}
            content={'Archive'}
            onClick={onClick}
            confirmMessage={{
                header: <ArchiveStaffMessageHeader />,
                content: <ArchiveStaffWarningMessage email={email} />,
            }}
            confirmButton={{ content: 'Archive Account' }}
        />
    );
};

/// REACTIVATE

const ReactivationWarningMessage: React.FC<{ email?: string }> = ({ email }) => {
    return (
        <Alert type={'warning'}>
            <AccountName email={email} />
            <p>If you reactivate this account, the account owner will be able to login into the system again.</p>
        </Alert>
    );
};

const ReactivationHeader = () => {
    return (
        <span>
            <Icon name={'warning sign'} color={'yellow'} />
            Reactivate Account?
        </span>
    );
};

export const ConfirmReactivateButton: React.FC<{ email?: string; onClick: () => any }> = ({ email, onClick }) => {
    return (
        <ConfirmButton
            basic
            size={'mini'}
            floated={'right'}
            icon={'add user'}
            color="blue"
            modalSize={'small'}
            negative={false}
            content={'Reactivate'}
            onClick={onClick}
            confirmMessage={{ header: <ReactivationHeader />, content: <ReactivationWarningMessage email={email} /> }}
            confirmButton={{ content: 'Reactivate Account' }}
        />
    );
};
