import * as React from 'react';
import {
    Alert,
    ButtonWithConfirm,
    ButtonWithPopup,
    MenuBar,
    MenuBarGroup,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    PageLayout,
    Segment,
    StickyMenuBar,
    toastError,
    toastSuccess,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType, useMutationWarrantyClaim, useMutationWarrantyClaimFileAttach } from '@poolware/api';
import { SubmissionSteps } from '../SubmissionSteps';
import { FileAttachmentPanel } from '../../components/FileAttachments/FileAttachmentPanel';
import { DisplayWarranter, WarrantyPreview } from '../../components/WarrantyPreview';

const READ_ONLY_MSG = 'This claim has been submitted to the office. It is read only.';

const SubmitConfirmButton: React.FC<{ onClick; claim: NodeType.WarrantyClaim }> = ({ claim, onClick }) => {
    return (
        <ButtonWithConfirm
            confirm={{
                confirmButton: { content: 'Send request email', icon: 'send' },
                confirmMessage: {
                    header: 'Send warranty claim request?',
                    content: (
                        <>
                            <p>An email with the claim details will be sent </p>

                            <p style={{ paddingLeft: '1rem' }}>
                                <b style={{ display: 'inline-block', width: '50px' }}>To: </b>
                                <DisplayWarranter warranter={claim.receiverWarranter} />
                            </p>
                            <p style={{ paddingLeft: '1rem' }}>
                                <b style={{ display: 'inline-block', width: '50px' }}>From: </b>
                                {claim.emailReplyTo || '--'}
                            </p>
                        </>
                    ),
                },
            }}
            popup={{ content: 'Send warranty claim to the nominated Destination Office' }}
            primary
            icon={'send'}
            basic={false}
            size={'medium'}
            content={'Send Request'}
            onClick={onClick}
        />
    );
};

export interface PageProps {
    claim: NodeType.WarrantyClaim;
    refetchQuery: any;
}

export const PagePreview: React.FC<PageProps> = ({ claim, refetchQuery }) => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const { executeWarrantyClaimRequest, delete: deleteMutation } = useMutationWarrantyClaim({
        refetchQueries: ['QueryWarrantyClaimsConnection', refetchQuery],
        awaitRefetchQueries: true,
    });

    const mutatorWarrantyClaimFileAttach = useMutationWarrantyClaimFileAttach({
        refetchQueries: ['QueryWarrantyClaimsConnection', refetchQuery],
        awaitRefetchQueries: true,
    });

    const isSubmitted = Boolean(claim.submittedAt);
    const canDelete = claim.checkMutations?.delete;

    const goBack = () => {
        AppNavigator.navigateRelative('/');
    };

    const onDelete = async () => {
        try {
            // console.log('onDelete');
            await deleteMutation({ id: params.id });
            goBack();
        } catch (e) {
            toastError(e);
        }
    };

    const onEdit = () => {
        AppNavigator.navigateRelative(`/${claim.id}/edit`);
    };

    const onSubmit = async () => {
        try {
            await executeWarrantyClaimRequest({ id: claim.id });
            toastSuccess({ title: 'Request submitted' });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };
    const barGroupItemConf: MenuBarGroupProps = !canDelete
        ? undefined
        : {
              menu: { menuMode: MenuBarGroupMODE.DROPDOWN, icon: 'bars', color: 'red' },
              items: [
                  {
                      render: (
                          <MenuBar.DropdownItemWithConfirm
                              icon={'trash'}
                              color="orange"
                              content="Delete"
                              onClick={onDelete}
                              disabled={!canDelete}
                              popup={isSubmitted ? { content: READ_ONLY_MSG } : { content: 'Delete Warranty Claim' }}
                              confirm={{
                                  confirmMessage: {
                                      header: 'Delete?',
                                      content: 'There is no undo! This claim will be deleted forever!',
                                  },
                                  confirmButton: {
                                      content: 'Delete',
                                      icon: 'trash',
                                      negative: true,
                                  },
                              }}
                          />
                      ),
                  },
              ],
          };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={goBack} icon={'chevron left'} title={'Back to list'} />
                    <MenuBar.HeaderItem icon={'certificate'}>
                        Warranty Claim - {claim.warrantyClaimNumber}
                    </MenuBar.HeaderItem>
                </MenuBar.Section>

                <MenuBar.Section position={'right'}>
                    {barGroupItemConf && <MenuBarGroup {...barGroupItemConf} />}
                </MenuBar.Section>
            </StickyMenuBar>

            <SubmissionSteps step={'review'} />

            <PageLayout.BodySection width={'screen-lg'}>
                <Segment>
                    <VStack>
                        <Alert type={'warning'}>Please review claim details before sending to the office.</Alert>

                        <WarrantyPreview claim={claim} />
                        <FileAttachmentPanel
                            claim={claim}
                            readOnly={isSubmitted}
                            mutatorWarrantyClaimFileAttach={mutatorWarrantyClaimFileAttach}
                        />
                        <Segment>
                            <ButtonWithPopup
                                onClick={onEdit}
                                variant={'secondary'}
                                floated={'left'}
                                icon={'edit'}
                                size={'md'}
                                content={'Edit Form'}
                            />
                            <div style={{ float: 'right' }}>
                                <SubmitConfirmButton claim={claim} onClick={onSubmit} />
                            </div>
                        </Segment>
                    </VStack>
                </Segment>
            </PageLayout.BodySection>
        </PageLayout>
    );
};
