import * as React from 'react';
import { usePersistedToggle } from '@ez/tools';
import { defaultOptionValueComparatorFn, Optionable, OptionableComponentL2, OptionableL2 } from '../optionable';
import { ActionBar } from './ActionBar';
import { IconButton } from '../buttons';

export interface ActionBarSingleSelectL2Props<V1 = any, V2 = any> extends OptionableComponentL2<V1, V2> {
    optionAll?: Optionable<any> | null;
    headerTitle: string | React.ReactNode;
    persistStorageKey?: string;
    maxHeight?: number;
}

export const ActionBarSingleSelect: React.FC<ActionBarSingleSelectL2Props<any, any>> = ({
    onChange,
    value = null,
    optionAll = null,
    options,
    headerTitle,
    persistStorageKey,
    maxHeight = 425,
    valueComparator = defaultOptionValueComparatorFn,
}) => {
    const [expanded, setExpanded] = usePersistedToggle(persistStorageKey, true, !persistStorageKey);

    const singleSelect = true;

    const handleOnChangeL1 = (optionL1: OptionableL2 | null, checked: boolean) => {
        if (checked) {
            onChange(optionL1, null);
        } else {
            onChange(null, null);
        }
    };

    const handleOnChangeL2 = (optionL1: OptionableL2 | null, optionL2: Optionable<any>, checked: boolean) => {
        if (checked) {
            onChange(optionL1, optionL2);
        } else {
            onChange(optionL1, null);
        }
    };

    const onShowAll = (checked: boolean) => {
        if (checked) {
            onChange(optionAll || null, null);
        }
    };

    const isAllChecked = optionAll ? valueComparator(value, optionAll) : !value;
    const hasFilter = !isAllChecked;

    return (
        <ActionBar active={hasFilter}>
            <ActionBar.Header
                content={headerTitle}
                icon={'filter'}
                actionComponent={
                    <IconButton
                        icon={expanded ? 'chevron down' : 'chevron right'}
                        color={'grey'}
                        size={'xs'}
                        onClick={() => setExpanded(!expanded)}
                    />
                }
            />
            {expanded && (
                <ActionBar.Body>
                    <ActionBar.ItemCheckbox
                        dividing={true}
                        checked={isAllChecked}
                        onChange={(checked) => onShowAll(checked)}
                        content={optionAll?.text || 'All'}
                        highlightChecked={false}
                    />
                    <ActionBar.Scroll maxHeight={maxHeight}>
                        {options.map((o1, i) => {
                            const subOptions = o1.options;
                            return (
                                <React.Fragment key={i}>
                                    <ActionBar.ItemCheckbox
                                        highlightChecked={true}
                                        radio={singleSelect}
                                        checked={valueComparator(value, o1)}
                                        onChange={(checked) => handleOnChangeL1(o1, checked)}
                                        icon={o1.icon}
                                        content={o1.text}
                                    />
                                    {subOptions?.map((o2, n) => {
                                        return (
                                            <ActionBar.ItemCheckbox
                                                highlightChecked={true}
                                                level={'second'}
                                                radio={singleSelect}
                                                checked={valueComparator(value, o2)}
                                                onChange={(checked) => handleOnChangeL2(o1, o2, checked)}
                                                key={n}
                                                icon={o2.icon}
                                                content={o2.text}
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </ActionBar.Scroll>
                </ActionBar.Body>
            )}
        </ActionBar>
    );
};
