import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    ConnectionErrorMessage,
    Display,
    PageSkeletonLoader,
    PrintPageHeader,
    PrintPageNoteText,
    PrintPageSubHeader,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionConsumer,
    PrintSectionHeader,
    PrintSectionItem,
    PrintSectionProvider,
    stringFormatters,
} from '@ez/components';
import { NotFoundPage, Helmet } from '@poolware/app-shell';
import * as _ from 'lodash';
import { ModuleIconNames } from '../../../constants';
import { Icon } from '@ez/components';
import { CustomerDetailsSection, LocationDetailsSection } from '../CustomerAndLocationSection';
import { useQueryWorkOrder } from './use-query-work-order';
import { fromEdges } from '@poolware/api';
import { WorkOrderJobItemsPanel } from './WorkOrderItemPanel';

export const WorkOrderPrintView: React.FC = () => {
    const { params } = useAppNavigator();
    const workOrderId = params.woId;

    const {
        loading,
        error,
        node: workOrder,
    } = useQueryWorkOrder(workOrderId, {
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!workOrder) {
        return <NotFoundPage />;
    }

    const noPrintDefaults = {
        note: false,
        customer: false,
        location: false,
        jobs: false,
        staff: false,
    };
    const jobTodos = fromEdges(workOrder?.jobs).sort((l, r) => {
        return l.index > r.index ? 1 : -1;
    });
    const hasTodos = jobTodos.length > 0;
    const startDate = workOrder?.appointmentItem?.startDate;
    const duration = workOrder?.appointmentItem?.duration;
    const customer = workOrder?.customer;

    return (
        <PrintSectionProvider moduleName={'print.toggles.service-call'} defaultState={noPrintDefaults}>
            <Helmet title={`Work Order - ${workOrder.workOrderNumber}`} />

            <PrintPageHeader>
                <Icon name={ModuleIconNames.WorkOrder} />
                {customer && (
                    <>
                        <b> Customer - {customer.crn}</b> <Icon name={'caret right'} />
                    </>
                )}
                Work Order - <small>{workOrder.workOrderNumber}</small>
            </PrintPageHeader>

            <PrintPageSubHeader>{workOrder.title}</PrintPageSubHeader>

            {startDate && (
                <div style={{ paddingBottom: '1rem', fontSize: '1em' }}>
                    <Icon name={'calendar alternate outline'} />
                    <Display.DateRange dayFormat={'dddd, DD MMM YYYY'} startDate={startDate} duration={duration} />
                </div>
            )}

            <CustomerDetailsSection customer={customer} />

            <LocationDetailsSection address={workOrder?.address} pool={workOrder?.pool} />

            <PrintSectionConsumer name={'staff'}>
                <PrintSectionHeader>Assigned To</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn>
                        <PrintSectionItem label="Name">
                            {stringFormatters.formatEntityName(workOrder?.assignedTo)}
                        </PrintSectionItem>
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSectionConsumer>

            <PrintSectionConsumer>
                <PrintSectionHeader>Work Order</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn>
                        <PrintSectionItem label={'Work Order ID'}>{workOrder.workOrderNumber}</PrintSectionItem>
                        <PrintSectionItem label={'Subject'}>
                            {_.truncate(workOrder.title, { length: 120 })}
                        </PrintSectionItem>
                        <PrintSectionItem label={'Parent Case'}>
                            {workOrder?.serviceJob?.serviceJobNumber} -{' '}
                            {_.truncate(workOrder?.serviceJob?.title, { length: 120 })}
                        </PrintSectionItem>
                        {startDate && (
                            <PrintSectionItem label={'Appointment'}>
                                <Display.DateRange
                                    dayFormat={'dddd, DD MMM YYYY'}
                                    startDate={startDate}
                                    duration={duration}
                                />
                            </PrintSectionItem>
                        )}
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSectionConsumer>

            <PrintSectionConsumer name={'note'}>
                <PrintSectionHeader>Work Order Description</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn paddingVal={2}>
                        <PrintPageNoteText
                            formatted={false}
                            limit={10000}
                            value={workOrder.description}
                            style={{ minHeight: '2cm' }}
                        />
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSectionConsumer>

            <PrintSectionConsumer name={'jobs'}>
                <PrintSectionHeader>{'Job Tasks'}</PrintSectionHeader>
                {jobTodos.map((jobTodo) => {
                    return (
                        <PrintSectionBody key={jobTodo.id}>
                            <PrintSectionColumn>
                                <WorkOrderJobItemsPanel jobTodo={jobTodo} />
                            </PrintSectionColumn>
                        </PrintSectionBody>
                    );
                })}
                {!hasTodos && <div>--</div>}
            </PrintSectionConsumer>
        </PrintSectionProvider>
    );
};
