import * as React from 'react';
import { NodeType } from '@poolware/api';
import { useQueryInvoicesConnection } from '../../Invoice/queries/use-query-abstract-doc-connection';
import { DefaultConnectionTable, SectionHeader, toastError, toastSuccess, useModalCtrl, VStack } from '@ez/components';
import { ModuleIconNames } from '../../../constants';
import { FormNewInvoiceDoc } from '../../Invoice/Edit/FormNewInvoiceDoc';
import { Modal } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useInvoiceListTableDef } from '../../Invoice';

export interface TabInvoicesProps {
    serviceJob?: NodeType.ServiceJob;
    workOrder?: NodeType.WorkOrder;
    refetchQuery?: any;
}

export const TabInvoices: React.FC<TabInvoicesProps> = ({ serviceJob, workOrder }) => {
    const tableDef = useInvoiceListTableDef();
    const newModalCtrl = useModalCtrl();
    const { AppNavigator } = useAppNavigator();

    const search: any = {};
    if (serviceJob?.id) {
        search.serviceJob = { id: serviceJob.id };
    }
    if (workOrder?.id) {
        search.workOrder = { id: workOrder.id };
    }
    const { connectionData, connectionState, refetchQuery } = useQueryInvoicesConnection({
        search: search,
        skip: !serviceJob && !workOrder,
    });

    const onDidCreateNewInvoice = async (doc: NodeType.AbstractDocument) => {
        try {
            newModalCtrl.onClose();
            toastSuccess({ title: 'Invoice created' });
        } catch (e) {
            toastError(e);
        }
    };

    const onView = (doc: NodeType.AbstractDocument) => {
        AppNavigator.navigateRelative(`/invoices/${doc.id}`, { setOrigin: true });
    };

    return (
        <VStack>
            <SectionHeader
                icon={ModuleIconNames.WorkOrder}
                content={'Invoices'}
                button={{
                    content: 'Add',
                    icon: 'plus',
                    color: 'blue',
                    onClick: newModalCtrl.onOpen,
                }}
            />
            <DefaultConnectionTable
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
            <Modal closeOnDimmerClick={false} {...newModalCtrl}>
                <FormNewInvoiceDoc
                    refetchQuery={refetchQuery}
                    initialValues={{
                        serviceJobId: serviceJob?.id,
                        workOrderId: workOrder?.id,
                        customerId: serviceJob?.customer?.id,
                    }}
                    onDidCreate={onDidCreateNewInvoice}
                    onCancel={newModalCtrl.onClose}
                />
            </Modal>
        </VStack>
    );
};
