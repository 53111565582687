import * as React from 'react';
import * as _ from 'lodash';
import { compose } from '@ez/tools';
import { AuthConnectProps, connectWithAuth } from '@ez/api-core';
import { getAppConfig, withAppConfig } from '../../app-config';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import styled from 'styled-components';
import { connectWithTheme, ThemeStateProps } from '../../app-theme';
import { Alert, AlertHeader, Button, Form, Segment } from '@ez/components';

const PoolwareIcon = styled.div<{ path?: string }>`
    background-image: url(${({ path }) => path || '/default/icon.png'});
    background-position: center center;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin-top: -8px;
    margin-bottom: -7px;
`;

const InvisibleDiv = styled.div`
    width: 1.5em;
    height: 1.5em;
    visibility: hidden;
`;

const LoginHeaderContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;

    > * {
        margin-left: 0.25em;
        margin-right: 0.25em;
    }
`;

interface Props extends AuthConnectProps, IAppNavigatorProps, ThemeStateProps {
    redirect: any;
}

const config = getAppConfig();

class LoginForm extends React.Component<Props, any> {
    constructor(props, context) {
        super(props, context);

        this.state = {
            error: false,
            email: '',
            password: '',
            authenticating: false,
        };
    }

    redirect = (props?) => {
        const redirect = _.get(props || this.props, 'query.redirect', '/');
        this.props.AppNavigator.replace(redirect);
    };

    UNSAFE_componentWillMount() {
        const { AuthState } = this.props;
        const { isAuthenticated } = AuthState;
        if (isAuthenticated) {
            this.redirect();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isAuthenticated } = nextProps.AuthState;
        const { isAuthenticated: wasAuthenticated } = this.props.AuthState;

        if (!wasAuthenticated && isAuthenticated) {
            this.redirect(nextProps);
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const { AuthAction } = this.props;
        const email = this.state.email;
        const pass = this.state.password;

        await AuthAction.login(email, pass);
    };

    handleInput =
        (name) =>
        (e, { value }) => {
            this.setState({ [name]: value });
        };

    render() {
        const { AuthState, ThemeState } = this.props;
        const { isAuthenticating } = AuthState;
        const { theme } = ThemeState;
        return (
            <div>
                <Form>
                    {AuthState.authError && (
                        <Alert type={'error'}>
                            <AlertHeader>Error</AlertHeader>
                            <p>{AuthState.authError}</p>
                        </Alert>
                    )}

                    <Segment className={'bg-gray-100 rounded-b-none'} color={'blue'}>
                        <LoginHeaderContainer>
                            <PoolwareIcon path={theme.iconPath} />
                            <div>
                                <h3>Login</h3>
                            </div>
                            <InvisibleDiv />
                        </LoginHeaderContainer>
                    </Segment>
                    <div className={'bg-white grid gap-3 p-4'}>
                        <Form.Input
                            label="Email"
                            id={'email'}
                            value={this.state.email}
                            type="email"
                            autoComplete={'username'}
                            disabled={isAuthenticating}
                            onChange={this.handleInput('email')}
                            placeholder="Email"
                        />
                        <Form.Input
                            label="Password"
                            id={'password'}
                            onChange={this.handleInput('password')}
                            value={this.state.password}
                            type="password"
                            autoComplete={'current-password'}
                            disabled={isAuthenticating}
                            placeholder="Password"
                        />

                        <Button
                            variant={'primary'}
                            name="submitButton"
                            color="primary"
                            type={'submit'}
                            size={'md'}
                            className={'w-full'}
                            disabled={isAuthenticating}
                            loading={isAuthenticating}
                            onClick={this.handleSubmit}
                        >
                            Login
                        </Button>

                        <div className={'my-2 text-right'}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                id={'forgot-password'}
                                href={config.api.passwordResetURL}
                            >
                                Forgot password?
                            </a>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

const LoginFormPage = styled.div`
    position: fixed;
    background-color: rgba(179, 220, 255, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
`;

const LoginFormContainer = styled.div`
    width: 460px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 80px;

    @media screen and (max-width: 500px) {
        width: 90%;
        padding-top: 40px;
    }

    @media screen and (max-height: 420px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;

const Login = (props) => (
    <LoginFormPage>
        <LoginFormContainer>
            <LoginForm {...props} />
        </LoginFormContainer>
    </LoginFormPage>
);

export default compose(connectWithAuth(), connectWithTheme(), withAppNavigator(), withAppConfig())(Login);
