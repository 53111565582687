import * as React from 'react';
import {
    Icon,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageSkeletonLoader,
    Panel,
    StickyMenuBar,
    toastError,
    toastSuccess,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NotFoundPage, useViewerContext } from '@poolware/app-shell';
import { useQueryJobTodoTemplate } from '../../../queries/use-query-job-todo-template';
import { useJobTodoTemplateMutators } from '../../../queries/mutators-job-todo-templates';
import * as _ from 'lodash';
import { fromEdges } from '@poolware/api';
import { PageLayout } from '@ez/components';

export const JobTodoTemplateView: React.FC<any> = () => {
    const { AppNavigator, params } = useAppNavigator();
    const { loading, error, node: jobTodoTemplate, refetchQuery } = useQueryJobTodoTemplate(params.id);
    const { delete: mutateDelete, update } = useJobTodoTemplateMutators({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });
    const viewerCtx = useViewerContext();

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (!jobTodoTemplate) {
        return <NotFoundPage />;
    }

    const isAdmin = viewerCtx.isAdmin;
    const canEdit = jobTodoTemplate.checkMutations.update;
    const canDelete = jobTodoTemplate.checkMutations.delete;

    const goBack = () => {
        AppNavigator.navigateToOrigin('/job-todo-template', { relativeToModule: true });
    };

    const onDeleteTemplate = async () => {
        try {
            await mutateDelete({ id: jobTodoTemplate.id }, { awaitRefetchQueries: false });
            AppNavigator.replace('/job-todo-template', { relativeToModule: true });
            toastSuccess({ title: 'Successfully deleted' });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const onRemoveFranchise = async () => {
        try {
            const r = window.confirm('This operation is irreversible. There is no undo.');
            if (r !== true) {
                return;
            }
            await update({ id: jobTodoTemplate.id, franchise: null });
        } catch (e) {
            toastError(e);
        }
    };

    const onEdit = () => {
        AppNavigator.navigateRelative(`/job-todo-template/${jobTodoTemplate.id}/edit`, {
            relativeToModule: true,
            setOrigin: true,
        });
    };

    const todoItems = fromEdges(jobTodoTemplate.items).sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1));

    const canPromote = jobTodoTemplate.franchise && isAdmin;

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'To List'} />
                    <MenuBarHeaderItem>Job Task Template</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    {canPromote && <MenuBarItem title={'Promote'} icon={'globe'} onClick={onRemoveFranchise} />}
                    {canEdit && <MenuBarItem title={'Edit'} icon={'edit'} onClick={onEdit} />}
                    {canDelete && (
                        <MenuBarDropdown color={'red'} icon={'bars'}>
                            <MenuBarDropdownItemWithConfirm
                                icon={'trash'}
                                color="orange"
                                content="Delete"
                                onClick={onDeleteTemplate}
                                popup={{ content: 'Will be deleted' }}
                                confirm={{
                                    confirmMessage: {
                                        header: 'Delete?',
                                        content: 'Will be deleted forever!',
                                    },
                                    confirmButton: {
                                        content: 'Delete',
                                        icon: 'trash',
                                        negative: true,
                                    },
                                }}
                            />
                        </MenuBarDropdown>
                    )}
                </MenuBarSection>
            </StickyMenuBar>
            <PageLayout.BodySection width={'screen-md'}>
                <Panel>
                    <Panel.Body>
                        {isAdmin && (
                            <Panel.Item
                                content={_.get(jobTodoTemplate, 'organisation.name', '-')}
                                label={'Organisation'}
                            />
                        )}
                        <Panel.Item label={'Owner'}>
                            {jobTodoTemplate.franchise ? (
                                <>{jobTodoTemplate.franchise.name}</>
                            ) : (
                                <>
                                    <Icon name={'globe'} /> System Template
                                </>
                            )}
                        </Panel.Item>
                        <Panel.Divider />
                        <Panel.Item label={'Title'}>{jobTodoTemplate.title}</Panel.Item>
                        <Panel.Item label={'Duration, min'}>{jobTodoTemplate.timeEstimate || '--'}</Panel.Item>
                        {false && <Panel.Item label={'Cost, $'}>{jobTodoTemplate.recommendedPrice || '--'}</Panel.Item>}
                        <Panel.ItemText
                            label={'Description'}
                            labelWidth={'100%'}
                            content={jobTodoTemplate.description}
                        />
                        <Panel.SectionHeader>Todo Items</Panel.SectionHeader>
                        <div style={{ paddingLeft: '1rem' }}>
                            {todoItems.length === 0 && <i>No todo items</i>}
                            <div className={'flex flex-col gap-1'}>
                                {todoItems.map((item) => {
                                    return (
                                        <div className={'flex flex-row'} key={item.id}>
                                            <Icon name={'checkmark box'} />
                                            <div>{item.title}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Panel.Body>
                </Panel>
            </PageLayout.BodySection>
        </PageLayout>
    );
};
