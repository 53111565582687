import * as React from 'react';
import { Dropdown } from '@ez/components';

export const SelectPanel: React.FC<{ options; onSelect; value }> = ({ options, onSelect, value }) => {
    const handleOnChange = (e, { value }) => {
        onSelect(value);
    };

    return (
        <Dropdown
            selection
            item
            selectOnBlur={false}
            selectOnNavigation={false}
            options={options}
            value={value}
            onChange={handleOnChange}
        />
    );
};
