import * as React from 'react';

import { Step } from '@ez/components';

export interface SubmissionStepsProps {
    step: 'edit' | 'review' | 'done';
}

export const SubmissionSteps: React.FC<SubmissionStepsProps> = ({ step }) => {
    const steps = [
        {
            key: 'edit',
            active: step === 'edit',
            icon: 'edit',
            title: 'Prepare',
            description: 'Prepare Details',
        },
        {
            key: 'confirm',
            active: step === 'review',
            icon: 'info',
            title: 'Review',
            disabled: step === 'edit',
            description: 'Verify details',
        },
        {
            active: step === 'done',
            key: 'done',
            icon: step === 'done' ? { name: 'checkmark', color: 'green' } : 'send',
            title: 'Submitted',
            disabled: step === 'edit' || step === 'review',
            description: 'Submitted to the office',
        },
    ];

    return <Step.Group unstackable fluid={true} size="small" items={steps} />;
};
