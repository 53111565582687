import { staffFragment } from '@poolware/api';
import gql from 'graphql-tag';

export const QueryStaffConnectionQL = gql`
    query QueryStaffConnection($first: Int, $page: Int, $search: StaffViewerStaffSearch, $sort: StaffViewerStaffSort) {
        viewer {
            staff(first: $first, page: $page, search: $search, sort: $sort) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        ...StaffFragment
                        user {
                            id
                            firstName
                            lastName
                            entity {
                                id
                                franchise {
                                    id
                                    name
                                    organisationType {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                        teams {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${staffFragment}
`;
