import * as React from 'react';
import tw, { css, styled } from 'twin.macro';
import { createGlobalStyle, keyframes } from 'styled-components';
import { ScrollableLayout } from './ScrollableLayout';
import { Portal } from '../semantic-ui';
import { Button } from '../buttons';

const BodyGlobalStyle = createGlobalStyle`
    &&& {
        body {
            overflow: hidden !important;
        }
    }
`;
const SlideAnimFromRight = keyframes`
    0% {
        right: -36em;
    }
    100% {
        right: 0;
    }
`;
const SlideAnimFromLeft = keyframes`
    0% {
        left: -36em;
    }
    100% {
        left: 0;
    }
`;

const FadeAnim = keyframes`
    0% {
        opacity: 0
    }
    30% {
        opacity: 0
    }
    100% {
        opacity: 30%;
    }
`;

const DockContainerMask = styled.div<{ animateSlide?: boolean }>(({ animateSlide }) => [
    tw`fixed inset-0 overflow-hidden select-none`,
    css`
        z-index: 950;
        background-color: #000000;
        opacity: 30%;
    `,
    animateSlide &&
        css`
            animation: ${FadeAnim} 200ms linear;
            animation-fill-mode: forwards;
        `,
]);

const DockContainer = styled.div<{
    preferredWidth?: string;
    animateSlide?: boolean;
    direction: 'left' | 'right';
}>(({ animateSlide, direction = 'right', preferredWidth }) => [
    tw`fixed max-w-screen-sm top-0 bottom-0 shadow-xl`,
    direction === 'right' && tw`right-0`,
    direction === 'left' && tw`left-0`,
    css`
        max-width: 100%;
        width: ${preferredWidth || '36em'};
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        z-index: 951;
    `,
    animateSlide &&
        direction === 'right' &&
        css`
            animation: ${SlideAnimFromRight} 100ms linear;
        `,
    animateSlide &&
        direction === 'left' &&
        css`
            animation: ${SlideAnimFromLeft} 100ms linear;
        `,
]);

export interface SlideInDockProps {
    open?: boolean;
    onClose?: () => any;
    animateSlide?: boolean;
    direction?: 'left' | 'right';
    preferredWidth?: string;
}

export class SlideInDock extends React.Component<SlideInDockProps> {
    public static BodyScroll = ScrollableLayout.BodyScroll;
    public static MenuBar = ScrollableLayout.MenuBar;

    render() {
        const { children, open = true, animateSlide = true, onClose, direction = 'right', preferredWidth } = this.props;
        return (
            <Portal open={open}>
                <>
                    <BodyGlobalStyle />
                    <DockContainerMask animateSlide={animateSlide} onClick={() => onClose?.()} />
                    <DockContainer preferredWidth={preferredWidth} animateSlide={animateSlide} direction={direction}>
                        <Button
                            className={'md:hidden z-[9999] fixed right-2 top-2'}
                            icon={'cancel'}
                            onClick={this.props.onClose}
                        />
                        <ScrollableLayout>{children}</ScrollableLayout>
                    </DockContainer>
                </>
            </Portal>
        );
    }
}
