import * as React from 'react';
import { useState } from 'react';
import { NodeType, useQueryStaffConnection } from '@poolware/api';
import { FormikSuggestInputProps, stringFormatters, SuggestInput, withFormikSuggestField } from '@ez/components';
import { useIsMounted, useSafeState } from '@ez/tools';

export interface JobTodoTemplateSuggestInputProps extends FormikSuggestInputProps<NodeType.Staff> {}

export const SuggestInputStaff: React.FC<JobTodoTemplateSuggestInputProps> = ({ onChange, ...rest }) => {
    const [acSearchQuery, setAcSearchQuery] = useSafeState<string>('');

    const isMounted = useIsMounted();

    const { connectionState, connectionData } = useQueryStaffConnection({
        userName: acSearchQuery,
        showDeleted: false,
    });

    const handleOnQueryChange = (value: string) => {
        setAcSearchQuery(value);
    };

    const onItemSelect = (item: NodeType.Staff) => {
        onChange(item);
    };

    const itemTextLabel = (item: NodeType.Staff) => {
        return stringFormatters.formatEntityName(item) || 'N/A';
    };

    const formatOptionLabel = (o, ctx) => {
        const isCtxValue = ctx.context === 'value';
        const item = o.item;
        const staffName = stringFormatters.formatEntityName(item);
        const staffRole = undefined;

        if (isCtxValue) {
            return <>{staffName}</>;
        }

        return (
            <>
                {staffRole ? (
                    <>
                        <>{staffName}</>
                        <span style={{ fontSize: '0.9em', float: 'right', fontStyle: 'italic' }}>{staffRole}</span>{' '}
                    </>
                ) : (
                    <>{staffName}</>
                )}
            </>
        );
    };

    if (!isMounted()) {
        return null;
    }

    return (
        <SuggestInput
            {...rest}
            formatOptionLabel={formatOptionLabel}
            items={connectionData}
            loading={connectionState.loading}
            onItemSelect={onItemSelect}
            onQueryChange={handleOnQueryChange}
            itemTextLabel={itemTextLabel}
        />
    );
};

export const FormikSuggestInputStaff = withFormikSuggestField(SuggestInputStaff);
