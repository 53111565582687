import * as React from 'react';
import { confirmModalImperative, Modal, Panel, SectionHeader, toastError, useModalCtrl } from '@ez/components';
import { NodeType } from '@poolware/api';
import { ModuleIconNames } from '../../../constants';
import { useServiceJobMutators } from '../../../queries/mutators-service-job';
import invariant from 'invariant';
import { ServiceJobTemplatePicker } from '../../../connected-components/ServiceJobTemplatePicker';
import { ServiceJobTemplatePreview } from '../../../components/ServiceJobTemplatePreview';

export const PanelWorkOrderAddSingleWO: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    canEdit: boolean;
    serviceJobMutator: ReturnType<typeof useServiceJobMutators>;
}> = (props) => {
    const modalWOPicker = useModalCtrl();
    const { appointmentItem, canEdit, serviceJobMutator } = props;
    invariant(!appointmentItem?.isRecurrentOrigin && !appointmentItem.isRecurring, 'Must be non-recurring appointment');

    const onSelectJobTemplate = async (
        template: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        modalWOPicker.onClose();
        if (!template) {
            return null;
        }

        const workOrderTemplate = workOrderTemplates?.[0];

        try {
            const res = await confirmModalImperative({
                confirmMessage: {
                    header: 'Add Service Template to appointment?',
                    content: (
                        <ServiceJobTemplatePreview
                            serviceJobTemplate={template}
                            full={true}
                            workOrderIds={[workOrderTemplate.id]}
                        />
                    ),
                },
                confirmButton: { content: 'Add' },
                cancelButton: { content: 'Cancel' },
            });

            if (!res) {
                return;
            }

            return serviceJobMutator.createForSingleAppointment({
                serviceTemplate: template?.id,
                workOrderTemplate: workOrderTemplate?.id,
                appointmentItem: appointmentItem.id,
                isImplicit: true,
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    // Non recurring, simple appointment
    return (
        <Panel>
            <Panel.Body>
                <Panel.SectionHeader
                    dividing={false}
                    icon={ModuleIconNames.WorkOrder}
                    button={
                        <SectionHeader.Button
                            locked={!canEdit}
                            onClick={modalWOPicker.onOpen}
                            icon={'plus'}
                            content={'Add'}
                        />
                    }
                >
                    Work Order
                </Panel.SectionHeader>
            </Panel.Body>
            {modalWOPicker.open && (
                <Modal {...modalWOPicker}>
                    <ServiceJobTemplatePicker
                        onCancel={modalWOPicker.onClose}
                        onSubmit={onSelectJobTemplate}
                        selectWorkOrder={'single'}
                        requiredWorkOrder={true}
                    />
                </Modal>
            )}
        </Panel>
    );
};
