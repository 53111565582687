import * as React from 'react';
import { Icon, PageLayout, SectionHeader, Segment } from '@ez/components';
import { Link, withRouter } from 'react-router-dom';

import { trackException } from '../withTracker';

const isProduction = process.env.NODE_ENV === 'production';

const toTitle = (error: Error, componentStack: string): string => {
    return `${error.toString()}\n\nThis is located at:${componentStack}`;
};

const errorStyle: any = {
    backgroundColor: '#996f53',
    color: '#FFF',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '1rem',
    fontSize: '0.9em',
};

export interface ErrorBoundaryFallbackComponentProps {
    componentStack: string;
    error: Error;
    root?: boolean;
}

const ErrorBoundaryFallbackComponent: React.FC<ErrorBoundaryFallbackComponentProps> = ({
    componentStack,
    error,
    root,
}) => {
    return (
        <PageLayout width={'screen-md'}>
            <Segment>
                <Segment>
                    <SectionHeader>
                        Something went wrong <Icon name={'frown'} />
                    </SectionHeader>
                    <React.Fragment>
                        <p>
                            We are terribly sorry. We've been notified of the problem and will do our best to make sure
                            it does not happen again!
                        </p>
                        {root && (
                            <React.Fragment>
                                <p>
                                    <Link to={'/'}>
                                        <Icon name={'home'} /> Take Me Home
                                    </Link>
                                </p>
                            </React.Fragment>
                        )}
                        {!isProduction && <pre style={errorStyle}>{toTitle(error, componentStack)}</pre>}
                    </React.Fragment>
                </Segment>
            </Segment>
        </PageLayout>
    );
};

interface ErrorBoundaryProps {
    root?: boolean;
    FallbackComponent?: React.ComponentType<ErrorBoundaryFallbackComponentProps>;
}

interface AppErrorBoundaryInjectedProps extends ErrorBoundaryProps {
    location: any;
}

class AppErrorBoundaryComp extends React.Component<AppErrorBoundaryInjectedProps, any> {
    static defaultProps = {
        FallbackComponent: ErrorBoundaryFallbackComponent,
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        console.error(error);
        // Display fallback UI
        this.setState({ hasError: true, error: error, info: info });

        trackException({ fatal: true, error: error, info: info });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage) {
            this.setState({ hasError: false, error: null, info: null });
        }
    }

    render() {
        const { children, FallbackComponent } = this.props;
        const { error, info } = this.state;

        if (this.state.hasError) {
            return <FallbackComponent root={true} componentStack={info ? info.componentStack : ''} error={error} />;
        }
        return children || null;
    }
}

// @ts-ignore
export const AppErrorBoundary: React.FC<{
    root?: boolean;
    FallbackComponent?: React.ComponentType<ErrorBoundaryFallbackComponentProps>;
}> = withRouter(AppErrorBoundaryComp);

export const withErrorBoundary =
    (root?: boolean, FallbackComponent?: React.ComponentType<ErrorBoundaryFallbackComponentProps>) =>
    <BaseProps extends any>(Component: React.ComponentType<BaseProps>) => {
        const WrappedComponent: React.FC<any> = (props) => {
            return (
                <AppErrorBoundary root={root} FallbackComponent={FallbackComponent}>
                    <Component {...props} />
                </AppErrorBoundary>
            );
        };
        // Format for display in DevTools
        const name = Component.displayName || Component.name;
        WrappedComponent.displayName = name ? `WithAppErrorBoundary(${name})` : 'WithAppErrorBoundary';

        return WrappedComponent;
    };
