import * as React from 'react';
import { ModuleRedirect, useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryServiceJob } from '../../queries/use-query-service-job';
import { ConnectionErrorMessage, PageSkeletonLoader } from '@ez/components';
import gql from 'graphql-tag';
import { NotFoundPage } from '@poolware/app-shell';

const QL = gql`
    query QueryServiceJob($id: ID!) {
        node(id: $id) {
            id
            ... on ServiceJob {
                isRecurring
            }
        }
    }
`;

export const PageViewDispatcher: React.FC = ({}) => {
    const { params } = useAppNavigator();
    const sjId = params.sjId;
    if (!sjId) {
        throw 'Expected jsId param';
    }

    const { node, loading, error } = useQueryServiceJob(sjId, { query: QL });
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={true}>{error.message}</ConnectionErrorMessage>;
    } else if (!node) {
        return <NotFoundPage />;
    }

    if (node.isRecurring) {
        return <ModuleRedirect to={`/sj/recur/${node.id}`} relativeToModule={true} />;
    } else {
        return <ModuleRedirect to={`/sj/single/${node.id}`} relativeToModule={true} />;
    }
};
