import * as React from 'react';
import { useState } from 'react';
import {
    Alert,
    ColorPillWithColorPicker,
    ContentEditableText,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    Panel,
    StickyMenuBar,
    toastError,
    toastSuccess,
    withApolloLoading,
    PageLayout,
} from '@ez/components';

import {
    AppointmentGroupMutatorProps,
    ColorPalette,
    ColorPaletteItem,
    deserializeColorPalette,
    IWithPermissionsProps,
    NodeType,
    withAppointmentGroupMutators,
    withPermissions,
} from '@poolware/api';
import { compose, mapProps } from '@ez/tools';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as URLBuilder from '../url-builder';
import { IconButton } from '../Components/IconButton';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';

interface ExternalProps {
    showFooter?: boolean;
    showDone?: boolean;
    onDone?: () => any;
    onCancel?: () => any;
    onCreate?: () => any;
    appointmentGroup: NodeType.AppointmentGroup;
}

const PaletteItemComp: React.FC<{
    onRemovePaletteItem: (index: number) => any;
    onChangeColor: (index: number, color: string) => any;
    onChangeLabel: (index: number, value: string) => any;
    canEdit: boolean;
    paletteItem: ColorPaletteItem;
    index: number;
}> = ({ onRemovePaletteItem, onChangeColor, onChangeLabel, canEdit, paletteItem, index }) => {
    const [mutating, setMutating] = useState(false);

    const handleDelete = async (index) => {
        setMutating(true);
        await onRemovePaletteItem(index);
        setMutating(false);
    };

    return (
        <div className={'p-1 flex flex-row items-center'}>
            {canEdit && (
                <IconButton
                    onClick={() => handleDelete(index)}
                    disabled={mutating}
                    loading={mutating}
                    icon={mutating ? 'spinner' : 'trash'}
                    color={'grey'}
                />
            )}
            <div>
                <ColorPillWithColorPicker value={paletteItem.color} onChange={(color) => onChangeColor(index, color)} />
            </div>
            <div style={{ flexGrow: 1, paddingLeft: '15px' }}>
                <ContentEditableText
                    value={paletteItem.label}
                    editable={canEdit}
                    onCommit={(newValue) => onChangeLabel(index, newValue)}
                />
            </div>
        </div>
    );
};

interface Props extends ExternalProps, ControlProps {}

class CalendarGroupEdit extends React.Component<Props, any> {
    state = {
        isAddingPaletteItem: false,
        isMutating: false,
    };

    tryer = async (promise) => {
        try {
            this.setState({ isMutating: true });
            const result = await promise;
            this.setState({ isMutating: false });
            return result;
        } catch (e) {
            this.setState({ isMutating: false });
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    onChangeColor = async (index, newColor) => {
        const { appointmentGroup } = this.props;
        return this.tryer(
            this.props.AppointmentGroupMutator.updatePaletteItem(appointmentGroup, index, { color: newColor })
        );
    };

    onChangeLabel = async (index, newLabel) => {
        const { appointmentGroup } = this.props;
        return this.tryer(
            this.props.AppointmentGroupMutator.updatePaletteItem(appointmentGroup, index, { label: newLabel })
        );
    };

    onAddPaletteItem = async () => {
        const { appointmentGroup } = this.props;
        this.setState({ isAddingPaletteItem: true });
        await this.tryer(
            this.props.AppointmentGroupMutator.addPaletteItem(appointmentGroup, { color: 'grey', label: 'label' })
        );
        this.setState({ isAddingPaletteItem: false });
    };

    onRemovePaletteItem = async (index) => {
        const { appointmentGroup } = this.props;
        return this.tryer(this.props.AppointmentGroupMutator.removePaletteItem(appointmentGroup, index));
    };

    onChangeGroupColor = async (newColor: string) => {
        return this.tryer(
            this.props.AppointmentGroupMutator.updateAppointmentGroup(this.props.appointmentGroup, {
                defaultColor: newColor,
            })
        );
    };

    onChangeTitle = async (newTitle: string) => {
        return this.tryer(
            this.props.AppointmentGroupMutator.updateAppointmentGroup(this.props.appointmentGroup, {
                title: newTitle,
            })
        );
    };

    onDone = () => {
        this.props.AppNavigator.replace(URLBuilder.Scheduler.home);
    };

    onDelete = async () => {
        try {
            await this.props.AppointmentGroupMutator.deleteAppointmentGroup(this.props.appointmentGroup);
            toastSuccess({ title: 'Success', description: 'Appointment group was deleted successfully' });
            this.props.AppNavigator.replaceToOrigin('/scheduler');
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    getSanitizedPalette = (ag: NodeType.AppointmentGroup): ColorPalette => {
        return deserializeColorPalette(ag);
    };

    render() {
        const { appointmentGroup, canEdit } = this.props;
        const { title, defaultColor } = appointmentGroup;
        const colorPalette = this.getSanitizedPalette(appointmentGroup);
        const { isAddingPaletteItem } = this.state;

        const paletteSize = colorPalette.items.length;
        const canAddMore = paletteSize < 8;

        return (
            <PageLayout>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem onClick={this.onDone} icon={'chevron left'} title={'Go Back'} />
                    </MenuBarSection>
                    <MenuBarSection position={'right'}>
                        <MenuBarDropdown icon={'bars'} color={'red'}>
                            <MenuBarDropdownItemWithConfirm
                                icon={'trash'}
                                content={'Delete Group'}
                                color="red"
                                locked={!canEdit}
                                confirm={{
                                    confirmMessage: {
                                        header: 'Delete Appointment Group?',
                                        content: <WarningMessage />,
                                    },
                                    confirmButton: {
                                        content: 'Delete',
                                        icon: 'trash',
                                        negative: true,
                                    },
                                }}
                                onClick={this.onDelete}
                            />
                        </MenuBarDropdown>
                    </MenuBarSection>
                </StickyMenuBar>
                <PageLayout.BodySection width={'screen-md'}>
                    <Panel>
                        <Panel.Header>Calendar Group</Panel.Header>
                        <Panel.Body>
                            <Panel.Item label={'Name'}>
                                <ContentEditableText editable={canEdit} value={title} onCommit={this.onChangeTitle} />
                            </Panel.Item>
                            <Panel.Item label={'Group Color'}>
                                <ColorPillWithColorPicker value={defaultColor} onChange={this.onChangeGroupColor} />
                            </Panel.Item>
                            <Panel.Divider />
                            <Panel.Item label={'Custom Palette'}>
                                <div className={'flex flex-col gap-3'}>
                                    {colorPalette.items.map((p, i) => (
                                        <PaletteItemComp
                                            key={i}
                                            onRemovePaletteItem={this.onRemovePaletteItem}
                                            onChangeColor={this.onChangeColor}
                                            onChangeLabel={this.onChangeLabel}
                                            canEdit={canEdit}
                                            index={i}
                                            paletteItem={p}
                                        />
                                    ))}
                                    <div hidden={!canEdit || !canAddMore}>
                                        <IconButton
                                            loading={isAddingPaletteItem}
                                            disabled={isAddingPaletteItem}
                                            onClick={this.onAddPaletteItem}
                                            icon={isAddingPaletteItem ? 'spinner' : 'plus'}
                                            color={'blue'}
                                        >
                                            <span style={{ color: '#3e9ff1' }}>Add Color</span>
                                        </IconButton>
                                    </div>
                                </div>
                            </Panel.Item>
                        </Panel.Body>
                    </Panel>
                </PageLayout.BodySection>
            </PageLayout>
        );
    }
}

const WarningMessage = () => {
    return (
        <Alert type={'warning'}>
            <p>This cannot be undone.</p>
            <p>This action is irreversible.</p>
        </Alert>
    );
};

const QUERY = gql`
    query EditAppointmentGroupQuery($id: ID!) {
        appointmentGroup: node(id: $id) {
            id
            ... on AppointmentGroup {
                id
                title
                defaultColor
                description
                priority
                colorPalette
            }
        }
    }
`;

export default compose(
    withAppNavigator(),
    withPermissions(),
    graphql(QUERY, {
        options: (props: any) => ({
            variables: {
                id: props?.params?.id,
            },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.appointmentGroup.id' }),
    mapProps((props) => {
        let appointmentGroup = props?.data?.appointmentGroup;
        let canEdit = props?.Permissions?.AppointmentGroup?.update ?? false;
        return {
            ...props,
            canEdit,
            appointmentGroup,
        };
    }),
    withAppointmentGroupMutators(['EditAppointmentGroupQuery', 'SideBarDataQuery_AppointmentGroups'])
)(CalendarGroupEdit);

interface ControlProps extends IAppNavigatorProps, AppointmentGroupMutatorProps, IWithPermissionsProps {
    appointmentGroup: NodeType.AppointmentGroup;
    canEdit: boolean;
}
