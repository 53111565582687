import * as React from 'react';
import { useMemo, useState } from 'react';
import { fromEdges, NodeType, useMutationCustomer, useQueryCustomerTagsConnection } from '@poolware/api';
import {
    getSuggestInputStyles,
    IconButton,
    LinkButton,
    Modal,
    SectionHeader,
    Segment,
    toastError,
} from '@ez/components';
import Creatable from 'react-select/creatable';
import { useViewer } from '@poolware/app-shell';

const getOptionValue = (option) => option && option['id'];

interface SuggestTagInputProps {
    tags?: NodeType.CustomerTag[];
    onTagSelect: (item: NodeType.CustomerTag | null) => any;
    onNewTag?: (value: string) => any;
    canCreateTag: boolean;
}

export const SuggestTagInput: React.FC<SuggestTagInputProps> = ({ tags, onTagSelect, onNewTag, canCreateTag }) => {
    const [acSearchQuery, setAcSearchQuery] = useState<string>('');

    const { connectionState, connectionData } = useQueryCustomerTagsConnection({ searchName: acSearchQuery });

    const handleOnSearchChange = (value: string) => {
        setAcSearchQuery(value);
    };

    const onNewItem = (value: string) => {
        onNewTag(value);
    };

    const onItemSelect = (o?: { item: NodeType.CustomerTag }, actionMeta?: { action?: string; option?; name? }) => {
        ///////////////////////////////
        // BUG workaround
        // see ussue https://github.com/JedWatson/react-select/issues/3988

        // if (o == null && actionMeta?.action === 'select-option' && o) {
        // onNewItem(actionMeta?.);
        // }

        // End of bug workaround
        ///////////////////////////////

        if (actionMeta?.action === 'select-option') {
            onTagSelect(o ? o.item : null);
        }
    };

    const itemRenderer = (item) => {
        return item ? `${item.name}` : '';
    };

    const itemToOption = (i: any) => {
        const value = getOptionValue(i);
        if (!value) return null;
        return { label: itemRenderer(i), value: value, key: value, item: i };
    };

    const error = false;

    const options = connectionData.filter((item) => {
        return tags?.findIndex((tag) => tag.id === item.id) === -1;
    });

    const isValidNewOption = (inputValue: string, selectValue, selectOptions: any[]) => {
        // ignore if tag creation disabled
        if (!canCreateTag) return false;

        // ignore empty value
        if (!inputValue) return false;

        const normaliseName = (v?: string) => {
            return v?.toLowerCase()?.trim();
        };

        inputValue = normaliseName(inputValue);

        // ignore if already in the tags list
        if (tags?.findIndex((t) => normaliseName(t.name) === inputValue) !== -1) {
            return false;
        }

        // ignore if in options list
        return selectOptions.findIndex((o) => normaliseName(o.item?.name) === inputValue) === -1;
    };

    const styles = useMemo(() => getSuggestInputStyles(error), [error]);

    return (
        <>
            <Creatable
                // autoFocus
                openMenuOnFocus={true}
                closeMenuOnSelect={false}
                placeholder={'Search or Create Tag...'}
                cacheOptions
                value={null}
                options={options.map(itemToOption)}
                isLoading={connectionState.loading}
                onChange={onItemSelect}
                onCreateOption={onNewItem}
                onInputChange={handleOnSearchChange}
                isValidNewOption={isValidNewOption}
                formatCreateLabel={(inputValue) => {
                    return (
                        <span style={{ color: 'black' }}>
                            + Create: <b style={{ color: 'blue', fontSize: '1.1em' }}>{inputValue}</b>
                        </span>
                    );
                }}
                styles={styles}
                // menuIsOpen={true}
            />
        </>
    );
};

export interface CustomerTagsPanelProps {
    customer: NodeType.Customer;
    canCreateTag?: boolean;
}

export const CustomerTagsPanelEdit: React.FC<CustomerTagsPanelProps> = ({ customer, canCreateTag }) => {
    const { update } = useMutationCustomer({ refetchQueries: ['CustomerQuery', 'QueryCustomerTagsConnection'] });

    const tags = fromEdges(customer?.tags);
    const onChange = async (value) => {
        if (!value) return;
        try {
            await update({ id: customer.id, tags: { assign: [value.id] } });
        } catch (e) {
            toastError(e);
        }
    };

    const onNewTag = async (value: string) => {
        if (!value) return;
        try {
            await update({ id: customer.id, tags: { create: [{ name: value }] } });
        } catch (e) {
            toastError(e);
        }
    };

    const onRemove = async (tag: NodeType.CustomerTag) => {
        if (!tag) return;
        try {
            await update({ id: customer.id, tags: { delete: [tag.id] } });
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <div className={'p-4 grid gap-2'}>
            <SectionHeader dividing={true}>Customer Tags</SectionHeader>
            {tags.length > 0 && (
                <div className={'flex flex-row flex-wrap gap-2 mb-4'}>
                    {tags.map((tag) => {
                        return (
                            <div className={'rounded pl-2 bg-blue-200'}>
                                {tag.name} <IconButton size={'sm'} icon={'close'} onClick={() => onRemove(tag)} />
                            </div>
                        );
                    })}
                </div>
            )}
            <SuggestTagInput
                canCreateTag={canCreateTag}
                tags={tags}
                onTagSelect={onChange}
                onNewTag={(tagValue) => onNewTag(tagValue)}
            />
        </div>
    );
};

export const PanelCustomerTags: React.FC<CustomerTagsPanelProps> = ({ customer }) => {
    const [isEdit, setEdit] = useState(false);

    const tags = fromEdges(customer?.tags);
    const { viewer } = useViewer();
    const canCreateTag = viewer.Permissions?.CustomerTag?.create;

    return (
        <Segment className={'py-2'}>
            <b style={{ paddingRight: '0.5em' }}>Tags</b>
            <div className={'flex flex-row flex-wrap gap-2'}>
                {tags.map((tag) => {
                    return (
                        <div className={'px-2 rounded bg-gray-200'} key={tag.id}>
                            {tag.name}
                        </div>
                    );
                })}
                <span className={'font-bold'}>
                    <LinkButton onClick={() => setEdit(true)}>{tags.length > 0 ? 'edit' : 'add'}</LinkButton>
                </span>
            </div>

            <Modal centered={false} open={isEdit} onClose={() => setEdit(false)}>
                <CustomerTagsPanelEdit customer={customer} canCreateTag={canCreateTag} />
            </Modal>
        </Segment>
    );
};
