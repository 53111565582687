import { fromEdges } from '@poolware/api';
import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@ez/components';
import Page from './EquipmentList';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const EquipmentList = gql`
    query EquipmentListQuery($poolId: ID!) {
        pool: node(id: $poolId) {
            id
            ... on Pool {
                id
                items(first: 1000) {
                    #Devices installed in the pool , e.g. sanitiser, filter
                    edges {
                        node {
                            id
                            ... on Item {
                                id
                                product {
                                    id
                                    name
                                    description
                                    identification
                                    consumable {
                                        id
                                    }
                                    installable {
                                        id
                                    }
                                }
                                owner {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default compose(
    graphql(EquipmentList, {
        options: (props: any) => ({
            variables: { poolId: props.poolId },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.pool.id' }),
    mapProps((props) => {
        const items = fromEdges(props?.data?.pool?.items);

        return {
            ...props,
            items,
        };
    })
)(Page);

export interface PageControlProps {
    items: any[];
}
