import * as React from 'react';
import {
    FormikDatePickerInputField,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    toastError,
} from '@ez/components';
import { PageControlProps } from './Page.Control';
import PoolDetails from '../../../CommonComponents/PoolDetails';
import * as Yup from 'yup';
import { ProductPickerField } from './ProductPickerField';
import { PickerMode } from './components';
import { NewProductInputFields } from './NewProductInputFields';
import { getIn } from 'formik';
import { NodeType } from '@poolware/api';
import invariant from 'invariant';
import { NewProductCreateContextType } from '@poolware/app-catalog';
import { PageLayout, Panel, SectionHeader } from '@ez/components';

interface ProductFormValues {
    product: NodeType.Product | null;
    productNew: NewProductCreateContextType | null;
    isGlobal: boolean;
    pool: NodeType.Pool;
    productPickerMode: PickerMode;
    notes: string;
    label?: string;
    serial?: string;
    installedAt?: Date;
}

const validationSchemaExistingProduct = Yup.object().shape({
    id: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
});

const validationSchemaNewProduct = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    brand: Yup.object().notRequired().nullable(),
    declaration: Yup.object().notRequired().nullable(),
});

const validationSchema = Yup.object().shape({
    productPickerMode: Yup.number().required(),
    product: Yup.object().when('productPickerMode', {
        is: PickerMode.SELECT_EXISTING,
        then: validationSchemaExistingProduct.nullable().required('Required'),
        otherwise: validationSchemaExistingProduct.notRequired().nullable(),
    }),
    productNew: Yup.object().when('productPickerMode', {
        is: PickerMode.CREATE_NEW,
        then: validationSchemaNewProduct.nullable().required('Required'),
        otherwise: validationSchemaNewProduct.notRequired().nullable(),
    }),
    label: Yup.string().notRequired(),
    notes: Yup.string().notRequired(),
    serial: Yup.string().notRequired(),
    installedAt: Yup.date().notRequired().nullable(),
});

export const PoolPage: React.FC<PageControlProps> = (props) => {
    const { pool } = props;

    const onCancel = () => {
        props.AppNavigator.navigateToOrigin();
    };

    const createProductItem = async (values: ProductFormValues) => {
        invariant(values.product, 'Product is not selected');
        await props.PoolMutator.createProductItem(pool, {
            product: values.product,
            note: values.notes,
            label: values.label,
            installedAt: values.installedAt,
            serial: values.serial,
        });
    };

    const createProductItemWithNewProduct = async (values: ProductFormValues) => {
        invariant(values.productNew, 'New Product values are not provided');
        const { productNew } = values;

        const newProductConf: NodeType.CreatePoolListItemCreateSwitchCreateProductInput = {
            name: productNew.name,
        };

        if (productNew.declaration) {
            newProductConf.traits = [{ declaration: productNew.declaration.id }];
        }

        if (productNew.brand) {
            newProductConf.brand = { id: productNew.brand.id };
        }

        newProductConf.isOneOff = !values.isGlobal;

        //console.log(newProductConf);

        await props.PoolMutator.createProductItemWithNewProduct(pool, {
            productCreateInput: newProductConf,
            note: values.notes,
            label: values.label,
            installedAt: values.installedAt,
            serial: values.serial,
        });
    };

    const handleSubmit = async (values: ProductFormValues) => {
        // props.AppNavigator.replaceToOrigin();
        // return;

        try {
            const { productPickerMode } = values;
            if (productPickerMode === PickerMode.SELECT_EXISTING) {
                await createProductItem(values);
            }
            if (productPickerMode === PickerMode.CREATE_NEW) {
                await createProductItemWithNewProduct(values);
            }
            props.AppNavigator.replaceToOrigin();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to add equipment', description: e.message });
        }
    };

    const initialValues: ProductFormValues = {
        product: null,
        productNew: null,
        isGlobal: false,
        pool: null,
        installedAt: null,
        notes: '',
        serial: '',
        label: undefined,
        productPickerMode: PickerMode.SELECT_EXISTING,
    };

    return (
        <PageLayout.BodySection width={'screen-md'}>
            <FormikDefaultForm
                header={'Register New Equipment'}
                debug={false}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onCancel={onCancel}
                submitButton={{ content: 'Register' }}
            >
                {(formikBag) => {
                    const productPickerMode = getIn(formikBag.values, 'productPickerMode');

                    const setProductInputMode = (newMode: PickerMode) => {
                        formikBag.setFieldValue('productPickerMode', newMode);
                    };

                    let handleOnNewProductCreateRequest = (suggestedValues: NewProductCreateContextType) => {
                        //console.log(suggestedValues);
                        formikBag.setFieldValue('productNew', suggestedValues);
                        setProductInputMode(PickerMode.CREATE_NEW);
                    };

                    return (
                        <>
                            <PoolDetails pool={pool} />
                            <Panel.Divider />

                            {productPickerMode === PickerMode.CREATE_NEW && (
                                <NewProductInputFields
                                    name={'productNew'}
                                    onCancel={() => setProductInputMode(PickerMode.SELECT_EXISTING)}
                                />
                            )}

                            {productPickerMode === PickerMode.SELECT_EXISTING && (
                                <ProductPickerField
                                    label={'Product'}
                                    name={'product'}
                                    required={true}
                                    onNewProductCreateRequest={handleOnNewProductCreateRequest}
                                />
                            )}

                            <SectionHeader>Equipment Details</SectionHeader>
                            <FormikInputField name={'serial'} label={'Serial Number'} />
                            <FormikInputField name={'label'} label={'Equipment Label'} />
                            <FormikDatePickerInputField name={'installedAt'} label={'Installation Date'} />
                            <FormikTextareaField name={'notes'} label={'Notes'} />
                        </>
                    );
                }}
            </FormikDefaultForm>
        </PageLayout.BodySection>
    );
};

export default PoolPage;
