import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';
import { ThemeInterface } from '../app-theme';
import { getThemeCssVars } from '../app-theme/theme-css-vars';

const rootTheme = getThemeCssVars();

export const AppGlobalStyle = createGlobalStyle<{ theme: ThemeInterface }>`
    ${rootTheme}
    body {
        ${tw`print:bg-white print:text-black`}
        color: var(--ez-text-color-base, hsl(0 20 20));
        font-size: var(--ez-font-size-base, 14px);
        background-color: var(--ez-main-background-color, hsl(234 20 90));
        & #content,
        & #root {
            height: 100%;
            ${tw`flex flex-col flex-grow`}
        }
    }
`;
