import * as React from 'react';
import { NodeType, useMutationHeaterRecommendation } from '@poolware/api';
import { useQueryHeaterRecommendations } from './use-query-heater-recommendations-connection';
import {
    confirmModalImperative,
    ConnectionTableDef,
    DefaultConnectionTable,
    SectionHeader,
    toastError,
} from '@ez/components';
import { HeatRecNodePayloadType, useHeatCalcActions } from '@poolware/app-calculators';
import { ButtonWithDropdownMenu } from '@ez/components';

export interface PanelCustomContractProps {
    customer: NodeType.Customer;
}

export const HeaterRecommendations: React.FC<PanelCustomContractProps> = ({ customer }) => {
    const { HeatCalcAction } = useHeatCalcActions();

    const { connectionData, connectionState, refetchQuery } = useQueryHeaterRecommendations({
        customerId: customer?.id,
    });
    const { delete: deleteRecommendtaion } = useMutationHeaterRecommendation();

    const onDelete = async (r: NodeType.HeaterRecommendation) => {
        try {
            const res = await confirmModalImperative({
                cancelButton: { content: 'Keep' },
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                    negative: true,
                },
                confirmMessage: {
                    content: 'Delete recommendation?',
                },
            });
            if (!res) return;

            await deleteRecommendtaion({ id: r.id }, { refetchQueries: [refetchQuery] });
        } catch (e) {
            toastError(e);
        }
    };

    const tableDef: ConnectionTableDef<NodeType.HeaterRecommendation> = [
        // {
        //     header: 'Expiry Date',
        //     width: '1',
        //     cell: (r) => {
        //         return <Display.Date format={'ll'} value={r.expiryDate} defaultString={'--'} />;
        //     },
        // },
        {
            header: 'Rec. Number',
            width: '1',
            cell: (r) => {
                return <>{r.recNumber}</>;
            },
        },
        {
            header: 'Equipment',
            cell: (r) => {
                const payload = r.payload as HeatRecNodePayloadType;
                return (
                    <div className={'text-blue-500'}>
                        {payload?.model?.name} ({payload?.model?.sku})
                    </div>
                );
            },
        },
        {
            header: '',
            width: 1,
            textAlign: 'center',
            onCellClick: () => {
                /*noop*/
            },
            cell: (r) => {
                return (
                    <ButtonWithDropdownMenu
                        icon={'ellipsis vertical'}
                        size={'sm'}
                        variant={'secondary'}
                        options={[
                            {
                                content: 'Delete',
                                icon: 'trash',
                                onClick: () => onDelete(r),
                            },
                        ]}
                    />
                );
            },
        },
    ];

    const onView = (hr: NodeType.HeaterRecommendation) => {
        HeatCalcAction.openCalc({ heaterRecommendation: hr });
    };

    const onNewRecommendation = () => {
        HeatCalcAction.newCalc({ customer: customer });
    };

    return (
        <>
            <SectionHeader
                size={'small'}
                content={'Heater Recommendations'}
                button={{ icon: 'plus', content: 'New', onClick: onNewRecommendation }}
            />

            <DefaultConnectionTable
                tableDef={tableDef}
                onRowClick={onView}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </>
    );
};
