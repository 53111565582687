import { MenuBar, StickyMenuBar, VStack } from '@ez/components';
import * as React from 'react';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { WaterlinkConfigForm } from './WaterlinkConfigFrom';
import { DeviceStatusPanelDev } from './DeviceStatusPanel';

const WaterlinkConfigPage: React.FC<IAppNavigatorProps> = ({ AppNavigator }) => {
    const goBack = () => {
        AppNavigator.navigateToOrigin('/waterlink');
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={goBack} icon={'chevron left'} title={'Back'} />
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.HeaderItem>Waterlink Configuration</MenuBar.HeaderItem>
                </MenuBar.Section>
            </StickyMenuBar>

            <WaterlinkConfigForm />
            <DeviceStatusPanelDev />
        </VStack>
    );
};

export default withAppNavigator()(WaterlinkConfigPage);
