import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { getAppConfig, useAppConfigContext } from './app-config';
import styled from 'styled-components';
import { useInterval, usePersistedString, useTimeout } from '@ez/tools';
import { Portal, DevOnly } from '@ez/components';

const initialConfig = getAppConfig();

const ReloadPrompt: React.FC<{ onReload; test? }> = ({ onReload, test }) => {
    const onClick = () => {
        onReload();
    };

    return (
        <Portal open={true}>
            <div className={'fixed bottom-0 right-0 px-6 pb-4 w-full sm:w-60'}>
                <div className={'bg-blue-50 shadow-lg p-2 rounded border border-solid border-gray-400'}>
                    <div className={'flex flex-col items-center justify-center text-sm'}>
                        <div>New update released.</div>
                        <div className={'mb-2'}>Please reload web page.</div>
                        <button
                            className={'py-2 px-4 rounded text-white bg-green-500 hover:bg-green-700 border-0'}
                            onClick={onClick}
                        >
                            Reload
                        </button>
                    </div>
                </div>
            </div>
        </Portal>
    );
};

const DebugPanel = styled.div`
    z-index: 10000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffb07a;
    padding: 8px;
    font-size: x-small;
`;

const fetchManifest = (url: string = '/server-manifest.json'): Promise<any> => {
    return fetch(url)
        .then(function (response) {
            if (response.ok) {
                // console.log(response);
                try {
                    return response.json();
                } catch (e) {
                    return {};
                }
            } else {
                return {};
            }
        })
        .catch((error) => {
            // console.log('Error:', error);
            return {};
        });
};

export interface AppUpdateState {
    pendingReload: boolean;
    reloadOnNavigation: boolean;
    initialVersion: string;
    serverVersion: string;
    reloadPage: (to: string) => any;
    lastRefreshVersion: string;
    promptTimeoutPassed: boolean;
}

let defaultContextState: AppUpdateState = {
    pendingReload: false,
    reloadOnNavigation: false,
    initialVersion: initialConfig.codeVersion,
    serverVersion: initialConfig.codeVersion,
    lastRefreshVersion: initialConfig.codeVersion,
    promptTimeoutPassed: false,
    reloadPage: () => {},
};

export const AppVersionCheckerContext = React.createContext<AppUpdateState>(defaultContextState);

//////
// THE PROBLEM :
//  The app is served from S3 + CloudFront CDN on AWS. The CloudFront speeds up
//  the SPA app loading significantly by caching files everywhere possible, including the web browsers.
//  The downside of it is that now we need to tell the browser somehow to reload the page, when
//  a new version of the app is deployed. Hence, this module `AppUpdater` exist.
//
// HACKY SOLUTION:
//  The way how the app updater is implemented is a bit of a hack at the moment.
//
//  During the build time, the script sets REACT_APP_CODE_VERSION var with git SHA version.
//  After the app is deploy, we also manually set REACT_APP_CODE_VERSION var in the json manifest file
//  (see Admin's Setting Store page).
//
//  Then this module periodically fetches the manifest file and compares
//  the value returned in the manifest with the value that was baked-in during the build time.
//  If the values don't match, it sets the flag for app reload.
//
// IMPORTANT:
//  The value in the manifest file must match exactly to the value of
//  the the app that is currently being deployed. Otherwise the AppUpdate module will always
//  raise the flag for reload.
//
// TODO: implement a proper app version checker
/////

const APP_UPDATE_INTERVAL = 1000 * (120 + Math.random() * 60); // 2 min + some jitter.
const PROMPT_STARTUP_TIMEOUT = 60000;

export const AppUpdater: React.FC<any> = ({ children }) => {
    const conf = useAppConfigContext();
    const [serverVersion, setServerVersion] = useState(initialConfig.codeVersion);
    const [pendingReload, setPendingReload] = useState(false);
    const [promptTimeoutPassed, setTimeoutPassed] = useState(false);
    const [lastRefreshVersion, saveLastRefresh] = usePersistedString<string>('pw.refresh-ver', '');

    const fetchAndValidate = async () => {
        try {
            const manifestUrl = conf.api.manifestJsonURL;
            const res = await fetchManifest(manifestUrl);
            const { REACT_APP_CODE_VERSION } = res;
            if (!REACT_APP_CODE_VERSION) {
                return;
            }
            setServerVersion(REACT_APP_CODE_VERSION);
            if (REACT_APP_CODE_VERSION !== defaultContextState.initialVersion) {
                setPendingReload(true);
            } else {
                setPendingReload(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        // fetch on initial load
        fetchAndValidate();
    }, []);

    // re-fetch periodically
    useInterval(fetchAndValidate, APP_UPDATE_INTERVAL);

    useTimeout(() => {
        setTimeoutPassed(true);
    }, PROMPT_STARTUP_TIMEOUT);

    const reloadPage = (to: string) => {
        try {
            saveLastRefresh(serverVersion);
            setPendingReload(false);
            if (to) {
                return window.location.assign(to);
            } else {
                return window.location.reload();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const appStateContext = {
        ...defaultContextState,
        serverVersion: serverVersion,
        lastRefreshVersion: lastRefreshVersion,
        pendingReload: pendingReload,
        reloadOnNavigation: conf.autoUpdater?.reloadOnNavigation,
        reloadPage: reloadPage,
        promptTimeoutPassed,
    };

    const shouldManualPrompt = promptTimeoutPassed && pendingReload && lastRefreshVersion !== serverVersion;
    return (
        <AppVersionCheckerContext.Provider value={appStateContext}>
            {children}
            {shouldManualPrompt && <ReloadPrompt onReload={reloadPage} />}
            {false && (
                <DevOnly>
                    <DebugPanel>
                        Pending Reload: <b>{pendingReload ? 'YES' : 'NO'}</b>
                        <br />
                        Reload on Nav: <b>{appStateContext.reloadOnNavigation ? 'YES' : 'NO'}</b>
                        <br />
                        Init Version: <b>{appStateContext.initialVersion}</b>
                        <br />
                        Server Version: <b>{appStateContext.serverVersion}</b>
                        <br />
                        LS Version: <b>{appStateContext.lastRefreshVersion}</b>
                        <br />
                        Promt Timeout: <b>{promptTimeoutPassed ? 'Y' : 'N'}</b>
                        <br />
                    </DebugPanel>
                </DevOnly>
            )}
        </AppVersionCheckerContext.Provider>
    );
};

export const useAppUpdater = () => useContext(AppVersionCheckerContext);

export const withAppUpdater =
    () =>
    <BaseProps extends any>(C: React.ComponentType<BaseProps>) => {
        const WrappedComponent: React.FC<any> = (props) => {
            const value = useContext(AppVersionCheckerContext);
            return <C {...props} pendingReload={value.pendingReload} reloadTo={value.reloadPage} />;
        };
        WrappedComponent.displayName = `withAppUpdater(${C.name})`;

        return WrappedComponent;
    };
