import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { findDOMNode } from 'react-dom';
import clsx from 'clsx';

import Selection, { getBoundsForNode, isEvent } from './Selection';
import * as dates from './utils/dates';
import * as TimeSlotUtils from './utils/TimeSlots';
import { isSelected } from './utils/selection';

import { notify } from './utils/helpers';
import * as DayEventLayout from './utils/DayEventLayout';
import { TimeSlotGroup } from './TimeSlotGroup';
import { TimeGridEvent } from './TimeGridEvent';
import { DayLayoutAlgorithmPropType } from './utils/propTypes';
import { ACTION_NOTIFICATION, CalResource } from './types';
import { CalendarViewBaseProps } from './CalendarPropsTypes';
import { RBCContext } from './CalendarContext';
import { TimeSlotMetricsType } from './utils/TimeSlots';

const propTypes = {
    // culture: PropTypes.string,
    // timeslots: PropTypes.number,
    // selected: PropTypes.object,
    // selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
    // eventOffset: PropTypes.number,
    // longPressThreshold: PropTypes.number,
    // onSelecting: PropTypes.func,
    // onSelectSlot: PropTypes.func.isRequired,
    // onSelectEvent: PropTypes.func.isRequired,
    // onDoubleClickEvent: PropTypes.func.isRequired,
    // className: PropTypes.string,
    // dragThroughEvents: PropTypes.bool,
    // resource: PropTypes.any,
    //
    // dayLayoutAlgorithm: DayLayoutAlgorithmPropType,
};

const defaultProps = {
    dragThroughEvents: true,
    timeslots: 2,
};

interface DayColumnState {
    selecting?;
    top?;
    height?;
    startDate?;
    endDate?;
    start?;
    end?;
    timeIndicatorPosition?;
}

interface DayColumnProps extends CalendarViewBaseProps {
    isNow?: boolean;
    eventOffset?: number;
    className?: string;
    dragThroughEvents?: boolean;
    resourceId?: string | number;
}

class DayColumn extends React.Component<DayColumnProps, DayColumnState> {
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    public static propTypes = propTypes;
    public static defaultProps = defaultProps;
    intervalTriggered = false;
    private slotMetrics: TimeSlotMetricsType;
    private _timeIndicatorTimeout: number;
    private _selector: Selection;
    private _initialSlot: any;

    constructor(props, context) {
        super(props, context);
        this.state = { selecting: false, timeIndicatorPosition: null };
        // @ts-ignore
        this.slotMetrics = TimeSlotUtils.getSlotMetrics(this.props);
    }

    componentDidMount() {
        this.props.selectable && this._selectable();

        if (this.props.isNow) {
            this.setTimeIndicatorPositionUpdateInterval();
        }
    }

    componentWillUnmount() {
        this._teardownSelectable();
        this.clearTimeIndicatorInterval();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectable && !this.props.selectable) this._selectable();
        if (!nextProps.selectable && this.props.selectable) this._teardownSelectable();

        this.slotMetrics = this.slotMetrics.update(nextProps);
    }

    componentDidUpdate(prevProps, prevState) {
        const getNowChanged = !dates.eq(prevProps.getNow(), this.props.getNow(), 'minutes');

        if (prevProps.isNow !== this.props.isNow || getNowChanged) {
            this.clearTimeIndicatorInterval();

            if (this.props.isNow) {
                const tail =
                    !getNowChanged &&
                    dates.eq(prevProps.date, this.props.date, 'minutes') &&
                    prevState.timeIndicatorPosition === this.state.timeIndicatorPosition;

                this.setTimeIndicatorPositionUpdateInterval(tail);
            }
        } else if (
            this.props.isNow &&
            (!dates.eq(prevProps.min, this.props.min, 'minutes') || !dates.eq(prevProps.max, this.props.max, 'minutes'))
        ) {
            this.positionTimeIndicator();
        }
    }

    /**
     * @param tail {Boolean} - whether `positionTimeIndicator` call should be
     *   deferred or called upon setting interval (`true` - if deferred);
     */
    setTimeIndicatorPositionUpdateInterval(tail = false) {
        if (!this.intervalTriggered && !tail) {
            this.positionTimeIndicator();
        }

        this._timeIndicatorTimeout = window.setTimeout(() => {
            this.intervalTriggered = true;
            this.positionTimeIndicator();
            this.setTimeIndicatorPositionUpdateInterval();
        }, 60000);
    }

    clearTimeIndicatorInterval() {
        this.intervalTriggered = false;
        window.clearTimeout(this._timeIndicatorTimeout);
    }

    positionTimeIndicator() {
        const { min, max, getNow } = this.props;
        const current = getNow();

        if (current >= min && current <= max) {
            const top = this.slotMetrics.getCurrentTimePosition(current);
            this.intervalTriggered = true;
            this.setState({ timeIndicatorPosition: top });
        } else {
            this.clearTimeIndicatorInterval();
        }
    }

    render() {
        const { max, isNow, resourceId } = this.props;

        const {
            localizer,
            components: { eventContainerWrapper: EventContainerWrapper, ...components },
            getters: { dayProp, ...getters },
        } = this.context;

        let { slotMetrics } = this;
        let { selecting, top, height, startDate, endDate } = this.state;

        let selectDates = { start: startDate, end: endDate };

        const { className, style } = dayProp(max);

        return (
            <div
                test-id={'DayColumn:render'}
                style={style}
                className={clsx(
                    className,
                    'rbc-day-slot',
                    'rbc-time-column',
                    isNow && 'rbc-now',
                    isNow && 'rbc-today', // WHY
                    selecting && 'rbc-slot-selecting'
                )}
            >
                {slotMetrics.groups.map((grp, idx) => (
                    <TimeSlotGroup key={idx} group={grp} resource={resourceId} />
                ))}
                <EventContainerWrapper
                    // devKey={localizer.format(this.props.date)}
                    resource={resourceId}
                    // localizer={localizer}
                    // accessors={accessors}
                    // getters={getters}
                    // components={components}
                    slotMetrics={slotMetrics}
                >
                    <div className={'rbc-events-container'}>{this.renderEvents()}</div>
                </EventContainerWrapper>

                {selecting && (
                    <div className="rbc-slot-selection" style={{ top, height }}>
                        <span>{localizer.format(selectDates, 'selectRangeFormat')}</span>
                    </div>
                )}
                {isNow && this.intervalTriggered && (
                    <div
                        className="rbc-current-time-indicator"
                        style={{ top: `${this.state.timeIndicatorPosition}%` }}
                    />
                )}
            </div>
        );
    }

    renderEvents = () => {
        let { events, selected, step, timeslots, dayLayoutAlgorithm } = this.props;
        let { accessors, localizer, components } = this.context;

        const { slotMetrics } = this;
        const { messages } = localizer;

        let styledEvents = DayEventLayout.getStyledEvents({
            events,
            accessors,
            slotMetrics,
            minimumStartDifference: step, //Math.ceil((step * timeslots) / 2),
            dayLayoutAlgorithm,
        });

        return styledEvents.map(({ event, style }, idx) => {
            let end = accessors.end(event);
            let start = accessors.start(event);
            let format = 'eventTimeRangeFormat';
            let label;

            const startsBeforeDay = slotMetrics.startsBeforeDay(start);
            const startsAfterDay = slotMetrics.startsAfterDay(end);

            if (startsBeforeDay) format = 'eventTimeRangeEndFormat';
            else if (startsAfterDay) format = 'eventTimeRangeStartFormat';

            if (startsBeforeDay && startsAfterDay) label = messages.allDay;
            else label = localizer.format({ start, end }, format);

            let continuesEarlier = startsBeforeDay || slotMetrics.startsBefore(start);
            let continuesLater = startsAfterDay || slotMetrics.startsAfter(end);

            return (
                <TimeGridEvent
                    style={style}
                    event={event}
                    label={label}
                    key={'evt_' + idx}
                    componentEvent={components.event}
                    componentEventWrapper={components.eventWrapper}
                    continuesEarlier={continuesEarlier}
                    continuesLater={continuesLater}
                    selected={isSelected(event, selected)}
                    onClick={(e) => this._select(event, e)}
                    onDoubleClick={(e) => this._doubleClick(event, e)}
                />
            );
        });
    };

    _selectable = () => {
        let node = findDOMNode(this);
        let selector = (this._selector = new Selection(() => findDOMNode(this), {
            longPressThreshold: this.props.longPressThreshold,
            debugKey: 'DayColumn',
        }));

        let maybeSelect = (box) => {
            let onSelecting = this.props.onSelecting;
            let current = this.state || {};
            let state = selectionState(box);
            let { startDate: start, endDate: end } = state;

            if (onSelecting) {
                if (
                    (dates.eq(current.startDate, start, 'minutes') && dates.eq(current.endDate, end, 'minutes')) ||
                    onSelecting({
                        start,
                        end,
                        resourceId: this.props.resourceId,
                    }) === false
                )
                    return;
            }

            if (
                this.state.start !== state.start ||
                this.state.end !== state.end ||
                this.state.selecting !== state.selecting
            ) {
                this.setState(state);
            }
        };

        let selectionState = (point) => {
            let currentSlot = this.slotMetrics.closestSlotFromPoint(point, getBoundsForNode(node));

            if (!this.state.selecting) {
                this._initialSlot = currentSlot;
            }

            let initialSlot = this._initialSlot;
            if (dates.lte(initialSlot, currentSlot)) {
                currentSlot = this.slotMetrics.nextSlot(currentSlot);
            } else if (dates.gt(initialSlot, currentSlot)) {
                initialSlot = this.slotMetrics.nextSlot(initialSlot);
            }

            const selectRange = this.slotMetrics.getRange(
                dates.min(initialSlot, currentSlot),
                dates.max(initialSlot, currentSlot)
            );

            return {
                ...selectRange,
                selecting: true,
                top: `${selectRange.top}%`,
                height: `${selectRange.height}%`,
            };
        };

        let selectorClicksHandler = (box, actionType) => {
            if (!isEvent(findDOMNode(this), box)) {
                const { startDate, endDate } = selectionState(box);
                this._selectSlot({
                    startDate,
                    endDate,
                    action: actionType,
                    box,
                });
            }
            this.setState({ selecting: false });
        };

        selector.on(ACTION_NOTIFICATION.selecting, maybeSelect);

        selector.on(ACTION_NOTIFICATION.selectStart, maybeSelect);

        selector.on(ACTION_NOTIFICATION.beforeSelect, (box) => {
            if (this.props.selectable !== 'ignoreEvents') {
                return;
            }
            return !isEvent(findDOMNode(this), box);
        });

        selector.on(ACTION_NOTIFICATION.click, (box) => selectorClicksHandler(box, ACTION_NOTIFICATION.click));

        selector.on(ACTION_NOTIFICATION.doubleClick, (box) =>
            selectorClicksHandler(box, ACTION_NOTIFICATION.doubleClick)
        );

        selector.on(ACTION_NOTIFICATION.selected, (bounds) => {
            if (this.state.selecting) {
                this._selectSlot({ ...this.state, action: ACTION_NOTIFICATION.selected, bounds });
                this.setState({ selecting: false });
            }
        });

        selector.on(ACTION_NOTIFICATION.reset, () => {
            if (this.state.selecting) {
                this.setState({ selecting: false });
            }
        });
    };

    _teardownSelectable = () => {
        if (!this._selector) return;
        this._selector.teardown();
        this._selector = null;
    };

    _selectSlot = (slot) => {
        const { startDate, endDate, action, bounds, box } = slot;
        let current = startDate;
        let slots = [];

        while (dates.lte(current, endDate)) {
            slots.push(current);
            current = new Date(+current + this.props.step * 60 * 1000); // using Date ensures not to create an endless loop the day DST begins
        }

        notify(this.props.onSelectSlot, {
            slots,
            start: startDate,
            end: endDate,
            resourceId: this.props.resourceId,
            action,
            bounds,
            box,
        });
    };

    _select = (...args) => {
        notify(this.props.onSelectEvent, args);
    };

    _doubleClick = (...args) => {
        notify(this.props.onDoubleClickEvent, args);
    };
}

export default DayColumn;
