import * as React from 'react';
import {
    FormikDefaultForm,
    FormikInputField,
    MenuBarHeaderItem,
    MenuBarSection,
    StickyMenuBar,
    toastError,
    VStack,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType, useMutationWorkOrderTemplate } from '@poolware/api';
import { FormikDefaultFormProps } from '@ez/components';
import { PageLayout } from '@ez/components';

export interface ServiceJobTemplateNewProps {}

interface WorkOrderTemplateNewFormProps extends Partial<FormikDefaultFormProps> {
    onDone: (newId: NodeType.ID) => any;
}

export const WorkOrderTemplateNewForm: React.FC<WorkOrderTemplateNewFormProps> = ({
    onSubmit,
    onDone,
    onCancel,
    ...rest
}) => {
    const { create } = useMutationWorkOrderTemplate();

    const initialValues = {
        templateTitle: '',
    };

    const handleOnSubmit = async (values: typeof initialValues) => {
        try {
            const res = await create({
                templateTitle: values.templateTitle?.trim() || '',
                providedTitle: '',
            });

            const id = res?.data?.WorkOrderTemplate?.WorkOrderTemplate?.id;
            onDone(id);
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            {...rest}
            header={'New Work Order Template'}
            initialValues={initialValues}
            submitButton={{ content: 'Next...' }}
            onSubmit={handleOnSubmit}
            onCancel={onCancel}
        >
            <FormikInputField label={'Template Title'} name={'templateTitle'} />
        </FormikDefaultForm>
    );
};

export const WorkOrderTemplateNew: React.FC<ServiceJobTemplateNewProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();

    const onCancel = () => {
        AppNavigator.replaceToOrigin('/', { relativeToModule: true });
    };

    const onSubmit = async (id: NodeType.ID) => {
        AppNavigator.replace(`/wo-template/${id}`, { relativeToModule: true });
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem>New Work Order Template</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <PageLayout.BodySection width={'screen-lg'}>
                <WorkOrderTemplateNewForm
                    header={'New Work Order Template'}
                    submitButton={{ content: 'Next...' }}
                    onDone={onSubmit}
                    onCancel={onCancel}
                />
            </PageLayout.BodySection>
        </PageLayout>
    );
};
