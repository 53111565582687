import * as React from 'react';
import { CSSProperties } from 'react';
import * as _ from 'lodash';
import { Button, Form, Panel, Tab } from '@ez/components';
import { PoolSizeCalc } from '../utils/pool-size-calcs';

const convertToDisplayLitres = (volumeM3) => {
    const litres = volumeM3 * 1000;

    if (litres < 1000) {
        return Math.round(litres);
    }

    return Math.round(litres / 1000) * 1000;
};

const ResultLabel = ({ label, volume }) => {
    const labelStyle: CSSProperties = {
        display: 'inline-block',
        width: '100px',
        marginRight: '10px',
        textAlign: 'left',
        paddingBottom: '1em',
    };

    return (
        <div>
            <span style={labelStyle}>{label}</span>
            {volume ? <span>{`${volume} Litres`}</span> : <span>{`-- Litres`}</span>}
        </div>
    );
};

interface CalcProps {
    onVolumeSubmit: (value: any) => any;
}

class SquareCalc extends React.Component<CalcProps, any> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            volume: null,
        };
    }

    handleChange = (e, data) => {
        const { name, value } = data;
        this.setState({ [name]: value }, this.updateResults);
    };

    updateResults = () => {
        let { length, width, depthDeep, depthShallow } = this.state;
        length = parseFloat(length);
        width = parseFloat(width);
        depthDeep = parseFloat(depthDeep);
        depthShallow = parseFloat(depthShallow);

        if (_.isNaN(length) || _.isNaN(width) || _.isNaN(depthDeep) || _.isNaN(depthShallow)) {
            this.setState({ volume: null });
        } else {
            const { volume } = PoolSizeCalc.rect({
                depthShallowEnd: depthShallow,
                length: length,
                width: width,
                depthDeepEnd: depthDeep,
            });
            const volumeDisp = convertToDisplayLitres(volume);

            this.setState({ volume: volumeDisp });
        }
    };

    handleSubmit = () => {
        if (this.props.onVolumeSubmit) {
            this.props.onVolumeSubmit(this.state.volume);
        }
    };

    render() {
        const { volume } = this.state;
        return (
            <Form as="div">
                <Form.Group widths="equal">
                    <Form.Input
                        label="Width, m"
                        name="width"
                        autoComplete="off"
                        value={this.state['width'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />

                    <Form.Input
                        label="Length, m"
                        name="length"
                        autoComplete="off"
                        value={this.state['length'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Input
                        label="Depth (deepest)"
                        name="depthDeep"
                        autoComplete="off"
                        value={this.state['depthDeep'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />

                    <Form.Input
                        label="Depth (shallowest)"
                        name="depthShallow"
                        autoComplete="off"
                        value={this.state['depthShallow'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />
                </Form.Group>

                <ResultLabel label="Pool Volume" volume={volume} />

                <Button className={'w-full'} variant={'primary'} disabled={!volume} onClick={this.handleSubmit}>
                    Set
                </Button>
            </Form>
        );
    }
}

class RoundCalc extends React.Component<CalcProps, any> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            volume: null,
        };
    }

    handleChange = (e, data) => {
        const { name, value } = data;
        this.setState({ [name]: value }, this.updateResults);
    };

    updateResults = () => {
        let { diam, depthDeep, depthShallow } = this.state;

        diam = parseFloat(diam);
        depthDeep = parseFloat(depthDeep);
        depthShallow = parseFloat(depthShallow);

        if (_.isNaN(diam) || _.isNaN(depthDeep) || _.isNaN(depthShallow)) {
            this.setState({ volume: null });
        } else {
            const { volume } = PoolSizeCalc.round({
                depthShallowEnd: depthShallow,
                diameter: diam,
                depthDeepEnd: depthDeep,
            });
            const volumeDisp = convertToDisplayLitres(volume);
            this.setState({ volume: volumeDisp });
        }
    };

    handleSubmit = () => {
        if (this.props.onVolumeSubmit) {
            this.props.onVolumeSubmit(this.state.volume);
        }
    };

    render() {
        const { volume } = this.state;
        return (
            <Form as="div">
                <Form.Input
                    label="Diameter, m"
                    name="diam"
                    autoComplete="off"
                    value={this.state['diam'] || ''}
                    size="mini"
                    onChange={this.handleChange}
                />

                <Form.Group widths="equal">
                    <Form.Input
                        label="Depth (deepest)"
                        name="depthDeep"
                        autoComplete="off"
                        value={this.state['depthDeep'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />

                    <Form.Input
                        label="Depth (shallowest)"
                        name="depthShallow"
                        autoComplete="off"
                        value={this.state['depthShallow'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />
                </Form.Group>

                <ResultLabel label="Pool Volume" volume={volume} />

                <Button className={'w-full'} variant={'primary'} disabled={!volume} onClick={this.handleSubmit}>
                    Set
                </Button>
            </Form>
        );
    }
}

class OvalCalc extends React.Component<CalcProps, any> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            volume: null,
        };
    }

    handleChange = (e, data) => {
        const { name, value } = data;
        this.setState({ [name]: value }, this.updateResults);
    };

    updateResults = () => {
        let { length, width, depthDeep, depthShallow } = this.state;
        length = parseFloat(length);
        width = parseFloat(width);
        depthDeep = parseFloat(depthDeep);
        depthShallow = parseFloat(depthShallow);

        if (_.isNaN(length) || _.isNaN(width) || _.isNaN(depthDeep) || _.isNaN(depthShallow)) {
            this.setState({ volume: null });
        } else {
            const { volume } = PoolSizeCalc.oval({
                depthShallowEnd: depthShallow,
                diameter1: length,
                diameter2: width,
                depthDeepEnd: depthDeep,
            });
            const volumeDisp = convertToDisplayLitres(volume);
            this.setState({ volume: volumeDisp });
        }
    };

    handleSubmit = () => {
        if (this.props.onVolumeSubmit) {
            this.props.onVolumeSubmit(this.state.volume);
        }
    };

    render() {
        const { volume } = this.state;
        return (
            <Form as="div">
                <Form.Group widths="equal">
                    <Form.Input
                        label="Width, m"
                        name="width"
                        autoComplete="off"
                        value={this.state['width'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />

                    <Form.Input
                        label="Length, m"
                        name="length"
                        autoComplete="off"
                        value={this.state['length'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Input
                        label="Depth (deepest)"
                        name="depthDeep"
                        autoComplete="off"
                        value={this.state['depthDeep'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />

                    <Form.Input
                        label="Depth (shallowest)"
                        name="depthShallow"
                        autoComplete="off"
                        value={this.state['depthShallow'] || ''}
                        size="mini"
                        onChange={this.handleChange}
                    />
                </Form.Group>

                <ResultLabel label="Pool Volume" volume={volume} />

                <Button className={'w-full'} variant={'primary'} disabled={!volume} onClick={this.handleSubmit}>
                    Set
                </Button>
            </Form>
        );
    }
}

export const PoolVolumeCalculator: React.FC<CalcProps> = (props) => {
    const panes = [
        {
            menuItem: 'Rectangular',
            render: () => (
                <Tab.Pane>
                    <SquareCalc {...props} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Round',
            render: () => (
                <Tab.Pane>
                    <RoundCalc {...props} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Oval',
            render: () => (
                <Tab.Pane>
                    <OvalCalc {...props} />
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Panel>
            <Panel.Header content="Pool Volume Calculator" />
            <Panel.Body>
                <Tab panes={panes} />
            </Panel.Body>
        </Panel>
    );
};
