import * as React from 'react';
import moment from 'moment';
import { useBookingActions, useCalendarActions } from '../../redux';
import * as URLBuilder from '../url-builder';
import styled from 'styled-components';
import {
    Button,
    cn,
    Dropdown,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    Icon,
    SegmentedSelectButtons,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { CalNavigate, CalResourceMode, CalViewLayoutMode, CalViewMode } from '../types';
import { useViewer } from '@poolware/app-shell';
import { ToolbarDatePicker } from './ToolbarDatePicker';
import { ModuleIconNames } from '../../constants';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';
import { SemanticCOLORS } from '@ez/components';

const WarningIcon = () => {
    return <Icon color="red" inverted name={'warning sign'} />;
};

const getTodayDate = () => {
    // Note:
    // This weird way of acquiring current time is because Date.now function is getting mocked in the unit-tests.
    // It helps to have deterministic unit-tests.
    return new Date(Date.now());
};

const ButtonsGroupRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 0.25rem;
    //&&& > * + * {
    //    margin-left: 0.25rem !important;
    //}
`;
const ButtonsRow = styled.div`
    display: flex;
    flex-grow: 1;
    gap: 0.25rem;
    flex-direction: row;
    //flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
`;

const mapFull = {
    [CalViewMode.MONTH]: 'Month',
    [CalViewMode.WEEK]: 'Week',
    [CalViewMode.WORK_WEEK]: 'Work Week',
    [CalViewMode.DAY]: 'Day',
    [CalViewMode.AGENDA]: 'Agenda',
};

const mapShort = {
    [CalViewMode.MONTH]: 'M',
    [CalViewMode.WEEK]: 'W',
    [CalViewMode.WORK_WEEK]: 'W',
    [CalViewMode.DAY]: 'D',
    [CalViewMode.AGENDA]: 'A',
};

export const mapCalViewModeToLabel = (mode: CalViewMode, isShort?: boolean) => {
    return (isShort ? mapShort[mode] : mapFull[mode]) || mode;
};

interface ToolbarProps {
    view: CalViewMode;
    views: CalViewMode[];
    label: React.ReactNode;
    localizer: any;
    onNavigate: (...arg) => any;
    onView: (...arg) => any;
}

export const CalendarToolbar: React.FC<ToolbarProps> = (props) => {
    const { modulesAccess, appLayoutMode } = useViewer();
    const { AppNavigator } = useAppNavigator();
    const { CalendarState, CalendarAction } = useCalendarActions();
    const { BookingAction, BookingState } = useBookingActions();
    const CalLayoutState = useCalendarLayoutState();

    const { viewMode, layoutMode } = CalendarState;
    // const isResourceShown = viewMode === CalViewMode.DAY && layoutMode === CalViewLayoutMode.GRID;
    const isResourceShown = viewMode !== CalViewMode.MONTH && layoutMode === CalViewLayoutMode.GRID;
    const isLayoutModeToggleShown = viewMode !== CalViewMode.MONTH;

    const activeDate = CalendarState?.activeDate;

    const navigate = (action: CalNavigate, date?: Date) => {
        props.onNavigate(action, date);
    };

    const startBooking = () => {
        BookingAction.setDetails({ startDate: new Date(), duration: 60 });
        if (!BookingState.isSagaMode) {
            // Start new saga
            BookingAction.startFromCalendar();
        }
    };

    const onPrint = () => {
        const url = URLBuilder.Print().AppointmentItem().list;
        AppNavigator.navigate(url, { setOrigin: true });
    };

    const setResourceMode = (newMode) => {
        const { resourceMode } = CalendarState;
        if (newMode !== undefined) {
            CalendarAction.setResourceMode(newMode);
        } else {
            const isChecked = resourceMode === CalResourceMode.SPLIT;
            CalendarAction.setResourceMode(isChecked ? CalResourceMode.COMBINED : CalResourceMode.SPLIT);
        }
    };

    const setLayoutMode = (newMode: any) => {
        const { layoutMode } = CalendarState;
        if (newMode !== undefined) {
            if (newMode === layoutMode) return;
            CalendarAction.setLayoutMode(newMode);
        } else {
            const isChecked = layoutMode === CalViewLayoutMode.GRID;
            CalendarAction.setLayoutMode(isChecked ? CalViewLayoutMode.LIST : CalViewLayoutMode.GRID);
        }
    };

    const renderFilterToggleButton = () => {
        const {
            filters: { hasActiveFilters },
        } = CalendarState;

        const handleClick = () => {
            CalLayoutState.setFilterBarState(!CalLayoutState.isFilterBarOpen);
        };

        if (CalLayoutState.isFilterBarOpen) {
            return null;
            // return <Button basic compact color={'purple'} onClick={handleClick} icon={'chevron left'} />;
        }

        const content = hasActiveFilters && appLayoutMode.gteTablet ? <span>Filtering</span> : undefined;
        return (
            <Button
                style={{ whiteSpace: 'nowrap' }}
                basic
                color={CalLayoutState.isFilterBarOpen || hasActiveFilters ? 'purple' : 'grey'}
                onClick={handleClick}
            >
                {hasActiveFilters ? <WarningIcon /> : <Icon name={'filter'} />}
                {content}
            </Button>
        );
    };

    const renderStaffGroupingToggle = () => {
        const { resourceMode, viewMode } = CalendarState;

        if (modulesAccess.FieldServices?.calendarSingleStaffMode) {
            return null;
        }

        if (viewMode !== CalViewMode.DAY) {
            return null;
        }

        const modes = {
            [CalResourceMode.COMBINED]: { label: 'Grouped', icon: 'object group' },
            [CalResourceMode.SPLIT]: { label: 'By Person', icon: 'object ungroup' },
        };

        const options = Object.keys(modes).map((mode) => {
            const { label } = modes[mode];
            const isActive = mode === resourceMode;
            return {
                key: mode,
                icon: isActive ? 'check' : '',
                active: isActive,
                onClick: () => setResourceMode(mode),
                content: label,
            };
        });

        return (
            <Dropdown
                button
                basic
                className={'icon pw-dropdown-padding-fix tiny'}
                labeled
                icon={modes[resourceMode].icon}
                text={modes[resourceMode].label}
                options={options}
            />
        );
    };

    const renderLayoutModeToggle = (mobile = false) => {
        const { layoutMode } = CalendarState;

        if (BookingState.isSelectingSlot) {
            return null;
        }

        const modes = [
            { mode: CalViewLayoutMode.GRID, label: 'Grid', icon: 'calendar alternate outline' },
            { mode: CalViewLayoutMode.LIST, label: 'List', icon: 'list ul' },
        ];

        const options = modes.map((m) => {
            const { label, icon, mode } = m;
            const isActive = mode === layoutMode;
            return {
                key: mode,
                icon: icon,
                active: isActive,
                value: mode,
                text: mobile ? label : '',
            };
        });

        return (
            <SegmentedSelectButtons
                options={options}
                value={layoutMode}
                onChange={(option) => {
                    setLayoutMode(option.value);
                }}
            />
        );
    };

    const renderViewRangeModeSwitch = (collapsed = false) => {
        let viewNames = props.views;
        const view = props.view;

        const options = viewNames.map((name) => {
            const isActive = view === name;
            const label = mapCalViewModeToLabel(name, false);
            return {
                key: name,
                icon: isActive ? 'check' : '',
                active: isActive,
                onClick: () => props.onView(name),
                content: label,
            };
        });

        const label = mapCalViewModeToLabel(view, false);

        return (
            <DropdownMenu>
                <DropdownMenuTrigger color={'grey'}>{label}</DropdownMenuTrigger>
                <DropdownMenuContent align={'center'}>
                    {options.map((option) => {
                        return (
                            <DropdownMenuItem key={option.key} onClick={option.onClick}>
                                {option.content}
                            </DropdownMenuItem>
                        );
                    })}
                </DropdownMenuContent>
            </DropdownMenu>
        );
    };

    const renderNavGroup = (fluid: boolean = false) => {
        const isToday = moment(activeDate).isSame(moment(), 'd');

        const color: SemanticCOLORS = 'grey';
        return (
            <div className={cn('flex flex-row', fluid && 'w-full')}>
                <Button
                    segmentPosition={'first'}
                    onClick={() => navigate(CalNavigate.PREVIOUS)}
                    basic
                    color={color}
                    icon={'chevron left'}
                />
                {appLayoutMode.gteTablet && (
                    <Button
                        onClick={() => navigate(CalNavigate.DATE, getTodayDate())}
                        color={'teal'}
                        variant={isToday ? 'primary' : 'secondary'}
                        segmentPosition={'middle'}
                        content={'Today'}
                    />
                )}
                <ToolbarDatePicker color={color} activeDate={activeDate} label={props.label} navigate={navigate} />
                <Button
                    style={{ borderLeft: 'none' }} // HACK: FIX: double border appears
                    onClick={() => navigate(CalNavigate.NEXT)}
                    basic
                    color={color}
                    icon={'chevron right'}
                    segmentPosition={'last'}
                />
            </div>
        );
    };

    const renderPrintButton = () => {
        const { view } = props;
        if (!modulesAccess.System?.printAccess) {
            return null;
        }

        if (view === CalViewMode.DAY || view === CalViewMode.WEEK) {
            return (
                <Button
                    variant={'tertiary'}
                    color={'blue'}
                    onClick={onPrint}
                    icon={'print'}
                    // content={appLayoutMode.gteTablet ? 'Print' : undefined}
                />
            );
        }
        return null;
    };

    const renderNewAppointmentButton = () => {
        if (BookingState.isSagaMode) {
            // TODO: don't show this button if already in saga mode
            // When in saga mode, pressing on this button will start another saga and it screws the hole thing up.
            return null;
        }

        return (
            <DropdownMenu>
                <DropdownMenuTrigger className={'ui button tiny blue'}>Create</DropdownMenuTrigger>
                <DropdownMenuContent alignOffset={-8}>
                    <DropdownMenuItem onClick={startBooking}>One off Customer case</DropdownMenuItem>
                    <DropdownMenuItem onClick={startBooking}>Recurring Customer case</DropdownMenuItem>
                    <DropdownMenuItem onClick={startBooking}>Internal appointment</DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        );
    };

    const renderDispatcherToolToggle = () => {
        if (!CalLayoutState.canOpenDock) {
            return null;
        }
        const handleClick = () => {
            CalLayoutState.setDockState(!CalLayoutState.isDockOpen);
        };

        if (!CalLayoutState.isDockOpen) {
            return <Button basic color={'purple'} onClick={handleClick} icon={ModuleIconNames.ServiceCase} />;
        }
    };

    const renderDesktop = () => {
        return (
            <div className={'flex flex-row items-center w-full mb-5'}>
                <div className={'w-[64px] text-center'}>{renderFilterToggleButton()}</div>
                <div
                    className={
                        'flex-1 p-2 rounded bg-white shadow justify-between items-center flex flex-row flex-wrap gap-2'
                    }
                >
                    <ButtonsGroupRow>
                        {renderNewAppointmentButton()}
                        {renderNavGroup()}
                        {isResourceShown && renderStaffGroupingToggle()}
                    </ButtonsGroupRow>
                    <ButtonsGroupRow>
                        {renderPrintButton()}
                        {isLayoutModeToggleShown && renderLayoutModeToggle()}
                        {renderViewRangeModeSwitch()}
                        {renderDispatcherToolToggle()}
                    </ButtonsGroupRow>
                </div>
            </div>
        );
    };

    const renderMobile = () => {
        return (
            <div className={'calendar-toolbar mb-1 p-1 md:p-1 flex flex-col rounded bg-white gap-2 print:hidden'}>
                <ButtonsRow>
                    <ButtonsGroupRow>
                        {renderViewRangeModeSwitch(true)}
                        {isResourceShown && renderStaffGroupingToggle()}
                    </ButtonsGroupRow>
                    <ButtonsGroupRow>
                        {!CalLayoutState.isDockOpen && renderPrintButton()}
                        {!CalLayoutState.isDockOpen && renderNewAppointmentButton()}
                        {!CalLayoutState.isDockOpen && renderDispatcherToolToggle()}
                    </ButtonsGroupRow>
                </ButtonsRow>
                <ButtonsRow>
                    <ButtonsGroupRow>
                        {renderFilterToggleButton()}
                        {isLayoutModeToggleShown && renderLayoutModeToggle(false)}
                    </ButtonsGroupRow>
                    <ButtonsGroupRow className={'flex-1 max-w-sm'}>{renderNavGroup(true)}</ButtonsGroupRow>
                </ButtonsRow>
            </div>
        );
    };

    const renderTablet = () => {
        return (
            <div className={'calendar-toolbar mb-1 p-1 md:p-1 flex flex-col rounded bg-white gap-2 print:hidden'}>
                <ButtonsRow>
                    <ButtonsGroupRow>
                        {isResourceShown && renderStaffGroupingToggle()}
                        {isLayoutModeToggleShown && renderLayoutModeToggle(false)}
                        {renderViewRangeModeSwitch(false)}
                    </ButtonsGroupRow>
                    <ButtonsGroupRow>
                        {renderNewAppointmentButton()}
                        {!CalLayoutState.isDockOpen && renderPrintButton()}
                        {!CalLayoutState.isDockOpen && renderDispatcherToolToggle()}
                    </ButtonsGroupRow>
                </ButtonsRow>
                <ButtonsRow>
                    <ButtonsGroupRow>{renderFilterToggleButton()}</ButtonsGroupRow>
                    <ButtonsGroupRow className={'flex-1 max-w-md'}>{renderNavGroup(true)}</ButtonsGroupRow>
                </ButtonsRow>
            </div>
        );
    };

    if (appLayoutMode.gteDesktopSM) {
        return renderDesktop();
    } else if (appLayoutMode.gteTablet) {
        return renderTablet();
    } else {
        return renderMobile();
    }
};

export default CalendarToolbar;
