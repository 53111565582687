import * as React from 'react';
import {
    Frame,
    PhotoAttachmentGroupHorizontal,
    PhotoAttachmentType,
    photoGroupTypeToLabel,
    SectionButton,
} from '../components';
import { Button, PageLayout } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMobileAppointmentCtx } from '../Root';
import { NotesFeed } from '../../../components/NotesFeed';

interface PageSectionProps {
    expanded?: boolean;
    onToggle?: () => void;
    appointmentItem?: NodeType.AppointmentItem;
}

export const PhotoAttachmentsPreview: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { workOrder } = useMobileAppointmentCtx();

    const files = fromEdges(workOrder?.files);

    const onAddPhoto = () => {
        AppNavigator.navigateRelative('/photos');
    };
    return (
        <Frame bordered={true}>
            <div className={'flex flex-row justify-between'}>
                <strong>Attachments</strong>
                <Button variant={'tertiary'} icon={'chevron right'} size={'sm'} onClick={onAddPhoto} />
            </div>
            {files.length > 0 && (
                <PhotoAttachmentGroupHorizontal
                    files={files}
                    label={photoGroupTypeToLabel[PhotoAttachmentType.Other]}
                    onAddPhoto={onAddPhoto}
                />
            )}
        </Frame>
    );
};

export const JobTechNotesPreview = () => {
    const { AppNavigator } = useAppNavigator();
    const { workOrder } = useMobileAppointmentCtx();

    const onAddNotes = () => {
        AppNavigator.navigateRelative('/notes');
    };
    const notes = fromEdges(workOrder?.notes);
    return (
        <Frame bordered={true}>
            <div className={'flex flex-row justify-between'}>
                <strong>Job Notes</strong>
                <Button variant={'tertiary'} icon={'chevron right'} size={'sm'} onClick={onAddNotes} />
            </div>
            {notes.length > 0 && <NotesFeed mode={'inputbottom'} maxHeight={2000} notes={notes} />}
        </Frame>
    );
};

export const PageSectionNotesAndPhotos: React.FC<PageSectionProps> = ({ expanded, onToggle }) => {
    return (
        <>
            <SectionButton expanded={expanded} onClick={onToggle}>
                All Notes and Attachments
            </SectionButton>
            {expanded && (
                <PageLayout.BodySection vStack={true}>
                    <PhotoAttachmentsPreview />
                    <JobTechNotesPreview />
                </PageLayout.BodySection>
            )}
        </>
    );
};
